import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async zuluPerformance(created_from, created_to) {
        let data = {
            "created_from": created_from,
            "created_to": created_to
        }

        try {
            let response = await fetch(apiHost + 'report/user-performance/zulu-performance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async countZuluClamps(unclamped_by_id, clamp_status_id, created_from, created_to) {

        let data = {
            "unclamped_by_id": unclamped_by_id,
            "clamp_status_id": clamp_status_id,
            "created_from": created_from,
            "created_to": created_to
        }

        try {
            let response = await fetch(apiHost + 'report/user-performance/count_zulu-unclamps',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

}