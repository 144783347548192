import { useContext } from "react"
import ThemeContext from "../../Context/ThemeContext"
import AlertError from "./AlertError"

const Notifier=()=>{

    const {displayMessage} = useContext(ThemeContext)

    return <>
            {displayMessage && <AlertError 
                                    message={displayMessage.message}
                                    type={displayMessage.type}
                                    title={displayMessage.title}
                                    />}
            </>
}


export default Notifier;