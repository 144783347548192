import React, { useEffect, useState, useContext } from "react";
import ajax from "../utils/ajax";
import functions from "../utils/functions";
import ajaxSupervisor from "../utils/remote/ajaxSupervisor";

const SupervisorReportContext = React.createContext();

export const SupervisorReportConsumer = SupervisorReportContext.Consumer;


export const SupervisorReportProvider=(props)=>{

   const [data, setData] = useState({"page":"1"})
   const [currentPage, setCurrentPage] = useState(1);
   const [fieldReport, setFieldReport] = useState(false);
   const [title, setTitle] = useState(false);

   useEffect(()=>{
    getFieldReport();
  }, [data])



  const  getFieldReport=async()=>{

    setFieldReport(false);
    const server_response = await ajaxSupervisor.fetchReport(data);
    if (server_response.status === "OK") {
        setTitle(server_response.message);
        setFieldReport(server_response.details.content);
    }else{
        setTitle('');
        setFieldReport("404")
    }
  }




const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "page":"1",
        "supervisor_id":options.supervisor
    })
    
}


    return (
        <SupervisorReportContext.Provider value={{
            fieldReport,
            title,
            getFieldReport,
            setData,
            resetData,
            filterData,
         }}
         >
            {props.children}
        </SupervisorReportContext.Provider>
     );


}

export default SupervisorReportContext;