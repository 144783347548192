import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';
import functions from '../../utils/functions'
import ajaxConfig from '../../utils/remote/ajaxConfig';
import { restoreTable } from '../../utils/script/customTables';
import SelectSearch from '../Common/SelectSearch';

class PopUploadSticker extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        fileName:'',
        actualCsvFile:'',
        depositorName:'',
        depositorContact:'',
        clientVehicle: '',
        batchName: '',
        credit:'',
        info: '',
        loading: false
    }

    onChangeFile = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
             this.setState({
                actualCsvFile:{file:  e.target.result}
             })
         }
         
    }

    handleDepositorName=(e)=>{
        this.setState({depositorName: e.target.value})
    }

    handleDepositorContact=(e)=>{
        this.setState({depositorContact: e.target.value})
    }

    handleCredit = (selectedOption) => {
       
        this.setState({credit: selectedOption.value})
    }

    onConfirm = async (event) => {
        event.preventDefault();
        const {fileName, actualCsvFile, depositorContact, depositorName, batchName, clientVehicle, credit}=this.state;
        if (fileName.length > 0 && depositorContact.length>9 && depositorName.length>0 && batchName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response = await ajaxConfig.migrateStickerBalances(actualCsvFile, depositorName, depositorContact, batchName, clientVehicle, credit);
             this.setState({
                loading: false
            })
            if(server_response.status=="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />,
                    fileName:'',
                    actualCsvFile:''
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

           
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }


    RenderCredit = () =>{

        const options = [
            {label: "Cash", value:"0"}, 
            {label: "Credit", value: "1"}
        ]

        return  <SelectSearch
        label="Sale Type"
        onChange={() => this.handleCredit}
        options={options}
        placeholder={"Select sale type..."}
    />
    }


   
    render() {

        return (
            <div className="modal" id="modal_vehicle_stickers">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Import Vehicle Stickers</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              <p>Note: The CSV file must have four(4) columns labelled <b>Plate Number</b>,  <b>Sticker type</b>, <b>Effective Date</b>  and <b>Expiry Date</b>.</p>

                              <TextInput
                                    label="Depositor Name"
                                    type="text"
                                    onChange={() => this.handleDepositorName}
                                    value={this.state.depositorName}
                                />
                                 <TextInput
                                    label="Depositor Contact"
                                    type="text"
                                    onChange={() => this.handleDepositorContact}
                                    value={this.state.depositorContact}
                                />
                                 <TextInput
                                    label="Batch Name"
                                    type="text"
                                    onChange={(e) => this.setState({batchName: e.target.value})}
                                    value={this.state.batchName}
                                    name="BatchName"
                                />
                                  <TextInput
                                    label="Client Vehicle"
                                    type="text"
                                    onChange={(e) => this.setState({clientVehicle: e.target.value})}
                                    value={this.state.clientVehicle}
                                    name="ClientVehicle"
                                />

                                <this.RenderCredit/>
                            <TextInput
                                    label="Select CSV file"
                                    type="file"
                                    onChange={() => this.onChangeFile}
                                    value={this.state.fileName}
                                />



                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Upload</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopUploadSticker;
