import React from 'react';
import ManageUserContext from '../../Context/ManageUserContext';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';

class PopAssignDevice extends React.Component {
    static contextType = ManageUserContext;
    state = {
        info: '',
        deviceId: '',
        userId: '',
        deviceList: false,
        userList: false,
        userRecord: false,
        device: "Select Device...",
        loading: false

    }
    componentDidMount() {
        this.getDevices();
        this.getUser();

    }

    onChangeDevice = (selectedOption) => {
        this.setState({
            deviceId: selectedOption.value,
            location: selectedOption.label
        })
    }


    getDevices = async () => {

        const server_response = await ajax.listDevices();
        if (server_response.status === "OK") {
            this.setState({
                deviceList: server_response.details.content.list
            })
        } else {
            this.setState({
                deviceList: "404"
            })
        }
    }



    getUser = async () => {

        const server_response = await ajax.listUsers();
        if (server_response.status === "OK") {
            this.setState({
                userList: server_response.details.content.list
            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }


    onChangeUser = (selectedOption) => {
        this.setState({
            userId: selectedOption.value
        })
    }

    onConfirm = async () => {
        this.setState({
            info: ''
        })
        const { userId, deviceId } = this.state;
        if (userId.length > 0 && deviceId.length > 0) {
            this.setState({
                loading: true
            }, () => this.props.onConfirm(userId, deviceId))
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }

    render() {

        const deviceList = this.state.deviceList;
        const optionsDevice = [];
        if (deviceList && deviceList !== "404") {
            deviceList.map((item, key) =>
                optionsDevice.push({ label: item.device_number + " (" + item.tag_number + ")", value: item.device_id })
            )
        }


        const {userList} = this.context;
        const optionsUser = [];
        if (userList && userList !== "404") {
            userList.map((item, key) =>
                optionsUser.push({ label: item.first_name + ' ' + item.last_name + ' @' + item.username + ' (' + item.user_role.role_name + ')', value: item.user_id })
            )
        }

        return (
            <div className="modal" id="pop_assign_device">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Device Assignment</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <SelectSearch
                                    label="Select User"
                                    onChange={() => this.onChangeUser}
                                    options={optionsUser}
                                    placeholder={"Select User..."}
                                />

                                <SelectSearch
                                    label="Select Device"
                                    onChange={() => this.onChangeDevice}
                                    options={optionsDevice}
                                    placeholder={this.state.device}
                                />




                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Assign Device</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>

            </div>
        )


    }
}

export default PopAssignDevice;
