import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
// import SearchBar from '../../Components/Common/SearchBar'
import ActivityLoader from '../../Components/Common/ActivityLoader'
// import RecentStickerSales from '../../Components/Finance/RecentStickerSales'
import Toast from '../../Components/Common/Toast'
// import SelectSearch from '../../Components/Common/SelectSearch'
// import StickerReceipt from '../../Components/Payments/StickerReceipt'
import { Link } from 'react-router-dom'
import NoContent from '../../Components/Common/NoContent'
import PageNav from '../../Components/Common/PageNav'
import FilterBar from '../../Components/Common/FilterBar'
import dictionary from '../../utils/dictionary'
import PopNewTicketFloat from '../../Components/Finance/PopNewTicketFloat'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'

class VendorTicketRecords extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    vehicleInfo: false,
    plateNumber: '',
    autoVehicle: false,
    loading: false,
    home: true,
    toast: false,
    stickerTypes: false,
    stickerTypeId: '',
    pagination: false,
    currentPage: 1,
    metaData: false,
    filter: false,
    floatDialogue: false
  }

  componentDidMount() {
    this.listTransactions();
  }



  resetToast = () => {
    this.setState({
      toast: false
    })
  }


  listTransactions = async () => {
    if (this.state.filter) {
      const filter = this.state.filter;
      var server_response = await ajax.filterTicketSales(
        this.state.currentPage,
        filter.dateFrom,
        filter.dateTo,
        this.state.userId,
        filter.keyword
      );
    } else {
      var server_response = await ajax.filterTicketSales(
        this.state.currentPage,
        "", "", this.state.userId, ""
      );

    }
    if (server_response.status === "OK") {
      this.setState({
        transactionList: server_response.details.content.list,
        metaData: server_response.details.content.meta,
      }, () => this.paginate())

    } else {
      this.setState({
        transactionList: "404",
        metaData: false
      })
    }
  }


  onClickLoadFloat = () => {
    this.setState({
      floatDialogue: false
    }, () => {

      this.setState({
        floatDialogue: <PopNewTicketFloat onConfirm={this.onConfirmLoadFloat} />
      }, () => {
        functions.openPopup("modal_new_ticket_float");
      })

    })

  }


  getStickerTypes = async () => {
    const server_response = await ajax.listStickerTypes()
    if (server_response.status === "OK") {
      this.setState({
        stickerTypes: server_response.details.content,
      })
    }
  }


  //==============NAVIGATION CONTROLS START===========================
  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
      this.setState({
        page: this.state.page + 1,
        transactionList: false

      }, () => {
        this.listTransactions();
      })
    }

  }

  onClickPage = (page) => {
    this.setState({
      page: page,
      transactionList: false
    }, () => this.listTransactions())
  }

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState({
        page: this.state.page - 1,
        transactionList: false

      }, () => {
        this.listTransactions();
      })
    }

  }

  paginate = () => {
    if (this.state.metaData) {
      this.setState({
        pagination: false
      }, () => {

        this.setState({
          pagination: <PageNav
            totalPages={this.state.metaData.total_pages}
            pageList={this.state.metaData.list_of_pages}
            currentPage={this.state.currentPage}
            onClickNext={this.onClickNext}
            onClickPage={this.onClickPage}
            onClickPrevious={this.onClickPrevious}
          />
        })

      })

    }
  }

  //==============NAVIGATION CONTROLS END===========================



  buttons = () => {
    const { transactionList } = this.state;
    return (
      <>

        <Link to="/new-ticket" className="btn ripple btn-success">
          <i className="far fa-edit"></i> {dictionary._sellTicket}
        </Link>
        {transactionList && transactionList !== "404" && <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
        </a>}

      </>
    )
  }


  onFilter = async (filter) => {
    this.setState({ filter: filter }, () => this.listTransactions())
  }
  resetFilter = () => {
    this.setState({
      filter: false,
      metaData: false,
      transactionList: false
    }, () => this.listTransactions())
  }




  refreshToast = () => {
    this.setState({
      toast: false
    })
  }



  render() {
    const transactionList = this.state.transactionList;
    const stickerOptions = [];
    this.state.stickerTypes && this.state.stickerTypes.map((item, key) =>
      stickerOptions.push({ title: item.ref_name, value: item.sticker_type_id })
    );

    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Ticket Sales"
              previousPageTitle="Home"
              buttons={<this.buttons />}

            />
            {this.state.toast}
            {this.state.floatDialogue}




            <CustomizedFilterBar
              keyword="keyword"
              onReset={this.resetFilter}
              onConfirm={this.onFilter}
            />

            <div className="row">
              {this.state.receiptView}
              <div className="col-sm-12 col-xl-12 col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="card-title mb-1">Parking Ticket Sales</h6>
                      <p>All parking ticket sale records</p>
                    </div>
                    <div className="table-responsive">

                      {transactionList && transactionList !== "404" &&
                        <table className="table table-bordered text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th>Sale Date</th>
                              <th>Trans ID</th>
                              <th>Sale Agent</th>
                              <th>Vehicle</th>
                              <th>Float Ref</th>
                              <th>Amount(UGX)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionList.map((item, key) =>
                              <tr key={key}>
                                <td>{item.created_at.long_date}</td>
                                <td>{item.sale_id_c}</td>
                                <td>{item.sold_by.username}</td>
                                <td>{item.vehicle.plate_number}</td>
                                <td className="tx-bold">
                                  <Link to={
                                    {
                                      pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                      state: { plateNumber: item.vehicle.plate_number }
                                    }}>{item.vehicle.plate_number}
                                  </Link>
                                </td>
                                <td>{item.amount.amount_c}</td>
                              </tr>
                            )}

                          </tbody>
                        </table>}
                      {transactionList === "404" && <NoContent />}
                      {!transactionList && <ActivityLoader />}
                      {transactionList && this.state.pagination}
                    </div>
                  </div>
                </div>


              </div>
            </div>








          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default VendorTicketRecords
