import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'
import ajaxConfig from '../../../utils/remote/ajaxConfig'

class EditWrongParkingReason extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        description: '',
        descriptionID: '',
        info: ''

    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/config/w.p-description');
        } else {
            const description = this.props.location.state.record;
            this.setState({
                descriptionID: description.description_id,
                description: description.description


            })

        }
    }


    onChangedescription = (event) => {
        this.setState({ description: event.target.value })
    }

    onChangeDescription = (event) => {
        this.setState({ description: event.target.value })
    }

    onChangeLat = (event) => {
        this.setState({ lat: event.target.value })
    }

    onChangeLng = (event) => {
        this.setState({ lng: event.target.value })
    }

    onSave = async (event) => {
        event.preventDefault();
        const { descriptionID, description } = this.state;

        if (description.length > 0) {
            //fetch login from the API
            const server_response = await ajaxConfig.updateWrongParkingDescription(descriptionID, description);
            if (server_response.status === "OK") {
                this.setState({ info: "" }, () =>
                    this.setState({
                        info: <AlertSuccess message={server_response.details.message} />
                    }))

            } else {
                this.setState({
                    info: ""
                }, () =>

                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    }))

            }

        } else {
            this.setState({ info: "" }, () =>
                this.setState({
                    info: <AlertError message={dictionary._requiredescription} />,
                    loading: ''
                }))
        }


    }

    render() {
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">

                        <PageHeader
                            title="Edit Wrong Parking reason"
                            previousPageTitle="Wrong Parking reasons"
                            back="/config/w.p-description"
                        />

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">

                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                {this.state.info}
                                                <form method="post" onSubmit={this.onSave}>

                                                    {/* 
                                                    <TextInput
                                                        label="W.P Description"
                                                        type="text"
                                                        onChange={() => this.onChangedescription}
                                                        value={this.state.description}
                                                    /> */}
                                                    <TextInput
                                                        label="Description"
                                                        type="text"
                                                        onChange={() => this.onChangeDescription}
                                                        value={this.state.description}
                                                    />

                                                    <div className="modal-footer">
                                                        <input className="btn ripple btn-success" type="button" value="Save Changes" onClick={this.onSave} />
                                                    </div>
                                                </form>

                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>

                            <ConfigMenu />



                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default EditWrongParkingReason
