import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import PopNewSession from '../../Components/Session/PopNewSession'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import PINLocation from '../../Components/Common/PINLocation'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import Export from '../../Components/Common/Export'
import Badge from '../../Components/Common/Badge'
import PageNav from '../../Components/Common/PageNav'
import PopWhiteListRecord from '../../Components/WhiteListing/PopWhiteListRecord'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'

class RTJSPayment extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        pagination: false,
        recordView: false
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                status: this.props.location.state.status
            }, () => this.listAllRecords())
        } else {
            this.listAllRecords();
        }
        this.getAllStartedBy();

    }


    componentWillUnmount() {
        //clearTimeout(this.timerCountSession)
    }





    listAllRecords = async () => {
        if (this.state.filter) {
            const filter = this.state.filter;
            // var server_response = await ajax.filterWhiteList(
            //     filter.dateFrom,
            //     filter.dateTo,
            //     filter.selectedValue,
            //     filter.keyword,
            //     this.state.currentPage,
            //     this.state.status
            // );
            var server_response = await ajax.listRTJSRecords(this.state.status, this.state.currentPage);


        } else {
            var server_response = await ajax.listRTJSRecords(this.state.status, this.state.currentPage);
        }
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())

        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }
    }











    buttons = () => {
        return (
            <>


                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>

            </>
        )
    }

    getAllStartedBy = async () => {
        const server_response = await ajax.listConductors()
        if (server_response.status === "OK") {
            this.setState({
                conductors: server_response.details.content.list,
            })
        }
    }


    onFilter = async (filter) => {
        this.setState({
            filter: false,
            transactionList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllRecords())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            transactionList: false
        }, () => this.listAllRecords())
    }


    onChangeStatus = (status) => {
        this.setState({
            status: status,
            transactionList: false,
            metaData: false,
            currentPage: 1
        }, () => this.listAllRecords())
    }



    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                page: this.state.page + 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    onClickPage = (page) => {
        this.setState({
            page: page,
            transactionList: false
        }, () => this.listAllRecords())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                page: this.state.page - 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {

                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }

    //==============NAVIGATION CONTROLS END===========================





    render() {
        const transactionList = this.state.transactionList;
        const filter = this.state.filter

        // const userList = this.state.userList;
        const userOptions = [];
        this.state.conductors && this.state.conductors.map((item, key) =>
            userOptions.push({
                title: item.username,
                value: item.user_id
            })
        );

        return (
            <div className="page">

                {this.state.recordView}


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="RTGs"
                            previousPageTitle="Finance"
                            // options={this.state.menuOptions}
                            back="/finance"
                            buttons={<this.buttons />}
                        />


                        <CustomizedFilterBar
                            selection={{ title: 'Recorded By', options: userOptions }}
                            keyword="keyword"
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        />

                        {this.state.toast}
                        {this.state.locationPIN}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="RTGS Records"
                                            subtitle="List of all payment RTGs records from the most recent."
                                        />


                                        <div className="text-wrap">
                                            <div className="example">
                                                <div className="btn-list">

                                                    <button type="button"
                                                        className="btn ripple btn-primary btn-sm"
                                                        onClick={() => this.onChangeStatus("")}>
                                                        All Transactions <span className="badge badge-light ml-2">...</span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn ripple btn-secondary btn-sm"
                                                        onClick={() => this.onChangeStatus(0)}>
                                                        Pending Transactions <span className="badge badge-dark ml-2">...</span>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn ripple btn-success btn-sm"
                                                        onClick={() => this.onChangeStatus(1)}>
                                                        Approved Transactions <span className="badge badge-dark ml-2">...</span>
                                                    </button>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-hover table-ellipsis">
                                                <thead>
                                                    <tr>
                                                        <th>Record Date</th>
                                                        <th>RTJS Date</th>
                                                        <th>Bank</th>
                                                        <th>Funds Source</th>
                                                        <th>Amount(UGX)</th>
                                                        <th>Status</th>
                                                        <th>Depositor Name</th>
                                                        <th>Depositor Contact</th>
                                                        <th>Recorded By</th>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactionList && transactionList !== "404" && transactionList.map((item, key) =>
                                                        <tr key={key}>
                                                            <td>{item.created_at.short_date}</td>
                                                            <td>{item.rtjs_date.short_date}</td>
                                                            <td>{item.bank.bank_name}</td>
                                                            <td className="tx-bold">

                                                            {item.rtgs_type.code == "STICKER" && <Link to={
                                                                    {
                                                                        pathname: "/finance/rtjs_payment/manage",
                                                                        state: { transactionId: item.transaction_id }
                                                                    }
                                                                }>
                                                                    {item.source_description}
                                                                </Link>}

                                                                {item.rtgs_type.code == "VEHICLE" && <Link to={
                                                                    {
                                                                        pathname: "/finance/rtjs_payment/manage",
                                                                        state: { transactionId: item.transaction_id }
                                                                    }
                                                                }>
                                                                    {item.source_description}
                                                                </Link>}

                                                                {item.rtgs_type.code == "RESERVE" && <Link to={
                                                                    {
                                                                        pathname: "/finance/rtgs/reserve",
                                                                        state: { transactionId: item.transaction_id }
                                                                    }
                                                                }>
                                                                    {item.source_description}
                                                                </Link>}


                                                               
                                                            </td>
                                                            <td>{item.amount.amount_c}</td>
                                                            <td>{item.status.status}</td>
                                                            <td>{item.depositor_name}</td>
                                                            <td>{item.depositor_contact}</td>
                                                            <td>{item.created_by.username}</td>


                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>


                                            {transactionList && this.state.pagination}
                                            {!transactionList && <ActivityLoader />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );

    }
}

export default RTJSPayment
