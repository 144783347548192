import React, { useContext } from 'react';
import StatisticCard from '../../../StatisticCard';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';


const SavedLastMonth =()=>{

    const {lastMonthTax } = useContext(EfrisHistoryContext);

 
    return <StatisticCard
                title="Prev. Saving" 
                value={lastMonthTax ? lastMonthTax : "..."}
                caption="Last Month"
                icon={"fa fa-check-circle"}
                color={"text-warning"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-warning"}
                />
 
}

export default SavedLastMonth;