import React, { useContext, useState } from 'react';
import AlertError from '../../Common/AlertError';
import TextInput from '../../Common/TextInput';
import dictionary from '../../../utils/dictionary';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ThemeContext from '../../../Context/ThemeContext';
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext';
import ajaxEfris from '../../../utils/remote/ajaxEfris';
import ActivityLoader from '../../Common/ActivityLoader';


const PopNewCreditNote=(props)=> {

    
        const [info, setInfo] = useState(false);
        const [depositId, setDepositId] = useState('');

        const {setDisplayMessage} = useContext(ThemeContext);

        const { refreshTax } = useContext(FiscalReceiptContext)
       
        const [loading, setLoading] = useState(false);


        const onConfirm = async (event) => {
            event.preventDefault();
            if (depositId.length > 0) {
                    setLoading(true)
                    const server_response = await ajaxEfris.createCreditNote(depositId);
                    setLoading(false)
                    if(server_response.status === "OK"){
                        setDisplayMessage(functions.templateMessage("EFRIS:", server_response.message, "info"))
                        setInfo(<AlertSuccess message={server_response.message} />)
                        functions.closePopup("modal-new-credit-note");
                        refreshTax();
                    }else{
                        setDisplayMessage(functions.templateMessage("EFRIS:", server_response.message, "danger"))
                        setInfo(<AlertError message={server_response.message} />)
                    }

             

            } else {
                setInfo(<AlertError message={"Enter receipt number, then try again."} />)
            }
        }



    
        return (
            <div className="modal" id="modal-new-credit-note">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Credit Note</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      
                                <TextInput
                                    type="text"
                                    label="Receipt No."
                                    onChange={(e) => setDepositId(e.target.value)}
                                    placeholder={"Deposit receipt number"}
                                    name="deposit_ref"
                                    value={depositId}
                                    required
                                />
                                

                            </form>

                        
                            <p className='small'><b>NOTE:</b> A Credit note is refund to a customer. Visit the vehicle payments to get the receipt No. for a particular payment you want to refund.</p>

                        </div>
                        <div className="modal-footer">
                            {!loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={onConfirm}>Save Credit Note</button>
                                <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopNewCreditNote;
