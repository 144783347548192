import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
// import TitleValueCard from '../../Components/TitleValueCard'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import dictionary from '../../../utils/dictionary';
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
const eformats = dictionary._exportConfigBtnFormats;

class ConfigTowingStations extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        towingStationList: false,
        towingStationID: false,
        towingStationRecord: false,
        toast: ''
    }

    componentDidMount() {
        this.getTowingStations();
    }

    getTowingStations = async () => {
        const server_response = await ajax.listTowingStations(this.state.userId);

        if (server_response.status === "OK") {
            this.setState({
                towingStationList: server_response.details.content
            })
        } else {
            this.setState({
                towingStationList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/towing-station/new'

        })
    }


    onClickEdit = () => {

        if (this.state.towingStationRecord) {

            this.props.history.push({
                pathname: '/config/towing-station/edit',
                search: '?q=1',
                state: { record: this.state.towingStationRecord }
            })
        }

    }

    onClickDelete = () => {
        if (this.state.towingStationRecord) {
            $("#modal_delete").modal('show')
        }
    }

    onConfirmDelete = async () => {

        if (this.state.towingStationID) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteTowingStation(this.state.towingStationID, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.towingStationID);
                    //Refresh list
                    this.getTowingStations();
                })

            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }

    onSelecttowingStationRecord = (towingStationID, towingStationRecord) => {
        this.setState({
            towingStationID: towingStationID,
            towingStationRecord: towingStationRecord,
            toast: ''
        })
    }

    render() {
        const towingStationList = this.state.towingStationList
        return (
            <div className="page">
                <WarnDelete
                    title="Delete Towing station?"
                    onConfirm={() => this.onConfirmDelete()} />



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Towing Stations"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "add": () => this.onClickAdd(),
                                "edit": () => this.onClickEdit(),
                                "delete": () => this.onClickDelete()
                            }}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <TableHeader
                                                    title="List Of Company Towing Stations"
                                                    subtitle="List of all towing stations managed by Mutiplex parking"
                                                />

                                                <div className="table-responsive">
                                                    {(towingStationList === "404") && <NoContent />}

                                                    {towingStationList && Array.isArray(towingStationList) &&
                                                        <table id="config-towingStation" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>No.</th>
                                                                    <th>Towing Station</th>
                                                                    <th>Description</th>
                                                                    <th>Created On</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {towingStationList && towingStationList !== "404" &&
                                                                    towingStationList.map((item, key) =>
                                                                        <tr
                                                                            id={"br_" + item.tow_station_id}
                                                                            key={key}
                                                                            className={`${this.state.towingStationID == item.tow_station_id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelecttowingStationRecord(item.tow_station_id, item)}>
                                                                            <td>TS-{key + 1}</td>
                                                                            <td>{item.station_name}</td>
                                                                            <td>{item.description}</td>
                                                                            <td>{item.created_at.long_date}</td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!towingStationList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigTowingStations
