import React from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';
import BranchContext from '../../Context/BranchContext';

class PopUpdateBranch extends React.Component {

    static contextType = BranchContext

    state = {
        branchId:'',
        branchName:'',
        loading:false
    }

    onChangeBranch=(item)=>{
        this.setState({
            branchId:item.value,
            branchName:item.label
        })
    }
    
  

    onConfirm = (event) => {
        event.preventDefault();
        const { branchId, branchName } = this.state;
        if (branchId.length > 0) {
            this.setState({loading:true}, ()=>{

                this.props.onConfirm(branchName, branchId);
            })
           
        } else {
            this.setState({
                info: ''
            }, () => this.setState({
                info: <AlertError message={"Select branch name and try again"} />
            })
            )
        }
    }

    
    render() {

        const {branchListing} = this.context;
        const options = [];
        Array.isArray(branchListing) && branchListing.map((item, key)=>{
            options.push({
                label: item.branch_name,
                value: item.branch_id
            })
        })

        return (
            <div className="modal" id="modal_update_branch">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Update Branch</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Branch"
                                    type="select"
                                    name="Branch"
                                    options={options}
                                    placeholder="Select Branch"
                                    onChange={this.onChangeBranch}
                                />

                              

                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Assign Branch</button>
                            <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopUpdateBranch;
