//Beauty table
import $ from 'jquery';
import 'datatables.net-buttons-bs4'
import jsZip from 'jszip';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import 'datatables.net-buttons/js/buttons.print.min';
import 'datatables.net-buttons/js/buttons.colVis.min';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
window.JSZip = jsZip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake = pdfMake;

export const beautifyTable=(tableId, load, exportFormats)=>{
   // console.log("Tables initialization requested")
   
        if(load)
        {
            //console.log("Tables initialization granted")
  
               setTimeout(() => {
                //['copy', 'excel', 'csv', 'pdf', 'print']
                    var table = $('#' + tableId).DataTable( {
                        buttons: Array.isArray(exportFormats)?exportFormats:false,
                        lengthChange: false,
                        pageLength:30,
                        order:false
                    } );
                    table.buttons().container()
                    .appendTo( '#' + tableId + '_wrapper .col-md-6:eq(0)' );
                
        
                    }, 1000);
          
        }    
   
  }

  export const restoreTable=(tableId)=>{
    $('#'+tableId).DataTable().destroy();
  }

  export const detectTable=()=>{
    var tableElement = document.getElementsByClassName("datatable");
    if(tableElement.length>0)
    {
        return tableElement[0].getAttribute('id')
    }
    
      return false;

  }



