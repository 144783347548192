import React from 'react'
import { Link } from 'react-router-dom'
import AnalyticsContext from '../../../Context/Reports/AnalyticsContext'
import ActivityLoader from '../../Common/ActivityLoader'
import NoContent from '../../Common/NoContent'
import TableHeader from '../../Common/TableHeader'


class LocationPerformance extends React.Component {

    static contextType = AnalyticsContext

    renderProgessColor = ($value) => {
        if ($value <= 30) {
            return "bg-danger"
        }
        if ($value <= 40) {
            return "bg-warning"
        }
        if ($value <= 50) {
            return "bg-secondary"
        }

        if ($value <= 60) {
            return "bg-info"
        }

        if ($value <= 70) {
            return "bg-primary"
        }

        if ($value > 70) {
            return "bg-success "
        }
    }

    RenderRow = (props) => {
        return <div className="pb-3">
            <div className="ml-auto">

                <div className="d-flex">
                    <span className="mr-4 font-weight-bold">{props.record.location_info.location_name}</span>
                    <span className="mr-4 font-weight-bold">{props.record.total_daily_sessions.total_p} <span className='fs-10'> session(s)</span>
                    </span>
                    <span className="mr-4 text-dark fs-12 font-weight-bold">{props.record.location_info.total_slots.total_p} <span className='fs-10'>slots</span> &nbsp;<span className="text-primary fs-12 mt-3"><i className="fa fa-car"></i> <span className='text-dark'>{props.record.total_available_vehicles.total_p}</span></span>
                    </span>

                    {props.record.variation >= 0 && <span className="text-success fs-12 mt-1"><i className="fe fe-arrow-up"></i>{props.record.variation}</span>}
                    {props.record.variation < 0 && <span className="text-danger fs-12 mt-1"><i className="fe fe-arrow-down"></i>{props.record.variation}</span>}


                </div>
            </div>
            <div className="main-traffic-detail-item">
                <div>
                    <span className='text-info'>{props.record.location_info.street.street_name}</span> <span>{props.record.occupancy}%</span>
                </div>
                <div>
                    <ol style={{ listStyle: 'none', fontSize: '12px' }}>
                        <li>C.C: {props.record.current_conductor.full_name}
                            {props.record.current_device && <span className="text-muted fs-8 ml-2"><i className='fa fa-phone text-success fs-10'></i> {props.record.current_device.device_info.phone_number}</span>}

                            {props.record.current_device && <span className="text-muted fs-8 ml-2"><i className='fa fa-mobile-alt text-dark fs-10'></i> {props.record.current_device.device_info.tag_number}</span>}
                        </li>

                        <li>P.C: {props.record.previous_conductor ? props.record.previous_conductor.full_name : "No Previous Assignment"}
                            {props.record.previous_device && <span className="text-muted fs-8 ml-2"><i className='fa fa-phone text-success fs-10'></i> {props.record.previous_device.device_info.phone_number}</span>}

                            {props.record.previous_device && <span className="text-muted fs-8 ml-2"><i className='fa fa-mobile-alt text-dark fs-10'></i> {props.record.previous_device.device_info.tag_number}</span>}

                        </li>
                    </ol>

                </div>
                <div className="progress">
                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow={props.record.ui_occupancy_percentage} className={`progress-bar progress-bar-xs wd-${props.record.ui_occupancy_percentage}p ${this.renderProgessColor(props.record.occupancy)}`} role="progressbar"></div>
                </div>
            </div>
        </div>
    }


    render() {
        const { transactionListing, refreshPerformance } = this.context;
        return (<div className="col-sm-12 col-xl-4 col-lg-4">
            <div className="card custom-card">
                <div className="card-body">

                    <TableHeader
                        title="Location Performance"
                        subtitle="Current performance relative to previous day"
                        rightComponent={<Link to="#" onClick={refreshPerformance}><i className='fa fa-redo tx-primary'></i></Link>}
                    />

                    <div className="activity-block user-manager mt-3 pt-3 scroll-widget border-top overflow-auto">




                        {Array.isArray(transactionListing) && transactionListing.map((item, key) =>
                            <this.RenderRow key={key} record={item} />
                        )}

                        {transactionListing == "404" && <NoContent />}
                        {!transactionListing && <ActivityLoader />}


                    </div>
                </div>
            </div>
        </div>

        )

    }
}

export default LocationPerformance;
