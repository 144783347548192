import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
// import TitleValueCard from '../../../Components/Common/TitleValueCard'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete';
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import ConfigurationContext, { ConfigurationConsumer } from '../../../Context/ConfigurationContext'
import dictionary from '../../../utils/dictionary'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import PopImportStreets from '../../../Components/PopImportStreets'
import PopCreatePlexPicture from '../../../Components/PlexApps/PopPlexPictures'
import ajaxPlex from '../../../utils/remote/ajaxPlex'
import PopViewImage from '../../../Components/PopViewImage'
import PopViewPlexImage from '../../../Components/PopViewPlexImage'
const eformats = dictionary._exportConfigBtnFormats

class ConfigPlexApps extends React.Component {
    static contextType = ConfigurationContext;
    state = {
        userId: functions.sessionGuard(),
        pictureList: false,
        pictureId: false,
        pictureRecord: false,
        toast: '',

        modal: false,
        imageView: false,
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }


    onConfirmAddPicture = async (uploadProfilePicture) => {
        const { getPlexPictures } = this.context;

        if (uploadProfilePicture) {
            const server_response = await ajaxPlex.addPlexPicture(uploadProfilePicture);
            if (server_response.status === 'OK') {

                $("#modal_plex_picture").modal('hide');
                this.setState({
                    toast: false,
                }, () =>
                    this.setState({
                        modal: false,
                        toast: <Toast message={server_response.details.message} />,
                    },
                        () => getPlexPictures()))
            } else {
                // $("#modal_plex_picture").modal('hide');
                this.setState({
                    toast: false,
                    plexErrorMessage: false
                }, () =>
                    this.setState({
                        toast: <Toast message={server_response.details.message} type={2} />,
                        plexErrorMessage: server_response.details.message

                    }
                    ))
            }
        }

    }


    onClickAddPictures = () => {
        this.resetToast();

        // const { getUnUsedClampTools, unUsedClampToolList
        // } = this.context;
        // getUnUsedClampTools();

        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopCreatePlexPicture
                    title="Add a plex picture"
                    reportandclamp="true"
                    plexErrorMessage={this.state.plexErrorMessage}
                    // unUsedClampToolList={unUsedClampToolList}
                    // onReload={this.listWrongParkingTransactions}
                    onConfirm={this.onConfirmAddPicture} />
            }, () => functions.openPopup("modal_plex_picture"))
        })
    }

    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAddPictures}>
                    <i className="fe fe-plus-circle mr-1"></i> Add
                </button>

                {/* <button className="btn ripple btn-primary" onClick={this.onClickImportStreets}>
                    <i className="fe fe-plus-circle mr-1"></i> Import
                </button> */}
            </>
        )
    }

    onClickViewPhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewPlexImage image={record} />
            }, () => {
                $("#modal_view_plex_image").modal('show');
            })
        })

    }

    onClickDelete = () => {
        // if(this.state.streetRecord)
        // {
        //     $("#modal_delete").modal('show')
        // }
    }

    RenderPictures = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const pictureList = props.plexPicturesList
                    return (
                        <div className="table-responsive allView-config border-top">

                            {(pictureList === "404") && <NoContent />}

                            {pictureList && Array.isArray(pictureList) &&
                                <table id="config-puctures" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            {/* <th>Picture Name</th> */}
                                            <th>Picture Image</th>
                                            <th>Created at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pictureList && pictureList !== "404" &&
                                            pictureList.map((item, key) =>
                                                <tr
                                                    id={"st_" + item.picture_id}
                                                    key={key}
                                                    className={`${this.state.pictureId == item.picture_id ? 'flag-row' : ''}`}
                                                    onClick={() => this.onSelectRecord(item.picture_id, item)}
                                                >
                                                    <td>{key + 1}</td>
                                                    {/* <td>{item.picture_name}</td> */}
                                                    {/* <td>{item.profile_picture}</td> */}
                                                    <td className="pointer">
                                                        <div className="main-img-user">
                                                            <img alt="avatar" onClick={() => this.onClickViewPhoto(item)} src={item?.profile_picture?.file_path} />
                                                        </div>
                                                    </td>
                                                    {/* <td>{item.total_locations.total_c}</td> */}
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>

                                </table>
                            }

                            {!pictureList && <ActivityLoader />}
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }


    RenderPictureCount = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const countAlPictures = props.totalPictures
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        Total Plex pictures <span className="badge badge-light ml-2">{countAlPictures}</span>
                                    </button>

                                    <button className="btn ripple btn-light btn-sm" onClick={this.context.getPlexPictures}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                </div>
                            </div>
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }


    onConfirmDelete = async () => {
        const { getStreets } = this.context;
        if (this.state.streetId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteBranch(this.state.streetId, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    // functions.hide('br_' + this.state.streetId);
                    //Refresh list
                    getStreets()
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }

    onSelectRecord = (streetId, streetRecord) => {
        this.setState({
            streetId: streetId,
            streetRecord: streetRecord,
            toast: ''
        })
    }

    render() {
        return (
            <div className="page">
                <WarnDelete
                    title="Delete Branch?"
                    description="Make sure this branch has not puctures and locations under it"
                    onConfirm={() => this.onConfirmDelete()} />



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Plex Pictures Configurations"
                            previousPageTitle="Configurations"
                            back="/config"
                            buttons={<this.buttons />}
                            options={{
                                "delete": () => this.onClickDelete()
                            }}
                        />

                        {this.state.toast}
                        {this.state.modal}
                        {this.state.imageView}

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <div className="d-flex justify-content-between align-items-center filter-example">

                                                    <TableHeader
                                                        title="List Of all plex pictures"
                                                        subtitle="List of all pictures added for promoton"
                                                    />

                                                    <this.RenderPictureCount />
                                                </div>

                                                <this.RenderPictures />
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigPlexApps
