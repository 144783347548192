import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import Badge from '../../Components/Common/Badge'
import RoleFilters from '../../Components/Operations/RoleFilters'
import PageNav from '../../Components/Common/PageNav'
import PopAssignDevice from '../../Components/Operations/PopAssignDevice'
import TableHeader from '../../Components/Common/TableHeader'
import NoContent from '../../Components/Common/NoContent'
import dictionary from '../../utils/dictionary'

class DeviceDistribution extends React.Component {

    state={
      userId:functions.sessionGuard(),
      menuOptions:'',
      currentPage:1,
      transactionList:false,
      metaData:false,
      activeTransactionId:false,
      toast:false,
      selectedRecord:false,
      dialogueNewAssignment:false,
      dialogueDelete:false,
      filterDescription:"All users with their respective assigned devices",
      pagination:false,
      roleId:false
    }

    componentDidMount()
    {
        this.listTransactions();
        this.setState({
            dialogueNewAssignment:<PopAssignDevice/>
        })
    
    }

    componentWillUnmount()
    {
      //clearTimeout(this.timerCountSession)
    }


    listTransactions= async()=>{

            if(!this.state.roleId)
            {
                var server_response = await ajax.getUserDeviceMapping();
            }else{
                var server_response = await ajax.getRoleDeviceMapping(this.state.roleId);
            }
            if(server_response.status==="OK")
            {
                this.setState({
                    transactionList:server_response.details.content.list,
                    metaData:server_response.details.content.meta
                }, ()=>this.paginate())
            }else{
                this.setState({
                    transactionList:"404",
                    metaData:false
                })
            }

       
    }

   
    onClickRole=(role)=>{

        if(role)
        {

            this.setState({
                transactionList:false,
                metaData:false,
                filterDescription:role.role_name + ' with their respective assigned devices',
                roleId:role.role_id
            }, ()=>this.listTransactions())
        }else{
            this.setState({
                roleId:false,
                transactionList:false,
                metaData:false,
                filterDescription:'All users with their respective assigned devices',
            }, ()=>this.listTransactions())
        }
           

       
    }

    onClickAssign=()=>{
        this.setState({
            dialogueNewAssignment:false
        }, ()=>{
            this.setState({
                dialogueNewAssignment:<PopAssignDevice onConfirm={this.onConfirmAssignment}/>
            }, ()=>{
                $("#pop_assign_device").modal('show');
            })
          
        })
       
    }

    onConfirmAssignment=async(userId, deviceId)=>{
        const server_response=await ajax.setUserDevice(userId, deviceId);
        if(server_response.status==="OK")
        {
            this.setState({
                toast:<Toast message={server_response.details.message} onClose={this.refreshToast}/>,
            }, ()=>{
                this.listTransactions();
                $("#pop_assign_device").modal('hide');

            })
        }else{
            this.setState({
                toast:<Toast type={2} message={server_response.details.message} onClose={this.refreshToast}/>
            }, ()=>{
                $("#pop_assign_device").modal('hide');
            })
        }
      
    }





    onSelectRecord=(transactionId, record)=>{
        this.setState({
            activeTransactionId:transactionId, 
            selectedRecord:record,
            toast:false
        })
    }


    buttons=()=>{
        return(
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAssign}>
                    <i className="fas fa-edit"></i> Assign Device
                </button>
                
              </>
            )
      }

      refreshToast=()=>{
          this.setState({
              toast:false
          })
      }


      customHeaderOptions=()=>{
        return(
            <>
                <a className="dropdown-item pointer">
                    <i className="fas fa-upload mr-2"></i>Import Excel Allocations
                </a>
                <a className="dropdown-item pointer">
                    <i className="fas fa-file-excel mr-2"></i>Download Excel Template
                </a>
                <a className="dropdown-item pointer" onClick={this.onClickRemoveAllocation}>
                    <i className="fe fe-trash-2 mr-2"></i>Return Selected Device
                </a>
                
              </>
            )
      }

      onClickRemoveAllocation=()=>{
        if(this.state.activeTransactionId)
        {
            this.setState({
                dialogueDelete:false
            }, ()=>{
                this.setState({
                    dialogueDelete: <WarnDelete
                    title="Return Device?"
                    description="You are about to mark selected device as returned!"
                    buttonLabel="Mark Returned"
                    icon="fas fa-undo"
                    onConfirm={()=>this.onConfirmReturnDevice()}/>
                }, ()=>{
                    $("#modal_delete").modal('show');
                })

            }) 
        }
      }

      onClickReturnDevice=(id)=>{
        this.setState({
            dialogueDelete:false,
            activeTransactionId:id
        }, ()=>{
            this.setState({
                dialogueDelete: <WarnDelete
                title="Return Device?"
                description="You are about to mark selected device as returned!"
                buttonLabel="Mark Returned"
                icon="fas fa-undo"
                onConfirm={()=>this.onConfirmReturnDevice()}/>
            }, ()=>{
                $("#modal_delete").modal('show');
            })

        }) 
      }

      onConfirmReturnDevice=async()=>{

        const server_response=await ajax.returnDevice(this.state.activeTransactionId);
        if(server_response.status==="OK")
        {
            this.setState({
                activeTransactionId:false,
                toast:<Toast message={server_response.details.message} onClose={this.refreshToast}/>

            });
            this.listTransactions();

        }else{
            this.setState({
                activeTransactionId:false,
                toast:<Toast type={2} message={server_response.details.message} onClose={this.refreshToast}/>
            });
        }
        $("#modal_delete").modal('hide');
           
      }






//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.currentPage}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================



   
    render(){
        const transactionList=this.state.transactionList;
          return (
            <div className="page">
            {this.state.sessionPreview}
            {this.state.dialogueDelete}
           


            <LeftSideBar/>
            
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Device Distribution"
                    previousPageTitle="Operations"
                    options={{
                        "customOptions":<this.customHeaderOptions/>
                    }}
                    buttons={<this.buttons/>}
                    back="/operations"
                    />
                  
                  {this.state.toast}
                  {this.state.dialogueNewAssignment}

                    
        <div className="row">
            <div className="col-sm-12 col-xl-12 col-lg-12">
                <div className="card custom-card">
                    <div className="card-body">
                        {/* <div>
                            <h6 className="card-title mb-1">User Device Mapping</h6>
                            <p>{this.state.filterDescription}</p>
                        </div> */}

                        <div className="d-flex justify-content-between align-items-center filter-example">

                        <TableHeader
                            title="User Device Mapping"
                            subtitle={this.state.filterDescription}
                        />


                       <RoleFilters onClick={this.onClickRole}/>

                       </div>


                        <div className="table-responsive allView-config border-top">

                        {(transactionList === "404") && <NoContent />}

                        {transactionList && Array.isArray(transactionList) &&
                            <table className="table text-nowrap mb-0 table-hover datatable" id="table-device-dist" export-format={dictionary._exportBtnFormats}>
                                <thead>
                                    <tr>
                                        <th>NO.</th>
                                        <th>User</th>
                                        <th>Device No.</th>
                                        <th>Tag Number</th>
                                        <th>Phone Number</th>
                                        <th>Distribution Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>

                                {
                                    transactionList && transactionList!=="404" &&
                                        transactionList.map((item, key)=>
                                       
                                            <tr 
                                                className={`pointer ${this.state.activeTransactionId===item.allocation_id?'flag-row':''}`}
                                                onClick={()=>this.onSelectRecord(item.allocation_id, item)}
                                                key={key}
                                            >
                                                <td>{key+1}</td>
                                                <td>
                                                    {item.user_info.first_name + ' ' + item.user_info.last_name} <Badge value={item.user_info.user_role.role_name} type={3}/>
                                                </td>
                                                
                                                    <td>{item.device_info.device_number}</td>
                                                    <td>{item.device_info.tag_number}</td>
                                                    <td>{item.device_info.phone_number}</td>
                                                    <td>{item.created_at.long_date}</td>
                                                    <td>
                                                    <a onClick={()=>this.onClickReturnDevice(item.allocation_id)}
                                                        className="btn ripple btn-danger btn-sm text-white" 
                                                        data-toggle="tooltip" data-original-title="Return Device" title="Return Device">
                                                        <i className="fas fa-undo"></i>
                                                    </a>
                                            </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
    }
                            {/* {transactionList && this.state.pagination} */}



            

                            {!transactionList && <ActivityLoader/>}


                        </div>
                    </div>
                </div>
            </div>
        </div>
       





                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DeviceDistribution
