import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import PopNewHoliday from '../../../Components/Holiday/PopNewHoliday'
import PopNewVehicleType from '../../../Components/VehicleType/PopNewVehicleType'
import PopEditVehicleType from '../../../Components/VehicleType/PopEditVehicleType'

class ConfigVehicleTypes extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewRecord: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.getVehicleTypes();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: false
            }, () => {
                this.setState({
                    transactionList: server_response.details.content
                })
            })

        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewRecord: false
        }, () => {
            this.setState({
                mdlNewRecord: <PopNewVehicleType
                    onConfirm={this.onConfirmNewRecord} />
            }, () => {
                functions.openPopup("modal_new_car_type");
            })
        })
    }


    onClickEdit = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlNewRecord: false
            }, () => {
                this.setState({
                    mdlNewRecord: <PopEditVehicleType item={this.state.transactionRecord}
                        onConfirm={this.onConfirmEditRecord} />
                }, () => {
                    functions.openPopup("modal_edit_car_type");
                })
            })
        }else{
            this.setState({
                toast: <Toast type={2} message={"Select record and try again"} />
            })
        }
    }


    onConfirmNewRecord = async (type_name, number_of_slots) => {
        const server_response = await ajax.createVehicleType(type_name, number_of_slots);

        if (server_response.status === "OK") {
            functions.closePopup("modal_new_car_type");
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            functions.closePopup("modal_new_car_type");
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Delete public holiday?"
                        description="Are you sure you want to delete this public holiday?"
                        onConfirm={() => this.onConfirmDelete()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        }
    }

    onConfirmDelete = async () => {

        if (this.state.transactionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deletePublicHoliday(this.state.transactionId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.transactionId);
                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }



    onConfirmEditRecord = async (id, type_name, number_of_slots) => {
        const server_response = await ajax.updateVehicleType(id, type_name, number_of_slots);

        if (server_response.status === "OK") {
            functions.closePopup("modal_edit_car_type");
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            functions.closePopup("modal_edit_car_type");
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }



    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Vehicle Type
                </button>

            </>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Vehicle Type
                </a>
                <a className="dropdown-item" onClick={this.onClickEdit}>
                    <i className="fas fa-pencil-alt mr-2"></i>Edit Vehicle Type
                </a>
                {/* <a className="dropdown-item" onClick={this.onClickDelete}>
                    <i className="fe fe-trash-2 mr-2"></i>Delete Sticker Type
                </a> */}

            </>
        )
    }


    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlNewRecord}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Vehicle Types"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                <div>
                                                    <h6 className="card-title mb-1">Car Types</h6>
                                                    <p className="card-description">Create vehicle/car types depending on the number of slots they occupy. These relate to vehicle sticker types.  </p>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table text-nowrap mb-0 table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Car Type</th>
                                                                <th>Number of slots</th>
        
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transactionList && transactionList !== "404" &&
                                                                transactionList.map((item, key) =>
                                                                    <tr
                                                                        id={"br_" + item.holiday_id}
                                                                        key={key}
                                                                        className={`${this.state.transactionId == item.type_id ? 'flag-row' : ''}`}
                                                                        onClick={() => this.onSelectTransaction(item.type_id, item)}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{item.type_name}</td>
                                                                        <td>{item.slots}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        </tbody>

                                                    </table>
                                                    {!transactionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigVehicleTypes
