import React, { Component } from 'react';
import CashierContext from '../../../Context/CashierContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import { RenderSecure } from '../../../utils/script/RenderSecure';
import ActivityLoader from '../../Common/ActivityLoader';
import Toast from '../../Common/Toast';
import WarnSuccess from '../../Common/WarnSuccess';
import PopChequePayment from '../../Payments/PopChequePayment';
import PopCreditCardPayment from '../../Payments/PopCreditCardPayment';
import PopRTJSPayment from '../../Payments/PopRTJSPayment';
import PopWhiteListPayment from '../../Payments/PopWhiteListPayment';
import PopDatedPayment from '../../PopDatedPayment';
import PopOfficePayment from '../../PopOfficePayment';
import PopVehicleListing from '../../Vehicle/VehicleListing/PopVehicleListing';
import PopVehicleEntryOption from './PopVehicleEntryOption';

class PaymentMethods extends Component {
    static contextType = CashierContext
    state={
        whiteList:false,
        paymentModes:false,
        toast: false,
        intBalance:0,
        info: '',
        officePayment: false,
        receipt: false,
        plateNumber:"",
        vehicleInfo:this.props.vehicleInfo,
        modelListOption:false
    }

    componentDidMount() {
        this.setState({
            intBalance:this.state.vehicleInfo.balance.amount,
            plateNumber:this.state.vehicleInfo.plate_number
        })
    }
    


  


    onClickPay=(modeCode)=>{
        if(this.state.intBalance<=0)
        {
            
            this.setState({
                toast: <Toast message={"Vehicle has no outstanding balance"} type={2} onClose={this.refreshToast} />
            })

            return false;
        }
        switch(modeCode)
        {
            case "CASH":

                this.onClickCashPayment()

            break;
            case "CHEQUE":
                //this.onClickChequePayment()
                this.showListOption(this.onClickChequePayment);
            break;
            case "RTJS":
                //this.onClickRTJSPayment();
                this.showListOption(this.onClickRTJSPayment);

            break;
            case "CC":
                this.showListOption(this.onClickCreditCard);
            break;
            default:

                this.setState({
                    toast: <Toast message={"MODECODE: "+ modeCode + " - Payment Mode not supported. Please contact support for assistance"} type={1} onClose={this.refreshToast} />
                })

            break;

        }//end switch
    }


    refreshToast = () => {
        this.setState({
            toast: false
        })
    }


    onClickCashPayment = () => {

        if(!this.state.whiteList)
        {


            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                    officePayment: <PopOfficePayment
                        onConfirm={this.setResponse}
                        balance={this.state.intBalance}
                        plateNumber={this.state.plateNumber}
                        vehicleInfo = {this.props.vehicleInfo}
                    />
                }, () => {
                    functions.openPopup("modal_office_payment")
                })
    
            })

        }else{
            this.onClickPayWhiteList()
        }
     
       

    }


    onClickDatedPayment = () => {

        if(!this.state.whiteList)
        {


            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                    officePayment: <PopDatedPayment
                        onConfirm={this.setResponse}
                        balance={this.state.intBalance}
                        plateNumber={this.state.plateNumber}
                    />
                }, () => {
                    functions.openPopup("modal_dated_payment")
                })
    
            })

        }else{
            this.onClickPayWhiteList()
        }
     
       

    }


    onClickPayWhiteList=()=>{
        if(this.state.vehicleInfo.white_list)
        {
            const whiteList=this.state.vehicleInfo.white_list;
            if(whiteList.status.status_id*1==1)
            {
                this.setState({
                    officePayment: false,
                    whiteList:whiteList
                }, () => {
        
                    this.setState({
                        officePayment: <PopWhiteListPayment
                            onConfirm={this.setResponse}
                            balance={this.state.intBalance}
                            plateNumber={this.state.plateNumber}
                            whiteList={whiteList}
                        />
                    }, () => {
                        functions.openPopup("modal_white_list_office_payment")
                    })
        
                })
            }
        }

    }



    onClickChequePayment = (listId) => {

        // if(!this.state.whiteList){

            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                        officePayment: <PopChequePayment
                        onConfirm={this.setResponse}
                        balance={this.state.balance}
                        plateNumber={this.state.plateNumber}
                        vehicleInfo={this.state.vehicleInfo}
                        listId={listId}
                    />
                }, () => {
                    functions.openPopup("modal_cheque_payment")

                })
    
            })

        // }else{
        //     this.onClickPayWhiteList()
        // }
     
       

    }



    onClickRTJSPayment = (listId) => {

        // if(!this.state.whiteList){


            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                        officePayment: <PopRTJSPayment
                        onConfirm={this.setResponse}
                        balance={this.state.balance}
                        plateNumber={this.state.plateNumber}
                        vehicleInfo={this.state.vehicleInfo}
                        listId={listId}

                    />
                }, () => {
                    functions.openPopup("modal_rtjs_payment")
                })
    
            })

        // }else{
        //     this.onClickPayWhiteList()
        // }
     
       

    }


    setResponse = (response) => {
        this.setState({
            info: response
        }, ()=>{
            this.props.refresh(this.state.plateNumber);
        })
    }



    onSelectList=(list_id, onSelect)=>{
        functions.closePopup("modal_vehicle_listing_payment");
        onSelect(list_id);
    }



    onClickImportFromList=(selectionCallBack)=>{
        functions.closePopup("modal_vehicle_entry_option");
        this.setState({
            modelListOption:false
        }, ()=>{
            this.setState({
                modelListOption:<PopVehicleListing 
                description="Which list are you importing from ?"
                    id="modal_vehicle_listing_payment" 
                    onSelect={this.onSelectList}
                    callBack={selectionCallBack}
                    />
            }, ()=>{
                functions.openPopup('modal_vehicle_listing_payment')
            })
        })
       
    }


    onClickManualEntry=(selectionCallBack)=>{
        functions.closePopup("modal_vehicle_entry_option");
        selectionCallBack();
    }


    showListOption=(selectionCallBack)=>{
        this.setState({
            modelListOption:<PopVehicleEntryOption 
                onSelectManual={()=>this.onClickManualEntry(selectionCallBack)} 
                title="Vehicle Wizard - New payment"
                onSelectImport={()=>this.onClickImportFromList(selectionCallBack)}
                />
        }, ()=>{
            functions.openPopup("modal_vehicle_entry_option")
        })
    }





    onClickCreditCard = (listId) => {

        // if(!this.state.whiteList){

            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                        officePayment: <PopCreditCardPayment
                        onConfirm={this.showPaymentCardLink}
                        balance={this.state.intBalance}
                        plateNumber={this.state.plateNumber}
                        vehicleInfo={this.state.vehicleInfo}
                        listId={listId}
                    />
                }, () => {
                    functions.openPopup("modal_cc_payment")

                })
    
            })

        // }else{
        //     this.onClickPayWhiteList()
        // }
     
       

    }

    showPaymentCardLink=(response)=>{
        functions.closePopup("modal_cc_payment");

        this.setState({
            officePayment: false
        }, () => {

            this.setState({
                    officePayment: <WarnSuccess
                    title="Card Payment Initialized"
                    description={response.message}
                    customLink={<a target="_blank" className="btn ripple btn-success pd-x-25" href={response.content.link}>Go to Link</a>}
                />
            }, () => {
                functions.openPopup("pop_success")

            })

        })

    }


    render() {
        const paymentModes=this.context.paymentModes


        return (
        <>
        {this.state.modelListOption}
            <div className="row">
                 {this.state.toast}
            <div className="col-12">
                <div className="card custom-card">
                    <div className="row">
                        {!paymentModes && <ActivityLoader/>}

                        {Array.isArray(paymentModes) && paymentModes.map((item, key)=>
                        <div key={key} className="col-6 pr-0 pl-0 border-right border-bottom hot-tile">
                            <div className="card-body text-center" onClick={()=>this.onClickPay(item.code)}>
                                <h6 className="mb-0">{item.mode_name}</h6>
                                <p className="mt-2 text-muted">

                                   {item.code=="CASH" && <i className="far fa-money-bill-alt" style={{fontSize:"25px"}}></i> }
                                   {item.code=="CHEQUE" && <i className="fas fa-bookmark" style={{fontSize:"25px"}}></i> }
                                   {item.code=="RTJS" && <i className="fas fa-bookmark" style={{fontSize:"25px"}}></i> }
                                   {item.code=="CC" && <i className="fas fa-credit-card" style={{fontSize:"25px"}}></i> }

                                </p>
                           
                            </div>
                        </div>)}

                        <RenderSecure code="C-PAY-DATED-01">
                            <div className="col-6 pr-0 pl-0 border-right border-bottom hot-tile">
                                <div className="card-body text-center" onClick={this.onClickDatedPayment}>
                                    <h6 className="mb-0">Dated Payment</h6>
                                    <p className="mt-2 text-muted">

                                    <i className="fa fa-calendar-alt" style={{fontSize:"25px"}}></i>
                                    
                                    </p>
                            
                                </div>
                            </div>
                        </RenderSecure>

                            {paymentModes=="404" && <div className="col-12 bg-warning">
                                    <span>Unable to handle payments at this time. For more information please contact the System Admin</span>
                                    </div>
                                    }



                    </div>
                </div>
            



            </div>
        </div>
        {this.state.officePayment}
        <div className="row">
            {this.state.info }
        </div>
                   
        </>
        );
    }
}

export default PaymentMethods;