import React, { Component } from 'react';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader';
import Toast from '../../Common/Toast';
import WarnSuccess from '../../Common/WarnSuccess';
import PopCreditCardPayment from '../../Payments/PopCreditCardPayment';
import PopRTJSPayment from '../../Payments/PopRTJSPayment';
import PopVehicleListing from '../../Vehicle/VehicleListing/PopVehicleListing';
import PopCashPayment from './PayReservation/PopCashPayment';
import PopChequePayment from './PayReservation/PopChequePayment';
import PopRTGSPayment from './PayReservation/PopRTGSPayment';
import PopVehicleEntryOption from './PopVehicleEntryOption';

class ReservationPaymentMethods extends Component {

    state={
        whiteList:false,
        paymentModes:false,
        toast: false,
        info: '',
        officePayment: false,
        receipt: false,
        modelListOption:false,
        invoiceRecord:false
    }

    componentDidMount() {
       
        this.getPaymentModes();
    }
    


    getPaymentModes = async () => {
        const server_response = await ajax.getOfficePaymentModes()
        if (server_response.status === "OK") {
            this.setState({
                paymentModes: server_response.details.content
            })
        }
    }


    onClickPay=(modeCode)=>{
        
        const invoice = this.props.onClickPay();
        if(!invoice){
            return false;
        }

        this.setState({
            invoiceRecord:invoice
        })

        if(invoice.balance.amount<=0){
            this.setState({
                toast: <Toast type={2} message="Invoice has no outstanding balance" onClose={this.refreshToast}/>
            })

            return false;
        }

        switch(modeCode)
        {
            case "CASH":

                this.onClickCashPayment()

            break;
            case "CHEQUE":
                this.onClickChequePayment()
            break;
            case "RTJS":
                this.onClickRTJSPayment();
            break;
            case "CC":
                // this.showListOption(this.onClickCreditCard);
                alert("Coming soon")
            break;
            default:

                this.setState({
                    toast: <Toast message={"MODECODE: "+ modeCode + " - Payment Mode not supported. Please contact support for assistance"} type={1} onClose={this.refreshToast} />
                })

            break;

        }//end switch
    }


    refreshToast = () => {
        this.setState({
            toast: false
        })
    }


    onClickCashPayment = () => {

       
            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                    officePayment: <PopCashPayment
                        onConfirm={this.setResponse}
                        invoiceId={this.state.invoiceRecord.invoice.id}
                        invoice={this.state.invoiceRecord}
                    />
                }, () => {
                    functions.openPopup("modal_reserve_cash_payment")
                })
    
            })

      
       

    }




    onClickChequePayment = () => {


            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                        officePayment: <PopChequePayment
                        onConfirm={this.setResponse}
                        invoiceId={this.state.invoiceRecord.invoice.id}
                        invoice={this.state.invoiceRecord}
                    />
                }, () => {
                    functions.openPopup("modal_cheque_payment")

                })
    
            })

    }



    onClickRTJSPayment = () => {

        this.setState({
            officePayment: false
        }, () => {

            this.setState({
                    officePayment: <PopRTGSPayment
                    onConfirm={this.setResponse}
                    invoiceId={this.state.invoiceRecord.invoice.id}
                    invoice={this.state.invoiceRecord}
                />
            }, () => {
                functions.openPopup("modal_rtgs_payment")

            })

        })
           


    }


    setResponse = (response) => {
        this.setState({
            info: response
        }, ()=>{
           this.props.refresh();
        })
    }



    onSelectList=(list_id, onSelect)=>{
        functions.closePopup("modal_vehicle_listing_payment");
        onSelect(list_id);
    }



    onClickImportFromList=(selectionCallBack)=>{
        functions.closePopup("modal_vehicle_entry_option");
        this.setState({
            modelListOption:false
        }, ()=>{
            this.setState({
                modelListOption:<PopVehicleListing 
                description="Which list are you importing from ?"
                    id="modal_vehicle_listing_payment" 
                    onSelect={this.onSelectList}
                    callBack={selectionCallBack}
                    />
            }, ()=>{
                functions.openPopup('modal_vehicle_listing_payment')
            })
        })
       
    }


    onClickManualEntry=(selectionCallBack)=>{
        functions.closePopup("modal_vehicle_entry_option");
        selectionCallBack();
    }


    showListOption=(selectionCallBack)=>{
        this.setState({
            modelListOption:<PopVehicleEntryOption 
                onSelectManual={()=>this.onClickManualEntry(selectionCallBack)} 
                title="Vehicle Wizard - New payment"
                onSelectImport={()=>this.onClickImportFromList(selectionCallBack)}
                />
        }, ()=>{
            functions.openPopup("modal_vehicle_entry_option")
        })
    }





    onClickCreditCard = (listId) => {

      
            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                        officePayment: <PopCreditCardPayment
                        onConfirm={this.showPaymentCardLink}
                        balance={this.state.intBalance}
                        plateNumber={this.state.plateNumber}
                        vehicleInfo={this.state.vehicleInfo}
                        listId={listId}
                    />
                }, () => {
                    functions.openPopup("modal_cc_payment")

                })
    
            })

    

    }

    showPaymentCardLink=(response)=>{
        functions.closePopup("modal_cc_payment");

        this.setState({
            officePayment: false
        }, () => {

            this.setState({
                    officePayment: <WarnSuccess
                    title="Card Payment Initialized"
                    description={response.message}
                    customLink={<a target="_blank" className="btn ripple btn-success pd-x-25" href={response.content.link}>Go to Link</a>}
                />
            }, () => {
                functions.openPopup("pop_success")

            })

        })

    }


    render() {
        const paymentModes=this.state.paymentModes


        return (
        <>
        {this.state.modelListOption}
        {this.state.toast}
            <div className="row">
                
            <div className="col-12">
                <div className="card custom-card">
                    <div className="row">
                        {!paymentModes && <ActivityLoader/>}
                        {paymentModes && <>

                        {paymentModes && paymentModes.map((item, key)=>
                        <div key={key} className="col-6 pr-0 pl-0 border-right border-bottom hot-tile">
                            <div className="card-body text-center" onClick={()=>this.onClickPay(item.code)}>
                                <h6 className="mb-0">{item.mode_name}</h6>
                                <p className="mt-2 text-muted">

                                   {item.code=="CASH" && <i className="far fa-money-bill-alt" style={{fontSize:"25px"}}></i> }
                                   {item.code=="CHEQUE" && <i className="fas fa-bookmark" style={{fontSize:"25px"}}></i> }
                                   {item.code=="RTJS" && <i className="fas fa-bookmark" style={{fontSize:"25px"}}></i> }
                                   {item.code=="CC" && <i className="fas fa-credit-card" style={{fontSize:"25px"}}></i> }


                                </p>
                           
                            </div>
                        </div>)}

                      

                    </>}


                    </div>
                </div>
            



            </div>
        </div>
        {this.state.officePayment}
        <div className="row">
            {this.state.info }
        </div>
                   
        </>
        );
    }
}

export default ReservationPaymentMethods;