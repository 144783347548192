import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async listEnforcementAnalysisByBranch(data)//POST
    {

        let response = await apiCall("enforcement/analysis/branch", data)

        return response;

    },
    async listContraventionAnalysisByBranch(page)//POST
    {
        let data = {
            page: page
        }
        try {
            let response = await fetch(apiHost + 'branch/contravention/analysis',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listViolationPerformance(data)//POST
    {
       let response = await apiCall("enforcement/analysis/violation-type", data);
       return response;

    },




}