import React, { useEffect, useState } from 'react'
import ajax from '../../../utils/ajax';
import dictionary from '../../../utils/dictionary';

const StickerStatus=(props)=> {

  const [vehicleID, setVehicleID] = useState(props.vehicleID)
  const [balance, setBalance] = useState("No Active Sticker")
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(()=>{
    getBalance();
  }, [refreshCounter])

  
  useEffect(()=>{

    setTimeout(() => {
        setRefreshCounter(refreshCounter+1)
    }, dictionary._refreshRate);

}, [refreshCounter])
  

  

  const getBalance = async () => {

    const server_response = await ajax.getVehicleActiveSticker(vehicleID);
    if (server_response.status === "OK") {
      
        setBalance("Valid till " + server_response.details.content.expiry_date.when)
      
    }
  }



    return (

      <div className="list-group-item  d-flex border-right-0 border-left-0 border-top-0">
        <i className="fas fa-tags"></i>
        <p className="ml-3 mb-0">Sticker </p>
        <span className="ml-auto font-weight-bold">{balance}</span>
      </div>
    )
  }


export default StickerStatus
