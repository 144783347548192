import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import functions from '../../../../utils/functions';
import PopTicketSaleCash from './PopTicketSaleCash';

class SellTicket extends Component {

    state={
        mdl:false
    }

    handleClick=()=>{
        this.setState({
            mdl:false
        }, ()=>{
            this.setState({
                mdl:<PopTicketSaleCash {...this.props}/>
            }, ()=>{
                functions.openPopup("modal_ticket_payment")
            })
           
        })
    }


    render() {
        return (
            <>
            {this.state.mdl}
            <Link to="#" className="card-link" onClick={this.handleClick}>
            <i className="fas fa-tag tx-success mr-1"/>
            Sell Virtual Ticket
        </Link>
        </>
        );
    }
}

export default SellTicket;