import React, { useContext } from 'react'
import PageHeader from '../../../Components/Common/PageHeader'
import { Link } from 'react-router-dom';
import AuthContext, { AuthConsumer } from '../../../Context/AuthContext'
import { RenderSecure } from '../../../utils/script/RenderSecure'
import PageContainer from '../../../Components/Layout/PageContainer'
import TaxLastMonth from '../../../Components/Cards/Efris/TaxLastMonth'
import TaxCurrentMonth from '../../../Components/Cards/Efris/TaxCurrentMonth'
import CreditNotesLastMonth from '../../../Components/Cards/Efris/CreditNotesLastMonth'
import CreditNotesCurrentMonth from '../../../Components/Cards/Efris/CreditNotesCurrentMonth'
import EfrisTransactions from '../../../Components/Finance/Efris/EfrisTransactions'
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext'
import useStateCallback from '../../../utils/customHooks/useStateCallback';
import PopNewCreditNote from '../../../Components/Finance/Efris/PopNewCreditNote';
import functions from '../../../utils/functions';
import PopCancelCreditNote from '../../../Components/Finance/Efris/PopCancelCreditNote';
import EfrisHistoryTransactions from '../../../Components/Finance/Efris/History/EfrisHistoryTransactions';
import EfrisHistoryContext from '../../../Context/Efris/EfrisHistoryContext';
import EMonthlyTarget from '../../../Components/Cards/Efris/Config/EMonthlyTarget';
import EDailyTarget from '../../../Components/Cards/Efris/Config/EDailyTarget';
import SavedLastMonth from '../../../Components/Cards/Efris/Config/SavedLastMonth';
import SavedThisMonth from '../../../Components/Cards/Efris/Config/SavedThisMonth';
import PopEfrisSwitch from '../../../Components/Finance/Efris/History/PopEfrisSwitch';
import PopSetMonthlyTarget from '../../../Components/Finance/Efris/History/PopSetMonthlyTarget';
import PopSetDailyTarget from '../../../Components/Finance/Efris/History/PopSetDailyTarget';
import PopNewEfrisWhitelist from '../../../Components/Finance/Efris/History/PopNewEfrisWhitelist';

const EfrisConfiguration =(props)=>{

  const {permissionList} = useContext(AuthContext);
  const {refreshTax, taxConfig} = useContext(EfrisHistoryContext);
  const {getCurrentMonthTax, getDailyTax} = useContext(FiscalReceiptContext);
  const [modal, setModal] = useStateCallback(false);

  const refresh = () =>{
    refreshTax();
    getCurrentMonthTax();
    getDailyTax();
  }

  const handleTurnOn=()=>{
    setModal(false, ()=>setModal(<PopEfrisSwitch value="ON"/>, ()=>functions.openPopup("efris_switch")))

  }

  const handleTurnOff=()=>{
    setModal(false, ()=>setModal(<PopEfrisSwitch value="OFF"/>, ()=>functions.openPopup("efris_switch")))
  }

  const handleMonthlyTarget=()=>{
    setModal(false, ()=>setModal(<PopSetMonthlyTarget />, ()=>functions.openPopup("modal-monthly-target")))
  }

  const handleDailyTarget=()=>{
    setModal(false, ()=>setModal(<PopSetDailyTarget/>, ()=>functions.openPopup("modal-daily-target")))
  }

  const handleWhiteList=()=>{
    setModal(false, ()=>setModal(<PopNewEfrisWhitelist/>, ()=>functions.openPopup("modal-efris-whitelist")))
  }

  const customHeaderOptions = () => {
    return (
      <>

          {taxConfig.status==="1" && <Link to="#" className="dropdown-item pointer" onClick={handleTurnOn}>
            <i className="fas fa-toggle-off  mr-2 text-danger tx-18"></i>Turn on saving
          </Link>}

          {taxConfig.status==="2" && <Link to="#" className="dropdown-item pointer" onClick={handleTurnOff}>
            <i className="fas fa-toggle-on  mr-2 text-success tx-18"></i>Turn off saving
          </Link>}

          <Link to="#" className="dropdown-item pointer" onClick={handleMonthlyTarget}>
            <i className="fas fa-bullseye  mr-2 text-primary tx-18"></i>Set Monthly Target
          </Link>

          <Link to="#" className="dropdown-item pointer" onClick={handleDailyTarget}>
            <i className="fas fa-bullseye mr-2 text-danger tx-18"></i>Set Daily Target
          </Link>

          <Link to="#" className="dropdown-item pointer" onClick={handleWhiteList}>
            <i className="fas fa-car mr-2 text-success tx-18"></i>Whitelist Vehicle
          </Link>
      </>

    )
  }

  const buttons = (props) => {

    return (
      <>
         <button className='btn btn-light' onClick={refresh}><i className='fa fa-redo mr-2' /> Refresh</button>

      </>
    )
  }

    return <PageContainer>
            {modal}
             <PageHeader
                    title="EFRIS Config"
                    previousPageTitle="Dashboard"
                    back="/finance/efris"
                    options={{
                      customOptions : customHeaderOptions({ permissionList:{permissionList}})
                    }}
                    buttons={buttons({permissionList:{permissionList}})}
                  />

                 <RenderSecure code="R-REVENUE-00">
                    <div className="row">
                      <EMonthlyTarget />
                      <EDailyTarget />
                      <SavedLastMonth />
                      <SavedThisMonth />
                    </div>
                  </RenderSecure>

                  <div className="row">
                    <div className="col-sm-12 col-xl-12 col-lg-12">

                        <EfrisHistoryTransactions/>
                     
                    </div>
                   
                  </div>

    </PageContainer>

  
}

export default EfrisConfiguration
