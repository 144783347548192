import React, { useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import ajaxTicket from '../utils/remote/ajaxTicket';
import { detectTable, restoreTable } from '../utils/script/customTables';

const FinanceContext = React.createContext();

export const FinanceConsumer = FinanceContext.Consumer;

export const FinanceProvider=(props)=>{


   const [recentInvoices, setRecentInvoices] = useState(false);
   const [recentStickerSales, setRecentStickerSales] = useState(false);
   const [recentTicketSales, setRecentTicketSales] = useState(false);
   const [invoiceCount, setInvoiceCount] = useState("...");
   const [monthlyRevenue, setMonthlyRevenue] = useState("...");
   const [wrongParkingRevenue, setWrongParkingRevenue] = useState("...");
   const [salesData, setSalesData] = useState(false);
   const [salesChartData, setSalesChartData] = useState(false);
   const [borderColors, setBorderColors] = useState(['rgba(113, 76, 190, 0.9)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.6)']);
   const [backgroundColors, setBackgroundColors] = useState(['rgba(113, 76, 190, 0.5)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.8)']);
   const [allCheques, setAllCheques] = useState(false);
   const [metaData, setMetaData] = useState(false);
   const [data, setData] = useState({"page":"1"})
   const [currentPage, setCurrentPage] = useState(1);
   const [invoiceListing, setInvoiceListing] = useState(false);
   const [loading, setLoading] = useState(false);
   const [chequeStatus, setChequeStatus] = useState('')
   const [chequeData, setChequeData] = useState({"page":"1", "status":''})

    
   useEffect(()=>{
       listRecentInvoices();
       listRecentStickerSales();
       listRecentTicketSales();
       countInvoices();
       monthlyParkingRevenue();
       monthlyWrongParkingRevenue();
       getMonthlySales();
   }, [])


   useEffect(()=>{
    listInvoices();
   }, [data])

   useEffect(()=>{
    // console.log(salesData)
        if(salesData)
        {
            const months = [];
            for (var i = 1; i < 13; i++) {
              months.push(salesData?.months[i]);
            }
            const chartData = { labels: months, datasets: [] };
    
            salesData.data.map((item, key) => {
              const revenue = [];
              for (var i = 1; i < 13; i++) {
                revenue.push(item.data.revenue[i]['total']);
              }
              chartData.datasets.push({
                label: item.source,
                data: revenue,
                fill: true,
                lineTension: 0.5,
                borderWidth: 2,
                backgroundColor: backgroundColors[key],
                borderColor: borderColors[key]
              })
            }
            )
    
            setSalesChartData(chartData)
        }
   }, [salesData])


   useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
    }, [currentPage])

    useEffect(()=>{
        getAllCheques();
    }, [chequeData])

   
    const listRecentInvoices = async () => {
        const server_response = await ajax.listRecentInvoices();
        if (server_response.status === "OK") {
            setRecentInvoices(server_response.details.content.list)
        } else {
            setRecentInvoices("404");
        }
    }


    const listRecentStickerSales = async () => {
        const server_response = await ajax.listRecentStickerSales();
        if (server_response.status === "OK") {

            setRecentStickerSales(server_response.details.content.list)

        } else {
            setRecentStickerSales("404");

        }
    }

    const listRecentTicketSales = async () => {
        const server_response = await ajaxTicket.monitorRecentTicketSales(1);
        if (server_response.status === "OK") {
           
            setRecentTicketSales(server_response.details.content.list)

        } else {
            setRecentTicketSales("404");
        }
    }


    const countInvoices=async()=>{

        const server_response = await ajax.countMonthlyInvoices();
        if(server_response.status==="OK")
        {
            setInvoiceCount(server_response.details.content.total_c)
        }
      }


   const monthlyParkingRevenue=async()=>{

        const server_response = await ajax.getMonthlyParkingRevenue();
        if(server_response.status==="OK")
        {
            setMonthlyRevenue(server_response.details.content.total_c)   
        }
      }


   const monthlyWrongParkingRevenue=async ()=>{

        const server_response = await ajax.getMonthlyWrongParkingRevenue();
        if(server_response.status==="OK")
        {
            setWrongParkingRevenue(server_response.details.content.total_c)
        }
      }


      const getMonthlySales = async () => {
        const server_response = await ajax.analyseAnnualRevenueCollection();
        // console.log(server_response.details.content)
        if (server_response.status === "OK") {

            setSalesData(server_response.details.content);
            

        } else {
            
            setSalesChartData("404");
            setSalesData(false);

        }
      }


      const getAllCheques = async () => {

        setAllCheques(false)
        const server_response = await ajax.listChequeRecords(chequeData);        
        if (server_response.status === "OK") {

            setAllCheques(server_response.details.content.list)
           

        } else {
            setAllCheques("404")
         
        }
    }
    
    
     const refresh=()=>{
          console.log("Refreshed")
      }






    const listInvoices = async () => {
        setInvoiceListing(false)
        const server_response = await ajax.listInvoices(data);
        if (server_response.status === "OK") {
            setInvoiceListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta)
        } else {

            setInvoiceListing("404");
        }
    }






    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());
        const server_response = await ajax.listInvoices(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setInvoiceListing(invoiceListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        const data={
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "invoice_class_id":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        }
        setData(data)
        
    }



    const filterChequeStatus = (status) => {

        const newData={
            ...chequeData,
            "status":status
        }
        setChequeData(newData)
    }







        return (
           <FinanceContext.Provider 
            value={{
                recentInvoices,
                recentStickerSales,
                recentTicketSales,
                invoiceCount,
                monthlyRevenue,
                wrongParkingRevenue,
                salesChartData,
                allCheques,
                loading,
                invoiceListing,
                metaData,
                refresh,
                getAllCheques,
                goToNextPage,
                resetData,
                filterData,
                filterChequeStatus
            }}
            >
               {props.children}
           </FinanceContext.Provider>
        );
}

export default FinanceContext;