import React, { useEffect, useState } from 'react';
import ajaxViolation from '../../utils/remote/ajaxViolation';

const TowingContext = React.createContext();

export const TowingConsumer = TowingContext.Consumer;

export const TowingProvider = (props)=> {

 
  const [allTransactions, setAllTransactions] = useState(false);

  const [data, setData] = useState({"page":"1"})
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(false);

  useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])


useEffect(()=>{
  getAllTransactions();
}, [data])


  const getAllTransactions=async()=>{
    setLoading(true)
    setAllTransactions(false)
    const server_response = await ajaxViolation.fetchTowTransactions(data);
    setLoading(false)
    if(server_response.status==="OK"){
      setAllTransactions(server_response.details.content.list)
      setMetaData(server_response.details.content.meta);
    }else{

      setAllTransactions("404");
      setMetaData("404");
    }
  }


  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);
    // restoreTable(detectTable());

    const server_response = await ajaxViolation.fetchTowTransactions(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.content.list);
        setAllTransactions(allTransactions.concat(incoming));
        setMetaData(server_response.details.content.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "page":"1",
        "plate_number":options.plateNumber,
        "status":options.status

    })
    
}


    return (
           <TowingContext.Provider value={
               {
                  allTransactions,
                  metaData,
                  loading,
                  getAllTransactions,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData
               }
               }>
               {props.children}
           </TowingContext.Provider>
        );
    
}

export default TowingContext;