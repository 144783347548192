import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import $ from 'jquery';
import VehiclePaymentHistory from '../../Components/Payments/VehiclePaymentHistory'
import VehicleInvoiceHistory from '../../Components/Invoices/VehicleInvoiceHistory'
import VehicleInvoices from '../../Components/Filters/VehicleInvoices'
import VehiclePayments from '../../Components/Filters/VehiclePayments'
import FltVehicleInfo from '../../Components/Filters/FltVehicleInfo'
import VehicleInfo from '../../Components/Vehicle/VehicleInfo'
import { Link } from 'react-router-dom'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import FltStatement from '../../Components/Filters/FltStatement'
import VehicleStatement from '../../Components/Payments/VehicleStatement'
import PopAddToVip from '../../Components/Vehicle/PopAddToVip'
import Toast from '../../Components/Common/Toast'
import PopRemoveFromVip from '../../Components/Vehicle/PopRemoveFromVip'
import PopWhiteListVehicle from '../../Components/Vehicle/PopWhiteListVehicle'
import PopValidateClamp from '../../Components/Clamps/PopValidateClamp'
import PopVehicleOpeningBalance from '../../Components/Vehicle/PopVehicleOpeningBalance'
import VehicleContext from '../../Context/VehicleContext'
import VehicleOutstandings from '../../Components/Invoices/VehicleOutstandings'
import InvoiceHistory from '../../Components/Filters/InvoiceHistory'
import PopCustomInvoicing from '../../Components/Vehicle/CustomInvoicing/PopCustomInvoicing'
import { RenderSecure } from '../../utils/script/RenderSecure'
class VehicleProfile extends React.Component {
  static contextType = VehicleContext

  state = {
    userId: functions.sessionGuard(),
    countActiveSessions: '...',
    menuOptions: '',
    currentPage: 1,
    List: false,
    metaData: false,
    invoiceView: false,
    plateNumber: false,
    currentOption: "PROFILE",
    loading: false,
    vipDialogue: false,
    vehicleInfo: false,
    toast: false,
    whiteListDialogue: false,
    clampValidation: false,
    clampStatus: "",
    mdlOpeningBalance: false,
    model: false,
    activeInvoiceId: ''
  }

  componentDidMount() {
    const { setPlateNumber } = this.context

    if (this.context.plateNumber.length == 0 && !this.props.match.params.vehicle) {
      this.props.history.push('/finance/invoices');
    } else {


      this.setState({
        plateNumber: this.props.match.params.vehicle,
        clampStatus: this.props.location?.state?.clampStatus
      }, () => setPlateNumber(this.state.plateNumber))

    }



    //this.listInvoices();
    this.setState({
      menuOptions: {
        "addInvoice": () => this.onClickAddInvoice,
        "addPayment": () => this.onClickAddPayment
      }
    })

  }




  refresh = (message) => {
    this.setState({
      loading: true,
      currentOption: 'LOADING',
      toast: message ? <Toast
        message={message}
        onClose={() => this.resetToast()} /> : false,
      currentOption: false
    }, () => {
      this.setState({
        loading: false,
        currentOption: 'PROFILE'
      })
    })
  }

  listInvoices = async () => {
    const server_response = await ajax.listInvoices(this.state.userId, this.state.currentPage);
    if (server_response.status === "OK") {
      this.setState({
        List: server_response.details.content.list,
        metaData: server_response.details.content.meta
      })
    } else {
      this.setState({
        List: "404",
        metaData: false
      })
    }
  }



  onClickAddInvoice = () => {

  }

  onClickAddPayment = () => {
    this.props.history.push({
      pathname: '/finance/new-payment',
      search: '?q=1',
      state: { vehiclePlate: this.state.plateNumber }
    })
  }

  onClickPage = (page) => {
    this.setState({
      currentPage: page,
      List: false
    }, () => this.listInvoices())
  }

  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
      this.setState({
        currentPage: this.state.currentPage + 1,
        List: false

      }, () => {
        this.listInvoices();
      })
    }

  }

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
        List: false
      }, () => {
        this.listInvoices();
      })
    }

  }

  onClickPayInvoice = (plateNumber) => {
    $("#modal_invoice").modal('hide');
    this.props.history.push({
      pathname: '/finance/new-payment',
      search: '?q=1',
      state: { vehiclePlate: plateNumber }
    })

  }


  onClickOption = (option) => {
    this.setState({
      currentOption: option
    })
  }


  buttons = () => {
    return (
      <>
        <Link to="#"
          className="btn ripple btn-warning">
          <i className="fe fe-flag"></i> Flag Vehicle
        </Link>

      </>
    )
  }

  clampStatusBadge = () => {
    const { vehicleInfo } = this.context

    const statusUI=["", "info", "", "secondary", "danger", "primary", "light", "info", "secondary", "danger", "primary", "light", "secondary", "danger", "primary", "light"]
    return (
      <>
      {!["0", "2", "7", "11"].includes(vehicleInfo.clamp_status_id) &&
          <span onClick={()=>this.onClickClampValidation(vehicleInfo.clamp_status_id)} className={`badge badge-${statusUI[vehicleInfo.clamp_status_id]} tx-12 mr-2 pointer text-bolder rounded`}>{vehicleInfo.clamping_status}</span>}
        {vehicleInfo.in_session && <span className="badge badge-success tx-14 pointer text-bold rounded"><i className='fa fa-car' /> PARKED</span>} 

      </>
    )
  }


  customHeaderOptions = () => {
    return (
      <>
        <Link to="#" className="dropdown-item pointer" onClick={this.context.getVehicle}>
          <i className="fa fa-redo text-primary mr-2"></i>Refresh Profile
        </Link>

        <RenderSecure code="C-WHITELIST-01">
          <Link to="#" className="dropdown-item pointer" onClick={this.onClickWhiteList}>
            <i className="fas fa-calendar-plus text-success mr-2"></i>White List Vehicle
          </Link>
        </RenderSecure>

        <RenderSecure code="C-VIP-01">
          {this.context.vehicleInfo.is_vip == "NO" && <Link to="#" className="dropdown-item pointer" onClick={this.onClickAddToVIP}>
            <i className="fas fa-star text-secondary mr-2"></i>Add to VIP
          </Link>}

          {this.context.vehicleInfo.is_vip == "YES" && <Link to="#" className="dropdown-item pointer" onClick={this.onClickRemoveFromVIP}>
            <i className="fas fa-star text-secondary mr-2"></i>Remove from VIP
          </Link>}
        </RenderSecure>


        {/* <Link to="#" className="dropdown-item pointer">
          <i className="fas fa-history mr-2"></i>Ticket Statement
        </Link>
        <Link to="#" className="dropdown-item pointer">
          <i className="fas fa-history mr-2"></i>Sticker History
        </Link> */}
        <RenderSecure code="C-OPENING-BAL-01">
          <Link to="#" className="dropdown-item pointer" onClick={this.onClickAddOpeningBalance}>
            <i className="fa fa-plus text-primary mr-2"></i>Add Opening Balance
          </Link>
        </RenderSecure>

        {/* <RenderSecure code="C-CUSTOM-INVOICING-01">
          <Link to="#" className="dropdown-item pointer" onClick={this.onClickCustomInvoicing}>
            <i className="fa fa-edit text-danger mr-2"></i>Custom Invoicing
          </Link>
        </RenderSecure> */}

      </>

    )
  }


  onClickAddToVIP = () => {
    this.setState({
      vipDialogue: false
    }, () => {
      this.setState({
        vipDialogue: <PopAddToVip vehicleInfo={this.context.vehicleInfo} onConfirm={this.onAddToVip} />
      }, () => {
        functions.openPopup('modal_add_to_vip')
      })
    })
  }



  onClickRemoveFromVIP = () => {
    this.setState({
      vipDialogue: false
    }, () => {
      this.setState({
        vipDialogue: <PopRemoveFromVip vehicleInfo={this.context.vehicleInfo} onConfirm={this.onRemoveFromVip} />
      }, () => {
        functions.openPopup('modal_remove_from_vip')
      })
    })
  }




  onAddToVip = async (reason, date) => {

    const server_response = await ajax.add_vehicle_to_vip(this.context.vehicleInfo.plate_number, reason, date)
    functions.closePopup('modal_add_to_vip')
    if (server_response.status === "OK") {

      this.setState({
        toast: <Toast
          message={server_response.details.message}
          onClose={() => this.resetToast()} />,
        currentOption: false
      }, () => {
        this.setState({
          currentOption: "PROFILE"
        })
      })

      this.context.getVehicle()

    } else {

      this.setState({
        toast: <Toast
          type={4}
          message={server_response.details.message}
          onClose={() => this.resetToast()} />
      })

    }
  }



  onRemoveFromVip = async () => {

    const server_response = await ajax.remove_vehicle_from_vip(this.state.plateNumber)
    functions.closePopup('modal_remove_from_vip')
    if (server_response.status === "OK") {

      this.setState({
        toast: <Toast
          message={server_response.details.message}
          onClose={() => this.resetToast()} />,
        currentOption: false
      }, () => {
        this.setState({
          currentOption: "PROFILE"
        })
      })

      this.context.getVehicle()

    } else {

      this.setState({
        toast: <Toast
          type={4}
          message={server_response.details.message}
          onClose={() => this.resetToast()} />
      })

    }

  }

  resetToast = () => {
    this.setState({
      toast: false
    })
  }


  onClickWhiteList = () => {
    this.setState({
      whiteListDialogue: false
    }, () => {
      this.setState({
        whiteListDialogue: <PopWhiteListVehicle vehicleInfo={this.context.vehicleInfo} onConfirm={this.onAddToVip} />
      }, () => {
        functions.openPopup('modal_new_white_list')
      })
    })
  }



  onClickClampValidation = (statusId) => {
    if(statusId*1!==4)
      return false;
      
    this.setState({
      clampValidation: false
    }, () => {
      this.setState({
        clampValidation: <PopValidateClamp />
      }, () => {
        functions.openPopup("validate_clamp")
      })
    })
  }


  onClickAddOpeningBalance = () => {
    const { vehicleInfo } = this.context
    this.setState({
      mdlOpeningBalance: false
    }, () => {
      this.setState({
        mdlOpeningBalance: <PopVehicleOpeningBalance vehicleInfo={vehicleInfo} />
      }, () => {
        functions.openPopup("vehicle-opening-balance")
      })
    })
  }


  onClickCustomInvoicing = () => {
    this.setState({
      model: false
    }, () => {
      this.setState({
        model: <PopCustomInvoicing plateNumber={this.context.plateNumber} activeInvoice={this.state.activeInvoiceId} />
      }, () => functions.openPopup("modal-custom-invoicing"))
    })
  }


  onSelectInvoice = (invoiceId) => {
    this.setState({
      activeInvoiceId: invoiceId
    })
  }


  render() {
    const { plateNumber, loading, vehicleInfo } = this.context
    return (
      <div className="page">
        {this.state.invoiceView}
        {this.state.vipDialogue}
        {this.state.toast}
        {this.state.whiteListDialogue}
        {this.state.clampValidation}
        {this.state.mdlOpeningBalance}
        {this.state.model}
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          {!loading && vehicleInfo && <div className="container-fluid">
            <PageHeader
              title={plateNumber}
              previousPageTitle="Vehicle Records"
              back={"/vehicles/all-vehicles"}
              options={{
                "customOptions": vehicleInfo ? <this.customHeaderOptions /> : <></>
              }}
              badge={<this.clampStatusBadge />}
              buttons={<this.buttons />}
            />

            {plateNumber.length > 0 && <div className="text-wrap">
              <div className="example">
                <div className="btn-list">
                  <FltVehicleInfo onClick={() => this.onClickOption("PROFILE")} />
                  <VehiclePayments onClick={() => this.onClickOption("PAYMENT")} />
                  <VehicleInvoices
                    onClick={() => this.onClickOption("INVOICE")}
                    plateNumber={plateNumber}
                  />
                  <FltStatement onClick={() => this.onClickOption("STATEMENT")} />
                  <InvoiceHistory
                    onClick={() => this.onClickOption("INVOICE_HISTORY")}
                    plateNumber={plateNumber}
                  />
                </div>
              </div>
            </div>}


            {this.state.plateNumber &&
              this.state.currentOption === "PROFILE" &&
              <VehicleInfo
                plateNumber={plateNumber}
                refresh={this.refresh} />}

            {this.state.plateNumber &&
              this.state.currentOption === "PAYMENT" &&
              <VehiclePaymentHistory
                plateNumber={plateNumber} />}


            {this.state.plateNumber &&
              this.state.currentOption === "INVOICE" &&
              <VehicleOutstandings
                plateNumber={plateNumber}
                onClickPay={this.onClickPayInvoice} />}


            {this.state.plateNumber &&
              this.state.currentOption === "STATEMENT" &&
              <VehicleStatement
                plateNumber={plateNumber} />}



            {this.state.plateNumber &&
              this.state.currentOption === "INVOICE_HISTORY" &&
              <VehicleInvoiceHistory
                plateNumber={plateNumber}
                onSelect={this.onSelectInvoice}
              />}




          </div>}
          {loading && <ActivityLoader />}

        </div>
        <RightSideBar />

      </div>


    );

  }
}

export default VehicleProfile
