import React from 'react'

class DashRow2 extends React.Component {

    render() {
        return (
            <div className={this.props.size}>
                <div className="card-body pinned-row text-center">
                    <p className="mb-2 text-muted">{this.props.title}</p>
                    <h3 className="mb-1 mt-1 number-font counter">{this.props.value}</h3>
                    <p className="mb-2 tx-inverse">
                        <span className={this.props.color}>
                            <i className={this.props.icon}></i>
                            {this.props.percentage}
                        </span> <span className="ml-auto">{this.props.caption}</span>
                    </p>
                </div>
            </div>
        )
    }

}

export default DashRow2