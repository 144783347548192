import React, { useContext } from "react"
import ScrollFilterBar from "../../../../Common/Filter/ScrollFilterBar";
import FilterSelectInput from "../../../../Common/Filter/FilterSelectInput";
import FilterTextInput from "../../../../Common/Filter/FilterTextInput";
import ConfigurationContext from "../../../../../Context/ConfigurationContext";
import XReportContext from "../../../../../Context/Reports/XReportContext";

const XReportFilter=(props)=>{

    const { 
            resetData,
            filterData
        } = useContext(XReportContext)

    const {locationList, conductorList} = useContext(ConfigurationContext);


    const optionLocations = [];
    if(Array.isArray(locationList)){
        locationList.forEach(element => {
            optionLocations.push({title:element.location_name + " - " + element.street.street_name, value:element.location_id})
        });
    }

    const optionConductorList = [];
    if(Array.isArray(conductorList)){
        conductorList.forEach(element=>{
            optionConductorList.push({title:element.full_name, value:element.user_id})
        })
    }



    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Parking Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Parking Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=> <FilterSelectInput 
            key={key}
            label="Location"
            options={optionLocations}
            onChange={onSet}
            name="location"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=> <FilterSelectInput 
            key={key}
            label="Conductor"
            options={optionConductorList}
            onChange={onSet}
            name="conductor"
            currentValues={currentValues}
          />,

        ],
        names:["dateFrom", "dateTo", "location", "conductor"]
    }}
/>

}

export default XReportFilter