import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import Export from '../../Components/Common/Export'
import { Link } from 'react-router-dom'
import PageNav from '../../Components/Common/PageNav'
import PopNewVehicle from '../../Components/Vehicle/PopNewVehicle'
import PopBankSlipForm from '../../Components/Payments/PopBankSlipForm'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import ajaxVendor from '../../utils/remote/ajaxVendor'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'

class ResellerStatement extends React.Component {


    state = {
        userId: functions.sessionGuard(),
        //PAGINATION
        currentPage: 1,
        transactionList: false,
        metaData: false,
        pagination: false,
        //EO PAGINATION
        filterTitle: 'Float Statement',
        filter: false,
        vendorUserId: false,
        username: this.props.match.params.username,
        mdlNewPayment: false,
        info: false

    }

    componentDidMount() {

        if (!this.props.location.state) {
            this.props.history.push('/vendor/list');
        } else {
            this.setState({
                vendorUserId: this.props.location.state.vendorUserId,
                filterTitle: this.state.username + ' ' + 'Statement'
            }, () => {
                this.listAllRecords();
            })
        }

    }




    listAllRecords = async () => {

        if (this.state.filter) {
            const filter = this.state.filter;
            var server_response = await ajaxVendor.filterVendorRecords(
                filter.dateFrom,
                filter.dateTo,
                filter.selectedValue,
                filter.keyword,
                this.state.currentPage,
                // this.state.status
            );

        } else {
            this.setState({
                transactionList: false,
                metaData: false
            })

            var server_response = await ajaxVendor.getVendorStatement(this.state.vendorUserId, this.state.currentPage);
        }

        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())
        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }
    }

    buttons = () => {
        return (
            <>
                {this.state.filter && <button className="btn ripple btn-primary" onClick={this.resetFilter}>
                    Reset
                </button>}

                {/* <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a> */}

                <Link to="#" className="btn ripple btn-success" onClick={this.onClickLoadFloat}>
                    <i className="fe fe-plus-circle"></i> Load Float
                </Link>

                <Link to="#" className="btn ripple btn-light">
                    <i className="fas fa-print"></i> Print
                </Link>

                {/* <Export
                    url="#"
                /> */}
            </>
        )
    }

    onChangeStatus = (vipStatus, filterTitle) => {
        this.setState({
            vipStatus,
            filterTitle
        }, () => this.listAllRecords())
    }


    onFilter = async (filter) => {
        this.setState({
            filter: false,
            transactionList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllRecords())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            transactionList: false
        }, () => this.listAllRecords())
    }


    onClickNewVehicle = () => {
        this.setState({
            mdlNewVehicle: false
        }, () => {
            this.setState({
                mdlNewVehicle: <PopNewVehicle />
            }, () => functions.openPopup('modal_new_vehicle'))
        })
    }


    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                page: this.state.page + 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    onClickPage = (page) => {
        this.setState({
            page: page,
            transactionList: false
        }, () => this.listAllRecords())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                page: this.state.page - 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {

                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }

    //==============NAVIGATION CONTROLS END===========================



    onClickLoadFloat = () => {
        this.setState({
            mdlNewPayment: false
        }, () => {
            this.setState({
                mdlNewPayment: <PopBankSlipForm
                    vendor={{ name: this.state.username, id: this.state.vendorUserId }}
                    onConfirm={this.onConfirmFloat} />
            }, () => functions.openPopup('modal_bank_slip_form'))
        })
    }


    onConfirmFloat = (jsx) => {
        this.setState({
            info: jsx
        })
        functions.closePopup("modal_bank_slip_form");

    }


    render() {

        const transactionList = this.state.transactionList;

        return (
            <div className="page">
                {this.state.mdlNewPayment}

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Float Statement"
                            previousPageTitle="Resellers"
                            back="/vendor/list"
                            buttons={<this.buttons />}

                        />
                        <CustomizedFilterBar
                            keyword="keyword"
                            // selection={{ title: 'Is VIP', options: listOptions }} 
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        />


                        {this.state.info}

                        <div className="row">

                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        {/* { functions.findInPermission(permissionList, 'USER-002') && */}
                                        <TableHeader
                                            title={this.state.filterTitle}
                                            subtitle="Most recent to older records"
                                        />
                                        <div className="text-wrap">
                                            <div className="example">
                                                {this.state.metaData && <div className="btn-list">

                                                    <button type="button"
                                                        className="btn ripple btn-light btn-sm"
                                                    >
                                                        Total  <span className="badge badge-dark ml-2">{this.state.metaData.count}</span>
                                                    </button>

                                                </div>}
                                            </div>
                                        </div>

                                        {/* } */}
                                        <div className="table-responsive">
                                            <table className="table table-hover text-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Description</th>
                                                        <th>Sale(Dr)</th>
                                                        <th>Topup(Cr)</th>
                                                        <th>Balance(UGX)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactionList && transactionList !== '404' &&
                                                        transactionList.map((item, key) =>
                                                            <tr key={key}>
                                                                <td>{item.trans_date.long_date}</td>
                                                                <td>{item.description}</td>
                                                                <td>{item.debit.amount * 1 > 0 ? item.debit.amount_c : ''}</td>
                                                                <td>{item.credit.amount * 1 > 0 ? item.credit.amount_c : ''}</td>
                                                                <td><b>{item.balance.amount_c}</b></td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>



                                            {this.state.metaData && this.state.pagination}

                                            {!transactionList && <ActivityLoader />}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ResellerStatement
