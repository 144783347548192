import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
// import ActivityLoader from '../../../Components/Common/ActivityLoader'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'

class CreateRole extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        roleName: '',
        code: ''
    }

  

    onChangeRoleName = (event) => {
        this.setState({ roleName: event.target.value })
    }

    onSave = async (event) => {
        event.preventDefault();
        const { roleName } = this.state;

        if (roleName.length > 0) {
            //fetch login from the API
            const server_response = await ajax.createRole(roleName);
            if (server_response.status === "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })
                this.setState({
                    roleName: ''
                })

            } else {

                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })

            }

        } else {
            this.setState({
                info: <AlertError message={dictionary._requireRoleName} />,
                loading: ''
            })
        }


    }

    render() {

        // const permissionList = this.state.permissionList

        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="New Role"
                            previousPageTitle="Roles"
                            back="/config/roles"
                        />

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">

                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                {this.state.info}
                                                <form method="post" onSubmit={this.onSave}>


                                                    <TextInput
                                                        label="Role Name"
                                                        type="text"
                                                        onChange={() => this.onChangeRoleName}
                                                        value={this.state.roleName}
                                                    />

                                                    <div className="modal-footer">
                                                        <input className="btn ripple btn-success" type="button" value="Submit" onClick={this.onSave} />
                                                    </div>
                                                </form>




                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default CreateRole
