import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import TableHeader from '../../Components/Common/TableHeader'
import PageNav from '../../Components/Common/PageNav'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import FinanceContext, { FinanceConsumer } from '../../Context/FinanceContext'
import dictionary from '../../utils/dictionary'
import NoContent from '../../Components/Common/NoContent'

class PaymentCheque extends React.Component {
    static contextType = FinanceContext;

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        pagination: false,
        recordView: false
    }



    buttons = () => {
        return (
            <>

            {/* 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a> */}

                <button className="btn ripple btn-light" onClick={this.context.getAllCheques}>
                <i className="fa fa-redo"></i> Refresh
              </button>



            </>
        )
    }

   





    RenderTransactions = () => {
        return (
            <div className="table-responsive">
                <FinanceConsumer>
                    {props => {
                        const transactionList = props.allCheques

                        return (<>
                            <table id="table-cheques" export-format={dictionary._allExportFormats} className="table datatable text-nowrap mb-0 table-hover table-ellipsis table-striped">
                                <thead>
                                    <tr>
                                        <th>Record Date</th>
                                        <th>Cheque Date</th>
                                        <th>Bank</th>
                                        <th>Cheque No.</th>
                                        <th>Amount(UGX)</th>
                                        <th>Status</th>
                                        <th>Depositor Name</th>
                                        <th>Depositor Contact</th>
                                        <th>Recorded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(transactionList) && transactionList.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item.created_at.short_date}</td>
                                            <td>{item.cheque_date.short_date}</td>
                                            <td>{item.bank.bank_name}</td>
                                            <td className="tx-bold">
                                                {item.cheque_type.code == "STICKER" && <Link to={
                                                    {
                                                        pathname: "/finance/cheques/sticker",
                                                        state: { chequeId: item.cheque_id }
                                                    }
                                                }>
                                                    {item.cheque_number}
                                                </Link>}

                                                {item.cheque_type.code == "VEHICLE" && <Link to={
                                                    {
                                                        pathname: "/finance/payment_cheques/manage",
                                                        state: { chequeId: item.cheque_id }
                                                    }
                                                }>
                                                    {item.cheque_number}
                                                </Link>}

                                                {item.cheque_type.code == "RESERVE" && <Link to={
                                                    {
                                                        pathname: "/finance/cheques/reserve",
                                                        state: { chequeId: item.cheque_id }
                                                    }
                                                }>
                                                    {item.cheque_number}
                                                </Link>}

                                            </td>
                                            <td>{item.amount.amount_c}</td>
                                            <td>{item.status.status}</td>
                                            <td>{item.depositor_name}</td>
                                            <td>{item.depositor_contact}</td>
                                            <td>{item.created_by.username}</td>


                                        </tr>
                                    )}

                                </tbody>
                            </table>
                            {!transactionList && <ActivityLoader />}
                            {transactionList==="404" && <NoContent/>}
                        </>
                        )
                    }}
                </FinanceConsumer>
                {/* {transactionList && this.state.pagination} */}


            </div>
        )
    }



    render() {
       

        return (
            <div className="page">

                {this.state.recordView}


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Payment Cheques"
                            previousPageTitle="Finance"
                            back="/finance"
                            buttons={<this.buttons />}
                        />


                        
                        {this.state.toast}
                        {this.state.locationPIN}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Cheque Records"
                                            subtitle="List of all payment cheque records from the most recent."
                                        />


                                        <div className="text-wrap">
                                            <div className="example">
                                                <div className="btn-list">

                                                    <button type="button"
                                                        className="btn ripple btn-primary btn-sm"
                                                        onClick={() => this.context.filterChequeStatus("")}>
                                                        All Cheques <span className="badge badge-light ml-2">...</span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn ripple btn-secondary btn-sm"
                                                        onClick={() => this.context.filterChequeStatus(0)}>
                                                        Pending Cheques <span className="badge badge-dark ml-2">...</span>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn ripple btn-success btn-sm"
                                                        onClick={() => this.context.filterChequeStatus(1)}>
                                                        Approved Cheques <span className="badge badge-dark ml-2">...</span>
                                                    </button>


                                                </div>
                                            </div>
                                        </div>


                                        <this.RenderTransactions />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );

    }
}

export default PaymentCheque
