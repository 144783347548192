import React, { useContext } from "react"
import ScrollFilterBar from "../../../../Common/Filter/ScrollFilterBar";
import FilterTextInput from "../../../../Common/Filter/FilterTextInput";
import ZuluReportContext from "../../../../../Context/Violation/ZuluReportContext";

const ZuluReportFilter=(props)=>{

    const { 
            resetData,
            filterData
        } = useContext(ZuluReportContext)




    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Enforcement Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Enforcement Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,
        ],
        names:["dateFrom", "dateTo"]
    }}
/>

}

export default ZuluReportFilter