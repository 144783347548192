import React, { useContext } from "react";
import FilterTextInput from "../Common/Filter/FilterTextInput";
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar";
import ViolationReportContext from "../../Context/Violation/ViolationReportContext";

const ViolationTypeFilter =(props)=>{


    const {resetData, filterData } = useContext(ViolationReportContext)

    
    return(<ScrollFilterBar
                onReset={resetData}
                onConfirm={filterData}
                firstScreen={{
                    inputs:[
                        (onSet, key, currentValues)=><FilterTextInput
                        key={key}
                        label="Date From"
                        type="date"
                        onChange={onSet}
                        name="dateFrom"
                        currentValues={currentValues}
                    />,
            
                    (onSet, key, currentValues)=><FilterTextInput
                        key={key}
                        label="Date To"
                        type="date"
                        onChange={onSet}
                        name="dateTo"
                        currentValues={currentValues}
                        />,

                    ],
                    names:["dateFrom", "dateTo"]
                }}
/>
    )

}


export default ViolationTypeFilter;