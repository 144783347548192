import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async deleteSticker(sticker_invoice_id){
        let response = await apiCall("sticker/invoice/delete", {"sticker_invoice_id":sticker_invoice_id})
        return response;
    },

    async updateStickerDuration(sticker_id, effective_date, expiry_date){
        const data={
            "sticker_id":sticker_id,
            "effective_date":effective_date,
            "expiry_date":expiry_date
        }
        let response = await apiCall("sticker/invoice/duration/update", data)
        return response;
    },

    async correctStickerVehicle(sticker_invoice_id, correct_plate_number){
        const data={
            "sticker_invoice_id":sticker_invoice_id,
            "correct_plate_number":correct_plate_number
        }
        let response = await apiCall("sticker/vehicle/correct", data)
        return response;
    },
    


    async effectStickerPayment(effective_date){
        const data={
            "effective_date":effective_date
        }
        let response = await apiCall("sticker/vehicle/clear/all", data)
        return response;
    },
    


    async listStickerClasses(){
       
        let response = await apiCall("sticker/class/list", [])
        return response;
    },
    


}