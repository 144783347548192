import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import Badge from '../../Components/Common/Badge'
import PopAssignLocation from '../../Components/Operations/PopAssignLocation'
import RoleFilters from '../../Components/Operations/RoleFilters'
import PageNav from '../../Components/Common/PageNav'
import TableHeader from '../../Components/Common/TableHeader'
import NoContent from '../../Components/Common/NoContent'
import dictionary from '../../utils/dictionary'
import LocationMappingContext from '../../Context/LocationMappingContext'
import { RenderSecure } from '../../utils/script/RenderSecure'
import { Link } from 'react-router-dom'

class UserLocationMapping extends React.Component {

    static contextType = LocationMappingContext

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        activeTransactionId: false,
        toast: false,
        selectedRecord: false,
        dialogueNewAssignment: false,
        dialogueDelete: false,
        filterDescription: "All users with their respective assigned locations",
        pagination: false,
        roleId: false
    }

  

    onClickRole = (role) => {

        const {switchRole, resetData} = this.context

        if (role) {

            this.setState({
                transactionList: false,
                metaData: false,
                filterDescription: role.role_name + ' with their respective assigned locations',
                roleId: role.role_id
            }, () => switchRole(role.role_id))
        } else {
            this.setState({
                roleId: false,
                transactionList: false,
                metaData: false,
                filterDescription: 'All branch users with their respective assigned locations',
            }, () => resetData())
        }



    }

    onClickAssign = () => {
        this.setState({
            dialogueNewAssignment: false
        }, () => {
            this.setState({
                dialogueNewAssignment: <PopAssignLocation onConfirm={this.onConfirmAssignment} />
            }, () => {
                $("#pop_assign_location").modal('show');
            })

        })

    }

    onConfirmAssignment = async (userId, locationId) => {
        const server_response = await ajax.set_user_location(userId, locationId);
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />,
            }, () => {
                this.context.refresh()
                $("#pop_assign_location").modal('hide');

            })
        } else {
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {
                $("#pop_assign_location").modal('hide');
            })
        }

    }





    onSelectRecord = (transactionId, record) => {
        this.setState({
            activeTransactionId: transactionId,
            selectedRecord: record,
            toast: false
        })
    }


    buttons = () => {
        return (
             <div className="btn btn-list">
              <RenderSecure code="U-SWITCH-BRANCH-0">
              <a className="btn ripple btn-primary dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-exchange-alt mr-1"></i> Switch Branch <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                {Array.isArray(this.context.branchListing) && this.context.branchListing.map((item, key)=>
                  <Link 
                    to="#" 
                    className="dropdown-item" 
                    key={key} 
                    onClick={()=>this.context.switchMappingBranch(item.branch_id)}
                    >
                    <i className="fa fa-university text-secondary mr-1"></i>{item.branch_name} Branch
                  </Link>
                )}

              </div>
              </RenderSecure>
                {/* <button className="btn ripple btn-success" onClick={this.onClickAssign}>
                    <i className="fas fa-map-pin"></i> Assign Location
                </button> */}
            </div>
        )
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }

    onPrint = () => {
        functions.printDoc("location-container")
    }


    customHeaderOptions = () => {
        return (
            <>
                {/* <a className="dropdown-item pointer">
                    <i className="fas fa-upload mr-2"></i>Import Excel Allocations
                </a> */}
                <a className="dropdown-item pointer" onClick={this.onPrint}>
                    <i className="fas fa-print mr-2"></i>Print Allocations
                </a>
                <a className="dropdown-item pointer" onClick={this.onClickRemoveAllocation}>
                    <i className="fe fe-trash-2 mr-2"></i>Remove Selected Allocation
                </a>

                <a className="dropdown-item pointer" onClick={this.onClickAssign}>
                    <i className="fas fa-map-pin mr-2"></i>Assign Location
                </a>

            </>
        )
    }

    onClickRemoveAllocation = () => {
        if (this.state.activeTransactionId) {
            this.setState({
                dialogueDelete: false
            }, () => {
                this.setState({
                    dialogueDelete: <WarnDelete
                        title="Remove Allocation?"
                        description="You are about to remove the selected allocation from user!"
                        buttonLabel="Remove Allocation"
                        onConfirm={() => this.onConfirmRemoveAllocation()} />
                }, () => {
                    $("#modal_delete").modal('show');
                })

            })
        }
    }

    onClickRemoveItem = (id) => {
        this.setState({
            dialogueDelete: false,
            activeTransactionId: id
        }, () => {
            this.setState({
                dialogueDelete: <WarnDelete
                    title="Remove Allocation?"
                    description="You are about to remove the selected allocation from user!"
                    buttonLabel="Remove Allocation"
                    onConfirm={() => this.onConfirmRemoveAllocation()} />
            }, () => {
                $("#modal_delete").modal('show');
            })

        })
    }

    onConfirmRemoveAllocation = async () => {

        const server_response = await ajax.remove_user_location(this.state.activeTransactionId);
        if (server_response.status === "OK") {
            this.setState({
                activeTransactionId: false,
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />

            });

        } else {
            this.setState({
                activeTransactionId: false,
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            });
        }
        $("#modal_delete").modal('hide');

    }



    render() {

        const transactionList = this.context.locationMapping
        return (
            <div className="page">
                {this.state.dialogueDelete}

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Location Distribution"
                            previousPageTitle="Operations"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                            back="/operations"
                        />

                        {this.state.toast}
                        {this.state.dialogueNewAssignment}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        {/* <div>
                                            <h6 className="card-title mb-1">User Location Mapping</h6>
                                            <p>{this.state.filterDescription}</p>
                                        </div> */}

                                        <div className="d-flex justify-content-between align-items-center filter-example">

                                        <TableHeader
                                            title={"User Location Mapping - " + this.context.branchName}
                                            subtitle={this.state.filterDescription}
                                        />


                                        <RoleFilters onClick={this.onClickRole} />

                                        </div>


                                        <div className="table-responsive allView-config border-top" id="location-container">
                                            
                                            {Array.isArray(transactionList) &&<table className="table table-bordered text-nowrap mb-0 table-hover" id="table-location-dist">
                                                <thead>
                                                    <tr>
                                                        <th>NO.</th>
                                                        <th>User</th>
                                                        <th>Location</th>
                                                        <th>Total Slots</th>
                                                        <th>Street</th>
                                                        <th>Branch</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                   
                                                           { transactionList.map((item, key) =>
                                                                <>

                                                                    {item.total_locations > 0 &&
                                                                        item.locations.map((itemin, keyin) =>
                                                                            <tr
                                                                                className={`pointer ${this.state.activeTransactionId === itemin.distribution_id ? 'flag-row' : ''}`}
                                                                                onClick={() => this.onSelectRecord(itemin.distribution_id, itemin)}
                                                                                key={keyin}>
                                                                                {keyin === 0 && <>
                                                                                    <td rowSpan={item.total_locations}>{key + 1}</td>

                                                                                    <td rowSpan={item.total_locations}>
                                                                                        {item.user_profile.first_name + ' ' + item.user_profile.last_name}
                                                                                        <Badge value={item.user_profile.user_role.role_name} type={4} />

                                                                                    </td>
                                                                                </>
                                                                                }
                                                                                <td>{itemin.location_info.location_name}</td>
                                                                                <td>{itemin.location_info.total_slots.total}</td>

                                                                                <td>{itemin.location_info.street.street_name}</td>
                                                                                <td>{itemin.location_info.street.branch.branch_name}</td>
                                                                                <td>
                                                                                    <a onClick={() => this.onClickRemoveItem(itemin.distribution_id)}
                                                                                        className="btn ripple btn-danger btn-sm text-white"
                                                                                        data-toggle="tooltip" data-original-title="Remove">
                                                                                        <i className="fe fe-trash-2"></i>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>)}

                                                                </>
                                                            )}
                                                </tbody>
                                            </table>}
                                            {!transactionList && <ActivityLoader />}
                                            {transactionList ==="404" && <NoContent/>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default UserLocationMapping
