import React, { useContext } from 'react'
import DemandNoteContext from '../../../Context/Finance/DemandNoteContext'
import NoContent from '../../Common/NoContent'
import TableHeader from '../../Common/TableHeader'
import ActivityLoader from '../../Common/ActivityLoader'

const RecentNotes =()=> {

    const {allTransactions, setBatchId, batchId} = useContext(DemandNoteContext);

    const selectRecord = (batch_id) =>{
        setBatchId(batch_id);
    }

        return (<div className="card custom-card">
                            <div className="card-body map-card">

                                <TableHeader
                                    title={"Recent Demand Notes"}
                                    subtitle="List of demand notes ordered from the most recent."
                                />

                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    <div className="table-responsive">
                                        <table className="table table-hover text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Batch Name</th>
                                                    <th>Contact</th>
                                                    <th>Status</th>
                                                    <th>Created at</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(allTransactions) && allTransactions.map((item, key)=>
                                                    <tr key={key} onClick={()=>selectRecord(item.id)} className={`${item.id === batchId ? 'flag-row' : ''}`} style={{cursor:'pointer'}}>
                                                        <td>{item.batch_name}</td>
                                                        <td>{item.phone_number}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.created_at.short_date} {item.created_at.time}</td>
                                                    </tr>
                                                )}
                                               
                                            </tbody>
                                        </table>
                                        {!allTransactions && <ActivityLoader />}
                                        {allTransactions === '404' && <NoContent />}
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
             
    }

export default RecentNotes
