import React, { useContext } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import PairedCard from '../../Components/Cards/UserCards/PairedCard'
import TableHeader from '../../Components/Common/TableHeader'
import PaperTicketContext from '../../Context/PaperTicketContext'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import dictionary from '../../utils/dictionary'
import ManageUserContext from '../../Context/ManageUserContext'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import { Link } from 'react-router-dom'

const PaperTicketDashboard =()=> {

    const {
        ticketListing, 
        metaData,  
        totalDailySales,
        totalDailyVendors,
        totalDailyVehicles,
        totalWeeklyVehicles,
        totalMonthlyVehicles,
        totalWeeklySales,
        totalMonthlySales,
        loading,
        goToNextPage,
        filterData,
        resetData
    } = useContext(PaperTicketContext);
    const {userList} = useContext(ManageUserContext);

    const CardSection = () => {


        return (
            <>

                    <PairedCard
                        className="col-sm-6 col-md-6 col-xl-6"
                        title1="Daily Sales (UGX)"
                        title2="Total Daily Vendors"
                        caption1="Only today"
                        caption2={totalDailyVehicles + " vehicle(s)"}
                        icon="fas fa-hand-holding-usd"
                        value1={totalDailySales}
                        value2={totalDailyVendors}
                        color1="mb-0 text-primary fs-13 ml-1"
                        color2="icon-service rounded-circle bg-secondary-transparent text-secondary"
                        href="#"
                    />

                    <PairedCard
                        className="col-sm-6 col-md-6 col-xl-6"
                        title1="Weekly Sales (UGX)"
                        title2="Monthly Sales (UGX)"
                        caption1={totalWeeklyVehicles + " vehicle(s)"}
                        caption2={totalMonthlyVehicles + " vehicle(s)"}
                        icon="fa  fa-calendar"
                        value1={totalWeeklySales}
                        value2={totalMonthlySales}
                        color1="mb-0 text-success fs-13 ml-1"
                        color2="icon-service rounded-circle bg-success-transparent text-success"
                        href="#"
                    />

            </>
        )

       
    }




    const RenderFilter = () => {
          
        const userOptions = [];
        Array.isArray(userList) && userList.map((item, key) =>
            userOptions.push({
                title: item.first_name + " " + item.last_name,
                value: item.user_id
            })
        );

        return (
            <CustomizedFilterBar
                selection={{ title: 'Sold By', options: userOptions }}
                keyword="Plate Number"
                onReset={resetData}
                onConfirm={filterData}
            />
        )

        
    }



    const buttons = () => {
        return (
            <>
                <Link to="/finance/paper-tickets-summary" className="btn ripple btn-primary">
                <i className="fa fa-user mr-1"></i>
                    Agent Sales</Link>
                {ticketListing && 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                }

            </>
        )
    }





        return (
           
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">

                        <PageHeader
                            title="Paper Tickets"
                            previousPageTitle="Dashboard"
                            back="/finance"
                            buttons={buttons()}
                        />
                        {RenderFilter()}

                        <div className="row">
                            {CardSection()}
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card ">
                                     <div className="card-body map-card">

                                        <TableHeader
                                            title="Paper Ticket Transactions"
                                            subtitle="All paper ticket transactions."
                                        />
                                         {Array.isArray(ticketListing) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }
                                            <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                                <div className="table-responsive">
                                                   {Array.isArray(ticketListing) && <table id="table-paper-ticket" className="table table-hover text-nowrap mg-b-0 datatable" export-format={dictionary._exportBtnFormats}>
                                                        <thead>
                                                            <tr>
                                                                <th>No</th>
                                                                <th>Transaction Date</th>
                                                                <th>Vehicle</th>
                                                                <th>Contact</th>
                                                                <th>Amount</th>
                                                                <th>Exchanged By</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { ticketListing.map((item, key)=> <tr key={key}>
                                                                    <td>{key+1}</td>
                                                                    <td>{item.created_at.short_date + ' ' + item.created_at.time}</td>
                                                                    <td>{item.vehicle.vehicle_number}</td>
                                                                    <td>{item.phone_number}</td>
                                                                    <td className='total-ticket-amount'>{item.amount.amount_c}</td>
                                                                    <td>{item.sold_by.first_name + ' ' + item.sold_by.last_name}</td>
                                                            </tr>)} 


                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td><b>TOTAL</b></td>
                                                                <td><b>{functions.sumTableField("total-ticket-amount")}</b></td>
                                                                <td>&nbsp;</td>
                                                            </tr>


                                                        </tbody>
                                                        </table>}
                                                        {!ticketListing && <ActivityLoader/>}
                                                        {(ticketListing === "404") && <NoContent />}

                                                       
                                                    </div>
                                                </div>
                                             </div>
                                        </div>
                                     </div>
                                 </div>

                         </div>

                </div>
                <RightSideBar />

            </div>);

                }

        



    


export default PaperTicketDashboard
