import React, { useContext, useEffect } from 'react'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import { Link } from 'react-router-dom'
import NoContent from '../../Components/Common/NoContent'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import XReportContext from '../../Context/Reports/XReportContext'
import dictionary from '../../utils/dictionary'



const XReport =(props)=>{
   
    const { 
        allTransactions, 
        goToNextPage,
        metaData,
        loading,
        setData,
        title
    } = useContext(XReportContext)

    useEffect(()=>{
        if(!allTransactions){
            setData({"page":"1", "status":"0"})
        }
    }, [])

        return (
          
            <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                   <TableHeader
                        title="X-Report"
                        subtitle="Conductor field report showing a list of invoiced vehicles"
                    />

                      {Array.isArray(allTransactions) &&
                                <LoadMoreButton
                                    action={goToNextPage}
                                    loading={loading}
                                    title={metaData.current_page + " of " + metaData.total_pages}
                                />
                            }


                    <div className="table-responsive">
                    {Array.isArray(allTransactions) && <table id='table-x-report' className={`table text-nowrap mb-0 table-hover ${props.datatable}`} export-format={dictionary._exportBtnFormats}>
                            <thead>
                                <tr>
                                    <th colSpan={7} style={{textAlign:'center'}}>{title}<br/><br/></th>
                                </tr>
                                <tr>
                                    <th>Date</th>
                                    <th>Vehicle Plate</th>
                                    <th>Start Time/End Time</th>
                                    <th>Amount Due(UGX)</th>
                                    <th>Served By</th>
                                    <th>Street Name</th>
                                    <th>Location Name</th>
                                </tr>
                            </thead>

                            <tbody>
                            {allTransactions.map((item, key)=><tr key={key}>
                                            <td>{item.start_time.short_date}</td>

                                            <td className="tx-bold">
                                                <Link to={
                                                    {
                                                        pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                        state: { plateNumber: item.vehicle.plate_number }
                                                    }}>{item.vehicle.plate_number}
                                                </Link>
                                            </td>

                                            <td>
                                                {item.start_time.time} - {item.end_time.time}
                                            </td>
                                            {/* <td>
                                                                    {item.end_time.time}
                                                                </td> */}
                                            <td className="tx-bold">
                                                {item.invoice.invoice_items?.total?.amount_c}
                                            </td>

                                            <td>{item.conductor?.first_name + ' ' + item.conductor?.last_name}</td>
                                            <td>
                                                {item.location?.street?.street_name}
                                            </td>
                                            <td>
                                                {item.location?.location_name}
                                            </td>
                                        </tr>
                                    )}


                            </tbody>
                        </table>}



                        {(allTransactions === "404") && <NoContent />}
                        {!allTransactions && <ActivityLoader />}

                    </div>
                   
                </div>
            </div>

        );

    }


export default XReport
