import React, { Component } from "react";
import Chart from "react-apexcharts";

class ApexPolar extends React.Component {
    state = {

        series: this.props.values,
        options: {
            chart: {
                // width: 480,
                // type: 'polarArea'
                parentHeightOffset: 0,
                // sparkline: {
                //     enabled: true
                // }
            },
            // labels: [
            //     'Reserved',
            //     'Available',
            //     'Rose C'
            // ],
            labels: this.props.labels,
            fill: {
                opacity: 1
            },
            // colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],
            colors: this.props.colors,
            stroke: {
                width: 1,
                // colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],
                // colors: undefined
            },
            yaxis: {
                show: false
            },
            legend: {
                position: 'top'
            },
            plotOptions: {
                polarArea: {
                    rings: {
                        strokeWidth: 0
                    },
                    spokes: {
                        strokeWidth: 0
                    },
                }
            },
            // theme: {
            //     monochrome: {
            //         enabled: false,
            //         shadeTo: 'light',
            //         shadeIntensity: 0.6
            //     }
            // }
        },
    };


    render() {
        return (

            <Chart
                options={this.state.options}
                series={this.state.series}
                type="polarArea"
                height={300}
            />
        )
    }
}

export default ApexPolar;
