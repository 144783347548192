import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ajax from '../../../utils/ajax'
import SearchBar from '../../../Components/Common/SearchBar'
import TitleValueCard from '../../../Components/Common/TitleValueCard'
import TableHeader from '../../../Components/Common/TableHeader'
import BankCashSummary from '../../../Components/Finance/Cashier/BankCashSummary'
import CustomListCard from '../../../Components/Finance/Cashier/CustomListCard'
import { Link } from 'react-router-dom';
import DailyCollectionGroups from './DailyCollectionGroups'
import VehicleInfoCard from '../../../Components/Finance/Cashier/VehicleInfoCard'
import PaymentMethods from '../../../Components/Finance/Cashier/PaymentMethods'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import Toast from '../../../Components/Common/Toast'
import VehicleListingInfo from '../../../Components/Vehicle/VehicleListing/VehicleListingInfo'
import PaySticker from '../../../Components/Finance/Cashier/PaySticker'
import PopCashierDailySummary from '../../../Components/Finance/Cashier/PopCashierDailySummary'
import { RenderSecure } from '../../../utils/script/RenderSecure'

class CashierDashboard extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        invoiceList: false,
        recentPayment: true,
        vehicleInfo: false,
        showInvoices: false,
        plateNumber: '',
        autoVehicle: false,
        currentMenu:"HOME",
        toast:false,
        listId:'',
        stickerScreen:false,
        mdlDailySummary:false
    }

    componentDidMount() {
        if (this.props.location) {
              if(this.props.location.state)
              {
                this.setState({
                    plateNumber: this.props.location.state.vehiclePlate,
                    autoVehicle: true
                }, () => this.onSearch(this.state.plateNumber))
            }

        }
    }

    exitPayment = () => {
        this.setState({
            menuOptions: '',
            invoiceList: false,
            recentPayment: true,
            vehicleInfo: false,
            showInvoices: false,
            plateNumber: ''
        })
    }

    hideRecentPayment = () => {
        this.setState({
            recentPayment: false
        })
    }

    showRecentPayment = () => {
        this.setState({
            recentPayment: true
        })
    }

    showVehicleInvoices = () => {
        this.setState({
            showInvoices: true
        })
    }

    onSearch = (plateNumber) => {
        if(plateNumber.length>0)
        {
            this.setState({
                plateNumber
            }, ()=>{
                this.searchVehicle(plateNumber);

            })
        }
    }

    searchVehicle = async (plateNumber) => {
        this.setState({
            currentMenu:"LOADING"
        })
        const server_response = await ajax.getVehicleByPlate(plateNumber, this.state.userId)
        this.setState({
            plateNumber: plateNumber
        })
        if (server_response.status === "OK") {
            this.setState({
                vehicleInfo: server_response.details.content,
                currentMenu: "VEHICLE"
            }, () => this.showVehicleInvoices())
        } else {
            this.setState({
                vehicleInfo: '404',
                currentMenu:"HOME",
                toast: <Toast
                type={2}
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
        
            })
        }
    }


    goHome=()=>{
        // this.setState({
        //     currentMenu:"HOME",
        //     toast:false
        // })
        window.location.reload()
    }


    onPrintDailySummary=()=>{
        this.setState({
            mdlDailySummary:false
        }, ()=>{
            this.setState({
                mdlDailySummary:<PopCashierDailySummary cashierId={this.state.userId}/>
            }, ()=>functions.openPopup("modal_cashier_daily_summary"))
        })
    }


    buttons = () => {
        return (
          <>
            <Link to="#" className="btn ripple btn-light" onClick={this.goHome}>
            <i className="fas fa-home"></i> Home
            </Link>
            <RenderSecure code="C-RESERVER-PAY-01">
                <Link to="/cashier/reservation" className="btn ripple btn-success">
                <i className="fas fa-car"></i> Pay Reservation
                </Link>
            </RenderSecure>

            <RenderSecure code="R-BATCH-PAYMENT-01">
                <Link to="/payment/batch" className="btn ripple btn-danger">
                <i className="fas fa-file-invoice"></i> Batch Payments
                </Link>
            </RenderSecure>

            <Link to="#" className="btn ripple btn-primary" onClick={this.onPrintDailySummary}>
              <i className="fas fa-print"></i> Print
            </Link>
    
          </>
        )
      }


      resetToast = () => {
        this.setState({
            toast: false
        })
    }

    refresh=()=>{

    }

    onClickViewList=(list_id)=>{
        this.setState({
            currentMenu:"LOADING"
        }, ()=>{

            this.setState({
                currentMenu:"LISTING",
                listId:list_id
            })
        })
      
    }


    goToNextScreen=(paymentModeCode)=>{
        this.setState({
            stickerScreen:paymentModeCode
        })
    }

    goToHomeScreen=()=>{
        this.setState({
            stickerScreen:false
        })
    }


    render() {
        const vehicleInfo = this.state.vehicleInfo
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Cashier"
                            previousPageTitle="Payments"
                            back="#"
                            buttons={<this.buttons />}
                        />
                    {this.state.mdlDailySummary}

                    {!this.state.stickerScreen && <div className="row">
                        <div className="col-md-8 col-sm-12">
                            <SearchBar
                                    title="Enter Vehicle Number"
                                    onSearch={this.onSearch}
                                    placeholder="Enter Vehicle plate number to make payment"
                                />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <BankCashSummary/>
                        </div>
                        
                       
                    </div>}


                     

                    <div className="row">
                    {this.state.toast}

                    {!this.state.stickerScreen && <>
                        <div className="col-md-8 col-sm-12">
                        
                                {this.state.currentMenu=="HOME" && <DailyCollectionGroups/>}
                                
                                {this.state.currentMenu=="VEHICLE" && 
                                <div className="row">
                                   
                                   
                                   
                                    <div className="col-md-8 col-sm-12">
                                        <VehicleInfoCard 
                                            vehicleInfo={vehicleInfo} 
                                            nextScreen={this.goToNextScreen}
                                            homeScreen={this.goToHomeScreen}
                                            refresh={this.searchVehicle}
                                            />
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <PaymentMethods 
                                            vehicleInfo={vehicleInfo} 
                                            refresh={this.refresh}/>
                                    </div>
                                    

                                </div>}

                                {this.state.currentMenu=="LISTING" && 
                                    <VehicleListingInfo listId={this.state.listId}/>}

                                {this.state.currentMenu=="LOADING" && <ActivityLoader/>}



                        </div>
                        <div className="col-md-4 col-sm-12">
            
                                <CustomListCard onView={this.onClickViewList}/>

                        </div>

                     </>}

                     {this.state.stickerScreen && 
                        <div className="col-12">
                            <PaySticker
                                paymentModeCode={this.state.stickerScreen} 
                                vehicleInfo={vehicleInfo}
                                onExit={this.goToHomeScreen}/>
                        </div>}

                        





                    </div>




                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default CashierDashboard
