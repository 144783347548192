import React, { useContext } from 'react'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import TableHeader from '../../Components/Common/TableHeader'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import PageContainer from '../../Components/Layout/PageContainer'
import { RenderSecure } from '../../utils/script/RenderSecure'
import PopUploadSessionData from '../../Components/DataMigration/PopUploadSessionData'
import ParkingMigrationContext from '../../Context/Migration/ParkingMigrationContext'
import WarnSecureDelete from '../../Components/WarnSecureDelete'
import ajaxSession from '../../utils/remote/ajaxSession'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import AlertError from '../../Components/Common/AlertError'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'



const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

class ImportSession extends React.Component {
    static contextType = ParkingMigrationContext;

    state = {
       modal:false,
       info:'',
       selectedId:false
    }


    onClickUpload=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<PopUploadSessionData/>
            }, ()=>functions.openPopup("modal_import_sessions"))

        })
      
    }


    onClickApprove=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<WarnSecureDelete
                id="modal_approve_parkings" 
                title="Approve Parkings"
                description="Confirm with your account password that you want to approve pending manual parkings records; Parking session invoices will be created on the respective vehicle accounts."
                icon="fas fa-question"
                buttonLabel={"Confirm Parkings"}
                buttonType="btn-success"
                onConfirm={this.confirMigrations}
                />
            }, ()=>functions.openPopup("modal_approve_parkings"))
        })
    }


    confirMigrations=async(password)=>{
        this.setState({info:''})
        const server_response = await ajaxSession.approveMigratedParkings(password);
        functions.closePopup("modal_approve_parkings");
        if(server_response.status==="OK")
        {  
            this.setState({info:<AlertSuccess message={server_response.details.message}/>})          
        }else{
           this.setState({info:<AlertError message={server_response.details.message}/>})          
        }
    }


    onClickDelete=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<WarnSecureDelete
                id="modal_delete_parking" 
                title="Delete Manual Parking"
                description="Confirm with your account password that you want to delete the selected parking record; Record cannot be deleted if already approved."
                icon="fas fa-question"
                buttonLabel={"Confirm Delete"}
                buttonType="btn-danger"
                onConfirm={this.confirmDelete}
                />
            }, ()=>functions.openPopup("modal_delete_parking"))
        })
    }


    confirmDelete=async(password)=>{
        this.setState({info:''})
        const server_response = await ajaxSession.deleteMigratedParking(password, this.state.selectedId);
        functions.closePopup("modal_delete_parking");
        if(server_response.status==="OK")
        {  
            this.setState({info:<AlertSuccess message={server_response.details.message}/>})          
        }else{
           this.setState({info:<AlertError message={server_response.details.message}/>})          
        }
    }


    onSelectRow=(id)=>{
        this.setState({
            selectedId:id
        })
    }
  

    buttons = () => {
        return (
            <>

            <div className="btn btn-list">

               <RenderSecure code="C-SESSION-IMPORT-01">
                    <button className="btn ripple btn-primary" onClick={this.onClickUpload}>
                        <i className="fa fa-plus mr-2"></i>New Upload
                    </button>
                </RenderSecure>


              <a className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                  <Link to="#" className="dropdown-item" onClick={this.context.getMigrations}>
                    <i className="fa fa-redo text-secondary mr-1"></i>Refresh Records</Link>
                    <RenderSecure code="U-SESSION-IMPORT-01">
                        <Link to="#" className="dropdown-item" onClick={this.onClickApprove}>
                        <i className="fa fa-check text-success mr-1"></i>Approve Parkings</Link>
                    </RenderSecure>

                    {this.state.selectedId && <RenderSecure code="D-SESSION-IMPORT-01">
                        <Link to="#" className="dropdown-item" onClick={this.onClickDelete}>
                        <i className="fa fa-trash-alt text-danger mr-1"></i>Delete Record</Link>
                    </RenderSecure>}

              </div>
             

              {this.context.migrationList  && 
                    <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                    </a>
                    }

               
            </div>
               
              
             
              
              
              
               
             

            </>
        )
    }



    RenderFilter = () => {
          
        const statuses = [
            {title:"PENDING", value:"1"}, 
            {title:"APPROVED", value:"2"} 
            // {title:"PROCESSED", value:"3"}
        ];
    
        return (
            <CustomizedFilterBar
                selection={{ title: 'Approval Status', options: statuses }}
                keyword="Plate Number"
                onReset={this.context.resetData}
                onConfirm={this.context.filterData}
            />
        )
    
        
    }



    render() {

        const { migrationList } = this.context

        return (

            <PageContainer>

                    <PageHeader
                            title="Import Sessions"
                            previousPageTitle="Dashboard"
                            // options={this.state.menuOptions}
                            back="/operations"
                            buttons={<this.buttons />}
                        />  
                        <this.RenderFilter/>
                        {this.state.modal}

                        <div className="row">
                    <div className="col-sm-12 col-xl-12 col-lg-12">

                    <div className="card custom-card">
                            <div className="card-body map-card">

                                <TableHeader
                                    title={"Manual Parking Sessions "}
                                    subtitle="List of all parking sessions recorded manually"
                                />


                                 {Array.isArray(this.context.migrationList) &&
                                                            <LoadMoreButton
                                                                action={this.context.goToNextPage}
                                                                loading={this.context.loading}
                                                                title={this.context.metaData.current_page + " of " + this.context.metaData.total_pages}
                                                            />
                                                        }

                         
                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    {this.state.info}
                                    <div className="table-responsive allView-table border-top">



                           {Array.isArray(migrationList) && <table id="table-session-import" export-format={exportFormats} className="table text-nowrap table-hover mg-b-0 datatable">
                                    <thead>
                                        <tr>
                                            <th>NO</th>
                                            <th>Date</th>
                                            <th>Vehicle</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Location</th>
                                            <th>Street</th>
                                            <th>Slots</th>
                                            <th>Ticket Amount</th>
                                            <th>Status</th>
                                            <th>Imported By</th>
                                            <th>Approved By</th>
                                            <th>Updated At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {migrationList.map((item, key)=>
                                            <tr key={key} onClick={()=>this.onSelectRow(item.import_id)} className={`${this.state.selectedId===item.import_id?'flag-row':''}`}>
                                                <td>{key+1}</td>
                                                <td>{item.parking_date.short_date}</td>
                                                <td>{item.plate_number}</td>
                                                <td>{item.start_time.time}</td>
                                                <td>{item.end_time.time}</td>
                                                <td>{item.location.location_name}</td>
                                                <td>{item.location.street.street_name}</td>
                                                <td>{item.total_slots}</td>
                                                <td>{item.ticket_amount.amount_c}</td>
                                                <td>{item.status.name}</td>
                                                <td>{item.created_by.username}</td>
                                                <td>{item.approved_by?item.approved_by.username:'-'}</td>
                                                <td>{item.updated_at.short_date + ' ' + item.updated_at.time}</td>
                                            </tr>
                                        )}

                                </tbody>
                            </table>}

                            {!migrationList && <ActivityLoader/>}

                            {migrationList=="404" && <NoContent/>}


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>

            </PageContainer>

           
        );

    }
}

export default ImportSession
