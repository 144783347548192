import React from 'react';
import functions from '../utils/functions';
import ActivityLoader from './Common/ActivityLoader';
import CompanyHeader from './Config/CompanyHeader';


class PopInvoice extends React.Component {

	state = {
		invoiceRecord: this.props.invoice
	}

	onClickPay = () => {
		this.props.onClickPay(this.state.invoiceRecord.vehicle.plate_number)
	} 

	print = () => {
		functions.printDoc('vehicle_invoice_doc');
	}

	render() {
		const record = this.state.invoiceRecord;
		
		return (
			<div className="modal" id="modal_invoice">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">

					<div className="modal-content modal-content-demo shadow">
						<div className="modal-header">
							<h6 className="modal-title">Invoice</h6>
							<button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body">


							<div className="row">
								{record && <div className="col-lg-12 col-md-12">
									<div className="card custom-card no-shadow">
										<div className="card-body" id="vehicle_invoice_doc">
											<div className="d-lg-flex">
												<h2 className="card-title mb-1">
													#{record.invoice_id_c}<br />
													{record.invoice_type.invoice_name}<br/>
													N{record.invoice_id}
												</h2>
												<div className="ml-auto">
													<p className="mb-1"><span className="font-weight-bold">Invoice Date :</span> {record.invoice_dates.long_date}</p>
													<p className="mb-0"><span className="font-weight-bold">Due Date :</span> __________</p>
												</div>
											</div>
											<hr className="mg-b-40" />
											<div className="row">
												<div className="col-lg-6">
													<CompanyHeader />
												</div>
												<div className="col-lg-6 text-right">
													<p className="h3">Invoice To:</p>
													<address>
														{record.vehicle.client.full_name}<br />
												Vehicle: {record.vehicle.plate_number}<br />
												Phone:{record.vehicle.client.phone_number}<br />
												Email:{record.vehicle.client.email}
													</address>
												</div>
											</div>
											<div className="table-responsive mg-t-40">
												<table className="table table-invoice table-bordered">
													<thead>
														<tr>
															<th className="wd-35p">Billing Date</th>
															<th className="wd-30p">Item</th>
															<th className="wd-35p">Description</th>
															<th className="tx-right">Amount(UGX)</th>
														</tr>
													</thead>
													<tbody>

														{record.invoice_items.items.map((item, key) =>
															<tr key={key}>
																<td className="tx-12">{item.billing_dates.long_date}</td>
																<td>{item.item_type.item_type_name}</td>
																<td></td>
																<td className="tx-right">{item.amount.amount_c}</td>
															</tr>)}

														<tr>
															<td className="valign-middle" colSpan="2" rowSpan="4">
																<div className="invoice-notes">
																	<label className="main-content-label tx-13">Notes</label>
																	<p>{record.particulars.description}</p>
																	{record.particulars.session_info && 
																		<>
																			<br/>
																			<span>Start time: <span className="font-weight-normal">{record.particulars.session_info.start_time.time}</span></span><br/>
																			<span>End time: <span className="font-weight-normal">{record.particulars.session_info.end_time.time}</span></span><br/>

																		</>
																	}
																</div>
															</td>
															<td className="tx-right">Total Bill</td>
															<td className="tx-right" colSpan="2">UGX.{record.invoice_items.total.amount_c}</td>
														</tr>
														<tr>
															<td className="tx-right">Total Paid</td>
															<td className="tx-right" colSpan="2">UGX.{record.payment.paid.amount_c}</td>
														</tr>
														<tr>
															<td className="tx-right">Discount</td>
															<td className="tx-right" colSpan="2">UGX. 0</td>
														</tr>
														<tr>
															<td className="tx-right tx-uppercase tx-bold tx-inverse">Total Due</td>
															<td className="tx-right" colSpan="2">
																<h4 className="tx-bold">UGX.{record.payment.balance.amount_c}</h4>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>


										<div className="card-footer text-right">
											
											{/* <button type="button" className="btn ripple btn-secondary mb-1"><i className="fe fe-send mr-1"></i> Send Invoice</button> */}
											<button type="button" 
												onClick={this.print}
												className="btn ripple btn-info mb-1">
													<i className="fe fe-printer mr-1"></i> Print Invoice</button>
										</div>
									</div>
								</div>}

								{!record && <ActivityLoader />}
							</div>





						</div>

					</div>


				</div>
			</div>
		)


	}
}

export default PopInvoice;
