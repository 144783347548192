import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import PopNewHoliday from '../../../Components/Holiday/PopNewHoliday'
import dictionary from '../../../utils/dictionary'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import PopImportHolidays from '../../../Components/PopImportHolidays'


const eformats = dictionary._exportConfigBtnFormats

class ConfigPublicHolidays extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        totalTransaction: "...",
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlDelete: false,

        modal: false,
    }

    componentDidMount() {
        this.getTransactions();
        this.totalTransactions();
    }

    getTransactions = async () => {
        // this.setState({
        //     transactionList: false
        // })
        const server_response = await ajax.getPublicHolidays();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: false
            }, () => {
                this.setState({
                    transactionList: server_response.details.content
                })
            })

        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }

    totalTransactions = async () => {
        const server_response = await ajax.getTotalHolidays();
        if (server_response.status === "OK") {
            this.setState({
                totalTransaction: false
            }, () => {
                this.setState({
                    totalTransaction: server_response.details.content.total_c
                })
            })

        } else {
            this.setState({
                totalTransaction: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewAmount: false
        }, () => {
            this.setState({
                mdlNewAmount: <PopNewHoliday
                    onConfirm={this.onConfirmEvent} />
            }, () => {
                $("#modal_new_holiday").modal('show')
            })
        })
    }


    onConfirmEvent = async (eventName, eventDate) => {
        const server_response = await ajax.createPublicHoliday(eventName, eventDate);

        if (server_response.status === "OK") {
            $("#modal_new_holiday").modal('hide')
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Delete public holiday?"
                        description="Are you sure you want to delete this public holiday?"
                        onConfirm={() => this.onConfirmDelete()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        }
    }

    onConfirmDelete = async () => {

        if (this.state.transactionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deletePublicHoliday(this.state.transactionId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.transactionId);
                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }



    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Event
                </button>

                <button className="btn ripple btn-primary" onClick={this.onClickImportHolidays}>
                    <i className="fe fe-plus-circle mr-1"></i> Import
                </button>

            </>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Event
                </a>
                <a className="dropdown-item" onClick={this.onClickDelete}>
                    <i className="fe fe-trash-2 mr-2"></i>Delete Event
                </a>

            </>
        )
    }


    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    onClickImportHolidays = () => {
        const { getTransactions } = this.getTransactions();;
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopImportHolidays
                    refresh={getTransactions}
                />
            }, () => functions.openPopup('modal_import_holidays'))

        })
    }

    render() {

        const transactionList = this.state.transactionList
        const totalTransaction = this.state.totalTransaction

        return (
            <div className="page">
                {this.state.mdlNewAmount}
                {this.state.mdlDelete}
                {this.state.modal}


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Public Holidays"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <div className="d-flex justify-content-between align-items-center filter-example">

                                                    <TableHeader
                                                        title="Public Holiday configuration"
                                                        subtitle="Special events on which parking will be free. No surcharge"
                                                    />

                                                    <div className="text-wrap">
                                                        <div className="">
                                                            <div className="btn-list">

                                                                <button type="button" className="btn ripple btn-primary btn-sm">
                                                                    Total Holidays <span className="badge badge-light ml-2">{totalTransaction}</span>
                                                                </button>

                                                                <button className="btn ripple btn-light btn-sm" onClick={this.getTransactions}>
                                                                    <i className="fa fa-redo mr-1"></i> Refresh
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive allView-config border-top">
                                                    {(transactionList === "404") && <NoContent />}

                                                    {transactionList && Array.isArray(transactionList) &&
                                                        <table id="config-publicHolidays" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>No.</th> */}
                                                                    <th>Event Title</th>
                                                                    <th>Event Date</th>
                                                                    <th>Created At</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transactionList && transactionList !== "404" &&
                                                                    transactionList.map((item, key) =>
                                                                        <tr
                                                                            id={"br_" + item.holiday_id}
                                                                            key={key}
                                                                            className={`${this.state.transactionId == item.holiday_id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelectTransaction(item.holiday_id, item)}>
                                                                            {/* <td>{key + 1}</td> */}
                                                                            <td><i className="fa fa-sticky-note text-primary mr-2" style={{ fontSize: "17px" }}></i> {item.event_name}</td>
                                                                            <td>{item.event_date}</td>
                                                                            {/* <td>{item.created_at.long_date}</td> */}
                                                                            <td>
                                                                                {item.created_at.date} <br />
                                                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!transactionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigPublicHolidays
