import React from 'react'
// import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import PageNav from '../Common/PageNav';
const eformat = dictionary._exportBtnFormats

class VehicleStatement extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    transactionList: false,
    metaData: false,
    plateNumber: this.props.plateNumber,
    currentPage: 1,
    pagination: false
  }
  componentDidMount() {

    this.listTransactions();
  }



  listTransactions = async () => {
    const server_response = await ajax.getVehicleStatement(this.state.plateNumber, this.state.currentPage);
    if (server_response.status === "OK") {
      this.setState({
        transactionList: server_response.details.content.list,
        metaData: server_response.details.content.meta
      }, () => this.paginate())
    } else {
      this.setState({
        transactionList: "404",
        metaData: false
      })
    }
  }


  //PAGINATION FUNCTIONS

  onClickPage = (page) => {
    this.setState({
      currentPage: page,
      List: false
    }, () => this.listPayments())
  }

  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
      this.setState({
        currentPage: this.state.currentPage + 1,
        List: false

      }, () => {
        this.listPayments();
      })
    }

  }

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1,
        List: false
      }, () => {
        this.listPayments();
      })
    }

  }




  //==============NAVIGATION CONTROLS START===========================
  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
      this.setState({
        currentPage: this.state.page + 1,
        transactionList: false

      }, () => {
        this.listTransactions();
      })
    }

  }

  onClickPage = (page) => {
    this.setState({
      currentPage: page,
      transactionList: false
    }, () => this.listTransactions())
  }

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState({
        currentPage: this.state.page - 1,
        transactionList: false

      }, () => {
        this.listTransactions();
      })
    }

  }

  paginate = () => {
    if (this.state.metaData) {
      this.setState({
        pagination: false
      }, () => {

        this.setState({
          pagination: <PageNav
            totalPages={this.state.metaData.total_pages}
            pageList={this.state.metaData.list_of_pages}
            currentPage={this.state.currentPage}
            onClickNext={this.onClickNext}
            onClickPage={this.onClickPage}
            onClickPrevious={this.onClickPrevious}
          />
        })

      })

    }
  }





  //==============NAVIGATION CONTROLS END===========================










  render() {
    const transactionList = this.state.transactionList;
    return (
      <div className="row">
        <div className="col-sm-12 col-xl-12 col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">Vehicle Statment</h6>
              </div>
              <div className="table-responsive">
               {Array.isArray(transactionList) && <table id={"VehiclestatementID"} className="table table-hover text-nowrap mb-0 datatable" export-format={eformat}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Invoice(Dr)</th>
                      <th>Payment(Cr)</th>
                      <th>Balance(UGX)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList.map((item, key) =>
                        <tr key={key}>
                          <td>{item.trans_date.long_date}</td>
                          <td>{item.description}</td>
                          <td>{item.debit.amount * 1 > 0 ? item.debit.amount_c : ''}</td>
                          <td>{item.credit.amount * 1 > 0 ? item.credit.amount_c : ''}</td>
                          <td><b>{item.balance.amount_c}</b></td>
                        </tr>
                      )}
                  </tbody>
                </table>}

                {this.state.pagination}


                {transactionList == '404' && <NoContent/>}

                {!transactionList && <ActivityLoader />}
              </div>
            </div>
          </div>


        </div>
      </div>

    )
  }

}

export default VehicleStatement