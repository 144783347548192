import React from 'react'
import { Link } from 'react-router-dom';
import functions from '../utils/functions';
import ActivityLoader from './Common/ActivityLoader';
import NoContent from './Common/NoContent';
import TableHeader from './Common/TableHeader';
import PopSessionLocation from './Operations/PopSessionLocation';
import PopViewImage from './PopViewImage';
import $ from 'jquery';
import PaymentDueContext from '../Context/ContraventionContext/PaymentDueContext';

class RecentPaymentDues extends React.Component {

    static contextType = PaymentDueContext;

    state = {
        userId: functions.sessionGuard(),
        sessionList: false,
        locationPIN: false,
        imageView: false,
    }

    componentDidMount() {
        const { getRecentPaymentDuesTransactions } = this.context;
        getRecentPaymentDuesTransactions();
    }

    onClickViewVehiclePhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewImage image={record} />
            }, () => {
                $("#modal_view_image").modal('show');
            })
        })

    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    ViewAllSessions = () => {
        this.props.history.push('/parking_sessions/sessions')
    }


    render() {

        const { recentPaymentDues } = this.context

        return (
            <div className="card custom-card ">
                {this.state.imageView}
                <div className="card-body map-card">

                    <TableHeader
                        title="Recent Payment Dues"
                        subtitle="A representation of the recent payment Due activity."
                        view="View All"
                        href={this.props.href}
                    />

                    {/* <div className="border-top mt-3"></div> */}
                    {this.state.locationPIN}
                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        {/* <th>PAYMENT DUE ID.</th> */}
                                        <th>Vehicle</th>
                                        <th>Defaulted On</th>

                                        <th>Total Amount</th>

                                        <th>Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (recentPaymentDues === "404")
                                            ?
                                            <tr>
                                                <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="7">
                                                    {/* <img alt="no-content" src={nocontent} /> */}
                                                    <NoContent />
                                                </td>
                                            </tr>
                                            :
                                            recentPaymentDues && recentPaymentDues !== "404" &&
                                            recentPaymentDues.map((item, key) =>
                                                <tr key={key}>
                                                    {/* <td>
                                                        {item.ref}
                                                    </td> */}
                                                    <td className="tx-bold">
                                                        <Link to={
                                                            {
                                                                pathname: `/vehicles/profile/${item.vehicle.vehicle_number}`,
                                                                state: { plateNumber: item.vehicle.vehicle_number }
                                                            }}>{item.vehicle.vehicle_number}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                        {/* {item.created_at.date} */}
                                                    </td>

                                                    <td>{item.total_amount_c}</td>
                                                    <td>
                                                        {item.uncleared == 1 &&
                                                            <span className="badge rounded badge-secondary">UNCLEARED</span>
                                                        }
                                                        {item.uncleared == 0 &&
                                                            <span className="badge rounded badge-success">RESOLVED</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )}


                                </tbody>

                            </table>
                            {!recentPaymentDues && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

export default RecentPaymentDues
