import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
// import TitleValueCard from '../../../Components/Common/TitleValueCard'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import ConfigurationContext, { ConfigurationConsumer } from '../../../Context/ConfigurationContext'
import dictionary from '../../../utils/dictionary'
import PopImportLocations from '../../../Components/PopImportLocations'
import NoContent from '../../../Components/Common/NoContent'
import TableHeader from '../../../Components/Common/TableHeader'
import PopImportUpdateLocations from '../../../Components/PopImportUpdateLocations'
const eformats = dictionary._exportConfigBtnFormats

class ConfigLocation extends React.Component {

    static contextType = ConfigurationContext

    state = {
        userId: functions.sessionGuard(),
        locationId: false,
        locationRecord: false,
        toast: '',

        info: false,
        modal: false,
        CreateLocation: '',

        dialogueNewAssignment: false,
    }


    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/locations/new'
        })
    }

    onClickImporLocations = () => {
        const { getLocations } = this.context;
        this.setState({
            model: false
        }, () => {
            this.setState({
                model: <PopImportLocations
                    refresh={getLocations}
                />
            }, () => functions.openPopup('modal_import_locations'))

        })
    }

    onClickImportUpdateLocations = () => {
        const { getLocations } = this.context;
        this.setState({
            model: false
        }, () => {
            this.setState({
                model: <PopImportUpdateLocations
                    refresh={getLocations}
                />
            }, () => functions.openPopup('modal_import_update_locations'))

        })
    }

    buttons = () => {
        return (
            <>
                {/* <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-1"></i> Add
                </button> */}

                <button className="btn ripple btn-primary" onClick={this.onClickImporLocations}>
                    <i className="fe fe-plus-circle mr-1"></i> Import
                </button>

                <button className="btn ripple btn-secondary" onClick={this.onClickImportUpdateLocations}>
                    <i className="fe fe-plus-circle mr-1"></i> Import Update
                </button>

                {/* <>
                    <a className="btn ripple btn-primary dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <i className="fe fe-settings mr-1"></i> Import <i className="fas fa-caret-down ml-1"></i>
                    </a>
                    <div className="dropdown-menu tx-13">
                        <a className="dropdown-item" onClick={this.onClickImporLocations}>
                            <i className="fe fe-plus-circle mr-1"></i> New locations
                        </a>
                        <a className="dropdown-item" onClick={this.onClickImportUpdateLocations}>
                            <i className="fe fe-edit mr-1"></i> Updated locations
                        </a>
                    </div>
                </> */}
            </>
        )
    }

    onClickEdit = () => {

        if (this.state.locationRecord) {

            this.props.history.push({
                pathname: '/config/locations/edit',
                search: '?q=1',
                state: { record: this.state.locationRecord }
            })
        }

    }

    onClickDelete = () => {
        // if(this.state.locationRecord)
        // {
        //     $("#modal_delete").modal('show')
        // }
    }

    onConfirmDelete = async () => {

        if (this.state.locationId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteBranch(this.state.locationId, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.locationId);
                    //Refresh list
                    this.getBranches();
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }

    onSelectRecord = (locationId, locationRecord) => {
        this.setState({
            locationId: locationId,
            locationRecord: locationRecord,
            toast: ''
        })
    }

    RenderLocations = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const LocationList = props.locationList
                    return (
                        <div className="table-responsive allView-config border-top">
                            {(LocationList === "404") && <NoContent />}

                            {LocationList && Array.isArray(LocationList) &&
                                <table id="config-location" className="table text-wrap mb-0 table-hover datatable" export-format={eformats}>
                                    <thead>
                                        <tr>
                                            {/* <th>No.</th> */}
                                            <th>Location</th>
                                            <th>Street</th>
                                            <th>Branch</th>
                                            <th>Slots</th>
                                            <th className="text-center">Is nonparking zone</th>
                                            {/* <th>Description</th> */}
                                            <th>Created at</th>
                                        </tr>
                                    </thead>

                                    <tbody>


                                        {LocationList && LocationList !== "404" &&
                                            LocationList.map((item, key) =>
                                                <tr
                                                    id={"loc_" + item.location_id}
                                                    key={key}
                                                    className={`${this.state.locationId == item.location_id ? 'flag-row' : ''}`}
                                                    onClick={() => this.onSelectRecord(item.location_id, item)}
                                                >
                                                    {/* <td>{key + 1}</td> */}
                                                    <td>{item.location_name}</td>
                                                    <td>{item.street.street_name}</td>
                                                    <td>{item.street.branch.branch_name}</td>
                                                    <td>{item.total_slots.total_c}</td>
                                                    <td className="text-center">
                                                        {item.is_nonparking_zone === "0" &&
                                                            <span className="btn ripple btn-success btn-sm">Parking</span>
                                                        }

                                                        {item.is_nonparking_zone === "1" &&
                                                            <span className="btn ripple btn-danger btn-sm">No Parking</span>
                                                        }
                                                    </td>
                                                    {/* <td className="text-center">
                                                        {item.is_nonparking_zone === "0" && <span className="badge rounded badge-success">Parking</span>}
                                                        {item.is_nonparking_zone === "1" && <span className="badge rounded badge-danger">No Parking</span>}
                                                    </td> */}
                                                    {/* <td className="w-25">{item.description}</td> */}
                                                    {/* <td  style={{ width: "100px" }}>
                                                        {item.description.length >= 30 ? (item.description.charAt(0).toUpperCase() + item.description.slice(1).toLowerCase()).substring(0, 30) + "..." : item.description}
                                                    </td> */}
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                    </td>
                                                    {/* <td>
                                              {item.is_nonparking_zone === "0" &&
                                                <div className="d-flex align-items-center">
                                                    <p className="task-icon-user m-0 bg-success mr-2"></p>
                                                    <span className="text-muted tx-13 m-0">active</span>
                                                </div>
                                              }
                                              {item.is_nonparking_zone === "1" &&
                                                  <div className="d-flex align-items-center">
                                                      <p className="task-icon-user m-0 bg-danger mr-2"></p><span className="text-muted tx-13 m-0">no parking</span>
                                                  </div>
                                              }
                                            </td> */}

                                                </tr>
                                            )
                                        }

                                    </tbody>

                                </table>
                            }

                            {!LocationList && <ActivityLoader />}
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }


    RenderLocationCount = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const countAllLocations = props.totalLocations
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        Total Locations <span className="badge badge-light ml-2">{countAllLocations}</span>
                                    </button>

                                    <button className="btn ripple btn-light btn-sm" onClick={this.context.getLocations}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                </div>
                            </div>
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }



    render() {
        return (
            <div className="page">
                <WarnDelete
                    title="Delete Location?"
                    description="Make sure this branch has not streets and locations under it"
                    onConfirm={() => this.onConfirmDelete()} />



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Locations"
                            previousPageTitle="Configurations"
                            back="/config"
                            buttons={<this.buttons />}
                            options={{
                                "add": () => this.onClickAdd(),
                                "edit": () => this.onClickEdit(),
                                "delete": () => this.onClickDelete()
                            }}

                        />

                        {this.state.toast}
                        {this.state.model}

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <div className="d-flex justify-content-between align-items-center filter-example">

                                                    <TableHeader
                                                        title="List Of Parking Locations"
                                                        subtitle="List of all parking locations managed by Mutiplex parking"
                                                    />

                                                    <this.RenderLocationCount />
                                                </div>

                                                <this.RenderLocations />
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>

                            <ConfigMenu />



                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigLocation
