import React from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import ActivityLoader from './Common/ActivityLoader';
import AlertError from './Common/AlertError';
import SelectSearch from './Common/SelectSearch';
import TextInput from './Common/TextInput';

class PopSessionLocation extends React.Component {

    state = {
        info: '',
        locationId: '',
        userId: this.props.userId,
        locationList: false,
        slots: '',
        selectedLocation: false,
        loading: false

    }

    componentDidMount() {
        this.getLocations();

    }

    onChangeLocation = (selectedOption) => {
        this.setState({
            locationId: selectedOption.value,
            selectedLocation: false,
            loading: true
        }, () => {
            this.getLocation()
        })
    }

    onChangeSlots = (selectedOption) => {
        this.setState({ slots: selectedOption.value })
    }


    onConfirm = (event) => {
        event.preventDefault();
        const { locationId, slots } = this.state
        if (locationId.length > 0 && slots * 1 > 0) {
            this.props.onConfirm(this.state.locationId, this.state.slots);
        } else {
            this.setState({
                info: <AlertError message={dictionary._requireLocation} />
            })
        }
    }

    getLocations = async () => {

        const server_response = await ajax.listUserLocation(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                locationList: server_response.details.content
            })
        } else {
            this.setState({
                locationList: "404"
            })
        }
    }


    getLocation = async () => {

        const server_response = await ajax.getLocation(this.state.locationId);
        if (server_response.status === "OK") {
            this.setState({
                selectedLocation: server_response.details.content,
                loading: false
            })
        }
    }


    render() {
        const locationList = this.state.locationList;
        const optionsLocation = [];
        if (locationList && locationList !== "404") {
            locationList.map((item, key) =>
                optionsLocation.push({ label: item.location_name + ' along ' + item.street.street_name, value: item.location_id })
            )
        }


        const slotList = this.state.selectedLocation;
        const optionSlots = [];
        if (slotList && slotList !== "404") {
            slotList.slot_list.map((item, key) =>
                optionSlots.push({ label: item == 1 ? item + " Slot" : item + " Slots", value: item })
            )
        }

        return (
            <div className="modal" id="modal_session_location">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>


                                <SelectSearch
                                    label="Conductor Location"
                                    onChange={() => this.onChangeLocation}
                                    options={optionsLocation}
                                    placeholder={"Select Location..."}
                                />


                                {this.state.selectedLocation && <SelectSearch
                                    label="Number Of Slots"
                                    onChange={() => this.onChangeSlots}
                                    options={optionSlots}
                                    placeholder={"Select Slots..."}
                                />}

                                {this.state.loading && <ActivityLoader />}

                            </form>


                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Session Start</button>
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopSessionLocation;
