import React, { useContext, useEffect, useState } from "react"
import NoContent from "../../../Components/Common/NoContent"
import ActivityLoader from "../../../Components/Common/ActivityLoader"
import PageContainer from "../../../Components/Layout/PageContainer"
import PageHeader from "../../../Components/Common/PageHeader"
import dictionary from "../../../utils/dictionary"
import TableHeader from "../../../Components/Common/TableHeader"
import LoadMoreButton from "../../../Components/Other/LoadMoreButton"
import { Link } from "react-router-dom"
import NonPaymentContext from "../../../Context/Violation/NonPaymentContext"
import NonPaymentFilter from "../../../Components/Operations/Violation/NonPaymentFilter"

const NonPaymentPage =(props)=>{

    const { 
            allTransactions, 
            goToNextPage,
            getAllTransactions,
            setData,
            resetData,
            filterData,
            metaData,
            loading 
        } = useContext(NonPaymentContext)

    const [selection, setSelection] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalId, setModalId] = useState(false);

    
   

    
  const RenderFilter = () => {
          
    return <NonPaymentFilter/>

  }


    const buttons = () => {
        return (
            <>
                {allTransactions  && 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                }



             <a className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                   
                  
                    <Link to="/enforcement/transactions" className="dropdown-item">
                     <i className="fas fa-list text-primary mr-2"></i>All transactions</Link>
                   

                    <Link to="#" className="dropdown-item" onClick={getAllTransactions}>
                    <i className="fa fa-redo text-secondary mr-2"></i>Refresh</Link>

                  

              </div>


      
            </>
        )
      }

    return  <PageContainer>

                <PageHeader
                    title="Non Payment enforcement"
                    previousPageTitle="Enforcement"
                    back="/enforcement/transactions"
                    buttons={buttons()}
                    />
                 {modal}
                 {RenderFilter()}

                 <div className="row">
                  <div className="col-sm-12 col-xl-12 col-lg-12">

                    <div className="card custom-card">
                        <div className="card-body map-card">

                          <TableHeader
                                    title={"Clamp transactions "}
                                    subtitle="List of all Non payment clamp transactions"
                                />

                            
                             {Array.isArray(allTransactions) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }
                        
                            <div className="table-responsive allView-table border-top">


                                    {Array.isArray(allTransactions) &&
                                        <table id="table-parking-enforcement" export-format={dictionary._exportBtnFormats} className="table text-nowrap table-hover mg-b-0 datatable">
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Clamp ref</th>
                                                    <th>Vehicle</th>
                                                    <th>Due Amount</th>
                                                    <th>Incident Date</th>
                                                    <th>Location</th>
                                                    <th>Street</th>
                                                    <th>Status</th>
                                                    <th>Last Updated</th>
                                                    <th>Clamp Cause</th>
                                                    <th>Violation</th>
                                                    <th>Reported By</th>
                                                    <th>Clamped By</th>
                                                    <th>Clamping Location</th>
                                                    <th>Unclamped By</th>
                                                    <th>Clamp Incident</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {allTransactions.map((item, key)=>
                                                    <tr key={key} onClick={()=>setSelection(item)} className={`${item.transaction_id*1==selection.transaction_id && 'flag-row'}`}>
                                                        <td>{key + 1}</td>
                                                        <td>{item.ref}</td>
                                                        <td>{item.vehicle.plate_number}</td>
                                                        <td>{item.due_amount.amount_p}</td>
                                                        <td>{item.created_at.short_date + " " + item.created_at.time}</td>
                                                        <td>{item.initial_location.location_name}</td>
                                                        <td>{item.initial_location.street.street_name}</td>
                                                        <td style={{fontSize:'12px'}}>{item.status}</td>
                                                        <td>{item.updated_at.short_date + " " + item.updated_at.time}</td>
                                                        <td>{item.clamped_for.cause.cause}</td>
                                                        <td>{item.clamped_for.violation_name}</td>
                                                        <td>{item.reported_by.full_name}</td>
                                                        <td>{item.clamped_by.full_name}</td>
                                                        <td>{item.clamping_location.location_name} {item.clamping_location?item.clamping_location.street.street_name : ''}</td>
                                                        <td>{item.unclamped_by.full_name}</td>
                                                        <td>{item.incident?<span><b>{item.incident.reason.reason}</b> effected by <i>{item.incident.cancelled_by.full_name}</i></span> : <span style={{fontSize:'12px'}}>NO INCIDENT REPORTED</span>}</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>}


                                        {(allTransactions === "404") && <NoContent />}
                                        {!allTransactions && <ActivityLoader />}
                                </div>

                            </div>
                        </div>
                    </div>
        </div>
</PageContainer>}


export default NonPaymentPage