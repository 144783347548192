import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertWarning from '../Common/AlertWarning';
import BoolInput from '../Common/BoolInput';
import TextInput from '../Common/TextInput';

class NewPaymentMode extends React.Component {

    state = {
        modeName: '',
        modeCode:'',
        manual:true,
        enabled:false,
        info: '',
        loading: false
    }

    onChangeModeName = (event) => {
        this.setState({ modeName: event.target.value });
    }

    onChangeModeCode = (event) => {
        this.setState({ modeCode: event.target.value });
    }

    onClickEnabled=(event)=>{
        this.setState({
            info: <AlertWarning message={"Payment mode will be enabled upon configuration"} />
        })
    }

    onClickManual=(event)=>{
        this.setState({manual:!this.state.manual})
    }

    onConfirm = async(event) => {
        event.preventDefault();
        const{modeCode, modeName, manual, enabled}=this.state;
        if (modeName.length > 0 && modeCode.length) {
            this.setState({
                loading: true
            })

            const server_response = await ajax.addPaymentMode(
                modeName,
                modeCode,
                manual?"1":"0",
                enabled?"1":"0"
                );
            this.setState({
                loading: false
            })
            if (server_response.status === "OK") {
                
                    this.props.onConfirm(server_response.details.message);
            } else {
                this.setState({info: <AlertError message={server_response.details.message} />})
            }
    

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }



    render() {
        return (
            <div className="modal" id="modal_set_payment_mode">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Payment Mode</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Mode Name"
                                    type="text"
                                    onChange={() => this.onChangeModeName}
                                    value={this.state.modeName}
                                />

                                <TextInput
                                    label="Mode Code"
                                    type="text"
                                    onChange={() => this.onChangeModeCode}
                                    value={this.state.modeCode}
                                />

                                 <BoolInput
                                     label="User Triggered"
                                     value={this.state.manual?true:false}
                                     onClick={() => this.onClickManual}
                                     />

                                 <BoolInput
                                     label="Enabled"
                                     value={this.state.enabled?true:false}
                                     onClick={() => this.onClickEnabled}
                                     />


                            </form>

                            <span>After adding a new payment mode, additional configurations might be required. Please contact support for more information.</span>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Save Payment Mode</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default NewPaymentMode;
