import React from 'react';
import { Link } from 'react-router-dom';
// import ajax from '../../utils/ajax';
// import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import InitialAvatar from '../Common/InitialAvatar';
import TextInput from '../Common/TextInput';

class PopAddToVip extends React.Component {

    state={
        loading:false,
        reason:'',
        date:'',
        info:false
    }

    destroyModal=()=>{
        functions.closePopup("modal_add_to_vip");
    }

    onConfirm=()=>{
        const {reason, date}=this.state
        if(reason.length>0)
        {
            this.setState({
                loading:true
            }, ()=>{
                this.props.onConfirm(reason, date)
            })
        }else{
            this.setState({
                info:<AlertError message="Enter VIP reason, then try again"/>
            })
        }
    }

    onChangeReason = (event) => {
        this.setState({ reason: event.target.value })
    }

    onChangeDate = (event) => {
        this.setState({ date: event.target.value })
    }

  render(){
      const vehicle=this.props.vehicleInfo
      const title="Note: The vehicle can only be added to the VIP for a specifically selected period of time.";
      return (
        <div className="modal" id="modal_add_to_vip">
        <div className="modal-dialog modal-dialog-centered" role="document">
            

        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Add to VIP</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                {this.state.info}
                
                <div className="justify-content-center text-center mt-3 d-flex">
                    <InitialAvatar value={<i className="fas fa-taxi"></i>}/>
                </div>
                <div className="justify-content-center text-center mt-3 d-flex">
                    <h5 className="mb-1 mt-0 ">{vehicle.plate_number}</h5>
                </div>

                <div className="">
                   
                <TextInput
                        label="Valid Until"
                        type="date"
                        onChange={() => this.onChangeDate}
                        value={this.state.date}
                    />
                    <TextInput
                        label="VIP Reason"
                        type="textarea"
                        onChange={() => this.onChangeReason}
                        value={this.state.reason}
                    />
                     <p className="mb-1 tx-bold">{title}</p>
                </div>
               
               
            
            </div>
            <div className="modal-footer">

                    {this.state.loading && <ActivityLoader/>}
                    {!this.state.loading &&
                    <>
                        <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Confirm VIP</button>
                        <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                    </>}
              
            </div>
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopAddToVip;
