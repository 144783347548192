import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from '../../Context/ThemeContext';
import functions from '../../utils/functions';


const ViewAs=()=>{

    const {viewAsRole} = useContext(ThemeContext)


    const handleViewAs=()=>{
        functions.openPopup("modal_view_as")
    }

    return (
        <>
         <div className="dropdown d-md-flex header-search">
                {functions.getRoleId() *1 === 1 && <Link to="#" title={viewAsRole} className="nav-link icon header-search" onClick={handleViewAs}>
                    <i className="fas fa-eye tx-light"></i>
                </Link>}
            
         </div>
        </>
      

    );


  
}

export default ViewAs;