import React from 'react';
import LeftSideBar from '../Components/Common/LeftSideBar';
import Footer from '../Components/Common/Footer';
import TopBar from '../Components/Common/TopBar';
import PageHeader from '../Components/Common/PageHeader';
import functions from '../utils/functions';
import dictionary from '../utils/dictionary';
import ActivityLoader from '../Components/Common/ActivityLoader';
import RightSideBar from '../Components/Common/RightSideBar';
import ajax from '../utils/ajax';
// import "../assets/paper-push-pin.png";
// import $ from 'jquery';

// import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from 'react-google-maps';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { RenderOnline } from '../utils/script/RenderOnline';
import { MapConsumer } from '../Context/MapContext';
// import ParkingMapMarker from '../Components/ParkingMapMarker';
// import Markers from '../Components/CustomMarker';



// const mapStyles = {
//   // position: "relative",
//   width: "100%",
//   height: "75vh",
//   // border: "1px solid red",
//   // zIndex: "1"
// };

class GoogleParkingMap extends React.Component {


  state = {
    userId: functions.sessionGuard(),
    locationList: false,
    locationParkingZoneList: false,
    locationNonParkingZoneList: false,
    status: '',

    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {},          // Shows the InfoWindow to the selected place upon a marker

    coords: this.props.coords,
    // defaultCenter: { lat: 0.347600, lng: 32.582500 },
    // defaultCenter: { lat: 0.303916, lng: 32.575631 },
    defaultCenter: { lat: 0.319936, lng: 32.590523 },
    // lat: 0.347600,
    // lng: 32.582500
    center: this.props.center,


    // mapMarker: null,
    // showingInfoWindow: false,
    showParkingInfoIndex: '',
    showNonParkingInfoIndex: '',

    selectedCenter: '',
    setSelectedCenter: '',
    plateNumber: '',

    directions: null,
  }


  componentDidMount() {
    window.initMap = function () {
      console.log('callback invoked');
    }
    // await this.getLocationCoordinates();
    // await this.getLocationZones();

    // await this.getParkingZones();
    // await this.getNonParkingZones();

    // if (window.google) {
    //   const directionsService = new window.google.maps.DirectionsService();

    //   const origin = { lat: 0.294930, lng: 32.549200 };
    //   const destination = { lat: 0.294510, lng: 32.555040 };

    //   directionsService.route(
    //     {
    //       origin: origin,
    //       destination: destination,
    //       travelMode: window.google.maps.TravelMode.WALKING
    //     },
    //     (result, status) => {
    //       if (status === window.google.maps.DirectionsStatus.OK) {
    //         this.setState({
    //           directions: result
    //         });
    //       } else {
    //         console.error(`error fetching directions ${result}`);
    //       }
    //     }
    //   );
    // }


  }



  getDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();

    const origin = this.state.currentLocation;
    const destination = this.state.markerPos;

    if (origin !== null && destination !== null) {
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    } else {
      console.log('Please mark your destination in the map first!');
    }
  };


  // onMarkerClick = () => {
  //   this.setState({
  //     showingInfoWindow: true
  //   });
  // };

  // onInfoWindowClose = () =>
  //   this.setState({
  //     showingInfoWindow: false
  //   });

  // onLoad = (mapMarker) => {
  //   this.setState({
  //     mapMarker
  //   });
  // };

  // showParkingInfo = (a) => {
  //   this.setState({
  //     showParkingInfoIndex: a
  //   })
  // }

  // showNonParkingInfo = (a) => {
  //   this.setState({
  //     showNonParkingInfoIndex: a
  //   })
  // }

  // getLocationCoordinates = async () => {

  //   const server_response = await ajax.listLocationCoordinates(this.state.userId);

  //   // console.log(server_response);

  //   if (server_response.status === "OK") {
  //     this.setState({
  //       locationList: server_response.details.content
  //     })
  //   } else {
  //     this.setState({
  //       locationList: "404"
  //     })
  //   }
  // }

  // getNonParkingZones = async () => {

  //   const server_response = await ajax.listLocationZones(this.state.userId, 1);
  //   // console.log(server_response.details.content)
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       locationNonParkingZoneList: server_response.details.content
  //     })
  //   } else {
  //     this.setState({
  //       locationNonParkingZoneList: "404"
  //     })
  //   }
  // }


  // getParkingZones = async () => {


  //   const server_response = await ajax.listLocationZones(this.state.userId, 0);

  //   // console.log(server_response);

  //   if (server_response.status === "OK") {
  //     this.setState({
  //       locationParkingZoneList: server_response.details.content
  //     })
  //   } else {
  //     this.setState({
  //       locationParkingZoneList: "404"
  //     })
  //   }
  // }


  MapView = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={17}
      // defaultCenter={this.state.defaultCenter}
      defaultCenter={props.defaultCenter}
      options={{
        mapTypeControl: false,
      }}
      defaultOptions={{
        // ...DEFAULT_MAP_OPTIONS,
        // styles: mapStyles,
        suppressMarkers: false,
        preserveViewport: true
      }}
    >
      {/* <DirectionsRenderer
        directions={this.state.directions}
      /> */}

      {/* {this.state.locationList && this.state.locationList !== "404" && this.state.locationList.map((item, index) => */}
      {Array.isArray(props.locationParkingZoneList) && props.locationParkingZoneList.map((item, index) =>


        <Marker
          key={index}
          options={
            {
              icon: (
                item.street.branch.branch_name === 'Alpha' ? dictionary.apiAssets + 'alpha2.svg' :
                  item.street.branch.branch_name === 'Bravo' ? dictionary.apiAssets + 'bravo.svg' :
                    item.street.branch.branch_name === 'Charlie' ? dictionary.apiAssets + 'charlie2.svg' :
                      item.street.branch.branch_name === 'Delta' ? dictionary.apiAssets + 'delta2.svg' :
                        item.street.branch.branch_name === 'Echo' ? dictionary.apiAssets + 'echo2.svg' :
                          dictionary.apiAssets + 'multi.svg'
              )
            }
          }
          position={{
            lat: item.lat * 1,
            lng: item.lng * 1
          }}
          // animation={window.google.maps.Animation.BOUNCE}
          // onMouseover={this.props.mouseEnterHandler}
          // onMouseout={this.props.mouseLeaveHandler}
          clickable
          onClick={() => props.showParkingInfo(item.location_id)}
        // options={{icon: 'http://www.firepineapple.com/application/views/images/map_marker.gif'}}
        >

          {props.showParkingInfoIndex == item.location_id &&
            <InfoWindow onCloseClick={props.onInfoWindowClose}>

              <div>
                <h5 className="text-secondary">{item.location_name}</h5>
                <span>Total slots: {item.total_slots.total}</span><br />
                <span>Active slots: <span className="text-success font-weight-bold">{item.active_slots.total}</span></span><br /><br />
                <span className="font-weight-bold">Vehicles in session: </span><br />
                <span>
                  {Array.isArray(props.vehicleListing) && <ol>
                    {props.vehicleListing.map((item, key) =>
                      <li className="tx-bold" key={key}><Link to={
                        {
                          pathname: `/vehicles/profile/${item}`,
                          state: { plateNumber: item }
                        }}>{item}
                      </Link>
                      </li>
                    )}
                  </ol>}
                  {!props.vehicleListing && <ActivityLoader height={20} width={40} />}

                </span>
              </div>

            </InfoWindow>
          }

        </Marker>


      )
      }

      {Array.isArray(props.locationNonParkingZoneList) && props.locationNonParkingZoneList.map((item, index) =>

        <Marker
          key={index}
          options={{ icon: dictionary.apiAssets + 'multi-non2.svg' }}
          position={{
            lat: item.lat * 1,
            lng: item.lng * 1
          }}
          // title={item.location_name}
          // onMouseover={this.props.mouseEnterHandler}
          // onMouseout={this.props.mouseLeaveHandler}
          // }}

          // animation={window.google.maps.Animation.BOUNCE}
          // onMouseover={this.props.mouseEnterHandler}
          // onMouseout={this.props.mouseLeaveHandler}
          clickable
          onClick={() => props.showNonParkingInfo(item.location_id)}
        // options={{icon: 'http://www.firepineapple.com/application/views/images/map_marker.gif'}}
        >

          {props.showNonParkingInfoIndex == item.location_id &&
            <InfoWindow onCloseClick={props.onInfoWindowClose}>

              <div>
                <h5 className="text-secondary">{item.location_name}</h5>
              </div>

            </InfoWindow>
          }

        </Marker>

      )
      }

    </GoogleMap>

  ))


  render() {

    // const GoogleMapExample = withGoogleMap(props => (
    //   <GoogleMap
    //     defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
    //     defaultZoom={13}
    //   >

    //   </GoogleMap>
    // ));

    return (
      <RenderOnline>

        <MapConsumer>
          {props => {
            return (

              <this.MapView
                isMarkerShown
                googleMapURL={
                  `https://maps.googleapis.com/maps/api/js?key=${dictionary._apiKey}&callBack=initMap&v=3.exp&libraries=geometry,drawing,places`
                }
                loadingElement={<ActivityLoader />}
                containerElement={
                  <div style={{ height: `100%`, width: `100%` }} />
                }
                mapElement={
                  <div style={{ height: `100%`, width: `100%` }} />
                }

                locationNonParkingZoneList={props.locationNonParkingZoneList}
                locationParkingZoneList={props.locationParkingZoneList}
                showNonParkingInfoIndex={props.showNonParkingInfoIndex}
                showParkingInfoIndex={props.showParkingInfoIndex}
                defaultCenter={props.defaultCenter}
                directions={props.directions}
                showNonParkingInfo={props.showNonParkingInfo}
                showParkingInfo={props.showParkingInfo}
                onPlaceSelected={props.onPlaceSelected}
                onInfoWindowClose={props.onInfoWindowClose}
                vehicleListing={props.vehicleListing}
              />

            )
          }}
        </MapConsumer>

      </RenderOnline>
    )
  }

}

export default GoogleParkingMap;
