import React from 'react';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import ajaxPayment from '../../utils/remote/ajaxPayment';
import AlertSuccess from '../Common/AlertSuccess';





class PopVehicleOpeningBalance extends React.Component {

    state = {

        info: "",
        balance: "",
        vehicleInfo: this.props.vehicleInfo
    }


    onConfirm = async (event) => {
        event.preventDefault();
        if (this.state.balance.length > 0) {
            const { balance, vehicleInfo } = this.state
            const server_response = await ajaxPayment.addVehicleOpeningBalance(vehicleInfo.plate_number, balance);
            if (server_response.status === "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Enter balance and try again"} />
            })
        }
    }

    onChangeBalance = (event) => {
        this.setState({ balance: event.target.value })
    }



    render() {


        return (
            <div className="modal" id="vehicle-manual-invoice">
                <div className="modal-dialog modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.vehicleInfo.plate_number}: Vehicle Opening Balance</h6>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <p><b>Add vehicle balance from the previous system. A new invoice will be created for vehicle and it will be considered a contravention invoice.</b>
                                <br />Each vehicle can only have one opening balance record hence any amount added here will update the already existing (unmigrated) balance.<br /><br />
                            </p>

                            <form method="post" onSubmit={this.onConfirm}>
                                <TextInput
                                    label="Opening Balance"
                                    type="number"
                                    name="openingBalance"
                                    onChange={this.onChangeBalance}
                                    value={this.state.balance}
                                    wrap
                                />
                            </form>

                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Update Opening Balance</button>
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopVehicleOpeningBalance;
