import React from 'react';
import { FinanceConsumer } from '../../../Context/FinanceContext';
import StatisticCard from '../../StatisticCard';


class CountMonthlyInvoices extends React.Component {
 


  render(){
    
      return (
        <FinanceConsumer>
            {props=>{
              return (
                <StatisticCard
                title="Number Of Invoices" 
                value={props.invoiceCount}
                caption="This month"
                icon={"fas fa-chart-bar"}
                color={"text-primary"}
                progressbar={"progress-bar progress-bar-xs wd-70p"}
                />
              )
            }}
        </FinanceConsumer>
       
      );
    
 
  }
}

export default CountMonthlyInvoices;