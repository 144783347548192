import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Common/ActivityLoader';
import NoContent from '../../Common/NoContent';
import TableHeader from '../../Common/TableHeader';
import PopViewImage from '../../PopViewImage';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';

const RecentEnforcement = (props)=> {

    const {recentTransactions} = useContext(ParkingViolationContext);

        return (
            <div className="card custom-card ">
                <div className="card-body map-card">

                    <TableHeader
                        title="Recent Enforcements"
                        subtitle="Most recent wrong parking & non-payment transactions."
                        view="View All"
                        href={"/enforcement/clamp_transactions"}
                    />

                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                        <div className="table-responsive">
                            {Array.isArray(recentTransactions) && <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th>Reported On</th>
                                        <th>Vehicle</th>
                                        <th>Location</th>
                                        <th>Reported By</th>
                                        <th>Contravention</th>
                                                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {recentTransactions.map((item, key) =>
                                        <tr key={key}>
                                            
                                            <td>
                                                {item.created_at.short_date} <br />
                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                            </td>
                                            <td className="tx-bold">
                                                <Link to={
                                                    {
                                                        pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                        state: { plateNumber: item.vehicle.plate_number }
                                                    }}>{item.vehicle.plate_number}
                                                </Link> <br/>
                                                <small className="tx-12 tx-gray-500">{item.status}</small>

                                            </td>

                                              
                                            <td>
                                                <span className="pointer">
                                                    <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                    {item.initial_location.location_name}<br />
                                                    <small className="tx-12 tx-gray-500">{item.initial_location.street.street_name}</small>
                                                </span>
                                            </td>

                                            <td>
                                                <h6 className="mg-b-1">{item.reported_by.full_name}</h6>
                                                <small className="tx-12 tx-gray-500">{item.reported_by.user_role.role_name}</small>
                                            </td>
                                            <td>
                                                <h6 className="mg-b-1">{item.clamped_for.cause.cause}</h6>
                                                <small className="tx-12 tx-gray-500">{item.clamped_for.violation_name}</small>
                                            </td>
                                          
                                                                                                
                                        </tr>
                                    )}


                                </tbody>

                            </table>}
                            {recentTransactions === '404' && <NoContent/>}
                            {!recentTransactions && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>

        )

    }


export default RecentEnforcement
