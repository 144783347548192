import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import InitialAvatar from '../Common/InitialAvatar';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class PopCreditCardPayment extends React.Component {

    state = {
        amount:this.props.vehicleInfo.balance.amount*1,
        depositorContact: '',
        depositorName: '',
        depositorEmail:'',
        info: '',
        whiteList:this.props.whiteList,
        vehicleList:[{'plate_number':this.props.plateNumber, 'balance':this.props.vehicleInfo.balance.amount*1}],
        plateNumber:'',
        searchList:false,
        externalList:true,
        loading:false
    }

    componentDidMount() {
      
        if(this.props.listId)
        {
            this.extractVehicleList(this.props.listId)
        }
    }
    

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }


    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value }, () => this.searchVehicle())
    }

    onChangeDepositorEmail =(event)=>{
        this.setState({ depositorEmail: event.target.value })

    }

  

    onConfirm = async(event) => {
        event.preventDefault();
        const {depositorContact, depositorName, depositorEmail, amount} = this.state
        if (depositorContact.length > 0 && 
            depositorName.length > 0 && 
            depositorEmail.length>0 && 
            amount>0) {

                const vehicleList = [];
                this.state.vehicleList.map((item, key) =>
                 vehicleList.push(item.plate_number)
                )
                
                this.setState({
                    loading:true
                })
                const server_response = await ajax.depositVehicleCardPayment(
                    depositorName,
                    depositorContact,
                    amount,
                    depositorEmail,
                    vehicleList
                );
                this.setState({
                    loading:false
                })
        
                if (server_response.status === "OK") {
                    //load receipt
                    functions.closePopup("modal_cheque_payment");
                    this.props.onConfirm(server_response.details)
                    // this.setState({
                    //     receipt: <PopPaymentSummary record={server_response.details.content} />
                    // }, () => {
                    //     functions.openPopup("modal_payment_summary");
                    // })
                } else {
                    
                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    })
                }
            

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
            
        }
    }


    

    onClickPlateNumber = (item) => {

        if(item.balance.amount*1==0)
        {
            this.setState({
                info: <AlertError message={item.plate_number + ' ' + ' has no outstanding balance'} />
            })
            return false;
        }
        this.setState({ plateNumber:item.plate_number }, ()=>{
            //get current vehicles
            const vehicleList=this.state.vehicleList
            for(var i=0; i<vehicleList.length; i++)
            {
                if(vehicleList[i].plate_number==item.plate_number)
                {
                    this.setState({
                        info: <AlertError message={item.plate_number + ' ' + ' has already been added'} />
                    })
                    return false;
                }
            }
            vehicleList.push({'plate_number':item.plate_number, 'balance':item.balance.amount*1});
            this.setState({
                vehicleList:vehicleList,
                amount:this.state.amount*1+item.balance.amount*1
            })
        })
    }




    searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(this.state.plateNumber);
        if (server_response.status === "OK") {
            this.setState({
                searchList: server_response.details.content
            })
        } else {
            this.setState({
                searchList: false
            })
        }
    }


    onClickRemovePlate=(item)=>{
        const vehicleList=this.state.vehicleList
        if(item.plate_number==this.props.plateNumber){
            this.setState({
                        info: <AlertError message={"Default vehicle cannot be removed. Please record this cheque using one of the paying vehicles"} />
                    })
            return false;
         }
        for(var i=0; i<vehicleList.length; i++)
        {
            if((vehicleList[i].plate_number==item.plate_number))
            {
                vehicleList.splice(i, 1);
            }
        }
        this.setState({
            vehicleList:vehicleList,
            amount:this.state.amount*1-item.balance
        })

    }


    extractVehicleList=async(list_id)=>{
        this.setState({
            externalList:false
        })
        const server_response = await ajax.extractPaymentVehicleListing(list_id);
        if(server_response.status=="OK")
        {   
            const vehicleList=this.state.vehicleList
            const externalList=server_response.details.content;
            externalList.map((item, key)=>{
                for(var i=0; i<vehicleList.length; i++)
                {
                    if(vehicleList[i].plate_number==item.plate_number)
                    {
                        // this.setState({
                        //     info: <AlertError message={item.plate_number + ' ' + ' has already been added'} />
                        // })
                        return false;
                    }
                }
                vehicleList.push({'plate_number':item.plate_number, 'balance':item.balance.amount*1});
                this.setState({
                    vehicleList:vehicleList,
                    amount:this.state.amount*1+item.balance.amount*1
                })
            })//ends map
        }

        this.setState({
            externalList:true
        })
    }


    render() {

     
        const plateList=this.state.vehicleList;
        const searchList = this.state.searchList;

        return (
            <>
                <div className="modal" id="modal_cc_payment">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New Card Payment</h6>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <div className="row">

                                <div className="col-4">
                                     <div className="justify-content-center text-center mb-3 d-flex">
                                        <InitialAvatar size={2} value={<i className="fas fa-car"></i>}/>
                                    </div>

                                    <div className="activity-block mr-3 scroll-widget border-top overflow-auto">
										<ul className="task-list">
											
											{plateList.map((item, key)=><li key={key}>
												<i className="task-icon bg-dark"></i>
												<h6>{item.plate_number}<Link to="#" className="float-right text-danger tx-14" onClick={()=>this.onClickRemovePlate(item)}>
                                                        <i className="fas fa-minus-circle"></i>
                                                    </Link>
                                                </h6>
											</li>)}
											
										</ul>

                                        {!this.state.externalList && <ActivityLoader/>}

									</div>




                                </div>



                                <div className="col-8">

                                <div className="justify-content-center text-center mb-3 d-flex">
                                        <InitialAvatar size={2} value={<i className="fas fa-credit-card"></i>}/>
                                </div>
                                <form method="post" onSubmit={this.onConfirm}>

                            

                                    <TextInput
                                        label="Total Bill"
                                        type="number"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                                    
                                    <TextInput
                                        label="Depositor's Name"
                                        placeholder="Last-Name  First-Name"
                                        type="text"
                                        onChange={() => this.onChangeDepositorName}
                                        value={this.state.depositorName}
                                    />

                                     <TextInput
                                        label="Depositor's Email"
                                        type="email"
                                        onChange={() => this.onChangeDepositorEmail}
                                        value={this.state.depositorEmail}
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                                <TextInput
                                    label="Add Vehicles"
                                    type="text"
                                    onChange={() => this.onChangePlateNumber}
                                    value={this.state.plateNumber}
                                />

                            {searchList && searchList !== "404" && <div className="btn-list">
                                {searchList.map((item, key) =>
                                    <button key={key}
                                        onClick={() => this.onClickPlateNumber(item)}
                                        type="button"
                                        className="btn ripple btn-light btn-sm">
                                        {item.plate_number}
                                    </button>)}
                            </div>}

                                </form>
                                </div>
                               
                                </div>



                            </div>
                            <div className="modal-footer">
                                {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Generate Payment Link</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                                {this.state.loading && <>
                                    <ActivityLoader/>
                                </>}
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }
}

export default PopCreditCardPayment;
