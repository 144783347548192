import React, { useContext, useState } from 'react';
import AlertError from '../../Common/AlertError';
import ConfigurationContext from '../../../Context/ConfigurationContext';
import TextInput from '../../Common/TextInput';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';
import dictionary from '../../../utils/dictionary';
import OptionButton from '../../Controls/OptionButton';
import { Link } from 'react-router-dom';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ThemeContext from '../../../Context/ThemeContext';


const PopTowVehicle=(props)=> {

    
        const vehicleId = props.record.vehicle.vehicle_id;
        const [info, setInfo] = useState(false);
        const [towingCharge, setTowingCharge] = useState('75000');
        const {setDisplayMessage} = useContext(ThemeContext);


        const { getAllTransactions} = useContext(ParkingViolationContext)
       


        const onConfirm = async (event) => {
            event.preventDefault();
            if (towingCharge.length > 0 && towingCharge>0) {

                    const server_response = await ajaxViolation.TowVehicle(vehicleId, towingCharge);
                    if(server_response.status === "OK"){
                        setDisplayMessage(functions.templateMessage("Towing:", server_response.message, "info"))
                        setInfo(<AlertSuccess message={server_response.message} />)
                        functions.closePopup("modal-tow-vehicle");
                        getAllTransactions();
                    }else{
                        setDisplayMessage(functions.templateMessage("Towing:", server_response.message, "danger"))
                        setInfo(<AlertError message={server_response.message} />)
                    }

             

            } else {
                setInfo(<AlertError message={dictionary._completeFields} />)
            }
        }



    
        return (
            <div className="modal" id="modal-tow-vehicle">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Tow Vehicle- {props.record.vehicle.plate_number}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      
                                <TextInput
                                    type="text"
                                    label="Tow Charge"
                                    onChange={(e) => setTowingCharge(e.target.value)}
                                    placeholder={"Enter towing charges..."}
                                    name="towingCharge"
                                    value={towingCharge}
                                    required
                                />
                                

                            </form>

                        
                            <p className='small'><b>NOTE:</b> An invoice amounting to the 'Tow charge' shall be generated for this vehicle. Please ensure that an appropiate tow charge is entered.</p>

                        </div>
                        <div className="modal-footer">

                            <button className="btn ripple btn-danger" type="button" onClick={onConfirm}>Tow Vehicle</button>

                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopTowVehicle;
