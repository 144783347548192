import React, { Component } from 'react';
import ajax from '../utils/ajax';
import functions from '../utils/functions';

const NotificationContext = React.createContext();

export const NotificationConsumer = NotificationContext.Consumer;

export class NotificationProvider extends Component {

    state = {
        countNotificationList: false,
        userId: functions.sessionGuard(),
        notificationsList: false,
        recentList: false,
        unreadList: false,
        status: "",
        countTodaysNotificationList: ""

    }

    componentDidMount() {
        this.countTodaysNotifications();
        this.getRecentNotifications();
        this.getTodaysUnReadNotifications();
        this.getUserNotifications();
        this.countUnreadMessages();
        // this.countTodaysNotifications();


    }

    //GET ALL USER NOTIFICATIONS
    getUserNotifications = async () => {
        const server_response = await ajax.getUserNotifications(this.state.userId);
        if (server_response.status === 'OK') {
            this.setState({
                notificationsList: server_response.details.content
            })
        }
        else {
            this.setState({
                notificationsList: "404"
            })
        }

    }
    //READ NOTIFICATION 
    readNotification = async (notification_id) => {
        const server_response = await ajax.readNotification(notification_id);
        if (server_response.status === 'OK') {

            this.setState({
                notificationsList: false
            }, () => {
                this.getUserNotifications()
                this.countTodaysNotifications()
            })
        }
        else {
            this.setState({
                readNotificationList: "404"
            })
        }
    }

    countUnreadMessages = async () => {
        const server_response = await ajax.countUnreadNotifications(this.state.userId);
        if (server_response.status === 'OK') {
            this.setState({
                countNotificationList: server_response.details.content
            }, () => {
                this.getUserNotifications()
            })
        }
        else {
            this.setState({
                countNotificationList: "404"
            })
        }
    }


    //VEIW RECENT  NOTIFICATIONS
    getRecentNotifications = async () => {
        const server_response = await ajax.getRecentNotifications(this.state.userId);
        if (server_response.status === 'OK') {
            this.setState({
                recentList: server_response.details.content
            })
        }
        else {
            this.setState({
                recentList: "404"
            })
        }

    }

    // VEIW TODAYS UNREAD NOTIFICATIONS
    getTodaysUnReadNotifications = async () => {
        let status = 0
        const server_response = await ajax.getTodaysNotifications(this.state.userId, status);
        if (server_response.status === 'OK') {
            this.setState({
                unreadList: server_response.details.content
            }, () => {
                this.getUserNotifications()
            })
        }
        else {
            this.setState({
                unreadList: "404"
            })
        }
    }




    // countTodaysNotifications = async () => {
    //     const server_response = await ajax.getTodaysNotifications(this.state.userId, this.state.status = 0);
    //     //console.log(server_response)
    //     //console.log("notification context")

    //     if (server_response.status === 'OK') {
    //         this.setState({
    //             countNotificationList: server_response.details.content.total_records
    //         }, () => {
    //             //this.getUserNotifications()
    //         })
    //     }
    //     else {
    //         this.setState({
    //             countNotificationList: "404"
    //         })
    //     }
    // }

    onClickRecord = (selectedNotificationId, notificationInfo, app_name) => {
        this.setState({
            selectedNotificationId: selectedNotificationId,
            notificationInfo: notificationInfo
        }, async () => {
            await this.readNotification(selectedNotificationId)
            if (app_name === 'CLAMPING') {
                window.location.href = "/enforcement/clamp_transactions"
            } else if (app_name === "PAYMENT_DUE") {
                window.location.href = "/contraventions/payment_due"
            } else if (app_name === "WRONG_PARKING") {
                window.location.href = "/contraventions/wrong_parking"
            } else if (app_name === "TOWING") {
                window.location.href = "/enforcement/tow_transactions"
            } else if (app_name === "CONFIGS") {
                window.location.href = "/config"
            } else if (app_name === "USERS") {
                window.location.href = "/users"
            } else if (app_name === "PAYMENT") {
                window.location.href = "/finance/new-payment"
            } else if (app_name === "STICKERS") {
                window.location.href = "/finance/new-sticker"
            } else if (app_name === "SURCHARGE") {
                window.location.href = "/config/surcharge"
            } else if (app_name === "INVOICES") {
                window.location.href = "/invoices"
            } else if (app_name === "PERMISSION_REQUESTS") {
                window.location.href = "/config/permissions/requests"
            } else if (app_name === "RECEIPTS") {
                window.location.href = ""
            } else if (app_name === "SESSION") {
                window.location.href = "/sessions"
            }
        })
    }




    // countUnreadMessages = async () => {
    //     const server_response = await ajax.countUnreadNotifications(this.state.userId);
    //     if (server_response.status === 'OK') {
    //         this.setState({
    //             countNotificationList: server_response.details.content
    //         }, () => {
    //             this.getUserNotifications()
    //         })
    //     }
    //     else {
    //         this.setState({
    //             countNotificationList: "404"
    //         })
    //     }
    // }

    countTodaysNotifications = async () => {
        const server_response = await ajax.getTodaysNotifications(this.state.userId, 0);
        if (server_response.status === 'OK') {
            this.setState({
                countTodaysNotificationList: server_response.details.content.total_records
            }, () => {
                this.getTodaysUnReadNotifications()
            })
        }
        else {
            this.setState({
                countTodaysNotificationList: "404"
            })
        }
    }

    render() {
        return (
            <NotificationContext.Provider value={{

                notificationsList: this.state.notificationsList,
                recentList: this.state.recentList,
                unreadList: this.state.unreadList,
                getRecentNotifications: this.getRecentNotifications,
                getUserNotifications: this.getUserNotifications,
                countTodaysNotifications: this.countTodaysNotifications,
                countNotificationList: this.state.countNotificationList,
                countUnreadMessages: this.countUnreadMessages,
                countTodaysNotificationList: this.state.countTodaysNotificationList,
                getTodaysUnReadNotifications: this.getTodaysUnReadNotifications,
                status: this.state.status




            }}>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}

export default NotificationContext;