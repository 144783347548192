import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import PageNav from '../../Components/Common/PageNav'
import { Link } from 'react-router-dom'
import Export from '../../Components/Common/Export'


class ParkingEnforcements extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        currentPage: 1,
        metaData: false,
        toast: false,
        pagination: false,
        filter: false,
        LocationList: false,
        locationId: false,
        locationRecord: false,
        toast: ''
    }


    componentDidMount() {
        this.getLocations();
        this.setState({
            menuOptions: {
                "addSession": () => this.onClickStartSession()
            }
        })

    }

    listAllSessions = async () => {
        if (this.state.filter) {
            const filter = this.state.filter;
            var server_response = await ajax.filterSessions(
                filter.dateFrom,
                filter.dateTo,
                filter.selectedValue,
                filter.keyword,
                this.state.currentPage
            );


        } else {
            var server_response = await ajax.listSessions(0, this.state.currentPage);
        }
        if (server_response.status === "OK") {
            this.setState({
                sessionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())

        } else {
            this.setState({
                sessionList: "404",
                metaData: false
            })
        }
    }


    countActiveSessions = async () => {

        const server_response = await ajax.countActiveSessions(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                countActiveSessions: server_response.details.content.total_c
            })
        }
    }


    onClickStartSession = () => {
        $("#modal_new_session").modal('show');
    }

    onClickAddPayment = () => {

    }

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            sessionList: false
        }, () => this.listAllSessions())
    }

    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                sessionList: false

            }, () => {
                this.listAllSessions();
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                sessionList: false
            }, () => {
                this.listAllSessions();
            })
        }

    }



    viewSessionInfo = (record) => {
        this.setState({
            sessionPreview: false
        }, () => {
            this.setState({
                sessionPreview: <PopSessionInfo session={record} onEnd={this.onEndSession} />
            }, () => {
                $("#modal_session_info").modal('show');
            })

        })

    }

    onEndSession = (sessionId) => {
        this.setState({
            activeSessionId: sessionId
        }, () => {
            $("#modal_session_info").modal('hide');
            $("#modal_delete").modal('show');
        })

    }

    onConfirmEndSession = async () => {

        if (this.state.activeSessionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.endSession(
                this.state.activeSessionId,
                this.state.userId
            );
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    this.listAllSessions()
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }


    onConfirmStartSession = (conductor, plateNumber) => {
        $("#modal_new_session").modal('hide');
        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: conductor, vehiclePlate: plateNumber }
        })
    }


    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickStartSession}>
                    <i className="fe fe-plus-circle mr-2"></i> Start Session
                </button>

                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
            </>
        )
    }

    getAllStartedBy = async () => {
        const server_response = await ajax.listConductors()
        if (server_response.status === "OK") {
            this.setState({
                conductors: server_response.details.content.list,
            })
        }
    }


    onFilter = async (filter) => {
        this.setState({
            filter: false,
            sessionList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllSessions())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            sessionList: false
        }, () => this.listAllSessions())
    }





    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                page: this.state.page + 1,
                transactionList: false

            }, () => {
                this.listAllSessions();
            })
        }

    }

    onClickPage = (page) => {
        this.setState({
            page: page,
            transactionList: false
        }, () => this.listAllSessions())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                page: this.state.page - 1,
                transactionList: false

            }, () => {
                this.listAllSessions();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {


            this.setState({
                pagination: <PageNav
                    totalPages={this.state.metaData.total_pages}
                    pageList={this.state.metaData.list_of_pages}
                    currentPage={this.state.currentPage}
                    onClickNext={this.onClickNext}
                    onClickPage={this.onClickPage}
                    onClickPrevious={this.onClickPrevious}
                />
            })



        }
    }

    getLocations = async () => {
        const server_response = await ajax.listLocations(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                LocationList: server_response.details.content
            })
        } else {
            this.setState({
                LocationList: "404"
            })
        }
    }

    //==============NAVIGATION CONTROLS END===========================



    render() {
        const LocationList = this.state.LocationList


        return (
            <div className="page">


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="X Report"
                            previousPageTitle="Reports"
                        // options={this.state.menuOptions}
                        // back="/operations"
                        //buttons={<this.buttons/>}
                        />

                        {/* <FilterBar
                            selection={{ title: 'Started By', options: userOptions }}
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        /> */}

                        {this.state.toast}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Occupancy Analysis"
                                            subtitle="This report handles two parameters namely Occupancy and Turnover."

                                        />
                                        {/* <Export url="wrong_parking/export_wrong_parking.php"
                                            extraClasses="btn-sm float-right"
                                        /> */}




                                        <div className="table-responsive">
                                            <table className="table mb-0 table-hover table-bordered occupancy-table">
                                                <tr>
                                                    <th className="text-left">Type of Parking Contravention</th>
                                                    <th>Number of Penalities Issued</th>
                                                    <th>Street with Highest Violation</th>
                                                    <th>Period (Date ranges)</th>
                                                </tr>
                                                <tbody className="text-left">
                                                    <tr>
                                                        <th>Double Parking</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Parked on walkways</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Parked in junction</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Non payment / defaulting</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>

                                                {/* <tbody>
                                                    {LocationList && LocationList !== "404" &&
                                                        LocationList.map((item, key) =>
                                                            <tr key={key}
                                                                id={"loc_" + item.location_id}
                                                                className={`${this.state.locationId == item.location_id ? 'flag-row' : ''}`}
                                                                onClick={() => this.onSelectRecord(item.location_id, item)}>
                                                                

                                                                <td>{key + 1}({item.location_id})</td>
                                                                <td>{item.location_name}</td>
                                                                <td>{item.street.street_name}</td>
                                                                <td>{item.street.branch.branch_name}</td>
                                                                <td>{item.total_slots.total_c}</td>
                                                                <td>{item.description}</td>

                                                                <td>{key + 1}({item.location_id})</td>
                                                                <td>{item.location_name}</td>
                                                                <td>{item.street.street_name}</td>
                                                                <td>{item.street.branch.branch_name}</td>
                                                                <td>{item.total_slots.total_c}</td>
                                                                <td>{item.description}</td>

                                                            


                                                                
                                                            </tr>
                                                        )}


                                                </tbody> */}
                                            </table>

                                            {this.state.pagination}


                                            {!LocationList && <ActivityLoader />}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ParkingEnforcements
