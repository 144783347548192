import React from 'react'
import { Link } from 'react-router-dom';
import { CashierConsumer } from '../../../Context/CashierContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import PopNewListing from '../../Vehicle/VehicleListing/PopNewListing';

class CustomListCard extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        vehicleListing:false,
        mdlNewListing:false

    }

   
    refresh=()=>{
        this.setState({
            vehicleListing:false
        }
        , ()=>this.getLists()
        )
    }


    getLists=async()=>{
        const server_response = await ajax.getUserVehicleListing(this.state.userId)
        if (server_response.status === "OK") {
            this.setState({
                vehicleListing: server_response.details.content
            })
        }
        
    }


    onClickNewList=()=>{
        this.setState({
            mdlNewListing:false
        }, ()=>{
            this.setState({
                mdlNewListing:<PopNewListing refresh={this.refresh}/>
            }, ()=>functions.openPopup('modal_new_vehicle_listing'))
            
        })
    }



    render() {
        const listing = this.state.vehicleListing;
        const list_bg=['bg-danger', 'bg-success', 'bg-warning', 'bg-secondary', 'bg-primary'];
        return (
            <CashierConsumer>
                {props=>{
                    const listing = props.customVehicleList
                    return(
                        <>
                        {this.state.mdlNewListing}
                        <div className="row row-sm">
                            <div className="col-md">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <h5 className="card-title tx-dark tx-medium mg-b-10">
                                            <i className="fas fa-capsules text-success mr-1"/>
                                            Vehicle Listing</h5>
                                        <p className="card-subtitle mg-b-15">Create & Manage vehicle groups</p>
                                        <div style={{height:"200px"}} className="card-text scroll-widget overflow-auto">
                                                {listing && 
                                                <ul className="task-list" style={{lineHeight:1.5}}>
                                                   {Array.isArray(listing) && listing.map((item, key)=>
                                                   <li className="pointer" key={key} onClick={()=>this.props.onView(item.list_id)}>
                                                   <i className={`task-icon ${list_bg[key] || 'bg-light'}`}></i>
                                                   <h6>{item.title}<small className="float-right text-muted tx-11">{item.created_at.short_date}</small></h6>
                                                   <span className="text-muted tx-12 d-inline-block text-truncate" style={{maxWidth: "90%"}}>
                                                       {item.description}
                                                    </span>
                                                    </li>)
                                                   }
                                                </ul>}                                
                                            </div>
                                            
                                    </div>
                                    <div className="card-body">
                                    <a className="card-link pointer" onClick={this.onClickNewList}>
                                                <i className="fas fa-plus mr-1 text-primary"/>
                                                New List
                                            </a> 
                                            <a className="card-link pointer">
                                                <i className="fas fa-eye mr-1 text-primary"/>
                                                View All... 
                                            </a>
            
                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                     </>
                    )
                }}
            </CashierConsumer>
          
        )
    }

}

export default CustomListCard