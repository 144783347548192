import React from 'react';
import ConfigurationContext from '../../Context/ConfigurationContext';
import LocationMappingContext from '../../Context/LocationMappingContext';
import { ManageUserConsumer } from '../../Context/ManageUserContext';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearch from '../Common/SelectSearch';

class PopAssignLocation extends React.Component {
    static contextType = LocationMappingContext;
    state = {
        info: '',
        locationId: '',
        userId: '',
        locationList: false,
        userList: false,
        userRecord: false,
        location: "Select Location...",
        loading: false

    }
   

    onChangeLocation = (selectedOption) => {
        this.setState({
            locationId: selectedOption.value,
            location: selectedOption.label
        })
    }




    onChangeUser = (selectedOption) => {
        this.setState({
            userId: selectedOption.value
        })
    }

    onConfirm = async () => {
        this.setState({
            info: ''
        })
        const { userId, locationId } = this.state;
        if (userId.length > 0 && locationId.length > 0) {
            this.setState({
                loading: true
            }, () => this.props.onConfirm(userId, locationId))
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }


    renderUser=()=>{
        const {branchUsers} = this.context
        
                const optionsUser = [];
                if (Array.isArray(branchUsers)) {
                    branchUsers.map((item, key) =>
                        optionsUser.push({ label: item.first_name + ' ' + item.last_name + ' @' + item.username + ' (' + item.user_role.role_name + ')', value: item.user_id })
                    )
                }

                return(
                    <SelectSearch
                    label="Select User"
                    onChange={() => this.onChangeUser}
                    options={optionsUser}
                    placeholder={"Select User..."}
                />
                )

          
    }


    render() {

        const { branchLocations }= this.context;
        const optionsLocation = [];
        if (Array.isArray(branchLocations)) {
            branchLocations.map((item, key) =>
                optionsLocation.push({ label: item.location_name + ' along ' + item.street.street_name, value: item.location_id })
            )
        }


       

        return (
            <div className="modal" id="pop_assign_location">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Location Assignment</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              <this.renderUser/>

                                <SelectSearch
                                    label="Location"
                                    onChange={() => this.onChangeLocation}
                                    options={optionsLocation}
                                    placeholder={this.state.location}
                                />




                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Assign Location</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>

            </div>
        )


    }
}

export default PopAssignLocation;
