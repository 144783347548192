import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import PageNav from '../../Components/Common/PageNav'
import { Link } from 'react-router-dom'
import Export from '../../Components/Common/Export'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import dictionary from '../../utils/dictionary'
import SpaceInventoryContext from '../../Context/Reports/SpaceInventoryContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import Occupancy from './Occupancy'
import SpaceInventory from './SpaceInventory'
const eformats = dictionary._exportBtnFormats


class ParkingDemandSupply extends React.Component {

  state = {
    datatable: "datatable",
    datatable2: "",
    table1ID: "table-spaceInventory",
    table2ID: "",
    activeTab: 1

  }

  setTab = (tabNo) => {
    this.setState({
      activeTab: tabNo,
    }, () => {
      if (tabNo == 1) {
        this.setState({
          datatable: "datatable",
          datatable2: "",
          table1ID: "table-spaceInventory",
          table2ID: ""

        })
      } else {
        this.setState({
          datatable: "",
          datatable2: "datatable",
          table1ID: "",
          table2ID: "table-occupancy"

        })
      }
    })
  }


  render() {

    return (

      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar
          // hasTab={true}
          />
          <div className="container-fluid">
            <PageHeader
              title="Parking Demand & Supply Analysis"
              previousPageTitle="Reports"
              // options={this.state.menuOptions}
              back="/operations"
            // buttons={<this.buttons />}
            />

            <div className="">
              <div className="card custom-card main-content-body-profile ">
                <nav className="nav main-nav-line">
                  <a className="nav-link active" data-toggle="tab" href="#tab1over" onClick={() => this.setTab(1)} >Space Inventory </a>
                  <a className="nav-link" data-toggle="tab" href="#tab2rev" onClick={() => this.setTab(2)}>Occupancy Analysis</a>
                  {/* <a className="nav-link" data-toggle="tab" href="#tab2pass">Password</a> */}
                  {/* <a className="nav-link" data-toggle="tab" href="#tab2pic">Profile photo</a> */}
                </nav>
                <div className="card-body tab-content h-100">

                  <div className="tab-pane active " id="tab1over">
                    <SpaceInventory
                      dataTable={this.state.datatable}
                      tableID={this.state.table1ID}
                    />
                  </div>

                  <div className="tab-pane" id="tab2rev">
                    <Occupancy
                      dataTable={this.state.datatable2}
                      tableID={this.state.table2ID}
                    />
                  </div>


                </div>
              </div>
            </div>






          </div>
        </div>
        <RightSideBar />

      </div>)


  }
}

export default ParkingDemandSupply