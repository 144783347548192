import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import PopNewSession from '../../Components/Session/PopNewSession'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import PINLocation from '../../Components/Common/PINLocation'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import Export from '../../Components/Common/Export'
import NoContent from '../../Components/Common/NoContent'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import OccupancyContext from '../../Context/Reports/OccupancyContext'

class SessionInSingleLocation extends React.Component {
    static contextType = OccupancyContext;

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        sessionList: false,
        metaData: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        locationID: '',
        from: '',
        to: '',
        locationName: '',
        vehicleTurnOver: ''
    }

    componentDidMount() {

        // const { listSessionsInLocations } = this.context;
        if (this.props.location.state) {
            this.setState({
                locationID: this.props.location.state.locationID,
                from: this.props.location.state.from,
                to: this.props.location.state.to,
                locationName: this.props.location.state.locationName
            }, () => this.listSessionsInLocations(this.props.location.state.locationID, this.props.location.state.from, this.props.location.state.to))
        }
        this.getAllStartedBy();
        this.setState({
            menuOptions: {
                "addSession": () => this.onClickStartSession()
            }
        })

    }


    componentWillUnmount() {
        //clearTimeout(this.timerCountSession)
    }


    listSessionsInLocations = async () => {
        const server_response = await ajax.listSessionsInLocation(this.state.userId, this.state.locationID, this.state.from, this.state.to, "2018-08-01");
        if (server_response.status === "OK") {
            this.setState({
                sessionList: server_response.details.content.session_info,
                vehicleTurnOver: server_response.details.content.average_turn_over
                // metaData: server_response.details.content.meta
            })
        } else {
            this.setState({
                sessionList: "404",
                // metaData: false
            })
        }
    }

    // listSessionsInLocations = async (locationID, from, to) => {
    //     const server_response = await ajax.listSessionsInLocation(this.state.userId, locationID, from, to);
    //     console.log(server_response)
    //     if (server_response.status === "OK") {
    //         this.setState({
    //             sessionLocationList: server_response.details.content.session_info,
    //             vehicleTurnOver: server_response.details.content.average_turn_over
    //             // metaData: server_response.details.content.meta
    //         })
    //     } else {
    //         this.setState({
    //             sessionList: "404",
    //             // metaData: false
    //         })
    //     }
    // }


    onClickStartSession = () => {
        $("#modal_new_session").modal('show');
    }

    onClickAddPayment = () => {

    }

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            sessionList: false
        }, () => this.listSessions())
    }

    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                sessionList: false

            }, () => {
                this.listSessions();
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                sessionList: false
            }, () => {
                this.listSessions();
            })
        }

    }



    viewSessionInfo = (record) => {
        this.setState({
            sessionPreview: false
        }, () => {
            this.setState({
                sessionPreview: <PopSessionInfo session={record} onEnd={this.onEndSession} />
            }, () => {
                $("#modal_session_info").modal('show');
            })

        })

    }

    onEndSession = (sessionId) => {
        this.setState({
            activeSessionId: sessionId,
            warnSessionEnd: false
        }, () => {

            this.setState({
                warnSessionEnd: <WarnDelete
                    title="End Session?"
                    description="You are about to end session as the currently logged in user!"
                    buttonLabel="End Session"
                    onConfirm={() => this.onConfirmEndSession()} />
            }, () => {
                functions.closePopup("modal_session_info");
                functions.openPopup("modal_delete");
            })

        })

    }




    onConfirmEndSession = async () => {
        const { listSessionsInLocations } = this.context;

        if (this.state.activeSessionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.endSession(
                this.state.activeSessionId,
                this.state.userId
            );
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    listSessionsInLocations()
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }


    onConfirmStartSession = (conductor, plateNumber) => {
        $("#modal_new_session").modal('hide');
        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: conductor, vehiclePlate: plateNumber }
        })
    }


    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickStartSession}>
                    <i className="fe fe-plus-circle mr-2"></i> Start Session
                </button>

                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                {!this.state.filter && <Export
                    url="session/export"
                />}


            </>
        )
    }

    getAllStartedBy = async () => {
        const server_response = await ajax.listConductors()
        if (server_response.status === "OK") {
            this.setState({
                conductors: server_response.details.content.list,
            })
        }
    }


    // onFilter = async (filter) => {
    //     this.setState({
    //         filter: false,
    //         sessionList: false,
    //         metaData: false
    //     }, () => {
    //         this.setState({ filter: filter }, () => this.listAllSessions())
    //     })
    // }

    // resetFilter = () => {
    //     this.setState({
    //         filter: false,
    //         sessionList: false
    //     }, () => this.listAllSessions())
    // }


    // onChangeStatus = (status) => {
    //     this.setState({
    //         status: status,
    //         sessionList: false,
    //         metaData: false,
    //         currentPage: 1
    //     }, () => this.listAllSessions())
    // }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    render() {
        const { vehicleTurnOver } = this.context;
        const sessionLocationList = this.state.sessionList;
        // const vehicleTurnOver = this.state.vehicleTurnOver;

        const filter = this.state.filter

        // const userList = this.state.userList;
        const userOptions = [];
        this.state.conductors && this.state.conductors.map((item, key) =>
            userOptions.push({
                title: item.username,
                value: item.user_id
            })
        );

        return (
            <div className="page">
                {this.state.sessionPreview}
                {this.state.warnSessionEnd}


                {/* <PopNewSession onConfirm={this.onConfirmStartSession} /> */}

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title={" Sessions in " + this.state.locationName}
                            previousPageTitle="Occupancy"
                            // options={this.state.menuOptions}
                            back="/reports/demand_supply/occupancy"
                        // buttons={<this.buttons />}
                        />

                        <CustomizedFilterBar
                            keyword="keyword"
                            selection={{ title: 'Started By', options: userOptions }}
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        />

                        {this.state.toast}
                        {this.state.locationPIN}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="All Session Activity"
                                            subtitle="List of all parking sessions in a location."
                                        />


                                        <div className="text-wrap">
                                            <div className="example">
                                                <div className="btn-list">

                                                    <button type="button"
                                                        className="btn ripple btn-primary btn-sm"
                                                    // onClick={() => this.onChangeStatus("")}
                                                    >
                                                        All Sessions <span className="badge badge-light ml-2">...</span>
                                                    </button>
                                                    {/* <button
                                                        type="button"
                                                        className="btn ripple btn-secondary btn-sm"
                                                        onClick={() => this.onChangeStatus(1)}>
                                                        Active Sessions <span className="badge badge-dark ml-2">...</span>
                                                    </button>
                                                    {sessionList !== "404" && filter && <Export
                                                        extraClasses="btn-sm float-right"
                                                        url={"sessions/export_filtered_sessions.php?keyword=" + filter.keyword + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&conductor_id=" + filter.selectedValue + "&status=" + this.state.status}
                                                    />} */}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="table-responsive">
                                            {sessionLocationList && Array.isArray(sessionLocationList) &&
                                                <table className="table text-nowrap mb-0 table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>SessionID</th>
                                                            <th>Start Time</th>
                                                            <th>Vehicle</th>
                                                            <th>Street</th>
                                                            <th>Location</th>
                                                            <th>Slots</th>
                                                            <th>Device ID</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sessionLocationList.map((item, key) =>
                                                            <tr key={key}>
                                                                <td>
                                                                    {/* <Link
                                                                        to="#"
                                                                        onClick={() => this.viewSessionInfo(item)}>
                                                                        {item.session_id_c}
                                                                    </Link> */}
                                                                    {item.session_id_c}

                                                                </td>
                                                                <td>
                                                                    {item.start_time.date} <small className="tx-12 tx-gray-500">{item.start_time.time}</small>
                                                                </td>
                                                                <td className="tx-bold">
                                                                    <Link to={
                                                                        {
                                                                            pathname: `/vehicles/profile/${item.session.vehicle.plate_number}`,
                                                                            state: { plateNumber: item.session.vehicle.plate_number }
                                                                        }}>{item.session.vehicle.plate_number}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                                        <i className="fas fa-map-marker-alt text-primary " /> &nbsp;
                                                                        {item.session.location.street.street_name}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {item.session.location.location_name}
                                                                </td>
                                                                <td>
                                                                    {item.session.number_of_slots}
                                                                </td>
                                                                <td>
                                                                    {item.session.start_device.device_number}
                                                                </td>
                                                                <td>
                                                                    {item.session.active === "1" && <span className="badge badge-success">Active</span>}
                                                                    {item.session.active === "0" && <span className="badge badge-secondary">Ended</span>}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            }
                                            {vehicleTurnOver <= 0 &&
                                                <>
                                                    <div className="text-center ">
                                                        <NoContent
                                                            title="No sessions available"
                                                        // subTitle={"No session availabl"}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            {/* {this.state.metaData && <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${this.state.currentPage === 1 && "disabled"}`}>
                                                        <a
                                                            className="page-link"
                                                            href="#" tabIndex="-1"
                                                            aria-disabled={this.state.currentPage === 1 ? false : true}
                                                            onClick={this.onClickPrevious}
                                                        >Previous</a>
                                                    </li>

                                                    {this.state.metaData.list_of_pages.map((item, key) =>
                                                        <li className={`page-item ${this.state.currentPage === item && "active"}`} key={key}>
                                                            <a
                                                                className="page-link"
                                                                href="#"
                                                                onClick={() => this.onClickPage(item)}>{item}</a>
                                                        </li>)}

                                                    <li className={`page-item ${this.state.currentPage === this.state.metaData.total_pages && "disabled"}`}>
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={this.onClickNext}>Next</a>
                                                    </li>
                                                </ul>
                                            </nav>} */}
                                            {!sessionLocationList && vehicleTurnOver > 0 && <ActivityLoader />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>
        );

    }
}

export default SessionInSingleLocation
