import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
// import TitleValueCard from '../../../Components/Common/TitleValueCard'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete';
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import ConfigurationContext, { ConfigurationConsumer } from '../../../Context/ConfigurationContext'
import dictionary from '../../../utils/dictionary'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import PopImportStreets from '../../../Components/PopImportStreets'
const eformats = dictionary._exportConfigBtnFormats

class ConfigStreet extends React.Component {
    static contextType = ConfigurationContext;
    state = {
        userId: functions.sessionGuard(),
        streetList: false,
        streetId: false,
        streetRecord: false,
        toast: '',

        model: false
    }

    onClickImportStreets = () => {
        const {getStreets} = this.context;
        this.setState({
            model: false
        }, () => {
            this.setState({
                model: <PopImportStreets
                        refresh={getStreets} 
                />
            }, () => functions.openPopup('modal_import_streets'))

        })
    }

    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-1"></i> Add
                </button>

                <button className="btn ripple btn-primary" onClick={this.onClickImportStreets}>
                    <i className="fe fe-plus-circle mr-1"></i> Import
                </button>
            </>
        )
    }

    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/streets/new'
        })
    }


    onClickEdit = () => {

        if (this.state.streetRecord) {

            this.props.history.push({
                pathname: '/config/streets/edit',
                search: '?q=1',
                state: { record: this.state.streetRecord }
            })
        }

    }

    onClickDelete = () => {
        // if(this.state.streetRecord)
        // {
        //     $("#modal_delete").modal('show')
        // }
    }

    RenderStreets = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const streetList = props.streetList
                    return (
                        <div className="table-responsive allView-config border-top">

                            {(streetList === "404") && <NoContent />}

                            {streetList && Array.isArray(streetList) &&
                                <table id="config-streets" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                    <thead>
                                        <tr>
                                            {/* <th>No.</th> */}
                                            <th>Street Name</th>
                                            <th>Branch</th>
                                            <th>Total Locations</th>
                                            <th>Created at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {streetList && streetList !== "404" &&
                                            streetList.map((item, key) =>
                                                <tr
                                                    id={"st_" + item.street_id}
                                                    key={key}
                                                    className={`${this.state.streetId == item.street_id ? 'flag-row' : ''}`}
                                                    onClick={() => this.onSelectRecord(item.street_id, item)}
                                                >
                                                    {/* <td>{key + 1}</td> */}
                                                    <td>{item.street_name}</td>
                                                    <td>{item.branch.branch_name}</td>
                                                    <td>{item.total_locations.total_c}</td>
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>

                                </table>
                            }

                            {!streetList && <ActivityLoader />}
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }


    RenderStreetCount = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const countAllStreets = props.totalStreets
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        Total Streets <span className="badge badge-light ml-2">{countAllStreets}</span>
                                    </button>

                                    <button className="btn ripple btn-light btn-sm" onClick={this.context.getStreets}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                </div>
                            </div>
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }


    onConfirmDelete = async () => {
        const { getStreets } = this.context;
        if (this.state.streetId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteBranch(this.state.streetId, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    // functions.hide('br_' + this.state.streetId);
                    //Refresh list
                    getStreets()
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }

    onSelectRecord = (streetId, streetRecord) => {
        this.setState({
            streetId: streetId,
            streetRecord: streetRecord,
            toast: ''
        })
    }

    render() {
        return (
            <div className="page">
                <WarnDelete
                    title="Delete Branch?"
                    description="Make sure this branch has not streets and locations under it"
                    onConfirm={() => this.onConfirmDelete()} />



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Streets"
                            previousPageTitle="Configurations"
                            back="/config"
                            buttons={<this.buttons />}
                            options={{
                                "add": () => this.onClickAdd(),
                                "edit": () => this.onClickEdit(),
                                "delete": () => this.onClickDelete()
                            }}
                        />

                        {this.state.toast}
                        {this.state.model}

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center filter-example">

                                                <TableHeader
                                                    title="List Of Company Parking Streets"
                                                    subtitle="List of all streets managed by Mutiplex parking"
                                                />

                                                <this.RenderStreetCount />
                                                </div>

                                                <this.RenderStreets />
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigStreet
