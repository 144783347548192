import React from 'react';
import ActivityLoader from '../Common/ActivityLoader';
import CompanyHeader from '../Config/CompanyHeader';
import functions from '../../utils/functions';
import '../../assets/css/payment.css';
import QRCodeGenerator from '../Common/QrCode/QRCodeGenerator';

const PopPaymentReceipt =({paymentRecord}) => {

	const print = () => {
		functions.printDoc('payment-receipt');
	}


	return (
		<div className="modal" id="modal_invoice">
		   <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
			  <div className="modal-content modal-content-demo shadow">
				<div className="modal-header">
					<h6 className="modal-title">Payment Receipt</h6>
					<button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
				</div>
				<div className="modal-body">

					<div className="receipt-container">
						<div class="header">
							<div className="company-info">
								<h1>Multiplex Limited</h1>
								<p>Kampala, Uganda</p>
								<p>P.O. Box 3874 kampala</p>
								<p>HOTLINE: 0312360100</p>
								<p>Email: multiplexug@gmail.com</p>

							</div>

							<div className="customer-info">
								<h1>Customer Information</h1>
								<p>Vehicle No. {paymentRecord.receipt.invoice.vehicle.plate_number}</p>
								<p>Name: {paymentRecord.receipt.invoice.vehicle.client.full_name}</p>
								<p>Phone: {paymentRecord.receipt.invoice.vehicle.client.phone_number}</p>
								<p>Email: {paymentRecord.receipt.invoice.vehicle.client.email}</p>
							</div>
						</div>
				

					<div className="parking-info">
						<h2>Invoice Details</h2>
						<p>Invoice Type: {paymentRecord.receipt.invoice.invoice_type.invoice_name}</p>
						<p>Invoice Ref: {paymentRecord.receipt.invoice.invoice_id_c}</p>
						<p>Invoice No: {paymentRecord.receipt.invoice.invoice_id}</p>
						<p>Invoice Date: {paymentRecord.receipt.invoice.invoice_dates.long_date}</p>
						<p>Invoice Amount: UGX {paymentRecord.receipt.invoice.invoice_items.total.amount_p}</p>


					</div>

					<div className="tax-info">
						<h2>Payment Details</h2>
						<p>Receipt Ref: {paymentRecord.receipt.payment_id_c}</p>
						<p>Receipt No: {paymentRecord.receipt.payment_id}</p>
						<p>Receipt Date: {paymentRecord.receipt.payment_date.long_date}</p>
						<p>Amount Paid: UGX {paymentRecord.receipt.amount.amount_p}</p>
						<p>Invoice Bal. After: UGX {paymentRecord.receipt.balance_after.amount_p}</p>
						<p>Payment Mode: {paymentRecord.receipt.payment_mode.mode_name}</p>

					</div>


					<div className="tax-info">
						<h2>Tax Details</h2>
						<p>VAT: </p>
						<p>NET AMOUNT: </p>
						<p>GROSS AMOUNT: </p>

					</div>

					<div className="qr-code">
						{/* <h2>Tax Details</h2> */}
						{/* <img src="path/to/qr-code.png" alt="QR Code"> */}
					</div>

					<div className="footer">
						{paymentRecord.receipt.invoice.payment.fiscal_receipt && <QRCodeGenerator text={paymentRecord.receipt.invoice.payment.fiscal_receipt.qr_code} />}
						<p>Thank you for parking with us!</p>
					</div>
				</div>

			</div>

		</div>


	</div>
</div>
		)

}

export default PopPaymentReceipt;
