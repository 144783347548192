import React, { useContext } from 'react'
import PageHeader from '../../../Components/Common/PageHeader'
import { Link } from 'react-router-dom';
import AuthContext, { AuthConsumer } from '../../../Context/AuthContext'
import { RenderSecure } from '../../../utils/script/RenderSecure'
import PageContainer from '../../../Components/Layout/PageContainer'
import TaxLastMonth from '../../../Components/Cards/Efris/TaxLastMonth'
import TaxCurrentMonth from '../../../Components/Cards/Efris/TaxCurrentMonth'
import CreditNotesLastMonth from '../../../Components/Cards/Efris/CreditNotesLastMonth'
import CreditNotesCurrentMonth from '../../../Components/Cards/Efris/CreditNotesCurrentMonth'
import EfrisTransactions from '../../../Components/Finance/Efris/EfrisTransactions'
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext'
import useStateCallback from '../../../utils/customHooks/useStateCallback';
import PopNewCreditNote from '../../../Components/Finance/Efris/PopNewCreditNote';
import functions from '../../../utils/functions';
import PopCancelCreditNote from '../../../Components/Finance/Efris/PopCancelCreditNote';

const EfrisDashboard =(props)=>{

  const {permissionList} = useContext(AuthContext);
  const {refreshTax} = useContext(FiscalReceiptContext);
  const [modal, setModal] = useStateCallback(false);

  const handleNewCreditNote=()=>{
    setModal(false, ()=>setModal(<PopNewCreditNote/>, ()=>functions.openPopup("modal-new-credit-note")))

  }

  const handleCancelCreditNote=()=>{
    setModal(false, ()=>setModal(<PopCancelCreditNote/>, ()=>functions.openPopup("modal-cancel-credit-note")))

  }

  const customHeaderOptions = (props) => {
    
    return (
      <>
        <RenderSecure code='R-INVOICE-02'>
          <Link to="#" className="dropdown-item pointer" onClick={handleNewCreditNote}>
            <i className="fas fa-tags text-primary mr-2"></i>New Credit Note
          </Link>
        </RenderSecure>

        <RenderSecure code='R-INVOICE-02'>
          <Link to="#" className="dropdown-item pointer" onClick={handleCancelCreditNote}>
            <i className="fas fa-times mr-2 text-danger tx-18"></i>Cancel Credit Note
          </Link>
          {/* <Link to="/efris/config" className="dropdown-item pointer">
            <i className="fas fa-cog mr-2 text-light tx-18"></i>Config
          </Link> */}
        </RenderSecure>  
        <RenderSecure code='R-INVOICE-02'>
          <Link to="#" className="dropdown-item pointer" onClick={handleNewCreditNote}>
            <i className="fas fa-tags text-primary mr-2"></i>Sticker Credit
          </Link>
        </RenderSecure>
        <RenderSecure code='R-INVOICE-02'>
          <Link to="#" className="dropdown-item pointer" onClick={handleNewCreditNote}>
            <i className="fas fa-tags text-primary mr-2"></i>Reservation Credit
          </Link>
        </RenderSecure>
      </>

    )
  }

  const buttons = (props) => {

    return (
      <>
         <button className='btn btn-light' onClick={refreshTax}><i className='fa fa-redo mr-2' /> Refresh</button>

      </>
    )
  }

    return <PageContainer>
            {modal}
             <PageHeader
                    title="URA EFRIS"
                    previousPageTitle="Dashboard"
                    back="/finance"
                    options={{
                      customOptions : customHeaderOptions({ permissionList:{permissionList}})
                    }}
                    buttons={buttons({permissionList:{permissionList}})}
                  />

                 <RenderSecure code="R-REVENUE-00">
                    <div className="row">
                      <TaxLastMonth/>
                      <TaxCurrentMonth/>
                      <CreditNotesCurrentMonth/>
                      <CreditNotesLastMonth/>
                    </div>
                  </RenderSecure>

                  <div className="row">
                    <div className="col-sm-12 col-xl-12 col-lg-12">

                        <EfrisTransactions/>
                     
                    </div>
                   
                  </div>

    </PageContainer>

  
}

export default EfrisDashboard
