import React from 'react'
import { Link } from 'react-router-dom';
import WrongParkingContext from '../../../Context/ContraventionContext/WrongParkingContext';
import { OperationConsumer } from '../../../Context/OperationContext';
import ajax from '../../../utils/ajax';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import NoContent from '../../../Components/Common/NoContent';
import TableHeader from '../../../Components/Common/TableHeader';
import PopSessionLocation from '../../../Components/Operations/PopSessionLocation';
import PopViewImage from '../../../Components/PopViewImage';
import $ from 'jquery';
import LoadMoreButton from '../../../Components/Other/LoadMoreButton';
import LeftSideBar from '../../../Components/Common/LeftSideBar';
import TopBar from '../../../Components/Common/TopBar';
import PageHeader from '../../../Components/Common/PageHeader';
import RightSideBar from '../../../Components/Common/RightSideBar';
const eformats = dictionary._exportBtnFormats

class ContraventionPerformance extends React.Component {

    static contextType = WrongParkingContext;

    state = {
        userId: functions.sessionGuard(),
        sessionList: false,
        locationPIN: false,
        imageView: false,
    }

    componentDidMount() {
        const { getRecentWrongParkingTransactions } = this.context;
        getRecentWrongParkingTransactions();
    }

    onClickViewVehiclePhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewImage image={record} />
            }, () => {
                $("#modal_view_image").modal('show');
            })
        })

    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    ViewAllSessions = () => {
        this.props.history.push('/parking_sessions/sessions')
    }

    buttons = () => {
        return (
            <>
                <Link
                    to="#"
                    className="btn ripple btn-secondary navresponsive-toggler mb-3" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>

            </>
        )
    }


    render() {

        const { enforcementAnalysisByBranchList, Eloading, goToNextEnforcementPage, EmetaData } = this.context
        console.log(Eloading)

        return (

            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Contavention performance"
                            previousPageTitle="Reports"
                            // options={this.state.menuOptions}
                            back="/operations"
                        //buttons={<this.buttons/>}
                        />

                        {/* <FilterBar
                            selection={{ title: 'Started By', options: userOptions }}
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        /> */}

                        {this.state.toast}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Contranvention performance"
                                            subtitle="Analysis by Branch."
                                            loadMore={Array.isArray(enforcementAnalysisByBranchList) && EmetaData.total_pages != 1 &&
                                                <div className="">
                                                    <LoadMoreButton
                                                        action={EmetaData.total_pages > 1 ? goToNextEnforcementPage : ""}
                                                        loading={Eloading}
                                                        title={EmetaData == false ? "Refresh" : (EmetaData.current_page + " of " + EmetaData.total_pages + " pages")}
                                                    />

                                                </div>
                                            }


                                        />

                                        <div className="table-responsive">
                                            <table id="table-contravention-analysis" className="table  border-t0 key-buttons text-nowrap w-100 datatable" export-format={eformats}>
                                                <thead>
                                                    <tr>
                                                        <th>Street Name</th>
                                                        <th>Branch</th>
                                                        <th className='text-center'>Total wrong parkings</th>
                                                        <th className='text-center'>Total Due payments</th>
                                                        <th className='text-center'>Total penalties issued</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (enforcementAnalysisByBranchList === "404")
                                                            ?
                                                            <tr>
                                                                <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="7">
                                                                    {/* <img alt="no-content" src={nocontent} /> */}
                                                                    <NoContent />
                                                                </td>
                                                            </tr>
                                                            :
                                                            enforcementAnalysisByBranchList && enforcementAnalysisByBranchList !== "404" &&
                                                            enforcementAnalysisByBranchList.map((item, key) =>
                                                                <tr key={key}>
                                                                    {/* <td>
                                                                    {item.start_time.long_date}
                                                                </td> */}
                                                                    <td>
                                                                        {item.street_name}
                                                                    </td>

                                                                    <td>
                                                                        {item.branch_name}
                                                                    </td>

                                                                    <td className='text-center'>
                                                                        {item.total_wp.total_c}
                                                                    </td>

                                                                    <td className='text-center'>{item.total_pd.total_c}</td>
                                                                    <td className='text-center'>{item.total_penalties.total_c}</td>

                                                                </tr>
                                                            )}


                                                </tbody>

                                            </table>
                                            {!enforcementAnalysisByBranchList && <ActivityLoader />}
                                        </div>





                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
                <RightSideBar />

            </div>


        )

    }

}

export default ContraventionPerformance
