import React, { useContext } from 'react';
import StatisticCard from '../../../StatisticCard';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';


const SavedThisMonth =()=>{

    const {currentMonthTax} = useContext(EfrisHistoryContext);

 
    return <StatisticCard
                title="Current Saving" 
                value={currentMonthTax ? currentMonthTax : "..."}
                caption="This Month"
                icon={"fa fa-check-circle"}
                color={"text-success"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-success"}
                />
 
}

export default SavedThisMonth;