import React from 'react';


class FilterBar extends React.Component {

	state = {
		dateFrom: '',
		dateTo: '',
		selectedValue: '',
		keyword: ''
	}


	onChangeDateFrom = (event) => {
		this.setState({ dateFrom: event.target.value });
	}

	onChangeDateTo = (event) => {
		this.setState({ dateTo: event.target.value });
	}

	onChangeSelection = (event) => {
		this.setState({ selectedValue: event.target.value });
	}

	onChangeKeyword = (event) => {
		this.setState({ keyword: event.target.value });
	}

	onApply = () => {
		this.props.onConfirm(this.state)
	}

	onClickReset = () => {
		this.setState({
			dateFrom: "",
			dateTo: "",
			selectedValue: false,
			keyword: false

		 });
	
	}



	render() {

		return (

			<div className="responsive-background">
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<div className="advanced-search">
						<div className="row align-items-center">

							<div className="col-md-6">
								<div className="row">
									<div className="col-md-6">
										<div className="form-group mb-lg-0">
											<label className="">From :</label>
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text">
														<i className="fe fe-calendar lh--9 op-6"></i>
													</div>
												</div>
												<input
													className="form-control fc-datepicker"
													placeholder="11/01/2019"
													type="date"
													value={this.state.dateFrom}
													onChange={this.onChangeDateFrom} />
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-lg-0">
											<label className="">To :</label>
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text">
														<i className="fe fe-calendar lh--9 op-6"></i>
													</div>
												</div>
												<input
													className="form-control fc-datepicker"
													placeholder="11/08/2019" type="date"
													value={this.state.dateTo}
													onChange={this.onChangeDateTo} />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="form-group mb-lg-0">
									<label className="">Keyword</label>
									<input
										className="form-control"
										placeholder="Search keyword"
										type="text"
										onChange={this.onChangeKeyword} />
								</div>
							</div>


							<div className="col-md-3">
								{this.props.selection && <div className="form-group mb-lg-0">
									<label className="">{this.props.selection.title}</label>
									<select
										className="form-control select2-flag-search"
										data-placeholder={this.props.selection.title}
										onChange={this.onChangeSelection}
									>
										<option value=""> -Select- </option>
										{this.props.selection.options.map((item, key) =>
											<option key={key} value={item.value}>{item.title}</option>
										)}
									</select>
								</div>}
							</div>

							


						</div>
						<hr />
						<div className="text-right">
							<a href="#" onClick={this.onApply} className="btn btn-primary" data-toggle="collapse" data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Apply</a>
							<a href="#" onClick={this.props.onReset || this.state.onClickReset} className="btn btn-light" data-toggle="collapse" data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Reset</a>
						</div>
					</div>
				</div>
			</div>

		);


	}
}

export default FilterBar;
