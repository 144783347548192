import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopInvoice from '../../Components/PopInvoice'
import { Link } from 'react-router-dom'
import TableHeader from '../../Components/Common/TableHeader'
import NoContent from '../../Components/Common/NoContent'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import FinanceContext from '../../Context/FinanceContext'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'


const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

class ListInvoices extends React.Component {

    static contextType = FinanceContext

    state = {
        invoiceView: false,
        activeRecord: false
    }

   



    onClickRecord = (activeRecord) => {
        this.setState({activeRecord})
    }

   

    onClickPayInvoice = (plateNumber) => {
        $("#modal_invoice").modal('hide');
        this.props.history.push({
            pathname: '/finance/new-payment',
            search: '?q=1',
            state: { vehiclePlate: plateNumber }
        })

    }


    onClickInvoice = (record) => {
        this.setState({
            invoiceView: false
        }, () => {

            this.setState({
                invoiceView: <PopInvoice
                    invoice={record}
                    onClickPay={this.onClickPayInvoice} />
            }, () => {
                $("#modal_invoice").modal('show');
            })
        })

    }




    RenderFilter = () => {
          
        const options = [
            {title:"All Invoices", "value":"1"},
            {title:"Parkings", "value":"2"},
            {title:"Others", "value":"3"}
        ];

        const{
            resetData,
            filterData
        } = this.context
    

        return (
            <CustomizedFilterBar
                selection={{ title: 'Invoice Selection', options: options }}
                keyword="Plate Number"
                onReset={resetData}
                onConfirm={filterData}
            />
        )

        
    }




    buttons = () => {
        return (
            <>
                {this.context.invoiceListing &&
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                }

            </>
        )
    }




    render() {
        const {
            loading,
            invoiceListing,
            goToNextPage,
            metaData
        } = this.context

        return (
            <div className="page">
                {this.state.invoiceView}
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="All Invoices"
                            previousPageTitle="Dashboard"
                            back="/"
                            buttons={<this.buttons/>}
                        />

                        {this.RenderFilter()}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                    <div className="d-flex justify-content-between align-items-center filter-example">
                                        <TableHeader
                                            title="All Invoices"
                                            subtitle="List of all the vehicle invoices including cleared invoices"
                                        />


                                        {Array.isArray(invoiceListing) &&
                                            <LoadMoreButton
                                                action={goToNextPage}
                                                loading={loading}
                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                />
                                            }

                                        {/* <div className="text-wrap">
                                            <div className="">
                                                <div className="btn-list">

                                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                                        All Invoices <span className="badge badge-light ml-2">4</span>
                                                    </button>
                                                    <button type="button" className="btn ripple btn-secondary btn-sm">
                                                        This Week <span className="badge badge-dark ml-2">78</span>
                                                    </button>
                                                    <button type="button" className="btn ripple btn-success btn-sm">
                                                        Today <span className="badge badge-danger ml-2">12</span>
                                                    </button>
                                                    <button type="button" className="btn ripple btn-info btn-sm">
                                                        Session By Date
                                                    </button>

                                                </div>
                                            </div>
                                        </div> */}
                                        </div>


                                        <div className="table-responsive allView-table border-top">


                                        {Array.isArray(invoiceListing) &&
                                          <table id="Invoices-tablw" className="table table-hover text-nowrap mb-0 datatable" export-format={exportFormats} >
                                          <thead>
                                              <tr>
                                                  <th>NO.</th>
                                                  <th>DATE</th>
                                                  <th>VEHICLE</th>
                                                  <th>INVOICE TYPE</th>
                                                  <th>STREET</th>
                                                  <th>AREA</th>
                                                  <th>SLOTS</th>
                                                  <th>INVOICE NO</th>
                                                  <th>TIME</th>
                                                  <th>DURATION</th>
                                                  <th>STD. CHARGE</th>
                                                  <th>SURCHARGE</th>
                                                  <th>TOTAL AMOUNT</th>
                                                  <th>BALANCE</th>
                                                  <th>STATUS</th>
                                              </tr>
                                          </thead>
                                          <tbody>

                                                {invoiceListing.map((item, key) =>
                                                      <tr 
                                                            key={key} 
                                                            onClick={()=>this.onClickRecord(item.invoice_id)}
                                                            className={`${this.state.activeRecord == item.invoice_id ? 'flag-row' : ''}`}
                                                            >
                                                          
                                                          <td>{key+1}</td>
                                                          <td>{item.invoice_dates.short_date}</td>
                                                          <td>{item.vehicle.plate_number}</td>
                                                          <td>{item.invoice_type.invoice_name}</td>
                                                          <td>{item.street}</td>
                                                          <td>{item.location}</td>
                                                          <td>{item.slots}</td>
                                                          <td><Link to="#" onClick={() => this.onClickInvoice(item)}>
                                                                  {item.invoice_id_c}
                                                              </Link>
                                                          </td>
                                                          <td>{item.invoice_type.type_id*1>1?item.invoice_dates.time : item.time}</td>
                                                          <td>{item.duration}</td>
                                                          <td className='invoice-std'>{item.standard_charge.amount_c}</td>
                                                          <td className='invoice-surcharge'>{item.surcharge.amount_c}</td>
                                                          <td className='invoice-total'>{item.invoice_items.total.amount_c}</td>
                                                          <td className='invoice-balance'>{item.payment.balance.amount_c}</td>
                                                          <td>
                                                              {item.payment.balance.amount <= 0 && <span className="badge badge-success">{item.payment.status}</span>}
                                                              {item.payment.balance.amount > 0 && <span className="badge badge-secondary">{item.payment.status}</span>}
                                                          </td>
  
                                                      </tr>)}

                                             
                                                    
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td><b>TOTAL</b></td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td><b>{functions.sumTableField('invoice-std')}</b></td>
                                                        <td><b>{functions.sumTableField('invoice-surcharge')}</b></td>
                                                        <td><b>{functions.sumTableField('invoice-total')}</b></td>
                                                        <td><b>{functions.sumTableField('invoice-balance')}</b></td>

                                                        <td>&nbsp;</td>
                                                    </tr>


                                          </tbody>
                                      </table>
                                        }




                                            {!invoiceListing && <ActivityLoader />}
                                            {invoiceListing === "404" && <NoContent/>}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ListInvoices
