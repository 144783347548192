import React from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import $ from 'jquery';
import PopInvoice from '../PopInvoice';
import PageNav from '../Common/PageNav';
import NoContent from '../Common/NoContent';
import TableHeader from '../Common/TableHeader';
const eformat = dictionary._exportBtnFormats


class VehicleOutstandings extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        metaData: false,
        plateNumber: this.props.plateNumber,
        invoiceView: false,
        currentPage: 1,
        pagination: false

    }

    componentDidMount() {
        this.listTransactions();
        // this.timerlistTransactions = setTimeout(this.functransactionList = async () => {
        //     await this.listTransactions();
        //     this.timerlistTransactions = setTimeout(this.functransactionList, dictionary._waitTime); // (*)
        // }, dictionary._waitTime);

    }

    componentWillUnmount() {
        // clearTimeout(this.timerlistTransactions)
    }

    listTransactions = async () => {
        this.setState({transactionList:false})
        const server_response = await ajax.listVehicleOutstandingInvoices(this.state.plateNumber, this.state.currentPage);
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content,
            })
        } else {
            this.setState({
                transactionList: "404",
            })
        }
    }



    onClickInvoice = (record) => {
        this.setState({
            invoiceView: false
        }, () => {

            this.setState({
                invoiceView: <PopInvoice
                    invoice={record}
                    onClickPay={this.props.onClickPay} />
            }, () => {
                $("#modal_invoice").modal('show');
            })
        })

    }


    render() {
        const transactionList = this.state.transactionList;
        return (
            <div className="row">
                {this.state.invoiceView}
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body"> 
                        
                            <div className="d-flex justify-content-between align-items-center filter-example">
                                <TableHeader
                                    title="Outstanding Invoices"
                                    subtitle="List of all outstanding vehicle balances"
                                />
                            </div>

                            <div className="table-responsive allView-table">
                                   {Array.isArray(transactionList) && 
                                   <table 
                                        id={"vehicleInvoiceID"} 
                                        className="table table-hover text-nowrap mb-0 datatable" 
                                        export-format={eformat} 
                                        messageBottom={"Printed by: " + functions.getPintedBy()} 
                                        // title={"Outstanding invoices for " + this.state.plateNumber}
                                        messageTop={"Outstanding invoices for " + this.state.plateNumber}
                                    >
                                        <thead>
                                            <tr>
                                                <th>NO.</th>
                                                <th>DATE</th>
                                                <th>VEHICLE</th>
                                                <th>INVOICE TYPE</th>
                                                <th>STREET</th>
                                                <th>AREA</th>
                                                <th>SLOTS</th>
                                                <th>INVOICE NO</th>
                                                <th>TIME</th>
                                                <th>DURATION</th>
                                                <th>STD. CHARGE</th>
                                                <th>SURCHARGE</th>
                                                <th>TOTAL AMOUNT</th>
                                                <th>BALANCE</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionList.map((item, key) =>
                                                    <tr key={key}>
                                                        
                                                        <td>{key+1}</td>
                                                        <td>{item.invoice_dates.short_date}</td>
                                                        <td>{item.vehicle.plate_number}</td>
                                                        <td>{item.invoice_type.invoice_name}</td>
                                                        <td>{item.street}</td>
                                                        <td>{item.location}</td>
                                                        <td>{item.slots}</td>
                                                        <td><Link to="#" onClick={() => this.onClickInvoice(item)}>
                                                                {item.invoice_id_c}
                                                            </Link>
                                                        </td>
                                                        <td>{item.invoice_type.type_id*1>1?item.invoice_dates.time : item.time}</td>
                                                        <td>{item.duration}</td>
                                                        <td className="vehicle-std">{item.standard_charge.amount_c}</td>
                                                        <td className="vehicle-surcharge">{item.surcharge.amount_c}</td>
                                                        <td className="vehicle-total">{item.invoice_items.total.amount_c}</td>
                                                        <td className="vehicle-balance">{item.payment.balance.amount_c}</td>
                                                        <td>
                                                            {item.payment.balance.amount <= 0 && <span className="badge badge-success">{item.payment.status}</span>}
                                                            {item.payment.balance.amount > 0 && <span className="badge badge-secondary">{item.payment.status}</span>}
                                                        </td>

                                                    </tr>)}

                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td><b>TOTAL</b></td>
                                                        <td><b>{functions.sumTableField('vehicle-std')}</b></td>
                                                        <td><b>{functions.sumTableField('vehicle-surcharge')}</b></td>
                                                        <td><b>{functions.sumTableField('vehicle-total')}</b></td>
                                                        <td><b>{functions.sumTableField('vehicle-balance')}</b></td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                  
                                        </tbody>
                                    </table>}

                                    {!transactionList && <ActivityLoader />}
                                    {transactionList==="404" && <NoContent />}
                                    {/* {this.state.metaData && this.state.pagination} */}

                                </div>

                        </div>
                    </div>


                </div>
            </div>

        )
    }

}

export default VehicleOutstandings
