import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import PopEditClient from './PopEditClient';

class ClientVehicle extends React.Component {
 
    state={
        dialogue:false,
        vehicleInfo:this.props.vehicleInfo
    }
    onClickEdit=()=>{
        this.setState({
            dialogue:false
        }, ()=>{
            this.setState({
                dialogue: <PopEditClient vehicleInfo={this.state.vehicleInfo} refresh={this.props.refresh}/>
            }, ()=>{
                $("#modal_edit_client").modal('show');
            })
        })
        
    }

  render(){

    const vehicleInfo=this.state.vehicleInfo;
    
      return (
        <div className="card custom-card">
        <div className="card-body main-contact-info-body">
        {this.state.dialogue}
            <div className="media-list pb-lg-0">
            <div className="media">
            <div className="media-body">
            <div>
            <label>Plate Number</label> <span className="tx-medium">{vehicleInfo.plate_number}</span>
            </div>
            <div>
            <label>Vehicle ID</label> <span className="tx-medium">{vehicleInfo.vehicle_id}</span>
            </div>
            </div>
            </div>

            <div className="media">
            <div className="media-body">
            <div>
            <label>Is VIP</label> 
            <span className="tx-medium">{vehicleInfo.is_vip}</span>
            </div>
            <div>
            <label>Client Contact</label> 
            <span className="tx-medium">{vehicleInfo.client.phone_number}</span>
            </div>

            </div>
            </div>

            <div className="media">
            <div className="media-body">
            <div>
            <label>Client Name</label> 
            <span className="tx-medium">{vehicleInfo.client.last_name + " " + vehicleInfo.client.first_name}</span>
            </div>
            </div>
            </div>

            <div className="media">
            <div className="media-body">
            <div>
            <label>Added Since</label> 
            <span className="tx-medium">{vehicleInfo.created_at}</span>
            </div>
            </div>

            <div className="media-body">
            <div>
            <span className="tx-medium">
                <Link to="#" onClick={this.onClickEdit}>
                    <i className="fa fa-edit"/>
                    Edit Client
                </Link>
            </span>
            </div>
            </div>
            </div>
            </div>
           
        </div>
        </div>
      );
    
 
  }
}

export default ClientVehicle;