import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import Toast from '../Common/Toast';
import WarnSecureDelete from '../WarnSecureDelete';

class PopWhiteListRecord extends React.Component {

    state={
        mdlWarn:false,
        request:this.props.record,
        toast:false,
        status:''
    }
  
   
    resetToast = () => {
        this.setState({
            toast: false
        })
      }

    /**
     * INVOKES THE HANDLE REQUEST DIALOGUE
     */
    onClickHandleRequest=(status)=>{
        functions.closePopup('modal_white_list');
        var { title, description, buttonLabel, buttonType}="";
        this.setState({
            status:status
        })
        switch(status)
        {
            case 2://cancelling
                title="Cancel White Listing Request";
                description="Please confirm with your account password that you want to cancel this request for vehicle: " + this.state.request.vehicle.plate_number;
                buttonLabel="Cancel Request";
                buttonType="btn-danger"
            break;
            case 1://approving

                title="Approve White Listing Request";
                description="Please confirm with your account password that you want to approve this request for vehicle: " + this.state.request.vehicle.plate_number;
                buttonLabel="Approve Request";
                buttonType="btn-success"

            break;
            case 3://approving
                title="Process White Listing Request";
                description="Please confirm with your account password that you want to process this request for vehicle: " + this.state.request.vehicle.plate_number;
                buttonLabel="Process Request";
                buttonType="btn-primary"
            break;

            case 4://approving
                title="Terminate White Listing Request";
                description="Please confirm with your account password that you want to terminate this request for vehicle: " + this.state.request.vehicle.plate_number;
                buttonLabel="Terminate Request";
                buttonType="btn-danger"
            break;

            default:
                title="";
                description="";
                buttonLabel="";
            break;
        }

        this.setState({
            mdlWarn:false
        }, ()=>{
            
            this.setState({
                mdlWarn:<WarnSecureDelete 
                title={title}
                description={description}
                icon="fas fa-question"
                buttonLabel={buttonLabel}
                buttonType={buttonType}
                onConfirm={this.confirmHandleRequest}
                />
            }, ()=>{
                functions.openPopup('modal_secure_delete')
            })
        })
    }


     /**
     * EFFECT THE HANDLE REQUEST ACTION
     */
     confirmHandleRequest=async(password)=>{

        var server_response;
        switch(this.state.status)
        {
            case 2://CANCEL REQUEST
                server_response=await ajax.cancelWhiteList(
                    this.state.request.request_id,
                    password
                );
        
            break;
            case 1://APPROVE REQUEST
                server_response=await ajax.approveWhiteList(
                    this.state.request.request_id,
                    password
                );
        
            break;
            case 3://PROCESS REQUEST
            server_response=await ajax.processWhiteList(
                this.state.request.request_id,
                password
            );
            break;
            case 4://TERMINATE REQUEST
            server_response=await ajax.terminateWhiteList(
                this.state.request.request_id,
                password
            );
    
            break;
            default:
                server_response={'status':'conn', 'details':{'message':'Invalid request', 'content':false}}
            break;
        }
       
        if(server_response.status==="OK")
        {
            this.setState({
                toast: <Toast
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
              this.props.refresh()
        }else{
            this.setState({
                toast: <Toast
                type={4}
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
        }
     }

     


  render(){
      const item=this.props.record;
      return (
        <>
        {this.state.mdlWarn}
        {this.state.toast}
        <div className="modal" id="modal_white_list">
        <div className="modal-dialog modal-dialog-centered" role="document">
            

        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">White Listing</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                

                    {item && item!=="404" &&
                            <div className="col-12">
                                <table className="table text-nowrap mb-0 table-hover">
                                    <tbody>
                                        <tr>
                                            <td className="tx-bold">Vehicle</td>
                                            <td>{item.vehicle.plate_number}</td>
                                        </tr>
                                        <tr>
                                            <td className="tx-bold">Balance Date</td>
                                            <td>{item.balance_date.short_date }</td>
                                        </tr>
                                        <tr>
                                            <td className="tx-bold">Client Name</td>
                                            <td>{item.vehicle.client.full_name}</td>
                                        </tr>
                                        <tr>
                                            <td className="tx-bold">Client Contact</td>
                                            <td>{item.vehicle.client.phone_number}</td>
                                        </tr>

                                        <tr>
                                            <td className="tx-bold">Commitment Amount</td>
                                            <td>UGX. {item.due_amount.amount_c}</td>
                                        </tr>

                                        <tr>
                                            <td className="tx-bold">Status</td>
                                            <td><i>{item.status.status}</i></td>
                                        </tr>

                                        <tr>
                                            <td className="tx-bold">Comment</td>
                                            <td><i>{item.comment}</i></td>
                                        </tr>

                                        <tr>
                                            <td className="tx-bold">Requested By</td>
                                            <td>{item.created_by.username}</td>
                                        </tr>


                                        <tr>
                                            <td className="tx-bold">Request Date</td>
                                            <td>{item.created_at.short_date + ' ' + item.created_at.time}</td>
                                        </tr>
                                    </tbody>
                                   

                                </table>
                            </div>}
                    
               
              
            </div>
            <div className="modal-footer">
              
                    {item.status.status_id*1!==3 && item.status.status_id*1!==2 &&
                        <input 
                            className="btn ripple btn-danger" 
                            type="button" 
                            value="Cancel Request" 
                            onClick={()=>this.onClickHandleRequest(2)}
                            />}
                    
                    {item.status.status_id*1===0 &&
                    <input 
                        className="btn ripple btn-primary" 
                        type="button" value={item.next_status} 
                        onClick={()=>this.onClickHandleRequest(1)}
                        />}

                    {item.status.status_id*1===1 &&
                    <input 
                        className="btn ripple btn-success" 
                        type="button" value={item.next_status} 
                        onClick={()=>this.onClickHandleRequest(3)}
                    />}

                    {item.status.status_id*1===3 &&
                    <input 
                        className="btn ripple btn-success" 
                        type="button" value={item.next_status} 
                        onClick={()=>this.onClickHandleRequest(4)}
                    />}


            </div>
        </div>


        </div>
    </div>
 </>
      )
    
 
  }
}

export default PopWhiteListRecord;
