import React, { useContext, useEffect, useState } from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import SupervisorContext from '../../../Context/SupervisorContext'
import { RenderSecure } from '../../../utils/script/RenderSecure'
import SuperCard from '../../../Components/Cards/OperationCards/SuperCard'
import RecentScan from '../../../Components/Supervisor/RecentScan'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Supervisor = (props) => {


    const [toast, setToast] = useState(false);
    const { 
        dailyCount, 
        monthlyCount,
        totalSupervisors, 
        getRecentTransactions,
        getDailyCount,
        getMonthlyCount,
        getEfficiency,
        efficiency
     } = useContext(SupervisorContext);
    const [modal, setModal] = useState(false);

  
    const handleRefresh=()=>{
        getRecentTransactions();
        getDailyCount();
        getMonthlyCount();
        getEfficiency();
    }
   

    const buttons = () => {
        return (
            <>
            <Link to='/operations/supervision/report' className="btn ripple btn-primary">
                <i className="fas fa-chart-area"></i> Field Report
            </Link>
            <button className="btn ripple btn-light" onClick={handleRefresh}>
                <i className="fas fa-redo"></i> Refresh
            </button>

            </>
        )
    }

 

   
    const CardSection = () => {

        return (
            <div className="col-sm-6 col-md-4 col-xl-2 p-0">
                <RenderSecure code="R-SESSION-02">
                    <SuperCard className="col-12"
                        title="Daily Scans"
                        icon="far fa-clock"
                        value={dailyCount}
                        color="icon-service rounded-circle bg-primary-transparent text-primary"
                        href="#"
                    />
                </RenderSecure>
                <RenderSecure code="R-AVAIL-PARKING-02">
                    <SuperCard className="col-12"
                        title="Total Daily Sessions"
                        icon="fa fa-car"
                        href="#"
                        value={efficiency ? efficiency.total_sessions.total_p : '...'}
                        color="icon-service rounded-circle bg-secondary-transparent text-secondary"
                    />
                </RenderSecure>
                <RenderSecure code="R-CLAMP-02">
                    <SuperCard className="col-12"
                        title="Total Super-visors"
                        icon="fas fa-users"
                        href="#"
                        value={totalSupervisors}
                        color="icon-service bg-success-transparent rounded-circle text-success"
                    />
                </RenderSecure>

            </div>
        )

    }

    return (
        <div className="page">

            <LeftSideBar />
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                <TopBar />
                <div className="container-fluid">
                    <PageHeader
                        title="Field Supervision"
                        previousPageTitle="Operations"
                        buttons={buttons()}
                        back="/operations"
                    />

                    {toast}
                    {modal}

                    <div className="row">

                        <CardSection />

                        <RecentScan />

                    </div>
                </div>
            </div>
            <RightSideBar />
             
        </div>


    );

}


export default Supervisor
