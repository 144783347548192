import { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ParkingViolationContext from "../../Context/Violation/ParkingViolationContext";
import ConfigurationContext from "../../Context/ConfigurationContext";

const RefreshTools = () =>{

    const {getToolStats} = useContext(ParkingViolationContext);
    const {getClampTools} = useContext(ConfigurationContext)

    const handleRefresh=()=>{
        getToolStats();
        getClampTools();
    }

    return (
        <Link to="#" className="dropdown-item" onClick={handleRefresh}>
                    <i className="fa fa-redo text-secondary mr-2"></i>Refresh</Link>
    )

}


export default RefreshTools;