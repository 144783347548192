import React from 'react'
import ActivityLoader from '../Common/ActivityLoader';
import '../../assets/css/receipt.css'
import functions from '../../utils/functions';
class StickerReceipt extends React.Component {

    print = () => {
        functions.printPOS('receipt_document');
    }

    render() {
        const receipt = this.props.receipt;
        return (
            <div className="modal" id="pos_receipt">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Receipt</h6>

                            <i className="fas fa-print App-link" title="Print" onClick={this.print}></i>
                            <i className="fas fa-share-alt App-link" title="Share"></i>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12" id="receipt_document">
                                    {/**START RECEIPT BODY */}
                                    {!receipt && <ActivityLoader />}

                                    {receipt &&
                                        <div className="invoice-POS">
                                            <center id="top">
                                                <div className="logo"></div>
                                                <div className="info">
                                                    <h2>MULTIPLEX</h2>
                                                </div>{/**<!--End Info-->**/}
                                            </center>{/* <!--End InvoiceTop--> */}

                                            <div id="mid">
                                                <div className="info">
                                                    <p className="company-info">
                                                        P. O Box 3874 Kampala<br />
                            TIN: 1000073445 <br />
                            Tel No.: 0393360100<br />
                                                    </p>
                                                </div>
                                                <h3>PAYMENT-RECEIPT</h3>
                                                <p className="doc-type">-- STICKER PURCHASE --</p>

                                            </div>{/* <!--End Invoice Mid--> */}


                                            <div id="bot">

                                                <div id="table">
                                                    <table>

                                                        <tr className="service">
                                                            <td className="itemtext"><h2>RECEIPTNO :</h2></td>
                                                            <td className="itemtext"><h2>{receipt.sale_id_c}</h2></td>
                                                        </tr>

                                                        <tr className="service">
                                                            <td className="itemtext"><h2>STICKER TYPE :</h2></td>
                                                            <td className="itemtext"><h2>{receipt.sticker_type.ref_name}</h2></td>
                                                        </tr>

                                                        <tr className="service">
                                                            <td className="itemtext"><h2>PAYMENT DATE :</h2></td>
                                                            <td className="itemtext"><h2>{receipt.created_at.long_date}</h2></td>
                                                        </tr>

                                                        <tr className="service">
                                                            <td className="itemtext"><h2>VEHICLE NO :</h2></td>
                                                            <td className="itemtext"><h2>{receipt.vehicle.plate_number}</h2></td>
                                                        </tr>


                                                        <tr className="service">
                                                            <td className="itemtext"><h2>AMOUNT PAID :</h2></td>
                                                            <td className="itemtext"><h2>UGX {receipt.amount.amount_c}</h2></td>
                                                        </tr>


                                                        <tr className="service">
                                                            <td className="itemtext"><h2>EXPIRY DATE :</h2></td>
                                                            <td className="itemtext"><h2>{receipt.expiry_date.long_date} </h2></td>
                                                        </tr>


                                                        <tr className="service">
                                                            <td className="itemtext"><h2>ISSUED BY :</h2></td>
                                                            <td className="itemtext"><h2>{receipt.issued_by.first_name + ' ' + receipt.issued_by.last_name} </h2></td>
                                                        </tr>




                                                    </table>
                                                </div>{/* <!--End Table--> */}

                                                <div id="legalcopy">
                                                    <p className="legal"><strong>Thank you!</strong><br />
						multiplexug@gmail.com<br />
						Customer Care: 0393360100

						</p>
                                                </div>


                                            </div>{/* <!--End InvoiceBot--> */}

                                        </div>}{/* <!--End Invoice--> */}


                                    {/**END RECEIPT BODY */}


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default StickerReceipt
