import React, { Component } from 'react';
import Footer from '../../Components/Common/Footer';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import PageHeader from '../../Components/Common/PageHeader';
import RightSideBar from '../../Components/Common/RightSideBar';
import TitleValueCard from '../../Components/Common/TitleValueCard';
import TopBar from '../../Components/Common/TopBar';
import ConfigMenu from '../../Components/ConfigMenu';
import functions from '../../utils/functions';
import Code from './Code';

class APIDocument extends Component {

    state = {}

    componentDidMount() { }

    render() {
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">

                        <PageHeader
                            title="API Documentation"
                            previousPageTitle="Dashboard"
                            back="/config"
                        // options={{
                        //       "add": () => this.onClickAdd(),
                        //       "edit": () => this.onClickEdit(),
                        //       "delete": () => this.onClickDelete()
                        //   }}
                        />

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="card custom-card">

                                    <div className="card-body">

                                        <section class="wrapper__content">
                                            <h2 style={{ marginBottom: "25px" }}>Welcome to the Multiplex API</h2>

                                            <div className="content__default">
                                                <p>
                                                    The Parking Management System API (PMIS-API) is an API that manages the data generation, creation, updates and manipulation of all the data that comes into the system from requests made by our client (PMIS). <br /><br />
                                                    It is created using the ARAKNERD PHP Vanilla framework.
                                                </p>
                                                <h4 style={{ margin: "37px 0 25px" }}>
                                                    <a href="#get-started" class="header-anchor">#</a> Get started
                                                </h4>
                                                <p>
                                                    In this documentation, we will take you through a <a href="#" class="">step-by-step tutorial</a> to help you navigate through the system. To get started with PMIS, you need to have an account to access the system which then you can log in to it and start using it. For next steps, you need access to the system and to do that you need permissions to be given or issued to you by the administation.
                                                </p>
                                                {/* <h4 style={{ margin: "37px 0 25px" }}>
                                                    <a href="#discover-netlify" class="header-anchor">#</a> Discover Netlify
                                                </h4>
                                                <p>
                                                    can learn how to <a href="/site-deploys/create-deploys/" class="">create deploys</a>. Deployment options range from connecting a Git repository for <a href="/configure-builds/overview/" class="">continuous deployment</a> to publishing a site folder with <a href="https://app.netlify.com/drop/" target="_blank" rel="noopener noreferrer">drag and drop</a>. Use the <strong>Deploy to Netlify</strong> button on our <a href="https://templates.netlify.com" target="_blank" rel="noopener noreferrer">templates</a> to create a Git repository linked to a Netlify site that you can experiment with
                                                </p>
                                                <p>
                                                    Explore our documentation to learn about Netlify products and features. Here are some highlights from each section of the docs:
                                                </p>
                                                <p>
                                                    <strong>Configure builds</strong> – Netlify can run your build command and deploy the result whenever you push to your Git repo. Get started with <a href="/configure-builds/overview/" class="">basic build settings</a>, learn about <a href="/configure-builds/manage-dependencies/" class="">managing build dependencies</a>, and explore additional options available with <a href="/configure-builds/file-based-configuration/" class="">file-based configuration</a>.
                                                </p>
                                                <p>
                                                    <strong>Site deploys</strong> – <a href="/site-deploys/overview/" class="">Atomic deploys</a> with Netlify guarantee that your site is always consistent. Learn how to <a href="/site-deploys/manage-deploys/" class="">manage deploys</a>, enable <a href="/site-deploys/notifications/" class="">deploy notifications</a>, and run a <a href="/site-deploys/split-testing/" class="">branch-based split test</a>. Use the power of <a href="/site-deploys/deploy-previews/" class="">Deploy Previews</a> to review site changes and collaborate with team members.
                                                </p>
                                                <p>
                                                    <strong>Monitor sites</strong> – Learn how <a href="/monitor-sites/analytics/" class="">Netlify Analytics</a> lets you monitor trends in site activity. <a href="/monitor-sites/monitor-builds/" class="">Monitor builds</a> to understand the role individual sites play in your team’s builds usage. Explore what kinds of <a href="/monitor-sites/logs/" class="">logs</a> and <a href="/monitor-sites/notifications/" class="">notifications</a> are available for your sites.
                                                </p> */}
                                            </div>

                                        </section>


                                    </div>
                                </div>

                            </div>
                            {/* <ConfigMenu /> */}
                            <Code />
                        </div>
                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );
    }
}

export default APIDocument;
