import { useContext } from "react";
import DemandNoteContext from "../../../Context/Finance/DemandNoteContext";
import StatisticCard from "../../StatisticCard"

const PaidNote = () => {

    const {totalMonthlySettled} = useContext(DemandNoteContext);


    return( <StatisticCard
                title="Paid Notes" 
                value={totalMonthlySettled}
                caption="This month - UGX"
                icon={"fas fa-chart-bar"}
                color={"text-success"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-success"}
                sizeClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
                />
    )

}

export default PaidNote