import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async getCashierDailysummary(data) {

        let response = await apiCall("finance/cashier/summary/daily", data);
        return response;
    },


    async listDailySalesPerCashier(data) {

        let response = await apiCall("finance/cashier/list/sales/daily", data);
        return response;

    },


    async payStickerCash(
        depositor_name,
        depositor_contact,
        amount,
        vehicle_list
    )//POST
    {

        let data = {
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact,
            "amount": amount,
            "vehicle_list": vehicle_list
        }

        try {
            let response = await fetch(apiHost + 'cashier/sticker-purchase/cash',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async depositReservationCheque(
        depositor_name,
        depositor_contact,
        amount,
        cheque_number,
        cheque_date,
        bank_id,
        reservation_id
    )//POST
    {

        let data = {
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact,
            "amount": amount,
            "cheque_number": cheque_number,
            "cheque_date": cheque_date,
            "reservation_id": reservation_id,
            "bank_id": bank_id
        }

        try {
            let response = await fetch(apiHost + 'payment/reservation/cheque.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getReservationChequeReceipt(
        cheque_id
    )//POST
    {

        let data = {
            "cheque_id": cheque_id
        }

        try {
            let response = await fetch(apiHost + 'payment/reservation/cheque/receipt',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async depositReservationRTGS(
        depositor_name,
        depositor_contact,
        amount,
        origin,
        date,
        bank_id,
        reservation_id
    )//POST
    {

        let data = {
            "depositor_name": depositor_name,
            "depositor_contact": depositor_contact,
            "amount": amount,
            "origin": origin,
            "date": date,
            "reservation_id": reservation_id,
            "bank_id": bank_id
        }


        try {
            let response = await fetch(apiHost + 'payment/reservation/rtgs.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getReservationRTGSReceipt(rtgs_id)//POST
    {

        let data = {
            "rtgs_id": rtgs_id
        }

        try {
            let response = await fetch(apiHost + 'payment/reservation/rtgs/receipt',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getPaymentRecords(data)//POST
    {
        const response = await apiCall("payment/list/all", data);
        return response;
    },


    async getPaymentDeposit(deposit_ref)//POST
    {
        const data = {
            deposit_ref: deposit_ref
        }

        let response = await apiCall('payment/deposit/ref', data);
        return response;
    },

    async addVehicleOpeningBalance(plate_number, amount) {
        const response = await apiCall("migration/vehicle/balance/direct", {
            "plate_number": plate_number,
            "amount": amount
        });
        return response
    },

    async fetchMobileMoneyRecords(data) {
        const response = await apiCall("payment/mobilemoney/list", data);
        return response
    },

    async fetchCashiers() {
        const response = await apiCall("payment/cashier/list", []);
        return response
    },

  


    async migratePayments(file) {

        let data = {
            "file": file
        }
        let response = await apiCall("migration/payment/import", data)
        return response;
    },
    
    
    async fetchMigratedPayments(data) {
        let response = await apiCall("migration/payment/list", data)
        return response;
    },
    
    async approveMigratedPayments(password) {
        let data = {
            "password": password
        }
        let response = await apiCall("migration/payment/approve", data)
        return response;
    },
    
    
    async deleteMigratedPayment(password, id) {
        let data = {
            "password": password,
            "id":id
        }
        let response = await apiCall("migration/payment/delete", data)
        return response;
    },


    async fetchDatedPaymentModes() {
    
        let response = await apiCall("payment/method/dated/list", [])
        return response;
    },

    async postDatedPayment(data) {
        let response = await apiCall("payment/dated/post", data)
        return response;
    },


    async approveSingleMigratedPayment(password, id) {
        let data = {
            "password": password,
            "id":id
        }
        let response = await apiCall("migration/payment/approve/single", data)
        return response;
    },


    async correctPayment(paymentId, correctPlateNumber) {
        let data = {
            "payment_id": paymentId,
            "correct_plate_number":correctPlateNumber
        }
        let response = await apiCall("payment.correct", data)
        return response;
    },


    async fetchTotalMobileMoney(data) {
        const response = await apiCall("payment/mobilemoney/total", data);
        return response
    },


    async deletePaymentDeposit(deposit_ref) {
        let data = {
            "deposit_ref": deposit_ref,
        }
        let response = await apiCall("payment.delete", data)
        return response;
    },

    
    async pollMobileMoney(internal_ref) {
        let data = {
            "customer_ref": internal_ref,
        }
        let response = await apiCall("job/mobilemoney/process/all", data)
        return response;
    },

    async fetchMobileMoneyProviders(data) {
        const response = await apiCall("payment/mobilemoney/providers", data);
        return response
    },

    async fetchMobileMoneyProviderTotals(data) {
        const response = await apiCall("payment/mobilemoney/provider/total", data);
        return response
    },

}