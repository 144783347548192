import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ajaxBankDeposit from '../../utils/remote/ajaxBankDeposit';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import InitialAvatar from '../Common/InitialAvatar';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class PopBankSlipForm extends React.Component {

    state = {
        amount:'',
        depositorContact: '',
        depositorName: '',
        transactionRef:'',
        depositDate:'',
        info: '',
        bankList:false,
        bankId:false,
        plateNumber:'',
        loading:false
    }

    componentDidMount() {
      
        this.getBanks();
        
    }
    

    getBanks=async()=>{
        const server_response=await ajax.getBankingClients();
        if(server_response.status==="OK")
        {
            this.setState({
                bankList:server_response.details.content
            })
        }
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }

    onChangeBank = (selectedOption) => {
        this.setState({
            bankId: selectedOption.value
        })
    }

   

    onChangeTransactionRef =(event)=>{
        this.setState({ transactionRef: event.target.value })

    }

    onChangeDepositDate =(event)=>{
        this.setState({ depositDate: event.target.value })

    }
  
  

    onConfirm = async(event) => {
        event.preventDefault();
        const {depositorContact, depositorName, transactionRef, depositDate, bankId, amount} = this.state
        if (depositorContact.length > 0 && 
            depositorName.length > 0 && 
            transactionRef.length>0 && 
            depositDate.length>0 && 
            bankId.length>0 &&
            amount>0) {

                this.setState({
                    loading:true
                })
                const server_response = await ajaxBankDeposit.createBankDeposit(
                    depositorName,
                    depositorContact,
                    amount,
                    transactionRef,
                    depositDate,
                    bankId,
                    this.props.vendor.id
                )

                this.setState({
                    loading:false
                })
        
                if (server_response.status === "OK") {
                    this.props.onConfirm(<AlertSuccess message={server_response.details.message} />)
                    
                } else {
                    
                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    })
                }
            

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
            
        }
    }


    


  




    render() {

        const bankList = this.state.bankList;
        const optionsBank = [];
        if (bankList && bankList !== "404") {
            bankList.map((item, key) =>
                optionsBank.push({ label: item.bank_name, value: item.bank_id })
            )
        }


        return (
            <>
                <div className="modal" id="modal_bank_slip_form">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New Bank Deposit
                                <p style={{fontSize:14, marginTop:10}}>From: {this.props.vendor.name}</p>

                                </h6>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <div className="row">

                                


                                <div className="col-12">

                               
                                <form method="post" onSubmit={this.onConfirm}>

                                <SelectSearch
                                    label="Select Bank"
                                    onChange={() => this.onChangeBank}
                                    options={optionsBank}
                                    placeholder={"Select Bank of origin..."}
                                />


                                    <TextInput
                                          label="Transaction Ref"
                                          value={this.state.transactionRef}
                                          type="text"
                                          placeholder="Enter reference number on the slip"
                                          onChange={() => this.onChangeTransactionRef}
                                    />

                                    <TextInput
                                        label="Amount Deposited"
                                        type="number"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                                    <TextInput
                                          label="Deposit Date"
                                          value={this.state.depositDate}
                                          type="date"
                                          onChange={() => this.onChangeDepositDate}
                                    />
                                     

                                    <TextInput
                                        label="Depositor's Name"
                                        type="text"
                                        onChange={() => this.onChangeDepositorName}
                                        value={this.state.depositorName}
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                                

                         

                                </form>
                                </div>
                               
                                </div>



                            </div>
                            <div className="modal-footer">
                              {this.state.loading && <ActivityLoader/>}
                              {!this.state.loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Request Slip Verification</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }
}

export default PopBankSlipForm;
