import React from 'react';


class FltUnReadNotifications extends React.Component {
  

  render(){
    
      return (
        <span 
        type="button" 
        className=" badge badge-pill badge-info  ml-3"
        onClick={this.props.onClick}>
        UnRead
        </span>
      );
 
  }
}


export default FltUnReadNotifications;