import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {

    async deactivateUser(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user.deactivate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getUserByUsername(username) {

        let data = {
            // "user_id": user_id,
            "username": username
        }

        try {
            let response = await fetch(apiHost + 'user/search/username',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async activateUser(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user.activate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRoleCount() {


        try {
            let response = await fetch(apiHost + 'user/role/user.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },


    async updateUserBranch(user_id, branch_id) {

        const data = {
            "user_id": user_id,
            "branch_id": branch_id
        }
        let response = await apiCall("user/branch/update", data)

        return response
    },

    async fetchBranchUsers(branch_id) {

        const data = {
            "branch_id": branch_id
        }
        let response = await apiCall("user/branch/list", data)

        return response;
    },


    async importUsers(file) {

        let data = {
            "file": file
        }

        try {
            let response = await fetch(apiHost + 'user/import',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },





}
