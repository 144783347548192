import React from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import StickerReceipt from '../Payments/StickerReceipt';

class RecentTicketSales extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        receiptView: false
    }

    async componentDidMount() {
        await this.listTransactions();
        this.timerlistTransactions = setTimeout(this.functransactionList = async () => {
            await this.listTransactions();
            this.timerlistTransactions = setTimeout(this.functransactionList, dictionary._waitTime); // (*)
        }, dictionary._waitTime);

    }

    componentWillUnmount() {
        clearTimeout(this.timerlistTransactions)
    }

    listTransactions = async () => {
        const server_response = await ajax.listRecentTicketSales();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list
            })
        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickReceipt = (record) => {
        this.setState({
            receiptView: false
        }, () => {

            this.setState({
                receiptView: <StickerReceipt
                    receipt={record}
                />
            }, () => {
                functions.openPopup("pos_receipt");
            })

        })

    }


    render() {
        const transactionList = this.state.transactionList;
        return (
            <div className="row">
                {this.state.receiptView}
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="card-title mb-1">Recent Ticket Sales</h6>
                                <p>Recently sold parking tickets in order of the most recent</p>
                            </div>
                            <div className="table-responsive">

                                {transactionList && transactionList !== "404" &&
                                    <table className="table table-hover text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sale Date</th>
                                                <th>Sale ID</th>
                                                <th>Sale Agent</th>
                                                <th>Vehicle</th>
                                                <th>Float Ref</th>
                                                <th>Amount(UGX)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionList.map((item, key) =>
                                                <tr key={key}>
                                                    <td>{item.created_at.long_date}</td>
                                                    <td>{item.sale_id_c}</td>
                                                    <td>{item.sold_by.username}</td>
                                                    <td className="tx-bold">
                                                        <Link to={
                                                            {
                                                                pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                state: { plateNumber: item.vehicle.plate_number }
                                                            }}>{item.vehicle.plate_number}
                                                        </Link>
                                                    </td>
                                                    <td>{item.float_id}</td>
                                                    <td>{item.amount.amount_c}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {transactionList === "404" && <NoContent />}
                                {!transactionList && <ActivityLoader />}
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        )
    }

}

export default RecentTicketSales
