import React, { useEffect, useState } from 'react';
import ajaxViolation from '../../utils/remote/ajaxViolation';

const ZuluReportContext = React.createContext();

export const ZuluReportConsumer = ZuluReportContext.Consumer;

export const ZuluReportProvider = (props)=> {

  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState(false);
  const [title, setTitle] = useState('');



useEffect(()=>{
  if(data){
    getZuluPerformance()
  }
}, [data])


const getZuluPerformance=async()=>{
    setPerformanceData(false);
    const server_response = await ajaxViolation.fetchZulusPerformance(data);
    if(server_response.status==='OK'){
        setPerformanceData(server_response.details.content)
        setTitle(server_response.message)
    }else{
        setPerformanceData('404')
    }   
}




const resetData=()=>{
    setData({
        "date_from":'',
        "date_to":'',
    })
}

const filterData=(options)=>{
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
    })
    
}

    
    return (
           <ZuluReportContext.Provider value={
               {
                  loading,
                  performanceData,
                  title,
                  getZuluPerformance,
                  setData,
                  resetData,
                  filterData
               }
               }>
               {props.children}
           </ZuluReportContext.Provider>
        );
    
}

export default ZuluReportContext;