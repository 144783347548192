import React, { useContext, useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxLocation from '../utils/remote/ajaxLocation';
import ajaxOperation from '../utils/remote/ajaxOperation';
import ajaxUser from '../utils/remote/ajaxUser';
import { detectTable, restoreTable } from '../utils/script/customTables';
import BranchContext from './BranchContext';

const LocationMappingContext = React.createContext();
const branch_id = functions.extractToken()['branch_id'] || 1
export const LocationMappingConsumer = LocationMappingContext.Consumer;

export const LocationMappingProvider=(props)=>{

   const [metaData, setMetaData] = useState(false);
   const [data, setData] = useState({"page":"1", "branch_id":branch_id})
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [branchId, setBranchId] = useState(branch_id);
  
   const [locationMapping, setLocationMapping] = useState(false);

   const { branchListing, branchName, switchBranch } = useContext(BranchContext)
   const [branchUsers, setBranchUsers] = useState(false);
   const [branchLocations, setBranchLocations] = useState(false);


   useEffect(()=>{
    getMapping();
    listBranchUsers();
    listBranchLocations();
   }, [data])

  
   useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
    }, [currentPage])


    const getMapping = async()=>{
        setLocationMapping(false)
        const server_response = await ajax.getUserLocationMapping(data);
        if(server_response.status === "OK"){
            setLocationMapping(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);

        }else{
            setLocationMapping("404")
        }
    }
   

    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());
        const server_response = await  ajax.getUserLocationMapping(newData);

        setLoading(false);
           
        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setLocationMapping(locationMapping.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1", "branch_id":branchId})
    }

    const filterData=(options)=>{
        const data={
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "invoice_class_id":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        }
        setData(data)
        
    }


    const switchMappingBranch=(branchId)=>{
        setBranchId(branchId)
        setData({"page":"1", "branch_id":branchId})
        switchBranch(branchId);
    }


    const switchRole=(roleId)=>{
        setData({"page":"1", "role_id":roleId, "branch_id":branchId})
    }


    const refresh=()=>{
       getMapping();
    }



    const listBranchUsers = async()=>{
        const server_response = await ajaxUser.fetchBranchUsers(branchId);
        if(server_response.status === "OK"){
            setBranchUsers(server_response.details.content)
        }else{
            setBranchUsers("404");
        }
    }

    const listBranchLocations = async()=>{

        const server_response = await ajaxLocation.fetchBranchLocations(branchId);
        if(server_response.status === "OK"){
            setBranchLocations(server_response.details.content)
        }else{
            setBranchLocations("404")
        }
    }



        return (
           <LocationMappingContext.Provider 
            value={{
                locationMapping,
                loading,
                metaData,
                branchListing,
                branchLocations,
                branchUsers,
                branchName,
                switchMappingBranch,
                switchRole,
                goToNextPage,
                resetData,
                filterData,
                refresh
            }}
            >
               {props.children}
           </LocationMappingContext.Provider>
        );
}

export default LocationMappingContext;