import React from 'react';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';
import functions from '../../utils/functions'
import ajaxSession from '../../utils/remote/ajaxSession';
import ParkingMigrationContext from '../../Context/Migration/ParkingMigrationContext';
import ajaxPayment from '../../utils/remote/ajaxPayment';
import PaymentMigrationContext from '../../Context/Migration/PaymentMigrationContext';

class PopUploadPaymentData extends React.Component {
    static contextType = PaymentMigrationContext
    state = {
        userId: functions.sessionGuard(),
        fileName:'',
        actualCsvFile:'',
        info: '',
        loading: false
    }

    onChangeFile = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
             this.setState({
                actualCsvFile:{file:  e.target.result}
             })
         }
         
       }


    onConfirm = async (event) => {
        event.preventDefault();
        const {fileName, actualCsvFile}=this.state;
        if (fileName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response = await ajaxPayment.migratePayments(actualCsvFile);
             this.setState({
                loading: false
            })
            if(server_response.status=="OK")
            {
                this.context.getMigrations();
                
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />,
                    fileName:'',
                    actualCsvFile:''
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

           
        } else {
            this.setState({
                info: <AlertError message={"Select CSV file and try again"} />
            })
        }
    }




   
    render() {

        return (
            <div className="modal" id="modal_import_payments">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Upload Vehicle Payments</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              <p>Note: The CSV file must have seven(7) columns labelled <b>Date</b>, <b>Plate Number</b>,  <b>Amount</b>, <b>Payment Mode</b>, <b>Customer Name</b>, <b>Customer Contact</b>, and <b>Reference</b></p>

                            <TextInput
                                    label="Select CSV file"
                                    type="file"
                                    onChange={() => this.onChangeFile}
                                    value={this.state.fileName}
                                />



                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Upload</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopUploadPaymentData;
