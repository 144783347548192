import React, { useContext, useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import ajaxTicket from '../utils/remote/ajaxTicket';
import { detectTable, restoreTable } from '../utils/script/customTables';
import AuthContext from './AuthContext';

const VirtualTicketContext = React.createContext();

export const VirtualTicketConsumer = VirtualTicketContext.Consumer;

export const VirtualTicketProvider = (props) => {

    const [ticketListing, setTicketListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const { secureCall } = useContext(AuthContext);
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [ticketSellerListing, setTicketSellerListing] = useState(false);




    useEffect(()=>{
        if(userId>0)
        {
            secureCall(getVirtualTickets, "R-TICKET-02")
            secureCall(listVirtualTicketSellers, "R-TICKET-02")
        }
      
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getVirtualTickets=async()=>{
        setLoading(true)
        setTicketListing(false)
        setCurrentPage(1);
        const server_response = await ajax.listTicketSales(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTicketListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setTicketListing("404");
            setMetaData(false);
        }
    }



    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajax.listTicketSales(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTicketListing(ticketListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "agent_id":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        })
        
    }



    const listVirtualTicketSellers=async()=>{
        const server_response = await ajaxTicket.fetchVirtualTicketSellers();
        if(server_response.status === "OK"){
            setTicketSellerListing(server_response.details.content)
        }   
    }


  
    return (
        <VirtualTicketContext.Provider value={{
            ticketListing,
            metaData,
            ticketSellerListing,
            getVirtualTickets,
            goToNextPage,
            setData,
            resetData,
            filterData
        }}
        >
            {props.children}
        </VirtualTicketContext.Provider>
    );
}


export default VirtualTicketContext;
