import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import WarnSecureDelete from '../../Components/WarnSecureDelete'
import PopChequePaymentReceipt from '../../Components/Payments/PopChequePaymentReceipt'
import PopStickerOptions from '../../Components/Finance/Cashier/PopStickerOptions'
import { Link } from 'react-router-dom'

class StickerChequeRecord extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        chequeId:'',
        chequeRecord:false,
        chequeVehicles:false,
        chequeType:false,
        chequeTotal:0,
        vehicleTotal:0,
        excessAmount:0,
        vehicleSheet:false,
        editedAmount:false,
        sheetId:false,
        edited:false,
        loading:false,
        toast: false,
        mdlWarn:false,
        receipt:false,
        receiptLoading:false,
        mdlReceipt:false,

        mdlStickerOptions:false,
        selectedVehicleInfo:false
       
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/finance/payment_cheques');
          } else {
            this.setState({
              chequeId: this.props.location.state.chequeId
            }, () => {
                this.getCheque();
            })
          }

    }


    getCheque=async()=>{
        const server_response=await ajax.getChequeRecord(this.state.chequeId);
        if(server_response.status==="OK")
        {
            this.setState({
                chequeRecord:server_response.details.content,
                chequeType:server_response.details.content.cheque_type,
                chequeTotal:server_response.details.content.amount.amount
            })

            
            this.getVehicleCheques()
            
        }
    }

    
    getVehicleCheques=async()=>{
        const server_response=await ajax.getVehiclesForStickerCheque(this.state.chequeId);
        if(server_response.status==="OK")
        {
            this.setState({
                chequeVehicles:server_response.details.content
            }, ()=>{
                const chequeSheet=this.state.chequeVehicles;
                const vehicleSheet=[];
                chequeSheet.map((item, key)=>{
                    vehicleSheet.push({
                        plate_number:item.vehicle.plate_number,
                        amount:item.sticker_type.amount.amount*1,
                        amount_c:item.sticker_type.amount.amount_c,
                        sticker_type_id:item.sticker_type.sticker_type_id,
                        sticker_ref_name:item.sticker_type.ref_name,
                        sheet_id:item.vehicle_cheque_id
                    })
                    
                })
                this.setState({
                    vehicleSheet:vehicleSheet
                }, ()=>this.computeSheetAmount(this.state.vehicleSheet))

            })
        }
    }


   

    computeSheetAmount=(sheet)=>{
        var totalVehicles=0;
        for(var i=0; i<sheet.length; i++)
        {
            totalVehicles+=sheet[i].amount
        }
        this.setState({
            vehicleTotal:totalVehicles,
            excessAmount:this.state.chequeTotal*1-totalVehicles
        })
    }

  
    resetSheetId=()=>{
        this.setState({sheetId:false})
    }


    buttons = () => {
        const chequeRecord=this.state.chequeRecord
        return (
            <>
            {this.state.chequeRecord && <>

               {this.state.loading && <button to="#" className="btn ripple btn-light" onClick={this.onClickAddPayment}>
                    <ActivityLoader
                     height={20} 
                     width={30} 
                     customClass="custom"
                    />
                </button>}
            
               {this.state.edited && !this.state.loading && <button className="btn ripple btn-primary" onClick={this.saveVehicleAmounts}>
                    <i className="fas fa-save"></i> Save Changes
                </button>}
                {!this.state.edited && chequeRecord.status.status_id*1==0 && <button className="btn ripple btn-success" onClick={()=>this.onClickHandleRequest(1)}>
                    <i className="far fa-check-circle"></i> {chequeRecord.next_status}
                </button>}
                {!this.state.edited && chequeRecord.status.status_id*1==1 && <button className="btn ripple btn-success" onClick={()=>this.onClickHandleRequest(3)}>
                    <i className="far fa-check-circle"></i> {chequeRecord.next_status}
                </button>}

                {chequeRecord.status.status_id*1<=1 && <button className="btn ripple btn-danger" onClick={()=>this.onClickHandleRequest(2)}>
                    <i className="fas fa-times"></i> Cancel Cheque
                </button>}

                {chequeRecord.status.status_id*1==3 && !this.state.receiptLoading?
                <button className="btn ripple btn-light" onClick={this.onClickPrintReceipt}>
                    <i className="fas fa-print"></i> Cheque Receipt
                </button>:
                 <button className="btn ripple btn-light">
                 <ActivityLoader 
                 height={20} 
                 width={30} 
                 customClass="custom"
                 />
             </button>
            }
            </>}
            </>
        )
    }



    saveVehicleAmounts=async()=>{
        this.setState({loading:true})
        const server_response=await ajax.updateStickerTypesForCheque(this.state.vehicleSheet);
        this.setState({loading:false})
        if(server_response.status==="OK")
        {   
            this.setState({
                edited: false,
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
            })
        }else{
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
        }
    }



    resetToast = () => {
        this.setState({
            toast: false
        })
    }





     /**
     * INVOKES THE HANDLE REQUEST DIALOGUE
     */
      onClickHandleRequest=(status)=>{
        functions.closePopup('modal_white_list');
        var { title, description, buttonLabel, buttonType}="";
        this.setState({
            status:status
        })
        switch(status)
        {
            case 2://cancelling
                title="Cancel Payment Cheque";
                description="Please confirm with your account password that you want to cancel this cheque";
                buttonLabel="Cancel Cheque";
                buttonType="btn-danger"
            break;
            case 1://approving

                title="Approve Payment Cheque";
                description="Please confirm with your account password that you want to approve this cheque";
                buttonLabel="Approve Cheque";
                buttonType="btn-success"

            break;
            case 3://process
                title="Process Payment Cheque";
                description="Please confirm with your account password that you want to process this cheque";
                buttonLabel="Process Cheque";
                buttonType="btn-primary"
            break;

            case 4://approving
                title="Terminate Payment Cheque";
                description="Please confirm with your account password that you want to terminate this cheque";
                buttonLabel="Terminate Cheque";
                buttonType="btn-danger"
            break;

            default:
                title="";
                description="";
                buttonLabel="";
            break;
        }

        this.setState({
            mdlWarn:false
        }, ()=>{
            
            this.setState({
                mdlWarn:<WarnSecureDelete 
                title={title}
                description={description}
                icon="fas fa-question"
                buttonLabel={buttonLabel}
                buttonType={buttonType}
                onConfirm={this.confirmHandleRequest}
                />
            }, ()=>{
                functions.openPopup('modal_secure_delete')
            })
        })
    }


     /**
     * EFFECT THE HANDLE REQUEST ACTION
     */
     confirmHandleRequest=async(password)=>{
        this.setState({
            loading:true
        })
        var server_response;
        switch(this.state.status)
        {
            case 2://CANCEL REQUEST
                server_response=await ajax.cancelCheque(
                    this.state.chequeId,
                    password
                );
        
            break;
            case 1://APPROVE REQUEST
                server_response=await ajax.approveCheque(
                    this.state.chequeId,
                    password
                );
        
            break;
            case 3://PROCESS REQUEST
            server_response=await ajax.processCheque(
                this.state.chequeId,
                password
            );
            break;
            case 4://TERMINATE REQUEST
            // server_response=await ajax.terminateWhiteList(
            //     this.state.request.request_id,
            //     password
            // );
    
            break;
            default:
                server_response={'status':'conn', 'details':{'message':'Invalid request', 'content':false}}
            break;
        }
        this.setState({
            loading:false
        })
       
        if(server_response.status==="OK")
        {
            this.setState({
                toast: <Toast
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
              this.refresh()
        }else{
            this.setState({
                toast: <Toast
                type={4}
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
        }
     }



     refresh=()=>{
        this.setState({
            chequeRecord:false
        }, async()=>{
            await this.getCheque()
            this.onClickPrintReceipt()
        })
     }



     getChequeReceipt=async()=>{
        const server_response=await ajax.getChequePayment(this.state.chequeId);
        if(server_response.status=="OK")
        {
            this.setState({
                receiptLoading:false,
                receipt:server_response.details.content,
                mdlReceipt:false
            }, ()=>{
                this.setState({
                    mdlReceipt:<PopChequePaymentReceipt record={this.state.receipt}/>
                }, ()=>functions.openPopup('modal_cheque_receipt'))
            })
            return true;
        }else{
            this.setState({
                receiptLoading:false,
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
            return false;
        }
     }


     onClickPrintReceipt=()=>{

       if(this.state.chequeRecord.status.status_id*1==3)
       {
        this.setState({
            receiptLoading:true,
            receipt:false
        })

        this.getChequeReceipt();
        }
     }



     onClickChangeSticker=(record)=>{
        if(this.state.chequeRecord.status.status_id*1>1)
        {
            this.setState({
                toast: <Toast type={4} message={"This cheque can nolonger be updated."} onClose={this.resetToast} />
            })
            return false;
        }
        this.setState({
            mdlStickerOptions:false,
            selectedVehicleInfo:record
        }, ()=>{
            this.setState({
                mdlStickerOptions:<PopStickerOptions 
                                    id="mdl_sticker_options" 
                                    onSelect={this.onChangeStickerType}
                                    title={"Change Sticker - " + record.plate_number}/>
            }, ()=>{
                functions.openPopup('mdl_sticker_options')
            })
        })
    }


    onChangeStickerType=(type)=>{
        functions.closePopup('mdl_sticker_options');
        const item = this.state.selectedVehicleInfo;
        //loop through the vehicle list
        const vehicleList=this.state.vehicleSheet
        var new_amount=0;
        for(var i=0; i<vehicleList.length; i++)
        {
            if((vehicleList[i].plate_number==item.plate_number))
            {
                vehicleList[i].amount=type.amount.amount*1;
                vehicleList[i].amount_c=type.amount.amount_c;
                vehicleList[i].sticker_ref_name=type.ref_name;
                vehicleList[i].sticker_type_id=type.sticker_type_id;
            }
            new_amount+=vehicleList[i].amount*1;
        }

        this.setState({
            vehicleList:vehicleList,
            edited:true
        },  ()=>this.computeSheetAmount(this.state.vehicleSheet))

       
    }


   
    render() {
      
        const cheque=this.state.chequeRecord
        const chequeVehicles=this.state.chequeVehicles
        const activeSheet=this.state.vehicleSheet
        return (
            <div className="page">
                {this.state.mdlStickerOptions}
                

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Cheque Record"
                            previousPageTitle="Payment Cheques"
                            // options={this.state.menuOptions}
                            back="/finance/payment_cheques"
                            buttons={<this.buttons />}
                        />
                        {this.state.toast}
                        {this.state.mdlWarn}
                        {this.state.mdlReceipt}
                       {this.state.chequeRecord && <div className="row">
                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Cheque Details"
                                            subtitle="Cheque Banking details"
                                        />


                                    <table className="table text-nowrap mb-0 table-hover">
                                    <tbody>
                                        <tr>
                                            <td>Cheque Type</td>
                                            <td className="tx-bold">{cheque.cheque_type.type_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Cheque Number</td>
                                            <td className="tx-bold">{cheque.cheque_number}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Cheque Date</td>
                                            <td className="tx-bold">{cheque.cheque_date.short_date}</td>
                                        </tr>

                                        <tr>
                                            <td>Total Amount</td>
                                            <td className="tx-bold">UGX. {cheque.amount.amount_c}</td>
                                        </tr>

                                        <tr>
                                            <td>Bank Of Origin</td>
                                            <td className="tx-bold">{cheque.bank.bank_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Cheque Status</td>
                                            <td className="tx-bold">{cheque.status.status}</td>
                                        </tr>
                                        <tr>
                                            <td>Depositor's Name</td>
                                            <td className="tx-bold">{cheque.depositor_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Depositor's Contact</td>
                                            <td className="tx-bold">{cheque.depositor_contact}</td>
                                        </tr>
                                        <tr>
                                            <td>Created On</td>
                                            <td className="tx-bold">{cheque.created_at.long_date}</td>
                                        </tr>
                                        <tr>
                                            <td>Created By</td>
                                            <td className="tx-bold">{cheque.created_by.username}</td>
                                        </tr>
                                    </tbody>
                                   

                                </table>
                            



                                    </div>
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Payment Details"
                                            subtitle="List of vehicles with their respective sticker types"
                                        />
                                        <div className="activity-block mr-3 scroll-widget border-top overflow-auto" style={{height:"280px"}}>
                                         
                                          <table className="table text-nowrap mb-0 table-hover">
                                              <thead>
                                                  <td>No.</td>
                                                  <td>Vehicle</td>
                                                  <td>Sticker Type</td>
                                                  <td>Amount</td>
                                              </thead>
                                            <tbody>
                                                {activeSheet && activeSheet.map((item, key)=>
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>
                                                        <Link to={
                                                            {
                                                                pathname: `/vehicles/profile/${item.plate_number}`,
                                                                state: { plateNumber: item.plate_number }
                                                            }
                                                            }
                                                        >
                                                                {item.plate_number}
                                                        </Link>
                                                            
                                                        </td>
                                                        <td>
                                                            <span className="tx-bold App-link"  onClick={()=>this.onClickChangeSticker(item)}>
                                                            {item.sticker_ref_name}
                                                        </span>
                                                        </td>
                                                        <td>{item.amount_c}</td>
                                                    </tr>
                                                )}
                                                
                                            </tbody>
                                          </table>
                                          {!chequeVehicles && <ActivityLoader/>}
                                       
                                        </div>




                                        <div className="activity-block mr-3 border-top">
                                         
                                            <table className="table text-nowrap mb-0 table-hover">

                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Vehicles Total</td>
                                                    <td className="tx-bold">UGX. {this.state.vehicleTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Cheque Total</td>
                                                    <td className="tx-bold">UGX. {this.state.chequeTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Excess Amount</td>
                                                    <td className="tx-bold">UGX. {this.state.excessAmount}</td>
                                                </tr>
                                            </table>
                                        </div>


                                       

                                    </div>
                                </div>
                            </div>




                        </div>}

                        {!this.state.chequeRecord && <ActivityLoader/>}

                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );

    }
}

export default StickerChequeRecord
