import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async fetchBranchUsers(data)
    {
       let response = await apiCall("branch/monitor/users", data);
       return response
    },

    async fetchBranchInfo(branch_id)
    {
       let response = await apiCall("branch/info", {"branch_id":branch_id});
       return response
    },

    async countBranchActiveSessions(branch_id)
    {
        let response = await apiCall("branch/session/active/count", {"branch_id":branch_id});
        return response
    },
    
    async countBranchClampedVehicles(branch_id)
    {
        let response = await apiCall("violation/clampedvehicle/branch_count", {"branch_id":branch_id});
        return response
    },

    async updateSessionInfo(session_id, plate_number, slots)
    {
        const data={
            "session_id":session_id,
            "plate_number":plate_number,
            "slots":slots
        }  
        
       let response = await apiCall("session/update", data);
       return response
    },


    async fetchBranchSessions(data)
    {
       let response = await apiCall("session/list/branch", data);
       return response
    },



    
    async fetchVIPVehicles(data) {

        const response = await apiCall("vip/list", data)
        return response;
    },


    async postBulkyVehicleLink(data) {

        const response = await apiCall("operations/vehicle/bulky_linking", data)
        return response;
    },


   
}