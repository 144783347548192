import React from 'react';
import StickerContext from '../../Context/StickerContext';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import SelectSearch from '../Common/SelectSearch';
import AlertError from './../Common/AlertError';
import TextInput from './../Common/TextInput';

class PopNewStickerType extends React.Component {

    static contextType = StickerContext


    state = {
        typeName: '',
        info: '',
        searchList: false,
        loading: false,
        price: '',
        vehicleTypeId:'',
        VehicleTypeList:false,
        duration:'',
        deductionLimit:'',
        decrement:'',
        stickerCategory:''
    }

    componentDidMount() {
        this.getVehicleTypes();
    }
    

    getVehicleTypes = async () => {
        const server_response = await ajax.getVehicleTypes();
        if (server_response.status === "OK") {
            
                this.setState({
                    VehicleTypeList: server_response.details.content
                })

        } else {
            this.setState({
                VehicleTypeList: "404"
            })
        }
    }

    onChangeVehicleType = (selectedOption) => {
        this.setState({
          vehicleTypeId: selectedOption.value
        })
      }
    

    onChangeTypeName = (event) => {
        this.setState({ typeName: event.target.value });
    }

    onChangePrice = (event) => {
        this.setState({ price: event.target.value });
    }

    onChangeDuration = (event) => {
        this.setState({ duration: event.target.value });
    }

    onChangeDecrement = (event) => {
        this.setState({ decrement: event.target.value });
    }

    onChangeDeductionLimit = (event) => {
        this.setState({ deductionLimit: event.target.value });
    }


    onChangeStickerCategory=(item)=>{
        this.setState({
            stickerCategory:item.value
        })
    }


    RenderStickerCategory=()=>{

        const { stickerClassList } = this.context;

        const options  = [];
        Array.isArray(stickerClassList) && stickerClassList.map((item, key)=>
            options.push({"label":item.class_name, value:item.id})
        )
           
        return (
            <TextInput
                label="Sticker Class"
                type="select"
                name="StickerCategory"
                options={options}
                placeholder="Select Sticker Category"
                onChange={this.onChangeStickerCategory}
            />

        )
    }



    onConfirm = (event) => {
        event.preventDefault();
        const { typeName, price, duration, deductionLimit, decrement, stickerCategory } = this.state;
        if (typeName.length > 0 && price.length>0 && deductionLimit > 0 && duration.length>0 && decrement.length>0 && stickerCategory.length>0) {
            this.setState({
                loading: true
            })
            this.props.onConfirm(typeName, price, duration, decrement, deductionLimit, stickerCategory);
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }



    render() {
        const VehicleTypeList = this.state.VehicleTypeList;
        const optionsVehicleType = [];
        if (VehicleTypeList && VehicleTypeList !== "404") {
        VehicleTypeList.map((item, key) =>
            optionsVehicleType.push({ label: item.type_name, value: item.type_id })
        )
        }
        return (
            <div className="modal" id="modal_new_sticker_type">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Sticker Type</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                          {VehicleTypeList &&  <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Type Name"
                                    type="text"
                                    onChange={() => this.onChangeTypeName}
                                    value={this.state.typeName}
                                />
                                  {/* <SelectSearch
                                    label="Vehicle Type"
                                    onChange={() => this.onChangeVehicleType}
                                    options={optionsVehicleType}
                                    placeholder={"Select vehicle type..."}
                                /> */}

                                <TextInput
                                    label="Price"
                                    type="number"
                                    onChange={() => this.onChangePrice}
                                    value={this.state.price}
                                />

                                 <TextInput
                                    label="Duration"
                                    placeholder="Duration (In days) e.g. 30"
                                    type="number"
                                    onChange={() => this.onChangeDuration}
                                    value={this.state.duration}
                                />

                                 <TextInput
                                    label="Decrement"
                                    placeholder="Amount deducted per day e.g. 3000"
                                    type="number"
                                    onChange={() => this.onChangeDecrement}
                                    value={this.state.decrement}
                                />

                                 <TextInput
                                    label="Decrement Limit"
                                    placeholder="Last day of deduction e.g. 21"
                                    type="number"
                                    onChange={() => this.onChangeDeductionLimit}
                                    value={this.state.deductionLimit}
                                />

                                <this.RenderStickerCategory/>

                            </form>}

                            {!VehicleTypeList && <ActivityLoader/>}



                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Create Sticker Type</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopNewStickerType;
