import React, { createContext, Component } from 'react';
import functions from '../../utils/functions';
import $ from 'jquery';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';
import { detectTable, restoreTable } from '../../utils/script/customTables';
import AlertWarning from '../../Components/Common/AlertWarning';



const eformat = dictionary._exportBtnFormats;
const PaymentsTrackingContext = React.createContext({});



export class PaymentsTrackingProvider extends Component {

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        status: '',
        paymentView: false,
        receiptView: false,
        invoiceView: false,
        pagination: false,
        paymentMethod: '',
        paymentMethods: false,
        cashiersList: "",
        loadTable: true,
        loading: false,
        fallbackPage: 1,
        backuptransactionData: false,
        info: false,
        filterInfo: false

    };


    componentDidMount() {
        this.listTransactions();
        this.getPaymentMethods();
        this.getCashiersAndSuperAdmins()

    }





    listTransactions = async () => {

        const { filter, currentPage, paymentMethod } = this.state
        if (filter) {
            var server_response = await ajax.getPaymentRecords(currentPage, paymentMethod, filter.selectedValue, filter.dateFrom, filter.dateTo, filter.selectedValue1)

        } else {
            var server_response = await ajax.getPaymentRecords(currentPage, paymentMethod, "", "", "", "");
        }

        // const server_response = await ajax.getPaymentRecords(this.state.currentPage, this.state.paymentMethod);
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta,
            })
        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }


    }

    getFilteredData = async (filter) => {
        const { currentPage, paymentMethod, transactionList } = this.state
        if (filter) {

            restoreTable(detectTable())
            this.setState({
                backuptransactionData: transactionList,
                fallbackPage: currentPage,
                currentPage: 1,
                loading: true,
                info: false
            })

            setTimeout(() => {
                this.setState({
                    transactionList: false,
                    metaData: false
                })
            }, 10);

            const server_response = await ajax.getPaymentRecords(currentPage, filter.selectedValue1, filter.selectedValue, filter.dateFrom, filter.dateTo)

            if (server_response.status === "OK") {
                this.setState({
                    transactionList: server_response.details.content.list,
                    metaData: server_response.details.content.meta,
                    loading: false
                })
            } else {
                this.setState({
                    info: <AlertWarning message={server_response.details.message} />,
                    filterInfo: server_response.details.content.list
                })


            }
        }
    }


    filterSessions = (filterData) => {
        this.setState({
            filter: filterData
        })
        setTimeout(() => {
            this.getFilteredData(filterData)
        }, 10);

    }





    fetchMore = () => {
        const { filter, paymentMethod, currentPage, transactionList } = this.state;

        this.setState({
            currentPage: currentPage + 1,
            loading: true
        })

        setTimeout(async () => {

            if (filter) {
                var server_response = await ajax.getPaymentRecords(currentPage, paymentMethod, filter.selectedValue, filter.dateFrom, filter.dateTo, filter.selectedValue1)
            }
            else {
                var server_response = await ajax.getPaymentRecords(this.state.currentPage + 1, paymentMethod, "", "", "", "");
            }
            this.setState({
                loading: false
            }, () => restoreTable(detectTable()))

            if (server_response.status === "OK") {

                const incoming = Array.from(server_response.details.content.list);

                this.setState({
                    transactionList: transactionList.concat(incoming),
                    metaData: server_response.details.content.meta,
                    loadTable: true
                })

            }

        }, 10);

    }


    getCashiersAndSuperAdmins = async () => {
        const server_response = await ajax.listCashiersAndSuperAdmins(this.state.userId)
        if (server_response.status === "OK") {
            this.setState({
                cashiersList: server_response.details.content,
            })
        }
    }


    resetFilter = () => {
        this.setState({
            filter: false,
            transactionList: false
        }, () => this.listTransactions())
    }

    getPaymentMethods = async () => {

        const server_response = await ajax.getAllPaymentModes();
        if (server_response.status === "OK") {
            this.setState({
                paymentMethods: server_response.details.content
            })
        }
    }

    refreshTransactionData = () => {
        const { backuptransactionData, fallbackPage } = this.state;
        if (backuptransactionData) {
            this.setState({
                filter: false,
                currentPage: fallbackPage,
                transactionList: backuptransactionData
            })
        }

    }





    render() {

        return (
            <PaymentsTrackingContext.Provider value={{
                transactionList: this.state.transactionList,
                loading: this.state.loading,
                paymentMethods: this.state.paymentMethods,
                cashiersList: this.state.cashiersList,
                fetchMore: this.fetchMore,
                filterSessions: this.filterSessions,
                refreshTransactionData: this.refreshTransactionData,
                info: this.state.info,
                filterInfo: this.state.filterInfo,
                filter: this.state.filter,
                listPaymentTransactions: this.listTransactions
            }}>
                {this.props.children}
            </PaymentsTrackingContext.Provider>
        );
    }

}



export default PaymentsTrackingContext;