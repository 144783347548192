import { data } from 'jquery';
import React, { useContext, useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxOperation from '../utils/remote/ajaxOperation';
import { detectTable, restoreTable } from '../utils/script/customTables';
import AuthContext from './AuthContext';
import BranchContext from './BranchContext';

const BranchSessionContext = React.createContext();
const branch_id = functions.extractToken()['branch_id'] || 1
export const BranchSessionConsumer = BranchSessionContext.Consumer;

export const BranchSessionProvider=(props)=>{

   const [metaData, setMetaData] = useState(false);
   const [data, setData] = useState({"page":"1", "branch_id":branch_id})
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [branchId, setBranchId] = useState(branch_id);
   const [sessionListing, setSessionListing] = useState(false);
   const { secureCall } = useContext(AuthContext);
   const { switchBranch } = useContext(BranchContext)



   useEffect(()=>{
        secureCall(listSessions, "R-BRANCH-SESSION-01");
   }, [data])

  
   useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
    }, [currentPage])


   

    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());
        const server_response = await ajaxOperation.fetchBranchSessions(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setSessionListing(sessionListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1", "branch_id":branchId})
    }

    const filterData=(options)=>{
        const newData={
            ...data,
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "conductor_id":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        }

        setData(newData)
        
    }



    const listSessions=async()=>{
        setSessionListing(false)
        const server_response = await ajaxOperation.fetchBranchSessions(data);
        if(server_response.status === "OK"){
            setSessionListing(server_response.details.content.list)
            setMetaData(server_response.details.content.meta)

        }else{
            setSessionListing("404")
            setMetaData(false)

        }
    }


    const switchSessionBranch=(branchId)=>{
        setBranchId(branchId)
        switchBranch(branchId)
        setData({"page":"1", "branch_id":branchId})
    }



    const refresh=()=>{
       listSessions()
    }



        return (
           <BranchSessionContext.Provider 
            value={{
                sessionListing,
                metaData,
                loading,
                listSessions,
                switchBranch,
                goToNextPage,
                resetData,
                filterData,
                refresh,
                switchSessionBranch
            }}
            >
               {props.children}
           </BranchSessionContext.Provider>
        );
}

export default BranchSessionContext;