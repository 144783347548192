import React, { useContext } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import PairedCard from '../../Components/Cards/UserCards/PairedCard'
import TableHeader from '../../Components/Common/TableHeader'
import PaperTicketContext from '../../Context/PaperTicketContext'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import dictionary from '../../utils/dictionary'
import ManageUserContext from '../../Context/ManageUserContext'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import { Link } from 'react-router-dom'
import BranchContext from '../../Context/BranchContext'
import { RenderSecure } from '../../utils/script/RenderSecure'

const PaperTicketAgentCollection = () => {

    const {
        agentSalesSummary,
        metaData,
        loading,
        filterData,
        resetData,
        getPaperTicketAgentSummary,
        switchTicketBranch
    } = useContext(PaperTicketContext);
    // const { userList } = useContext(ManageUserContext);
    const {branchListing, branchUserListing} = useContext(BranchContext)



    const RenderFilter = () => {

        const userOptions = [];
        Array.isArray(branchUserListing) && branchUserListing.map((item, key) =>
            userOptions.push({
                title: item.user.first_name + " " + item.user.last_name,
                value: item.user.user_id
            })
        );

        return (
            <CustomizedFilterBar
                selection={{ title: 'Sold By', options: userOptions }}
                keyword="Plate Number"
                onReset={resetData}
                onConfirm={filterData}
            />
        )


    }



    const buttons = () => {
        return (
            <>
            <div className="btn btn-list">
                  <RenderSecure code="U-SWITCH-BRANCH-0">
                  <a className="btn ripple btn-primary dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i className="fa fa-exchange-alt mr-1"></i> Switch Branch <i className="fas fa-caret-down ml-1"></i>
                  </a>
                  <div className="dropdown-menu tx-13">
                    {Array.isArray(branchListing) && branchListing.map((item, key)=>
                      <Link to="#" className="dropdown-item" key={key} onClick={()=>switchTicketBranch(item.branch_id)}>
                        <i className="fa fa-university text-secondary mr-1"></i>{item.branch_name} Branch</Link>
                    )}
    
                  </div>
                  </RenderSecure>
                {agentSalesSummary && <>
                    <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                    </a>
                    <button className='btn btn-light' onClick={getPaperTicketAgentSummary}><i className='fa fa-redo mr-2' /> Refresh</button>
                </>
                }
            </div>
            </>
        )
    }





    return (

        <div className="page">
            <LeftSideBar />
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                <TopBar />
                <div className="container-fluid">

                    <PageHeader
                        title="Paper Tickets"
                        previousPageTitle="Dashboard"
                        back="/"
                        buttons={buttons()}
                    />
                    {RenderFilter()}



                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-lg-12">
                            <div className="card custom-card ">
                                <div className="card-body map-card">

                                    <div className="d-flex justify-content-between align-items-center filter-example">

                                        <TableHeader
                                            title="Paper Ticket Agent Summary"
                                            subtitle="List of all paper tickets summary sold by the each each."
                                        />

                                    </div>
                                    {/* <div className="user-manager mt-3 scroll-widget border-top overflow-auto"> */}
                                    <div className="table-responsive allView-config border-top">
                                        {Array.isArray(agentSalesSummary) &&
                                            <table id="table-paper-ticket" className="table table-hover text-nowrap mg-b-0 datatable" export-format={dictionary._exportBtnFormats}>
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Sales Agent Name</th>
                                                        <th>Role</th>
                                                        <th>Branch</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {agentSalesSummary.map((item, key) => <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>{item.sales_agent.first_name + ' ' + item.sales_agent.last_name}</td>
                                                        <td>{item.sales_agent.user_role.role_name}</td>
                                                        <td>{item.sales_agent.branch.branch_name}</td>
                                                        <td>{item.amount.total_c}</td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        }
                                        {!agentSalesSummary && <ActivityLoader />}
                                        {(agentSalesSummary === "404") && <NoContent />}


                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <RightSideBar />

        </div>);

}








export default PaperTicketAgentCollection
