import { useContext } from "react";
import TableHeader from "../Common/TableHeader";
import SupervisorContext from "../../Context/SupervisorContext";
import ActivityLoader from "../Common/ActivityLoader";
import NoContent from "../Common/NoContent";
import LiveTile from "../Cards/LiveTile";

const RecentScan = (props)=>{

const {recentTransactions, efficiency} = useContext(SupervisorContext)

 return   <div className="col-sm-12 col-xl-10 col-lg-12">
    <div className="row">
        <div className="col-sm-12 col-xl-4 col-lg-4">
            <LiveTile
                        title="Inactive Sessions"
                        value={efficiency ? efficiency.daily_inactive.total_p : '...'}
                        description="Non-invoice correction incidents"
                        theme="light"
                        comment="Sessions"
                    />
        </div>
        <div className="col-sm-12 col-xl-4 col-lg-4">
            <LiveTile
                        title="Coverage"
                        value={efficiency ? efficiency.performance +'%' : '...'}
                        description="Percentage of scanned sessions"
                        theme="light"
                        comment={`Efficiency: ${efficiency ? efficiency.efficiency + '%' : '...'}`}
                    />
        </div>
        <div className="col-sm-12 col-xl-4 col-lg-4">
            <LiveTile
                        title="Flagged Locations"
                        value={efficiency ? efficiency.flagged.total.total_p : '...'}
                        description="Locations of non-invoice incidents"
                        theme="light"
                        comment={`Coverage: ${efficiency ? efficiency.performance + '%' : '...'}`}
                    />
        </div>
    </div>
    <div className="row">
     <div className="col-12">
        <div className="card custom-card" >
            <div className="card-body">
                <TableHeader
                    title="Recent Supervisor ACtivity"
                    subtitle="List of the most recent supervisor scans"
                    view="View All"
                    href={"/operations/supervision/transactions"}
                />




              <div className="user-manager mt-3 scroll-widget border-top overflow-auto" style={{maxHeight:'300px'}}>
                    <div className="table-responsive">

                    <table className="table  text-nowrap mb-0 table-hover">
                        <thead>
                            <tr>
                                <th>Scan Date</th>
                                <th>Supervisor</th>
                                <th>Conductor</th>
                                <th>Vehicle</th>
                                <th>Location</th>
                                <th>IN SESSION</th>
                                <th>Comment</th>
                            </tr>
                        </thead>

                        <tbody>

                            { Array.isArray(recentTransactions) && recentTransactions.map((item, key)=>
                                <tr key={key}>
                                    <td>
                                        {item.created_at.date} <br />
                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                    </td>
                                    <td>{item.supervisor.full_name}<br />
                                            <small className="tx-12 tx-gray-500">@{item.supervisor.username}</small>
                                    </td>
                                    <td>{item.conductor.full_name}<br />
                                            <small className="tx-12 tx-gray-500">@{item.conductor.username}</small>
                                    </td>
                                    <td>{item.vehicle.plate_number}</td>
                                    <td>
                                        <span className="pointer">
                                            <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                            {item.location.location_name}<br />
                                            <small className="tx-12 tx-gray-500">{item.location.street.street_name}</small>
                                        </span>
                                    </td>
                                    <td>
                                        {item.found_in_session === "YES" && <span className="badge rounded badge-success">{item.found_in_session}</span>}
                                        {item.found_in_session === "NO" && <span className="badge rounded badge-secondary">{item.found_in_session}</span>}
                                    </td>
                                    <td>{item.comment}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>

                    {recentTransactions === "404" && <NoContent />}
                    {!recentTransactions && <ActivityLoader/>}

                    </div>

                </div>
            </div>
        </div>
 </div>
 </div>
</div>

}

export default RecentScan;