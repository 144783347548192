import React from 'react';
import { Link } from 'react-router-dom';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import CompanyHeader from '../Config/CompanyHeader';


class ReservationInvoice extends React.Component {

    state={
       receipts:this.props.record
    }

   destroy=()=>{
	   functions.closePopup("modal_reservation_invoice")
   }

  render(){
	  const record=this.state.receipts;
      return (
        <div className="modal" id="modal_reservation_invoice">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Invoice</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
               
            
                <div className="row">
						{record && <div className="col-lg-12 col-md-12">
							<div className="card custom-card">
								<div className="card-body">
									<div className="d-lg-flex">
                                        <h2 className="card-title mb-1">
                                            #{record.invoice_ref}<br/>
                                            {"RESERVATION INVOICE"}
                                        </h2>
										<div className="ml-auto">
                                            <p className="mb-1"><span className="font-weight-bold">Invoice Date :</span> {record.created_at.long_date}</p>
										</div>
									</div>
									<hr className="mg-b-40"/>
									<div className="row">
										<div className="col-lg-6">
											<CompanyHeader />
										</div>
										<div className="col-lg-6 text-right">
											<p className="h3">Invoice to:</p>
											<address>
                                                {record.organisation.title}<br/>
                                                {record.organisation.location_address}<br/>
                                                {record.organisation.contact}
                                                
											</address>
										</div>
									</div>
									<div className="table-responsive mg-t-40">
										<table className="table table-invoice table-bordered">
											<thead>
												<tr>
													<th className="wd-25p">Location</th>
													<th className="wd-5p">Slots</th>
                                                    <th className="wd-25p">Unit Cost(Ugx)</th>
                                                    <th className="wd-25p">Total(Ugx)</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{record.location.location_name}</td>
													<td>{record.total_slots}</td>
													<td>{record.unit_cost.total_c}</td>
													<td>{record.amount.total_c}</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td>&nbsp;</td>
													<td>&nbsp;</td>
													<td>&nbsp;</td>
												</tr>

												<tr>
													<td className="valign-middle" colSpan="2" rowSpan="4">
														<div className="invoice-notes">
															<label className="main-content-label tx-13">Location Details.</label><br/>
															{record.location.location_name + ' along ' + record.location.street.street_name}

														</div>
													</td>
													<td className="tx-right">
														<label className="main-content-label tx-13">Total</label>
													</td>
                                                    <td className="tx-left" colSpan="2">
														<label className="main-content-label tx-13">
															{record.amount.total_c}
														</label>
													</td>
												</tr>
												
											
											</tbody>
										</table>
									</div>
								</div>
								
                                
                                <div className="card-footer text-right">
                                   
									{/* <button type="button" className="btn ripple btn-secondary mb-1"><i className="fe fe-send mr-1"></i> Send Invoice</button> */}
									{/* <Link 
										className="btn ripple btn-success mb-1"
										to={
											{
												pathname: `/reservation/pay_invoice/${record.invoice_ref}`,
												state: { invoiceId: record.id }
											}}
											onClick={this.destroy}
										>
                                        <i className="fe fe-credit-card mr-1"></i> Pay Invoice
                                    </Link>&nbsp; */}
									<button type="button" className="btn ripple btn-info mb-1"><i className="fe fe-printer mr-1"></i> Print</button>
								</div>
							</div>
						</div>}

                        {!record && <ActivityLoader/>}
					</div>




            
            </div>
          
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default ReservationInvoice;