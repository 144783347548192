import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast';
import ConfigurationContext, { ConfigurationConsumer } from '../../../Context/ConfigurationContext';
import dictionary from '../../../utils/dictionary'
import NoContent from '../../../Components/Common/NoContent'
import TableHeader from '../../../Components/Common/TableHeader'
import PopCreateClampViolations from '../../../Components/Clamps/PopCreateClampViolations'
import ajaxClamping from '../../../utils/remote/ajaxClamping'
import PopUpdateClampViolations from '../../../Components/Clamps/PopUpdateClampViolations'
// const eformats = dictionary._exportBtnFormats
const eformats = ['excel', 'pdf', 'csv', 'print']




class ConfigClampViolationReasons extends React.Component {

    static contextType = ConfigurationContext;

    state = {
        userId: functions.sessionGuard(),
        clampViolationReasonsList: false,
        clampViolationReasonsId: false,
        clampViolationReasonsRecord: false,
        toast: '',
        modal: false,
    }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    onSave = async (reason, fine, is_clamp_violation) => {
        const { getClampViolationReasons } = this.context;
        //fetch login from the API
        const server_response = await ajaxClamping.createClampViolationReasons(reason, fine, 1);
        functions.closePopup("modal_new_clamp_violation");
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
            })
            getClampViolationReasons();
        } else {
            this.setState({
                toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />
            })
        }
    }

    onClickAdd = () => {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopCreateClampViolations
                    onConfirm={this.onSave}
                />
            }, () => functions.openPopup("modal_new_clamp_violation"))
        })
    }

    onUpdate = async (clampViolationReasonsId, reason, fine) => {
        const { getClampViolationReasons } = this.context;
        //fetch login from the API
        const server_response = await ajaxClamping.updateClampViolationReason(clampViolationReasonsId, reason, fine);
        functions.closePopup("modal_update_clamp_violation");
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
            })
            getClampViolationReasons();
        } else {

            this.setState({
                toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />
            })
        }
    }

    onClickEdit = () => {

        if (this.state.clampViolationReasonsRecord) {

            this.setState({
                modal: false
            }, () => {
                this.setState({
                    modal: <PopUpdateClampViolations
                        record={this.state.clampViolationReasonsRecord}
                        onConfirm={this.onUpdate}
                    />
                }, () => functions.openPopup("modal_update_clamp_violation"))
            })
        } else {
            this.setState({
                toast: <Toast message={"Select record and try again"} type={4} onClose={this.resetToast} />
            })
        }
    }

    // onClickDelete = () => {
    //     if (this.state.branchRecord) {
    //         $("#modal_delete").modal('show')
    //     }
    // }

    // onConfirmDelete = async () => {
    //     const { getBranches } = this.context;
    //     if (this.state.branchId) {
    //         $("#modal_delete").modal('hide');

    //         const server_response = await ajax.deleteBranch(this.state.branchId, this.state.userId);
    //         if (server_response.status === "OK") {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} />
    //             }, () => {

    //                 //hide record
    //                 //Refresh list
    //                 getBranches();
    //             })
    //         } else {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} />
    //             })
    //         }

    //     }

    // }

    onSelectReason = (clampViolationReasonsId, clampViolationReasonsRecord) => {
        this.setState({
            clampViolationReasonsId: clampViolationReasonsId,
            clampViolationReasonsRecord: clampViolationReasonsRecord,
            toast: ''
        })
    }

    RenderClampViolationReasons = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const clampViolationReasonsList = props.clampViolationReasonsList
                    return (
                        <div className="table-responsive allView-config border-top">

                            {(clampViolationReasonsList === "404") && <NoContent />}

                            {clampViolationReasonsList && Array.isArray(clampViolationReasonsList) &&
                                <table id="config-clamp-violation-reasons" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                    <thead>
                                        <tr>
                                            {/* <th>No.</th> */}
                                            <th>Reason</th>
                                            <th>Fine (Shs)</th>
                                            <th>Created At</th>
                                            {/* <th>Geo Location</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clampViolationReasonsList && clampViolationReasonsList !== "404" &&
                                            clampViolationReasonsList.map((item, key) =>
                                                <tr
                                                    id={"cl_" + item.reason_id}
                                                    key={key}
                                                    className={`${this.state.clampViolationReasonsId == item.reason_id ? 'flag-row' : ''}`}
                                                    onClick={() => this.onSelectReason(item.reason_id, item)}
                                                >
                                                    {/* <td>{key + 1}</td> */}
                                                    <td>{item.reason}</td>
                                                    <td>{item.fine.total_c}</td>
                                                    {/* <td>{item.total_locations.total_c}</td>
                                                    <td>{item.lat !== null ? item.lat + ', ' + item.lng : ''}</td> */}
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>

                                </table>
                            }

                            {!clampViolationReasonsList && <ActivityLoader />}
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }



    RenderClampViolationReasonsCount = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const countAllClampViolationReasons = props.totalViolations
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        Total Clamp Violation Reasons <span className="badge badge-light ml-2">{countAllClampViolationReasons}</span>
                                    </button>

                                    <button className="btn ripple btn-light btn-sm" onClick={this.context.getAllUsers}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                </div>
                            </div>
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }



    render() {
        return (
            <div className="page">

                {this.state.modal}

                <WarnDelete
                    title="Delete Branch?"
                    description="Make sure this branch has not streets and locations under it"
                    onConfirm={() => this.onConfirmDelete()}
                />

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Clamp Violation Reasons"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "add": () => this.onClickAdd(),
                                "edit": () => this.onClickEdit(),
                                // "delete": () => this.onClickDelete()
                            }}
                        />

                        {this.state.toast}

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center filter-example">

                                                <TableHeader
                                                    title="List Of Clamp Violation Reasons"
                                                    subtitle="List of the clamping violation reasons"
                                                />

                                            <this.RenderClampViolationReasonsCount />
                                                </div>

                                                <this.RenderClampViolationReasons />
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigClampViolationReasons
