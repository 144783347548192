import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopSessionInfo from '../../../Components/PopSessionInfo'
import Toast from '../../../Components/Common/Toast'
import TableHeader from '../../../Components/Common/TableHeader'
import PageNav from '../../../Components/Common/PageNav'
import { Link } from 'react-router-dom'
import Export from '../../../Components/Common/Export'
import CustomizedFilterBar from '../../../Components/Common/CustomizedFilterBar'
import dictionary from '../../../utils/dictionary'
import SpaceInventoryContext from '../../../Context/Reports/SpaceInventoryContext'
import LoadMoreButton from '../../../Components/Other/LoadMoreButton'
import ajaxClamping from '../../../utils/remote/ajaxClamping'
import AlertError from '../../../Components/Common/AlertError'
import ConfigurationContext from '../../../Context/ConfigurationContext'
import ajaxUnclamping from '../../../utils/remote/ajaxUnclamping'
const eformats = dictionary._exportBtnFormats




class ZulusPerformanceReport extends React.Component {
    static contextType = ConfigurationContext;
    state = {
        currentPage: 1,
        filter: false,
        toast: '',
        userId: "8",
        userUnClampList: "",
        clampStatusId: "",
        createdFrom: "",
        createdTo: "",
        info2: "",


    }

    componentDidMount() {
        this.getZuluClampHistory()

    }


    buttons = () => {
        return (
            <>
                {/* {this.state.filter && <button className="btn ripple btn-primary" onClick={this.resetFilter}>
          Reset
        </button>} */}
                <ol className="customButtonList">

                    <li>

                        <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                        </a>
                    </li>
                    <li>

                        {/* {!this.state.filter && <Export
                            excelUrl={"location/export"}
                            pdfUrl={"space-inventory/export/pdf?"}
                        />} */}


                    </li>
                </ol>


            </>
        )
    }


    onFilter = (filter) => {
        this.setState({
            filter: "",
            userUnClampList: false,
            metaData: false

        }, () =>
            this.setState({
                filter: filter,
                metaData: false
            }, () => this.getZuluClampHistory(filter)))

    }


    resetFilter = (filter) => {
        this.setState({
            filter: false,
            userUnClampList: false
        },
            () => this.getZuluClampHistory(filter))
    }


    getZuluClampHistory = async (filter) => {


        this.setState({
            userUnClampList: false,
            loading: true
        })

        if (this.state.filter) {
            var server_response = await ajaxUnclamping.zuluPerformance(filter.dateFrom, filter.dateTo);

        } else {
            var server_response = await ajaxUnclamping.zuluPerformance("", "");

        }


        // if (this.state.filter) {

        //     // CHECK IF THE USER IS A COBRA OR ZULU
        //     if (this.state.userInfo.user_role.role_id === "3") {

        //         var server_response = await ajaxClamping.userClampHistory(userId, "", filter.selectedValue, filter.dateFrom, filter.dateTo);
        //     } else if (this.state.userInfo.user_role.role_id === "4") {
        //         var server_response = await ajaxClamping.userClampHistory("", userId, filter.selectedValue, filter.dateFrom, filter.dateTo);
        //     }

        // } else {

        //     // CHECK IF THE USER IS A COBRA OR ZULU
        //     if (this.state.userInfo.user_role.role_id === "3") {
        //         var server_response = await ajaxClamping.userClampHistory(userId);
        //     } else if (this.state.userInfo.user_role.role_id === "4") {
        //         var server_response = await ajaxClamping.userClampHistory("", userId);
        //     }

        // }
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            this.setState({
                userUnClampList: server_response.details.content.list
            })
        } else {
            this.setState({
                userUnClampList: "404",
                info2: <AlertError message={server_response.details.message} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }





    filterButton = () => {
        return (

            <a href="#" className="btn ripple btn-secondary navresponsive-toggler mb-3" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
            </a>

        )
    }

    onClickEdit = () => {

        if (this.state.userRecord) {

            this.props.history.push({
                pathname: '/config/users/edit',
                search: '?q=1',
                state: { record: this.state.userRecord }
            })
        }

    }






    //==============NAVIGATION CONTROLS START===========================




    //==============NAVIGATION CONTROLS END===========================





    render() {
        const { userUnClampList, filter } = this.state;
        const clampingStatusOptions = [];

        const { clampingStatusList } = this.context;


        clampingStatusList &&
            clampingStatusList.map((item, key) =>
                clampingStatusOptions.push({ title: item.clamping_status, value: item.clamping_status_id })
            )


        const { loading } = this.state


        return (
            // <div className="page">
            //     <LeftSideBar />
            //     <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            //         <TopBar />
            //         <div className="container-fluid">
            //             <PageHeader
            //                 title="Zulu Performance"
            //                 previousPageTitle="Operations"
            //                 back="/operations"
            //                 modalContainer={<this.buttons />}
            //             />
            //             {this.state.toast}
            <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    {/* <div className="card custom-card">
                        <div className="card-body"> */}
                    <TableHeader
                        title=" Zulu performance report"
                        subtitle="This report tracks a zulus field performance."
                        filterButton={clampingStatusList && clampingStatusList !== "404" && <this.buttons />}

                    />
                    <div className="table-responsive">

                        <div className="tab-pane active">
                            {this.state.clampPreview}
                            <CustomizedFilterBar
                                // selection={{
                                //     title: 'Clamp status',
                                //     options: clampingStatusOptions
                                // }}

                                onReset={this.resetFilter}
                                onConfirm={this.onFilter}
                            />
                            <div className="table-responsive  scroll-widget overflow-auto" style={{ height: "500px" }}>
                                <table id={this.props.tableID} className={`table  border-t0 key-buttons text-nowrap w-100 ${this.props.datatable}`} export-format={eformats}>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            {/* <th>Plate Number</th> */}
                                            <th>Zulu Name</th>
                                            <th>Role</th>
                                            <th>DATE</th>
                                            <th>unclamped vehicles</th>
                                            <th>responded vehicles</th>
                                            {/* <th>vehicles paid</th> */}


                                            <th></th>
                                            {/* <th>Clamped For</th> */}
                                            {/* <th>Clamp Location</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userUnClampList && Array.isArray(userUnClampList) && userUnClampList.map((item, key) =>
                                            <tr key={key}>
                                                {/* <td> <Link
                                                                        to="#"
                                                                        onClick={() => this.onClickRef(item)}>
                                                                        {item.clamp_history_ref} </Link>
                                                                    </td> */}
                                                {/* <td>{item.vehicle.vehicle_number}</td> */}
                                                <td>{key + 1}</td>
                                                {/* <td>{item.clamped_by.first_name} {item.clamped_by.last_name}</td> */}

                                                <td>{item.first_name}&nbsp;&nbsp;<br /><small className="tx-12 tx-gray-500">{item.last_name}</small></td>
                                                <td>Zulu</td>
                                                <td>{item.created_at}</td>
                                                <td>{item.responded_unclamps.total_c}{item.responded_unclamps.total_c == "1" ? "Vehicle" : " vehicles"}</td>
                                                <td>{item.unclamps.total_c}{item.responded_unclamps.total_c == "1" ? "vehicle " : " vehicles"}</td>
                                                {/* <td>{item.paid_vehicles.total_c}</td> */}
                                                {/* <td className="text-center w-10">


                                                                        <Link
                                                                            to={
                                                                                {
                                                                                    pathname: `/reports/userfield-performance/${item.first_name}${item.last_name}`,
                                                                                    pathname: `/reports/${item.first_name}${item.last_name}/field-performance`,

                                                                                    state: {
                                                                                        userID: item.user_id, firstName: item.first_name,
                                                                                        roleCode: item.role_code,
                                                                                        createdFrom: filter.dateFrom, createdTo: filter.dateTo
                                                                                    }
                                                                                }
                                                                            }
                                                                            className="btn ripple btn-primary btn-sm text-white mr-2"
                                                                            data-toggle="tooltip" data-original-title="View"
                                                                            title="View Profile">

                                                                            <i className="fas fa-eye mr-1" />
                                                                        </Link>


                                                                    </td> */}





                                                {/* <td>{item.status.status === 'PENDING' && item.is_parked === "1" &&
                                                                        <span className="badge badge-info badge-pill">{item.status.status}</span>}
                                                                        {item.status.status === 'PENDING' && item.is_parked === "0" &&
                                                                            <span className="badge badge-danger   badge-pill">ABORTED</span>}
                                                                        {item.status.status === 'RESPONDED' &&
                                                                            <span className="badge badge-secondary badge-pill">{item.status.status}</span>}
                                                                        {item.status.status === 'CLAMPED' &&
                                                                            <span className="badge badge-dark badge-pill">{item.status.status}</span>}
                                                                        {item.status.status === 'PENDING_UNCLAMP' &&
                                                                            <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                                                        {item.status.status === 'UNCLAMP_RESPONDED' &&
                                                                            <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}
                                                                        {item.status.status === 'UNCLAMPED' &&
                                                                            <span className="badge badge-success badge-pill">{item.status.status}</span>}
                                                                    </td> */}

                                                {/* <td>{item.clamped_for.item}</td> */}


                                                {/* <td>{item.clamped_location.location_name}</td> */}

                                            </tr>)}
                                    </tbody>
                                </table>

                                {loading && <ActivityLoader />}
                                {this.state.info2}
                            </div>
                        </div >
                    </div>
                </div>
                {/* </div>
                </div> */}
            </div>
            //         </div>
            //     </div>
            //     <RightSideBar />
            //     <Footer />
            // </div>
        );
    }
}

export default ZulusPerformanceReport