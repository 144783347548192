import React, { useContext } from "react"
import PaymentContext from "../../../Context/PaymentContext";
import ScrollFilterBar from "../../Common/Filter/ScrollFilterBar";
import FilterTextInput from "../../Common/Filter/FilterTextInput";
import FilterSelectInput from "../../Common/Filter/FilterSelectInput";

const PaymentFilter=(props)=>{

    const { 
            resetData,
            filterData,
            cashierListing
        } = useContext(PaymentContext)

    const cashiers = [];
    Array.isArray(cashierListing) && cashierListing.map((item, key)=>{
        cashiers.push({"title":item.last_name + " " + item.first_name, "value":item.user_id})
    })


    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Payment Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Payment Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=> <FilterTextInput 
            key={key}
            label="Filter By Vehicle"
            type="text"
            placeholder="Enter plate number"
            onChange={onSet}
            name="plateNumber"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=> <FilterSelectInput 
            key={key}
            label="Filter By Cashier"
            options={cashiers}
            onChange={onSet}
            name="cashier"
            currentValues={currentValues}
          />,

        ],
        names:["dateFrom", "dateTo", "plateNumber", "cashier"]
    }}
/>

}

export default PaymentFilter