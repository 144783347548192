import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async listRecentWrongParking()//GET
    {
        try {
            let response = await fetch(apiHost + 'wrongparking/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countTodaysWrongParking()//GET
    {
        try {
            let response = await fetch(apiHost + 'wrongparking/today.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countReportedByWrongParking()//GET
    {
        try {
            let response = await fetch(apiHost + 'wrongparking/reported_by.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countWrongParkingDescriptions()//GET
    {
        try {
            let response = await fetch(apiHost + 'description/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    // FILTER WONRG PARKING
    async filterWrongParking(vehicle_id, plate_number, description_id, reported_by_id, created_from, created_to, page, custom_items_per_page) {
        let data = {
            "vehicle_id": vehicle_id,
            "plate_number": plate_number,
            "description_id": description_id,
            "reported_by_id": reported_by_id,

            "created_from": created_from,
            "created_to": created_to,
            // "payment_status": payment_status,
            // "amount_due": amount_due,
            "page": page,
            "custom_items_per_page": custom_items_per_page
        }

        console.log(data)
        try {
            let response = await fetch(apiHost + 'filter/wrong_parking_records',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async listReportedByUsers() {

        try {
            let response = await fetch(apiHost + 'users/list/reportedby',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },



}