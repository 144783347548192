import React, { useContext, useEffect, useState } from 'react';
import functions from '../../utils/functions';
import ajaxSession from '../../utils/remote/ajaxSession';
import { detectTable, restoreTable } from '../../utils/script/customTables';
import AuthContext from '../AuthContext';

const ParkingMigrationContext = React.createContext();

export const ParkingMigrationConsumer = ParkingMigrationContext.Consumer;

export const ParkingMigrationProvider=(props)=>{

    const [migrationList, setMigrationList] = useState(false);
    const [message, setMessage] = useState("");
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [userId, setUserId] = useState(functions.sessionGuard())
    const { secureCall } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        //RequirePermission
        if(userId*1>0){
            secureCall(getMigrations, "R-SESSION-IMPORT-01")

        }
    }, [data])


    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])
   
   
    const getMigrations=async()=>{
        setMigrationList(false)
        const server_response = await ajaxSession.fetchMigratedParkings(data);
        if(server_response.status==="OK")
        {  
            setMigrationList(server_response.details.content.list)
            setMetaData(server_response.details.content.meta)
        }else if(server_response.status==="Fail"){
            setMigrationList("404")
            setMessage(server_response.details.message);
            setMetaData("404");
        }
    }





    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxSession.fetchMigratedParkings(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setMigrationList(migrationList.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "status":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        })
        
    }


   

        return (
           <ParkingMigrationContext.Provider value={{
               migrationList,
               message,
               metaData,
               loading,
               goToNextPage,
               setData,
               resetData,
               filterData,
               getMigrations
            }}
            >
               {props.children}
           </ParkingMigrationContext.Provider>
        );
    }


export default ParkingMigrationContext;