import React from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ajaxPayment from '../../utils/remote/ajaxPayment';
import { RenderSecure } from '../../utils/script/RenderSecure';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import PageNav from '../Common/PageNav';
import TableHeader from '../Common/TableHeader';
import Toast from '../Common/Toast';
import PopInvoice from '../PopInvoice';
import WarnDelete from '../WarnDelete';
import PopEditPayment from './PopEditPayment';
import PopPaymentSummary from './PopPaymentSummary';
import POSReceipt from './POSReceipt';
import WrongParkingReceipt from './WrongParkingReceipt';
import PopPaymentReceipt from './PopPaymentReceipt';
const eformat = dictionary._exportBtnFormats


class VehiclePaymentHistory extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    plateNumber: this.props.plateNumber,
    transactionList: false,
    metaData: false,
    currentPage: 1,
    pagination: false,
    paymentView: false,
    receiptView: false,
    invoiceView: false,
    mdlConfig: false,
    selectedId: false,
    selectedRecord: false,
    toast:false
  }


  componentDidMount() {
    this.listTransactions();

  }


  listTransactions = async () => {
    this.setState({ pagination: false })
    const server_response = await ajax.listVehiclePaymentHistory(this.state.plateNumber, this.state.currentPage);
    if (server_response.status === "OK") {
      this.setState({
        transactionList: server_response.details.content.list,
        metaData: server_response.details.content.meta
      }, () => this.paginate())
    } else {
      this.setState({
        transactionList: "404",
        metaData: false
      })
    }
  }




  //==============NAVIGATION CONTROLS START===========================
  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
      this.setState({
        currentPage: this.state.page + 1,
        transactionList: false

      }, () => {
        this.listTransactions();
      })
    }

  }

  onClickPage = (page) => {
    this.setState({
      currentPage: page,
      transactionList: false
    }, () => this.listTransactions())
  }

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState({
        currentPage: this.state.page - 1,
        transactionList: false

      }, () => {
        this.listTransactions();
      })
    }

  }

  paginate = () => {
    if (this.state.metaData) {
      this.setState({
        pagination: false
      }, () => {

        this.setState({
          pagination: <PageNav
            totalPages={this.state.metaData.total_pages}
            pageList={this.state.metaData.list_of_pages}
            currentPage={this.state.currentPage}
            onClickNext={this.onClickNext}
            onClickPage={this.onClickPage}
            onClickPrevious={this.onClickPrevious}
          />
        })

      })

    }
  }





  //==============NAVIGATION CONTROLS END===========================





  onClickPayment = (deposit_ref) => {
    this.setState({
        paymentView: false
    }, () => {

        this.setState({
            paymentView: <PopPaymentSummary deposit_ref={deposit_ref} />
        }, () => {
            functions.openPopup("modal_payment_summary");
        })
    })

}



onClickInvoice = (record) => {
    this.setState({
        invoiceView: false
    }, () => {

        this.setState({
            invoiceView: <PopInvoice
                invoice={record}
            //onClickPay={this.props.onClickPay}
            />
        }, () => {
            functions.openPopup("modal_invoice");
        })
    })

}


onClickReceipt = (record) => {
    this.setState({
        receipt: false
    }, () => {

      this.setState({
        receiptView: <PopPaymentReceipt
            paymentRecord={record}
        />
    }, () => {
        functions.openPopup("modal_invoice");
    })

        // const invoiceType = record.invoice.invoice_type.type_id;
        // switch (invoiceType) {

        //     case "1"://session
        //         this.setState({
        //             receiptView: <POSReceipt
        //                 receipt={record}
        //             />
        //         }, () => {
        //             functions.openPopup("pos_receipt");
        //         })
        //         break;
        //     case "3"://Wrong parking
        //         this.setState({
        //             receiptView: <WrongParkingReceipt
        //                 receipt={record}
        //             />
        //         }, () => {
        //             functions.openPopup("pos_receipt");
        //         })
        //         break;
        //     default:
        //         alert("Not available");
        //         break;

        // }

    })

}


onClickCorrectPayment = ()=>{
  this.setState({
    mdlConfig:false
  }, ()=>{
    this.setState({
      mdlConfig: <PopEditPayment paymentId={this.state.selectedId} refresh={this.listTransactions}/>
    }, ()=>{functions.openPopup("modal_edit_payment")})
  })
}



onClickDeletePayment = ()=>{
  this.setState({
    mdlConfig:false
  }, ()=>{
    this.setState({
      mdlConfig: <WarnDelete
      title="Delete Payment?"
      description={"You are about to delete a payment deposit record -" + this.state.selectedRecord.deposit.ref + ". All invoices currently cleared by this deposit will be restored."}
      onConfirm={() => this.onConfirmDelete()} />
    }, ()=>{functions.openPopup("modal_delete")})
  })
}


onSelectRow = (id, record)=>{
  this.setState({
    selectedId:id,
    selectedRecord:record
  })
}







onConfirmDelete=async()=>{
  const server_response = await ajaxPayment.deletePaymentDeposit(this.state.selectedRecord.deposit.ref);
  functions.closePopup("modal_delete")
  if(server_response.status==="OK")
  {
    this.listTransactions();
    this.setState({
      toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
    })
  }else{
    this.setState({
      toast: <Toast message={server_response.details.message} type={4} onClose={this.refreshToast} />
    })
  }
}


refreshToast = () => {
this.setState({
    toast: false
})
}







  render() {
    const paymentList = this.state.transactionList;
    return (
      <>
        {this.state.toast}
      <div className="row">
        <div className="col-sm-12 col-xl-12 col-lg-12">
          <div className="card custom-card">
          <div className="card-body map-card">
                      {this.state.mdlConfig}
                      <TableHeader
                          title="Vehicle Payments"
                          subtitle="List of all vehicle payments. You can also view the vehicle statement."
                          rightComponent={<>
                          
                          <RenderSecure code="U-PAYMENT-CORRECT-01">
                            {this.state.selectedId && <button
                            className="btn btn-sm ripple btn-light"
                            onClick={this.onClickCorrectPayment}
                            style={{float:'right'}}
                          >
                            <i className="fa fa-cog mr-1"></i> Correct Payment
                        </button>}
                        </RenderSecure>&nbsp;


                        <RenderSecure code="D-PAYMENT-01">
                            {this.state.selectedId && <button
                            className="btn btn-sm ripple btn-danger"
                            onClick={this.onClickDeletePayment}
                            style={{float:'right'}}
                          >
                            <i className="fa fa-trash-alt mr-1"></i> Delete Deposit
                        </button>}
                        </RenderSecure>



                        </>
                          }
                      />

                        {this.state.invoiceView}
                        {this.state.receiptView}
                        {this.state.paymentView}


                      <div className="table-responsive allView-table border-top">


                          {Array.isArray(paymentList) && <table className="table table-hover text-nowrap mb-0 datatable" id="VpaymentID" export-format={eformat}>
                              <thead>
                                <tr>
                                    <th>Payment Date</th>
                                    <th>Receipt ID</th>
                                    <th>Invoice ID</th>
                                    <th>Vehicle</th>
                                    <th>Payment Mode</th>
                                    <th>Customer Name</th>
                                    <th>Customer Contact</th>
                                    <th>Amount(UGX)</th>
                                    <th>Deposit Ref</th>
                                    <th>Cashier</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentList.map((item, key) =>
                                    <tr 
                                      key={key} 
                                      onClick={()=>this.onSelectRow(item.receipt.payment_id, item)}
                                      className={`${this.state.selectedId===item.receipt.payment_id?"flag-row":""}`}
                                      >
                                        <td>{item.receipt.payment_date.short_date + ' ' + item.receipt.payment_date.time}</td>
                                        <td>
                                            <Link to="#" onClick={() => this.onClickReceipt(item)}>
                                                {item.receipt.payment_id_c}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to="#" onClick={() => this.onClickInvoice(item.receipt.invoice)}>{item.receipt.invoice.invoice_id_c}</Link>
                                        </td>
                                        <td>{item.receipt.invoice.vehicle.plate_number}</td>
                                        <td>{item.receipt.payment_mode.mode_name}</td>
                                        <td>{item.receipt.depositor_name}</td>
                                        <td>{item.receipt.depositor_contact}</td>
                                        <td className="v-paid-amount">{item.receipt.amount.amount_c}</td>
                                        <td>
                                            <Link to="#" onClick={() => this.onClickPayment(item.deposit_ref)}>
                                                {item.deposit.ref}
                                            </Link>
                                        </td>
                                        <td>{item.receipt.cashier.user_id? item.receipt.cashier.last_name + ' ' + item.receipt.cashier.first_name : ""}</td>
                                    </tr>)}

                                      <tr>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th><b>TOTAL</b></th>
                                      <th><b>{functions.sumTableField('v-paid-amount')}</b></th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                  </tr>

                              </tbody>
                            </table>}

                            {this.state.pagination}

                            {paymentList==="404" && <NoContent />}

                            {!paymentList && <ActivityLoader />}
                          </div>
            </div>
          </div>


        </div>
      </div>
    </>
    )
  }

}

export default VehiclePaymentHistory
