import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import FltRecentNotifications from '../../Components/Filters/FltNotifications/FltRecentNotifications';
import FltUnreadNotifications from '../../Components/Filters/FltNotifications/FltUnreadNotifications';
import FltAllNotifications from '../../Components/Filters/FltNotifications/FltAllNotifications';
import ListRecentNotifications from '../../Components/Notify/ListRecentNotification';
import ListUnReadNotifications from '../../Components/Notify/ListUnReadNotifications';
import ListAllNotifications from '../../Components/Notify/ListAllNotifications';
import NotificationContext, { NotificationConsumer } from '../../Context/NotificationContext';


class Notifications extends React.Component {
    static contextType = NotificationContext;

    state = {
        userId: functions.sessionGuard(),
        // notificationsList: "",
        readNotificationList: "",
        // countNotificationList: "",
        currentOption: "UNREAD",
        recentList: false

    };

    componentDidMount() {
        const { countTodaysNotifications } = this.context;
        countTodaysNotifications();
    }



    toggleDropdown = (idSelector) => {

        //functions.removeClassFromElements('header-dropdown', 'show') 
        setTimeout(() => {
            document.getElementById(idSelector).classList.toggle('show');

        }, 100);
    }

    getUserNotifications = async () => {
        const server_response = await ajax.getUserNotifications(this.state.userId);
        if (server_response.status === 'OK') {
            this.setState({
                notificationsList: server_response.details.content
            })
        }
        else {
            this.setState({
                notificationsList: "404"
            })
        }

    }


    readNotification = async (notification_id) => {
        const { getUserNotifications, countTodaysNotifications } = this.context;
        const server_response = await ajax.readNotification(notification_id);
        if (server_response.status === 'OK') {

            this.setState({
                notificationsList: false
            }, () => {
                getUserNotifications()
                countTodaysNotifications()
            })
        }
        else {
            this.setState({
                readNotificationList: "404"
            })
        }
    }



    onClickRecord = (selectedNotificationId, notificationInfo, app_name) => {
        this.setState({
            selectedNotificationId: selectedNotificationId,
            notificationInfo: notificationInfo
        }, async () => {
            await this.readNotification(selectedNotificationId)
            if (app_name === 'CLAMPING') {
                window.location.href = "/enforcement/clamp_transactions"
            } else if (app_name === "PAYMENT_DUE") {
                window.location.href = "/contraventions/payment_due"
            } else if (app_name === "WRONG_PARKING") {
                window.location.href = "/contraventions/wrong_parking"
            } else if (app_name === "TOWING") {
                window.location.href = "/enforcement/tow_transactions"
            } else if (app_name === "CONFIGS") {
                window.location.href = "/config"
            } else if (app_name === "USERS") {
                window.location.href = "/users"
            } else if (app_name === "PAYMENT") {
                window.location.href = "/finance/new-payment"
            } else if (app_name === "STICKERS") {
                window.location.href = "/finance/new-sticker"
            } else if (app_name === "SURCHARGE") {
                window.location.href = "/config/surcharge"
            } else if (app_name === "INVOICES") {
                window.location.href = "/invoices"
            } else if (app_name === "PERMISSION_REQUESTS") {
                window.location.href = "/config/permissions/requests"
            } else if (app_name === "RECEIPTS") {
                window.location.href = ""
            } else if (app_name === "SESSION") {
                window.location.href = "/sessions"
            } else if (app_name === "WHITE LISTING") {
                window.location.href = "/whitelisting"
            }
        })
    }



    onClickOption = (option) => {
        this.setState({
            currentOption: option
        },
            () => this.setState({
                currentOption: false
            },
                () => this.setState({
                    currentOption: option
                }),
            ))
    }

    render() {



        return (
            <NotificationConsumer>
                {props => {
                    const { countTodaysNotificationList } = props;
                    return (
                        <div className="dropdown main-header-notification header-dropdown" id="notification_menu">
                            <a className="nav-link icon" href="#" onClick={() => this.toggleDropdown('notification_menu')}>
                                {countTodaysNotificationList !== "404" && countTodaysNotificationList >= 1 &&
                                    <div className="notify-icon-user unread-icon bg-info d-flex justify-content-center align-items-center">
                                        <small className="tx-11 tx-bold">{this.props.countTodaysNotificationList ? this.props.countTodaysNotificationList : countTodaysNotificationList}</small>
                                    </div>}

                                <i className="fe fe-bell"></i>
                            </a>
                            <div className="dropdown-menu">
                                <div className="header-navheading ">
                                    <p className="main-notification-text ">
                                        <FltUnreadNotifications onClick={() => this.onClickOption("UNREAD")} />
                                        <FltRecentNotifications onClick={() => this.onClickOption("VEIW_RECENT")} />
                                        <FltAllNotifications onClick={() => this.onClickOption("VEIW_ALL")} />
                                    </p>
                                </div>
                                <div className="main-notification-list overflow-auto">
                                    {this.state.currentOption === "VEIW_RECENT" &&
                                        <ListRecentNotifications
                                        />}
                                    {this.state.currentOption === "UNREAD" &&
                                        <ListUnReadNotifications
                                            status={0}
                                        />}
                                    {this.state.currentOption === "VEIW_ALL" &&
                                        <ListAllNotifications
                                        />}
                                </div>

                            </div>
                        </div>



                    )
                }}
            </NotificationConsumer>
        );
    }
}

export default Notifications;
