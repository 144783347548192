import React, { createContext, useState, useEffect } from 'react';
import ajax from '../../utils/ajax';


const XReportContext = createContext({});



export const XReportProvider =(props)=>{


 
    const [allTransactions, setAllTransactions] = useState(false);

    const [data, setData] = useState(false)
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [metaData, setMetaData] = useState(false);
    const [title, setTitle] = useState('')
  
    useEffect(()=>{
      if(currentPage>1)
      {
          fetchMoreTrigger()
      }
  }, [currentPage])
  
  
  useEffect(()=>{
    if(data){
        getAllTransactions();
    }
  }, [data])
  
  
    const getAllTransactions=async()=>{
      setLoading(true)
      setAllTransactions(false)
      const server_response = await ajax.filterSessions(data);
      setLoading(false)
      if(server_response.status==="OK"){
        setAllTransactions(server_response.details.content.list)
        setMetaData(server_response.details.content.meta);
        setTitle(server_response.message);
      }else{
  
        setAllTransactions("404");
        setMetaData("404");
      }
    }
  
  
    const fetchMoreTrigger=()=>{
      const newData={
          ...data,
          "page":currentPage,
      }
      fetchMore(newData)
  }
  
  
  const fetchMore = async(newData) => {
      setLoading(true);
      // restoreTable(detectTable());
  
      const server_response = await ajax.filterSessions(newData);
  
      setLoading(false);
         
      if (server_response.status === "OK") {
          const incoming = Array.from(server_response.details.content.list);
          setAllTransactions(allTransactions.concat(incoming));
          setMetaData(server_response.details.content.meta);
      }
     
  }
  
  
  const goToNextPage=()=>{
      if(currentPage < metaData.total_pages){
          setCurrentPage(currentPage + 1);
      }
  }
  
  
  const resetData=()=>{
      setCurrentPage(1)
      setData({"page":"1", "status":"0"})
      return data
  }
  
  const filterData=(options)=>{
      setCurrentPage(1)
      setData({
          "date_from":options.dateFrom,
          "date_to":options.dateTo,
          "page":"1",
          "conductor_id" : options.conductor,
          "location_id":options.location,
  
      })
      
  }






        return (
            <XReportContext.Provider value={{
                allTransactions,
                  metaData,
                  loading,
                  title,
                  getAllTransactions,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData

            }}>
                {props.children}
            </XReportContext.Provider>
        );
    

}



export default XReportContext;