import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
// import $ from 'jquery';
// import PopInvoice from '../../Components/PopInvoice'
// import { Link } from 'react-router-dom'
import TableHeader from '../../Components/Common/TableHeader'
import PageNav from '../../Components/Common/PageNav'

class GeneralVehicleStatement extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        currentPage:1,
        pagination:false
    }
    componentDidMount()
    {
        
        this.listTransactions();
    }

  

    listTransactions=async ()=>{
        const server_response = await ajax.getGeneralVehicleStatement(this.state.currentPage);
        if(server_response.status==="OK")
        {
            this.setState({
                transactionList:server_response.details.content.list,
                metaData:server_response.details.content.meta
            }, ()=>this.paginate())
        }else{
            this.setState({
                transactionList:"404",
                metaData:false
            })
        }
    }


    //PAGINATION FUNCTIONS

    onClickPage=(page)=>{
        this.setState({
            currentPage:page,
            List:false
        }, ()=>this.listPayments())
    }

    onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            currentPage:this.state.currentPage+1,
            List:false

          }, ()=>{
            this.listPayments();                                                
          })
        }

      }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            currentPage:this.state.currentPage-1,
            List:false
          }, ()=>{
            this.listPayments();
          })
        }

      }


  

      //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        currentPage:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        currentPage:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        currentPage:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.currentPage}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }



  

  //==============NAVIGATION CONTROLS END===========================










    
    render(){
        const transactionList=this.state.transactionList;

          return (
            <div className="page">
                {this.state.invoiceView}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Invoices & Payments"
                    previousPageTitle="Finance"
                    back="/finance"
                    options={this.state.menuOptions}
                    />
                  

                    
        <div className="row">
            <div className="col-sm-12 col-xl-12 col-lg-12">
                <div className="card custom-card">
                    <div className="card-body">

                    <TableHeader
                        title="Invoice-Payment Statement"
                        subtitle="Reducing balance of invoices and payments for all vehicles"
                    />


                        <div className="text-wrap">
                            <div className="example">
                                <div className="btn-list">
                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        All Invoices <span className="badge badge-light ml-2">...</span>
                                    </button>
                                    <button type="button" className="btn ripple btn-secondary btn-sm">
                                        Session <span className="badge badge-dark ml-2">...</span>
                                    </button>
                                    <button type="button" className="btn ripple btn-success btn-sm">
                                        Wrong Parking <span className="badge badge-danger ml-2">...</span>
                                    </button>
                                    <button type="button" className="btn ripple btn-info btn-sm">
                                        Towing
                                        <span className="badge badge-danger ml-2">...</span>
                                    </button>
                                    <button type="button" className="btn ripple btn-dark btn-sm">
                                        Payment Due
                                        <span className="badge badge-danger ml-2">...</span>
                                    </button>
                        
                                </div>
                            </div>
                            </div>


                            <div className="table-responsive">
                            <table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Invoice(Dr)</th>
                                        <th>Payment(Cr)</th>
                                        <th>Balance(UGX)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {transactionList && transactionList!=='404' &&
                                 transactionList.map((item, key)=>
                                  <tr key={key}>
                                      <td>{item.trans_date.long_date}</td>
                                      <td>{item.description}</td>
                                      <td>{item.debit.amount*1>0?item.debit.amount_c:''}</td>
                                      <td>{item.credit.amount*1>0?item.credit.amount_c:''}</td>
                                      <td><b>{item.balance.amount_c}</b></td>
                                  </tr>
                                )}
                                </tbody>
                            </table>

                            {this.state.pagination}




                            {!transactionList && <ActivityLoader/>}

                        </div>
                      
                   
                    </div>
                </div>
            </div>
        </div>
       





                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default GeneralVehicleStatement