import React from 'react';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import $ from 'jquery';
import ActivityLoader from '../Common/ActivityLoader';
import { Link } from 'react-router-dom';
import PopWhiteListPayment from '../Payments/PopWhiteListPayment';
import Toast from '../Common/Toast';
import PopChequePayment from '../Payments/PopChequePayment';
import PopRTJSPayment from '../Payments/PopRTJSPayment';
import PopCashPayment from './PopCashPayment';


class ReservationPaymentOptionsTab extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        balance: "...",
        invoiceId: this.props.invoiceId,
        info: '',
        officePayment: false,
        receipt: false,
        vehicleInfo:false,
        whiteList:false,
        paymentModes:false,
        toast: false,
        intBalance:0
    }

    componentDidMount() {
        this.getBalance();
        // this.timerBalance = setTimeout(this.funcBalance = async () => {
        //     await this.getBalance();
        //     this.timerBalance = setTimeout(this.funcBalance, 1000); // (*)
        // }, 1000);

        // this.getVehicle();
        this.getPaymentModes();
    }

    componentWillUnmount() {
       // clearTimeout(this.timerBalance)
    }

    getBalance = async () => {
        const server_response = await ajax.getReservationCompanyBalance(this.props.companyId)
        if (server_response.status === "OK") {
            this.setState({
                balance: "UGX. " + server_response.details.content.amount_c,
                intBalance:server_response.details.content.amount*1
            })
        }
    }


    getPaymentModes = async () => {
        const server_response = await ajax.getOfficePaymentModes()
        if (server_response.status === "OK") {
            this.setState({
                paymentModes: server_response.details.content
            })
        }
    }


    onClickCashPayment = () => {

            this.setState({
                officePayment: false
            }, () => {
    
                this.setState({
                    officePayment: <PopCashPayment
                        onConfirm={this.setResponse}
                        balance={this.state.balance}
                        invoiceId={this.state.invoiceId}
                    />
                }, () => {
                    $("#modal_office_payment").modal('show');
                })
    
            })

       

    }

    setResponse = (response) => {
        this.setState({
            info: response,
        }, ()=>{
            this.getBalance();
        })
    }



    


    onClickPayWhiteList=()=>{
        if(this.state.vehicleInfo.white_list)
        {
            const whiteList=this.state.vehicleInfo.white_list;
            if(whiteList.status.status_id*1==1)
            {
                this.setState({
                    officePayment: false,
                    whiteList:whiteList
                }, () => {
        
                    this.setState({
                        officePayment: <PopWhiteListPayment
                            onConfirm={this.setResponse}
                            balance={this.state.balance}
                            plateNumber={this.state.plateNumber}
                            whiteList={whiteList}
                        />
                    }, () => {
                        $("#modal_white_list_office_payment").modal('show');
                    })
        
                })
            }
        }

    }



    onClickChequePayment = () => {

        // if(!this.state.whiteList){


            // this.setState({
            //     officePayment: false
            // }, () => {
    
            //     this.setState({
            //             officePayment: <PopChequePayment
            //             onConfirm={this.setResponse}
            //             balance={this.state.balance}
            //             plateNumber={this.state.plateNumber}
            //             vehicleInfo={this.state.vehicleInfo}
            //         />
            //     }, () => {
            //         $("#modal_cheque_payment").modal('show');
            //     })
    
            // })

        // }else{
        //     this.onClickPayWhiteList()
        // }
     
       

    }



    onClickRTJSPayment = () => {

        // if(!this.state.whiteList){


            // this.setState({
            //     officePayment: false
            // }, () => {
    
            //     this.setState({
            //             officePayment: <PopRTJSPayment
            //             onConfirm={this.setResponse}
            //             balance={this.state.balance}
            //             plateNumber={this.state.plateNumber}
            //             vehicleInfo={this.state.vehicleInfo}
            //         />
            //     }, () => {
            //         $("#modal_rtjs_payment").modal('show');
            //     })
    
            // })

        // }else{
        //     this.onClickPayWhiteList()
        // }
     
       

    }




    onClickPay=(modeCode)=>{
        if(this.state.intBalance<=0)
        {
            
            this.setState({
                toast: <Toast message={"Vehicle has no outstanding balance"} type={4} onClose={this.refreshToast} />
            })

            return false;
        }
        switch(modeCode)
        {
            case "CASH":

                this.onClickCashPayment()

            break;
            case "CHEQUE":
                this.onClickChequePayment()
            break;
            case "RTJS":
                this.onClickRTJSPayment();
            break;
            default:

                this.setState({
                    toast: <Toast message={"MODECODE: "+ modeCode + " - Payment Mode not supported. Please contact support for assistance"} type={1} onClose={this.refreshToast} />
                })

            break;

        }//end switch
    }


    refreshToast = () => {
        this.setState({
            toast: false
        })
    }


    render() {

        const paymentModes=this.state.paymentModes

        return (
            <>
                 {this.state.toast}
                <div className="row">
                    <div className="col-12">
                        <div className="card custom-card">
                            <div className="">
                                <div className="media">
                                    <div className="media-body overflow-hidden" id="mailContent">
                                        <div className="main-mail-header mg-b-0 pd-20 pd-lg-x-25">
                                            <div>
                                                <h4 className="main-content-title mg-b-5">{this.props.title}</h4>
                                                <p>Total Balance: {this.state.balance}</p>
                                            </div>
                                            <div>

                                              {!this.state.paymentModes && 
                                                 <Link className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                 <ActivityLoader 
                                                    height={20} 
                                                    width={30} 
                                                    customClass="custom"
                                                    />
                                             </Link>
                                              }
                                                {this.state.paymentModes && <div className="btn btn-list">
                                                    <Link className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                        <i className="fe fe-settings"></i> Payment Options <i className="fas fa-caret-down ml-1"></i>
                                                        
                                                    </Link>
                                                    <div className="dropdown-menu tx-13">
                                                       
                                                    {paymentModes && paymentModes.map((item, key)=>
                                                     <Link
                                                        key={key}
                                                        className="dropdown-item"
                                                        onClick={()=>this.onClickPay(item.code)}>
                                                        <i className="fas fa-chevron-circle-right mr-2">
                                                        </i>{item.mode_name}
                                                     </Link>
                                                    )}
                                                       
                                                      
                                                    </div>
                                                </div>}

                                                <div className="btn-group border" role="group">
                                                    <button
                                                        className="btn btn-light border-left"
                                                        type="button" data-placement="top"
                                                        data-toggle="tooltip" title="Next Payment"
                                                        onClick={this.props.onExit}>
                                                        <i className="fe fe-chevron-right"></i>
                                                    </button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.info}
                    </div>


                    {this.state.officePayment}

                </div>
            </>

        )


    }
}

export default ReservationPaymentOptionsTab;
