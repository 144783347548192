import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import dictionary from '../../utils/dictionary';
import ActivityLoader from './ActivityLoader';


class PINLocation extends React.Component {

  state = {
    coords: this.props.coords,
    defaultCenter: { lat: 0.303916, lng: 32.575631 },
    center: this.props.center
  }

  MapView = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={20}
      defaultCenter={
        this.state.center && {
          lat: this.state.center.latitude * 1,
          lng: this.state.center.longitude * 1
        } || this.state.defaultCenter
      }
      options={{
        mapTypeControl: false,
      }}
      defaultOptions={{
        // ...DEFAULT_MAP_OPTIONS,
        // styles: mapStyles,
        suppressMarkers: false,
        preserveViewport: true
      }}
    >
      {this.state.coords &&

        this.state.coords.map((item, key) =>
          <Marker
            key={key}
            options={{ icon: dictionary.apiAssets + 'multi.svg' }}
            position={{ lat: item.latitude * 1, lng: item.longitude * 1 }}
            title={item.description}
          >
            {/* <InfoWindow>
                <span style={{fontSize:16, fontWeight:'bold'}} className={`${rides[item.ride_type*1-1]} main-color`}> {item.driver_info.reg_number}</span>
              </InfoWindow> */}
          </Marker>
        )
      }


    </GoogleMap>))


  render() {

    return (

      <this.MapView
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${dictionary._apiKey}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<ActivityLoader />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />

    );


  }
}

export default PINLocation;
