const ModalBox = (props) => {

    return(
        <div className="modal" id={props.id}>
        <div className="modal-dialog modal-dialog-centered" role="document">


            <div className="modal-content modal-content-demo shadow">
                <div className="modal-header">
                    <h6 className="modal-title">{props.title}</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                <div className="modal-footer">
                    {props.cancel && <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Cancel</button>}
                    {props.footer}
                </div>
            </div>
        </div>
        </div>
    )
}

export default ModalBox;