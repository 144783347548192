import React from 'react';
import ajax from '../../../utils/ajax';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import TextInput from '../../Common/TextInput';

class PopImportList extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        title: '',
        fileName:'',
        actualCsvFile:'',
        info: '',
        loading: false
    }

    onChangeTitle = (event) => {
        this.setState({ title: event.target.value })
    }

    onChangeFile = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
             this.setState({
                actualCsvFile:{file:  e.target.result}
             })
         }
         
       }


    onConfirm = async (event) => {
        event.preventDefault();
        const {fileName, actualCsvFile}=this.state;
        if (fileName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response=await ajax.importVehicleListing(this.props.list.list_id, actualCsvFile);
            this.setState({
                loading: false
            })
            if(server_response.status=="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />,
                    fileName:'',
                    actualCsvFile:''
                }, ()=>this.props.refresh())
            }else{
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Select CSV file and try again"} />
            })
        }
    }




   
    render() {

        return (
            <div className="modal" id="modal_import_vehicle_listing">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Import Vehicle Listing</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              

                                <TextInput
                                    label="Selected List"
                                    type="text"
                                    onChange={() => this.onChangeTitle}
                                    value={this.props.list.title}
                                    readOnly={true}
                                />

                                 <TextInput
                                    label="Select CSV file"
                                    type="file"
                                    onChange={() => this.onChangeFile}
                                    value={this.state.description}
                                />



                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Import</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopImportList;
