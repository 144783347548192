import React, { Component } from 'react';
import ajax from '../utils/ajax';
import ajaxConfig from '../utils/remote/ajaxConfig';
import functions from '../utils/functions';
import dictionary from '../utils/dictionary';
import ajaxClamping from '../utils/remote/ajaxClamping';
import ajaxTowing from '../utils/remote/ajaxTowing';
import ajaxClampReasons from '../utils/remote/ajaxClampReasons';
import ajaxPlex from '../utils/remote/ajaxPlex';
import ajaxWrongParking from '../utils/remote/ajaxWrongParking';



const ConfigurationContext = React.createContext();

export const ConfigurationConsumer = ConfigurationContext.Consumer;

export class ConfigurationProvider extends Component {

  state = {
    userId: functions.sessionGuard(),
    totalBranches: '...',
    totalStreets: '...',
    totalLocations: '...',
    totalDevices: '...',
    totalClampTools: '...',
    clampThreshold: '...',
    totalHolidays: '...',
    surcharge: '...',
    clampingFee: '...',
    towingFee: '...',
    totalRoles: '...',
    totalPermissions: '...',
    totalNotifications: '...',
    totalPaymentModes: '...',
    totalStickerTypes: '...',
    totalVehicleTypes: '...',
    totalTowStations: '...',
    vehiclestatistics: '...',
    totalVehicleTypes: '...',
    totalBanks: '...',
    branchList: false,
    streetList: false,
    locationList: false,
    deviceList: false,
    permissionList: false,
    loadTable: true,
    tableId: "table",
    bankList: false,
    clampToolList: false,
    // clampToolStatistics: "",
    clampToolsByStatusList: "",
    // clampAbortReasonList: "",
    clampedForList: "",
    zuluList: "",
    cobraList: "",
    cobraRoleId: 3,
    zuluRoleId: 4,
    conductorRoleId: 2,
    operationRoleId: 6,
    conductorList: "",
    // unUsedClampToolList: "",
    // wrongParkingDescriptionList: "",
    operationsList: "",
    // clampingStatusList: "",
    // clampViolationReasonsList: false,
    // clampAbortReasonsList: false,
    statistics: "",

    // totalViolations: "...",
    // totalAbortReasons: "...",
    totalPlexPictures: "...",
    plexPicturesList: false,
    totalPictures: "...",
    // countAllWrongParkingDescriptions: "...",
    // towingStationList: "",
    // towingStatusList: ""
  }

  componentDidMount() {

    this.getTotalBranches();
    this.getTotalStreets();
    this.getTotalLocations();
    this.getTotalDevices();
    this.getTotalClampTools();
    this.getActiveClampThreshold();
    this.getTotalHolidays();
    this.getActiveSurcharge();
    this.getActiveClampingFees();
    this.getTotalRoles();
    this.getTotalPermissions();
    this.getTotalUnreadNotifications();
    this.getTotalPaymentModes();
    this.getTotalStickerTypes();
    this.getTotalVehicleTypes();
    // this.getTotalTowingStations();
    this.getVehicleStatistics();
    this.getBankingClientCount();
    this.getBranches();
    this.getStreets();
    this.getLocations();
    this.getDevices();
    this.getPermissions();
    this.getBanks();
    this.getClampTools();
    // this.ClampStatistics();
    // this.getClampAbortReasons();
    //this.listClampReasons();
    this.getCobras();
    this.getZulus();
    this.getConductors();
    // this.getUnUsedClampTools();
    // this.getWrongParkingDescriptions();
    this.getOperationsUsers();
    // this.getTowStations();
    // this.getClampStatuses();
    // this.getTowStatuses();
    // this.getClampViolationReasons();
    // this.getCobraClampAbortReasons();
    // this.getActiveTowingFees();

    // this.getTotalViolations();
    // this.getTotalAbortReasons();
    this.getPlexPictures();
    this.getTotalPictures();
    // this.getTotalWrongParkingDescriptions();
  }


  getTotalBranches = async () => {
    const server_response = await ajax.countBranches(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalBranches: server_response.details.content.total_c
      })
    }
  }


  // getTotalViolations = async () => {
  //   const server_response = await ajaxClampReasons.countViolationReasons()
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       totalViolations: server_response.details.content.total_c
  //     })
  //   }
  // }

  // getTotalAbortReasons = async () => {
  //   const server_response = await ajaxClampReasons.countAbortReasons()
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       totalAbortReasons: server_response.details.content.total_c
  //     })
  //   }
  // }


  getTotalStreets = async () => {
    const server_response = await ajax.countStreets(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalStreets: server_response.details.content.total_c
      })
    }
  }


  getTotalLocations = async () => {
    const server_response = await ajax.countLocations(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalLocations: server_response.details.content.total_c
      })
    }
  }


  getTotalDevices = async () => {
    const server_response = await ajax.countDevices(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalDevices: server_response.details.content.total_c
      })
    }
  }



  getTotalClampTools = async () => {
    const server_response = await ajax.countClampTools()
    if (server_response.status === "OK") {
      this.setState({
        totalClampTools: server_response.details.content.total_c
      })
    }
  }

  getActiveClampThreshold = async () => {
    const server_response = await ajax.getActiveClampThreshold()
    if (server_response.status === "OK") {
      this.setState({
        clampThreshold: server_response.details.content.amount_c
      })
    }
  }


  getTotalHolidays = async () => {
    const server_response = await ajax.getTotalHolidays()
    if (server_response.status === "OK") {
      this.setState({
        totalHolidays: server_response.details.content.total_c
      })
    }
  }


  getActiveSurcharge = async () => {
    const server_response = await ajax.getActiveSurcharge()
    if (server_response.status === "OK") {
      this.setState({
        surcharge: server_response.details.content.amount_c
      })
    }
  }

  getActiveClampingFees = async () => {
    const server_response = await ajax.getActiveClampFee()
    if (server_response.status === "OK") {
      this.setState({
        clampingFee: server_response.details.content.amount_c
      })
    }
  }

  // getActiveTowingFees = async () => {
  //   const server_response = await ajaxTowing.getActiveTowFee()
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       towingFee: server_response.details.content.amount_c
  //     })
  //   }
  // }



  getTotalRoles = async () => {
    const server_response = await ajax.countRoles(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalRoles: server_response.details.content.total_c
      })
    }
  }


  getTotalPermissions = async () => {
    const server_response = await ajax.countPermissions(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalPermissions: server_response.details.content.total_c
      })
    }
  }


  getTotalUnreadNotifications = async () => {
    const server_response = await ajax.countUnreadNotifications(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        totalNotifications: server_response.details.content
      })
    }
  }


  getTotalPaymentModes = async () => {
    const server_response = await ajax.countPaymentModes()
    if (server_response.status === "OK") {
      this.setState({
        totalPaymentModes: server_response.details.content.total_c
      })
    }
  }

  getTotalStickerTypes = async () => {
    const server_response = await ajax.countStickerTypes()
    if (server_response.status === "OK") {
      this.setState({
        totalStickerTypes: server_response.details.content.total_c
      })
    }
  }
  // getTotalTowingStations = async () => {
  //   const server_response = await ajax.countTowingStations()
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       totalTowStations: server_response.details.content.total_c
  //     })
  //   }
  // }

  getTotalVehicleTypes = async () => {
    const server_response = await ajax.countVehicleTypes()
    if (server_response.status === "OK") {
      this.setState({
        totalVehicleTypes: server_response.details.content.total_c
      })
    }
  }

  getVehicleStatistics = async () => {
    const server_response = await ajax.getVehicleStatistics();

    if (server_response.status === "OK") {
      this.setState({
        statistics: server_response.details.content.total_vehicles.total_p
      })
    }
  }


  getBankingClientCount = async () => {
    const server_response = await ajaxConfig.countBankingClients();

    if (server_response.status === "OK") {
      this.setState({
        totalBanks: server_response.details.content.total_p
      })
    }
  }



  getBranches = async () => {
    this.setState({
      branchList: false
    })
    const server_response = await ajax.listBranches(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        branchList: server_response.details.content
      })
    } else {
      this.setState({
        branchList: "404"
      })
    }
  }


  getStreets = async () => {
    this.setState({
      streetList: false
    })
    const server_response = await ajax.listStreets(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        streetList: server_response.details.content
      })
    } else {
      this.setState({
        streetList: "404"
      })
    }
  }



  getLocations = async () => {
    this.setState({
      locationList: false
    })
    const server_response = await ajax.listLocations(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        locationList: server_response.details.content
      })
    } else {
      this.setState({
        locationList: "404"
      })
    }
  }

  getDevices = async () => {
    this.setState({
      deviceList: false
    })
    const server_response = await ajax.listDevices(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        deviceList: server_response.details.content.list
      })
    } else {
      this.setState({
        deviceList: "404"
      })
    }
  }





  getPermissions = async () => {
    const server_response = await ajax.listPermissions();
    if (server_response.status === "OK") {
      this.setState({
        permissionList: server_response.details.content,
        tableId: "table-permissions"
      }, () => {
        //beautifyTable(this.state.tableId, this.state.loadTable, false); Remove
        this.setState({
          loadTable: false
        })
      })
    } else {
      this.setState({
        permissionList: "404"
      })
    }
  }


  getBanks = async () => {
    const server_response = await ajax.getBankingClients();
    if (server_response.status === "OK") {
      this.setState({
        bankList: server_response.details.content
      })
    }
  }

  getClampTools = async () => {
    this.setState({
      clampToolList: false
    })
    const server_response = await ajax.listClampTools();
    if (server_response.status === "OK") {
      this.setState({
        clampToolList: server_response.details.content
      })
    } else {
      this.setState({
        clampToolList: "404"
      })
    }
  }
  // ClampStatistics = async () => {
    // const server_response = await ajax.getClampToolStatistics();

    // if (server_response.status === "OK") {
    //   this.setState({
    //     clampToolStatistics: server_response.details.content
    //   })
    // }
  // }

  /** Fetches all clamp abort reasons. */
  // getClampAbortReasons = async () => {
  //   const server_response = await ajax.listViolationReasons();
  //   if (server_response.status === 'OK') {
  //     this.setState({
  //       clampAbortReasonList: server_response.details.content
  //     })
  //   }
  //   else {
  //     this.setState({
  //       clampAbortReasonList: "404"
  //     })
  //   }
  // }


  listClampReasons = async () => {
    const server_response = await ajax.listContraventions(this.state.userId);
    if (server_response.status === 'OK') {
      this.setState({
        clampedForList: server_response.details.content
      })
    }
    else {
      this.setState({
        clampedForList: "404"
      })
    }

  }

  getCobras = async () => {
    const server_response = await ajax.listUsersByRole(this.state.userId, this.state.cobraRoleId);
    if (server_response.status === 'OK') {
      this.setState({
        cobraList: server_response.details.content
      })
    }
    else {
      this.setState({
        cobraList: "404"
      })
    }

  }

  getZulus = async () => {
    const server_response = await ajax.listUsersByRole(this.state.userId, this.state.zuluRoleId);
    if (server_response.status === 'OK') {
      this.setState({
        zuluList: server_response.details.content
      })
    }
    else {
      this.setState({
        zuluList: "404"
      })
    }

  }

  getConductors = async () => {
    const server_response = await ajax.listUsersByRole(this.state.userId, this.state.conductorRoleId);

    if (server_response.status === 'OK') {
      this.setState({
        conductorList: server_response.details.content
      })
    }
    else {
      this.setState({
        conductorList: "404"
      })
    }

  }

  // getUnUsedClampTools = async () => {
  //   const server_response = await ajax.listClampToolsByStatus(0);
  //   if (server_response.status === 'OK') {
  //     this.setState({
  //       unUsedClampToolList: server_response.details.ClampTools
  //     })
  //   }
  //   else {
  //     this.setState({
  //       unUsedClampToolList: "404"
  //     })
  //   }

  // }

  // getWrongParkingDescriptions = async () => {
  //   this.setState({
  //     wrongParkingDescriptionList: false
  //   })
  //   const server_response = await ajax.listDescriptions(this.state.userId);
  //   if (server_response.status === 'OK') {
  //     this.setState({
  //       wrongParkingDescriptionList: server_response.details.content
  //     })
  //   }
  //   else {
  //     this.setState({
  //       wrongParkingDescriptionList: "404"
  //     })
  //   }

  // }

  getContraventions = async () => {
    const server_response = await ajax.listContraventions(this.state.userId);
    if (server_response.status === 'OK') {
      this.setState({
        clampedForList: server_response.details.content
      })
    }
    else {
      this.setState({
        clampedForList: "404"
      })
    }

  }

  getOperationsUsers = async () => {
    const server_response = await ajax.getUsersByRole(this.state.operationRoleId);
    if (server_response.status === 'OK') {
      this.setState({
        operationsList: server_response.details.content
      })
    }
    else {
      this.setState({
        operationsList: "404"
      })
    }

  }

  // getTowStations = async () => {
  //   const server_response = await ajax.listTowingStations();
  //   if (server_response.status === 'OK') {
  //     this.setState({
  //       towingStationList: server_response.details.content
  //     })
  //   }
  //   else {
  //     this.setState({
  //       towingStationList: "404"
  //     })
  //   }
  // }




  // getClampStatuses = async () => {
  //   const server_response = await ajax.listClampStatuses();
  //   if (server_response.status === 'OK') {
  //     this.setState({
  //       clampingStatusList: server_response.details.ClampingStatus
  //     })
  //   }
  //   else {
  //     this.setState({
  //       clampingStatusList: "404"
  //     })
  //   }

  // }

  // getTowStatuses = async () => {
  //   const server_response = await ajaxTowing.listTowStatuses();
  //   if (server_response.status === 'OK') {
  //     this.setState({
  //       towingStatusList: server_response.details.content
  //     })
  //   }
  //   else {
  //     this.setState({
  //       towingStatusList: "404"
  //     })
  //   }

  // }


  // getClampViolationReasons = async () => {
  //   const server_response = await ajax.listViolationReasons();
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       clampViolationReasonsList: server_response.details.content
  //     })
  //   } else {
  //     this.setState({
  //       clampViolationReasonsList: "404"
  //     })
  //   }
  // }


  // list the clamp abort reasons with no fine only
  // getCobraClampAbortReasons = async () => {
  //   const server_response = await ajaxClamping.listClampAbortReasons();
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       clampAbortReasonsList: server_response.details.content
  //     })
  //   } else {
  //     this.setState({
  //       clampAbortReasonsList: "404"
  //     })
  //   }
  // }


  getPlexPictures = async () => {
    this.setState({
      plexPicturesList: false
    })
    const server_response = await ajaxPlex.listAllPlexPictures();
    if (server_response.status === "OK") {
      this.setState({
        plexPicturesList: server_response.details.content.list
      })
    } else {
      this.setState({
        plexPicturesList: "404"
      })
    }
  }


  getTotalPictures = async () => {
    const server_response = await ajaxPlex.countAllPictures()
    if (server_response.status === "OK") {
      this.setState({
        totalPictures: server_response.details.content.total_c
      })
    }
  }


  // getTotalWrongParkingDescriptions = async () => {
  //   const server_response = await ajaxWrongParking.countWrongParkingDescriptions(this.state.userId)
  //   if (server_response.status === "OK") {
  //     this.setState({
  //       countAllWrongParkingDescriptions: server_response.details.content.total_c
  //     })
  //   }
  // }


  render() {
    return (
      <ConfigurationContext.Provider value={{
        ...this.state,
        getPermissions: this.getPermissions,
        getLocations: this.getLocations,
        getBanks: this.getBanks,
        clampToolList: this.state.clampToolList,
        // clampToolStatistics: this.state.clampToolStatistics,
        getClampTools: this.getClampTools,
        // clampAbortReasonList: this.state.clampAbortReasonList,
        clampedForList: this.state.clampedForList,
        zuluList: this.state.zuluList,
        getCobras: this.getCobras,
        cobraList: this.state.cobraList,
        conductorList: this.state.conductorList,
        // unUsedClampToolList: this.state.unUsedClampToolList,
        // getUnUsedClampTools: this.getUnUsedClampTools,
        // wrongParkingDescriptionList: this.state.wrongParkingDescriptionList,
        operationsList: this.state.operationsList,
        // towingStationList: this.state.towingStationList,
        getOperationsUsers: this.getOperationsUsers,
        // getTowStations: this.getTowStations,
        // getClampStatuses: this.getClampStatuses,
        // getTowStatuses: this.getTowStatuses,
        // clampingStatusList: this.state.clampingStatusList,
        // towingStatusList: this.state.towingStatusList,
        // getWrongParkingDescriptions: this.getWrongParkingDescriptions,
        // clampViolationReasonsList: this.state.clampViolationReasonsList,
        // getClampViolationReasons: this.getClampViolationReasons,
        // clampAbortReasonsList: this.state.clampAbortReasonsList,
        // getCobraClampAbortReasons: this.getCobraClampAbortReasons,
        // ClampStatistics: this.ClampStatistics,
        statistics: this.state.statistics,
        // towingFee: this.state.towingFee,
        getDevices: this.getDevices,

        getBranches: this.getBranches,
        getStreets: this.getStreets,

        // totalViolations: this.state.totalViolations,
        // totalAbortReasons: this.state.totalAbortReasons,

        // getTotalAbortReasons: this.getTotalAbortReasons,
        // getTotalViolations: this.getTotalViolations,

        plexPicturesList: this.state.plexPicturesList,
        getPlexPictures: this.getPlexPictures,
        totalPictures: this.state.totalPictures,
        getTotalPictures: this.getTotalPictures,
        // countAllWrongParkingDescriptions: this.state.countAllWrongParkingDescriptions,
        // getTotalWrongParkingDescriptions: this.getTotalWrongParkingDescriptions,
        branchList: this.state.branchList,
        streetList: this.state.streetList
      }}>
        {this.props.children}
      </ConfigurationContext.Provider>
    );
  }
}

export default ConfigurationContext;