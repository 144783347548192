import React, { useContext, useState } from 'react';
import ActivityLoader from '../../../Common/ActivityLoader';
import AlertError from '../../../Common/AlertError';
import AlertSuccess from '../../../Common/AlertSuccess';
import TextInput from '../../../Common/TextInput';
import ajaxEfris from '../../../../utils/remote/ajaxEfris';
import ThemeContext from '../../../../Context/ThemeContext';
import functions from '../../../../utils/functions';

const PopTaxProfileForm = (props) => {

    const [TIN, setTIN] = useState(props.vehicleInfo.client.TIN);
    const [TaxPayerName, setTaxPayerName] = useState(props.vehicleInfo.client.tax_payer_name);
    const [loading, setLoading] = useState(false);
    const [clientId, setCLientId] = useState(props.vehicleInfo.client_id);
    const [info, setInfo] = useState(false);
    const {setDisplayMessage} = useContext(ThemeContext);

    const onConfirm = async (event) => {
        if(functions.roleGuard()!=="SUPER" && props.vehicleInfo.client.TIN.length > 5){
            setInfo(<AlertError message="Tax profile already set! Please contact admin to make additional changes." type="info"/>);
            return;
        }
        event.preventDefault();
        if(TIN.length > 0 && TaxPayerName.length > 0){
            setInfo(<ActivityLoader />);
            setLoading(true);
            const server_response = await ajaxEfris.postTaxProfileUpdate({
                TIN:TIN,
                tax_payer_name: TaxPayerName,
                client_id: clientId
            });
            setLoading(false);
            if(server_response.status === "OK"){
                setDisplayMessage(functions.templateMessage("Tax Profile: ", server_response.message, "info"))
                setInfo(<AlertSuccess message={server_response.details.message} />)
                functions.closePopup('modal_tax_profile_form')
                props.refresh(props.vehicleInfo.plate_number);

            }else{
                setInfo(<AlertError message={server_response.details.message} />)
            }

        }else{
            setInfo(<AlertError message="Both fields are required" />)
        }
    }

        return (
            <>
                <div className="modal" id="modal_tax_profile_form">
                    <div className="modal-dialog modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">Update Tax Profile</h6>
                                <span>Vehicle: {props.vehicleInfo.plate_number}</span>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {info}
                                <form method="post" onSubmit={onConfirm}>

                                    <TextInput
                                        label="TIN"
                                        type="number"
                                        placeholder="TIN"
                                        onChange={(e) => setTIN(e.target.value) }
                                        value={TIN}
                                        name="TIN"
                                    />

                                
                                    <TextInput
                                        label="Tax Payer Name"
                                        type="text"
                                        placeholder="Tax Payer's Name"
                                        onChange={(e)=>setTaxPayerName(e.target.value)}
                                        value={TaxPayerName}
                                        name="tax_payer_name"
                                    />

                                </form>




                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-success" disabled={loading} type="button" onClick={onConfirm}>Save Changes</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )

}

export default PopTaxProfileForm;
