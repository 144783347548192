import React from 'react'
import Chart from "react-apexcharts";

import TableHeader from '../Common/TableHeader';
import BarChart from './BarChart';
import ApexChart from './ApexChart'
import ApexLine from './ApexLine';
import functions from '../../utils/functions';
import ajax from '../../utils/ajax';
import ActivityLoader from '../Common/ActivityLoader';
import ajaxAnalysis from '../../utils/remote/ajaxAnalysis';


class PaymentDueGraph extends React.Component {

    state = {

        userId: functions.sessionGuard(),
        paymentDueData: false,
        options: false,
        series: false,

        // chartData: false,

        // borderColors: [
        //     'rgba(113, 76, 190, 0.9)',
        //     'rgba(235, 111, 51, 0.8)',
        //     'rgba(21, 197, 95, 0.6)'
        // ],
        // backgroundColors: [
        //     'rgba(113, 76, 190, 0.5)',
        //     'rgba(235, 111, 51, 0.8)',
        //     'rgba(21, 197, 95, 0.8)'
        // ],

        // series: [
        //     {
        //         name: 'Total Payment Due',
        //         data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 34, 87, 90]
        //     },
        //     {
        //         name: 'Total Paid',
        //         data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 23, 12]
        //     },
        // ],

        // options: {
        //     chart: {
        //         stacked: false,
        //         toolbar: {
        //             show: false
        //         }
        //     },
        //     plotOptions: {
        //         bar: {
        //             horizontal: false,
        //             columnWidth: '60%',
        //             endingShape: 'rounded'
        //         },
        //     },
        //     dataLabels: {
        //         enabled: false
        //     },
        //     colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],
        //     stroke: {
        //         show: true,
        //         width: [0, 2, 4],
        //         colors: ['transparent']
        //     },
        //     fill: {
        //         opacity: [0.85, 0.75, 1],
        //         gradient: {
        //             inverseColors: false,
        //             shade: 'light',
        //             type: "vertical",
        //             opacityFrom: 0.85,
        //             opacityTo: 0.55,
        //             stops: [0, 100, 100, 100]
        //         }
        //     },
        //     markers: {
        //         size: 0
        //     },
        //     xaxis: {
        //         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        //     },
        //     yaxis: {
        //         title: {
        //             display: false,
        //         },
        //         min: 0,
        //         tickAmount: 7,
        //     },
        //     legend: {
        //         display: true,
        //         position: 'top'
        //     },
        //     tooltip: {
        //         shared: true,
        //         intersect: false,
        //         y: {
        //             formatter: function (val) {
        //                 return "$ " + val + " thousands"
        //             }
        //         }
        //     },
        //     grid: {
        //         borderColor: '#f1f1f1'
        //     },
        // },



        // fill: {
        //     opacity: [0.85, 0.25, 1],
        //     gradient: {
        //         inverseColors: false,
        //         shade: 'light',
        //         type: "vertical",
        //         opacityFrom: 0.85,
        //         opacityTo: 0.55,
        //         stops: [0, 100, 100, 100]
        //     }
        // },

    };

    componentDidMount() {
        this.getAnuualContraventionsCount();
    }

    setOptionsMixedChart = (data) => {

        const optionsMixedChart = {

            chart: {
                // height: 139,
                // type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                }
            },

            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '60%',
                    endingShape: 'rounded'
                    // distributed: true,
                }
            },

            dataLabels: {
                enabled: false
            },

            colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],

            stroke: {
                show: true,
                width: [0, 2, 4],
                curve: 'smooth',
                colors: ['transparent']
            },

            fill: {
                opacity: [0.85, 0.75, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },

            // xaxis: {
            //   type: 'datetime'
            // },

            // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],

            // labels: data,

            markers: {
                size: 0
            },

            // labels: data,

            xaxis: {
                categories: data,
            },

            yaxis: {
                title: {
                    // text: 'Points',
                    display: false,
                },
                min: 0,
                tickAmount: 7,
                // max: 200,
            },

            legend: {
                display: true,
                position: 'top'
            },

            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " cars";
                        }
                        return y;

                    }
                }
            },

            grid: {
                borderColor: '#f1f1f1'
            },

        }

        this.setState({ options: optionsMixedChart })

    }


    setSeriesMixedChart = (data_series) => {
        const seriesMixedChart = [];
        const newlist = []
        newlist.push(data_series)

        Object.keys(data_series).map((item, key) => {
            var series = data_series[item]
            var series_data = []
            for (var i = 1; i < 13; i++) {
                series_data.push(series.data[i].total)
            }

            seriesMixedChart.push(
                {
                    name: series.title,
                    type: series.graph_type,
                    data: series_data
                    // data={ this.state.chartData }
                }
            )


        })

        // newlist.map((item, key) => {
        //   const count_data = [];

        //   for (const key in item) {
        //     for (const newkey in item[key].data) {
        //       count_data.push(item[key].data[newkey].total);
        //     }

        //     // console.log(key)
        //     // // for (var i = 1; i < 13; i++) {
        //     // // list =[1,2,3,4,5,6,7,8,9,0]
        //     //   console.log(item[key])

        //     // count_data.push(item[key].data[1].total);

        //     // // }

        //     seriesMixedChart.push(
        //       {
        //         name: item[key].title,
        //         type: item[key].graph_type,
        //         data: count_data
        //         // data={ this.state.chartData }
        //       }
        //     )
        //   }
        //   // console.log(item["session_count"])
        //   // for (var i = 1; i < 13; i++) {
        //   //   count_data.push(item[key].data[i].total);
        //   // }
        //   // console.log(count_data);


        // })

        this.setState({ series: seriesMixedChart })

    }


    // type="line"
    getAnuualContraventionsCount = async () => {
        const server_response = await ajaxAnalysis.analyseAnnualContraventionCollection();


        if (server_response.status === "OK") {
            this.setState({
                paymentDueData: server_response.details.content
            }, () => {

                const months = [];
                for (var i = 1; i < 13; i++) {
                    months.push(this.state.paymentDueData.months[i]);
                }

                this.setOptionsMixedChart(months)
                this.setSeriesMixedChart(this.state.paymentDueData.counts)
            })


        } else {
            this.setState({
                paymentDueData: '404'
            })
        }
    }


    render() {


        return (
            //   <div className="col-sm-12 col-md-12 col-xl-7 col-lg-7">
            <div className="card custom-card">
                <div className="card-body map-card">

                    <TableHeader
                        title="Bar Chart of Payment Due status"
                        subtitle="Below is the Bar chart representing paid and not paid payment due."
                    />

                    <div className="mt-3 border-top">
                        <div className="mt-2">
                            {this.state.options && this.state.series &&
                                <Chart
                                    options={this.state.options}
                                    series={this.state.series}

                                    // options={this.state.options}
                                    // series={this.state.series}
                                    type="bar"
                                    height={360}
                                />
                            }

                            {!this.state.options && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>
            //   </div>
        )
    }
}

export default PaymentDueGraph;
