import React, { Component, useContext, useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxSession from '../utils/remote/ajaxSession';
import { detectTable, restoreTable } from '../utils/script/customTables';
import AuthContext from './AuthContext';
import ajaxViolation from '../utils/remote/ajaxViolation';
import ajaxLocation from '../utils/remote/ajaxLocation';

const OperationContext = React.createContext();

export const OperationConsumer = OperationContext.Consumer;

const exportFormats =['copy', 'excel', 'csv', 'pdf', 'print']
export const OperationProvider=(props)=> {

    const [countActiveSessions, setCountActiveSessions] = useState('...');
    const [countEndedSessions, setCountEndedSessions] = useState('...');
    const [countClampedVehicles, setCountClampedVehicles] = useState('...');
    const [countTowedVehicles, setCountTowedVehicles] = useState('...');
    const [countAvailableParking, setCountAvailableParking] = useState('...');
    const [countAllSessions, setCountAllSessions] = useState('...');
    const [recentSessionList, setRecentSessionList] = useState(false);
    const [allSessions, setAllSessions] = useState(false);
    const [conductorList, setConductorList] = useState(false);
    const [filteredSessions, setFilteredSessions] = useState(false);
    const [status, setStatus] = useState('');
    const [tableId, setTableId] = useState('table');
    const [loading, setLoading] = useState(false);
    const [assignedConductorList, setAssignedConductorList]=useState(false);
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [refreshRate, setRefreshRate] = useState(1);
    const [sessionEndRequests, setSessionEndRequests] = useState(false);
    const [activeSlotCount, setActiveSlotCount] = useState('...')

    const { secureCall } = useContext(AuthContext);

    useEffect(()=>{
      secureCall(getConductors, "USERS-LIST-CONDUCTOR");
      secureCall(getAssignedConductors, "USERS-LIST-CONDUCTOR");
      // secureCall(listAllSessions, "R-SESSION-02");
      secureCall(getCountEndedSessions, "R-SESSION-02");
      secureCall(getCountAllSessions, "R-SESSION-02");
      secureCall(getCountActiveSlots, "R-SESSION-02");

    }, [])

    useEffect(()=>{

      if(userId)
      {
        secureCall(getCountActiveSessions, "R-SESSION-02")
        secureCall(getCountAvailableParking, "R-AVAIL-PARKING-02");
        secureCall(listRecentSessions, "R-SESSION-02");
        secureCall(getSessionEndRequests, "R-SESSION-02");
        secureCall(getCountClampedVehicles, "R-CLAMP-02");
        secureCall(getCountTowedVehicles, "R-TOW-02");
        secureCall(getCountActiveSlots, "R-SESSION-02");

        setTimeout(() => {
          setRefreshRate(functions.timestamp());
        }, dictionary._refreshRate);
       

      }

    }, [refreshRate])
    


    const getCountActiveSessions = async () => {

        const server_response = await ajax.countActiveSessions();
        if (server_response.status === "OK") {
          setCountActiveSessions(server_response.details.content.total_p)
        }
      }

    const getCountEndedSessions = async () => {

      const server_response = await ajaxSession.countEndedSessions();
      if (server_response.status === "OK") {
        setCountEndedSessions(server_response.details.content.total_p)
      }
    }


    const getCountAllSessions = async () => {

        const server_response = await ajaxSession.countAllSessions();
        if (server_response.status === "OK") {
          setCountAllSessions(server_response.details.content.total_p);
        }
      }
    
    const getCountClampedVehicles = async () => {
    
        const server_response = await ajaxViolation.fetchTotalClamped();
        if (server_response.status === "OK") {
            setCountClampedVehicles(server_response.details.content.total_p)
        }
      }
    
    const getCountTowedVehicles = async () => {
    
        const server_response = await ajax.countTowedVehicles();
        if (server_response.status === "OK") {
           setCountTowedVehicles(server_response.details.content.total_p)
        }
      }
    
    const  getCountAvailableParking = async () => {
    
        const server_response = await ajax.getAvailableParking();
        if (server_response.status === "OK") {
          setCountAvailableParking(server_response.details.content.total_p)
        }
      }
    
     
     const listRecentSessions = async () => {
        var server_response;
        if (props.streetId) {
            server_response = await ajax.listRecentStreetSessions(props.streetId);
        
        } else {
            server_response = await ajax.listRecentSessions(userId);
        }
        if (server_response.status === "OK") {
           setRecentSessionList(server_response.details.content.list)  
      
        } else {
           setRecentSessionList("404");
        }
    }

    



const getConductors = async () => {
  const server_response = await ajax.listConductors();
  if (server_response.status === "OK") {

      setConductorList(server_response.details.content.list)
  }
}

const getAssignedConductors = async () => {

  const server_response = await ajax.listAllocatedConductors();
  if (server_response.status === "OK") {
      setAssignedConductorList(server_response.details.content.list)
      
  } else {
      setAssignedConductorList("404");
  }
}
   





const getSessionEndRequests = async () => {
  const server_response = await ajaxSession.getClientSessionEndRequests();
  if (server_response.status === "OK") {

      setSessionEndRequests(server_response.details.content)
  }else{
    setSessionEndRequests("404")
  }
}


const  getCountActiveSlots = async () => {
    
  const server_response = await ajaxLocation.fetchActiveSlots();
  if (server_response.status === "OK") {
    setActiveSlotCount(server_response.details.content.total_p)
  }
}





        return (
           <OperationContext.Provider value={{
                countActiveSessions,
                countEndedSessions,
                countClampedVehicles,
                countTowedVehicles,
                countAvailableParking,
                countAllSessions,
                recentSessionList,
                conductorList,
                filteredSessions,
                tableId,
                loading,
                assignedConductorList,
                sessionEndRequests,
                activeSlotCount               
           }}>
               {props.children}
           </OperationContext.Provider>
        );
}

export default OperationContext;