import React, { useContext } from "react"
import ScrollFilterBar from "../../Common/Filter/ScrollFilterBar";
import FilterTextInput from "../../Common/Filter/FilterTextInput";
import FilterSelectInput from "../../Common/Filter/FilterSelectInput";
import TowingContext from "../../../Context/Violation/TowingContext";

const TowFilter=(props)=>{

    const { 
        resetData,
        filterData,
    } = useContext(TowingContext)


    const optionStatus = [
        {title:'TOWED', value:'1'},
        {title:'RESOLVED', value:'2'},
        {title:'PICKED', value:'3'},
    ];
        



    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Plate Number"
            type="text"
            placeholder="Enter plate number"
            onChange={onSet}
            name="plateNumber"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterSelectInput
            key={key}
            label="Status"
            options={optionStatus}
            onChange={onSet}
            name="status"
            currentValues={currentValues}
          />,

        ],
        names:["dateFrom", "dateTo", "plateNumber", "status"]
    }}
   
/>

}

export default TowFilter