import React from 'react';


class FltRecentNotifications extends React.Component {
  

  render(){
    
      return (
        <span 
        type="button" 
        className=" badge badge-pill badge-success ml-3"
        onClick={this.props.onClick}>
        View Recent
        </span>
      );
 
  }
}


export default FltRecentNotifications;