import React from 'react';
import dictionary from '../../../utils/dictionary';
import AlertError from '../../Common/AlertError';
import SelectSearch from '../../Common/SelectSearch';
import TextInput from '../../Common/TextInput';
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader'
import AlertSuccess from '../../Common/AlertSuccess';
import ConfigurationContext, { ConfigurationConsumer } from '../../../Context/ConfigurationContext';
import ajaxInvoice from '../../../utils/remote/ajaxInvoice';


class NewClampViolation extends React.Component {

    state = {
       
        violationDate:"",
        violationTime:"",
        violationId:"",
        loading:false,
        invoiceId:""

    }


    componentDidMount() {
        this.setState({
            invoiceId:this.props.activeInvoice
        }, ()=>{
            if(this.state.invoiceId.length<1){
                this.setState({
                    info:<AlertError message="Warning: No clamping invoice has been selected from the invoice history" type="warning"/>
                })
            }
        })
    }
    

    onConfirm = async(event) => {
        event.preventDefault();
        const { violationDate, violationTime, invoiceId, violationId } = this.state;
        if ( violationDate.length > 0 && violationTime.length > 0 && invoiceId.length>0 && violationId.length>0) {
            this.setState({loading:true})
            const server_response = await ajaxInvoice.postCustomClampViolation({
                "plate_number":this.props.plateNumber,
                "violation_id":violationId,
                "violation_date":violationDate + " " + violationTime,
                "invoice_id":invoiceId
            })

            if(server_response.status==="OK"){

                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })

                setTimeout(()=>{
                    functions.closePopup("modal-custom-invoicing")
                }, 3000)

            }else{
                this.setState({
                    loading:false,
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
          this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
            
        }
    }

   


   
    render() {
        const optionsViolations = [];

        return (

            <ConfigurationConsumer>

                {props => {

                    const { clampAbortReasonList } = props;

                  
                    if (Array.isArray(clampAbortReasonList)) {
                        clampAbortReasonList.map((item, key) =>
                            optionsViolations.push({ label: item.reason + (" - UGX. " + item.fine.total_c), value: item.reason_id })
                        )

                    }



                    return (
                    
                                <div className="modal-content modal-content-demo shadow">
                                    <div className="modal-header">
                                        <h6 className="modal-title">New Clamp Violation Invoice</h6>

                                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                   
                                    <div className="modal-body">
                                        {this.state.info}


                                        <form method="post" onSubmit={this.onConfirm}>
                                        
                                            <TextInput
                                                label="Violation Date"
                                                type="date"
                                                value={this.state.violationDate}
                                                name="ViolationDate"
                                                onChange={(e) => {this.setState({violationDate:e.target.value})}}
                                            />

                                             <TextInput
                                                label="Violation Time"
                                                type="time"
                                                name="ViolationTime"
                                                value={this.state.violationTime}
                                                onChange={(e) => {this.setState({violationTime:e.target.value})}}
                                            />

                                           
                                

                                            <SelectSearch
                                                label="Clamp Violation"
                                                name="ClampViolation"
                                                onChange={(selection) => this.setState({violationId:selection.value})}
                                                options={ optionsViolations}
                                                placeholder={"Select clamp violation"}
                                            />

                


                                        

                                        </form>

                                    </div>
                                    <div className="modal-footer">
                                        {!this.state.loading && <>
                                            <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Save Invoice</button>
                                            <button className="btn ripple btn-light" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                                        </>}
                                        {this.state.loading && <ActivityLoader />}
                                    </div>
                                </div>
                           
                    )
                }}
            </ConfigurationConsumer>

        )
    }
}

export default NewClampViolation;
