import React, { useEffect, useState } from "react";
import ajax from "../utils/ajax";
import functions from "../utils/functions";
import dictionary from "../utils/dictionary";
import ActivityLoader from "../Components/Common/ActivityLoader";
import ajaxLocation from "../utils/remote/ajaxLocation";
//import Autocomplete from 'react-google-autocomplete';
const MapContext = React.createContext();

export const MapConsumer = MapContext.Consumer;


export const MapProvider = (props) => {

  const [MapComponent, setMapComponent] = useState(<ActivityLoader />);
  const [directions, setDirections] = useState(null);
  const [userId, setUserId] = useState(functions.sessionGuard());
  const [locationNonParkingZoneList, setlocationNonParkingZoneList] = useState(false);
  const [locationParkingZoneList, setlocationParkingZoneList] = useState(false);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [showNonParkingInfoIndex, setShowNonParkingInfoIndex] = useState(false);
  const [showParkingInfoIndex, setShowParkingInfoIndex] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0.3141698, lng: 32.5849633 });
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [state, setState] = useState("");
  const [zoom, setZoom] = useState(15);
  const [height, setHeight] = useState(400);
  const [mapPosition, setMapPosition] = useState({ lat: 0, lng: 0, });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0, })
  const [vehicleListing, setVehicleListing] = useState(false);
  const [refreshRate, setRefreshRate] = useState(1);


//   useEffect(()=>{

//     setTimeout(() => {
//         setRefreshRate(refreshRate + 1)
//     }, dictionary._refreshRate);

// }, [refreshRate])


  const initializeMap = async () => {

    if (window.google) {
      const directionsService = new window.google.maps.DirectionsService();

      const origin = { lat: 0.294930, lng: 32.549200 };
      const destination = { lat: 0.294510, lng: 32.555040 };

      // const origin = { lat: props.startLat, lng: props.startLng };
      // const destination = { lat: props.endLat, lng: props.endLng };

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.WALKING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result)
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
    //renderMap()

  }


  useEffect(() => {
    getParkingZones();
    getNonParkingZones();
  }, [])


  useEffect(() => {
     initializeMap()
  }, [locationParkingZoneList, locationNonParkingZoneList, vehicleListing])


  useEffect(()=>{
    if(showParkingInfoIndex){
        getLocationSessions(showParkingInfoIndex)
    }
  }, [showParkingInfoIndex])



  const getNonParkingZones = async () => {

    const server_response = await ajax.listLocationZones(userId, 1);
    if (server_response.status === "OK") {
      setlocationNonParkingZoneList(server_response.details.content)
    } else {
      setlocationNonParkingZoneList("404")
    }
  }


  const getParkingZones = async () => {

    const server_response = await ajax.listLocationZones(userId, 0);
    if (server_response.status === "OK") {
      setlocationParkingZoneList(server_response.details.content)
    } else {
      setlocationParkingZoneList("404");
    }
  }



  const onMarkerClick = () => setShowingInfoWindow(true);

  const onInfoWindowClose = () => setShowingInfoWindow(false)


  const showParkingInfo = (a) => {
    setShowParkingInfoIndex(a);
    //onMarkerClick();
  }

  const showNonParkingInfo = (a) => {
    setShowNonParkingInfoIndex(a)
  }




  const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };




  const onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = getCity(addressArray),
      area = getArea(addressArray),
      state = getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    setAddress(address ? address : '');
    setArea(area ? area : '');
    setCity(city ? city : '');
    setState(state ? state : '');
    setMarkerPosition({
      lat: latValue,
      lng: lngValue
    })

    setMapPosition({
      lat: latValue,
      lng: lngValue
    })

  };






  //   const renderMap=()=>{
  //       setMapComponent(MapView)
  //   }



  const getLocationSessions = async (locationId) => {
    setVehicleListing(false)
    const server_response = await ajaxLocation.getVehiclesInSession(locationId);
    if (server_response.status === "OK") {
      setVehicleListing(server_response.details.content)
    } else {
      setVehicleListing("404");
    }
  }



  return (
    <MapContext.Provider value={{
      locationNonParkingZoneList,
      locationParkingZoneList,
      showNonParkingInfoIndex,
      showParkingInfoIndex,
      defaultCenter,
      showParkingInfoIndex,
      vehicleListing,
      onInfoWindowClose,
      onPlaceSelected,
      directions,
      showParkingInfo,
      showNonParkingInfo,
      defaultCenter
    }}
    >
      {props.children}
    </MapContext.Provider>
  );


}

export default MapContext;
