import React from 'react';
import ajax from '../../../utils/ajax';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import SelectSearch from '../../Common/SelectSearch';

class PopStickerOptions extends React.Component {

    state={
        stickerTypes:false,
        loading:true,
        selectedType:false,
        info:''
    }

    componentDidMount() {
        this.getStickerTypes();
    }

    onChangeStickerType = (selectedOption) => {
        this.setState({
            selectedType: selectedOption.value
        })
    }

    

    getStickerTypes = async () => {
        const server_response = await ajax.listStickerTypes();
        this.setState({
            loading:false
        })
        if (server_response.status === "OK") {
            this.setState({
                stickerTypes: server_response.details.content,
            })
        } 
    }



    onConfirm=()=>{
        if(!this.state.selectedType)
        {
            this.setState({
                info:<AlertError message="Select sticker type"/>
            })
        }else{
            this.props.onSelect(this.state.selectedType)
        }
    }
    

    render() {

        const stickerTypes = this.state.stickerTypes;
        const optionStickerType = [];
        if (stickerTypes && stickerTypes !== "404") {
            stickerTypes.map((item, key) =>
                optionStickerType.push({
                    label: item.ref_name + '@' + item.amount.amount_c + ' for ' + item.days_c,
                    value: item
                })
            )
        }


        return (
            <div className="modal" id={this.props.id || "modal_sticker_options"}>
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title || "Select Sticker Type"}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                                    {this.state.info}

                                <form method="post">
                                   {stickerTypes && <SelectSearch
                                        label="Sticker Type"
                                        onChange={() => this.onChangeStickerType}
                                        options={optionStickerType}
                                        placeholder={"Select Sticker Type"}
                                    />}

                                    {!stickerTypes && <ActivityLoader/>}
                                </form>


                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn ripple btn-primary"
                                type="button"
                                onClick={this.onConfirm}>Confirm Type</button>

                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopStickerOptions;
