import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthConsumer } from '../../../Context/AuthContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import ajaxWhiteList from '../../../utils/remote/ajaxWhiteList';
import { RenderSecure } from '../../../utils/script/RenderSecure';
import Toast from '../../Common/Toast';
import PopPaymentMode from '../../PaymentMode/PopPaymentMode';
import PopVehicleListing from '../../Vehicle/VehicleListing/PopVehicleListing';
import ConvertTicket from './Tickets/ConvertTicket';
import SellTicket from './Tickets/SellTicket';
import PopUpdateTaxProfile from './Tax/PopUpdateTaxProfile';

class VehicleInfoCard extends Component {

    state={
        vehicleInfo:this.props.vehicleInfo,
        balance:this.props.vehicleInfo.balance.amount_c,
        mdlVehicleListing:false,
        toast: false,
        whiteListRecord:false
    }

    componentDidMount() {
        this.getBalance();
        this.getWhiteListRecord();
        this.timerBalance = setTimeout(this.funcBalance = async () => {
            await this.getBalance();
            this.timerBalance = setTimeout(this.funcBalance, 1000); // (*)
        }, 1000);

    }

    componentWillUnmount() {
        clearTimeout(this.timerBalance)
    }

    getBalance = async () => {
        const server_response = await ajax.getVehicleOutstandingBalance(this.state.vehicleInfo.plate_number)
        if (server_response.status === "OK") {
            this.setState({
                balance: server_response.details.content.amount_p
            })
        }
    }


    addToList=async(list_id)=>{
        functions.closePopup("modal_vehicle_listing");
        this.setState({
            toast: <Toast
                    message={"Adding...please wait..."}
                    type={4}
                    onClose={() => this.resetToast()} />
        })
        const server_response = await ajax.populateVehicleListing(list_id, this.state.vehicleInfo.vehicle_id)
        
        if(server_response.status=="OK")
        {
            this.setState({
                toast: <Toast
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
            })
        }else{
            this.setState({
                toast: <Toast
                        type={4}
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
            })
        }
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }



    onClickAddListing=()=>{
        this.setState({
            mdlVehicleListing:false
        }, ()=>{
            this.setState({
                mdlVehicleListing:<PopVehicleListing onSelect={this.addToList}/>
            }, ()=>{
                functions.openPopup('modal_vehicle_listing')
            })
        })
       
    }


    showStickerPayment=(method_code)=>{
        if(method_code=="CHEQUE"){
            this.props.nextScreen(method_code);
            functions.closePopup('modal_payment_mode')
        }else if(method_code=="CASH"){
            this.props.nextScreen(method_code);
            functions.closePopup('modal_payment_mode')
        }
        
        else{
            this.setState({
                toast: <Toast
                        type={4}
                        message={"Method not supported for sticker payment"}
                        onClose={() => this.resetToast()} />
            })
            functions.closePopup('modal_payment_mode')
        }
            
    }


    onClickBuySticker=()=>{
        this.setState({
            mdlVehicleListing:false
        }, ()=>{
            this.setState({
                mdlVehicleListing:<PopPaymentMode onSelect={this.showStickerPayment}/>
            }, ()=>{
                functions.openPopup('modal_payment_mode')
            })
        })
       
    }





    getWhiteListRecord = async () => {
        const server_response = await ajaxWhiteList.getApprovedVehicleWhitelistRecord(this.state.vehicleInfo.vehicle_id)
        if (server_response.status === "OK") {
            this.setState({
                whiteListRecord: server_response.details.content
            })
        }
    }


    

    render() {
        const vehicleInfo=this.state.vehicleInfo;
        const identity=[];
        if(vehicleInfo.client.full_name!="Not Provided")
        {
            identity.push(vehicleInfo.client.full_name);
        }
        if(vehicleInfo.client.phone_number!="N/A")
        {
            identity.push(vehicleInfo.client.phone_number);
        }
        if(vehicleInfo.client.email!="N/A")
        {
            identity.push(vehicleInfo.client.email);
        }

        const userIdentity=identity.join(", ");

      
        const roleList = functions.roleGuard()

        const whiteListRecord = this.state.whiteListRecord;

        return (
        <AuthConsumer>
            {props=>{
                  const permissionList = props.permissionList;
                return(
                    <>
                    {this.state.mdlVehicleListing}
                    {this.state.toast}
                    <div className="row row-sm">
                        <div className="col-md">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <h5 className="card-title tx-dark tx-medium mg-b-10">
                                        {vehicleInfo.plate_number}
                                        
                                    </h5>
                                    <p className="card-subtitle mg-b-15">{userIdentity.length>0?userIdentity:"No information about vehicle owner"}</p>
                                    <div className="card-text">
        
                                        <span className="text-cente bg-company-text">
                                        <div>
                                            <h4 className="mb-1"><span className="minor-tag">ugx</span>{this.state.balance}
                                                &nbsp;
                                                {whiteListRecord && <span className='badge badge-warning tx-12 pointer'>White list: {whiteListRecord.due_amount.amount_c}</span>}
                                            </h4>
                                            {/* <span className="text-muted fs-12"><i className="fas fa-caret-up mr-1 text-success"></i>Total Balance</span> */}
                                            <h6 className="mt-1 text-muted">Outstanding Balance</h6>
        
                                        </div>
                                        </span>
        
        
                                        </div>
                                       
                                      
                                       
                                </div>
                            </div>
                        </div>
        
        
                      
        
        
        
                    </div>
        
        
                    <div className="row row-sm">
                        <div className="col-md">
                            <div className="card custom-card">
                                <div className="card-body">
        
                                <ul className="mt-0" style={{listStyle:'none', lineHeight:'35px'}}>
                                           {/* <li>
                                                <a className="card-link" href="#">
                                                    <i className="fas fa-exchange-alt mr-1 text-warning"/>
                                                    Vehicle Statement... 
                                                </a>
                                           </li> */}
                                        <RenderSecure code="R-VEHICLE-02.01">
                                            <li>
                                               <PopUpdateTaxProfile vehicleInfo={vehicleInfo} refresh={this.props.refresh}/>
                                            </li>
                                        </RenderSecure>

                                        <RenderSecure code="C-STICKER-01">
                                            <li>
                                                <a className="card-link" href="#" onClick={this.onClickBuySticker}>
                                                    <i className="fas fa-plus-circle mr-1 text-success"/>
                                                    Buy sticker 
                                                </a>
                                           </li>
                                        </RenderSecure>
                                        <RenderSecure code="R-VEHICLE-02.01">
                                            <li>
                                                <Link to={
                                                        {
                                                            pathname: `/vehicles/profile/${vehicleInfo.plate_number}`,
                                                            state: { plateNumber: vehicleInfo.plate_number }
                                                        }
                                                    } className="card-link">
                                                    <i className="fas fa-car mr-1"/>
                                                    View profile 
                                                </Link>
                                            </li>
                                        </RenderSecure>
        
                                        
                                         
                                            <li>
                                                <a className="card-link" href="#" onClick={()=>this.onClickAddListing(vehicleInfo.vehicle_id)}>
                                                    <i className="fas fa-capsules mr-1 text-warning"/>
                                                    Add to vehicle listing 
                                                </a>
                                           </li>


                                           <RenderSecure code="C-TICKET-01">
                                            <li>
                                               <SellTicket plateNumber={vehicleInfo.plate_number}/>
                                            </li>
                                        </RenderSecure>

                                        <RenderSecure code="C-TICKET-01">
                                            <li>
                                               <ConvertTicket plateNumber={vehicleInfo.plate_number}/>
                                            </li>
                                        </RenderSecure>
                                         
                                       </ul>
        
                                </div>
                            </div>
                        </div>
                    </div>
        
                  </>
                )
            }}
        </AuthConsumer>
       
        );
    }
}

export default VehicleInfoCard;