import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import Footer from '../Components/Common/Footer'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import functions from '../utils/functions'
import RightSideBar from '../Components/Common/RightSideBar'
// import DashCard from '../../Components/Cards/OperationCards/DashCard'
// import RecentSessions from '../../Components/RecentSessions'
import ajax from '../utils/ajax'
import dictionary from '../utils/dictionary'
import $ from 'jquery';
// import PopNewSession from '../../Components/Session/PopNewSession'
import { Link } from 'react-router-dom'
import NoContent from '../Components/Common/NoContent'
import Welcome from '../Components/Common/Welcome'
import DashCard from '../Components/Cards/OperationCards/DashCard'
import WelcomeTopBar from '../Components/Common/WelcomeTopBar'
import AlertSuccess from '../Components/Common/AlertSuccess'
import AlertError from '../Components/Common/AlertError'
import Toast from '../Components/Common/Toast'
// import ReservationChart from '../../Components/Operations/ReservationChart'
// import PlaceGraphHolder from '../../Components/SmallParkingMap'
// import OperationsGraph from '../../Components/BarChart/OperationsGraph'
// import BarChart from '../../Components/BarChart/BarChart'
// import SpaceAvailabilityCard from '../../Components/Location/SpaceAvailabilityCard'

class WelcomePage extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        // countActiveSessions: '...',
        // countClampedVehicles: '...',
        // countTowedVehicles: '...',
        // countAvailableParking: '...',
        requestPermission: 'Request permission to access the app',
        toast: '',
    }



    onLogout = () => {
        // const cookies = new Cookies();
        // cookies.remove('parking@user');
        // window.location.replace('/login')

        localStorage.removeItem('parking@user');
        window.location.replace('/login');
    }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    onRequest = async () => {

        const server_response = await ajax.requestPermission(this.state.requestPermission);

        if (server_response.status === "OK") {
            this.resetToast();
            this.setState({
                // info: <AlertSuccess message={server_response.details.message} />
                toast: <Toast message={server_response.details.message} />
            })

        } else {
            this.resetToast();
            this.setState({
                toast: <Toast message={server_response.details.message} type={2} />
            })

        }




    }



    render() {

        return (
            <>
                <div className="page">
                    {/* <LeftSideBar /> */}
                    {/* <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}> */}
                    {/* <WelcomeTopBar /> */}
                    <div className="container-fluid">

                        {/* <PageHeader
                            title="Operations"
                            previousPageTitle="Dashboard"
                            back="/operations"
                            options={{
                                // "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        /> */}

                        {this.state.toast}

                        <div className="row d-flex align-items-center justify-content-center">

                            {/* <DashCard className="col-sm-6 col-md-4 col-xl-3"
                                title="Towed Vehicles"
                                icon="fas fa-wrench"
                                href="/enforcement/tow_transactions"
                                value={this.state.countTowedVehicles}
                                color="icon-service bg-info-transparent rounded-circle text-info"
                            /> */}

                            {/* <div className="col-sm-6 col-md-6 col-xl-4 mt-5">
                            <Link to="#">
                                <div className="card custom-card pinned-card">
                                    <div className="card-body mt-3 mb-3 p-5 text-center">
                                        <div className="icon-service2 bg-danger-transparent rounded-circle text-danger font-size-25">
                                            <i className="fas fa-hand-paper"></i>
                                        </div>
                                        <div className="my-5">
                                            <h2 className="mb-2">Oops Sorry about this!</h2>
                                            <p className="mb-0 text-muted font-size-22">Looks like you have no permissions to access the application... Contact administration to have this rectified.</p>
                                        </div>
                                        <button className="btn ripple btn-danger" onClick={this.onRequest}>
                                            <i className="fe fe-settings mr-1"></i> Request administration
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        </div> */}


                            {/* <div className="col-12 border border-danger access-perms">
                            <div className="d-flex align-items-center justify-content-center ">
                                <div className="d-flex flex-column">
                                    <img
                                        src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"}
                                        alt="logo"
                                        width="100px"
                                        className="img-fluid mx-auto d-block"
                                    />
                                    <img
                                        src={this.props.image || process.env.PUBLIC_URL + "../../assets/images/no_data.svg"}
                                        alt="logo"
                                        width="200px"
                                        className="img-fluid mx-auto d-block my-5"
                                    />
                                    <h3 className="notfound-color">{this.props.title || "Oops! Multiplex says:"}</h3>
                                    <p className="text-muted">{this.props.subTitle || "There seems not be any available data here, sorry."}</p>
                                </div>
                            </div>
                        </div> */}
                            <div className=" w-50 mx-auto access-perms custom-card">
                                <div class="h-55">
                                    {/* <!-- <div class="col-12"> --> */}
                                    <div class="d-flex justify-content-center align-items-center text-center flex-column">
                                        <img
                                            src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"}
                                            alt="logo"
                                            width="110px"
                                            className="img-fluid mx-auto d-block"
                                        />
                                        <img
                                            src={process.env.PUBLIC_URL + "../../images/secure.svg"}
                                            alt="logo"
                                            width="210px"
                                            className="img-fluid mx-auto d-block my-5"
                                        />
                                        <div className="mb-5">
                                            <h3 class=" font-weight-bold text-dark mb-3">Oops! Multiplex says:</h3>
                                            <p><small class="text-secondary fs-14 ">Looks like you have no permissions <br />to access the application... <br />Contact administration to have this rectified</small></p>
                                        </div>
                                        <div className="btn btn-list">
                                            <button className="btn ripple btn-primary" onClick={this.onRequest}>
                                                <i className="fe fe-settings mr-1"></i> Request administration
                                            </button>
                                            <button className="btn ripple btn-danger" onClick={this.onLogout}>
                                                <i className="fe fe-settings mr-1"></i> Log out
                                            </button>
                                        </div>
                                    </div>
                                    {/* <!-- </div> --> */}
                                </div>
                            </div>

                            {/* <Welcome /> */}
                        </div>


                    </div>
                    {/* </div>
                <RightSideBar />
                 */}
                </div>

            </>
        );

    }
}

export default WelcomePage
