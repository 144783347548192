import React, { useEffect, useState } from 'react';
import ajaxViolation from '../../utils/remote/ajaxViolation';

const ViolationReportContext = React.createContext();

export const ViolationReportConsumer = ViolationReportContext.Consumer;

export const ViolationReportProvider = (props)=> {

  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false);
  const [clampReasonAnalysis, setClampReasonAnalysis] = useState(false);
  const [title, setTitle] = useState('');


useEffect(()=>{
    if(data){
        getClampReasonAnalysis()
    }
}, [data])


const getClampReasonAnalysis=async()=>{

    setClampReasonAnalysis(false);
    const server_response = await ajaxViolation.fetchClampReasonAnalysis(data);
    if(server_response.status==='OK'){
        setClampReasonAnalysis(server_response.details.content)
        setTitle(server_response.message)
    }else{
        setClampReasonAnalysis('404')
    }   
}





const resetData=()=>{
    setData({"page":"1"})
}

const filterData=(options)=>{
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo
    })
    
}

    
    return (
           <ViolationReportContext.Provider value={
               {
                  loading,
                  clampReasonAnalysis,
                  title,
                  setData,
                  resetData,
                  filterData,
                  getClampReasonAnalysis,
               }
               }>
               {props.children}
           </ViolationReportContext.Provider>
        );
    
}

export default ViolationReportContext;