import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import TableHeader from '../Common/TableHeader';
import ApexPolar from './ApexPolar';




const ReservationChart = (props) => {

    const [spaceAvailability, setSpaceAvailability] = useState(false)
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {
        getSpaceAvailability();
    }, [refreshCounter])


    useEffect(() => {

        setTimeout(() => {
            setRefreshCounter(refreshCounter + 1)
        }, dictionary._waitTime);

    }, [refreshCounter])





    const getSpaceAvailability = async () => {
        const server_response = await ajax.getSpaceAvailability();
        if (server_response.status === "OK") {

            setSpaceAvailability(server_response.details.content)

        } else {
            setSpaceAvailability("404")

        }

    }




    const transaction = spaceAvailability

    return (

        <div className="card custom-card">
            <div className="card-body map-card">

                <TableHeader
                    title="Parking space stats"
                    subtitle="Contrast between reserved and available parking."
                    view="View All"
                    onClick={props.onClickViewAll}
                />


                <div className="text-center user-manager mt-3 scroll-widget border-top overflow-auto">

                    <div className="mt-3 mb-3">

                        {transaction && transaction != "404" &&
                            <>
                                <ApexPolar
                                    colors={
                                        [
                                            '#7c59e6',
                                            '#dfe2e6',
                                            '#eb6f33',
                                            '#01b8ff'

                                        ]
                                    }
                                    displayKey={true}
                                    labels={
                                        [
                                            'Occupied Slots',
                                            'Available Space',
                                            'Reserved Space',
                                            'Slots Occupied by Clamps'

                                        ]
                                    }
                                    values={
                                        [
                                            transaction.used_slots.total * 1,
                                            transaction.available_slots.total * 1,
                                            transaction.reserved.total * 1,
                                            transaction.clamp_occupied_slots.total * 1


                                            // 42, 47, 52
                                        ]
                                    }
                                />

                                <p className="mt-n5 mb-1 tx-inverse">Available Space</p>

                                <h4 className="mb-1">
                                    {transaction.available_slots.total * 1 + '/' + transaction.total_slots.total * 1}<span className="minor-tag">{transaction.total_slots.total * 1 > 1 ? ' Slots' : 'Slot'}</span>
                                </h4>

                                <span className="text-muted tx-12">
                                    <i className="fas fa-caret-up mr-1 text-success"></i>{transaction.net_availablity} % Availability
                                </span>
                            </>
                        }

                        {!transaction && <ActivityLoader />}

                    </div>


                </div>

            </div>
        </div>
    )
}



export default ReservationChart
