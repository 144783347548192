import React from 'react';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import CompanyHeader from '../Config/CompanyHeader';


class PopChequePaymentReceipt extends React.Component {

    state={
       receipts:this.props.record
    }
    

	 
	print = () => {
		functions.printDoc('receipt_doc');
	}

	
   
  render(){
	  const record=this.state.receipts;
      return (
        <div className="modal" id="modal_cheque_receipt">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Cheque Receipt</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
               
            
                <div className="row">
						{record && <div className="col-lg-12 col-md-12">
							<div className="card custom-card">
								<div className="card-body" id="receipt_doc">
									<div className="d-lg-flex">
                                        <h2 className="card-title mb-1">
                                            {record.cheque_info.bank.bank_name}<br/>
											{' Cheque No. ' + record.cheque_info.cheque_number}<br/>
                                            {"RECEIPT"}
                                        </h2>
										<div className="ml-auto">
                                            <p className="mb-1"><span className="font-weight-bold">Receipt Date :</span> {record.cheque_info.created_at.short_date}</p>
										</div>
									</div>
									<hr className="mg-b-40"/>
									<div className="row">
										<div className="col-lg-6">
											<CompanyHeader/>
										</div>
										<div className="col-lg-6 text-right">
											<p className="h3">Payment From:</p>
											<address>
                                                {record.cheque_info.depositor_name}
											<br/>
											{record.cheque_info.depositor_contact}
											<br/>
											</address>
										</div>
									</div>
									<div className="table-responsive mg-t-40">
										<table className="table table-invoice table-bordered">
											<thead>
												<tr>
													<th className="wd-5p">#NO.</th>
													<th className="wd-25p">Vehicle</th>
                                                    <th className="wd-25p">Invoice No.</th>
                                                    <th className="wd-25p">Amount Paid.</th>
													<th className="wd-25p">Bal. After</th>
												</tr>
											</thead>
											<tbody>

												{record.payment_records.map((item, key)=>
                                                    <tr key={key}>
                                                    <td>{key+1}</td>
                                                    <td className="tx-12">{item.invoice.vehicle.plate_number}</td>
													<td>{item.invoice.invoice_id_c}</td>
													<td>{item.amount.amount_c}</td>
													<td>{item.balance_after.amount_c}</td>
												</tr>)}
												
												<tr>
													<td className="valign-middle" colSpan="2" rowspan="3">
														<div className="invoice-notes">
															
															<label className="main-content-label tx-13">CASHIER SIGN.</label>
															
														</div>
													</td>
													<td className="tx-right"><b>Cheque Amount</b></td>
                                                    <td className="tx-right" colSpan="2">UGX.{record.cheque_info.amount.amount_c}</td>
												</tr>
												<tr>
													<td className="tx-right">Total Paid</td>
													<td className="tx-right" colSpan="2">UGX.{record.total.amount_c}</td>
												</tr>
												
												<tr>
													<td className="tx-right">&nbsp;</td>
													<td className="tx-right" colSpan="2">
														&nbsp;
													</td>
												</tr>
												
											</tbody>
										</table>
									</div>
								</div>
								
                                
                                <div className="card-footer text-right">
                                   
									{/* <button type="button" className="btn ripple btn-secondary mb-1"><i className="fe fe-send mr-1"></i> Send Invoice</button> */}
									<button type="button"
									onClick={this.print} 
									className="btn ripple btn-info mb-1"><i className="fe fe-printer mr-1"></i> Print</button>
								</div>
							</div>
						</div>}

                        {!record && <ActivityLoader/>}
					</div>




            
            </div>
          
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopChequePaymentReceipt;