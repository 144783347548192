import React from 'react'
import { Link } from 'react-router-dom'

class SuperCard extends React.Component {

    render() {
        return (
            <div className={this.props.className}>
                <Link to={this.props.href}>
                    <div className="card custom-card pinned-card">
                        <div className="card-body">
                            <div className='d-flex ml-1 mt-3 mb-0'>
                                <div className={this.props.color}>
                                    <i className={this.props.icon}></i>
                                </div>
                                <div className="ml-2">
                                    <p className="mb-2 text-muted">{this.props.title}</p>
                                </div>
                            </div>

                            <div className="text-right">
                                <h3 className="mb-0">{this.props.value}</h3>
                            </div>                          
                        </div>
                     
                    </div>
                </Link>
            </div>
        )
    }

}

export default SuperCard
