import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import SpaceAvailabilityCard from '../../Components/Location/SpaceAvailabilityCard'
import { Link } from 'react-router-dom'

class ParkingSpace extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        currentPage: 1,
        LocationList: false,
        metaData: false,
        activeTransactionId: false,
        toast: false,
        selectedRecord: false,
        dialogueNewAssignment: false,
        dialogueDelete: false,
        filterDescription: "List of parking reservation invoices",
        pagination: false,
        roleId: false,
        invoice: false,
        dialogueNewOrganisation: false,
        showOrganisations: true,
        spaceAvailability: false

    }

    componentDidMount() {
        this.listLocations();
    }


    listLocations = async () => {
        const server_response = await ajax.listLocations();
        if (server_response.status === "OK") {
            this.setState({
                LocationList: server_response.details.content
            })
        } else {
            this.setState({
                LocationList: "404"
            })
        }

    }




    render() {
        const LocationList = this.state.LocationList
        return (
            <div className="page">

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Available Space"
                            previousPageTitle="Parking Space"
                            // options={{
                            //     "customOptions":<this.customHeaderOptions/>
                            // }}
                            // buttons={<this.buttons/>}
                            back="/operations"
                        />

                        <div className="row">
                            <div className="col-sm-12 col-xl-8 col-lg-8">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Parking Locations"
                                            subtitle="List of parking locations with their respective parking space availability"
                                        />

                                        <div className=" mt-3 scroll-widget border-top overflow-auto">
                                            <div className="table-responsive">
                                                <table className="table text-nowrap mb-0 table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th>Location</th>
                                                            <th>Street</th>
                                                            <th>Total</th>
                                                            <th>Reserved</th>
                                                            <th>Parked</th>
                                                            <th>Occupied by <br />Clamped Vehicles</th>
                                                            <th>Available</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {LocationList && LocationList !== "404" &&
                                                            LocationList.map((item, key) =>
                                                                <tr
                                                                    id={"loc_" + item.location_id}
                                                                    key={key}
                                                                    className={`${this.state.locationId === item.location_id ? 'flag-row' : ''}`}
                                                                >
                                                                    <td>{key + 1}</td>
                                                                    <td>{item.location_name}</td>
                                                                    <td>
                                                                        <Link to={
                                                                            {
                                                                                pathname: `/available-space/street-occupancy/${item.street.street_name}`,
                                                                                state: { streetId: item.street.street_id }
                                                                            }}>{item.street.street_name}
                                                                        </Link>

                                                                    </td>
                                                                    <td>{item.total_slots.total_c}</td>
                                                                    <td>{item.reserved_slots.total_c}</td>
                                                                    <td>{item.active_slots.total_c}</td>
                                                                    <td>{item.clamps_occupied_slots.total_c}</td>

                                                                    <td>{item.available_parking.total_c}</td>
                                                                </tr>
                                                            )}


                                                    </tbody>
                                                </table>

                                                {!this.state.LocationList && <ActivityLoader />}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-xl-4 col-lg-4">
                                <SpaceAvailabilityCard />

                            </div>


                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>

        );

    }
}

export default ParkingSpace
