import React, { useContext } from "react"
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar";
import FilterTextInput from "../Common/Filter/FilterTextInput";
import FilterSelectInput from "../Common/Filter/FilterSelectInput";
import SupervisorContext from "../../Context/SupervisorContext";
import ConfigurationContext from "../../Context/ConfigurationContext";
import SupervisorReportContext from "../../Context/SupervisorReportContext";

const SupervisorReportFilter=(props)=>{

    const { supervisorList} = useContext(SupervisorContext)
    const {filterData, resetData} = useContext(SupervisorReportContext);
    const {locationList} = useContext(ConfigurationContext);

    const optionLocations = [];
    if(Array.isArray(locationList)){
        locationList.forEach(element => {
            optionLocations.push({title:element.location_name + " - " + element.street.street_name, value:element.location_id})
        });
    }

    const optionsupervisorList = [];
    if(Array.isArray(supervisorList)){
        supervisorList.forEach(element=>{
            optionsupervisorList.push({title:element.full_name, value:element.user_id})
        })
    }



    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Scan Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Scan Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

          (onSet, key, currentValues)=> <FilterSelectInput 
          key={key}
          label="Supervisor Name"
          options={optionsupervisorList}
          onChange={onSet}
          name="supervisor"
          currentValues={currentValues}
        />,


        ],
        names:["dateFrom", "dateTo", "supervisor"]
    }}
/>

}

export default SupervisorReportFilter