import React from 'react'
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import Chart from "react-apexcharts";
//import {Bar} from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import TableHeader from '../Common/TableHeader';
import { FinanceConsumer } from '../../Context/FinanceContext';
import ApexLine from '../BarChart/ApexLine';

// const chartData = {
//   labels: [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May'
//   ],
//   datasets: [

//     {
//       label: 'Wrong Parking',
//       fill: true,
//       lineTension: 0.5,
//       backgroundColor: 'rgba(113, 76, 190, 0.5)',
//       borderColor: 'rgba(113, 76, 190, 0.9)',
//       borderWidth: 2,
//       data: [65, 59, 80, 81, 56]
//     },

//     {
//       label: 'Towing',
//       fill: true,
//       lineTension: 0.5,
//       backgroundColor: 'rgba(235, 111, 51, 0.8)',
//       borderColor: 'rgba(235, 111, 51, 0.8)',
//       borderWidth: 2,
//       data: [35, 29, 40, 31, 16]
//     },

//     {
//       label: 'Session',
//       fill: true,
//       lineTension: 0.5,
//       backgroundColor: 'rgba(21, 197, 95, 0.8)',
//       borderColor: 'rgba(21, 197, 95, 0.6)',
//       borderWidth: 2,
//       data: [45, 89, 20, 61, 76]
//     }

//   ]
// }
class SalesGraph extends React.Component {

  state = {
    userId: functions.sessionGuard(),

    // borderColors: ['rgba(113, 76, 190, 0.9)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.6)'],
    // backgroundColors: ['rgba(113, 76, 190, 0.5)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.8)'],

    // series: [{
    //   name: 'series1',
    //   data: [31, 40, 28, 51, 42, 109, 100, 11, 32, 45, 32, 34]
    // }, {
    //   name: 'series2',
    //   data: [11, 32, 45, 32, 34, 52, 41, 31, 40, 28, 51, 42]
    // }],

    // options: {
    //   chart: {
    //     height: 250,
    //     type: 'area'
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   stroke: {
    //     curve: 'smooth'
    //   },
    //   xaxis: {
    //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    //   },
    // },
  }


  // addGraphData = () => {

  // }


  render() {
    return (
      <FinanceConsumer>
        {props => {
          const chartData = props.salesChartData;
          return (

            <div className="card custom-card ">
              <div className="card-body map-card">

                <TableHeader
                  title="Overview of Collected Revenue"
                  subtitle="Revenue collection statistics for parking, towing and contraventions."
                />

                {/* <div className="user-manager mt-3 scroll-widget border-top overflow-auto"> */}
                <div className="mt-3 border-top">
                  <div className="mt-2">

                    {chartData && chartData !== "404" &&
                      <>
                        {/* <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="line"
                        // width={500}
                        height={320}
                      /> */}

                        {/* <ApexLine /> */}

                        <Line
                          data={chartData}
                          height={147}
                          options={{

                            title: {
                              display: false,
                              //text:'Graph title',
                              // fontSize: 20
                            },
                            legend: {
                              display: true,
                              position: 'top'
                            },
                            scales: {
                              xAxes: [{
                                gridLines: {
                                  display: false,
                                  drawBorder: false
                                },
                              }],

                              yAxes: [{
                                gridLines: {
                                  // display: false,
                                  drawBorder: false

                                }
                              }]

                            }
                          }}
                        />
                      </>
                    }

                    {!chartData && <ActivityLoader />}

                  </div>
                </div>
              </div>
            </div>


          )

        }}

      </FinanceConsumer>
    )
  }

}

export default SalesGraph
