import React, { useContext, useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import { detectTable, restoreTable } from '../utils/script/customTables';
import AuthContext from './AuthContext';

const ParkingsContext = React.createContext();

export const ParkingsConsumer = ParkingsContext.Consumer;

export const ParkingsProvider = (props) => {

    const [parkingTransactions, setParkingTransactions] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [status, setStatus] = useState("");
    const { secureCall } = useContext(AuthContext);
    const [title, setTitle] = useState('');

    useEffect(()=>{
        if(userId){
            secureCall(getParkingsTransactions, "R-SESSION-02")
        }
    }, [data])


    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])

    useEffect(()=>{
        setCurrentPage(1)
        const newData={
            ...data,
            "status":status,
        }
        setData(newData)
    }, [status])



    const getParkingsTransactions=async()=>{
        setLoading(true)
        setParkingTransactions(false);
        setMetaData(false)
        const server_response = await ajax.filterSessions(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setParkingTransactions(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
            setTitle(server_response.message);
        }else{
            setParkingTransactions("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajax.filterSessions(newData);

        setLoading(false);
           
        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setParkingTransactions(parkingTransactions.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
        return getFreshObject();
    }

    const filterData=(options)=>{
        setCurrentPage(1)
        setData({
            "conductor_id": options.conductorId,
            "plate_number": options.plateNumber,
            "date_from": options.dateFrom,
            "date_to": options.dateTo,
            "page": 1,
            "status": status,
            "branch_id":options.branch,
            "location_id":options.location,
            "street_id":options.street
        })
        
    }


    const getFreshObject=()=>{

        return [{
                  "street": ""
                },
                {
                  "location": ""
                },
                {
                  "branch": ""
                },
                {
                  "dateFrom": ""
                },
                {
                  "dateTo": ""
                },
                {
                  "plateNumber": ""
                },
                {
                  "conductorId": ""
                }
              ]
    }

  
    return (
        <ParkingsContext.Provider value={{
            parkingTransactions,
            metaData,
            loading,
            title,
            getFreshObject,
            setStatus,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getParkingsTransactions
        }}
        >
            {props.children}
        </ParkingsContext.Provider>
    );
}


export default ParkingsContext;
