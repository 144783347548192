import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import TitleValueCard from '../../Components/Common/TitleValueCard'
import ConfigMenu from '../../Components/ConfigMenu'
import { ConfigurationConsumer } from '../../Context/ConfigurationContext'
import { AuthConsumer } from '../../Context/AuthContext'
import PageContainer from '../../Components/Layout/PageContainer'

class SystemSettings extends React.Component {

  StatisticTiles = () => {

    return (
      <ConfigurationConsumer>
        {props => {

          return (
            <AuthConsumer>
              {userProps => {
                const permissionList = userProps.permissionList;
                const roleList = userProps.roleList;
                return (
                  <div className="row">
                    {functions.findInPermission(permissionList, 'OPERATIONS-00') &&
                      <>
                        <TitleValueCard href="/config/branches" title="Total Branches" value={props.totalBranches} icon="fas fa-code-branch" color="icon-service rounded-circle bg-primary-transparent text-primary" />
                        <TitleValueCard href="/config/streets" title="Total Streets" value={props.totalStreets} icon="fas fa-street-view" color="icon-service bg-info-transparent rounded-circle text-info" />
                        <TitleValueCard href="/config/locations" title="Total Locations" value={props.totalLocations} icon="fas fa-location-arrow" color="icon-service bg-success-transparent rounded-circle text-success" />
                        <TitleValueCard href="/config/devices" title="Total Devices" value={props.totalDevices} icon="fas fa-mobile-alt" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />
                        <TitleValueCard href="/config/clamp_tools" title="Total Clamp Tools" value={props.totalClampTools} icon="fas fa-magnet" color="icon-service rounded-circle bg-warning-transparent text-warning" />
                      </>
                    }
                    {(functions.findInPermission(permissionList, 'R-CONFIG-CLAMP-THRESHOLD-02') || roleList === "CASH") &&
                      <TitleValueCard href="/config/clamping/thresholdfee" title="Clamp Threshold" value={props.clampThreshold} icon="fas fa-fire" color="icon-service bg-secondary-transparent rounded-circle text-secondary" />
                    }

                    {functions.findInPermission(permissionList, 'OPERATIONS-00') &&
                      <>
                        <TitleValueCard href="/config/clampingfee" title="Clamping Fee" value={props.clampingFee} icon="fas fa-parking" color="icon-service bg-success-transparent rounded-circle text-success" />

                        {/* <TitleValueCard href="/config/towingfee" title="Towing Fee" value={props.towingFee} icon="fas fa-parking" color="icon-service bg-success-transparent rounded-circle text-success" /> */}
                        <TitleValueCard href="/config/holiday" title="Public Holidays" value={props.totalHolidays} icon="fas fa-gift" color="icon-service rounded-circle bg-info-transparent text-info" />


                      </>
                    }



                    {(functions.findInPermission(permissionList, 'R-CONFIG-SURCHAGE-02') || roleList === "CASH") &&
                      <TitleValueCard href="/config/surcharge" title="Surcharge" value={props.surcharge} icon="fas fa-money-bill-alt" color="icon-service rounded-circle bg-primary-transparent text-primary" />
                    }

                    <TitleValueCard href="/config/roles" title="Total Roles" value={props.totalRoles} icon="fa fa-tasks" color="icon-service bg-secondary-transparent rounded-circle text-secondary" />
                    <TitleValueCard href="/config/permissions" title="Total Permissions" value={props.totalPermissions} icon="fas fa-lock-open" color="icon-service rounded-circle bg-warning-transparent text-warning" />
                    <TitleValueCard href="/config/notifications" title="Notifications" value={props.totalNotifications} icon="fas fa-bell" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />

                    {functions.findInPermission(permissionList, 'R-CONFIG-PAY-MODE-02') &&
                      <TitleValueCard href="/config/payment_modes" title="Payment Modes" value={props.totalPaymentModes} icon="fas fa-hand-holding-usd" color="icon-service bg-primary-transparent rounded-circle text-primary" />
                    }

                    {/* {functions.findInPermission(permissionList, 'OPERATIONS-00') &&
                      <TitleValueCard href="/config/towing-stations" title="Towing Stations" value={props.totalTowStations} icon="fas fa-parking" color="icon-service bg-info-transparent rounded-circle text-info" />
                    } */}
                    {functions.findInPermission(permissionList, 'R-VEHICLES-02') &&
                      <TitleValueCard href="/vehicles/all-vehicles" title="Registered vehicles" value={props.statistics} icon="fas fa-car" color="icon-service bg-success-transparent rounded-circle text-success" />
                    }

                    <TitleValueCard href="/config/sticker-types" title="Sticker Types" value={props.totalStickerTypes} icon="fas fa-barcode" color="icon-service rounded-circle bg-info-transparent text-info" />

                    {functions.findInPermission(permissionList, 'R-VEHICLE-TYPES-02') &&
                      <TitleValueCard href="/config/car-types" title="Vehicle Types" value={props.totalVehicleTypes} icon="fas fa-car" color="icon-service rounded-circle bg-primary-transparent text-primary" />
                    }

                    <TitleValueCard href="/config/workdays" title="Working Days" value="Daily" icon="fas fa-barcode" color="icon-service rounded-circle bg-info-transparent text-info" />

                    <TitleValueCard href="/config/workinghours" title="Working Hours" value="8 am - 6 pm" icon="fas fa-barcode" color="icon-service rounded-circle bg-info-transparent text-info" />

                    <TitleValueCard href="/config/migration" title="Migration" value={props.totalStickerTypes} icon="fas fa-barcode" color="icon-service rounded-circle bg-info-transparent text-info" />

                    {functions.findInPermission(permissionList, 'R-CONFIG-BANKS-02') &&
                      <TitleValueCard href="/config/bankingclient" title="Banking Clients" value={props.totalBanks} icon="fas fa-university" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />
                    }
                  </div>

                )
              }}

            </AuthConsumer>
          )
        }}

      </ConfigurationConsumer>
    )

  }

  render() {


    return (
      <PageContainer>
            <PageHeader
              title="System Configurations"
              previousPageTitle="Dashboard"
              back="/config"
            />
            <div className="row">
              <div className="col-xl-9 col-lg-12">
                    <div className="
                    allView-config-cards px-1">
                      <this.StatisticTiles />
                    </div>
              </div>
              <ConfigMenu />
            </div>
        </PageContainer>
    );
  }
}

export default SystemSettings
