import React, { useEffect, useState } from 'react';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxAuth from '../utils/remote/ajaxAuth';
import ajaxLog from '../utils/remote/ajaxLog';

const AuthContext = React.createContext();

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider=(props)=>{

    const [permissionList, setPermissionList] = useState(false);
    const [loggedIn, setLoggedIn] = useState(functions.sessionGuard());
    const [refreshRate, setRefreshRate] = useState(1);
    const [loading, setLoading] = useState(false);
    const [viewAsActive, setViewAsActive] = useState(false)

    useEffect(()=>{

        if(loggedIn){
            if(!viewAsActive){
                getUserAccess();
            }
            logOnline();
            setTimeout(() => {
                setRefreshRate(functions.timestamp())
            }, dictionary._refreshRate);
        }

       
    }, [refreshRate])

    const getUserAccess=async()=>{
        const token=functions.authToken();
        if(token){
  
              const server_response = await ajaxAuth.getUserAccessCodes(token.user_id);
              if(server_response.status==="OK")
              {
                  setPermissionList(server_response.details.content);
                    
              }else{
                   setPermissionList("404")
              }
          }
      }


      const viewAsRole=async(roleId, callBack)=>{
        setLoading(true)
        // setPermissionList(false);
        const server_response = await ajaxAuth.getRoleAccessCodes(roleId);
        setLoading(false)
        if(server_response.status === "OK"){
            setPermissionList(server_response.details.content);
            setViewAsActive(true)
        }else{
            setPermissionList("404")
            setViewAsActive(false)
        }

      }


      const secureCall=(call, code)=>{
          if(functions.findInPermission(permissionList, code))
          {
            call();
          }else{
              return false
          }
      }


      const accessGranted=(code)=>{
        if(functions.findInPermission(permissionList, code))
        {
          return true
        }else{
            return false
        }
    }


      const logOnline=async()=>{
        const token=functions.authToken();
        if(token){
  
              const server_response = await ajaxLog.logOnline();
              if(server_response.status==="OK")
              {
                 // console.log("Tracking online")
                    
              }else{
                  if(server_response.message=="SUSP001")
                  {
                    // console.log("Log this user out")
                    localStorage.removeItem('parking@user');
                    window.location.replace('/login');
                  }
              }
          }
      }


        return (
           <AuthContext.Provider value={{
               permissionList,
               loggedIn,
               loading,
               setLoading,
               viewAsRole,
               secureCall,
               accessGranted,
            }}
            >
               {props.children}
           </AuthContext.Provider>
        );
    }


export default AuthContext;