import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../../../utils/ajax';
import dictionary from '../../../../utils/dictionary';
import functions from '../../../../utils/functions';
import ajaxPayment from '../../../../utils/remote/ajaxPayment';
import ActivityLoader from '../../../Common/ActivityLoader';
import AlertError from '../../../Common/AlertError';
import AlertSuccess from '../../../Common/AlertSuccess';
import InitialAvatar from '../../../Common/InitialAvatar';
import SelectSearch from '../../../Common/SelectSearch';
import TextInput from '../../../Common/TextInput';

class PopChequePayment extends React.Component {

    state = {
        amount:'',
        depositorContact: '',
        depositorName: '',
        chequeNumber:'',
        chequeDate:'',
        info: '',
        bankList:false,
        bankId:false,
    }

    componentDidMount() {
      
        this.getBanks();
       
    }
    

    getBanks=async()=>{
        const server_response=await ajax.getBankingClients();
        if(server_response.status==="OK")
        {
            this.setState({
                bankList:server_response.details.content
            })
        }
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }

    onChangeBank = (selectedOption) => {
        this.setState({
            bankId: selectedOption.value
        })
    }

   

    onChangeChequeNo =(event)=>{
        this.setState({ chequeNumber: event.target.value })

    }

    onChangeChequeDate =(event)=>{
        this.setState({ chequeDate: event.target.value })

    }
  
  

    onConfirm = async(event) => {
        event.preventDefault();
        const {depositorContact, depositorName, chequeNumber, chequeDate, bankId, amount} = this.state
        if (depositorContact.length > 0 && 
            depositorName.length > 0 && 
            chequeNumber.length>0 && 
            chequeDate.length>0 && 
            bankId.length>0 &&
            amount>0) {

                if(amount > this.props.invoice.balance.amount)
                {
                    this.setState({
                        info: <AlertError message={dictionary._amountBound} />
                    })
                    return false;
                }    
                const server_response = await ajaxPayment.depositReservationCheque(
                    depositorName,
                    depositorContact,
                    amount,
                    chequeNumber,
                    chequeDate,
                    bankId,
                    this.props.invoice.invoice.id
                )
        
                if (server_response.status === "OK") {
                    //load receipt
                    functions.closePopup("modal_cheque_payment");
                    this.props.onConfirm(<AlertSuccess message={server_response.details.message} />)
                   
                } else {
                    
                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    })
                }
            

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
            
        }
    }


    



    render() {
        const bankList = this.state.bankList;
        const optionsBank = [];
        if (bankList && bankList !== "404") {
            bankList.map((item, key) =>
                optionsBank.push({ label: item.bank_name, value: item.bank_id })
            )
        }

        return (
            <>
                <div className="modal" id="modal_cheque_payment">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New Cheque Payment</h6>
                                <span>Amount Due: {this.props.invoice.balance.amount_c}</span>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <div className="row">

                                <div className="col-4">
                                     <div className="justify-content-center text-center mb-3 d-flex">
                                        <InitialAvatar size={2} value={<i className="fas fa-briefcase"></i>}/>
                                    </div>

                                    <div className="activity-block mr-3 scroll-widget border-top overflow-auto">
										<ul className="task-list">
										    <li><b>{ this.props.invoice.invoice.organisation.title }</b></li>
                                            <li style={{fontSize:'12px'}}>{ this.props.invoice.invoice.organisation.location_address }</li>
                                            <li style={{fontSize:'12px'}}>{ this.props.invoice.invoice.organisation.contact }</li>

										</ul>


									</div>




                                </div>



                                <div className="col-8">

                                <div className="justify-content-center text-center mb-3 d-flex">
                                        <InitialAvatar size={2} value={<i className="fas fa-edit"></i>}/>
                                </div>
                                <form method="post" onSubmit={this.onConfirm}>

                                <SelectSearch
                                    label="Select Bank"
                                    onChange={() => this.onChangeBank}
                                    options={optionsBank}
                                    placeholder={"Select Bank of origin..."}
                                />


                                    <TextInput
                                          label="Cheque Number"
                                          value={this.state.chequeNumber}
                                          type="number"
                                          onChange={() => this.onChangeChequeNo}
                                    />

                                    <TextInput
                                        label="Cheque Amount"
                                        type="number"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                                    <TextInput
                                          label="Cheque Date"
                                          value={this.state.chequeDate}
                                          type="date"
                                          onChange={() => this.onChangeChequeDate}
                                    />
                                     

                                    <TextInput
                                        label="Depositor's Name"
                                        type="text"
                                        onChange={() => this.onChangeDepositorName}
                                        value={this.state.depositorName}
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                             
                                </form>
                                </div>
                               
                                </div>



                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Request Cheque Verification</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }
}

export default PopChequePayment;
