import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ajaxPayment from '../../utils/remote/ajaxPayment';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import BlankAlert from '../Common/BlankAlert';
import InitialAvatar from '../Common/InitialAvatar';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';
import PopStickerOptions from '../Finance/Cashier/PopStickerOptions';
import PopVehicleListing from '../Vehicle/VehicleListing/PopVehicleListing';
import PopPaymentSummary from './PopPaymentSummary';

class CashPaymentForm extends React.Component {

    state = {
        amount:0,
        depositorContact: '',
        depositorName: '',
        chequeNumber:'',
        chequeDate:'',
        info: <BlankAlert/>,
        whiteList:this.props.whiteList,
        bankList:false,
        bankId:false,
        vehicleList:[],
        plateNumber:'',
        searchList:false,
        externalList:true,
        selectedVehicleInfo:false,
        mdlVehicleListing:false,
        loading:false,
        mdlStickerOptions:false,
        loadingSubmit:false,
        paymentView:false
    }

    componentDidMount() {
        const vehicle=this.props.vehicleInfo
        const amount=vehicle.sticker_options.recommended?vehicle.sticker_options.recommended.amount.amount : 0
        const sticker_type_id=vehicle.sticker_options.recommended?vehicle.sticker_options.recommended.sticker_type_id : 0
        const sticker_ref_name=vehicle.sticker_options.recommended?vehicle.sticker_options.recommended.ref_name : "Not Provided"
        const amount_c=vehicle.sticker_options.recommended?vehicle.sticker_options.recommended.amount.amount_c : 0

        this.setState({
            amount:amount,
            vehicleList:[{
                plate_number:vehicle.plate_number,
                amount:amount,
                amount_c:amount,
                sticker_type_id:sticker_type_id,
                sticker_ref_name:sticker_ref_name
            }]
        })
      
        if(this.props.listId)
        {
            this.extractVehicleList(this.props.listId)
        }
    }
    

   
    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }

   
    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value }, () => this.searchVehicle())
    }

  
  

    onConfirm = async(event) => {
        event.preventDefault();
        const {depositorContact, depositorName, amount} = this.state
        
        if (depositorContact.length > 0 && 
            depositorName.length > 0 && 
            amount>0) {

                const vehicleList = [];
                this.state.vehicleList.map((item, key) =>
                 vehicleList.push(item.plate_number)
                )
                
                this.setState({
                    loadingSubmit:true
                })
                
                const server_response = await ajaxPayment.payStickerCash(
                    depositorName,
                    depositorContact,
                    amount,
                    this.state.vehicleList
                )
                this.setState({
                    loadingSubmit:false
                })
                
        
                if (server_response.status === "OK") {

                    this.setState({
                        info: <AlertSuccess message={server_response.details.message} />,
                        chequeDate:"",
                        depositorContact:"",
                        depositorName:""
                    })

                    this.handleReceiptView(server_response.details.content)
                  
                } else {
                    
                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    })
                }
            

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
            
        }
    }


    

    onClickPlateNumber = (item) => {
        this.setState({
            plateNumber:item.plate_number,
            selectedVehicleInfo:item
        })
       
    }

    onAddPlateNumber=()=>{
        const item=this.state.selectedVehicleInfo;
        if(!item)
        {
            this.setState({
                info:<AlertError message="Enter vehicle plate number to add to list"/>
            })
        }else{
            
            this.setState({ plateNumber:item.plate_number }, ()=>{
                //get current vehicles
                const vehicleList=this.state.vehicleList
                for(var i=0; i<vehicleList.length; i++)
                {
                    if(vehicleList[i].plate_number==item.plate_number)
                    {
                        this.setState({
                            info: <AlertError message={item.plate_number + ' ' + ' has already been added'} />
                        })
                        return false;
                    }
                }

                const amount=item.sticker_options.recommended?item.sticker_options.recommended.amount.amount : 0
                const sticker_type_id=item.sticker_options.recommended?item.sticker_options.recommended.sticker_type_id : 0
                const sticker_ref_name=item.sticker_options.recommended?item.sticker_options.recommended.ref_name : "Not Provided"
                const amount_c=item.sticker_options.recommended?item.sticker_options.recommended.amount.amount_c : 0

                vehicleList.push({
                        plate_number:item.plate_number,
                        amount:amount,
                        amount_c:amount,
                        sticker_type_id:sticker_type_id,
                        sticker_ref_name:sticker_ref_name
        
                    });

                this.setState({
                    vehicleList:vehicleList,
                    amount:this.state.amount*1 + amount*1
                })
            })
        }
    }




    searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(this.state.plateNumber);
        if (server_response.status === "OK") {
            this.setState({
                searchList: server_response.details.content
            })
        } else {
            this.setState({
                searchList: false
            })
        }
    }


    onClickRemovePlate=(item)=>{
        const vehicleList=this.state.vehicleList
        if(item.plate_number==this.props.vehicleInfo.plate_number){
            this.setState({
                        info: <AlertError message={"Default vehicle cannot be removed. Please record this cheque using one of the paying vehicles"} />
                    })
            return false;
         }
        for(var i=0; i<vehicleList.length; i++)
        {
            if((vehicleList[i].plate_number==item.plate_number))
            {
                vehicleList.splice(i, 1);
            }
        }

        this.setState({
            vehicleList:vehicleList,
            amount:this.state.amount*1-item.amount*1
        })

    }


    extractVehicleList=async(list_id)=>{
        this.setState({
            externalList:false,
            loading:true
        })
        const server_response = await ajax.extractPaymentVehicleListing(list_id);
        if(server_response.status=="OK")
        {   
            const vehicleList=this.state.vehicleList
            const externalList=server_response.details.content;
            externalList.map((item, key)=>{
                for(var i=0; i<vehicleList.length; i++)
                {
                    if(vehicleList[i].plate_number==item.plate_number)
                    {
                        // this.setState({
                        //     info: <AlertError message={item.plate_number + ' ' + ' has already been added'} />
                        // })
                        return false;
                    }
                }

                const amount=item.sticker_options.recommended?item.sticker_options.recommended.amount.amount : 0
                const sticker_type_id=item.sticker_options.recommended?item.sticker_options.recommended.sticker_type_id : 0
                const sticker_ref_name=item.sticker_options.recommended?item.sticker_options.recommended.ref_name : "Not Provided"
                const amount_c=item.sticker_options.recommended?item.sticker_options.recommended.amount.amount_c : 0

                vehicleList.push({
                        plate_number:item.plate_number,
                        amount:amount,
                        amount_c:amount,
                        sticker_type_id:sticker_type_id,
                        sticker_ref_name:sticker_ref_name
        
                    });
                this.setState({
                    vehicleList:vehicleList,
                    amount:this.state.amount*1+amount*1
                })
            })//ends map
        }

        this.setState({
            externalList:true,
            loading:false
        })
    }

    onSelectList=(list_id, callBack)=>{
        this.setState({
            loading:true
        }, ()=>{
            this.extractVehicleList(list_id);
            functions.closePopup('mdl_vlisting_import_cheque')

        })
    }


    onClickImportVehicles=()=>{
        //show listing
        this.setState({
            mdlVehicleListing:false
        }, ()=>{
            this.setState({
                mdlVehicleListing:<PopVehicleListing 
                                    id="mdl_vlisting_import_cheque" 
                                    onSelect={this.onSelectList}
                                    description="Select vehicle listing to import from"/>
            }, ()=>{
                functions.openPopup('mdl_vlisting_import_cheque')
            })
        })
    }



    onClickChangeSticker=(record)=>{
        this.setState({
            mdlStickerOptions:false,
            selectedVehicleInfo:record
        }, ()=>{
            this.setState({
                mdlStickerOptions:<PopStickerOptions 
                                    id="mdl_sticker_options" 
                                    onSelect={this.onChangeStickerType}
                                    title={"Change Sticker - " + record.plate_number}/>
            }, ()=>{
                functions.openPopup('mdl_sticker_options')
            })
        })
    }


    onChangeStickerType=(type)=>{
        functions.closePopup('mdl_sticker_options');
        const item=this.state.selectedVehicleInfo;
      
        //loop through the vehicle list
        const vehicleList=this.state.vehicleList
        var new_amount=0;
        for(var i=0; i<vehicleList.length; i++)
        {
            if((vehicleList[i].plate_number==item.plate_number))
            {
                vehicleList[i].amount=type.amount.amount;
                vehicleList[i].amount_c=type.amount.amount_c;
                vehicleList[i].sticker_ref_name=type.ref_name;
                vehicleList[i].sticker_type_id=type.sticker_type_id;
            }
            new_amount+=vehicleList[i].amount*1;
        }

        this.setState({
           // vehicleList:vehicleList,
            amount: new_amount
        })
    }





    handleReceiptView = (deposit_ref) => {
        this.setState({
            paymentView: false
        }, () => {
    
            this.setState({
                paymentView: <PopPaymentSummary deposit_ref={deposit_ref} />
            }, () => {
                functions.openPopup("modal_payment_summary");
            })
        })
    
    }
    


    render() {

        const bankList = this.state.bankList;
        const optionsBank = [];
        if (bankList && bankList !== "404") {
            bankList.map((item, key) =>
                optionsBank.push({ label: item.bank_name, value: item.bank_id })
            )
        }

        const plateList=this.state.vehicleList;
        const searchList = this.state.searchList;

        return (
            <>
             {this.state.info}
             {this.state.mdlVehicleListing}
             {this.state.mdlStickerOptions}
             {this.state.paymentView}
           <div className="row">
            		
                        <div className="col-lg-6">
							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<h6 className="card-title mb-0">Cash deposit details</h6>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<form method="post" onSubmit={this.onConfirm}>

                                
                                    <TextInput
                                        label="Amount Paid"
                                        type="number"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                            

                                    <TextInput
                                        label="Depositor's Name"
                                        type="text"
                                        onChange={() => this.onChangeDepositorName}
                                        value={this.state.depositorName}
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                                </form>




									</div>
									<div className="btn btn-list">
										<a href="#" onClick={this.onClickImportVehicles} className="btn ripple btn-primary"><i className="fas fa-capsules mr-1"></i>Import Vehicles</a>
										{!this.state.loadingSubmit && <a href="#" onClick={this.onConfirm} className="btn ripple btn-success"><i className="fa fa-check ml-1"></i> Confirm Payment</a>}
                                       
                                        {this.state.loadingSubmit && <a href="#" className="btn ripple btn-success">
                                            <ActivityLoader height={20} width={20} customClass="none" color="#FFFFFF"/>
                                         </a>}

                                    </div>
								</div>
							</div>
						</div>


                        <div className="col-lg-6">  
							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<h6 className="card-title mb-0">Vehicle List</h6>
								</div>
								<div className="card-body">
									<div className="table-responsive text-nowrap scroll-widget overflow-auto">
										{this.state.loading && <ActivityLoader/>}
                                        {!this.state.loading && <table className="table table-bordered">
											<thead>
												<tr>
													<th>No</th>
													<th>Plate No.</th>
													<th>Sticker Type</th>
													<th>Amount</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                                            {plateList.map((item, key)=>
												<tr key={key}>
													<td>{key+1}</td>
													<td>
														<span>{item.plate_number}</span>
													</td>
													<td className="wd-20">
                                                        <span>{item.sticker_ref_name}</span>
													</td>
													<td>
														<span className="font-weight-bold">{item.amount}</span>
													</td>
													<td>
														<a className="btn ripple btn-danger btn-sm text-white" 
                                                            data-toggle="tooltip" data-original-title="Remove"
                                                            title="Remove"
                                                            onClick={()=>this.onClickRemovePlate(item)}
                                                            >
                                                            <i className="fe fe-trash-2"></i>
                                                        </a>


                                                        <a className="btn ripple btn-primary btn-sm text-white ml-1" 
                                                            data-toggle="tooltip" data-original-title="Change Sticker"
                                                            title="Change Sticker"
                                                            onClick={()=>this.onClickChangeSticker(item)}
                                                            >
                                                            <i className="far fa-edit"></i>
                                                        </a>

													</td>
												</tr>)}
											
											</tbody>
										</table>}
									</div>
									<form className="text-left  product-cart mb-0">
										<div className="input-group">
											<input 
                                                className="productcart form-control" 
                                                type="text" 
                                                placeholder="Add Vehicle"
                                                onChange={this.onChangePlateNumber}
                                                value={this.state.plateNumber}/>
											<span className="input-group-btn">
												<button className="btn ripple btn-primary" type="button" onClick={this.onAddPlateNumber}>
													<span className="input-group-btn">Add</span>
												</button>
											</span>
										</div>


                                        
                              

                                    {searchList && searchList !== "404" && <div className="btn-list">
                                        {searchList.map((item, key) =>
                                            <button key={key}
                                                onClick={() => this.onClickPlateNumber(item)}
                                                type="button"
                                                className="btn ripple btn-light btn-sm">
                                                {item.plate_number}
                                            </button>)}
                                    </div>}



									</form>
								</div>
							</div>
						</div>
				
           
           </div>
           </>
        )


    }
}

export default CashPaymentForm;
