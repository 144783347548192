import React, { useEffect, useState, useContext } from "react";
import ajax from "../utils/ajax";
import dictionary from "../utils/dictionary";
import functions from "../utils/functions";
import ajaxPayment from "../utils/remote/ajaxPayment";

const CashierContext = React.createContext();

export const CashierConsumer = CashierContext.Consumer;


export const CashierProvider=(props)=>{

   const [dailySaleSummary, setDailySummary]=useState(false);
   const [cashierId, setCashierId]=useState(functions.sessionGuard())
   const [customVehicleList, setCustomVehicleList]=useState(false)
   const [plateNumber, setPlateNumber] = useState(false);
   const [balance, setBalance] = useState("...");
   const [intBalance, setIntBalance] = useState(0);

   const [refreshCounter, setRefreshCounter] = useState(0);
   const [paymentModes, setPaymentMode] = useState(false);

   const [salesListing, setSalesListing] = useState(false);
   const [cashierSummary, setCashierSummary] = useState(false);

   const [data, setData] = useState({"page":"1"});
   const [datedPaymentModes, setDatedPaymentModes] = useState(false);


  useEffect(()=>{
    if(plateNumber){
        getBalance();
    }
  }, [refreshCounter])

  
  useEffect(()=>{
    
        setTimeout(() => {
            setRefreshCounter(refreshCounter+1)
        }, dictionary._refreshRate);
     
       
    }, [refreshCounter])


   useEffect(()=>{

        props.auth.secureCall(fetchDailySummary, "CASHIER-00")
        props.auth.secureCall(getDatedPayments, "C-PAY-DATED-01")

     },[]
   )//componentDidMount

   useEffect(()=>{
        getCustomVehicleLists();
        getPaymentModes();
     },[]
   )


   useEffect(()=>{
        listSalesPerCashier();
   }, [data])


   useEffect(()=>{
        if(cashierId*1>0)
        {
            const newData={
                ...data,
                "cashier_id":cashierId,
            }
            getCashierSummary(newData)

        }
   }, [cashierId])


   const getBalance = async () => {
    const server_response = await ajax.getVehicleOutstandingBalance(plateNumber)
    if (server_response.status === "OK") {
        setBalance("UGX. " + server_response.details.content.amount_c);
        setIntBalance(server_response.details.content.amount*1)
    }
  }
   
   const fetchDailySummary = async () => {
    
        const server_response = await ajax.groupCashierDailyInvoiceTypes(cashierId);
        if (server_response.status === "OK") {
           setDailySummary(server_response.details.content);
        }else{
            setDailySummary("404")
        }
      }


    const  getCustomVehicleLists=async()=>{

        const server_response = await ajax.getUserRecentVehicleListing(cashierId)
        if (server_response.status === "OK") {
            setCustomVehicleList(server_response.details.content);
        }else{
            setCustomVehicleList("404")
        }
    }

    
    const getPaymentModes = async () => {
        const server_response = await ajax.getOfficePaymentModes()
        if (server_response.status === "OK") {
            setPaymentMode(server_response.details.content)
        }else{
            setPaymentMode("404")
        }
    }





    const resetData=()=>{
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        const data={
            "date_from":options.dateFrom,
            "date_to":options.dateTo
        }
        setData(data)
        
    }


   const listSalesPerCashier = async () => {
        setSalesListing(false)
        const server_response = await ajaxPayment.listDailySalesPerCashier(data);
         if (server_response.status === "OK") {
            setSalesListing(server_response.details.content)
         } else {
            setSalesListing("404")
         }
     }

     const refresh=()=>{
        setCashierId(0);
        listSalesPerCashier();
     }



     const getCashierSummary = async (cData) => {
        setCashierSummary(false)
        const server_response = await ajaxPayment.getCashierDailysummary(cData);
         if (server_response.status === "OK") {
            setCashierSummary(server_response.details.content)
         } else {
            setCashierSummary("404")
         }
     }




     const getDatedPayments = async () => {
        const server_response = await ajaxPayment.fetchDatedPaymentModes();
         if (server_response.status === "OK") {
            setDatedPaymentModes(server_response.details.content)
         } else {
            setDatedPaymentModes("404")
         }
     }


    return (
        <CashierContext.Provider value={{
            setPlateNumber,
            refresh,
            filterData,
            resetData,
            setCashierId,
            dailySaleSummary,
            customVehicleList,
            plateNumber,
            intBalance,
            balance,
            paymentModes,
            salesListing,
            cashierSummary,
            datedPaymentModes
         }}
         >
            {props.children}
        </CashierContext.Provider>
     );


}

export default CashierContext;