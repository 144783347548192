import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import ReservationPaymentOptionsTab from '../../Components/Reservation/ReservationPaymentOptionsTab'

class NewReservationPayment extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        invoice:false,
        invoiceId:''
       
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
               invoiceId: this.props.location.state.invoiceId
            }, () => this.getInvoice())

        }
    }

  
    getInvoice = async () => {
        const server_response = await ajax.getReservation(this.state.invoiceId)
     
        if (server_response.status === "OK") {
            this.setState({
                invoice: server_response.details.content,
            })
        } else {
            this.setState({
                invoice: '404'
            })
        }
    }







    render() {
        const invoice = this.state.invoice

        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="New Reservation Payment"
                            previousPageTitle="Reservation"
                            back="/parking-space/reservation"
                        />


                     
        {this.state.invoice && this.state.invoice!=="404" && <>           
            <ReservationPaymentOptionsTab
                title={this.state.invoice.organisation.title}
                invoiceId={this.state.invoiceId} 
                companyId={this.state.invoice.organisation.organisation_id}/>


            <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="card-title mb-1">Invoice details</h6>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <td>Invoice Ref</td>
                                            <th>Plan</th>
                                            <th>Start Date</th>
                                            <th>Expiry Date</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                            <td>{invoice.created_at.when}</td>
                                            <td>{invoice.invoice_ref}</td>
                                            <td>{invoice.reservation_plan.plan}</td>
                                            <td>{invoice.start_date.short_date}</td>
                                            <td>{invoice.expiry_date.short_date}</td>
                                            <td>{invoice.amount.total_c}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            </>}

            {!invoice && <ActivityLoader />}



                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default NewReservationPayment
