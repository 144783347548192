import React from 'react'
import functions from '../../utils/functions'
import WhiteListing from '../TaskBoard/WhiteListing'
import VIPListing from '../TaskBoard/VIPListing'
import PaymentCheque from '../TaskBoard/PaymentCheque'
import RTJSPayment from '../TaskBoard/RTJSPayment'
import BankDepositPayment from '../TaskBoard/BankDepositPayment'
import { RenderSecure } from '../../utils/script/RenderSecure'

class RightSideBar extends React.Component {



    render() {
        return (
            <div className="sidebar sidebar-right sidebar-animate" id="system_drawer">
                <div className="sidebar-icon">
                    <span onClick={functions.closeSystemDrawer} className="text-right float-right text-dark fs-20 pointer" data-toggle="sidebar-right" data-target=".sidebar-right"><i className="fe fe-x"></i></span>
                </div>
                <div className="sidebar-body">
                    <h5>Task Board</h5>

                    {/* <div className="d-flex p-2">
                                <label className="ckbox"><input checked type="checkbox" /><span>Hangout With friends</span></label>
                                <span className="ml-auto">
                                    <i className="fe fe-edit-2 text-primary mr-2" data-toggle="tooltip" title="" data-placement="top" data-original-title="Edit"></i>
                                    <i className="fe fe-trash-2 text-danger mr-2" data-toggle="tooltip" title="" data-placement="top" data-original-title="Delete"></i>
                                </span>
                            </div> */}
                        <RenderSecure code="R-WHITELIST-02">
                            <WhiteListing/>
                        </RenderSecure>
                      
                       {/* <VIPListing/> */}
                       <RenderSecure code="R-PAY-CHEQUE-02">
                            <PaymentCheque/>
                       </RenderSecure>
                      <RenderSecure code="R-PAY-RTGs-02">
                            <RTJSPayment/>
                      </RenderSecure>
                      <RenderSecure code="R-PAY-BANKSLIP-01">
                        <BankDepositPayment/>
                      </RenderSecure>
                     

                        
                           

                </div>
            </div>

        )
    }

}

export default RightSideBar
