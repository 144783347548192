import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {

    async grantUserAccess(user_id, permissions) {

        let data = {
            "permissions": permissions,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'access/user/grant',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async grantRoleAccess(role_id, permissions) {

        let data = {
            "permissions": permissions,
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'access/role/grant',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async grantUserBulkyAccess(user_id, permissions) {

        let data = {
            "permission_list": permissions,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'access/user/grant/bulky',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async grantRoleBulkyAccess(role_id, permissions) {

        let data = {
            "permission_list": permissions,
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'access/role/grant/bulky',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getUserAccess(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'access/user/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRoleAccess(role_id) {

        let data = {
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'access/role/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async denyUserAccess(user_id, permission_id) {

        let data = {
            "permission_id": permission_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'access/user/deny',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async denyRoleAccess(role_id, permission_id) {

        let data = {
            "permission_id": permission_id,
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'access/role/deny',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async requestPasswordReset(username) {
      let data = {
        username: username
      }
      try {
        let response = await fetch(apiHost + 'user/auth/password/reset.request',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    }

}
