import React from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';

class PopRoleInput extends React.Component {

    state = {
        roleName: '',
        code: '',
        info: '',
        toast: false,
        modal: '',
        loading:false
    }

    onChangeRoleName = (event) => {
        this.setState({ roleName: event.target.value })
    }

    onChangeRoleCode = (event) => {
        this.setState({ code: event.target.value })
    }

    onConfirm = (event) => {
        event.preventDefault();
        const { roleName, code } = this.state;
        if (roleName.length > 0 && code.length>0) {
            this.setState({loading:true}, ()=>{
                this.props.onConfirm(roleName, code);
            })
           
        } else {
            this.setState({
                info: ''
            }, () => this.setState({
                info: <AlertError message={dictionary._requireRoleName} />
            })
            )
        }
    }

    
    render() {

        return (
            <div className="modal" id="modal_role_input">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Create New Role</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Role Name"
                                    type="text"
                                    onChange={() => this.onChangeRoleName}
                                    value={this.state.roleName}
                                />

                                <TextInput
                                    label="Code"
                                    type="text"
                                    onChange={() => this.onChangeRoleCode}
                                    value={this.state.code}
                                />

                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Role</button>
                            <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopRoleInput;
