import React, { useContext } from 'react'
import PageHeader from '../../Components/Common/PageHeader'
import DashCard from '../../Components/Cards/OperationCards/DashCard'
import { Link } from 'react-router-dom'
import { OperationConsumer } from '../../Context/OperationContext'
import { RenderSecure } from '../../utils/script/RenderSecure'
import PageContainer from '../../Components/Layout/PageContainer'
import BranchDevice from '../../Components/Device/BranchDevice'
import BranchContext from '../../Context/BranchContext'

class BranchMonitor extends React.Component {

  static contextType = BranchContext
  
 

  buttons = () => {

    const {refresh, branchListing, switchBranch} = useContext(BranchContext)

    return (
      <>
       <div className="btn btn-list">
              <RenderSecure code="U-SWITCH-BRANCH-0">
              <a className="btn ripple btn-primary dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-exchange-alt mr-1"></i> Switch Branch <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                {Array.isArray(branchListing) && branchListing.map((item, key)=>
                  <Link to="#" className="dropdown-item" key={key} onClick={()=>switchBranch(item.branch_id)}>
                    <i className="fa fa-university text-secondary mr-1"></i>{item.branch_name} Branch</Link>
                )}

              </div>
              </RenderSecure>
             

            <button className="btn ripple btn-light" onClick={refresh}>
              <i className="fa fa-redo"></i> Refresh
            </button>
       </div>
         
         
       

      </>
    )
  }


  CardSection = () => {

    return (
      <OperationConsumer>
        {props => {

          return (
            <>
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Total Locations"
                  icon="fas fa-map-pin"
                  href="#"
                  value={this.context.totalLocations}
                  color="icon-service bg-info-transparent rounded-circle text-info"
                />
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Active Sessions"
                  icon="far fa-clock"
                  value={this.context.sessionCount}
                  color="icon-service rounded-circle bg-primary-transparent text-primary"
                  href={
                    {
                      pathname: '/parking_sessions/branch',
                      state: { status: 1 }
                    }
                  }
                />
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Total Slots"
                  icon="fas fa-parking"
                  href="#"
                  value={this.context.totalSlots}
                  color="icon-service rounded-circle bg-success-transparent text-success"
                />

              <RenderSecure code="R-CLAMP-02">
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Clamped Vehicles"
                  icon="fas fa-ban"
                  href="#"
                  value={this.context.clampCount}
                  color="icon-service bg-secondary-transparent rounded-circle text-secondary"
                />
              </RenderSecure>
            

            </>
          )

        }}
      </OperationConsumer>
    )

  }



  render() {
    return (
            <PageContainer>

                  <PageHeader
                    title={this.context.branchName}
                    previousPageTitle="Dashboard"
                    back="/operations"
                    buttons={<this.buttons/>}
                  />


                  <div className="row">
                    <this.CardSection />
                  </div>

                  <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12">
                            <BranchDevice/>
                      </div>

                  </div>

             
            
         </PageContainer>
          );



  }
}

export default BranchMonitor
