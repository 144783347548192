import React from 'react'
import { Link } from 'react-router-dom'
import { AuthConsumer } from '../Context/AuthContext'
import functions from '../utils/functions'
import { RenderSecure } from '../utils/script/RenderSecure'

class ConfigMenu extends React.Component {

    state = {
        activeLocationPath: window.location.pathname,
        activeRoot: ''
    }

    componentDidMount() {

        const pathSections = this.state.activeLocationPath.split("/");
        this.setState({
            activeRoot: "/" + pathSections[1]
        })
    }



    render() {

        const path = this.state.activeLocationPath
        const activeRoot = this.state.activeRoot;
        const roleList = functions.roleGuard()

        return (
            <AuthConsumer>
                {props => {

                    const permissionList = props.permissionList;

                    return (
                        <div className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav ">
                            <div className="main-content-left-components">
                                <div className="card custom-card">
                                    <div className="card-body config-component-item">
                                        <nav className=" d-flex flex-column  nav-view">
                                            {functions.findInPermission(permissionList, 'OPERATIONS-00') &&
                                                <>
                                                    <Link
                                                        className={`nav-link ${path === '/config/branches' && 'active'}`}
                                                        to="/config/branches">Branches</Link>
                                                    <Link
                                                        className={`nav-link ${path === '/config/streets' && 'active'}`}
                                                        to="/config/streets">Streets</Link>
                                                    <Link
                                                        className={`nav-link ${path === '/config/locations' && 'active'}`}
                                                        to="/config/locations">Locations</Link>
                                                    <Link
                                                        className={`nav-link ${path === '/config/devices' && 'active'}`}
                                                        to="/config/devices">Devices</Link>

                                                    <Link
                                                        className={`nav-link ${path === '/config/clamp_tools' && 'active'}`}
                                                        to="/config/clamp_tools">Clamp Tools</Link>
                                                    {/*<Link
                                                        className={`nav-link ${path === '/config/clamp-violation-reasons' && 'active'}`}
                    to="/config/clamp-violation-reasons">Clamp Violation Reasons</Link>*/}
                                                    
                                                    {/*<Link
                                                        className={`nav-link ${path === '/config/clamp-abort-reasons' && 'active'}`}
                    to="/config/clamp-abort-reasons">Clamp Abort Reasons</Link>*/}
                                                </>
                                            }


                                            {functions.findInPermission(permissionList, 'R-CONFIG-CLAMP-THRESHOLD-02') &&
                                                <>
                                                    <Link
                                                        className={`nav-link ${path === '/config/clamping/thresholdfee' && 'active'}`}
                                                        to="/config/clamping/thresholdfee">Clamp Threshold</Link>

                                                    <Link
                                                        className={`nav-link ${path === '/config/clampingfee' && 'active'}`}
                                                        to="/config/clampingfee">Clamping Fee</Link>

                                                    {/* <Link
                                                        className={`nav-link ${path === '/config/towingfee' && 'active'}`}
                                                        to="/config/towingfee">Towing Fee</Link> */}
                                                </>

                                            }

                                            {functions.findInPermission(permissionList, 'OPERATIONS-00') &&
                                                <>


                                                    {/* <Link 
                                                        className={`nav-link ${path === '/config/w.p-description' && 'active'}`}
                                                        to="/config/w.p-description">W.P Description</Link>*/}
                                                    <Link
                                                        className={`nav-link ${path === '/config/holiday' && 'active'}`}
                                                        to="/config/holiday">Public Holidays</Link>
                                                </>
                                            }

                                            {(functions.findInPermission(permissionList, 'R-CONFIG-SURCHAGE-02') || roleList === "CASH") &&
                                                <Link
                                                    className={`nav-link ${path === '/config/surcharge' && 'active'}`}
                                                    to="/config/surcharge">Surcharge</Link>
                                            }
                                            <Link
                                                className={`nav-link ${path === '/config/roles' && 'active'}`}
                                                to="/config/roles">Roles</Link>
                                            <Link
                                                className={`nav-link ${path === '/config/permissions' && 'active'}`}
                                                to="/config/permissions">Permissions</Link>
                                            <Link
                                                className={`nav-link ${path === '/config/notifications' && 'active'}`}
                                                to="/config/notifications">Notifications </Link>
                                            {functions.findInPermission(permissionList, 'R-CONFIG-PAY-MODE-02') &&
                                                <Link
                                                    className={`nav-link ${path === '/config/payment_modes' && 'active'}`}
                                                    to="/config/payment_modes">Payment Modes </Link>
                                            }
                                            {/* <Link
                                                className={`nav-link ${path === '/config/towing-stations' && 'active'}`}
                                                to="/config/towing-stations">Towing Stations </Link> */}
                                            <Link
                                                className={`nav-link ${path === '/config/workdays' && 'active'}`}
                                                to="/config/workdays">Working Days</Link>
                                            <Link
                                                className={`nav-link ${path === '/config/workinghours' && 'active'}`}
                                                to="/config/workinghours">Working Hours</Link>

                                            <Link
                                                className={`nav-link ${path === '/config/migration' && 'active'}`}
                                                to="/config/migration">Migration</Link>

                                            <Link
                                                className={`nav-link ${path === '/vehicles/all-vehicles' && 'active'}`}
                                                to="/vehicles/all-vehicles">Registered Vehicles </Link>
                                            <Link
                                                className={`nav-link ${path === '/config/sticker-types' && 'active'}`}
                                                to="/config/sticker-types">Sticker Types </Link>
                                            <Link
                                                className={`nav-link ${path === '/config/car-types' && 'active'}`}
                                                to="/config/car-types">Vehicle Types </Link>
                                            <Link
                                                className={`nav-link ${path === '/config/bankingclient' && 'active'}`}
                                                to="/config/bankingclient">Banking Clients </Link>
                                            <Link
                                                className={`nav-link ${path === '/config/plex-apps' && 'active'}`}
                                                to="/config/plex-apps">Plex Slider </Link>




                                        </nav>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }}
            </AuthConsumer>


        )
    }

}

export default ConfigMenu
