import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewVehicleType from '../../../Components/VehicleType/PopNewVehicleType'
import PopEditVehicleType from '../../../Components/VehicleType/PopEditVehicleType'
import ajaxConfig from '../../../utils/remote/ajaxConfig'
import PopNewBankingClient from '../../../Components/BankingClient/PopNewBankingClient'
import TableHeader from '../../../Components/Common/TableHeader'

class ConfigBankingClient extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewRecord: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.getBankingClients();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: false
            }, () => {
                this.setState({
                    transactionList: server_response.details.content
                })
            })

        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewRecord: false
        }, () => {
            this.setState({
                mdlNewRecord: <PopNewBankingClient
                    onConfirm={this.onConfirmNewRecord} />
            }, () => {
                functions.openPopup("modal_new_banking_client");
            })
        })
    }


   

    onConfirmNewRecord = async (bank_name) => {
        const server_response = await ajaxConfig.createBankingClient(bank_name);

        if (server_response.status === "OK") {
            functions.closePopup("modal_new_banking_client");
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            functions.closePopup("modal_new_banking_client");
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Delete public holiday?"
                        description="Are you sure you want to delete this public holiday?"
                        onConfirm={() => this.onConfirmDelete()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        }
    }

    onConfirmDelete = async () => {

        if (this.state.transactionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deletePublicHoliday(this.state.transactionId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.transactionId);
                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }



    onConfirmEditRecord = async (id, type_name, number_of_slots) => {
        const server_response = await ajax.updateVehicleType(id, type_name, number_of_slots);

        if (server_response.status === "OK") {
            functions.closePopup("modal_edit_car_type");
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            functions.closePopup("modal_edit_car_type");
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }



    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Banking Client
                </button>

            </>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Vehicle Type
                </a>
                <a className="dropdown-item" onClick={this.onClickEdit}>
                    <i className="fas fa-pencil-alt mr-2"></i>Edit Vehicle Type
                </a>
                {/* <a className="dropdown-item" onClick={this.onClickDelete}>
                    <i className="fe fe-trash-2 mr-2"></i>Delete Sticker Type
                </a> */}

            </>
        )
    }


    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlNewRecord}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Banking Client"
                            previousPageTitle="Configurations"
                            back="/config"
                           
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center filter-example">
                                                <TableHeader
                                                    title="Banking Listing"
                                                    subtitle="List of all banks used in the system financial transactions"
                                                />
                                                

                                                <div className="text-wrap">
                                                    <div className="">
                                                        <div className="btn-list">

                                                            <button type="button" className="btn ripple btn-primary btn-sm">
                                                                Total Clients <span className="badge badge-light ml-2">{12}</span>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="table-responsive allView-config border-top">
                                                    <table className="table text-nowrap mb-0 table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Bank Name</th>
                                                                <th>Created At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transactionList && transactionList !== "404" &&
                                                                transactionList.map((item, key) =>
                                                                    <tr
                                                                        id={"br_" + item.bank_id}
                                                                        key={key}
                                                                        className={`${this.state.transactionId == item.bank_id ? 'flag-row' : ''}`}
                                                                        onClick={() => this.onSelectTransaction(item.bank_id, item)}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{item.bank_name}</td>
                                                                        <td>{item.created_at.short_date + ' ' + item.created_at.time}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        </tbody>

                                                    </table>
                                                    {!transactionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigBankingClient
