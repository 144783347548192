import React, { Component } from "react";
import Chart from "react-apexcharts";

class ApexRadioChart extends React.Component {

    state = {

        series: this.props.values,
        options: {
            chart: {
                // height: 390,
                // type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    }
                }
            },
            colors: this.props.colors,
            labels: this.props.labels,
            legend: {
                show: true,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 160,
                offsetY: 15,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }]
        },


    };

    render() {
        return (

            <Chart
                options={this.state.options}
                series={this.state.series}
                type="radialBar"
                height={300}
            />
        )
    }
}

export default ApexRadioChart;
