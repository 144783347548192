import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async monitorRecentTicketSales(page) {

        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'ticket/monitor/recent',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async fetchPaperTickets(data){
        let response = await apiCall("ticket/conversion/list", data)
        return response;
    },

    async fetchTotalDailyPaperTicketSales(data){
        let response = await apiCall("ticket/paper/total/daily", data)
        return response;
    },

    async fetchTotalPaperTicketVendors(data){
        let response = await apiCall("ticket/paper/vendor/total/daily", data)
        return response;
    },

    async fetchTotalPaperTicketVehicles(data){
        let response = await apiCall("ticket/paper/vehicle/total/daily", data)
        return response;
    },

    async fetchWeeklyTotalPaperTicketVehicles(data){
        let response = await apiCall("ticket/paper/vehicle/total/weekly", data)
        return response;
    },

    async fetchMonthlyTotalPaperTicketVehicles(data){
        let response = await apiCall("ticket/paper/vehicle/total/monthly", data)
        return response;
    },

    async fetchTotalWeeklyPaperTicketSales(data){
        let response = await apiCall("ticket/paper/total/weekly", data)
        return response;
    },

    async fetchTotalMonthlyPaperTicketSales(data){
        let response = await apiCall("ticket/paper/total/monthly", data)
        return response;
    },

    async postOfficeTickeSale(vehicle, amount, contact){
        let data={
            "amount":amount,
            "plate_number":vehicle,
            "phone_number":contact
        }
        let response = await apiCall("ticket.create.office", data)
        return response;
    },

    async postTicketConversion(vehicle, amount, contact){
        let data={
            "amount":amount,
            "plate_number":vehicle,
            "phone_number":contact
        }
        let response = await apiCall("ticket/convert", data)
        return response;
    },

    async fetchPaperTicketAgentSummary(data){
        let response = await apiCall("ticket/sales/vendor", data)
        return response;
    },



    async fetchVirtualTicketSellers(){
        let response = await apiCall("ticket/virtual/sellers/list", [])
        return response;
    },

    



}