import React from 'react';

class PopInUseClampInfo extends React.Component {

    state = {
        clampTool: this.props.clampTool
    }


    render() {
        const { clampTool } = this.state
        return (
            <>
                {this.state.receipt}
                <div className="modal" id="modal_config_clamp_tool">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">ClampTool Information </h6>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {clampTool && clampTool !== "404" &&
                                    clampTool.clamp_transaction &&

                                    <>
                                        <div className="card  no-shadow">

                                            <div className="card-header custom-card-header">
                                                <h1 className="card-title tx-dark tx-medium mb-0 font-size-18">  <span className="font-weight-normal font-size-15 text-primary">Clamp Number: </span>CT-{clampTool.clamp_number}</h1>
                                            </div>
                                            <div className="card-body main-contact-info-body">

                                                <div className="media-list pb-lg-0">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                                <label>Being Used By</label> <span className="tx-medium">{clampTool?.clamp_transaction?.clamped_by?.username}</span>
                                                            </div>
                                                            <div>
                                                                <label>Clamped Vehicle Plate</label> <span className="tx-medium">{clampTool.clamp_transaction.vehicle.vehicle_number}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                                <label>Clamped Location</label>
                                                                <span className="tx-medium">{clampTool.clamp_transaction.clamped_location.location_name}</span>
                                                            </div>

                                                            <div>
                                                                <label>User Role</label>
                                                                <span className="tx-medium no-wrap">
                                                                    {/* {vehicleInfo.vehicle_type?vehicleInfo.vehicle_type.type_name:"Not Specified"} */}
                                                                    {clampTool.clamp_transaction.clamped_by.user_role.role_name}

                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                                <label>Clamped On</label>
                                                                <span className="tx-medium">{clampTool.clamp_transaction.updated_at.long_date}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )

    }
}

export default PopInUseClampInfo;
