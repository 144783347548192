import React from 'react'
import functions from '../../utils/functions';
import { FinanceConsumer } from '../../Context/FinanceContext';
import Chart from "react-apexcharts";

class ApexLine extends React.Component {

    state = {

        userId: functions.sessionGuard(),

        optionsMixedChart: false,
        seriesMixedChart: false,

        series: [
            {
                name: 'TEAM A',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 50]
            },
            {
                name: 'TEAM B',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 20]
            },
            {
                name: 'TEAM C',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 38]
            },
            {
                name: 'TEAM D',
                data: [20, 15, 56, 10, 75, 15, 74, 62, 52, 46, 69, 38]
            },
            {
                name: 'TEAM E',
                data: [23, 52, 31, 57, 12, 33, 11, 51, 46, 17, 33, 10]
            },
        ],

        options: {
            // chart: {
            //     // height: 350,
            //     // type: 'area'
            //     stacked: false,
            //     toolbar: {
            //         show: false
            //     }
            // },
            // colors: ['#eb6f33', '#dfe2e6', '#7c59e6', '#56D180', '#F5C107'],
            // dataLabels: {
            //     enabled: false
            // },
            // stroke: {
            //     width: [2.5, 2.5, 2.5, 2.5, 2.5],
            //     curve: 'smooth'
            // },
            // grid: {
            //     borderColor: '#f1f1f1'
            // },
            // legend: {
            //     display: true,
            //     position: 'top'
            // },
            // xaxis: {
            //     // type: 'datetime',
            //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            // },
            // fill: {
            //     opacity: [1, 1, 1, 0, 0],
            //     // gradient: {
            //     //     inverseColors: false,
            //     //     shade: 'light',
            //     //     type: "vertical",
            //     //     opacityFrom: 0.85,
            //     //     opacityTo: 0.55,
            //     //     stops: [0, 100, 100, 100]
            //     // }
            // },
            // tooltip: {
            //     shared: true,
            //     intersect: false,
            //     y: {
            //         formatter: function (y) {
            //             if (typeof y !== "undefined") {
            //                 return y.toFixed(0) + " points";
            //             }
            //             return y;
            //         }
            //     },
            //     // x: {
            //     //     format: 'dd/MM/yy HH:mm'
            //     // },
            // },

            chart: {
                // type: "area",
                // height: 350,
                // foreColor: "#999",
                stacked: false,
                // dropShadow: {
                //     enabled: true,
                //     enabledSeries: [0],
                //     top: -2,
                //     left: 2,
                //     blur: 5,
                //     opacity: 0.06
                // },
                toolbar: {
                    show: false
                }
            },
            // colors: ['#00E396', '#0090FF'],
            colors: ['#7c59e6', '#eb6f33', '#56D180', '#dfe2e6', '#F5C107'],
            stroke: {
                curve: "smooth",
                width: 2.5
            },
            dataLabels: {
                enabled: false
            },
            // series: [
            //     {
            //         name: 'Total Views',
            //         data: this.generateDayWiseTimeSeries(0, 18)
            //     }, {
            //         name: 'Unique Views',
            //         data: this.generateDayWiseTimeSeries(1, 18)
            //     }
            // ],

            series: [
                {
                    name: 'TEAM A',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 50]
                },
                {
                    name: 'TEAM B',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 20]
                },
                {
                    name: 'TEAM C',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 38]
                },
                {
                    name: 'TEAM D',
                    data: [20, 15, 56, 10, 75, 15, 74, 62, 52, 46, 69, 38]
                },
                // {
                //     name: 'TEAM E',
                //     data: [23, 52, 31, 57, 12, 33, 11, 51, 46, 17, 33, 10]
                // },
            ],

            markers: {
                size: 0,
                strokeColor: "#fff",
                strokeWidth: 3,
                strokeOpacity: 1,
                fillOpacity: 1,
                hover: {
                    size: 6
                }
            },
            xaxis: {
                // type: "datetime",
                // axisBorder: {
                //     show: false
                // },
                // axisTicks: {
                //     show: false
                // }
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            },
            // yaxis: {
            //     labels: {
            //         offsetX: 14,
            //         offsetY: -5
            //     },
            //     tooltip: {
            //         enabled: true
            //     }
            // },
            grid: {
                // padding: {
                //     left: -5,
                //     right: 5
                // },
                borderColor: '#f1f1f1'
            },
            tooltip: {
                x: {
                    format: "dd MMM yyyy"
                },
            },
            legend: {
                position: 'top',
                display: true,
                // horizontalAlign: 'left'
            },
            fill: {
                // type: "solid",
                fillOpacity: 0.5,
                opacity: [1, 1, 1, 0, 0],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " points";
                        }
                        return y;
                    }
                },
                // x: {
                //     format: 'dd/MM/yy HH:mm'
                // },
            },
        },

    };

    setOptionsMixedChart = (data) => {

        const optionsMixedChart = {

            chart: {
                // type: "area",
                // height: 350,
                // foreColor: "#999",
                stacked: false,
                // dropShadow: {
                //     enabled: true,
                //     enabledSeries: [0],
                //     top: -2,
                //     left: 2,
                //     blur: 5,
                //     opacity: 0.06
                // },
                toolbar: {
                    show: false
                }
            },
            // colors: ['#00E396', '#0090FF'],
            colors: ['#7c59e6', '#eb6f33', '#56D180', '#dfe2e6', '#F5C107'],
            stroke: {
                curve: "smooth",
                width: 2.5
            },
            dataLabels: {
                enabled: false
            },
            // series: [
            //     {
            //         name: 'Total Views',
            //         data: this.generateDayWiseTimeSeries(0, 18)
            //     }, {
            //         name: 'Unique Views',
            //         data: this.generateDayWiseTimeSeries(1, 18)
            //     }
            // ],

            series: [
                {
                    name: 'TEAM A',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 50]
                },
                {
                    name: 'TEAM B',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 20]
                },
                {
                    name: 'TEAM C',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 38]
                },
                {
                    name: 'TEAM D',
                    data: [20, 15, 56, 10, 75, 15, 74, 62, 52, 46, 69, 38]
                },
                // {
                //     name: 'TEAM E',
                //     data: [23, 52, 31, 57, 12, 33, 11, 51, 46, 17, 33, 10]
                // },
            ],

            markers: {
                size: 0,
                strokeColor: "#fff",
                strokeWidth: 3,
                strokeOpacity: 1,
                fillOpacity: 1,
                hover: {
                    size: 6
                }
            },
            xaxis: {
                // type: "datetime",
                // axisBorder: {
                //     show: false
                // },
                // axisTicks: {
                //     show: false
                // }
                // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
                categories: data
            },
            // yaxis: {
            //     labels: {
            //         offsetX: 14,
            //         offsetY: -5
            //     },
            //     tooltip: {
            //         enabled: true
            //     }
            // },
            grid: {
                // padding: {
                //     left: -5,
                //     right: 5
                // },
                borderColor: '#f1f1f1'
            },
            tooltip: {
                x: {
                    format: "dd MMM yyyy"
                },
            },
            legend: {
                position: 'top',
                display: true,
                // horizontalAlign: 'left'
            },
            fill: {
                // type: "solid",
                fillOpacity: 0.5,
                opacity: [1, 1, 1, 0, 0],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " points";
                        }
                        return y;
                    }
                },
                // x: {
                //     format: 'dd/MM/yy HH:mm'
                // },
            },

        }

        this.setState({ optionsMixedChart: optionsMixedChart })

    }

    render() {
        return (

            <FinanceConsumer>
                {props => {
                    const chartData = props.salesChartData;
                    return (
                        <div id="chart">
                            {chartData && chartData !== "404" &&
                                <Chart
                                    options={this.state.options}
                                    series={this.state.options.series}
                                    type="area"
                                    height={350}
                                />
                            }
                        </div>
                    )

                }}

            </FinanceConsumer>
        )
    }
}

export default ApexLine;

