import React from 'react';
import { FinanceConsumer } from '../../../Context/FinanceContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import StatisticCard from '../../StatisticCard';


class MonthlyWrongParkingRevenue extends React.Component {
  state={
    userId:functions.sessionGuard(),
    count:'...',
  }

  // async componentDidMount()
  // {
  //     await this.countInvoices();

  //      this.timerCount = setTimeout(this.funcCount=async()=> {
  //       await this.countInvoices();
  //       this.timerCount = setTimeout(this.funcCount, 5000); // (*)
  //     }, 5000)
  // }

  // componentWillUnmount()
  // {
  //   clearTimeout(this.timerCount)
  // }
  

  // countInvoices=async ()=>{

  //   const server_response = await ajax.getMonthlyWrongParkingRevenue(this.state.userId);
  //   if(server_response.status==="OK")
  //   {
  //       this.setState({
  //           count:server_response.details.content.total_c
  //       })
  //   }
  // }


  render(){
    
      return (
        <FinanceConsumer>
          {props=>{
            return(
              <StatisticCard
              title="Contravention Revenue"
              value={props.wrongParkingRevenue}
              caption="This month income"
              icon={"fas fa-ban"}
              color={"text-success"}
              progressbar={"progress-bar progress-bar-xs wd-50p bg-success"}
            />
            )
          }}
         
        </FinanceConsumer>  
      );
    
 
  }
}

export default MonthlyWrongParkingRevenue;