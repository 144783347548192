import React, { useContext, useEffect, useState } from 'react';
import AlertError from '../../Common/AlertError';
import ConfigurationContext from '../../../Context/ConfigurationContext';
import TextInput from '../../Common/TextInput';
import dictionary from '../../../utils/dictionary';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';


const PopCancelClamp=(props)=> {

        const clampId = props.record.transaction_id;

        const [info, setInfo] = useState(false);
        const [reasonId, setReasonId] = useState('');

        const { clampIncidents, getAllTransactions } = useContext(ParkingViolationContext)


    
        const onConfirm = async (event) => {
            event.preventDefault();
            if (clampId.length > 0 && reasonId.length>0) {

                const server_response = await ajaxViolation.cancelClamp(clampId, reasonId);
                if(server_response.status === "OK"){
                    setInfo(<AlertSuccess message={server_response.message} />)
                    functions.closePopup("modal_cancel_clamp");
                    getAllTransactions();
                }else{
                    setInfo(<AlertError message={server_response.message} />)
                }

            } else {
                setInfo(<AlertError message={"Select incident, then try again"} />)
            }
        }



    const optionReason = [];
    if(Array.isArray(clampIncidents)){
        clampIncidents.forEach(element=>{
            optionReason.push({label:element.reason + "(UGX." + element.fine_amount.amount_c + ")", value:element.id})
        })
    }

  
        return (
            <div className="modal" id="modal_cancel_clamp">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Clamp incident - {props.record.vehicle.plate_number}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                                <p className='mb-3'>Clamp incidents are premptive measures that can be taken during the clamping process. E.g. the zulu might find a cut clamp instead of a clamped car, false alarm for wrong parking, etc.</p>
                      

                                <TextInput
                                    type="select"
                                    label="Incident"
                                    onChange={(option) => setReasonId(option.value)}
                                    options={optionReason}
                                    placeholder={"Select incident..."}
                                    name="cancellationReason"
                                />



                            </form>

                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-danger" type="button" onClick={onConfirm}>Confirm Incident</button>
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopCancelClamp;
