import React from 'react';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import ClientView from '../ClientView';
import ActivityLoader from '../Common/ActivityLoader';
import Toast from '../Common/Toast';
import StickerStatus from '../Finance/Vehicle/StickerStatus';
import TicketBalance from '../Finance/Vehicle/TicketBalance';
import PopVehicleType from './PopVehicleType';


class VehicleInfo extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        balance: "...",
        plateNumber: this.props.plateNumber,
        vehicleInfo: false,
        mdlType:false,
        toast: false
    }

    componentDidMount() {
        this.getVehicle();
    }


    getVehicle = async () => {
        const server_response = await ajax.getVehicleByPlate(this.state.plateNumber, this.state.userId)
        if (server_response.status === "OK") {
            this.setState({
                vehicleInfo: server_response.details.content
            })
        } else {
            this.setState({
                vehicleInfo: false
            })
        }
    }


    onClickChangeType=()=>{
        this.setState({
            mdlType:false
        }, ()=>{

            this.setState({
                mdlType:<PopVehicleType onConfirm={this.changeVehicleType}/>
            }, ()=>{
                functions.openPopup('mdl_vehicle_type')
            })
        })
      
    }

    changeVehicleType=async(type)=>{
        const server_response = await ajax.changeVehicleType(
            this.state.plateNumber,
            type
        );

        functions.closePopup('mdl_vehicle_type');

        if (server_response.status === "OK") {
            this.props.refresh(server_response.details.message)
        } else {
            this.setState({
                vehicleInfo: false,
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
        }


    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }


    render() {

        const vehicleInfo = this.state.vehicleInfo;

        return (
            <>
            {this.state.mdlType}
            {this.state.toast}
            <div className="row">

                <div className="col-lg-4 col-md-12">

                    {vehicleInfo && vehicleInfo !== "404" &&
                        <>
                            <div className="card custom-card">

                                <div className="card-header custom-card-header">
                                    <h5 className="card-title tx-dark tx-medium mb-0">Vehicle Info</h5>
                                </div>
                                <div className="card-body main-contact-info-body">

                                    <div className="media-list pb-lg-0">
                                        <div className="media">
                                            <div className="media-body">
                                                <div>
                                                    <label>Plate Number</label> <span className="tx-medium">{vehicleInfo.plate_number}</span>
                                                </div>
                                                <div>
                                                    <label>Vehicle ID</label> <span className="tx-medium">{vehicleInfo.vehicle_id}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="media">
                                            <div className="media-body">
                                                <div>
                                                    <label>Is VIP</label>
                                                    <span className="tx-medium">{vehicleInfo.is_vip}</span>
                                                </div>

                                                <div>
                                                    <label>Vehicle Type</label>
                                                    <span className="tx-medium no-wrap">
                                                        {vehicleInfo.vehicle_type?vehicleInfo.vehicle_type.type_name:"Not Specified"}
                                                        <i 
                                                            className="fas fa-pencil-alt ml-2 text-primary App-link" 
                                                            title="Change Vehicle type"
                                                            onClick={this.onClickChangeType}
                                                            />
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="media">
                                            <div className="media-body">
                                                <div>
                                                    <label>Added Since</label>
                                                    <span className="tx-medium">{vehicleInfo.created_at}</span>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>


                            </div>


                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="card-title mb-1">Prepayment Plan</h6>
                                        <p className="text-muted mb-0 card-sub-title">Vehicle balances on prepaid plans</p>
                                    </div>
                                </div>
                                <div className="bd-t">
                                    {vehicleInfo && <div className="list-group">
                                        <TicketBalance vehicleID={vehicleInfo.vehicle_id} />
                                        <StickerStatus vehicleID={vehicleInfo.vehicle_id} />

                                    </div>}
                                </div>
                            </div>
                        </>

                    }

                </div>




                {vehicleInfo && vehicleInfo !== "404" && <ClientView vehicleInfo={vehicleInfo} refresh={this.props.refresh} />}

                {!this.state.vehicleInfo && <ActivityLoader />}


            </div>
        </>
        );


    }
}

export default VehicleInfo;
