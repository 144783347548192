import React, { useContext } from 'react';
import OperationContext from '../../../Context/OperationContext';

const SessionEndRequests = (props) => {

    const { sessionEndRequests } = useContext(OperationContext);


    const incomingRequests = [];

    if (Array.isArray(sessionEndRequests)) {
        sessionEndRequests.map((item, key) => {

            incomingRequests.push({ "location": item.session.location.location_name, "conductor_phone": item.session.start_device.phone_number, "plate_number": item.vehicle.plate_number })

        })
    }

    return (
        <div className="d-flex no-gutters justify-content-between mb-4 w-100">

            <div className="w-25 incoming-card d-flex justify-content-center align-items-center box-shadow crypto-ticker bg-white">
                <div className="marquee-container">
                    <h5 className="card-title mb-0">Incoming Requests</h5>
                </div>
            </div>

            <div className="w-75 incoming-card border crypto-ticker bg-light">
                <div className="marquee-container">
                    <div className="mask py-1">
                        {Array.isArray(sessionEndRequests) &&
                            <marquee scrollamount="2" scrolldelay="1">

                                <ul id="webticker-big" className="marquee-list">

                                    {incomingRequests.map((item, key) =>
                                        <li key={key}>
                                            <span className="text-primary"> <i className="fa fa-car"></i> {item.plate_number}  </span>

                                            <span className="text-success"> - <i className="fa fa-map-pin"></i> {item.location} </span>

                                            <span className=""> <i className="fa fa-phone text-danger"></i> {item.conductor_phone}</span>
                                        </li>
                                    )}

                                </ul>

                            </marquee>
                        }

                        {!Array.isArray(sessionEndRequests) &&
                            <span style={{ fontSize: "16px" }} className='text-muted ml-2'>
                                Session end requests from clients appear here
                            </span>
                        }

                    </div>

                </div>
            </div>

        </div>


    );
}


export default SessionEndRequests;
