import React, { useContext } from "react"
import BranchContext from "../../Context/BranchContext"
import dictionary from "../../utils/dictionary"
import ActivityLoader from "../Common/ActivityLoader"
import NoContent from "../Common/NoContent"
import TableHeader from "../Common/TableHeader"
import LoadMoreButton from "../Other/LoadMoreButton"

const BranchDevice =(props)=>{

    const {branchUserListing, goToNextPage, metaData, loading} = useContext(BranchContext)
    return(
        <div className="card custom-card ">
        <div className="card-body map-card">

            <TableHeader
                title="Device Monitoring"
                subtitle="List of all branch devices;- showing assigned conductors and field activity"
            />


            {Array.isArray(branchUserListing) &&
                <LoadMoreButton
                    action={goToNextPage}
                    loading={loading}
                    title={metaData.current_page + " of " + metaData.total_pages}
                    />
                }

            <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                <div className="table-responsive">
                   {Array.isArray(branchUserListing) && <table className="table text-nowrap mb-0 table-hover datatable" id="table-branch-dist" export-format={dictionary._exportBtnFormats}>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Assigned User</th>
                                <th>Device Tag</th>
                                <th>Device Phone</th>
                                <th>User Role</th>
                                <th>Daily Sessions</th>
                                <th>Daily Clamps</th>
                                <th>Daily Scans</th>
                                <th>Operation area</th>
                            </tr>
                        </thead>
                        <tbody>
                            {branchUserListing.map((item, key)=>
                                <tr key={key}>
                                    <td>{key+1}</td>
                                    <td>{item.user.first_name + ' ' + item.user.last_name}</td>
                                    <td>{item.device_allocation?item.device_allocation.device_info.tag_number:''}</td>
                                    <td>{item.device_allocation?item.device_allocation.device_info.phone_number:''}</td>
                                    <td>{item.user?item.user.user_role.role_name:''}</td>
                                    <td style={{textAlign:"center"}}>{item.daily_total_sessions.total_c}</td>
                                    <td style={{textAlign:"center"}}>{item.daily_total_clamps.total_c}</td>
                                    <td style={{textAlign:"center"}}>{item.daily_total_scans.total_c}</td>
                                    <td>{item.location_allocation}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>}
                    {!branchUserListing && <ActivityLoader/>}
                    {branchUserListing ==="404" && <NoContent/>}
                </div>
                </div>
           </div>
        </div>
    )
}

export default BranchDevice