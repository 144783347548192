import React, { useContext, useEffect, useState } from 'react';
import AlertError from '../../Common/AlertError';
import ConfigurationContext from '../../../Context/ConfigurationContext';
import TextInput from '../../Common/TextInput';
import dictionary from '../../../utils/dictionary';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';


const PopUnClamp=(props)=> {

        const vehicleId = props.record.vehicle.vehicle_id;

        const [info, setInfo] = useState(false);

        const { getAllTransactions } = useContext(ParkingViolationContext)


    
        const onConfirm = async (event) => {
            event.preventDefault();

                const server_response = await ajaxViolation.unClampVehicle(vehicleId);
                if(server_response.status === "OK"){
                    setInfo(<AlertSuccess message={server_response.message} />)
                    functions.closePopup("modal_unclamp");
                    getAllTransactions();
                }else{
                    setInfo(<AlertError message={server_response.message} />)
                }

           
        }



  
        return (
            <div className="modal" id="modal_unclamp">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Unclamp Vehicle</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      

                            <p className='mb-3'>Confirm that you want to unclamp this vehicle. Vehicle will now be available for new parking transactions.</p>


                            </form>

                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-success" type="button" onClick={onConfirm}>Confirm Unclamp</button>
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopUnClamp;
