import React from 'react';
import ajax from '../../../../utils/ajax';
import dictionary from '../../../../utils/dictionary';
import functions from '../../../../utils/functions';
import ajaxTicket from '../../../../utils/remote/ajaxTicket';
import ActivityLoader from '../../../Common/ActivityLoader';
import AlertError from '../../../Common/AlertError';
import AlertSuccess from '../../../Common/AlertSuccess';
import TextInput from '../../../Common/TextInput';

class PopTicketConvert extends React.Component {

    state = {
        amount: '',
        depositorContact: '',
        depositorName: '',
        info: '',
        loading:false
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }


    makeOfficePayment = async (amount, depositorContact) => {
        if(this.state.loading)
        {
            return false
        }
        this.setState({
            info: <ActivityLoader />,
            loading:true
        })
        const server_response = await ajaxTicket.postTicketConversion(
            this.props.plateNumber,
            amount,
            depositorContact
        )


        if (server_response.status === "OK") {

            this.setState({
                amount:'',
                depositorContact:'',
                info: <AlertSuccess message={server_response.details.message} />
            }, ()=>{

                setTimeout(()=>{
                    functions.closePopup("modal_ticket_convert")
                }, 2000)

            })


           
        } else {
            this.setState({
                info: <AlertError message={server_response.details.message} />,
                loading:false
            })
        }
    }




    onConfirm = (event) => {
        event.preventDefault();
        const { amount, depositorContact} = this.state
        if (amount.length > 0 && depositorContact.length > 0) {

            this.makeOfficePayment(amount, depositorContact);

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }




    render() {
        return (
            <>
                <div className="modal" id="modal_ticket_convert">
                    <div className="modal-dialog modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New Ticket Exchange</h6>
                                <span>Vehicle: {this.props.plateNumber}</span>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <form method="post" onSubmit={this.onConfirm}>

                                    <TextInput
                                        label="Amount"
                                        type="number"
                                        placeholder="Paper Ticket Amount"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                                   

                                    <TextInput
                                        label="Contact"
                                        type="text"
                                        placeholder="Customer's Phone Number"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                                </form>




                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-success" disabled={this.state.loading} type="button" onClick={this.onConfirm}>Confirm Exchange</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }
}

export default PopTicketConvert;
