import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async getSupervisorDistribution() {

        let data = {
            "device": "BROWSER"
        }

        try {
            let response = await fetch(apiHost + 'supervision/distribution',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countDailyScans() {

        let data = {
            "device": "BROWSER"
        }

        try {
            let response = await fetch(apiHost + 'supervision/transaction/count/daily',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countUserDailyScans(supervisor_id) {

        let data = {
            "supervisor_id": supervisor_id
        }

        try {
            let response = await fetch(apiHost + 'supervision/transaction/count/daily',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getComments() {
      try {
        let response = await fetch(apiHost + 'supervision/comments',
          {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },
    async assignLocation(supervisorId, locationId) {
      let data = {
        supervisor_id:supervisorId,
        location_id: locationId
      }
      try {
        let response = await fetch(apiHost + 'supervisor/allocate',
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          });
        let result = await response.json();
        return result;
      } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
      }
    },
    async getScanRecords(data) {

      let response = await apiCall("supervisor/transaction/list", data);
      return response;

    },

    async filterScanRecords(page, data) {
      let response = await apiCall("supervisor/transaction/list", data);
      return response;
    },

    async fetchRecentTransactions()
    {
        let response = await apiCall("operations/supervisor/supervisor/recent_transactions", [])
        return response;

    },


     async fetchMonthlyCount()
      {
          let response = await apiCall("operations/supervisor/supervisor/count_monthly_records", [])
          return response;
  
      },

      async fetchTotalSupervisors()
      {
          let response = await apiCall("operations/supervisor/supervisor/count_supervisors", [])
          return response;
  
      },

      async fetchEfficiency()
      {
          let response = await apiCall("operations/supervisor/supervisor/efficiency", [])
          return response;
  
      },

      async fetchReport(data)
      {
          let response = await apiCall("operations/supervisor/supervisor/supervisors_performance", data)
          return response;
  
      },


}