import React from 'react';
import VehicleContext from '../../Context/VehicleContext';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class PopNewVehicle extends React.Component {
    static contextType = VehicleContext;
    state = {
        plateNumber: '',
        info: '',
        phoneNumber: '',
        email: '',
        firstName: '',
        lastName: '',
        typeId:'',
        loading: false
    }

    onChangeFirstName = (event) => {
        this.setState({ firstName: event.target.value })
    }

    onChangeLastName = (event) => {
        this.setState({ lastName: event.target.value })
    }

    onChangePhone = (event) => {
        this.setState({ phoneNumber: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value })
    }

    

    onChangeType = (selectedOption) => {
        this.setState({
            typeId: selectedOption.value
        })
    }


    onConfirm = async (event) => {
        event.preventDefault();
        const {phoneNumber, email, typeId, firstName, lastName, plateNumber}=this.state;
        if (plateNumber.length > 0 && phoneNumber.length >= 10 && typeId.length>0 && firstName.length>0 && lastName.length>0) {

            this.setState({ loading: true })
            const server_response = await ajax.createNewVehicle(
                plateNumber,
                typeId,
                firstName,
                lastName,
                phoneNumber,
                email
            );
            this.setState({ loading: false })
            if (server_response.status === "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />,
                    plateNumber: '',
                    phoneNumber: '',
                    email: '',
                    fullName: ''
                })
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Complete vehicle details with a correct client phone number"} />
            })
        }
    }





    render() {

        const typeList = this.context.vehicleTypes;
        const optionsType = [];
        if (typeList && typeList !== "404") {
            typeList.map((item, key) =>
                optionsType.push({ label: item.type_name + ' - vehicle occupies ' + item.slots + ' slot(s)', value: item.type_id })
            )
        }

        return (
            <div className="modal" id="modal_new_vehicle">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Vehicle {this.state.plateNumber.length>0 && " - " + this.state.plateNumber.toUpperCase()}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            {this.context.vehicleTypes && <form method="post" onSubmit={this.onConfirm}>

                            <TextInput
                                    label="Vehicle Number"
                                    type="text"
                                    onChange={() => this.onChangePlateNumber}
                                    value={this.state.plateNumber}
                                />
                            <SelectSearch
                                    label="Vehicle Type"
                                    onChange={() => this.onChangeType}
                                    options={optionsType}
                                    placeholder={"Select vehicle type..."}
                                />

                                <TextInput
                                    label="First Name"
                                    type="text"
                                    placeholder="First Name"
                                    onChange={() => this.onChangeFirstName}
                                    value={this.state.firstName}
                                />

                                <TextInput
                                    label="Last Name"
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={() => this.onChangeLastName}
                                    value={this.state.lastName}
                                />


                                <TextInput
                                    label="Client Phone"
                                    type="text"
                                    placeholder="Phone Number"
                                    onChange={() => this.onChangePhone}
                                    value={this.state.phoneNumber}
                                />

                                <TextInput
                                    label="Client Email"
                                    placeholder="Email Address"
                                    type="text"
                                    onChange={() => this.onChangeEmail}
                                    value={this.state.email}
                                />

                            </form>}
                            {!this.context.vehicleTypes && <ActivityLoader/>}

                        </div>
                        <div className="modal-footer">
                            {this.state.loading && <ActivityLoader />}
                            {!this.state.loading &&
                                <>
                                    <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Save Vehicle</button>
                                    <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopNewVehicle;
