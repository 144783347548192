import React, { useEffect, useState } from 'react';
import ajaxViolation from '../../utils/remote/ajaxViolation';

const BranchAnalysisContext = React.createContext();

export const BranchAnalysisConsumer = BranchAnalysisContext.Consumer;

export const BranchAnalysisProvider = (props)=> {

  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(false);
  const [streetClampAnalysis, setStreetClampAnalysis] = useState(false);
  const [title, setTitle] = useState('');



  useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
  }, [currentPage])


useEffect(()=>{
 if(data){
    getStreetClampAnalysis();
 }
}, [data])


const getStreetClampAnalysis=async()=>{

    setStreetClampAnalysis(false)
    const server_response = await ajaxViolation.fetchStreetClampAnalysis(data);
    if(server_response.status==='OK'){
        setTitle(server_response.message);
        setStreetClampAnalysis(server_response.details.content)
    }else{
        setStreetClampAnalysis('404')
    }   
}

 

  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
  
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "branch_id":options.branch,
        "street_id":options.street,
    })
    
}

    
    return (
           <BranchAnalysisContext.Provider value={
               {
                  metaData,
                  loading,
                  streetClampAnalysis,
                  title,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData,
                  getStreetClampAnalysis
               }
               }>
               {props.children}
           </BranchAnalysisContext.Provider>
        );
    
}

export default BranchAnalysisContext;