import axios from "axios";
import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost
const connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

const apiCall = async (endpoint, data)=>{


    try {
        let response = await axios.post(apiHost + endpoint, data,
          {
            headers: {
                'Authorization': 'Bearer ' + ACCESS_TOKEN,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });

        // let result = await response.json();
        // return result;
        return response.data
      } catch (error) {
        if(error.response.status===0){
            console.log("Logging out");
        }
        return connError;
      }

}


export default apiCall