import React, { useContext } from 'react';
import FieldContext from '../../../Context/FieldContext';
import dictionary from '../../../utils/dictionary';
import ajaxClamping from '../../../utils/remote/ajaxClamping';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import NoContent from '../../Common/NoContent';
import TableHeader from '../../Common/TableHeader';

const OpClamps = (props) => {

    const { clampList, clampMessage } = useContext(FieldContext)

    const clampStatusBadge = (status_id, status) => {    
        const statusUI=["", "info", "", "secondary", "danger", "primary", "light", "info", "secondary", "danger", "primary", "light", "secondary", "danger", "primary", "light"]
        return (
          <>
              <span className={`badge badge-${statusUI[status_id]} tx-12 mr-2 pointer text-bolder rounded`}>{status}</span>
          </>
        )
      }
    return (

        <div className="col-sm-12 col-xl-4 col-lg-4">
            <div className="card custom-card">
                <div className="card-body">



                    <TableHeader
                        title="Contraventions"
                        subtitle="Violations and enforcement responses."
                    />

                    <div className="activity-block user-manager mt-3 pt-3 scroll-widget border-top overflow-auto">

                        {!clampList && <ActivityLoader />}

                        {clampList == "404" &&
                            <>
                                <NoContent />
                            </>
                        }

                        {Array.isArray(clampList) && <ul className="task-list">
                            {clampList.map((item, key) => <li key={key}>
                            <i className={`task-icon bg-${item.status_id === '1'? "primary" : "success"}`}></i>
                                <h6>
                                    {item.vehicle.plate_number}<small className="float-right text-muted tx-11">
                                        {item.updated_at.date + " " + item.updated_at.time}</small>
                                </h6>
                                <span className="text-muted tx-12">Reason: {item.clamped_for.violation_name}</span>
                                {item.due_amount &&
                                    <>
                                        <br />

                                        <span className="text-muted tx-12">Amount Due:</span> <span className=" tx-11 text-normal">{item.due_amount.amount_c}</span>
                                    </>
                                }

                                <br />
                                <span className="text-muted tx-12">Location: {item.clamping_location.location_name || item.initial_location.location_name}</span>
                                <br />
                                <span className="text-normal tx-12">
                                    Status: {clampStatusBadge(item.status_id, item.status)}
                                </span>
                                <br />
                               {item.incident && <span className="text-danger tx-12">Abort Reason: <span className="text-primary ml-1">{item.incident.reason.reason}</span> <span className='text-dark'> - by {item.incident.cancelled_by.full_name}</span></span>}
                            </li>)}

                        </ul>}
                        {/* </div>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>


    );
}

export default OpClamps;