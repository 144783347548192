import React, {useContext } from 'react';
import OperationContext from '../../../Context/OperationContext';
import LiveTile from '../../Cards/LiveTile';

const OpStatistics = () => {

    const { countActiveSessions, countClampedVehicles, countAvailableParking, countTowedVehicles, activeSlotCount } = useContext(OperationContext)


    return (
        <>

            <div className="col-sm-12 col-xl-3 col-lg-3">
                <LiveTile
                    title="Total Active Slots"
                    value={activeSlotCount}
                    description="Active slots vs active sessions."
                    comment = {"By: " + countActiveSessions}
                    theme="primary"
                />
            </div>

            <div className="col-sm-12 col-xl-3 col-lg-3">
                <LiveTile
                    title="Total Clamped Vehicles"
                    value={countClampedVehicles}
                    description="A red flag indicates anomaly."
                />
            </div>

            <div className="col-sm-12 col-xl-3 col-lg-3">
                <LiveTile
                    title="Total Available Slots"
                    value={countAvailableParking}
                    description="A red flag indicates anomaly."
                />
            </div>

            <div className="col-sm-12 col-xl-3 col-lg-3">
                <LiveTile
                    title="Total Towed Vehicles"
                    value={countTowedVehicles}
                    description="A red flag indicates anomaly."
                />
            </div>
        </>


    );

}

export default OpStatistics;
