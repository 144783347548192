import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import WarnSecureDelete from '../../Components/WarnSecureDelete'
import PopChequePaymentReceipt from '../../Components/Payments/PopChequePaymentReceipt'
import PopStickerOptions from '../../Components/Finance/Cashier/PopStickerOptions'
import { Link } from 'react-router-dom'
import ReservationInvoice from '../../Components/Invoices/ReservationInvoice'
import ajaxPayment from '../../utils/remote/ajaxPayment'
import PopReservationReceipt from '../../Components/Reservation/PopReservationReceipt'

class ReserveRTGSRecord extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        chequeId:'',
        chequeRecord:false,
        reservationInvoice:false,
        chequeType:false,
        chequeTotal:0,
        vehicleTotal:0,
        excessAmount:0,
        vehicleSheet:false,
        editedAmount:false,
        sheetId:false,
        edited:false,
        loading:false,
        toast: false,
        mdlWarn:false,
        receipt:false,
        receiptLoading:false,
        mdlReceipt:false,
        mdlStickerOptions:false,
        selectedVehicleInfo:false,

        mdlInvoice:false
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/finance/rtjs_payment');
          } else {
            this.setState({
              chequeId: this.props.location.state.transactionId
            }, () => {
                this.getCheque();
            })
          }

    }


    getCheque=async()=>{
        const server_response=await ajax.getRTJSRecord(this.state.chequeId);
        if(server_response.status==="OK")
        {
            this.setState({
                chequeRecord:server_response.details.content,
                chequeType:server_response.details.content.cheque_type,
                chequeTotal:server_response.details.content.amount.amount
            }, ()=>this.getReservation(this.state.chequeRecord.internal_reference_id))
            
        }
    }

    
    getReservation=async(reservation_id)=>{
        const server_response=await ajax.getReservation(reservation_id);
        if(server_response.status==="OK")
        {
           this.setState({
                reservationInvoice: server_response.details.content
           })
           
        }else{
            this.setState({
                reservationInvoice :"404"
            })
        }
    }


   



    buttons = () => {
        const chequeRecord=this.state.chequeRecord
        return (
            <>
            {this.state.chequeRecord && <>

               {this.state.loading && <button to="#" className="btn ripple btn-light" onClick={this.onClickAddPayment}>
                    <ActivityLoader
                     height={20} 
                     width={30} 
                     customClass="custom"
                    />
                </button>}
            
               {this.state.edited && !this.state.loading && <button className="btn ripple btn-primary" onClick={this.saveVehicleAmounts}>
                    <i className="fas fa-save"></i> Save Changes
                </button>}
                {!this.state.edited && chequeRecord.status.status_id*1==0 && <button className="btn ripple btn-success" onClick={()=>this.onClickHandleRequest(1)}>
                    <i className="far fa-check-circle"></i> {chequeRecord.next_status}
                </button>}
                {!this.state.edited && chequeRecord.status.status_id*1==1 && <button className="btn ripple btn-success" onClick={()=>this.onClickHandleRequest(3)}>
                    <i className="far fa-check-circle"></i> {chequeRecord.next_status}
                </button>}

                {chequeRecord.status.status_id*1<=1 && <button className="btn ripple btn-danger" onClick={()=>this.onClickHandleRequest(2)}>
                    <i className="fas fa-times"></i> Cancel RTGS
                </button>}
 
                {chequeRecord.status.status_id*1==3 && !this.state.receiptLoading?
                <button className="btn ripple btn-light" onClick={this.onClickPrintReceipt}>
                    <i className="fas fa-print"></i> RTGS Receipt
                </button>:
                 <button className="btn ripple btn-light">
                 <ActivityLoader 
                 height={20} 
                 width={30} 
                 customClass="custom"
                 />
             </button>
            }
            </>}
            </>
        )
    }



   


    resetToast = () => {
        this.setState({
            toast: false
        })
    }





     /**
     * INVOKES THE HANDLE REQUEST DIALOGUE
     */
      onClickHandleRequest=(status)=>{
        functions.closePopup('modal_white_list');
        var { title, description, buttonLabel, buttonType}="";
        this.setState({
            status:status
        })
        switch(status)
        {
            case 2://cancelling
                title="Cancel RTGS";
                description="Please confirm with your account password that you want to cancel this RTGS";
                buttonLabel="Cancel RTGS";
                buttonType="btn-danger"
            break;
            case 1://approving

                title="Approve RTGS";
                description="Please confirm with your account password that you want to approve this RTGS";
                buttonLabel="Approve RTGS";
                buttonType="btn-success"

            break;
            case 3://process
                title="Process RTGS";
                description="Please confirm with your account password that you want to process this RTGS";
                buttonLabel="Process RTGS";
                buttonType="btn-primary"
            break;

            case 4://approving
                title="Terminate RTGS";
                description="Please confirm with your account password that you want to terminate this RTGS";
                buttonLabel="Terminate RTGS";
                buttonType="btn-danger"
            break;

            default:
                title="";
                description="";
                buttonLabel="";
            break;
        }

        this.setState({
            mdlWarn:false
        }, ()=>{
            
            this.setState({
                mdlWarn:<WarnSecureDelete 
                title={title}
                description={description}
                icon="fas fa-question"
                buttonLabel={buttonLabel}
                buttonType={buttonType}
                onConfirm={this.confirmHandleRequest}
                />
            }, ()=>{
                functions.openPopup('modal_secure_delete')
            })
        })
    }


     /**
     * EFFECT THE HANDLE REQUEST ACTION
     */
     confirmHandleRequest=async(password)=>{
        this.setState({
            loading:true
        })
        var server_response;
        switch(this.state.status)
        {
            case 2://CANCEL REQUEST
                server_response=await ajax.cancelRTJS(
                    this.state.chequeId,
                    password
                );
        
            break;
            case 1://APPROVE REQUEST
                server_response=await ajax.approveRTJS(
                    this.state.chequeId,
                    password
                );
        
            break;
            case 3://PROCESS REQUEST
            server_response=await ajax.processRTJS(
                this.state.chequeId,
                password
            );
            break;
            case 4://TERMINATE REQUEST
            // server_response=await ajax.terminateWhiteList(
            //     this.state.request.request_id,
            //     password
            // );
    
            break;
            default:
                server_response={'status':'conn', 'details':{'message':'Invalid request', 'content':false}}
            break;
        }
        this.setState({
            loading:false
        })
       
        if(server_response.status==="OK")
        {
            this.setState({
                toast: <Toast
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
              this.refresh()
        }else{
            this.setState({
                toast: <Toast
                type={4}
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
        }
     }



     refresh=()=>{
        this.setState({
            chequeRecord:false
        }, async()=>{
            await this.getCheque()
            this.onClickPrintReceipt()
        })
     }



     getChequeReceipt=async()=>{
        const server_response=await ajaxPayment.getReservationRTGSReceipt(this.state.chequeId);
        if(server_response.status=="OK")
        {
            this.setState({
                receiptLoading:false,
                receipt:server_response.details.content,
                mdlReceipt:false
            }, ()=>{
                this.setState({
                    mdlReceipt:<PopReservationReceipt record={this.state.receipt}/>
                }, ()=>functions.openPopup('modal_payment_summary'))
            })
            return true;
        }else{
            this.setState({
                receiptLoading:false,
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
            return false;
        }
     }


     onClickPrintReceipt=()=>{

       if(this.state.chequeRecord.status.status_id*1==3)
       {
        this.setState({
            receiptLoading:true,
            receipt:false
        })

        this.getChequeReceipt();
        }
     }



   
    viewInvoice=(invoiceRecord)=>{
        this.setState({
            mdlInvoice:false
        }, ()=>{
          this.setState({
            mdlInvoice:<ReservationInvoice record={invoiceRecord} />
          }, ()=>{
                  functions.openPopup("modal_reservation_invoice")
          })

        });
    }

   
    render() {
      
        const cheque=this.state.chequeRecord
        const invoice = this.state.reservationInvoice;
        return (
            <div className="page">
                {this.state.mdlStickerOptions}
                {this.state.mdlInvoice}

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="RTGS Record"
                            previousPageTitle="RTGS Payments"
                            // options={this.state.menuOptions}
                            back="/finance/rtjs_payment"
                            buttons={<this.buttons />}
                        />
                        {this.state.toast}
                        {this.state.mdlWarn}
                        {this.state.mdlReceipt}
                       {this.state.chequeRecord && <div className="row">
                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="RTGS Details"
                                            subtitle="RTGS payment details"
                                        />


                                    <table className="table text-nowrap mb-0 table-hover">
                                    <tbody>
                                    <tr>
                                            <td>RTGS Type</td>
                                            <td className="tx-bold">{cheque.rtgs_type.type_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Bank Of Origin</td>
                                            <td className="tx-bold">{cheque.bank.bank_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Source Description</td>
                                            <td className="tx-bold">{cheque.source_description}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Transaction Date</td>
                                            <td className="tx-bold">{cheque.rtjs_date.short_date}</td>
                                        </tr>

                                        <tr>
                                            <td>Total Amount</td>
                                            <td className="tx-bold">UGX. {cheque.amount.amount_c}</td>
                                        </tr>

                                        
                                        <tr>
                                            <td>Transaction Status</td>
                                            <td className="tx-bold">{cheque.status.status}</td>
                                        </tr>
                                        <tr>
                                            <td>Depositor's Name</td>
                                            <td className="tx-bold">{cheque.depositor_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Depositor's Contact</td>
                                            <td className="tx-bold">{cheque.depositor_contact}</td>
                                        </tr>
                                        <tr>
                                            <td>Created On</td>
                                            <td className="tx-bold">{cheque.created_at.long_date}</td>
                                        </tr>
                                        <tr>
                                            <td>Created By</td>
                                            <td className="tx-bold">{cheque.created_by.username}</td>
                                        </tr>
                                    </tbody>
                                   

                                </table>
                            



                                    </div>
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Customer Details"
                                            subtitle="Customer cheque details"
                                        />
                                        <div className="activity-block mr-3 scroll-widget border-top overflow-auto" style={{height:"200px"}}>
                                         
                                            {!invoice && <ActivityLoader/>}
                                            {invoice && invoice!=="404" && <ol>
                                                <li><b>{ invoice.organisation.title }</b></li>
                                                <li>{ invoice.organisation.location_address }</li>
                                                <li>{ invoice.organisation.contact }</li>
                                                <li className='App-link page-link' onClick={()=>this.viewInvoice(invoice) }>View Invoice</li>
                                                </ol> }                                             
                                           
                                       
                                        </div>




                                        <div className="activity-block mr-3 border-top">
                                         
                                           {cheque.status.status_id*1 <= 1 && <table className="table text-nowrap mb-0 table-hover">
                                                <tbody>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Invoice Outstanding</td>
                                                    <td className="tx-bold">UGX. {invoice && invoice!=="404" && invoice.balance.amount_c}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Cheque Total</td>
                                                    <td className="tx-bold">UGX. {this.state.chequeTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Excess Amount</td>
                                                    <td className="tx-bold">UGX. {invoice && invoice!=="404" && (this.state.chequeTotal - invoice.balance.amount)}</td>
                                                </tr>
                                                </tbody>
                                            </table>}
                                        </div>


                                       

                                    </div>
                                </div>
                            </div>




                        </div>}

                        {!this.state.chequeRecord && <ActivityLoader/>}

                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );

    }
}

export default ReserveRTGSRecord
