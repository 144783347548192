import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async getLiveClamps()//GET
    {
        let response = await apiCall('violation/clampenforcement/monitor_live_clamps', [])
        return response;
    },

    // GET USER CLAMP HISTORY
    async userClampHistory(clamped_by_id, page, unclamped_by_id, clamp_status_id, created_from, created_to, status_id_1, status_id_2) {

        let data = {
            "clamped_by_id": clamped_by_id,
            "page": page,
            "unclamped_by_id": unclamped_by_id,
            "clamp_status_id": clamp_status_id,
            "created_from": created_from,
            "created_to": created_to,
            "status_id_1": status_id_1,
            "status_id_2": status_id_2

        }

        try {
            let response = await fetch(apiHost + 'clamping/list/all/users/clamp/history',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listRecentClamps()//GET
    {
        try {
            let response = await fetch(apiHost + 'clamping/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listClampAbortReasons() {

        try {
            let response = await fetch(apiHost + 'clamping/reasons',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createClampViolationReasons(reason, fine, is_clamp_violation) {

        let data = {
            "reason": reason,
            "fine": fine,
            "is_clamp_violation": is_clamp_violation,
        }

        try {
            let response = await fetch(apiHost + 'clamping/reasons.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async updateClampViolationReason(reasonId, reason, fine) {

        let data = {
            "reason_id": reasonId,
            "reason": reason,
            "fine": fine,
            // "is_clamp_violation": is_clamp_violation,
        }


        try {
            let response = await fetch(apiHost + 'clamping/reasons.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async cobraPerformance(created_from, created_to) {

        let data = {
            "created_from": created_from,
            "created_to": created_to
        }

        try {
            let response = await fetch(apiHost + 'report/user-performance/cobra-performance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async countCobraClamps(clamped_by_id, clamp_status_id, created_from, created_to) {

        let data = {
            "clamped_by_id": clamped_by_id,
            "clamp_status_id": clamp_status_id,
            "created_from": created_from,
            "created_to": created_to
        }

        try {
            let response = await fetch(apiHost + 'report/user-performance/count_cobra-clamps',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    async getClampingFee()//GET
    {

        try {
            let response = await fetch(apiHost + 'clamping/fees',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createClampingFee(amount)//POST
    {

        let data = {
            "amount": amount
        }

        try {
            let response = await fetch(apiHost + 'clamping/fee.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deleteClampingFee(id)//POST
    {

        let data = {
            "fee_id": id
        }

        try {
            let response = await fetch(apiHost + 'clamping/fee.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countAllClampActivity()//GET
    {
        try {
            let response = await fetch(apiHost + 'clamping/all.count',

                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'

                    },
                    // body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async abortRespondToClamp(clamp_transaction_id, location_id, reason_id, clamped_by_id, aborted_by_id) {


        let data = {
            "location_id": location_id,
            "reason_id": reason_id,
            "clamp_transaction_id": clamp_transaction_id,
            "clamped_by_id": clamped_by_id,
            "location_id": location_id,
            "aborted_by_id": aborted_by_id
        }

        try {
            let response = await fetch(apiHost + 'clamping/cobra/abort',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }

            return connError;
        }

    },


    async listAbortedClamps(cobra_abort, admin_abort, system_abort, page, custom_items_per_page) {
        let data = {
            "cobra_abort": cobra_abort,
            "admin_abort": admin_abort,
            "system_abort": system_abort,
            "page": page,
            "custom_items_per_page": custom_items_per_page

        }
        try {
            let response = await fetch(apiHost + 'clamping/list/aborts',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


}