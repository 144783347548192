import React, { useContext, useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import ajaxSticker from '../utils/remote/ajaxSticker';
import { detectTable, restoreTable } from '../utils/script/customTables';

const StickerContext = React.createContext();

export const StickerConsumer = StickerContext.Consumer;

export const StickerProvider = (props) => {

    const [stickerListing, setStickerListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [stickerTypeList, setStickerTypeList] = useState(false);
    const [stickerClassList, setStickerClassList] = useState(false);


    useEffect(()=>{
        getStickerTypes();
        getStickerClasses();
    }, [])

    useEffect(()=>{
        getStickers();
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getStickers=async()=>{
        setLoading(true)
        setStickerListing(false)
        const server_response = await ajax.listStickerSales(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setStickerListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setStickerListing("404");
            setMetaData(false);
        }
    }

   
   


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajax.listStickerSales(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setStickerListing(stickerListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "sticker_type_id":options.stickerType,
            "page":"1",
            "plate_number":options.plateNumber,
            "issued_by_id":options.issuedBy,
        })
        
    }





    const getStickerTypes = async () => {
        const server_response = await ajax.listStickerTypes()
        if (server_response.status === "OK") {
            setStickerTypeList(server_response.details.content)
        }
      }


    const getStickerClasses = async () => {
            const server_response = await ajaxSticker.listStickerClasses()
            if (server_response.status === "OK") {
                setStickerClassList(server_response.details.content)
            }
    }


  
    return (
        <StickerContext.Provider value={{
            stickerListing,
            metaData,
            loading,
            stickerListing,
            stickerTypeList,
            stickerClassList,
            getStickers,
            goToNextPage,
            setData,
            resetData,
            filterData
        }}
        >
            {props.children}
        </StickerContext.Provider>
    );
}


export default StickerContext;
