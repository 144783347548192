import React from 'react'
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ActivityLoader from './Common/ActivityLoader';
import $ from 'jquery';
import PopInvoice from './PopInvoice';
import { Link } from 'react-router-dom';
import TableHeader from './Common/TableHeader';
import { FinanceConsumer } from '../Context/FinanceContext';
import { AuthConsumer } from '../Context/AuthContext';
import NoContent from './Common/NoContent';

class RecentInvoices extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        invoiceView: false
    }

    onClickInvoice = (record) => {
        this.setState({
            invoiceView: false
        }, () => {

            this.setState({
                invoiceView: <PopInvoice
                    invoice={record}
                    onClickPay={this.props.onClickPay} />
            }, () => {
                $("#modal_invoice").modal('show');
            })
        })

    }




    render() {
        // const invoiceList = this.state.invoiceList;
        return (
            <FinanceConsumer>
                {props => {
                    const invoiceList = props.recentInvoices;
                    return (

                        <div className="card custom-card">
                            <div className="card-body map-card">

                                <TableHeader
                                    title={"Recent Invoices "}
                                    subtitle="A representation of the recent generated Invoices."
                                    view="View All"
                                    href="/finance/invoices"
                                />

                                {/* <div className="border-top mt-3"></div> */}
                                {this.state.invoiceView}
                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    <div className="table-responsive">
                                        <table className="table table-hover text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Invoice REF</th>

                                                    <th>Invoice Date</th>
                                                    <th>Vehicle</th>
                                                    
                                                    <th>Amount(UGX)</th>
                                                    <th>Status</th>
                                                    <th>Invoice Type</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (invoiceList === "404")
                                                        ?
                                                        <tr>
                                                            <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="6">
                                                                {/* <img alt="no-content" src={nocontent} /> */}
                                                                <NoContent />
                                                            </td>
                                                        </tr>
                                                        :
                                                        invoiceList && invoiceList !== "404" &&
                                                        invoiceList.map((item, key) =>
                                                            <tr key={key}>
                                                                {/* <td>
                                                                    <Link to="#" onClick={() => this.onClickInvoice(item)}>
                                                                        {item.invoice_id_c}
                                                                    </Link>
                                                                </td> */}
                                                                <td>
                                                                    {item.invoice_id_c}
                                                                </td>
                                                                {/* <td>{item.invoice_date}</td> */}
                                                                
                                                                <td>
                                                                    {item.invoice_dates.short_date} <br />
                                                                    <small className="tx-12 tx-gray-500">{item.invoice_dates.time}</small>
                                                                </td>

                                                                <td className="tx-bold">
                                                                    <Link to={
                                                                        {
                                                                            pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                            state: { plateNumber: item.vehicle.plate_number }
                                                                        }}>{item.vehicle.plate_number}
                                                                    </Link>
                                                                </td>
                                                                
                                                                <td>{item.invoice_items.total.amount_c}</td>
                                                                <td>
                                                                    {item.payment.balance.amount <= 0 && <span className="badge rounded badge-success">{item.payment.status}</span>}
                                                                    {item.payment.balance.amount > 0 && <span className="badge rounded badge-secondary">{item.payment.status}</span>}

                                                                </td>

                                                                <td>{item.invoice_type.invoice_name}</td>

                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                        {!invoiceList && <ActivityLoader />}
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }}
            </FinanceConsumer>
        )
    }

}

export default RecentInvoices
