import React from 'react';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import functions from '../../utils/functions';
import AlertWarning from '../Common/AlertWarning';
import ImageUpload from '../Common/ImageUpload';
import ActivityLoader from '../Common/ActivityLoader';
import WrongParkingContext from '../../Context/ContraventionContext/WrongParkingContext';


class PopCreatePlexPicture extends React.Component {

    static contextType = WrongParkingContext;

    state = {
        userId: functions.sessionGuard(),
        plateNumber: '',

        info: '',
        reportedById: '',
        conductorList: false,
        TransactionId: '',
        vehicleId: '',
        searchList: false,
        toast: false,
        modal: '',

        loading: false,
        uploadProfilePicture: ''
    }

    componentDidMount() {

        // this.getDescriptions();
        // this.getLocations();
        // this.getContraventions();
        // this.getUnUsedClampTools();
        // this.getCobras();

    }

    // onChangePictureName = (event) => {
    //     this.setState({ pictureName: event.target.value })
    // }

    onChangeImage = (event) => {

        this.setState({
            fileName: event.target.value
        })
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.setState({
                uploadProfilePicture: e.target.result
            })
            console.log(e.target.result)
        }

    }

    onConfirm = (event) => {
        event.preventDefault();
        const { uploadProfilePicture } = this.state;
        // if (uploadProfilePicture.length > 0) {
        this.props.onConfirm(uploadProfilePicture);
        // } else {
        //     this.setState({
        //         info: ''
        //     }, () => this.setState({
        //         info: <AlertError message={"Complete all feilds"} />
        //     })
        //     )
        // }
    }


    render() {

        return (

            <div className="modal" id="modal_plex_picture">
                <div className="modal-dialog modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title}</h6>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>

                        {this.props.plexErrorMessage &&
                            <AlertWarning message={this.props.plexErrorMessage} />
                        }

                        <div className="modal-body">
                            {this.state.info}

                            <form method="post" onSubmit={this.onConfirm}>


                                {/* <TextInput
                                    label="Picture Name"
                                    type="text"
                                    value={this.state.pictureName}
                                    onChange={() => this.onChangePictureName}
                                /> */}

                                <ImageUpload
                                    label="Upload Image"
                                    id="fileToUpload"
                                    name="fileToUpload"
                                    onChange={() => this.onChangeImage}
                                />


                            </form>

                        </div>

                        <div className="modal-footer">
                            {!this.state.loading &&
                                <>
                                    <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>
                                        Confirm Request
                                    </button>

                                    <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">
                                        Close
                                    </button>
                                </>
                            }

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default PopCreatePlexPicture;
