import React from 'react';


class VehiclePayments extends React.Component {
  

  render(){
    
      return (
        <button 
        type="button" 
        className="btn ripple btn-success btn-sm"
        onClick={this.props.onClick}>
        Vehicle Payments
        </button>
      );
    
 
  }
}

export default VehiclePayments;