import React from 'react'
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';

class TicketFloatCard extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        balance: '...'
    }

    async componentDidMount() {
        await this.getBalance();
        this.timer = setTimeout(this.func = async () => {
            await this.getBalance();
            this.timer = setTimeout(this.func, 2000); // (*)
        }, 2000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    getBalance = async () => {
        const server_response = await ajax.getTicketFloatBalance(this.state.userId)
        if (server_response.status === "OK") {
            this.setState({
                balance: server_response.details.content.amount_c,
            })
        }
    }

    render() {
        return (
            <div className="card custom-card">
                <div className="card-body text-center">
                    <span className="text-mutd">Float Balance</span>
                    <h3 className="mb-0">
                        <span className="minor-tag">ugx</span>
                        {this.state.balance}
                    </h3>
                </div>
            </div>
        )
    }

}

export default TicketFloatCard
