import React, { useContext, useState } from 'react';
import ReservationContext from '../../../Context/ReservationContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import StatisticCard from '../../StatisticCard';


const AnnualReservationRevenue =()=> {
    const [count, setCount] = useState('...');
 
    const {annualReserationRevenue} = useContext(ReservationContext);

    
      return (

        <StatisticCard
                title="Reservation Revenue"
                value={annualReserationRevenue}
                caption="Annual Income"
                icon={"fas fa-car"}
                color={"text-info"}
                progressbar={"progress-bar progress-bar-xs wd-40p bg-info"}
              />
      );
    
 
  
}

export default AnnualReservationRevenue;
