import React, { useContext } from 'react';
import StatisticCard from '../../StatisticCard';
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext';


const TaxLastMonth =()=>{

    const {lastMonthTax} = useContext(FiscalReceiptContext);

 
    return <StatisticCard
                title="VAT" 
                value={lastMonthTax ? lastMonthTax : "..."}
                caption="Last month"
                icon={"fa fa-gavel"}
                color={"text-danger"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-danger"}
                />
 
}

export default TaxLastMonth;