import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearch from '../Common/SelectSearch';
import SelectSearchWrap from '../Common/SelectSearchWrap';
import TextInput from '../Common/TextInput';
import TextInputWrap from '../Common/TextInputWrap';

class PopNewOrganisation extends React.Component {

    state = {
        info: '',
        organisationTypeList: false,
        organisationType: '',
        organisationName: '',
        address: '',
        telephone: '',
        email: '',
        TIN: '',
        loading: false
    }
    componentDidMount() {
        this.getOrganisationType();
    }


    onChangeName = (event) => {
        this.setState({ organisationName: event.target.value })
    }

    onChangeAddress = (event) => {
        this.setState({ address: event.target.value })
    }

    onChangeTelephone = (event) => {
        this.setState({ telephone: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onChangeTIN = (event) => {
        this.setState({ TIN: event.target.value })
    }
    getOrganisationType = async () => {

        const server_response = await ajax.listOrganisationType();
        if (server_response.status == "OK") {
            this.setState({
                organisationTypeList: server_response.details.content
            })
        }

    }

    onChangeOrganisationType = (selectedOption) => {
        this.setState({
            organisationType: selectedOption.value
        })
    }


    onSave = async () => {
        const { organisationName, organisationType, telephone, email, address, TIN } = this.state;
        if (organisationName.length > 0 && organisationType * 1 > 0 && telephone.length > 0) {
            this.setState({
                loading: true
            })
            const server_response = await ajax.createOrganisation(
                organisationName,
                address,
                telephone,
                email,
                organisationType,
                TIN);

            this.setState({
                loading: false
            })

            if (server_response.status == "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                }, () => {
                    this.props.onConfirm();
                    functions.closePopup('pop_new_organisation')

                })
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }

    }


    render() {

        const organisationTypeList = this.state.organisationTypeList;
        const optionsOrganisationType = [];
        if (organisationTypeList && organisationTypeList !== "404") {
            organisationTypeList.map((item, key) =>
                optionsOrganisationType.push({ label: item.type_name, value: item.type_id })
            )
        }


        return (
            <div className="modal" id="pop_new_organisation">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Organisation</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onSave}>



                                <TextInputWrap
                                    label="Name of organisation"
                                    type="text"
                                    onChange={() => this.onChangeName}
                                    value={this.state.organisationName}
                                    required={true}
                                />

                                <SelectSearchWrap
                                    label="Type of Organisation"
                                    onChange={() => this.onChangeOrganisationType}
                                    placeholder="Select organisation type"
                                    options={optionsOrganisationType}
                                    required={true}
                                />

                                <TextInputWrap
                                    label="Location Address"
                                    type="text"
                                    onChange={() => this.onChangeAddress}
                                    value={this.state.address}
                                    required={true}
                                />

                                <TextInputWrap
                                    label="Work Telephone"
                                    type="text"
                                    onChange={() => this.onChangeTelephone}
                                    value={this.state.telephone}
                                    required={true}
                                />

                                <TextInputWrap
                                    label="TIN"
                                    type="text"
                                    placeholder="Tax Identification Number"
                                    onChange={() => this.onChangeTIN}
                                    value={this.state.TIN}
                                />

                                <TextInputWrap
                                    label="Email Address"
                                    type="text"
                                    onChange={() => this.onChangeEmail}
                                    value={this.state.email}
                                />


                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onSave}>Add Organisation</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>

            </div>
        )


    }
}

export default PopNewOrganisation;
