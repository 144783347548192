import apiCall from "./apiCall";


export default {


    async fetchDemandNotes(data)
    {
        let response = await apiCall("finance/demandnote/invoicebatch/list", data)
        return response;

    },

    async fetchDemandNoteInfo(batch_id)
    {
        const data = {
            batch_id : batch_id
        }
        let response = await apiCall("finance/demandnote/invoicebatch/generate", data)
        return response;

    },

    async createDemandNote(data)
    {
        let response = await apiCall("finance/demandnote/invoicebatch/new", data)
        return response;

    },
    

    async applyEfris(data)
    {
        let response = await apiCall("finance/demandnote/invoicebatch/apply_efris", data)
        return response;

    },

    async fetchMonthlySettledSum()
    {
        let response = await apiCall("finance/demandnote/batchinvoice/sum_monthly_settled", [])
        return response;

    },

    async fetchUnSettledSum()
    {
        let response = await apiCall("finance/demandnote/batchinvoice/sum_unsettled", [])
        return response;

    },


    async postClearBatch(batch_id)
    {
        let response = await apiCall("finance/demandnote/invoicebatch/clear_batch", {'batch_id':batch_id})
        return response;

    },
    
    async postCancelBatch(batch_id)
    {
        let response = await apiCall("finance/demandnote/invoicebatch/cancel_batch", {'batch_id':batch_id})
        return response;

    },

}