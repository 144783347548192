import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
// import ActivityLoader from '../../../Components/Common/ActivityLoader'
// import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'

class DeviceInfo extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    deviceId: '',
    deviceNumber: ''
  }


  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/config/devices');
    } else {
      const deviceRecord = this.props.location.state.record;
      this.setState({
        deviceId: deviceRecord.device_id,
        deviceNumber: deviceRecord.device_number
      })
    }
  }

  onChangeDeviceNumber = (event) => {
    this.setState({ deviceNumber: event.target.value })
  }



  onSave = async (event) => {
    event.preventDefault();
    const { deviceNumber } = this.state;

    if (deviceNumber.length > 0) {
      //fetch login from the API
      const server_response = await ajax.createDevice(deviceNumber);
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })
        this.setState({
          deviceNumber: ''
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._completeFields} />,
        loading: ''
      })
    }


  }

  render() {
    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title={this.state.deviceNumber}
              previousPageTitle="Devices"
              back="/config/devices"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">

                        <div>
                          <h6 className="card-title mb-1">Device Locations</h6>
                          <p className="text-muted card-sub-title">Currenly allocated locations for this device</p>
                          <button type="button" className="btn ripple btn-secondary btn-sm">Assign Location</button>

                        </div>
                        <div className="text-wrap">
                          <table className="table main-table-reference mt-0 mb-0">
                            <thead>
                              <tr>
                                <th>Location</th>
                                <th>Street</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>




                    <div className="card custom-card">
                      <div className="card-body">

                        <div>
                          <h6 className="card-title mb-1">Device Users</h6>
                          <p className="text-muted card-sub-title">Users currenly allocated to this device</p>
                          <button type="button" className="btn ripple btn-secondary btn-sm">Assign User</button>
                        </div>
                        <div className="text-wrap">
                          <table className="table main-table-reference mt-0 mb-0">
                            <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Role</th>
                                <th>Contact</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>




                  </div>





                </div>

              </div>

              <ConfigMenu />



            </div>

          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default DeviceInfo
