import React from 'react';


class FltStatement extends React.Component {
  

  render(){
    
      return (
        <button 
        type="button" 
        className="btn ripple btn-info btn-sm"
        onClick={this.props.onClick}>
        Statement
        </button>
      );
    
 
  }
}

export default FltStatement;