import React from 'react';
import ajax from '../../utils/ajax';
import ApexPolar from '../BarChart/ApexPolar';
import ActivityLoader from '../Common/ActivityLoader';
import TableHeader from '../Common/TableHeader';
import HollowPie from '../Statistics/HollowPie';


class SpaceAvailabilityCard extends React.Component {
    state = {
        spaceAvailability: false
    }

    async componentDidMount() {
        await this.getSpaceAvailability();

        this.timerCount = setTimeout(this.funcCount = async () => {
            await this.getSpaceAvailability();
            this.timerCount = setTimeout(this.funcCount, 5000); // (*)
        }, 5000)
    }

    componentWillUnmount() {
        clearTimeout(this.timerCount)
    }



    getSpaceAvailability = async () => {
        const server_response = await ajax.getSpaceAvailability();
        if (server_response.status === "OK") {
            this.setState({
                spaceAvailability: server_response.details.content
            })
        } else {
            this.setState({
                spaceAvailability: "404"
            })
        }

    }




    render() {

        const transaction = this.state.spaceAvailability

        return (
            <>
                {transaction && transaction != "404" &&
                    <div className="card custom-card overflow-hidden">
                        <div className="card-body map-card">
                            <TableHeader
                                title="Availability of Parking"
                                subtitle="Contrast between available parking and occupied slots. The unvalibale parking includes reserved and occupied slots."
                                view="View All"
                                onClick={this.props.onClickViewAll}
                            />

                            <div className="col-12 col-md-12 text-center">
                                <div className="text-center user-manager mt-3 scroll-widget border-top overflow-auto">
                                    <div className="mt-3">
                                        <HollowPie
                                            // color="#ed7533, #ed75ff,"
                                            color=" #7c59e6, #dfe2e6, #eb6f33, #01b8ff,"
                                            displayKey={true}
                                            labels={['Occupied Slots', 'Available Space', 'Reserved Space', 'Clamped Vehicles ']}
                                            values={[
                                                transaction.used_slots.total * 1, 
                                                transaction.available_slots.total * 1, 
                                                transaction.reserved.total * 1, 
                                                transaction.clamp_occupied_slots.total * 1
                                            ]}
                                        />

                                        {/* <ApexPolar
                                        colors={
                                            [
                                                '#7c59e6',
                                                '#dfe2e6',
                                                '#eb6f33'
                                            ]
                                        }
                                        displayKey={true}
                                        labels={
                                            [
                                                'Occupied Slots',
                                                'Available Space',
                                                'Reserved Space'
                                            ]
                                        }
                                        values={
                                            [
                                                transaction.used_slots.total * 1,
                                                transaction.available_slots.total * 1,
                                                transaction.reserved.total * 1

                                                // 42, 47, 52
                                            ]
                                        }
                                    /> */}
                                    </div>

                                    <p className="mb-1 tx-inverse">Available Space</p>
                                    <h4 className="mb-1">{transaction.available_slots.total * 1 + '/' + transaction.total_slots.total * 1}<span className="minor-tag">{transaction.total_slots.total * 1 > 1 ? ' Slots' : 'Slot'}</span></h4>
                                    <span className="text-muted tx-12"><i className="fas fa-caret-up mr-1 text-success"></i>{transaction.net_availablity} % Availability</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!transaction && <ActivityLoader />}
            </>
        );


    }
}

export default SpaceAvailabilityCard;