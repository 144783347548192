import React from 'react';

class FilterClampTools extends React.Component {

    state = {

    }

    render() {
        return (
            <>

                {/* {this.props.status && */}
                {/* <div className=""> */}
                <button aria-expanded="true" aria-haspopup="true" className="btn ripple btn-secondary dropdown-toggle btn-sm" href="#"
                    data-toggle="dropdown" id="dropdownMenuButton"> Select status <i className="fas fa-caret-down ml-1"></i>
                </button>
                <div className="dropdown-menu tx-13">
                    <a className="dropdown-item" onClick={this.props.checkStatus}>
                        Available clamp tools <span className="badge badge-light ml-2">{this.props.unusedTotal}</span>
                    </a>
                    <a className="dropdown-item" onClick={this.props.checkStatus}>
                        Used clamp tools <span className="badge badge-light ml-2">{this.props.usedTotal}</span>
                    </a>
                </div>
                {/* </div> */}
                {/* } */}
            </>
        );
    }
}

export default FilterClampTools;
