import React, { useEffect, useState, useContext } from "react";
import ajax from "../utils/ajax";
import functions from "../utils/functions";
import ajaxSupervisor from "../utils/remote/ajaxSupervisor";
import { detectTable, restoreTable } from "../utils/script/customTables";

const SupervisorContext = React.createContext();

export const SupervisorConsumer = SupervisorContext.Consumer;


export const SupervisorProvider=(props)=>{

   const [distributionList, setDistributionList]=useState(false);
   const [dailyCount, setDailyCount] = useState("...");
   const [scanRecords, setScanRecords] = useState(false);
   const [recentTransactions, setRecentTransactions] = useState(false);
   const [monthlyCount, setMonthlyCount] = useState("...");
   const [totalSupervisors, setTotalSupervisors] = useState("...");
   const [supervisorList, setSupervisorList] = useState(false);
   
   const [data, setData] = useState({"page":"1"})
   const [loading, setLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [metaData, setMetaData] = useState(false);
   const [efficiency, setEfficiency] = useState(false);
   const [fieldReport, setFieldReport] = useState(false);



   useEffect(()=>{
        // getSupervisorDistribution();
        getDailyCount();
        getRecentTransactions();
        getMonthlyCount();
        getTotalSupervisors();
        getSupervisorList();
        getEfficiency();
        
     },[]
   )


  useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])


  useEffect(()=>{
    getScanRecords();
  }, [data])


   const getRecentTransactions=async()=>{
    setRecentTransactions(false);
    const server_response = await ajaxSupervisor.fetchRecentTransactions();
    if(server_response.status==="OK"){
      setRecentTransactions(server_response.details.content)
    }else{

      setRecentTransactions("404");
    }
  }






    const  getSupervisorDistribution=async()=>{

        const server_response = await ajaxSupervisor.getSupervisorDistribution()
        if (server_response.status === "OK") {
            setDistributionList(server_response.details.content);
        }else{
            setDistributionList("404")
        }
    }

    const  getDailyCount=async()=>{
        setDailyCount("...");
        const server_response = await ajaxSupervisor.countDailyScans()
        if (server_response.status === "OK") {
            setDailyCount(server_response.details.content.total_p);
        }
    }


    const  getMonthlyCount=async()=>{

      setMonthlyCount("...");
      const server_response = await ajaxSupervisor.fetchMonthlyCount()
      if (server_response.status === "OK") {
          setMonthlyCount(server_response.details.content.total_p);
      }
  }


    const  getScanRecords=async()=>{

        setScanRecords(false);
        const server_response = await ajaxSupervisor.getScanRecords(data)
        if (server_response.status === "OK") {
            setScanRecords(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setScanRecords("404");
            setMetaData(false)
        }
    }




  const  getTotalSupervisors=async()=>{

      setTotalSupervisors("...");
      const server_response = await ajaxSupervisor.fetchTotalSupervisors()
      if (server_response.status === "OK") {
        setTotalSupervisors(server_response.details.content.total_p);
      }
  }

  const getSupervisorList = async () => {
    const server_response = await ajax.listUsersByRole(0, 8);
    if (server_response.status === 'OK') {
        setSupervisorList(server_response.details.content)
    }
    else {
      setSupervisorList("404")
    }

  }





  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);
    // restoreTable(detectTable());

    const server_response = await ajaxSupervisor.getScanRecords(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.content.list);
        setScanRecords(scanRecords.concat(incoming));
        setMetaData(server_response.details.content.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "page":"1",
        "status":options.status,
        "location_id":options.location,
        "supervisor_id":options.supervisor
    })
    
}


const  getEfficiency=async()=>{

  setEfficiency(false);
  const server_response = await ajaxSupervisor.fetchEfficiency();
  if (server_response.status === "OK") {
      setEfficiency(server_response.details.content);
  }
}

const  getFieldReport=async()=>{

    setFieldReport(false);
    const server_response = await ajaxSupervisor.fetchReport();
    if (server_response.status === "OK") {
        setFieldReport(server_response.details.content);
    }else{
        setFieldReport("404")
    }
  }


    return (
        <SupervisorContext.Provider value={{
            distributionList,
            dailyCount,
            scanRecords,
            loading,

            recentTransactions,
            monthlyCount,
            totalSupervisors,
            supervisorList,

            metaData,
            efficiency,
            fieldReport,
            getSupervisorDistribution,
            getScanRecords,
            fetchMore,
            getRecentTransactions,
            getDailyCount,
            getMonthlyCount,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getEfficiency,
            getFieldReport
         }}
         >
            {props.children}
        </SupervisorContext.Provider>
     );


}

export default SupervisorContext;