import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';

class PopWhiteListVehicle extends React.Component {

    state = {
        vehicleInfo: this.props.vehicleInfo,
        balanceDate: '',
        info: '',
        amount: '',
        comment: '',
        loading: false
    }

   
   
    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeComment = (event) => {
        this.setState({ comment: event.target.value })
    }

    onChangeBalanceDate = (event) => {
        this.setState({ balanceDate: event.target.value })
    }

    onConfirm = async (event) => {
        const {amount, comment, balanceDate}=this.state;
        event.preventDefault();
        if (amount.length > 0 && comment.length > 0 && balanceDate.length>0) {

            this.setState({ loading: true })
            const server_response = await ajax.addToWhiteList(
                this.state.vehicleInfo.plate_number,
                amount,
                balanceDate,
                comment
            );
            this.setState({ loading: false })
            if (server_response.status === "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                }, () => {
                    //functions.closePopup('modal_new_white_list');
                    //this.props.refresh();
                })
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }





    render() {
        return (
            <div className="modal" id="modal_new_white_list">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">White List Vehicle - {this.state.vehicleInfo.plate_number}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Balance Date"
                                    type="date"
                                    onChange={() => this.onChangeBalanceDate}
                                    value={this.state.balanceDate}
                                />

                                <TextInput
                                    label="Commitment - Amount"
                                    type="number"
                                    onChange={() => this.onChangeAmount}
                                    value={this.state.amount}
                                />

                                <TextInput
                                    label="Comment"
                                    type="textarea"
                                    onChange={() => this.onChangeComment}
                                    value={this.state.comment}
                                />

                            </form>


                        </div>
                        <div className="modal-footer">
                            {this.state.loading && <ActivityLoader />}
                            {!this.state.loading &&
                                <>
                                    <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Submit Request</button>
                                    <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopWhiteListVehicle;
