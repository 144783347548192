import { useContext, useEffect } from "react";
import ActivityLoader from "../../../Common/ActivityLoader";
import NoContent from "../../../Common/NoContent";
import TableHeader from "../../../Common/TableHeader";
import dictionary from "../../../../utils/dictionary";
import BranchAnalysisContext from "../../../../Context/Violation/BranchAnalysisContext";
const eformats = dictionary._exportBtnFormats

const BranchAnalysis = () =>{

    const {
        streetClampAnalysis,
        title,
        getStreetClampAnalysis
    } = useContext(BranchAnalysisContext)

    useEffect(()=>{
      if(!streetClampAnalysis){
        getStreetClampAnalysis()
      }
    }, [])


    return(
        <div className="row">
          <div className="col-sm-12 col-xl-12 col-lg-12">

            <TableHeader
                title="Branch enforcement performance"
                subtitle="Analysis by Branch."
            />
                <div className="table-responsive  allView-table border-top">
               {Array.isArray(streetClampAnalysis) && <table id="analy-street-clamps" className="table text-nowrap table-hover mg-b-0 datatable" export-format={eformats}>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{textAlign:'center'}}>{title}<br/><br/></th>
                        </tr>
                        <tr>
                            <th>Street Name</th>
                            <th>Branch Name</th>
                            <th className='text-center'>Total penalties issued</th>
                        </tr>
                    </thead>
                    <tbody>
                        { streetClampAnalysis.map((item, key) =>
                            <tr key={key}>
                                <td>{item.street.street_name} </td>
                                <td> {item.street.branch.branch_name}</td>
                                <td className='text-center'>{item.total_clamps.total_p}</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
                {streetClampAnalysis === "404" && <NoContent />}
                {!streetClampAnalysis && <ActivityLoader />}

            </div>
        </div>
    </div>
    )
}

export default BranchAnalysis;