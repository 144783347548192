import React, { Component } from 'react';
import functions from '../../utils/functions';
import LeftSideBar from '../Common/LeftSideBar';
import RightSideBar from '../Common/RightSideBar';
import TopBar from '../Common/TopBar';

class PageContainer extends Component {
    render() {
        return (
            <div className="page">
            <LeftSideBar />
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
              <TopBar />
              <div className="container-fluid">
                    {this.props.children}
               </div>
            </div>
            <RightSideBar/>
        </div>
        );
    }
}

export default PageContainer;