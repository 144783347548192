import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import TableHeader from '../../../Components/Common/TableHeader'
import PopNewFirebaseNotification from '../../../Components/Common/PopNewNotification'
import ajaxFirebase from '../../../utils/remote/ajaxFirebase'
import AlertSuccess from '../../../Components/Common/AlertSuccess'

class ConfigAppNotifications extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlNewNotification: false,
        mdlDelete: false,
        info: ""
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.getNotificationConfigs();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content
            })
        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    // onClickAdd=()=>{
    //    this.setState({
    //        mdlNewAmount:false
    //    }, ()=>{
    //        this.setState({
    //            mdlNewAmount:<PopNewAmount 
    //                         title="New Wrong Parking Fee"
    //                         buttonLabel="Confirm Amount"
    //                         description="This fee will automatically override the existing amount.
    //                           only a single fee can be active at a time"
    //                         onConfirm={this.onConfirmFee}/>
    //        }, ()=>{
    //         $("#modal_amount_input").modal('show')
    //        })
    //    })
    // }


    // onConfirmFee=async(amount)=>{

    //     const server_response = await ajax.createWrongParkingFee(amount);
    //     if(server_response.status==="OK")
    //     {
    //         $("#modal_amount_input").modal('hide')
    //         this.setState({
    //             toast:<Toast message={server_response.details.message} onClose={this.refreshToast}/>
    //         }, ()=>{

    //             this.getTransactions();
    //         })
    //     }else{
    //         $("#modal_amount_input").modal('hide')
    //         this.setState({
    //             toast:<Toast type={2} message={server_response.details.message} onClose={this.refreshToast}/>
    //         })
    //     }

    // }

    onClickToggle = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
        }, () => {

            const item = this.state.transactionRecord;
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        buttonLabel="Confirm action"
                        title="Confirm switch"
                        description={`Are you sure you want to Turn ${item.notification_status == "ON" ? "OFF" : "ON"} ${item.app_name}  notifications? `}
                        onConfirm={() => this.onConfirmChange()} />
                },

                    () => {
                        $("#modal_delete").modal('show')
                    })
            })

        })
    }

    onClickSMSToggle = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
        }, () => {

            const item = this.state.transactionRecord;
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        buttonLabel="Confirm toggle"
                        title="Confirm action"
                        description={`Are you sure you want to Turn ${item.sms_status == "ON" ? "OFF" : "ON"} ${item.app_name}  SMS? `}
                        onConfirm={() => this.onConfirmSmsChange()} />
                },

                    () => {
                        $("#modal_delete").modal('show')
                    })
            })

        })
    }

    onConfirmChange = async () => {

        if (this.state.transactionId) {
            const item = this.state.transactionRecord;

            $("#modal_delete").modal('hide');
            if (item.notification_status === "OFF") {
                const server_response = await ajax.turnOnNotifications(this.state.transactionId);
                if (server_response.status === "OK") {
                    this.setState({
                        toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
                    }, () => {

                        //Refresh list
                        this.getTransactions();
                    })
                } else {
                    this.setState({
                        toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
                    })
                }

            } else if (item.notification_status === "ON") {

                const server_response = await ajax.turnOffNotifications(this.state.transactionId);
                if (server_response.status === "OK") {
                    this.setState({
                        toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
                    }, () => {

                        //Refresh list
                        this.getTransactions();
                    })
                } else {
                    this.setState({
                        toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
                    })
                }

            }
        }

    }



    onConfirmSmsChange = async () => {

        if (this.state.transactionId) {
            const item = this.state.transactionRecord;

            $("#modal_delete").modal('hide');
            if (item.sms_status === "OFF") {
                const server_response = await ajax.turnOnSms(this.state.transactionId);
                if (server_response.status === "OK") {
                    this.setState({
                        toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
                    }, () => {

                        //Refresh list
                        this.getTransactions();
                    })
                } else {
                    this.setState({
                        toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
                    })
                }

            } else if (item.sms_status === "ON") {

                const server_response = await ajax.turnOffSms(this.state.transactionId);
                if (server_response.status === "OK") {
                    this.setState({
                        toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
                    }, () => {

                        //Refresh list
                        this.getTransactions();
                    })
                } else {
                    this.setState({
                        toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
                    })
                }

            }
        }

    }



    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }

    onClickFirebase = () => {
        this.setState({
            mdlNewNotification: false
        }, () => {
            this.setState({
                mdlNewNotification: <PopNewFirebaseNotification
                    title="New Firebase Notification"
                    buttonLabel="Confirm Notification"
                    description="This notification will automatically be sent to any machine running the PMIS system"
                    onConfirm={this.onConfirmNewNotification} />
            }, () => {
                $("#modal_amount_input").modal('show')
            })
        })
    }


    onConfirmNewNotification = async (title, body) => {

        const server_response = await ajaxFirebase.sendFirebaseNotification(title, body);
        if (server_response.success == 1 && server_response.failure == 0) {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: false,
                info: false
            }, () => this.setState({
                info: <AlertSuccess message={"Notification has been sent"} />
            }))
        } else {
            $("#modal_amount_input").modal('hide')
            this.setState({
                info: false,
                toast: <Toast type={2} message="Failed to send notification" />
            })
        }

    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlDelete}
                {this.state.mdlNewNotification}

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Notifications"
                            previousPageTitle="Configurations"
                            back="/config"
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">

                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                {this.state.info}

                                                <div className="d-flex justify-content-between align-items-center filter-example">

                                                <TableHeader
                                                    title="App notifications"
                                                    subtitle="Configure app notifications by turning them on or off."
                                                    // value="Send main notification"
                                                    // button
                                                    // onClick={this.onClickFirebase}
                                                />

                                                <button
                                                    className="btn ripple btn-success"
                                                    onClick={this.onClickFirebase}
                                                >
                                                    <i className="far fa-edit mr-1"></i> Send notification
                                                </button>

                                                </div>

                                                <div className="swichermainleft allView-config border-top col-11">
                                                    {/* <div className="switch_section "> */}
                                                        {/* col-sm-8 col-xl-9 col-lg-9 */}
                                                        <table className="table hr-table table-borderless text-nowrap mb-0">
                                                            <thead >
                                                                <tr>
                                                                    <th className="">APP NAME</th>
                                                                    <th className="text-center">In-App <br />Notifications</th>
                                                                    <th className="text-center">SMS <br />Notifications</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {this.state.transactionList &&
                                                                    this.state.transactionList !== "404" &&
                                                                    this.state.transactionList.map((item, key) =>
                                                                        <tr
                                                                            className="switch-toggle"
                                                                            key={key}
                                                                            onClick={() => this.onSelectTransaction(item.configuration_id, item)}>

                                                                            <td> <span className="mr-auto">{item.app_name}</span></td>
                                                                            <td className="m-auto">
                                                                                <div
                                                                                    className="onoffswitch2 m-auto"
                                                                                    onClick={this.onClickToggle}
                                                                                //style={{textAlign:'center', justifyContent:'center', margin:'auto'}}
                                                                                >
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="onoffswitch2-checkbox "
                                                                                        defaultChecked={item.notification_status === "ON" ? true : false}
                                                                                    />
                                                                                    <label for="myonoffswitch" className="onoffswitch2-label"></label>
                                                                                </div></td>
                                                                            <td className=""><div className="onoffswitch3 m-auto " onClick={this.onClickSMSToggle}>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="onoffswitch3-checkbox "
                                                                                    checked={item.sms_status === "ON" ? true : false}
                                                                                />
                                                                                <label for="myonoffswitchsms" className="onoffswitch3-label"></label>
                                                                            </div></td>
                                                                        </tr>)}
                                                            </tbody>
                                                        </table>

                                                        {!transactionList && <ActivityLoader />}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfigMenu />
                        </div>
                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );
    }
}

export default ConfigAppNotifications
