import React, { useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import ajaxOperation from '../utils/remote/ajaxOperation';
import { detectTable, restoreTable } from '../utils/script/customTables';

const BranchContext = React.createContext();
const branch_id = functions.extractToken()['branch_id'] || 1
export const BranchConsumer = BranchContext.Consumer;

export const BranchProvider=(props)=>{

   const [metaData, setMetaData] = useState(false);
   const [data, setData] = useState({"page":"1", "branch_id":branch_id})
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [branchId, setBranchId] = useState(branch_id);
   const [branchUserListing, setBranchUserListing] = useState(false);
   const [branchName, setBranchName] = useState("Branch Monitor...")
   const [branchInfo, setBranchInfo] = useState(false);
   const [totalLocations, setTotalLocations] = useState("...");
   const [totalSlots, setTotalSlots] = useState("...");
   const [branchListing, setBranchListing] = useState(false);
   const [sessionCount, setSessionCount] = useState("...");
   const [clampCount, setClampCount] = useState("...");
   const [branchLocations, setBranchLocations] = useState(false);


   useEffect(()=>{
        listBranches();
   }, [])
   
   useEffect(()=>{
    getBranch();
    countSessions();
    countClamps();
   }, [branchId])

   useEffect(()=>{
    listBranchUsers();
   }, [data, branchId])

  
   useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
    }, [currentPage])


    const listBranchUsers = async()=>{
        setBranchUserListing(false)
        setMetaData(false)
        const server_response = await ajaxOperation.fetchBranchUsers(data);
        if(server_response.status === "OK"){
            setBranchUserListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta)
        }else{
            setBranchUserListing("404")
            setMetaData(false)
        }
    }


    const getBranch=async()=>{
        setBranchName("Branch Monitor...")
        const server_response = await ajaxOperation.fetchBranchInfo(branchId);
        if(server_response.status === "OK"){
            setBranchInfo(server_response.details.content);
            setBranchName(server_response.details.content.branch_name + " Branch");
            setTotalLocations(server_response.details.content.total_locations.total_p);
            setTotalSlots(server_response.details.content.total_slots.total_p)

        }else{
            setBranchInfo("404")
        }
    }

   

    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());
        const server_response = await ajaxOperation.fetchBranchUsers(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setBranchUserListing(branchUserListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        const data={
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "invoice_class_id":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        }
        setData(data)
        
    }



    const listBranches=async()=>{
        const server_response = await ajax.listBranches();
        if(server_response.status === "OK"){
            setBranchListing(server_response.details.content)
        }else{
            setBranchListing("404")
        }
    }


    const switchBranch=(branchId)=>{
        setBranchId(branchId)
        setData({"page":"1", "branch_id":branchId})
    }


    const countSessions=async()=>{
        setSessionCount("...")
        const server_response = await ajaxOperation.countBranchActiveSessions(branchId);
        if(server_response.status === "OK"){
            setSessionCount(server_response.details.content.total_p)
        }
    }


    const countClamps=async()=>{
        setClampCount("...")
        const server_response = await ajaxOperation.countBranchClampedVehicles(branchId);
        if(server_response.status === "OK"){
            setClampCount(server_response.details.content.total_p)
        }
    }


    const refresh=()=>{
        countSessions();
        countClamps();
        listBranchUsers();
    }



        return (
           <BranchContext.Provider 
            value={{
                branchUserListing,
                loading,
                metaData,
                branchName,
                branchInfo,
                totalLocations,
                totalSlots,
                branchListing,
                sessionCount,
                clampCount,
                switchBranch,
                goToNextPage,
                resetData,
                filterData,
                listBranchUsers,
                refresh
            }}
            >
               {props.children}
           </BranchContext.Provider>
        );
}

export default BranchContext;