import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'

import TableHeader from '../../../Components/Common/TableHeader'
import FilterBar from '../../../Components/Common/FilterBar'

import PageNav from '../../../Components/Common/PageNav'
import WarnSecureDelete from '../../../Components/WarnSecureDelete'
import Toast from '../../../Components/Common/Toast'
import PopManageBankDeposit from '../../../Components/Payments/BankDeposit/PopManageBankDeposit'
import ajaxBankDeposit from '../../../utils/remote/ajaxBankDeposit'
import ajaxVendor from '../../../utils/remote/ajaxVendor'
import CustomizedFilterBar from '../../../Components/Common/CustomizedFilterBar'

class BankPaymentRecords extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        toast: false,
        filter: false,
        vendors: false,
        status: '',
        pagination: false,
        recordView: false,
        currentRecord: false,
        popManageRecord: false,
        mdlWarn: false
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                status: this.props.location.state.status
            }, () => this.listAllRecords())
        } else {
            this.listAllRecords();
        }
        this.getAllVendors();

    }


    componentWillUnmount() {
        //clearTimeout(this.timerCountSession)
    }





    listAllRecords = async () => {
        if (this.state.filter) {
            const filter = this.state.filter;
            // var server_response = await ajax.filterWhiteList(
            //     filter.dateFrom,
            //     filter.dateTo,
            //     filter.selectedValue,
            //     filter.keyword,
            //     this.state.currentPage,
            //     this.state.status
            // );
            var server_response = await ajaxBankDeposit.listBankDepositRecords(this.state.status, this.state.currentPage);


        } else {
            var server_response = await ajaxBankDeposit.listBankDepositRecords(this.state.status, this.state.currentPage);
        }
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())

        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }
    }







    buttons = () => {
        const { transactionList } = this.state;
        return (
            <>


                {transactionList && transactionList !== "404" && <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>}

            </>
        )
    }

    getAllVendors = async () => {
        const server_response = await ajaxVendor.getVendorRecords();
        if (server_response.status === "OK") {
            this.setState({
                vendors: server_response.details.content.list,
            })
        }
    }


    onFilter = async (filter) => {
        this.setState({
            filter: false,
            transactionList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllRecords())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            transactionList: false
        }, () => this.listAllRecords())
    }


    onChangeStatus = (status) => {
        this.setState({
            status: status,
            transactionList: false,
            metaData: false,
            currentPage: 1
        }, () => this.listAllRecords())
    }



    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                page: this.state.page + 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    onClickPage = (page) => {
        this.setState({
            page: page,
            transactionList: false
        }, () => this.listAllRecords())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                page: this.state.page - 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {

                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }

    //==============NAVIGATION CONTROLS END===========================

    onClickManageRecord = (currentRecord) => {
        this.setState({
            currentRecord: currentRecord,
            popManageRecord: false
        }, () => {

            this.setState({
                popManageRecord: <PopManageBankDeposit
                    record={this.state.currentRecord}
                    handleApproval={this.onClickHandleRequest} />
            }, () => functions.openPopup('mdl-manage-bank-deposit'))
        })
    }



    /**
    * INVOKES THE HANDLE REQUEST DIALOGUE
    */
    onClickHandleRequest = (status) => {
        functions.closePopup('mdl-manage-bank-deposit');
        var { title, description, buttonLabel, buttonType } = "";
        this.setState({
            status: status
        })
        const transaction = this.state.currentRecord;
        switch (status) {
            case 2://cancelling
                title = "Cancel Bank Deposit";
                description = "Please confirm with your account password that you want to cancel this bank deposit";
                buttonLabel = "Cancel Bank Deposit";
                buttonType = "btn-danger"
                break;
            case 1://approving

                title = transaction.next_status;
                description = "Please confirm with your account password that you want to approve this bank deposit";
                buttonLabel = transaction.next_status
                buttonType = "btn-success"

                break;
            case 3://process
                title = transaction.next_status;
                description = "Please confirm with your account password that you want to process this cheque";
                buttonLabel = transaction.next_status;
                buttonType = "btn-primary"
                break;


            default:
                title = "";
                description = "";
                buttonLabel = "";
                break;
        }

        this.setState({
            mdlWarn: false
        }, () => {

            this.setState({
                mdlWarn: <WarnSecureDelete
                    title={title}
                    description={description}
                    icon="fas fa-question"
                    buttonLabel={buttonLabel}
                    buttonType={buttonType}
                    onConfirm={this.confirmHandleRequest}
                />
            }, () => {
                functions.openPopup('modal_secure_delete')
            })
        })
    }


    /**
    * EFFECT THE HANDLE REQUEST ACTION
    */
    confirmHandleRequest = async (password) => {
        this.setState({
            loading: true
        })
        var server_response;
        switch (this.state.status) {
            case 2://CANCEL REQUEST
                server_response = await ajaxBankDeposit.cancelBankDeposit(
                    this.state.currentRecord.record_id,
                    password
                );

                break;
            case 1://APPROVE REQUEST
                server_response = await ajaxBankDeposit.approveBankDeposit(
                    this.state.currentRecord.record_id,
                    password
                );

                break;
            case 3://PROCESS REQUEST
                server_response = await ajaxBankDeposit.processBankDeposit(
                    this.state.currentRecord.record_id,
                    password
                );
                break;

            default:
                server_response = { 'status': 'conn', 'details': { 'message': 'Invalid request', 'content': false } }
                break;
        }
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast
                    message={server_response.details.message}
                    onClose={() => this.resetToast()} />
            })
            this.listAllRecords()
        } else {
            this.setState({
                toast: <Toast
                    type={4}
                    message={server_response.details.message}
                    onClose={() => this.resetToast()} />
            })
        }
    }




    resetToast = () => {
        this.setState({
            toast: false
        })
    }





    render() {
        const transactionList = this.state.transactionList;
        const filter = this.state.filter
        // const userList = this.state.userList;
        const userOptions = [];
        this.state.vendors && this.state.vendors.map((item, key) =>
            userOptions.push({
                title: item.username,
                value: item.user_id
            })
        );

        return (
            <div className="page">




                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Bank Deposits"
                            previousPageTitle="Finance"
                            // options={this.state.menuOptions}
                            back="/finance"
                            buttons={<this.buttons />}
                        />



                        <CustomizedFilterBar
                            selection={{ title: 'Recorded By', options: userOptions }}
                            keyword="keyword"
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        />



                        {this.state.toast}
                        {this.state.popManageRecord}
                        {this.state.mdlWarn}

                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Bank Deposit Records"
                                            subtitle="List of all payments deposited by clients in the bank."
                                        />


                                        <div className="text-wrap">
                                            <div className="example">
                                                <div className="btn-list">

                                                    <button type="button"
                                                        className="btn ripple btn-primary btn-sm"
                                                        onClick={() => this.onChangeStatus("")}>
                                                        All Deposits <span className="badge badge-light ml-2">...</span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn ripple btn-secondary btn-sm"
                                                        onClick={() => this.onChangeStatus(0)}>
                                                        Pending Deposits <span className="badge badge-dark ml-2">...</span>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn ripple btn-success btn-sm"
                                                        onClick={() => this.onChangeStatus(1)}>
                                                        Approved Deposits <span className="badge badge-dark ml-2">...</span>
                                                    </button>


                                                </div>
                                            </div>
                                        </div>


                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-hover table-ellipsis">
                                                <thead>
                                                    <tr>
                                                        <th>Record Date</th>
                                                        <th>Deposit Date</th>
                                                        <th>Bank</th>
                                                        <th>Deposit Ref.</th>
                                                        <th>Amount(UGX)</th>
                                                        <th>Status</th>
                                                        <th>Account Name</th>
                                                        <th>Account Contact</th>
                                                        <th>Recorded By</th>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactionList && transactionList !== "404" && transactionList.map((item, key) =>
                                                        <tr key={key}>
                                                            <td>{item.created_at.short_date}</td>
                                                            <td>{item.deposit_date.short_date}</td>
                                                            <td>{item.bank.bank_name}</td>
                                                            <td>{item.bank_ref}</td>
                                                            <td>{item.amount.amount_c}</td>

                                                            <td>
                                                                <Link to="#" onClick={() => this.onClickManageRecord(item)}>
                                                                    {item.status.status}
                                                                </Link>
                                                            </td>
                                                            <td>{item.local_account.username}</td>
                                                            <td>{item.local_account.phone_number}</td>
                                                            <td>{item.created_by.username}</td>

                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>


                                            {transactionList && this.state.pagination}
                                            {!transactionList && <ActivityLoader />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );

    }
}

export default BankPaymentRecords
