import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';
import $ from 'jquery';
import AlertWarning from '../Common/AlertWarning';


class PopExport extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        noOfRecords: '',
        info: '',
        searchList: false,
        conductorList: false,
        conductorRecord: false,
        specifiedRecord: "",
        recordlabel: "",
        hideButton: false,
        isOccupancy: this.props.isOccupancy,
        occupancyIsSet: "",
        turnOverIsSet: ""
    }

    componentDidMount() {
        // this.getConductor()
    }

    onChangenoOfRecords = (event) => {

        this.setState({
            specifiedRecord: event.target.value
        })

    }

    onConfirm = (event) => {
        event.preventDefault();
        if (this.state.noOfRecords.length > 0 && this.state.conductorRecord) {
            this.props.onConfirm(this.state.conductorRecord, this.state.noOfRecords);
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }

    onClicknoOfRecords = (noOfRecords) => {
        this.setState({ noOfRecords })
    }




    searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(this.state.noOfRecords);
        if (server_response.status === "OK") {
            this.setState({
                searchList: server_response.details.content
            })
        } else {
            this.setState({
                searchList: false
            })
        }
    }



    // getConductor = async () => {

    //     const server_response = await ajax.listAllocatedConductors();
    //     if (server_response.status === "OK") {
    //         this.setState({
    //             conductorList: server_response.details.content.list
    //         })
    //     } else {
    //         this.setState({
    //             conductorList: "404"
    //         })
    //     }
    // }

    onChangeRecords = (selectedOption) => {
        this.setState({
            noOfRecords: "",
            recordlabel: ""
        }, () => {
            this.setState({
                noOfRecords: selectedOption.value,
                recordlabel: selectedOption.label
            })
        })

        // console.log(selectedOption.label)
    }



    onChangeOccupancySelection = (selectedOption) => {

        if (selectedOption.value === "1") {
            this.setState({
                occupancyIsSet: true,
                turnOverIsSet: ""

            })
        } else {
            this.setState({
                turnOverIsSet: true,
                occupancyIsSet: ""

            })
        }
    }




    handleClick = () => {
        // e.preventDefault();
        this.setState({
            info: <AlertWarning message="Your download will start shortly please wait..." />
        })
        setTimeout(() => {
            $("#modal_export").modal('hide');
        }, 3000);
    }


    handleAllRecordsClick = () => {
        // e.preventDefault();

        this.setState({
            hideButton: true,

            info: <AlertWarning message="Due to many records being retrived, Your download might take a while. It will start shortly after the popup has closed, please wait..." />
        })
        setTimeout(() => {

            $("#modal_export").modal('hide');

        }, 30000);
    }


    // setTimeout(() => {
    //     this.setState({
    //         info: <ActivityLoader />,
    //     })
    // }, 2000);


    render() {

        // const conductorList = this.state.conductorList;

        const optionsExport = [
            // { "label": "All records", "value": "0" },
            { "label": "5", "value": "8" },
            { "label": "10", "value": "10" },
            { "label": "15", "value": "15" },
            { "label": "20", "value": "20" },
            { "label": "25", "value": "25" },
            { "label": "30", "value": "30" },
            { "label": "35", "value": "35" },
            { "label": "40", "value": "40" },
            { "label": "45", "value": "45" },
            { "label": "50", "value": "50" }
            // { "label": "Specifiy desired records", "value": this.state.specifiedRecord },


        ];

        const occupancyList = [
            { "label": "Average Occupancy", "value": "1" },
            { "label": "Average Turn Over ", "value": "2" }

        ];





        // console.log()

        // if (conductorList && conductorList !== "404") {
        //     conductorList.map((item, key) =>
        //         optionsConductor.push({ label: item.user_profile.first_name + ' ' + item.user_profile.last_name, value: item.user_profile })
        //     )
        // }


        const searchList = this.state.searchList;

        return (
            <div className="modal" id="modal_export">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Select number of records you wish to export</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form>

                                <SelectSearch
                                    label="Select number of records"
                                    onChange={() => this.onChangeRecords}
                                    options={optionsExport}
                                    placeholder={"Select number of records to export..."}
                                />

                                {this.state.isOccupancy && <SelectSearch
                                    label="Choose one of the items bellow that you wish to export."
                                    onChange={() => this.onChangeOccupancySelection}
                                    options={occupancyList}
                                    placeholder={"choose report to be export"}
                                />}

                                {this.state.recordlabel === "Specifiy desired records" && <TextInput
                                    label="Specify number of records"
                                    type="text"
                                    onChange={() => this.onChangenoOfRecords}
                                    value={this.state.specifiedRecord}
                                />}

                                {this.state.recordlabel == "All records" && <p className="text-danger">* Please note that this option is not usually recommended. Exporting all records might take a while.</p>}
                            </form>

                            {/* {searchList && searchList !== "404" && <div className="btn-list">
                                {searchList.map((item, key) =>
                                    <button key={key}
                                        onClick={() => this.onClicknoOfRecords(item.plate_number)}
                                        // type="button"
                                        href={dictionary.apiHost + this.props.excelUrl}
                                        className="btn ripple btn-light btn-sm">
                                        {item.plate_number}
                                    </button>)}
                            </div>} */}


                        </div>
                        <div className="modal-footer">
                            {!this.state.isOccupancy &&

                                !this.state.hideButton && <>

                                    {this.state.recordlabel !== "All records" && this.state.recordlabel !== "Specifiy desired records" &&
                                        <a
                                            className="btn ripple btn-primary"
                                            href={dictionary.apiHost + this.props.pdfUrl + "no_of_records=" + this.state.noOfRecords}
                                            onClick={this.handleClick}
                                        >
                                            Submit
                                        </a>
                                    }

                                    {this.state.recordlabel === "Specifiy desired records" &&
                                        <a
                                            className="btn ripple btn-primary"
                                            href={dictionary.apiHost + this.props.pdfUrl + "no_of_records=" + this.state.specifiedRecord}
                                            onClick={this.handleClick}
                                        >
                                            Submit
                                        </a>
                                    }

                                    {this.state.recordlabel === "All records" &&
                                        <a
                                            className="btn ripple btn-primary"
                                            href={dictionary.apiHost + this.props.pdfUrl}
                                            onClick={this.handleAllRecordsClick}
                                        >
                                            Submit
                                        </a>
                                    }

                                </>}

                            {this.state.isOccupancy && <>

                                {this.state.recordlabel !== "All records" && this.state.recordlabel !== "Specifiy desired records" &&
                                    <a
                                        className="btn ripple btn-primary"
                                        href={dictionary.apiHost + this.props.pdfUrl + "avg_turnover=" + this.state.turnOverIsSet + "&avg_occupancy=" + this.state.occupancyIsSet + "&no_of_records=" + this.state.noOfRecords}
                                        onClick={this.handleClick}
                                    >
                                        Submit
                                    </a>
                                }

                                {this.state.recordlabel === "Specifiy desired records" &&
                                    <a
                                        className="btn ripple btn-primary"
                                        href={dictionary.apiHost + this.props.pdfUrl + "avg_turnover=" + this.state.turnOverIsSet + "&avg_occupancy=" + this.state.occupancyIsSet + "&no_of_records=" + this.state.specifiedRecord}
                                        onClick={this.handleClick}
                                    >
                                        Submit
                                    </a>
                                }

                                {this.state.recordlabel === "All records" &&
                                    <a
                                        className="btn ripple btn-primary"
                                        href={dictionary.apiHost + this.props.pdfUrl + "avg_turnover=" + this.state.turnOverIsSet + "&avg_occupancy=" + this.state.occupancyIsSet}
                                        onClick={this.handleAllRecordsClick}
                                    >
                                        Submit
                                    </a>
                                }

                            </>}
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )


    }
}

export default PopExport;
