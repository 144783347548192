import React from 'react';
import { FinanceConsumer } from '../../../Context/FinanceContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import StatisticCard from '../../StatisticCard';


class MonthlyParkingRevenue extends React.Component {
  state={
    userId:functions.sessionGuard(),
  }

 

  render(){
    return(
    <FinanceConsumer>
    {props=>{
      return(
        <StatisticCard
        title="Parking Revenue" 
        value={props.monthlyRevenue}
        caption="This month"
        icon={"far fa-clock"}
        color={"text-secondary"}
        progressbar={"progress-bar progress-bar-xs wd-60p bg-secondary"}
        />
      )
    }}
    </FinanceConsumer>
    )
  }
  
}

export default MonthlyParkingRevenue;
