import React from 'react'
import { Link } from 'react-router-dom';
import { OperationConsumer } from '../Context/OperationContext';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ActivityLoader from './Common/ActivityLoader';
import NoContent from './Common/NoContent';
import TableHeader from './Common/TableHeader';
import PopSessionLocation from './Operations/PopSessionLocation';

class RecentSessions extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        sessionList: false,
        locationPIN: false
    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    ViewAllSessions = () => {
        this.props.history.push('/parking_sessions/sessions')
    }


    render() {
        return (
          <OperationConsumer>
              {props=>{
                  const sessionList = props.recentSessionList
                  return(
                    <div className="card custom-card ">
                    <div className="card-body map-card">
    
                        <TableHeader
                            title="Recent Sessions"
                            subtitle="A representation of the recent session activity."
                            view="View All"
                            href={this.props.href}
                        />
    
                        {/* <div className="border-top mt-3"></div> */}
                        {this.state.locationPIN}
                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                            <div className="table-responsive">
                                <table className="table table-hover text-nowrap mg-b-0">
                                    <thead>
                                        <tr>
                                            <th>Session ref</th>
                                            
                                            <th>Start Time</th>
                                            <th>Vehicle</th>
                                            
                                            {/* <th>Street</th> */}
                                            <th>Slots</th>
                                            <th>Status</th>
                                            <th>Location</th>
    
                                            {/* <th>Device ID</th> */}
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        (sessionList === "404")
                                        ?
                                        <tr>
                                            <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="7">
                                                {/* <img alt="no-content" src={nocontent} /> */}
                                                <NoContent />
                                            </td>
                                        </tr>
                                        :
                                        sessionList && sessionList !== "404" &&
                                            sessionList.map((item, key) =>
                                                <tr key={key}>
                                                    {/* <td>
                                                            {item.start_time.long_date}
                                                        </td> */}
                                                    <td>
                                                        {item.session_id_c}
                                                    </td>
                                                    <td>
                                                        {item.start_time.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.start_time.time}</small>
                                                    </td>
                                                    <td className="tx-bold">
                                                        <Link to={
                                                            {
                                                                pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                state: { plateNumber: item.vehicle.plate_number }
                                                            }}
                                                        >
                                                            {item.vehicle.plate_number}
                                                        </Link>
                                                    </td>
                                                    
                                                    
                                                    <td>
                                                        {item.number_of_slots}
                                                    </td>
                                                    <td>
                                                        {item.active === "1" && <span className="badge rounded badge-success">Active</span>}
                                                        {item.active === "0" && <span className="badge rounded badge-secondary">Ended</span>}
                                                    </td>
                                                    <td>
                                                        <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                            <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                            {item.location.location_name}<br />
                                                            <small className="tx-12 tx-gray-500">{item.location.street.street_name}</small>
                                                        </span>
                                                    </td>
                                                    {/* <td>
                                                        {item.start_device.device_number}
                                                    </td> */}
                                                    
                                                </tr>
                                            )}
    
    
                                    </tbody>
    
                                </table>
                                {!sessionList && <ActivityLoader />}
                            </div>
                        </div>
                    </div>
                </div>
    
                  )
              }}
          </OperationConsumer>
        )
    }

}

export default RecentSessions
