import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PopClampVehicle from "./PopClampVehicle"
import functions from "../../../utils/functions";
import PopRespondClamp from "./PopRespondClamp";
import PopTowVehicle from "./PopTowVehicle";
import PopRespondUnClamp from "./PopRespondUnClamp";
import PopUnClamp from "./PopUnClamp";

const ClampActionButton=(props)=>{

    const [modal, setModal] = useState(false);
    const [modalId, setModalId] = useState(false);
    useEffect(()=>{
        if(modal){
            functions.openPopup(modalId)
        }
    }, [modal])

    const handleConfirmClamp=()=>{
        props.action("modal_confirm_clamp", ()=><PopClampVehicle record={props.clampRecord}/>)
    }

    const handleRespond=()=>{
        props.action("modal_respond_clamp", ()=><PopRespondClamp record={props.clampRecord}/>)

    }

    const handleTow=()=>{
        props.action("modal-tow-vehicle", ()=><PopTowVehicle record={props.clampRecord}/>)

    }

    const handleRespondUnclamp=()=>{
        props.action("modal_respond_unclamp", ()=><PopRespondUnClamp record={props.clampRecord}/>)

    }


    const handleUnclamp=()=>{
        props.action("modal_unclamp", ()=><PopUnClamp record={props.clampRecord}/>)

    }

    if(!props.clampRecord){
        return <></>
    }

    if(props.clampRecord.incident){
        return <></>
    }

    switch(props.clampRecord.next_action.status_id){
        case 3://Respond to clamp
            return  <Link to="#" className="btn ripple btn-success" onClick={handleRespond}>
                        <i className="fa fa-gavel mr-1"></i>{props.clampRecord.next_action.action}
                    </Link>

        case 4://Clamp Vehicle
            return  <>
                        <Link to="#" className="btn ripple btn-danger" onClick={handleConfirmClamp}>
                            <i className="fa fa-gavel mr-1"></i>{props.clampRecord.next_action.action}
                        </Link>
                    </>

        case 9://Tow Vehicle
                    return  <>
                        <Link to="#" className="btn ripple btn-danger" onClick={handleTow}>
                            <i className="fa fa-truck-monster mr-1"></i>{props.clampRecord.next_action.action}
                        </Link>
                    </>

        case 6://Respond to unclamp
            return  <Link to="#" className="btn ripple btn-light" onClick={handleRespondUnclamp}>
                        <i className="fa fa-gavel mr-1"></i>{props.clampRecord.next_action.action}
                    </Link>

        case 7://Respond to unclamp
            return  <Link to="#" className="btn ripple btn-warning" onClick={handleUnclamp}>
                    <i className="fa fa-gavel mr-1"></i>{props.clampRecord.next_action.action}
                </Link>

        default:
            return <></>

    }

}

export default ClampActionButton