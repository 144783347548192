import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import NotificationAvatar from '../../Components/Common/NotificationAvartar';
import { NotificationConsumer } from '../../Context/NotificationContext';
import { Link } from 'react-router-dom';


class ListRecentNotifications extends React.Component {

    state = {
        // userId: functions.sessionGuard(),
        notificationsList: "",
        readNotificationList: "",
        countNotificationList: "",
        recentList: ""

    };

    // componentDidMount() {
    //     this.getRecentNotifications();
    //     this.countUnreadMessages();
    //     this.timerlistTransactions = setTimeout(this.functransactionList = async () => {
    //         await this.getRecentNotifications();
    //         // await this.countUnreadMessages();
    //         this.timerlistTransactions = setTimeout(this.functransactionList, dictionary._waitTime); // (*)
    //     }, dictionary._waitTime);
    // }

    // componentWillUnmount() {
    //     clearTimeout(this.timerlistTransactions)
    // }


    getUserNotifications = async () => {
        const server_response = await ajax.getUserNotifications(this.state.userId);
        if (server_response.status === 'OK') {
            this.setState({
                notificationsList: server_response.details.content
            })
        }
        else {
            this.setState({
                notificationsList: "404"
            })
        }

    }


    // getRecentNotifications = async () => {
    //     const server_response = await ajax.getRecentNotifications(this.state.userId);
    //     if (server_response.status === 'OK') {
    //         this.setState({
    //             recentList: server_response.details.content
    //         })
    //     }
    //     else {
    //         this.setState({
    //             recentList: "404"
    //         })
    //     }

    // }


    readNotification = async (notification_id) => {
        const server_response = await ajax.readNotification(notification_id);
        if (server_response.status === 'OK') {

            this.setState({
                notificationsList: false
            }, () => {
                // this.getUserNotifications()
                //this.countUnreadMessages()
            })
        }
        else {
            this.setState({
                readNotificationList: "404"
            })
        }
    }


    countUnreadMessages = async () => {
        const server_response = await ajax.countUnreadNotifications(this.state.userId);
        if (server_response.status === 'OK') {
            this.setState({
                countNotificationList: server_response.details.content
            }, () => {
                this.getUserNotifications()
            })
        }
        else {
            this.setState({
                countNotificationList: "404"
            })
        }
    }

    // onClickRecord = (selectedNotificationId, notificationInfo, app_name) => {
    //     this.setState({
    //         selectedNotificationId: selectedNotificationId,
    //         notificationInfo: notificationInfo
    //     }, async () => {
    //         await this.readNotification(selectedNotificationId)
    //         if (app_name === "CLAMPING") {
    //             window.location.href = "/enforcement/clamp_transactions"
    //         } else if (app_name === "PAYMENT_DUE") {
    //             window.location.href = "/contraventions/payment_due"
    //         } else if (app_name === "WRONG_PARKING") {
    //             window.location.href = "/contraventions/wrong_parking"
    //         } else if (app_name === "TOWING") {
    //             window.location.href = "/enforcement/tow_transactions"
    //         } else if (app_name === "CONFIGS") {
    //             window.location.href = "/config"
    //         } else if (app_name === "USERS") {
    //             window.location.href = "/users"
    //         } else if (app_name === "PAYMENT") {
    //             window.location.href = "/finance/new-payment"
    //         } else if (app_name === "STICKERS") {
    //             window.location.href = "/finance/new-sticker"
    //         } else if (app_name === "SURCHARGE") {
    //             window.location.href = "/config/surcharge"
    //         } else if (app_name === "INVOICES") {
    //             window.location.href = "/invoices"
    //         } else if (app_name === "RECEIPTS") {
    //             window.location.href = ""
    //         } else if (app_name === "SESSION") {
    //             window.location.href = "/sessions"
    //         } else if (app_name === "PERMISSION_REQUESTS") {
    //             window.location.href = "/config/permissions/requests"
    //         }
    //     })
    // }

    linkMapper = (appName) => {
        var url
        switch (appName) {
            case "CLAMPING":
                url = "/enforcement/clamp_transactions"
                break;
            case "PAYMENT_DUE":
                url = "/contraventions/payment_due"
                break;
            case "WRONG_PARKING":
                url = "/contraventions/wrong_parking"
                break;
            case "TOWING":
                url = "/enforcement/tow_transactions"
                break;
            case "CONFIGS":
                url = "/config"
                break;
            case "USERS":
                url = "/users"
                break;
            case "PAYMENT":
                url = "/finance/new-payment"
                break;
            case "STICKERS":
                url = "/finance/new-sticker"
                break;
            case "SURCHARGE":
                url = "/config/surcharge"
                break;
            case "INVOICES":
                url = "/invoices"
                break;
            case "RECEIPTS":
                url = ""
                break;
            case "SESSION":
                url = "/config/surcharge"
                break;
            case "PERMISSION_REQUESTS":
                url = "/config/permissions/requests"
                break;
            case "WHITE LISTING":
                url = "/whitelisting"
                break;
            default:
                url = "#"
                break
        }

        return url;

    }

    render() {


        return (
            <NotificationConsumer>
                {props => {
                    const { recentList } = props;

                    return (

                        <div className={recentList.total_records <= 6 ? "main-notification-list" : " main-notification-list notify-height"}>
                            {recentList.total_records === 0 ? <div className="dropdown-footer">
                                <span>You don`t have any <strong>recent</strong> Notifications!</span>
                            </div> :
                                recentList.notifications &&
                                recentList.notifications.map((item, key) =>
                                    <div className="media notification-media" key={key}>
                                        <div className="main-img-user mr-1">
                                            <NotificationAvatar value={item.app_info.app_name === "STICKERS" ? "ST" : item.app_info.app_initials} /></div>
                                        <div className="media-body"
                                        // onClick={() => this.onClickRecord(item.notification_id, item, item.app_info.app_name)}
                                        >
                                            <Link to={() => this.linkMapper(item.app_info.app_name)}>
                                                <p className="notify-text">{item.notification}</p>
                                                <span>{item.created_at.long_date}</span>
                                            </Link>
                                        </div>
                                        {item.status == "UNREAD" &&
                                            <div className="task-icon-user bg-info m-1"></div>}
                                    </div>)}
                        </div>

                    )
                }}
            </NotificationConsumer>
        );
    }
}

export default ListRecentNotifications;
