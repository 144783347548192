import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'
import SelectSearch from '../../../Components/Common/SelectSearch'
import AlertWarning from '../../../Components/Common/AlertWarning'
import ajaxViolation from '../../../utils/remote/ajaxViolation'
import ActivityLoader from '../../../Components/Common/ActivityLoader'

class UpdateClampTool extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    clampNumber: '',
    clampToolId: '',
    info: '',
    clampToolAvailability: '',
    clampToolUse: '',
    loading: false
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/config/clamp_tools');
    } else {
      const clampRecord = this.props.location.state.record;
      this.setState({
        clampNumber: clampRecord.clamp_number,
        clampToolId: clampRecord.clamp_tool_id,
        clampToolUse: clampRecord.in_use
      })
    }
  }


  onChangeclampNumber = (event) => {
    this.setState({ clampNumber: event.target.value })
  }

  onChangeClampToolStatus = (selectedOption) => {
    this.setState({
      clampToolAvailability: selectedOption.value
    })
  }

  onSave = async (event) => {
    event.preventDefault();
    const { clampNumber, clampToolId} = this.state;
    if (clampNumber.length > 0 && clampToolId.length > 0) {
      const data = {
        clamp_number : clampNumber,
        tool_id : clampToolId
      }
      //fetch login from the API
      this.setState({
        loading : true
      })
      const server_response = await ajaxViolation.updateClampTool(data);
      this.setState({
        loading : false
      })
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.message} />
        })

      } else {

        this.setState({
          info: <AlertWarning message={server_response.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._requireclampNumber} />,
        loading: ''
      })
    }


  }




  render() {
    const optionsClampStatus = [];
    const clampToolStatus = [
      {
        "in_use": 0,
        "label": "AVAILABLE"
      },
      // {
      //   "in_use": 1,
      //   "label": "INUSE"
      // }
    ]
    const optionsStreet = [];
    if (clampToolStatus) {
      clampToolStatus.map((item, key) =>
        optionsClampStatus.push({ label: item.label, value: item.in_use })
      )
    }
    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Edit Clamp Tool "
              previousPageTitle="Clamp Tool "
              back="/config/clamp_tools"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>


                          <TextInput
                            label="Clamp Number"
                            type="text"
                            onChange={() => this.onChangeclampNumber}
                            value={this.state.clampNumber}
                          />

                          {this.state.clampToolUse !== "AVAILABLE" &&
                            <SelectSearch
                              label="ClampTool Status"
                              onChange={() => this.onChangeClampToolStatus}
                              options={optionsClampStatus}
                              placeholder={"Select ClampTool status..."}
                            />
                          }


                          <div className="modal-footer">
                            {!this.state.loading && <input className="btn ripple btn-success" type="button" value="Save Changes" onClick={this.onSave} />}
                            {this.state.loading && <ActivityLoader/>}
                          </div>
                        </form>

                      </div>
                    </div>


                  </div>


                </div>

              </div>

              <ConfigMenu />



            </div>

          </div>
        </div>
        <RightSideBar />

      </div>


    );

  }
}

export default UpdateClampTool
