import React, { useEffect, useState } from 'react';
import dictionary from '../../utils/dictionary';
import ajaxBankDeposit from '../../utils/remote/ajaxBankDeposit';
import TaskBoardCard from './TaskBoardCard';

const BankDepositPayment =()=> {

  const [count, setCount] = useState("...")
  const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(()=>{
      countRecords();
    }, [refreshCounter])

    
    useEffect(()=>{

      setTimeout(() => {
          setRefreshCounter(refreshCounter+1)
      }, dictionary._waitTime);

  }, [refreshCounter])
  

  const countRecords=async()=>{

    const server_response = await ajaxBankDeposit.countPendingBankDeposits();
    if(server_response.status==="OK")
    {
       setCount(server_response.details.content.total_p)
        
    }
  }


        return (
          <TaskBoardCard
            title={"Bank Slip Deposits"}
            value={count}
            caption={"Pending processing"}
            href="/finance/bankdeposit/list"
          />
        );
    }

export default BankDepositPayment;