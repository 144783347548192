import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import PageNav from '../../Components/Common/PageNav'
import { Link } from 'react-router-dom'
import Export from '../../Components/Common/Export'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import dictionary from '../../utils/dictionary'
import PaymentsTrackingContext from '../../Context/Reports/PaymentsTrackingContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
const eformats = dictionary._exportBtnFormats


class PaymentsTracking extends React.Component {

  static contextType = PaymentsTrackingContext;

  state = {
    userId: functions.sessionGuard(),
    countActiveSessions: '...',
    menuOptions: '',
    currentPage: 1,
    transactionList: false,
    metaData: false,
    sessionPreview: '',
    activeSessionId: false,
    toast: false,
    filter: false,
    status: '',
    paymentView: false,
    receiptView: false,
    invoiceView: false,
    pagination: false,
    paymentMethod: '',
    paymentMethods: false,
    cashiersList: ""
  }


  componentDidMount() {


    const { listPaymentTransactions
    } = this.context;
    listPaymentTransactions();

    this.getPaymentMethods();
    this.getCashiersAndSuperAdmins()

  }


  buttons = () => {
    return (
      <>
        <ol className="customButtonList">

          <li>

            <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
            </a>
          </li>
          <li>

            {/* {!this.state.filter && <Export
              excelUrl="export/all/payments"
              pdfUrl="export/all/payments"

            />} */}
          </li>
        </ol>
      </>
    )
  }

  getCashiersAndSuperAdmins = async () => {
    const server_response = await ajax.listCashiersAndSuperAdmins(this.state.userId)
    if (server_response.status === "OK") {
      this.setState({
        cashiersList: server_response.details.content,
      })
    }
  }


  onFilter = async (filter) => {
    this.setState({
      filter: false,
      transactionList: false,
      metaData: false
    }, () => {
      this.setState({ filter: filter }, () => this.listTransactions())
    })
  }

  resetFilter = () => {
    this.setState({
      filter: false,
      transactionList: false
    }, () => this.listTransactions())
  }




  onChangePaymentMethod = (method) => {
    this.setState({
      paymentMethod: method,
      metaData: false,
      currentPage: 1,
      transactionList: false
    }, () => this.listTransactions())
  }



  getPaymentMethods = async () => {

    const server_response = await ajax.getAllPaymentModes();
    if (server_response.status === "OK") {
      this.setState({
        paymentMethods: server_response.details.content
      })
    }
  }


  PaymentOptions = () => {
    const { transactionList, paymentMethods, filter } = this.context;


    const themes = ['btn-secondary', 'btn-success', 'btn-dark'];
    return (
      <div className="text-wrap">
        <div className="example">
          {transactionList !== "404" && filter && <Export
            extraClasses="btn-sm float-right mb-5 "


            excelUrl={"export/filtered/payments?processed_by=" + filter.selectedValue + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&payment_mode=" + filter.selectedValue1}


            pdfUrl={"export/filtered/payments?processed_by=" + filter.selectedValue + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&payment_mode=" + filter.selectedValue1}
          />}

          {paymentMethods && <div className="btn-list">
            <button type="button"
              className="btn ripple btn-primary btn-sm"
              onClick={() => this.onChangePaymentMethod("")}
            >
              All Payments <span className="badge badge-light ml-2">...</span>
            </button>
            {paymentMethods.map((item, key) =>
              <button
                key={key}
                type="button"
                className={`btn ripple ${themes.length > key ? themes[key] : 'btn-light'} btn-sm`}
                onClick={() => this.onChangePaymentMethod(item.mode_id)}
              >
                {item.mode_name}
                {/* <span className="badge badge-dark ml-2">...</span> */}
              </button>
            )}


          </div>}

        </div>
      </div>

    )
  }




  render() {

    const { cashiersList, transactionList, paymentMethods, loading, fetchMore, filterSessions, refreshTransactionData, info, filterInfo, filter } = this.context;

    const userOptions = [];
    cashiersList && cashiersList.map((item, key) =>
      userOptions.push({
        title: item.username,
        value: item.user_id
      })
    );


    const paymentModeOptions = [];
    paymentMethods && paymentMethods.map((item, key) =>
      paymentModeOptions.push({
        title: item.mode_name,
        value: item.mode_id
      })
    );

    return (
      <div className="page">


        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Payment Tracking"
              previousPageTitle="Reports"
              // options={this.state.menuOptions}
              back="#"
              modalContainer={<this.buttons />}
            />

            <CustomizedFilterBar
              selection={{ title: 'Processed By', options: userOptions }}
              selection1={{ title1: 'Payment Mode', options1: paymentModeOptions }}

              onReset={refreshTransactionData}
              onConfirm={filterSessions}
            />

            {this.state.toast}


            <div className="row">
              <div className="col-sm-12 col-xl-12 col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">

                    <TableHeader
                      title="Payments processed by User"
                      subtitle="This report displays the transactions made by a logged in user."
                    />



                    {/* {LocationList !== "404" && filter && <Export
                      extraClasses="btn-sm float-right mb-0"
                      url={"location/filtered/export?street_id=" + filter.selectedValue + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&location_id=" + filter.selectedValue1}
                    />} */}

                    <this.PaymentOptions />



                    <div className="table-responsive">
                      <table id="table-paymentsTracking" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                        <thead>
                          <tr>
                            <th>Date of payment</th>
                            <th>Vehicle</th>
                            <th>Invoice Type</th>
                            <th>Amount Paid (UGX)</th>
                            <th>Payment Mode</th>
                            <th>Processed by (User)</th>
                          </tr>
                        </thead>

                        <tbody>
                          {transactionList && transactionList !== "404" &&
                            transactionList.map((item, key) =>
                              <tr key={key}>
                                <td>{item.receipt.payment_date.when}</td>
                                <td className="tx-bold">
                                  <Link to={
                                    {
                                      pathname: `/vehicles/profile/${item.receipt.invoice.vehicle.plate_number}`,
                                      state: { plateNumber: item.receipt.invoice.vehicle.plate_number }
                                    }}>{item.receipt.invoice.vehicle.plate_number}
                                  </Link>
                                </td>
                                <td>{item.receipt.invoice.invoice_type.invoice_name}</td>
                                <td>{item.receipt.amount.amount_c}</td>
                                <td>{item.receipt.payment_mode.mode_name}</td>
                                <td>{item.receipt.cashier.username}</td>
                              </tr>)}

                        </tbody>
                      </table>
                      {info && info}

                      {transactionList && Array.isArray(transactionList) && <LoadMoreButton action={fetchMore} loading={loading} />}

                      {!transactionList && !info && <ActivityLoader />}
                      {/* {filter && filterInfo?.status !== "Fail" && <ActivityLoader />} */}

                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default PaymentsTracking
