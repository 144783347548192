import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import dictionary from '../../../utils/dictionary'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import ajaxTowing from '../../../utils/remote/ajaxTowing'
import ConfigurationContext from '../../../Context/ConfigurationContext'
const eformats = dictionary._exportConfigBtnFormats

class ConfigTowingFee extends React.Component {
    static contextType = ConfigurationContext

    state = {
        userId: functions.sessionGuard(),
        towingFeeList: false,
        towingFeeId: false,
        towingFeeRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlDelete: false
    }

    componentDidMount() {
        // const { } = this.context;
        this.getTowingFee();
    }

    getTowingFee = async () => {
        const server_response = await ajaxTowing.getTowingFee();

        if (server_response.status === "OK") {
            this.setState({
                towingFeeList: server_response.details.content
            })
        } else {
            this.setState({
                towingFeeList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewAmount: false
        }, () => {
            this.setState({
                mdlNewAmount: <PopNewAmount
                    title="New towinging Charge"
                    buttonLabel="Confirm towing Charge"
                    description="This towing Charge will automatically override the existing amount.
                              only a single towing Charge can be active at a time"
                    onConfirm={this.onConfirmNewtowingCharge} />
            }, () => {
                $("#modal_amount_input").modal('show')
            })
        })
    }


    onConfirmNewtowingCharge = async (amount) => {

        const server_response = await ajaxTowing.createTowingFee(amount);
        if (server_response.status === "OK") {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTowingFee();
            })
        } else {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.towingFeeRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Delete towing Fee?"
                        description="Are you sure you want to delete this towing Fee?"
                        onConfirm={() => this.onConfirmDelete()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        }
    }

    onConfirmDelete = async () => {

        if (this.state.towingFeeId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajaxTowing.deleteTowingFee(this.state.towingFeeId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('t_' + this.state.towingFeeId);
                    //Refresh list
                    this.getTowingFee();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }



    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Towing Fee
                </button>

            </>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Towing Fee
                </a>
                <a className="dropdown-item" onClick={this.onClickDelete}>
                    <i className="fe fe-trash-2 mr-2"></i>Delete Towing Fee
                </a>

            </>
        )
    }


    onSelectTowingFee = (recordId, record) => {
        this.setState({
            towingFeeId: recordId,
            towingFeeRecord: record,
            toast: ''
        })
    }

    render() {
        const towingFeeList = this.state.towingFeeList
        return (
            <div className="page">
                {this.state.mdlNewAmount}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Towing Fee"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center filter-example">

                                                <TableHeader
                                                    title="Towing Charge"
                                                    subtitle="This represents amount of money charged after towing a vehicle"
                                                />

                                                </div>

                                                <div className="table-responsive allView-config border-top">
                                                    {(towingFeeList === "404") && <NoContent />}

                                                    {towingFeeList && Array.isArray(towingFeeList) &&
                                                        <table id="config-towingFee" className="table table-hover text-nowrap mb-0 datatable " export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Amount</th>
                                                                    <th className="text-center">Status</th>
                                                                    <th>Created At</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {towingFeeList && towingFeeList !== "404" &&
                                                                    towingFeeList.map((item, key) =>
                                                                        <tr
                                                                            id={"t_" + item.id}
                                                                            key={key}
                                                                            className={`${this.state.towingFeeId == item.id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelectTowingFee(item.id, item)}
                                                                        >
                                                                            <td>{item.amount.amount_c}</td>
                                                                            {/* <td>{item.status_name}</td> */}
                                                                            <td className="text-center">
                                                                                {item.status_name === 'ACTIVE' &&
                                                                                    <span className="btn ripple btn-success btn-sm">{item.status_name}</span>
                                                                                }

                                                                                {item.status_name === 'INACTIVE' &&
                                                                                    <span className="btn ripple btn-danger btn-sm">{item.status_name}</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {item.created_at.date} <br />
                                                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!towingFeeList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>

                            <ConfigMenu />



                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}


export default ConfigTowingFee
