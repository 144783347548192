import React from 'react';
import dictionary from '../../../utils/dictionary';
import AlertError from '../../Common/AlertError';
import SelectSearch from '../../Common/SelectSearch';
import TextInput from '../../Common/TextInput';
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader'
import AlertSuccess from '../../Common/AlertSuccess';
import WrongParkingContext from '../../../Context/ContraventionContext/WrongParkingContext';
import { ConfigurationConsumer } from '../../../Context/ConfigurationContext';
import ajaxInvoice from '../../../utils/remote/ajaxInvoice';


class NewPaymentDue extends React.Component {

    static contextType = WrongParkingContext;

    state = {
       
        violationDate:"",
        violationTime:"",
        cobraId:"",
        locationId:"",
        zuluId:"",
        amountDue:"",
        loading:false

    }




    onChangeAddress = (selectedOption) => {
        this.setState({
            locationId: selectedOption.value
        })
    }




   

    onConfirm = async(event) => {
        event.preventDefault();
        const { cobraId, zuluId, violationDate, violationTime, locationId, amountDue } = this.state;
        if (cobraId.length > 0 && zuluId.length > 0 && violationDate.length > 0 && violationTime.length > 0 && locationId.length>0 && amountDue>0) {
            this.setState({loading:true})
            const server_response = await ajaxInvoice.postCustomClampInvoice({
                "reason_code":"PD",
                "plate_number":this.props.plateNumber,
                "cobra_id":cobraId,
                "zulu_id":zuluId,
                "clamp_date":violationDate + " " + violationTime,
                "location_id":locationId,
                "violation_description_id":amountDue
            })

            if(server_response.status==="OK"){

                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })

                setTimeout(()=>{
                    functions.closePopup("modal-custom-invoicing")
                }, 3000)

            }else{
                this.setState({
                    loading:false,
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
          this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
            
        }
    }

   


   
    render() {
        const optionsLocation = [];
        const optionsCobra = [];

        return (

            <ConfigurationConsumer>

                {props => {

                    const { locationList, zuluList, cobraList, wrongParkingDescriptionList } = props;

                  
                    if (Array.isArray(locationList)) {
                        locationList.map((item, key) =>
                            optionsLocation.push({ label: item.location_name, value: item.location_id })
                        )

                    }


                    if (Array.isArray(cobraList)) {
                        cobraList.map((item, key) =>
                            optionsCobra.push({ label: item.last_name, value: item.user_id })
                        )
                    }



                    const optionsZulu = [];
                    if (Array.isArray(zuluList)) {
                        zuluList.map((item, key) =>
                            optionsZulu.push({ label: item.username, value: item.user_id })
                        )

                    }

                    return (
                    
                                <div className="modal-content modal-content-demo shadow">
                                    <div className="modal-header">
                                        <h6 className="modal-title">New Payment Due Invoice</h6>

                                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                   
                                    <div className="modal-body">
                                        {this.state.info}


                                        <form method="post" onSubmit={this.onConfirm}>
                                        
                                            <TextInput
                                                label="Clamping Date"
                                                type="date"
                                                value={this.state.violationDate}
                                                name="ViolationDate"
                                                onChange={(e) => {this.setState({violationDate:e.target.value})}}
                                            />

                                             <TextInput
                                                label="Clamping Time"
                                                type="time"
                                                name="ViolationTime"
                                                value={this.state.violationTime}
                                                onChange={(e) => {this.setState({violationTime:e.target.value})}}
                                            />

                                           
                                            <SelectSearch
                                                label="Clamping Location"
                                                onChange={() => this.onChangeAddress}
                                                options={optionsLocation}
                                                placeholder={"Select Location"}
                                            />

                                            <SelectSearch
                                                label="Clamped By"
                                                name="ClampedBy"
                                                onChange={(selection) => this.setState({cobraId:selection.value})}
                                                options={ optionsCobra}
                                                placeholder={"Select field personnel"}
                                            />

                
                                          <SelectSearch
                                                label="UnClamped By"
                                                name="UnclampedBy"
                                                onChange={(selection)=>this.setState({zuluId:selection.value})}
                                                options={ optionsZulu}
                                                placeholder={"Select field personnel"}
                                            />

                                          
                                          <TextInput
                                                label="Amount Due"
                                                type="amount"
                                                name="AmountDue"
                                                placeholder="Enter amount above threshold"
                                                value={this.state.amountDue}
                                                onChange={(e) => {this.setState({amountDue:e.target.value})}}
                                            />



                                        

                                        </form>

                                    </div>
                                    <div className="modal-footer">
                                        {!this.state.loading && <>
                                            <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Save Invoice</button>
                                            <button className="btn ripple btn-light" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                                        </>}
                                        {this.state.loading && <ActivityLoader />}
                                    </div>
                                </div>
                           
                    )
                }}
            </ConfigurationConsumer>

        )
    }
}

export default NewPaymentDue;
