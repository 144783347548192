import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import { Link } from 'react-router-dom';
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import Toast from '../../../Components/Common/Toast'
import { ReservationConsumer } from '../../../Context/ReservationContext'
import ReservationPaymentMethods from '../../../Components/Finance/Cashier/ReservationPaymentMethods'
import ReservationInvoice from '../../../Components/Invoices/ReservationInvoice'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'

class ReservationCashier extends React.Component {

    state={
        invoiceId:'',
        invoice:false,
        selectedRecord:false,
        toast:false
    }
   
   
   NavButtons = () => {
        return (
          <>
            <Link to="/cashier" className="btn ripple btn-light">
            <i className="fas fa-home"></i> Home
            </Link>
    
          </>
        )
      }


      onSelectInvoice=(invoiceId, selectedRecord)=>{
        this.setState({invoiceId, selectedRecord})
      }

      viewInvoice=(invoiceRecord)=>{
          this.setState({
              invoice:false
          }, ()=>{
            this.setState({
                invoice:<ReservationInvoice record={invoiceRecord} />
            }, ()=>{
                    functions.openPopup("modal_reservation_invoice")
            })

          });

          
      }

      RenderSelection=()=>{
          const selection = this.state.selectedRecord;
          if(!selection){
              return(
                  <>
                    <h5 className="card-title tx-dark tx-medium mg-b-10"> UGX. 0</h5>
                    <p className="card-subtitle mg-b-15">No invoice selected</p>
                  </>
              )
          }else{
              return(
                <>
                <h5 className="card-title tx-dark tx-medium mg-b-10"> UGX. {selection.balance.amount_p}</h5>
                <p className="card-subtitle mg-b-15">{selection.invoice.organisation.title}</p>
              </>
              )
          }
      }


     RenderInvoices=()=>{
        return(
            <ReservationConsumer>
                {props=>{
                    const transactions = props.outstandingInvoices;
                    return(
                        <div className="table-responsive table-ellipsis">

                            {(transactions === "404") && <NoContent />}

                        {transactions && Array.isArray(transactions) && 
                        <table id="table-reservation-invoices" className="datatable table text-nowrap mb-0 table-striped">
                            <thead>
                                <tr>
                                    <th>Invoice No.</th>
                                    <th>Company</th>
                                    <th>Balance</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((item, key)=>
                                    <tr 
                                        key={key}
                                        className={`${this.state.invoiceId == item.invoice.id ? 'flag-row' : ''}`}
                                        onClick={()=>this.onSelectInvoice (item.invoice.id, item) }
                                        >
                                        <td>
                                            <a 
                                                className='App-link'
                                                onClick={()=>this.viewInvoice(item.invoice)}
                                                 >{item.invoice.invoice_ref}</a>
                                        </td>
                                        <td>{item.invoice.organisation.title}</td>
                                        <td>{item.balance.amount_p}</td>
                                        <td>{item.invoice.start_date.short_date}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>}
                        {!transactions && <ActivityLoader/>}
                    </div>
                    )
                }}
            </ReservationConsumer>
        )
    }


    onClickPay=()=>{
        if(!this.state.selectedRecord)
        {
            this.setState({
                toast:<Toast type={2} message="Please select invoice, then try again" onClose={this.resetToast}/>
            })

            return false;
        }

        return this.state.selectedRecord;
    }


    resetToast=()=>{
        this.setState({toast:false})
    }


   render(){

        return (
            <div className="page">
                {this.state.invoice}
                {this.state.toast}
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Pay Reservation"
                            previousPageTitle="Cashier"
                            back="/cashier"
                            buttons={<this.NavButtons/>}
                        />

                    <div className="row">

                    <div className="col-md-8 col-sm-12">
                            <div className="card custom-card">
                                <div className="card-body">
                                    
                                    <TableHeader
                        title="Invoices"
                        subtitle="Select outstanding invoice to make payment"
                    />
                                    
                                    <div className="card-text">
                                            <this.RenderInvoices/>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                             <div className="card custom-card">
                                <div className="card-body">
                                   <this.RenderSelection/>
                                    <div className="card-text">
                                    <ReservationConsumer>
                                        {props=>{
                                            return  <ReservationPaymentMethods onClickPay={this.onClickPay} refresh={props.listOutstandingInvoices}/>
                                        }}
                                       
                                    </ReservationConsumer>
                                    </div>
                                </div>
                            </div>
                        
                    </div>

                    </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );
    }

}

export default ReservationCashier
