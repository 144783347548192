import React from 'react';
import ReservationContext from '../../Context/ReservationContext';
import ajax from '../../utils/ajax';
import ActivityLoader from '../Common/ActivityLoader';
import InitialAvatar from '../Common/InitialAvatar';
import NoContent from '../Common/NoContent';
import TableHeader from '../Common/TableHeader';

class ReservationCompanyList extends React.Component {

    static contextType = ReservationContext;


    render() {

        const { companyList } = this.context;

        return (

            <div className="card custom-card">
                <div className="card-body map-card">

                    <TableHeader
                        title={"Client Organisation"}
                        subtitle="List of organisations with reservation statistics"
                    // view="View All"
                    // href="/finance/sticker-records"
                    />

                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                        <div className="table-responsive">
                            <table className="table mg-b-0">
                                <tbody>
                                    {/* {Array.isArray(companyList) && companyList.map((item, key) => */}

                                    {
                                        (companyList === "404")
                                            ?
                                            <tr>
                                                <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="3">
                                                    <NoContent />
                                                </td>
                                            </tr>
                                            :
                                            companyList && companyList !== "404" && companyList.map((item, key) =>

                                                <tr key={key}>
                                                    <td className="bd-t-0">
                                                        <InitialAvatar value={<i className="far fa-building" />} size={1} />
                                                    </td>
                                                    <td className="bd-t-0">
                                                        <h6 className="mg-b-0">{item.title}</h6>
                                                        <small className="tx-11 tx-gray-500">{item.contact + ' - ' + item.location_address}</small>
                                                    </td>
                                                    <td className="bd-t-0">
                                                        <h6 className="mg-b-0 font-weight-bold">{item.slots.total}</h6>
                                                        <small className="tx-11 tx-gray-500">{item.slots.unit}</small>
                                                    </td>
                                                </tr>


                                            )}


                                </tbody>
                            </table>

                            {!companyList && <ActivityLoader />}
                        </div>
                    </div>
                </div>


            </div>

        )


    }
}

export default ReservationCompanyList;
