import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ajaxUser from '../../utils/remote/ajaxUser'
import $ from 'jquery';
import PopVehicleInput from '../../Components/PopVehicleInput'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import Export from '../../Components/Common/Export'
import WarnDelete from '../../Components/WarnDelete'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import WarnAction from '../../Components/WarnAction'
import Toast from '../../Components/Common/Toast'
import { Link } from 'react-router-dom'
import ManageUserContext, { ManageUserConsumer } from '../../Context/ManageUserContext'
import dictionary from '../../utils/dictionary'
import PopUpdateBranch from '../../Components/User/PopUpdateBranch'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import PopImportUsers from '../../Components/PopImportUsers'
const eformats = dictionary._exportBtnFormats


class ListUsers extends React.Component {

    static contextType = ManageUserContext

    state = {
        userId: functions.sessionGuard(),
        userList: false,
        currentPage: 1,
        metaData: false,
        menuOptions: '',
        userRecord: '',
        selectedUserId: '',
        toast: '',
        filter: false,
        userAdmins: false,
        accessGrant: '',

        info: false,
        modal: false,
        model: false,
        toast: false,
        loading: false,

        hovered: false,
        activeKey:false
    }

 

   
    resetToast = () => {
        this.setState({
            toast: false
        })
    }

   

    onSelectRecord = (selectedUserId, userRecord, key) => {
        this.setState({
            selectedUserId: selectedUserId,
            userRecord: userRecord,
            toast: '',
            activeKey:key
        }, () => {
            //change menu according to selected role
            if (this.state.userRecord.user_role.role_id === "2") {
                this.setState({
                    menuOptions: {
                        "startSession": () => this.onClickStartSession(),
                        "add": () => this.onClickAdd(),
                        "edit": () => this.onClickEdit(),
                        "delete": () => this.onClickDelete(),
                        "resetPassword": () => this.onClickResetPassword()
                    }
                })

            } else {
                this.setState({
                    menuOptions: {
                        "add": () => this.onClickAdd(),
                        "edit": () => this.onClickEdit(),
                        "delete": () => this.onClickDelete(),
                        "resetPassword": () => this.onClickResetPassword()
                    }
                })

            }

        },
            // () => {
            //     if (this.state.selectedUserId) {
            //         this.setState({ hovered: true })
            //     }
            // }
        )
    }

    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/users/new'
        })
    }

    onClickEdit = () => {

        if (this.state.userRecord) {

            this.props.history.push({
                pathname: '/config/users/edit',
                search: '?q=1',
                state: { record: this.state.userRecord }
            })
        }

    }


    onClickAssign=(userId)=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<PopUpdateBranch onConfirm={this.onConfirmBranchUpdate}/>
            }, ()=>{
                functions.openPopup("modal_update_branch")
            })
        })
    }


    onConfirmBranchUpdate=async(branchName, branchId)=>{
        const server_response = await ajaxUser.updateUserBranch(this.state.selectedUserId, branchId);
        if(server_response.status==="OK"){
            this.context.hotUpdateBranchName(this.state.activeKey, branchName)
            functions.closePopup("modal_update_branch")
        }
       
    }


    onClickDelete = () => {
       
        if (this.state.userRecord) {
            this.setState({
                modal: false
            }, () => {

                this.setState({
                    modal: <WarnAction
                        title="Suspend User ?"
                        buttonLabel="Confirm Suspension"
                        description="This user will nolonger be able to login. Please note that you can also just deny this user access to some system information."
                        onConfirm={() => this.onConfirmDeleteUser()}
                    />

                }, () => {
                    $("#modal_action").modal('show');
                });

            })
        }
    }

    // }

    onConfirmDeleteUser = async () => {

        if (this.state.selectedUserId) {
           

            const server_response = await ajaxUser.deactivateUser(this.state.selectedUserId);
            if (server_response.status === "OK") {
                functions.closePopup("modal_action")
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                })
            } else {
                functions.closePopup("modal_action")
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2}/>
                })
            }
        }
    }

    onClickStartSession = () => {
        $("#modal_vehicle_input").modal('show');
    }

    onConfirmVehicle = (plateNumber) => {
        $("#modal_vehicle_input").modal('hide');

        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: this.state.userRecord, vehiclePlate: plateNumber }
        })
    }

    onClickResetPassword = () => {
        $("#modal_delete").modal('show');

        // this.props.history.push({
        //     pathname: '/operations/sessions/new',
        //     search: '?q=1',
        //     state: { conductor: this.state.userRecord, vehiclePlate: plateNumber }
        // })
    }

    onConfirmReset = (plateNumber) => {
        $("#modal_vehicle_input").modal('hide');

        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: this.state.userRecord, vehiclePlate: plateNumber }
        })
    }


    buttons = () => {
        const { userList } = this.state;
        return (
            <>
                {/* {userList && userList !== "404" && 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                } */}

               {this.state.activeKey && <button className="btn ripple btn-primary" onClick={() => this.onClickAssign(this.state.selectedUserId)}>
                    <i className="fa fa-university mr-1"></i> Assign Branch
                </button>}
                <button className="btn ripple btn-success" onClick={() => this.onClickAdd()}>
                    <i className="fe fe-plus-circle mr-1"></i> Create user
                </button>

                <button className="btn ripple btn-light" onClick={this.context.getAllUsers}>
                    <i className="fa fa-redo mr-1"></i> Refresh
                </button>

                <button className="btn ripple btn-primary" onClick={this.onClickImportUsers}>
                    <i className="fe fe-plus-circle mr-1"></i> Import
                </button>


            </>
        )
    }


    getAllCreatedBy = async () => {
        const server_response = await ajax.listAdmins()
        if (server_response.status === "OK") {
            this.setState({
                userAdmins: server_response.details.content.list,
            })
        }
    }


    onFilter = async (filter) => {
        this.setState({ filter: filter }, () => this.listAllUsers())
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            userList: false
        }, () => this.listAllUsers())
    }





    onClickActivate = () => {
       
        if (this.state.userRecord) {
            this.setState({
                modal: false
            }, () => {

                this.setState({
                    modal: <WarnAction
                        title="Activate User ?"
                        buttonLabel="Confirm user activation"
                        description="This user will now be able to login. Please note that the user still retains the previously assigned permissions."
                        onConfirm={() => this.onConfirmActivateUser()}
                    />

                }, () => {
                    $("#modal_action").modal('show');
                });

            })
        }
    }



    onConfirmActivateUser = async () => {

        if (this.state.selectedUserId) {
           

            const server_response = await ajaxUser.activateUser(this.state.selectedUserId);
            if (server_response.status === "OK") {
                functions.closePopup("modal_action")
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                })
            } else {
                functions.closePopup("modal_action")
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2}/>
                })
            }
        }
    }



    RenderUsers=()=>{
        const {userList} = this.context;
        return(
           
            <div className="table-responsive allView-table border-top">
                {Array.isArray(userList) && <table id="users" className="table text-nowrap table-hover mg-b-0 datatable" export-format={eformats}>
                            <thead>
                                <tr>
                                    <th>NO.</th>
                                    <th>Photo</th>
                                    <th>Full names</th>
                                    <th>Role</th>
                                    <th>Phone number</th>
                                    <th>Branch</th>
                                    <th className="text-center">Email address</th>
                                    <th>Created on</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {userList.map((item, key) =>
                                        <tr
                                            id={"usr_" + item.user_id}
                                            key={key}
                                            className={`${this.state.selectedUserId === item.user_id ? 'flag-row' : ''}`}
                                            onClick={() => this.onSelectRecord(item.user_id, item, key)}
                                        >
                                            <td>{key+1}</td>
                                            <td>
                                                <div className="main-img-user">
                                                    <img alt="avatar" src={item.profile_photo.file_path} />
                                                </div>
                                            </td>
                                            <td>
                                                <h6 className="mg-b-1">{item.first_name} {item.last_name}</h6>
                                                <small className="tx-12 tx-gray-500">@ {item.username}</small>
                                            </td>
                                            <td className="text-center">
                                                {item.user_role.role_name}<br />
                                                <small className="tx-12 tx-gray-500">Created by: {item.created_by.username}</small>
                                                
                                            </td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.branch?item.branch.branch_name : <span className='tx-12 tx-gray-500'>Not attached</span>}</td>
                                            {/* <td>
                                                <h6 className="mg-b-1">{item.created_by.username}</h6>
                                                <small className="tx-12 tx-gray-500">{item.created_by.user_role.role_name}</small>
                                            </td> */}
                                            <td className="text-center">{item.email !== 'NULL' ? item.email : <small className="tx-12 tx-gray-500">No Email</small>}</td>
                                            <td>
                                                {item.created_at.date}<br />
                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                            </td>
                                            <td>
                                               
                                                <div className="d-flex flex-column align-items-start">
                                                    <span className='d-flex align-items-center'>
                                                    <i className={`task-icon-user m-0 bg-${item.activity.status_id==1?"success":item.activity.status_id==2?"primary":"danger"} mr-2`}></i>
                                                    <span className="text-muted tx-13 m-0">{item.activity.status_name}</span>
                                                    </span>
                                                    <p className="tx-12 tx-gray-500">{item.last_seen?item.last_seen.when:"Never Active"}</p>
                                                </div>
                                                
                                               
                                            </td>
                                            <td className="text-center w-10">
                                                {this.state.selectedUserId === item.user_id &&
                                                    <>
                                                        
                                                        <Link
                                                            to={
                                                                {
                                                                    pathname: `/users/profile/${item.username}`,
                                                                    state: { username: item.username }
                                                                }
                                                            }
                                                            className="btn ripple btn-success btn-sm text-white mr-2"
                                                            data-toggle="tooltip" data-original-title="View"
                                                            title="View Profile">
                                                            {/* <i className="fe fe-trash-2"></i> */}
                                                            {/* <i className="fas fa-plus mr-1 text-primary"/> */}
                                                            <i className="fas fa-eye mr-1" />
                                                        </Link>

                                                        <a
                                                            onClick={() => this.onClickEdit()}
                                                            className="btn ripple btn-primary btn-sm text-white mr-2"
                                                            data-toggle="tooltip" data-original-title="Edit"
                                                            title='Edit User'>
                                                            {/* <i className="fe fe-trash-2"></i> */}
                                                            {/* <i className="fas fa-plus mr-1 text-primary"/> */}
                                                            <i className="fas fa-edit mr-1" />
                                                        </a>

                                                       {item.status*1>0 && 
                                                       <a
                                                            onClick={() => this.onClickDelete()}
                                                            className="btn ripple btn-danger btn-sm text-white"
                                                            data-toggle="tooltip" data-original-title="Suspend"
                                                            title='Suspend User'>
                                                            <i className="fa fa-lock"></i>
                                                        </a>
                                                        }

                                                        {item.status*1==0 && 
                                                        <a
                                                            onClick={() => this.onClickActivate()}
                                                            className="btn ripple btn-success btn-sm text-white"
                                                            data-toggle="tooltip" data-original-title="Suspend"
                                                            title='Activate User'>
                                                            <i className="fa fa-unlock"></i>
                                                        </a>
                                                        }


                                                    </>
                                                }
                                            </td>
                                          
                                        </tr>
                                    )}

                            </tbody>
                        </table>}

                       

                        {!userList && <ActivityLoader />}
                        {userList ==="404" && <NoContent/>}

                    </div>
                    )
             
    }


    RenderFilter=()=>{

        return(
           <ManageUserConsumer>
               {props=>{
                   return(
                    <div className="text-wrap">
                    <div className="">
                        <div className="btn-list">
        
                            <button type="button"
                                className="btn ripple btn-primary btn-sm"
                                // onClick={() => this.onChangeStatus("")}
                                >
                                All Users <span className="badge badge-light ml-2">{props.userCount}</span>
                            </button>
                            <button
                                type="button"
                                className="btn ripple btn-secondary btn-sm"
                                // onClick={() => this.onChangeStatus(1)}
                                >
                                Active Users <span className="badge badge-dark ml-2">...</span>
                            </button>
                            {/* {userList !== "404" && filter && <Export
                                extraClasses="btn-sm float-right"
                                url={"user/filter/export?keyword=" + filter.keyword + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&created_by=" + filter.selectedValue + "&status=" + this.state.status}
                            />} */}
                        </div>
                    </div>
                </div>
                   )
               }}
           </ManageUserConsumer>
           
        )
    }


    onClickImportUsers = () => {
        const { getAllUsers } = this.context;
        this.setState({
            model: false
        }, () => {
            this.setState({
                model: <PopImportUsers
                    refresh={getAllUsers}
                />
            }, () => functions.openPopup('modal_import_users'))

        })
    }


    render() {
        // const userList = this.state.userList;
        // const filter = this.state.filter

        // const userOptions = [];
        // this.state.userAdmins && this.state.userAdmins.map((item, key) =>
        //     userOptions.push({
        //         title: item.username,
        //         value: item.user_id
        //     })
        // );


        const {userList, metaData, goToNextPage, loading } = this.context


        return (
            <div className="page">
                <PopVehicleInput
                    title="New Session"
                    onConfirm={this.onConfirmVehicle}
                />

                <WarnDelete
                    title="Requseting Password Reset"
                    description="Make sure to have the user at the premises for successful reseting of the password"
                    onConfirm={this.onConfirmVehicle}
                />

                {/* <WarnAction
                    title="Careful while deleting a user?"
                    buttonLabel="Confirm Deactivation"
                    description="Make sure the user's information is backed up"
                    onConfirm={() => this.onConfirmDeleteUser()}
                /> */}

                {this.state.modal}
                {this.state.model}

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="All users"
                            previousPageTitle="User Management"
                            back="/users"
                            buttons={<this.buttons />}

                            // options={this.state.menuOptions}
                            // export={!this.state.filter && userList !== "404" && <Export
                            //     url="user/export"
                            // />}
                        />



                       



                        {/* <CustomizedFilterBar
                            keyword="keyword"
                            selection={{ title: 'Created By', options: userOptions }}
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        /> */}

                        {this.state.toast && this.state.toast}

                        <div className="row">

                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        {/* { functions.findInPermission(permissionList, 'USER-002') && */}

                                        <div className="d-flex justify-content-between align-items-center filter-example">
                                            <TableHeader
                                                title="All system users"
                                                subtitle="System users with their role and activity and status."
                                            />

                                                {Array.isArray(userList) &&
                                                    <LoadMoreButton
                                                        action={goToNextPage}
                                                        loading={loading}
                                                        title={metaData.current_page + " of " + metaData.total_pages}
                                                        />
                                                    }

                                            {/* <this.RenderFilter/> */}
                                       </div>

                                        <this.RenderUsers/>                                       


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ListUsers
