import React, { useEffect, useState } from 'react';
import ajaxViolation from '../../utils/remote/ajaxViolation';
import ajaxDemandNote from '../../utils/remote/ajaxDemandNote';

const DemandNoteContext = React.createContext();

export const DemandNoteConsumer = DemandNoteContext.Consumer;

export const DemandNoteProvider = (props)=> {

  const [allTransactions, setAllTransactions] = useState(false);
  const [demandNote, setDemandNote] = useState('404');
  const [batchId, setBatchId] = useState(false);

  const [data, setData] = useState({"page":"1"})
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(false);
  const [totalUnsettled, setTotalUsettled] = useState('...');
  const [totalMonthlySettled, setTotalMonthlySettled] = useState('...');


useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])


useEffect(()=>{
    if(allTransactions){
        getAllTransactions();
    }
}, [data])

useEffect(()=>{
    if(batchId){
        generateDemandNote();
    }
}, [batchId])


  const getAllTransactions=async()=>{
    setLoading(true)
    setAllTransactions(false)
    const server_response = await ajaxDemandNote.fetchDemandNotes(data);
    setLoading(false)
    if(server_response.status==="OK"){
      setAllTransactions(server_response.details.content.list)
      setMetaData(server_response.details.content.meta);
    }else{

      setAllTransactions("404");
      setMetaData("404");
    }
  }



  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);
    // restoreTable(detectTable());

    const server_response = await ajaxDemandNote.fetchDemandNotes(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.content.list);
        setAllTransactions(allTransactions.concat(incoming));
        setMetaData(server_response.details.content.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "page":"1",
        "status":options.status,

    })
    
}

const generateDemandNote = async() =>{
    setDemandNote(false);
    const server_response = await ajaxDemandNote.fetchDemandNoteInfo(batchId);
    if(server_response.status === "OK"){
        setDemandNote(server_response.details.content)
    }else{
        setDemandNote('404')
    }
}

const refresh = () =>{
    resetData();
    if(currentPage===1){
        getAllTransactions();
    }

    getTotalUnsettled();
    getTotalMonthlySettled();
}

const getTotalUnsettled = async() =>{
    setTotalUsettled('...');
    const server_response = await ajaxDemandNote.fetchUnSettledSum();
    if(server_response.status === "OK"){
        setTotalUsettled(server_response.details.content.amount_p)
    }else{
        setTotalUsettled('N/A')
    }
}

const getTotalMonthlySettled = async() =>{
    setTotalMonthlySettled('...');
    const server_response = await ajaxDemandNote.fetchMonthlySettledSum();
    if(server_response.status === "OK"){
        setTotalMonthlySettled(server_response.details.content.amount_p)
    }else{
        setTotalMonthlySettled('N/A')
    }
}
    
    return (
           <DemandNoteContext.Provider value={
               {
                  allTransactions,
                  metaData,
                  loading,
                  demandNote,
                  batchId,
                  totalUnsettled,
                  totalMonthlySettled,
                  getAllTransactions,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData,
                  setBatchId,
                  refresh
               }
               }>
               {props.children}
           </DemandNoteContext.Provider>
        );
    
}

export default DemandNoteContext;