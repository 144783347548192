import React, { useContext } from 'react';
import StatisticCard from '../../StatisticCard';
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext';


const TaxCurrentMonth =()=>{

    const {currentMonthTax} = useContext(FiscalReceiptContext);
 
    return <StatisticCard
                title="VAT" 
                value={currentMonthTax ? currentMonthTax : "..."}
                caption="This month"
                icon={"fa fa-gavel"}
                color={"text-success"}
                progressbar={"progress-bar progress-bar-xs wd-60p bg-success"}
                />
 
}

export default TaxCurrentMonth;