import React, { useEffect, useState } from 'react';
import dictionary from '../utils/dictionary';
import ajaxClamping from '../utils/remote/ajaxClamping';
import ajaxSession from '../utils/remote/ajaxSession';

const FieldContext = React.createContext();

export const FieldConsumer = FieldContext.Consumer;

export const FieldProvider=(props)=>{

    const [sessionList, setSessionList] = useState(false);
    const [sessionMessage, setSessionMessage] = useState(false);
    const [refreshRate, setRefreshRate] = useState(1);
    const [clampList, setClampList] = useState(false);
    const [clampMessage, setClampMessage] = useState(false);

    useEffect(()=>{
       getSessionList();
       getLiveClamps();
    }, [refreshRate])

    useEffect(()=>{

        setTimeout(() => {
            setRefreshRate(refreshRate + 1)
        }, dictionary._fieldRefreshRate);

    }, [refreshRate])
   
   

    const getSessionList=async()=>{
        const server_response = await ajaxSession.getSessionsToday();
        if(server_response.status==="OK")
        {
            setSessionList(server_response.details.list)
           
        }else if(server_response.status==="Fail"){
            setSessionMessage(server_response.message);
            setSessionList("404");
        }
    }


    const getLiveClamps=async()=>{
        const server_response = await ajaxClamping.getLiveClamps();
        if(server_response.status==="OK")
        {
            setClampList(server_response.details.content)
            
        }else if(server_response.status==="Fail"){
            setClampList("404");
            setClampMessage(server_response.message)
        }
    }


        return (
           <FieldContext.Provider value={{
               sessionList,
               sessionMessage,
               clampList,
               clampMessage,
               getSessionList,
               getLiveClamps
            }}
            >
               {props.children}
           </FieldContext.Provider>
        );
    }


export default FieldContext;