import React, { useState } from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';

const PopCreateClampViolations = (props) => {

    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [fine, setFine] = useState("");
    const [info, setInfo] = useState(false);

    const onConfirm = (event) => {
        event.preventDefault();
        if (reason.length > 0) {
            setLoading(true);
            props.onConfirm(reason, fine);
        } else {

            setInfo(<AlertError message={"Enter Violation"} />)

        }
    }

    return (
        <div className="modal" id="modal_new_clamp_violation">
            <div className="modal-dialog modal-dialog-centered" role="document">


                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <h6 className="modal-title">Create Clamp Violation</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        {info}
                        <form method="post" onSubmit={onConfirm}>

                            <TextInput
                                label="Violation"
                                type="text"
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                                name="violation"
                            />

                            <TextInput
                                label="Fine amount"
                                type="text"
                                onChange={(e) => setFine(e.target.value)}
                                value={fine}
                                name="fine"
                            />

                        </form>


                    </div>
                    <div className="modal-footer">
                        {!loading && <>
                            <button className="btn ripple btn-primary" type="button" onClick={onConfirm}>Save Violation</button>
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </>}
                        {loading && <ActivityLoader />}
                    </div>
                </div>


            </div>
        </div>
    )


}


export default PopCreateClampViolations;
