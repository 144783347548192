import React, { useContext } from "react"
import MobileMoneyContext from "../../Context/MobileMoneyContext"
import FilterSelectInput from "../Common/Filter/FilterSelectInput"
import FilterTextInput from "../Common/Filter/FilterTextInput"
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar"
const MobileMoneyFilter=(props)=>{

    const { resetData, filterData, providerList} = useContext(MobileMoneyContext)

    const paymentOptions = [{title:"Invoice Payment", value:"1"}, {title:"Ticket Purchase", value:"2"}];

    const optionProviders = [];
    if(Array.isArray(providerList)){
        
        providerList.forEach((item)=>{
          optionProviders.push({"title":item, "value":item})
        })

    }

    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Payment Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Payment Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Plate Number"
            type="text"
            placeholder="Enter plate number"
            onChange={onSet}
            name="plateNumber"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterSelectInput
            key={key}
            label="Payment Reason"
            options={paymentOptions}
            onChange={onSet}
            name="reason"
            currentValues={currentValues}
          />,

        ],
        names:["dateFrom", "dateTo", "plateNumber", "reason"]
    }}
    secondScreen={
        {
          inputs:[
           (onSet, key, currentValues)=> <FilterTextInput 
              key={key}
              label="Phone Number"
              placeholder="Filter Phone Number" 
              onChange={onSet}
              name="phoneNumber"
              currentValues={currentValues}
              />,
              (onSet, key, currentValues)=><FilterSelectInput
                key={key}
                label="Status"
                options={[
                    {title:"SUCCESS", value:"3"},
                    // {title:"QUEUED", value:"0"},
                    {title:"PENDING", value:"1"},
                    {title:"FAILED", value:"2"}
                ]}
                onChange={onSet}
                name="status"
                currentValues={currentValues}
              />,

              (onSet, key, currentValues)=><FilterSelectInput
                key={key}
                label="Provider"
                options={optionProviders}
                onChange={onSet}
                name="provider"
                currentValues={currentValues}
              />
          ],
          names:["phoneNumber", "status", "provider"]
        }
      }
/>

}

export default MobileMoneyFilter