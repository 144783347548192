import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import dictionary from '../../../utils/dictionary'
import TableHeader from '../../../Components/Common/TableHeader'
import { Link } from 'react-router-dom'
import ManageUserContext, { ManageUserConsumer } from '../../../Context/ManageUserContext'
import PopRoleInput from '../../../Components/User/PopRoleInput'
import PopUpdateRole from '../../../Components/User/PopUpdateRole'
import NoContent from '../../../Components/Common/NoContent'
const eformats = dictionary._exportConfigBtnFormats

class ConfigRoles extends React.Component {

    static contextType = ManageUserContext;

    state = {
        userId: functions.sessionGuard(),
        selectedPermissionId: false,
        toast: false,

        roleList: false,
        roleRecord: false,
        roleId: false,

        info: false,
        modal: false,
        CreateRole: '',

        dialogueNewAssignment: false,
    }



    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    onClickAddRole = () => {

        this.setState({
            modal: false,
        }, () => {
            this.setState({
                modal: <PopRoleInput
                    onConfirm={this.onSave} />

            }, () => functions.openPopup("modal_role_input")
            )
        })

    }





    onClickEditRole = () => {

        if (this.state.roleRecord) {

            this.setState({
                modal: false
            }, () => {
                this.setState({
                    modal: <PopUpdateRole
                        record={this.state.roleRecord}
                        onConfirm={this.onUpdate}
                    />
                }, () => functions.openPopup("modal_update_role"))
            })

        }

    }

    onClickDeleteRole = () => {
        if (this.state.roleRecord) {
            this.setState({
                modal: false
            }, () => {

                this.setState({
                    modal: <WarnDelete
                        id="modal_delete_role"
                        title="Are you sure you want to delete selected Role?"
                        description="Make sure the Role has no users assigned to it"
                        onConfirm={() => this.onConfirmDeleteRole()} />
                }, () => functions.openPopup("modal_delete_role"))
            })

        }
    }

    onConfirmDeleteRole = async () => {

        if (this.state.roleId) {

            const { getRoles } = this.context;

            const server_response = await ajax.deleteRole(this.state.roleId, this.state.userId);
            functions.closePopup("modal_delete_role")

            if (server_response.status === "OK") {
                getRoles();
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} />
                })
            }
        }
    }

    onSave = async (roleName, code) => {
        const { getRoles } = this.context;
        if (roleName.length > 0 || code.length > 0) {
            //fetch login from the API
            const server_response = await ajax.createRole(roleName, code);
            functions.closePopup("modal_role_input");
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
                })

                getRoles();
            } else {

                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />
                })
            }

        }

    }


    onUpdate = async (roleId, roleName, code) => {
        const { getRoles } = this.context;
        if (roleName.length > 0 || code.length > 0) {
            //fetch login from the API
            const server_response = await ajax.updateRole(roleId, roleName, code);
            functions.closePopup("modal_update_role");
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
                })
                functions.closePopup("modal_update_input");
                getRoles();
            } else {

                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />
                })
            }

        }

    }


    onSelectRole = (roleId, roleRecord) => {
        this.setState({
            roleId: roleId,
            roleRecord: roleRecord,
            toast: ''
        })

    }

    buttons = () => {
        const role = this.state.roleRecord;
        return (
            <>
                {this.state.roleId && <Link to={
                    {
                        pathname: `/config/roles/permissions/${role.role_name}`,
                        state: { role_id: role.role_id, role_name: role.role_name }
                    }
                } className="btn ripple btn-success">
                    <i className="fas fa-lock"></i> Manage Role Permissions
                </Link>}
            </>
        )
    }


    RenderRoles = () => {
        return (
            <ManageUserConsumer>
                {props => {
                    const roleList = props.roleList
                    return (
                        <div className="table-responsive allView-config border-top">

                            {(roleList === "404") && <NoContent />}

                            {roleList && Array.isArray(roleList) &&

                                <table id="config-roles" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Role Name</th>
                                            <th>Code</th>
                                            {/* <th className="text-center">Status</th>
                                            <th>Permissions</th> */}
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roleList && roleList !== "404" &&
                                            roleList.map((item, key) =>
                                                <tr
                                                    id={"br_" + item.role_id}
                                                    key={key}
                                                    className={`${this.state.roleId === item.role_id ? 'flag-row' : ''}`}
                                                    onClick={() => this.onSelectRole(item.role_id, item)}
                                                >

                                                    <td>{key + 1}</td>
                                                    <td>{item.role_name}</td>
                                                    {/* <td>{item.code}</td> */}

                                                    <td>
                                                        <span className="btn ripple btn-light btn-sm">{item.code}</span>
                                                    </td>
                                                    {/* <td>None</td>
                                                    <td><span className="badge badge-success rounded">active</span></td> */}
                                                </tr>
                                            )
                                        }

                                    </tbody>
                                </table>
                            }

                            {!roleList && <ActivityLoader />}
                        </div>
                    )
                }}
            </ManageUserConsumer>

        )
    }



    RenderRoleCount = () => {
        return (
            <ManageUserConsumer>
                {props => {
                    const countAllRoles = props.roleCount
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        Total Roles <span className="badge badge-light ml-2">{countAllRoles}</span>
                                    </button>

                                    <button className="btn ripple btn-light btn-sm" onClick={this.context.getRoles}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                </div>
                            </div>
                        </div>
                    )
                }}
            </ManageUserConsumer>
        )
    }



    render() {


        return (
            <div className="page">

                {this.state.modal}
                {this.state.toast}

                {this.state.dialogueNewAssignment}

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="All System Roles"
                            previousPageTitle="Configurations"
                            back="/config"
                            {...this.state.CreateRole}
                            buttons={<this.buttons />}
                            options={{
                                "add": () => this.onClickAddRole(),
                                "edit": () => this.onClickEditRole(),
                                "delete": () => this.onClickDeleteRole()
                            }}
                        />

                        {this.state.toast && this.state.toast}


                        <div className="row">


                            <div className="col-xl-9 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                    <div className="d-flex justify-content-between align-items-center filter-example">
                                        <TableHeader
                                            title="System Roles"
                                            subtitle="All the system configured roles."
                                        />
                                        <this.RenderRoleCount />
                                        </div>


                                        {/* <div className="text-wrap mb-4">
                                            <div className="example">
                                                <div className="btn-list">
                                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                                        Admin <span className="badge badge-light ml-2">...</span>
                                                    </button>
                                                    <button type="button" className="btn ripple btn-secondary btn-sm">
                                                        Conductor <span className="badge badge-dark ml-2">...</span>
                                                    </button>
                                                    <button type="button" className="btn ripple btn-success btn-sm">
                                                        Cobra <span className="badge badge-danger ml-2">...</span>
                                                    </button>
                                                    <button type="button" className="btn ripple btn-info btn-sm">
                                                        Zulu <span className="badge badge-warning ml-2">...</span>
                                                    </button>

                                                </div>
                                            </div>
                                        </div> */}


                                        <this.RenderRoles />
                                    </div>
                                </div>
                            </div>
                            <ConfigMenu />
                        </div>
                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );
    }
}

export default ConfigRoles
