import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import TableHeader from '../../../Components/Common/TableHeader'

class ConfigWorkDay extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.getWeekDays();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content
            })
        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewAmount: false
        }, () => {
            this.setState({
                mdlNewAmount: <PopNewAmount
                    title="New Wrong Parking Fee"
                    buttonLabel="Confirm Amount"
                    description="This fee will automatically override the existing amount.
                              only a single fee can be active at a time"
                    onConfirm={this.onConfirmFee} />
            }, () => {
                $("#modal_amount_input").modal('show')
            })
        })
    }


    onConfirmFee = async (amount) => {

        const server_response = await ajax.createWrongParkingFee(amount);
        if (server_response.status === "OK") {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {

                this.getTransactions();
            })
        } else {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            })
        }

    }

    onClickToggle = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
        }, () => {

            const item = this.state.transactionRecord;
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        buttonLabel="Confirm Change"
                        title="Change Work Day?"
                        description={"Are you sure you want to change " + item.day + " to a " + item.new_status_name}
                        onConfirm={() => this.onConfirmChange()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        })


    }

    onConfirmChange = async () => {

        if (this.state.transactionId) {
            const item = this.state.transactionRecord;

            $("#modal_delete").modal('hide');

            const server_response = await ajax.toggleWorkDay(this.state.transactionId, item.new_status_id);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
                }, () => {

                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
                })
            }

        }

    }



    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlNewAmount}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Working Days"
                            previousPageTitle="Configurations"
                            back="/config"
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <TableHeader
                                                    title="Working Week Days"
                                                    subtitle="Unchecked days are considered free days thus no session will start or surcharge applied on such a day"
                                                />

                                                <div className="swichermainleft col-6 mt-3">

                                                    <div className="switch_section">

                                                        {this.state.transactionList &&
                                                            this.state.transactionList !== "404" &&
                                                            this.state.transactionList.map((item, key) =>
                                                                <div
                                                                    className="switch-toggle d-flex"
                                                                    key={key}
                                                                    onClick={() => this.onSelectTransaction(item.day_id, item)}>
                                                                    <span className="mr-auto">{item.day}</span>
                                                                    <div className="onoffswitch2" onClick={this.onClickToggle}>
                                                                        <input
                                                                            type="radio"
                                                                            className="onoffswitch2-checkbox"
                                                                            checked={item.status === "0" ? true : false}
                                                                        />
                                                                        <label for="myonoffswitch" className="onoffswitch2-label"></label>
                                                                    </div>
                                                                </div>)}


                                                        {!transactionList && <ActivityLoader />}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigWorkDay
