import React, { useContext, useEffect } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import PopReportWrongParking from '../../Components/Contraventions/WrongParking/PopReportWrongParking';
import $ from 'jquery';
import Toast from '../../Components/Common/Toast'
import dictionary from '../../utils/dictionary'
import { Link } from 'react-router-dom'
import PaymentDueContext from '../../Context/ContraventionContext/PaymentDueContext'
import TableHeader from '../../Components/Common/TableHeader'
import NoContent from '../../Components/Common/NoContent'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import FilteredDuePayments from './Filters/FilteredDuePayments'



const eformat = dictionary._exportBtnFormats;


class ListDuePaymentDefaulters extends React.Component {

    static contextType = PaymentDueContext;

    state = {
        tableId: false,
        defaultersList: false,
        currentPage: false,
        metaData: false,
        onClickPage: false,
        onClickNext: false,
        onClickPrevious: false,
        listDuePayments: false,
        filter: false,
    }

    componentDidMount() {
        const { listDuePayments } = this.context
        listDuePayments();

    }


    buttons = () => {
        return (
            <>
                <Link
                    to="#"
                    className="btn ripple btn-secondary navresponsive-toggler mb-3" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>

            </>
        )
    }

    // onChangeStatus = (status) => {
    //     this.setState({
    //         status: status,
    //         defaultersList: false,
    //         metaData: false,
    //         currentPage: 1
    //     }, () => this.listDuePayments())
    // }

    // const { tableId, defaultersList, currentPage, metaData, onClickPage, onClickNext, onClickPrevious, listDuePayments } = useContext(PaymentDueContext);

    // static contextType = PaymentDueContext;

    // useEffect(() => {
    //     listDuePayments();
    // }, [])
    // state = {
    //     userId: functions.sessionGuard(),
    //     countActiveSessions: '...',
    //     WrongParking: '',
    //     currentPage: 1,
    //     defaultersList: false,
    //     modal: false,
    //     metaData: false,
    //     toast: false,
    //     info: false
    // }





    // onClickPage = (page) => {
    //     this.setState({
    //         currentPage: page,
    //         defaultersList: false
    //     }, () => this.listPaymentDueDefaulters())
    // }

    // onClickNext = () => {
    //     //increment page numbers
    //     const metaData = this.state.metaData;
    //     if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
    //         this.setState({
    //             currentPage: this.state.currentPage + 1,
    //             defaultersList: false

    //         }, () => {
    //             this.listPaymentDueDefaulters();
    //         })
    //     }

    // }

    // onClickPrevious = () => {
    //     const metaData = this.state.metaData;
    //     if (metaData.current_page * 1 > 1) {
    //         this.setState({
    //             currentPage: this.state.currentPage - 1,
    //             defaultersList: false
    //         }, () => {
    //             this.listPaymentDueDefaulters();
    //         })
    //     }

    // }

    onFilter = (filter) => {
        this.setState({
            filter: "",
            metaData: false

        }, () =>
            this.setState({
                filter: filter,
                metaData: false
            }))
    }

    resetFilter = () => {
        this.setState({
            filter: false
        },

        )
    }



    render() {

        const {
            defaultersList,
            countAllPaymentDues,
            countPaidPaymentDues,
            countUnPaidPaymentDues,
            loading,
            getPaymentDueByStatus,
            goToNextPage,
            metaData

        } = this.context

        const paymentStatusOptions = []
        const paymentStatusList = [{
            "id": "1",
            "payment_status": "UNCLEARED"
        },
        {
            "id": " 0",
            "payment_status": "RESOLVED"

        },
        ]

        const Itemsperpage = [15, 20, 25, 30, 35, 40, 45, 50]

        const itemsPerPageOptions = [];

        if (paymentStatusList && paymentStatusList !== "404") {
            paymentStatusList.map((item, key) =>
                paymentStatusOptions.push({
                    title: item.payment_status,
                    value: item.id
                })
            )

        }


        Itemsperpage.map((item, key) =>
            itemsPerPageOptions.push({
                title: item,
                value: item
            })
        )



        const { filter } = this.state;


        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="All due payments defaulters"
                            previousPageTitle="Contraventions"
                            back="/contraventions"
                            buttons={<this.buttons />}

                        />

                        {/* "vehicle_id":"",
                        "plate_number":"",
                        "created_from":"",
                        "payment_status":"",
                        "amount_due":"",
                        "created_to":"",
                        "page":1 */}

                        {!this.state.closeFilter &&
                            <CustomizedFilterBar
                                keyword="Plate Number"
                                searchPlate="searchPlate"
                                keyword1="Enter amount"
                                // keyword2="Enter items per page (max 50 items)"
                                // keyword1ClassName="keyword1ClassName"
                                selection5={{ title5: 'No. of Records per page', options5: itemsPerPageOptions }}

                                selection={{ title: 'Payment Status', options: paymentStatusOptions }}
                                className="col-md-3 mt-3"
                                // selection3={{ title3: 'Clamped By', options3: clampedByOptions }}
                                // selection4={{ title4: 'Un Clamped By', options4: unClampedByOptions }}
                                onReset={this.resetFilter}
                                onConfirm={this.onFilter}
                            // clampfilter="clampfilter"

                            />
                        }

                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        {!this.state.filter && <TableHeader
                                            // title="All due payments defaulters"
                                            subtitle="Vehicles reported with due payments today"
                                        />}

                                        {this.state.filter && <TableHeader
                                            subtitle="Payment due results from your filtered data"
                                        />}

                                        <div className="d-flex justify-content-between align-items-center mb-0">
                                            {!this.state.filter && <div className="text-wrap mt-2">
                                                <div className="d-flex ">
                                                    <div className="btn-list mr-2">
                                                        <button type="button"
                                                            className="btn ripple btn-primary btn-sm"
                                                            onClick={() => getPaymentDueByStatus('')}>
                                                            All Due Payments Today<span className="badge badge-light ml-2">{countAllPaymentDues}</span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn ripple btn-success btn-sm"
                                                            onClick={() => getPaymentDueByStatus(0)}>
                                                            Paid Dues Today <span className="badge badge-dark ml-2">{countPaidPaymentDues}</span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn ripple btn-secondary btn-sm"
                                                            onClick={() => getPaymentDueByStatus(1)}>
                                                            Unpaid Dues Today <span className="badge badge-dark ml-2">{countUnPaidPaymentDues}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>}



                                            {Array.isArray(defaultersList) && !this.state.filter &&
                                                <LoadMoreButton
                                                    action={goToNextPage}
                                                    loading={loading}
                                                    title={metaData.current_page + " of " + metaData.total_pages + " pages"}
                                                // title={metaData.current_page + "" + metaData.current_page == 1 ? + " Page" : + " Pages"}
                                                />
                                            }


                                        </div>



                                        {!this.state.filter && <div className="table-responsive allView-table">

                                            {(defaultersList === "404") && <NoContent />}
                                            {loading && !Array.isArray(defaultersList) && <ActivityLoader />}


                                            {defaultersList && Array.isArray(defaultersList) && !this.state.loading && !this.state.filter &&

                                                <table id="tab-payment-due" className="table text-nowrap mb-0 table-hover key-buttons datatable" export-format={eformat}>

                                                    <thead>
                                                        <tr>
                                                            <th >PAYMENT DUE REF.</th>
                                                            <th>DATE</th>
                                                            <th>Vehicle</th>
                                                            <th className="text-center">Payment Status</th>
                                                            <th className="text-center">Amount Due (UGX)</th>
                                                            <th className="text-center"> Clamp Status</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            // defaultersList && defaultersList !== "404" &&
                                                            defaultersList.map((item, key) =>
                                                                <tr key={key}>
                                                                    <td >{item.ref}</td>
                                                                    <td>
                                                                        {item.updated_at.date}
                                                                        <small className="tx-12 tx-gray-500"> at {item.updated_at.time}</small>
                                                                    </td>

                                                                    <td className="tx-bold">
                                                                        <Link to={
                                                                            {
                                                                                pathname: `/vehicles/profile/${item.vehicle.vehicle_number}`,
                                                                                state: { plateNumber: item.vehicle.vehicle_number }
                                                                            }}>{item.vehicle.vehicle_number}
                                                                        </Link>
                                                                    </td>


                                                                    <td className="text-center">
                                                                        {item.uncleared == 1 && <span className="badge rounded badge-secondary">UNCLEARED</span>
                                                                        }
                                                                        {item.uncleared == 0 && <span className="badge rounded badge-success">RESOLVED</span>}
                                                                    </td>

                                                                    <td className="text-center tx-bold">{item.total_amount_c}</td>


                                                                    <td className="text-center">
                                                                        {!item.clamping_info &&

                                                                            <span className="badge badge-light badge-pill ">UNINITIALISED</span>

                                                                        }


                                                                        {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                            === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_partialy === "0" && <span className="badge badge-success badge-pill  badge-pending partial-pay-color ">UNCLAMPED</span>}

                                                                        {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                            === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_partialy === "1" && <span className="badge badge-success badge-pill partial-pay-color ">PAID PARTIALY BEFORE CLAMP</span>}

                                                                        {item.clamping_info != false && item.clamping_info.clamp_info.status.status && item.clamping_info.clamp_info
                                                                            === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" &&
                                                                            item.clamping_info.clamp_info.towed === "1" && <span className="badge  badge-success badge-pill unclamp-tow-color">UNCLAMPED & TOWED</span>}


                                                                        {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                            === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "1" && <span className="badge badge-success badge-pill clamp-color ">CLEARED BEFORE CLAMP</span>}



                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "1" &&
                                                                            <span className="badge badge-info badge-pill">PENDING  CLAMP</span>}


                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "0" && item.clamping_info?.clamp_info.cobra_abort === "1" &&
                                                                            <>
                                                                                <span className="badge badge-danger   badge-pill fw-bold"> Cobra Aborted</span>
                                                                                <br />
                                                                                <small className="tx-12 tx-gray-500"> {item.clamping_info?.clamp_info?.clamp_abort_info?.reason.reason} </small>

                                                                            </>
                                                                        }



                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "0" && item.clamping_info?.clamp_info.admin_abort === "1" &&
                                                                            <>
                                                                                <span className="badge badge-secondary   badge-pill fw-bold"> Clamp removed by Ops</span>
                                                                                <br />
                                                                                <small className="tx-12 tx-gray-500"> {item.clamping_info?.clamp_info?.clamp_abort_info?.reason?.reason} </small>

                                                                            </>
                                                                        }


                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.system_abort === "1" &&
                                                                            <>
                                                                                <span className="badge badge-warning   badge-pill">Left Parking</span>
                                                                                <br />
                                                                                <small className="tx-12 tx-gray-500"> Session Ended </small></>

                                                                        }



                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'RESPONDED' &&
                                                                            <span className="badge badge-secondary badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}

                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'CLAMPED' &&
                                                                            <span className="badge badge-dark badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}
                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING_UNCLAMP' &&
                                                                            <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                                                        {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'UNCLAMP_RESPONDED' &&
                                                                            <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}
                                                                        {/* {item.clamping_info != false && clamping_info?.clamp_info.status.status === 'UNCLAMPED' &&
                                                                            <span className="badge badge-success badge-pill">UNCLAMPED</span>} */}
                                                                    </td>

                                                                    {/* <td>{item.created_at.long_date}</td> */}


                                                                </tr>
                                                            )}

                                                    </tbody>
                                                </table>
                                            }

                                            {/* {defaultersList && Array.isArray(defaultersList) && <LoadMoreButton action={props.fetchMore} loading={props.loading} />} */}


                                        </div>}

                                        {this.state.filter &&
                                            <FilteredDuePayments
                                                vehicleID=""
                                                plateNumber={filter.keyword}
                                                payementStatusID={filter.selectedValue}
                                                amountDue={filter.keyword1}
                                                itemsPerPage={filter.selectedValue5}
                                                createdFrom={filter.dateFrom}
                                                createdTo={filter.dateTo}
                                                filter={filter}
                                                filterclampsTableID={"listFiteredPaymentDueID"}

                                            />
                                        }




                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <RightSideBar />

            </div>
        );

    }


}

export default ListDuePaymentDefaulters
