import React from 'react';
import { useEffect, useState } from "react";
import nocontent from '../../assets/no-internet.svg'
import functions from '../functions';

export const RenderOnline = (props) => {

    const [content, setContent] = useState(<span>Loading...</span>)

    useEffect(() => {
        tryonline();

    }, [])


    const tryonline = async () => {


        try {
            let response = await fetch(functions.getHost + '/street/list',
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                })
                .then(setContent(props.children))

        }
        catch (error) {
            setContent(
                <>

                    <div className="h-100 d-flex justify-content-center align-items-center flex-flow">
                        <img className="w-7 mb-5 opacity-5" alt="no-content" src={nocontent} />
                        <p>You are not connected to internet</p>
                    </div>
                </>
            )
        }


    }

    return (
        <>{content}</>
    )

}