import apiCall from "./apiCall";


export default {


    async fetchRecentTransactions(data)
    {
        let response = await apiCall("violation/clamp/list/recent", data)
        return response;

    },



    async fetchClampTransactions(data)
    {
        let response = await apiCall("violation/clamp/list", data)
        return response;

    },

    async fetchViolationList()
    {
        let response = await apiCall("violation/violations/list", [])
        return response;

    },


    async fetchAvailableTools()
    {
        let response = await apiCall("violations/clamptools/available", [])
        return response;

    },

    async initializeClamping(plateNumber, violationId, locationId, photo)
    {
        const data={
            plate_number : plateNumber,
            violation_id : violationId,
            location_id : locationId,
            vehicle_photo : photo
        }
        let response = await apiCall("violation/clamp/initialize", data)
        return response;

    },


    async directlyClamp(plateNumber, violationId, locationId, cobraId, toolId, photo)
    {
        const data={
            plate_number : plateNumber,
            violation_id : violationId,
            location_id : locationId,
            cobra_id : cobraId,
            tool_id : toolId,
            vehicle_photo : photo
        }
        let response = await apiCall("violation/clamp/clamp/direct", data)
        return response;

    },

    
    async clampVehicle(vehicleId, locationId, toolId)
    {
        const data={
            vehicle_id : vehicleId,
            location_id : locationId,
            clamp_tool_id : toolId
        }
        let response = await apiCall("violation/clamp/clamp", data)
        return response;

    },

    

    async respondToClamp(vehicleId, cobraId)
    {
        const data={
            vehicle_id : vehicleId,
            cobra_id : cobraId
        }
        let response = await apiCall("violation/clamp/respond", data)
        return response;

    },

    async fetchClampCancellationReasons()
    {
        let response = await apiCall("violation/clamp/cancellation/reasons",[])
        return response;

    },
   
    async cancelClamp(clampId, reasonId)
    {
        const data={
            "clamp_id":clampId,
            "reason_id":reasonId
        }
        let response = await apiCall("violation/clamp/cancel", data)
        return response;

    },


    async fetchClampStatuses()
    {
        let response = await apiCall("violation/clamp/status/list",[])
        return response;

    },


    async fetchClampCauses()
    {
        let response = await apiCall("violation/clamp/cause/list",[])
        return response;

    },


    async fetchNonPayment(data)
    {
        let response = await apiCall("violation/clamp/list/nonpayments", data)
        return response;

    },

    async fetchTotalClamped()
    {
        let response = await apiCall("violation/clampedvehicle/count_clamped_vehicles", [])
        return response;

    },

    async TowVehicle(vehicleId, amount)
    {
        const data={
            vehicle_id : vehicleId,
            amount : amount
        }
        let response = await apiCall("violation/clampenforcement/tow_vehicle", data)
        return response;

    },

    
    async fetchTowTransactions(data)
    {
        let response = await apiCall("violation/towedvehicle/list", data)
        return response;

    },

    async returnTowedVehicle(data)
    {
        let response = await apiCall("violation/towedvehicle/pickup", data)
        return response;

    },

    async countReportedEnforcements(data)
    {
        let response = await apiCall("violation/clampenforcement/count_reported_causes", data)
        return response;

    },

    async countClampedEnforcements(data)
    {
        let response = await apiCall("violation/clampenforcement/count_clamped_causes", data)
        return response;
    },


    async fetchReporterStats(data)
    {
        let response = await apiCall("violation/clampenforcement/reporter_stats", data)
        return response;

    },

    async fetchClampReasonAnalysis(data)
    {
        let response = await apiCall("violation/clampedvehicle/count_clamps_by_reason", data)
        return response;
    },

    async fetchStreetClampAnalysis(data)
    {
        let response = await apiCall("violation/clampedvehicle/count_clamps_per_street", data)
        return response;
    },

    async fetchCobrasPerformance(data)
    {
        let response = await apiCall("violation/clampenforcement/cobras_performances", data)
        return response;

    },

    async fetchZulusPerformance(data)
    {
        let response = await apiCall("violation/clampenforcement/zulus_performances", data)
        return response;

    },

    async createClampTool(data)
    {
        let response = await apiCall("violation/clampware/create", data)
        return response;

    },

    async updateClampTool(data)
    {
        let response = await apiCall("violation/clampware/update", data)
        return response;

    },
    
    async fetchToolStatistics()
    {
        let response = await apiCall("violation/clampware/statistics", [])
        return response;

    },


    async respondToUnClamp(vehicleId, zuluId)
    {
        const data={
            vehicle_id : vehicleId,
            zulu_id : zuluId
        }
        let response = await apiCall("violation/clamp/unclamp-respond", data)
        return response;

    },

    async unClampVehicle(vehicleId)
    {
        const data={
            vehicle_id : vehicleId
        }
        let response = await apiCall("violation/clamp/unclamp", data)
        return response;

    },

    async restoreClamp(clampId)
    {
        const data={
            enforcement_id : clampId
        }
        let response = await apiCall("violation/clampenforcement/restore_clamp", data)
        return response;

    },
    

}