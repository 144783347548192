import React, { useContext, useState } from 'react';
import CompanyHeader from '../../Config/CompanyHeader';
import functions from '../../../utils/functions';
import DemandNoteContext from '../../../Context/Finance/DemandNoteContext';
import ActivityLoader from '../../Common/ActivityLoader';
import NoContent from '../../Common/NoContent';


const NoteView = () => {

    const {demandNote} = useContext(DemandNoteContext);
	 

    return (<div className="card custom-card p-0 m-0">
				<div className="card-body">
                    <div className="user-manager scroll-widget border-top overflow-auto" style={{height:'620px'}} id="receipt_doc">
                        <div className="table-responsive">
									{demandNote === '404' && <NoContent />}
                                    {!demandNote && <ActivityLoader />}
                                    {functions.isObject(demandNote) && demandNote && <>
									<div className="d-lg-flex">
                                        <h2 className="card-title mb-1">
											{"INVOICE"}<br/>
                                            REF: {demandNote.info.invoice_number}<br/>
											NO: {demandNote.info.invoice_id}
                                        </h2>
										<div className="ml-auto">
                                            <p className="mb-1"><span className="font-weight-bold">Receipt Date :</span> {demandNote.info.created_at.short_date} {demandNote.info.created_at.time}</p>
										</div>
									</div>
									<hr className="mg-b-40"/>
									<div className="row">
										<div className="col-lg-6">
											<CompanyHeader/>
										</div>
										<div className="col-lg-6 text-right">
											<p className="h3">Invoice to:</p>
											<address>
												{demandNote.info.batch_name}<br/>
                                                {demandNote.info.phone_number}<br/>
												
											</address>
										</div>
									</div>
									<div className="table-responsive mg-t-40">
										<table className="table table-invoice table-bordered">
											<thead>
												<tr>
													<th className="wd-5p">#NO.</th>
													<th className="wd-25p">Vehicle</th>
                                                    <th className="wd-25p">Balance (UGX)</th>
                                                    <th className="wd-25p">As of date</th>
												</tr>
											</thead>
											<tbody>
                                               {demandNote.balances.list.map((item, key)=>
											    <tr key={key}>
                                                    <td>{key+1}</td>
                                                    <td className="tx-12">{item.plate_number}</td>
													<td>{item.amount.amount_c}</td>
													<td>{item.updated_at.short_date}</td>
												</tr>
												 )}
												
												<tr>
													<td className="valign-middle" colSpan="2" rowSpan={3}>
														<div className="invoice-notes">
															
															<label className="main-content-label tx-13">ISSUER SIGN.</label>
															
														</div>
													</td>
													<td className="tx-right">&nbsp;</td>
                                                    <td className="tx-right" colSpan="2">&nbsp;</td>
												</tr>
												<tr>
													<td className="tx-right"><b>Total Amount</b></td>
													<td className="tx-right" colSpan="2"><b>UGX. {demandNote.balances.total.total_p}</b></td>
												</tr>
												
												<tr>
													<td className="tx-right">&nbsp;</td>
													<td className="tx-right" colSpan="2">
														&nbsp;
													</td>
												</tr>

												{demandNote.fiscal_receipt && <>
													<tr>
														<td className="tx-right" rowSpan={3} colSpan="2">Tax</td>
														<td className="tx-right">VAT. Amount</td>
														<td className="tx-right">{demandNote.fiscal_receipt.tax_amount}</td>
													</tr>
													<tr>
														<td className="tx-right">Net. Amount</td>
														<td className="tx-right">{demandNote.fiscal_receipt.net_amount}</td>
													</tr>
													<tr>
														<td className="tx-right">Gross. Amount</td>
														<td className="tx-right">{demandNote.fiscal_receipt.gross_amount}</td>
													</tr>									
												</>}
												
											</tbody>
										</table>
										{demandNote.fiscal_receipt && <div style={{display:'flex', alignContent:'center', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
											<div style={{flex:1}} id="generatedQRCode">
												<img src={demandNote.fiscal_receipt.qr_image} alt="Qrcode" style={{width:'100px'}}/>
											</div>
										    <div style={{flex:1}}>
												<span>FDN NO: {demandNote.fiscal_receipt.invoice_number}</span><br/>
												<span>VERIFICATION CODE: {demandNote.fiscal_receipt.anti_fake_code}</span>

											</div>
										</div>}
									</div>
                                </>}
                                </div>
								</div>
								
                    </div>
                  </div>
				)}

export default NoteView;