import React from 'react';
import nocontent from '../../assets/no-content.svg';

class Welcome extends React.Component {


  render() {

    return (
      <div className="col-12">
        <div className="p-4 m-5 text-center">
          {/* <img
            src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"}
            alt="logo"
            width="150px"
            className="img-fluid mx-auto d-block mb-3"
          /> */}
          <img
            src={process.env.PUBLIC_URL + "../../assets/img/no.png"}
            alt="logo"
            // width="450px"
            className="img-fluid mx-auto d-block mb-4"
          />
          <h3 className="notfound-color">{this.props.title || "Oops! Multiplex says:"}</h3>
          <p className="text-muted">{this.props.subTitle || "There seems not be any available data here, sorry."}</p>
        </div>
      </div>

    );


  }
}

export default Welcome;
