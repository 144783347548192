import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
// import $ from 'jquery';
// import WarnDelete from '../../Components/WarnDelete'
// import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import PopPaymentSummary from '../../Components/Payments/PopPaymentSummary'
import POSReceipt from '../../Components/Payments/POSReceipt'
import WrongParkingReceipt from '../../Components/Payments/WrongParkingReceipt'
import PopInvoice from '../../Components/PopInvoice'
import PaymentContext from '../../Context/PaymentContext'
import dictionary from '../../utils/dictionary'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import NoContent from '../../Components/Common/NoContent'
import PageContainer from '../../Components/Layout/PageContainer'
import PaymentFilter from '../../Components/Payments/Filter/PaymentFilter'

class PaymentRecords extends React.Component {

    static contextType = PaymentContext;

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        status: '',
        paymentView: false,
        receiptView: false,
        invoiceView: false,
        pagination: false,
        paymentMethod: '',
        paymentMethods: false
    }


    onClickRole = (role) => {

        if (role) {

            this.setState({
                transactionList: false,
                metaData: false,
                filterDescription: role.role_name + ' with their respective assigned devices',
                roleId: role.role_id
            }, () => this.listTransactions())
        } else {
            this.setState({
                roleId: false,
                transactionList: false,
                metaData: false,
                filterDescription: 'List of parking reservation invoices',
            }, () => this.listTransactions())
        }



    }




    onSelectRecord = (transactionId, record) => {
        this.setState({
            activeTransactionId: transactionId,
            selectedRecord: record,
            toast: false
        })
    }


    buttons = () => {
        return (
            <>
                {/* <Link to="/finance/new-payment" className="btn ripple btn-success" onClick={this.onClickAddPayment}>
                    <i className="fe fe-plus-circle"></i> New Payment
                </Link> */}

                <Link to="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>

            </>
        )
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }



    onClickPayment = (deposit_ref) => {
        this.setState({
            paymentView: false
        }, () => {

            this.setState({
                paymentView: <PopPaymentSummary deposit_ref={deposit_ref} />
            }, () => {
                functions.openPopup("modal_payment_summary");
            })
        })

    }



    onClickInvoice = (record) => {
        this.setState({
            invoiceView: false
        }, () => {

            this.setState({
                invoiceView: <PopInvoice
                    invoice={record}
                //onClickPay={this.props.onClickPay}
                />
            }, () => {
                functions.openPopup("modal_invoice");
            })
        })

    }


    onClickReceipt = (record) => {
        this.setState({
            receipt: false
        }, () => {
            const invoiceType = record.invoice.invoice_type.type_id;
            switch (invoiceType) {

                case "1"://session
                    this.setState({
                        receiptView: <POSReceipt
                            receipt={record}
                        />
                    }, () => {
                        functions.openPopup("pos_receipt");
                    })
                    break;
                case "3"://Wrong parking
                    this.setState({
                        receiptView: <WrongParkingReceipt
                            receipt={record}
                        />
                    }, () => {
                        functions.openPopup("pos_receipt");
                    })
                    break;
                default:
                    alert("Not available");
                    break;

            }

        })

    }


    onChangePaymentMethod = (method) => {
        this.setState({
            paymentMethod: method,
            metaData: false,
            currentPage: 1,
            transactionList: false
        }, () => this.listTransactions())
    }








    PaymentOptions = () => {

        const { paymentMethods, selectPaymentMethod } = this.context;
        const themes = ['btn-secondary', 'btn-success', 'btn-dark'];
        return (
            <div className="text-wrap">
                <div className="example">

                    {Array.isArray(paymentMethods) && <div className="btn-list">
                        <button type="button"
                            className="btn ripple btn-primary btn-sm"
                            onClick={() => selectPaymentMethod("")}
                        >
                            All Payments <span className="badge badge-light ml-2">...</span>
                        </button>
                        {paymentMethods.map((item, key) =>
                            <button
                                key={key}
                                type="button"
                                className={`btn ripple ${themes.length > key ? themes[key] : 'btn-light'} btn-sm`}
                                onClick={() => selectPaymentMethod(item.mode_id)}
                            >
                                {item.mode_name}
                                {/* <span className="badge badge-dark ml-2">...</span> */}
                            </button>
                        )}


                    </div>}
                </div>
            </div>

        )
    }


    render() {

        const { paymentRecords, info, loading, goToNextPage, metaData } = this.context;

        return (
            <PageContainer>
           
                <PageHeader
                    title="Payment Tracking"
                    previousPageTitle="Finance"
                    back="/finance"
                    buttons={<this.buttons />}
                />
                <PaymentFilter />

                    {this.state.toast}
                    {this.state.invoiceView}
                    {this.state.receiptView}
                    {this.state.paymentView}



                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Payment receipts"
                                            subtitle="List of all payment records from the most recent."
                                        />

                                                    {Array.isArray(paymentRecords) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages + " pages"}
                                                            />
                                                        }

                                      

                                        <this.PaymentOptions />

                                        <div className="table-responsive allView-table border-top">
                                        {Array.isArray(paymentRecords) &&  <table className="table datatable text-nowrap mb-0 table-striped" id="table-payments" export-format={dictionary._allExportFormats}>
                                                <thead>
                                                    <tr>
                                                        <th>Payment Date</th>
                                                        <th>Receipt ID</th>
                                                        <th>Invoice ID</th>
                                                        <th>Vehicle</th>
                                                        <th>Payment Mode</th>
                                                        <th>Customer Contact</th>
                                                        <th>Amount(UGX)</th>
                                                        <th>Deposit Ref</th>
                                                        <th>Cashier</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                   {paymentRecords.map((item, key) =>
                                                        <tr key={key}>
                                                            <td>{item.receipt.payment_date.short_date + ' ' + item.receipt.payment_date.time}</td>
                                                            <td>
                                                                <Link to="#" onClick={() => this.onClickReceipt(item.receipt)}>
                                                                    {item.receipt.payment_id_c}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to="#" onClick={() => this.onClickInvoice(item.receipt.invoice)}>{item.receipt.invoice.invoice_id_c}</Link>
                                                            </td>
                                                            <td>{item.receipt.invoice.vehicle.plate_number}</td>
                                                            <td>{item.receipt.payment_mode.mode_name}</td>
                                                            <td>{item.receipt.depositor_contact}</td>
                                                            <td className="v-paid-amount">{item.receipt.amount.amount_c}</td>
                                                            <td>
                                                                <Link to="#" onClick={() => this.onClickPayment(item.deposit_ref)}>
                                                                    {item.deposit.ref}
                                                                </Link>
                                                            </td>
                                                            <td>{item.receipt.cashier.user_id>1?item.receipt.cashier.last_name + ' ' + item.receipt.cashier.first_name : ""}</td>
                                                        </tr>)}

                                                        <tr>
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                        <th><b>TOTAL</b></th>
                                                        <th><b>{functions.sumTableField('v-paid-amount')}</b></th>
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                    </tr>

                                                </tbody>
                                            </table>}

                                            {info}

                                            {!paymentRecords && <ActivityLoader />}
                                            {paymentRecords==="404" && <NoContent/>}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


            </PageContainer>
        );

    }
}

export default PaymentRecords
