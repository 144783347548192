import React, { useEffect, useState } from 'react';
import ajaxEnforcementAnalysis from '../../utils/remote/ajaxEnforcementAnalysis';
import { detectTable, restoreTable } from '../../utils/script/customTables';




const ContraventionTypeContext = React.createContext({});

export const ContraventionTypeConsumer = ContraventionTypeContext.Consumer;


export const ContraventionTypeProvider=(props)=> {

    const [contraventionTypeTransactions, setContraventionTypeTransactions] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1",  "street_id":"", "branch_id": "", "created_from": "", "created_to":""})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    
    useEffect(()=>{
        //The function below should be wrapped in secureCall()
            getContraventionTypeTransactions()
        
    }, [data])


    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])

  

    const getContraventionTypeTransactions=async()=>{
        setLoading(true)
        setContraventionTypeTransactions(false);
        setMetaData(false)
        const server_response = await ajaxEnforcementAnalysis.listViolationPerformance(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setContraventionTypeTransactions(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setContraventionTypeTransactions("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxEnforcementAnalysis.listViolationPerformance(newData);

        setLoading(false);
           
        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setContraventionTypeTransactions(contraventionTypeTransactions.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1",  "street_id":"", "branch_id": "", "created_from": "", "created_to":""})
        return getFreshObject();
    }

    const filterData=(options)=>{

        setCurrentPage(1)
        setData({
                "street_id": options.selectedValue6,
                "branch_id": options.selectedValue,
                "created_from": options.dateFrom,
                "created_to": options.dateTo,
                "page": 1
        })
        
    }


    const getFreshObject=()=>{

        return [{
                  "street": ""
                },
               
                {
                  "branch": ""
                },
                {
                  "dateFrom": ""
                },
                {
                  "dateTo": ""
                }
              ]
    }





   
        return (
            <ContraventionTypeContext.Provider value={{
               contraventionTypeTransactions,
               metaData,
               loading,
               setData,
               getFreshObject,
               goToNextPage,
               resetData,
               filterData,
               getContraventionTypeTransactions

            }}>
                {props.children}
            </ContraventionTypeContext.Provider>
        );
    }





export default ContraventionTypeContext;