import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async getVendorRecords(page) {

        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'vendor/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterVendorRecords(
        createdFrom,
        createdTo,
        vipValue,
        keyword,
        page,
        // status
    ) {

        let data = {
            "vipValue": vipValue,
            "keyword": keyword,
            "created_from": createdFrom,
            "created_to": createdTo,
            "page": page,
            // "status": status
        }

        try {
            let response = await fetch(apiHost + 'vendor/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getVendorStatement(user_id, page) {

        let data = {
            "page": page,
            "user_id":user_id
        }

        try {
            let response = await fetch(apiHost + 'vendor/statement',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },







}