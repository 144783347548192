import React, { useEffect, useState } from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';


const InvoiceHistory =(props)=> {

  
    return (
      <button
        type="button"
        className="btn ripple btn-warning btn-sm"
        onClick={props.onClick}>
        Invoice History
      </button>

    );


  }

export default InvoiceHistory;
