import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import TableHeader from '../../Common/TableHeader';
import ActivityLoader from '../../Common/ActivityLoader';
import NoContent from '../../Common/NoContent';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';

const ReporterStats =()=> {

    const {getReporterStats, reporterStats} = useContext(ParkingViolationContext);

        return (
            <div className="card custom-card ">
                <div className="card-body map-card">

                    <TableHeader
                        title="Top enforcement reporters"
                        subtitle="Users who have reported parking contraventions."
                        rightComponent={<Link to='#' onClick={getReporterStats}><i className='fa fa-redo text-primary'/></Link>}
                    />

                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                        <div className="table-responsive">
                            {Array.isArray(reporterStats) && <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Reporter</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {reporterStats.map((item, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        <div className="main-img-user">
                                                            <img alt="avatar" src={item.user.profile_photo.file_path} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h6 className="mg-b-1">{item.user.full_name}</h6>
                                                        <small className="tx-12 tx-gray-500">@ {item.user.user_role.role_name}</small>
                                                    </td>
                                    
                                                    <td>{item.total.total_c}</td>
                                                </tr>
                                            )}


                                </tbody>

                            </table>}
                            {reporterStats==='404' && <NoContent />}
                            {!reporterStats && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>

        )

    }



export default ReporterStats
