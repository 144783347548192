import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
// import WarnDelete from '../../../Components/WarnDelete'
// import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
// import PopNewAmount from '../../../Components/Common/PopNewAmount'
import TextInput from '../../../Components/Common/TextInput'
import TableHeader from '../../../Components/Common/TableHeader'

class ConfigWorkHour extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlDelete: false,
        openingTime: '',
        closingTime: '',
        clampingClosingTime: ''

    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.getWorkingHours();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content
            }, () => {
                this.setState({
                    openingTime: this.state.transactionList.opening_time,
                    closingTime: this.state.transactionList.closing_time,
                    clampingClosingTime: this.state.transactionList.clamping_closing_time
                })
            })
        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }




    onUpdateWorkingTime = async () => {

        const server_response = await ajax.changeWorkingHours(this.state.openingTime, this.state.closingTime);
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {

                this.getTransactions();
            })
        } else {
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            })
        }

    }



    onUpdateClampingClosingTime = async () => {

        const server_response = await ajax.changeClampingClosingHours(this.state.clampingClosingTime, this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {

                this.getTransactions();
            })
        } else {
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            })
        }

    }


    refreshToast = () => {
        this.setState({
            toast: false
        })
    }

    onChangeOpeningTime = (event) => {
        this.setState({ openingTime: event.target.value });
        console.log(this.state.openingTime);
    }

    onChangeClosingTime = (event) => {
        this.setState({ closingTime: event.target.value });
    }

    onChangeClampingClosingTime = (event) => {
        this.setState({ clampingClosingTime: event.target.value });
    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlNewAmount}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Working Hours"
                            previousPageTitle="Configurations"
                            back="/config"
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <TableHeader
                                                    title="Working Hours"
                                                    subtitle="Users won't be able to start sessions outside the set working time"
                                                />

                                                <div className='mt-5'></div>

                                                <TextInput
                                                    label="Opening Time"
                                                    type="time"
                                                    onChange={() => this.onChangeOpeningTime}
                                                    value={this.state.openingTime}
                                                />

                                                <TextInput
                                                    label="Closing Time"
                                                    type="time"
                                                    onChange={() => this.onChangeClosingTime}
                                                    value={this.state.closingTime}
                                                />

                                                {/* <TextInput
                                                    label="Clamping Closing Time"
                                                    type="time"
                                                    onChange={() => this.onChangeClampingClosingTime}
                                                    value={this.state.clampingClosingTime}
                                                /> */}


                                                {!transactionList && <ActivityLoader />}

                                                <div className="modal-footer border-0">
                                                    <button className="btn ripple btn-primary" type="button" onClick={this.onUpdateWorkingTime}>Update Hours</button>
                                                </div>

                                            </div>

                                            <div className="card-body">
                                                <TextInput
                                                    label="Clamping Closing Time"
                                                    type="time"
                                                    onChange={() => this.onChangeClampingClosingTime}
                                                    value={this.state.clampingClosingTime}
                                                />

                                                <div className="modal-footer border-0  ">
                                                    <button className="btn ripple btn-info " type="button" onClick={this.onUpdateClampingClosingTime}>Update Clamping EndTIme</button>
                                                </div>

                                            </div>
                                        </div>





                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigWorkHour
