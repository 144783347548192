import React from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import AlertError from './Common/AlertError';
import TextInput from './Common/TextInput';

class PopVehicleInput extends React.Component {

    state = {
        plateNumber: '',
        info: '',
        searchList: false
    }

    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value }, () => this.searchVehicle())
    }

    onConfirm = (event) => {
        event.preventDefault();
        if (this.state.plateNumber.length > 0) {
            this.props.onConfirm(this.state.plateNumber);
        } else {
            this.setState({
                info: <AlertError message={dictionary._requirePlateNumber} />
            })
        }
    }

    onClickPlateNumber = (plateNumber) => {
        this.setState({ plateNumber })
    }


    searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(this.state.plateNumber);
        if (server_response.status === "OK") {
            this.setState({
                searchList: server_response.details.content
            })
        } else {
            this.setState({
                searchList: false
            })
        }
    }

    render() {
        const searchList = this.state.searchList;
        return (
            <div className="modal" id="modal_vehicle_input">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Vehicle Plate Number"
                                    type="text"
                                    onChange={() => this.onChangePlateNumber}
                                    value={this.state.plateNumber}
                                />

                            </form>

                            {searchList && searchList !== "404" && <div className="btn-list">
                                {searchList.map((item, key) =>
                                    <button key={key}
                                        onClick={() => this.onClickPlateNumber(item.plate_number)}
                                        type="button"
                                        className="btn ripple btn-light btn-sm">
                                        {item.plate_number}
                                    </button>)}
                            </div>}


                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Vehicle</button>
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopVehicleInput;
