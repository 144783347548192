import React from 'react';
import { Doughnut } from 'react-chartjs-2';

class HollowPie extends React.Component {

  state = {
    chartData: {
      labels: this.props.labels || ['Sticker', 'Other'],
      legend: {
        position: 'top'
      },
      datasets: [
        {
          //label: 'Rainfall',
          backgroundColor: [
            // '#e95053',
            '#ed6f33',
            '#43d07f',
            '#dfe2e6', '#01b8ff'
            // '#8760fb',

            // '#e1e6f1'
          ],
          // hoverBackgroundColor: [
          //  this.props.color,
          // '#e1e6f1'
          // ],
          colors: this.props.colors,
          data: this.props.values || [65, 59]
        }
      ]
    }

  }

  render() {

    return (

      <Doughnut
        data={this.state.chartData}
        options={{
          title: {
            display: false,
            // text:'Chart title',
            fontSize: 5
          },
          legend: {
            display: this.props.displayKey || false,
            position: 'right'
          }
        }}
      />


    );


  }
}

export default HollowPie;
