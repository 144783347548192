import React, { useEffect, useState } from 'react';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxConfig from '../utils/remote/ajaxConfig';

const MigrationContext = React.createContext();

export const MigrationConsumer = MigrationContext.Consumer;

export const MigrationProvider=(props)=>{

    const [balanceList, setBalanceList] = useState(false);
    const [page, setPage] = useState(1);
    const [message, setMessage] = useState("");
    const [paginationData, setPaginationData] = useState(false);
   
    useEffect(()=>{
        //RequirePermission
        getBalanceList();
    }, [])

   
   
    const getBalanceList=async()=>{
        const server_response = await ajaxConfig.getMigrationVehicleBalances(page);
        if(server_response.status==="OK")
        {  
            setBalanceList(server_response.details.content.list)
            setPaginationData(server_response.details.meta)
        }else if(server_response.status==="Fail"){
            setMessage(server_response.details.message);
            setBalanceList("404");
        }
    }


   

        return (
           <MigrationContext.Provider value={{
               balanceList,
               message,
               getBalanceList
            }}
            >
               {props.children}
           </MigrationContext.Provider>
        );
    }


export default MigrationContext;