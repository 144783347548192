import React, { useEffect, useState } from 'react';
import { detectTable, restoreTable } from '../../utils/script/customTables';
import ajaxEnforcementAnalysis from '../../utils/remote/ajaxEnforcementAnalysis';




const EnforcementContext = React.createContext({});

export const EnforcementConsumer = EnforcementContext.Consumer;


export const EnforcementProvider = (props) => {

    const [enforcementTransactions, setEnforcementTransactions] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({ "page": "1", "street_id": "", "branch_id": "", "created_from": "", "created_to": "" })
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        //The function below should be wrapped in secureCall()
        getEnforcementTransactions()

    }, [data])


    useEffect(() => {
        if (currentPage > 1) {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getEnforcementTransactions = async () => {
        setLoading(true)
        setEnforcementTransactions(false);
        setMetaData(false)
        const server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch(data);
        setLoading(false)
        if (server_response.status === "OK") {
            setEnforcementTransactions(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        } else {
            setEnforcementTransactions("404");
            setMetaData(false);
        }
    }




    const fetchMoreTrigger = () => {
        const newData = {
            ...data,
            "page": currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async (newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch(newData);

        setLoading(false);

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setEnforcementTransactions(enforcementTransactions.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }

    }


    const goToNextPage = () => {
        if (currentPage < metaData.total_pages) {
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData = () => {
        setCurrentPage(1)
        setData({ "page": "1", "street_id": "", "branch_id": "", "created_from": "", "created_to": "" })
        return getFreshObject();
    }

    const filterData = (options) => {

        setCurrentPage(1)
        setData({
            "street_id": options.selectedValue6,
            "branch_id": options.selectedValue,
            "created_from": options.dateFrom,
            "created_to": options.dateTo,
            "page": 1
        })

    }


    const getFreshObject = () => {

        return [{
            "street": ""
        },

        {
            "branch": ""
        },
        {
            "dateFrom": ""
        },
        {
            "dateTo": ""
        }
        ]
    }






    return (
        <EnforcementContext.Provider value={{
            enforcementTransactions,
            metaData,
            loading,
            setData,
            getFreshObject,
            goToNextPage,
            resetData,
            filterData,
            getEnforcementTransactions

        }}>
            {props.children}
        </EnforcementContext.Provider>
    );
}





export default EnforcementContext;