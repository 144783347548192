import React from 'react';
import { Link } from 'react-router-dom';
// import ajax from '../../utils/ajax';
// import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
// import ActivityLoader from '../Common/ActivityLoader';
// import AlertError from '../Common/AlertError';
// import AlertSuccess from '../Common/AlertSuccess';
import InitialAvatar from '../Common/InitialAvatar';
// import TextInput from '../Common/TextInput';

class PopRemoveFromVip extends React.Component {

    state={
        loading:false
    }

    destroyModal=()=>{
        functions.closePopup("modal_add_to_vip");
    }

    onConfirm=()=>{
        this.setState({
            loading:true
        }, ()=>{
            this.props.onConfirm()
        })
    }

  render(){
      const vehicle=this.props.vehicleInfo
      const title="Are you sure you want to remove this vehicle from VIP? This action might require two factor approval to be successful.";
      return (
        <div className="modal" id="modal_remove_from_vip">
        <div className="modal-dialog modal-dialog-centered" role="document">
            

        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Remove from VIP</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                
                <div class="justify-content-center text-center mt-3 d-flex">
                    <InitialAvatar value={<i className="fas fa-taxi"></i>}/>
                </div>
                <div class="justify-content-center text-center mt-3 d-flex">
                    <h5 class="mb-1 mt-0 ">{vehicle.plate_number}</h5>
                </div>

                <div class="justify-content-center text-left mt-3 d-flex">
                    <p class="mb-1"><b>{title} </b></p>
                </div>
               
               
            
            </div>
            <div className="modal-footer">

                    {this.state.loading && <ActivityLoader/>}
                    {!this.state.loading &&
                    <>
                        <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Remove Vehicle</button>
                        <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                    </>}
              
            </div>
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopRemoveFromVip;
