import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import WarnSecureDelete from '../../Components/WarnSecureDelete'
import PopRTJSReceipt from '../../Components/Payments/PopRTJSReceipt'
import { RenderSecure } from '../../utils/script/RenderSecure'

class RTJSRecord extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        RTJSID:'',
        chequeRecord:false,
        rtgsType:false,
        chequeVehicles:false,
        chequeTotal:0,
        vehicleTotal:0,
        excessAmount:0,
        vehicleSheet:false,
        editedAmount:false,
        sheetId:false,
        edited:false,
        loading:false,
        toast: false,
        mdlWarn:false,
        receipt:false,
        receiptLoading:false,
        mdlReceipt:false
       
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/finance/rtjs_payment');
          } else {
            this.setState({
              RTJSID: this.props.location.state.transactionId
            }, () => {
                this.getRTJS();
            })
          }

    }


    getRTJS=async()=>{
        const server_response=await ajax.getRTJSRecord(this.state.RTJSID);
        if(server_response.status==="OK")
        {
            this.setState({
                chequeRecord:server_response.details.content,
                rtgsType:server_response.details.content.rtgs_type.id,
                chequeTotal:server_response.details.content.amount.amount
            })

            if(server_response.details.content.rtgs_type.code==="VEHICLE")
            {
                this.getVehicleCheques()
            }
        }
    }

    
    getVehicleCheques=async()=>{
        const server_response=await ajax.getVehiclesForRTJS(this.state.RTJSID);
        if(server_response.status==="OK")
        {
            this.setState({
                chequeVehicles:server_response.details.content
            }, ()=>{
                const chequeSheet=this.state.chequeVehicles;
                const vehicleSheet=[];
                chequeSheet.map((item, key)=>{
                    vehicleSheet.push({'sheet_id':item.vehicle_rtjs_id, 'amount':item.amount.amount*1, 'vehicle':item.vehicle})
                })
                this.setState({
                    vehicleSheet:vehicleSheet
                }, ()=>this.computeSheetAmount(this.state.vehicleSheet))

            })
        }
    }


    adjustVehicleAmount=(sheet_id, amount)=>{
        const sheet=this.state.vehicleSheet;
        const newSheet=[];
        for(var i=0; i<sheet.length; i++)
        {
            if(sheet[i].sheet_id*1==sheet_id*1)
            {
                newSheet.push({'sheet_id':sheet_id, 'amount':amount*1, 'vehicle':sheet[i].vehicle})
            }else{
                newSheet.push({'sheet_id':sheet[i].sheet_id, 'amount':sheet[i].amount, 'vehicle':sheet[i].vehicle})
            }
        }

        this.setState({
            vehicleSheet:newSheet,
            edited:true
        }, ()=>this.computeSheetAmount(newSheet))
    }


    computeSheetAmount=(sheet)=>{
        var totalVehicles=0;
        for(var i=0; i<sheet.length; i++)
        {
            totalVehicles+=sheet[i].amount
        }
        this.setState({
            vehicleTotal:totalVehicles,
            excessAmount:this.state.chequeTotal*1-totalVehicles
        })
    }

    onClickSheet=(sheetId, amount)=>{
        if(this.state.chequeRecord.status.status_id*1>1)
        {
            this.setState({
                toast: <Toast type={4} message={"This RTJS can nolonger be updated."} onClose={this.resetToast} />
            })
            return false;
        }
        this.setState({
            sheetId:sheetId,
            editedAmount:amount
        })
    }

    onChangeAmount = (event) => {
        this.setState({ editedAmount: event.target.value }, ()=>{
            this.adjustVehicleAmount(this.state.sheetId, this.state.editedAmount)
        })
    }

    resetSheetId=()=>{
        this.setState({sheetId:false})
    }


    buttons = () => {
        const chequeRecord=this.state.chequeRecord
        return (
            <>
            {this.state.chequeRecord && <>

               {this.state.loading && <button to="#" className="btn ripple btn-light" onClick={this.onClickAddPayment}>
                    <ActivityLoader
                     height={20} 
                     width={30} 
                     customClass="custom"
                    />
                </button>}
            
               {this.state.edited && !this.state.loading && 
               <RenderSecure code="U-MODIFY-RTGs-03">
               <button className="btn ripple btn-primary" onClick={this.saveVehicleAmounts}>
                    <i className="fas fa-save"></i> Save Changes
                </button></RenderSecure>}
              
                {!this.state.edited && chequeRecord.status.status_id*1==0 &&  <RenderSecure code="U-PAY-RTGs-03">
                <button className="btn ripple btn-success" onClick={()=>this.onClickHandleRequest(1)}>
                    <i className="far fa-check-circle"></i> {chequeRecord.next_status}
                </button>
                </RenderSecure>
                }
                {!this.state.edited && chequeRecord.status.status_id*1==1 && <RenderSecure code="U-PROCESS-RTGs-03">
                    <button className="btn ripple btn-success" onClick={()=>this.onClickHandleRequest(3)}>
                    <i className="far fa-check-circle"></i> {chequeRecord.next_status}
                </button>
                </RenderSecure>}

                {chequeRecord.status.status_id*1<=1 && 
                <RenderSecure code="D-PAY-RTGs-01">
                <button className="btn ripple btn-danger" onClick={()=>this.onClickHandleRequest(2)}>
                    <i className="fas fa-times"></i> Cancel RTJS
                </button>
                </RenderSecure>}

                {chequeRecord.status.status_id*1==3 && !this.state.receiptLoading?
                <button className="btn ripple btn-light" onClick={this.onClickPrintReceipt}>
                    <i className="fas fa-print"></i> RTJS Receipt
                </button>:
                 <button className="btn ripple btn-light">
                 <ActivityLoader 
                 height={20} 
                 width={30} 
                 customClass="custom"
                 />
             </button>
            }
            </>}
            </>
        )
    }



    saveVehicleAmounts=async()=>{
        this.setState({loading:true})
        const server_response=await ajax.updateVehicleAmountsForRTJS(this.state.vehicleSheet);
        this.setState({loading:false})
        if(server_response.status==="OK")
        {   
            this.setState({
                edited: false,
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
            })
        }else{
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
        }
    }



    resetToast = () => {
        this.setState({
            toast: false
        })
    }





     /**
     * INVOKES THE HANDLE REQUEST DIALOGUE
     */
      onClickHandleRequest=(status)=>{
        functions.closePopup('modal_white_list');
        var { title, description, buttonLabel, buttonType}="";
        this.setState({
            status:status
        })
        switch(status)
        {
            case 2://cancelling
                title="Cancel RTJS";
                description="Please confirm with your account password that you want to cancel this RTJS";
                buttonLabel="Cancel RTJS";
                buttonType="btn-danger"
            break;
            case 1://approving

                title="Approve RTJS";
                description="Please confirm with your account password that you want to approve this RTJS";
                buttonLabel="Approve RTJS";
                buttonType="btn-success"

            break;
            case 3://process
                title="Process RTJS";
                description="Please confirm with your account password that you want to process this RTJS";
                buttonLabel="Process RTJS";
                buttonType="btn-primary"
            break;

            case 4://approving
                title="Terminate RTJS";
                description="Please confirm with your account password that you want to terminate this RTJS";
                buttonLabel="Terminate RTJS";
                buttonType="btn-danger"
            break;

            default:
                title="";
                description="";
                buttonLabel="";
            break;
        }

        this.setState({
            mdlWarn:false
        }, ()=>{
            
            this.setState({
                mdlWarn:<WarnSecureDelete 
                title={title}
                description={description}
                icon="fas fa-question"
                buttonLabel={buttonLabel}
                buttonType={buttonType}
                onConfirm={this.confirmHandleRequest}
                />
            }, ()=>{
                functions.openPopup('modal_secure_delete')
            })
        })
    }


     /**
     * EFFECT THE HANDLE REQUEST ACTION
     */
     confirmHandleRequest=async(password)=>{
        this.setState({
            loading:true
        })
        var server_response;
        switch(this.state.status)
        {
            case 2://CANCEL REQUEST
                server_response=await ajax.cancelRTJS(
                    this.state.RTJSID,
                    password
                );
        
            break;
            case 1://APPROVE REQUEST
                server_response=await ajax.approveRTJS(
                    this.state.RTJSID,
                    password
                );
        
            break;
            case 3://PROCESS REQUEST
            server_response=await ajax.processRTJS(
                this.state.RTJSID,
                password
            );
            break;
            case 4://TERMINATE REQUEST
            // server_response=await ajax.terminateWhiteList(
            //     this.state.request.request_id,
            //     password
            // );
    
            break;
            default:
                server_response={'status':'conn', 'details':{'message':'Invalid request', 'content':false}}
            break;
        }
        this.setState({
            loading:false
        })
       
        if(server_response.status==="OK")
        {
            this.setState({
                toast: <Toast
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
              this.refresh()
        }else{
            this.setState({
                toast: <Toast
                type={4}
                message={server_response.details.message}
                onClose={() => this.resetToast()} />
              })
        }
     }



     refresh=()=>{
        this.setState({
            chequeRecord:false
        }, async()=>{
            await this.getRTJS();
            this.onClickPrintReceipt()
        })
     }



     getRTJSReceipt=async()=>{
        const server_response=await ajax.getRTJSPayment(this.state.RTJSID);
        if(server_response.status=="OK")
        {
            this.setState({
                receiptLoading:false,
                receipt:server_response.details.content,
                mdlReceipt:false
            }, ()=>{
                this.setState({
                    mdlReceipt:<PopRTJSReceipt record={this.state.receipt}/>
                }, ()=>functions.openPopup('modal_cheque_receipt'))
            })
            return true;
        }else{
            this.setState({
                receiptLoading:false,
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
            return false;
        }
     }


     onClickPrintReceipt=()=>{

       if(this.state.chequeRecord.status.status_id*1==3)
       {
        this.setState({
            receiptLoading:true,
            receipt:false
        })

        this.getRTJSReceipt();
        }
     }

   
    render() {
      
        const cheque=this.state.chequeRecord
        const chequeVehicles=this.state.chequeVehicles
        const activeSheet=this.state.vehicleSheet
        return (
            <div className="page">
               
                

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="RTGS Record"
                            previousPageTitle="RTGS Payments"
                            // options={this.state.menuOptions}
                            back="/finance/rtjs_payment"
                            buttons={<this.buttons />}
                        />
                        {this.state.toast}
                        {this.state.mdlWarn}
                        {this.state.mdlReceipt}
                       {this.state.chequeRecord && <div className="row">
                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Cheque Details"
                                            subtitle="Cheque Banking details"
                                        />


                                    <table className="table text-nowrap mb-0 table-hover">
                                    <tbody>
                                        <tr>
                                            <td>RTGS Type</td>
                                            <td className="tx-bold">{cheque.rtgs_type.type_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Bank Of Origin</td>
                                            <td className="tx-bold">{cheque.bank.bank_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Source Description</td>
                                            <td className="tx-bold">{cheque.source_description}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Transaction Date</td>
                                            <td className="tx-bold">{cheque.rtjs_date.short_date}</td>
                                        </tr>

                                        <tr>
                                            <td>Total Amount</td>
                                            <td className="tx-bold">UGX. {cheque.amount.amount_c}</td>
                                        </tr>

                                        
                                        <tr>
                                            <td>Transaction Status</td>
                                            <td className="tx-bold">{cheque.status.status}</td>
                                        </tr>
                                        <tr>
                                            <td>Depositor's Name</td>
                                            <td className="tx-bold">{cheque.depositor_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Depositor's Contact</td>
                                            <td className="tx-bold">{cheque.depositor_contact}</td>
                                        </tr>
                                        <tr>
                                            <td>Created On</td>
                                            <td className="tx-bold">{cheque.created_at.long_date}</td>
                                        </tr>
                                        <tr>
                                            <td>Created By</td>
                                            <td className="tx-bold">{cheque.created_by.username}</td>
                                        </tr>
                                    </tbody>
                                   

                                </table>
                            



                                    </div>
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Payment Details"
                                            subtitle="Cheque payment details"
                                        />
                                        <div className="activity-block mr-3 scroll-widget border-top overflow-auto">
                                         
                                          <table className="table text-nowrap mb-0 table-hover">
                                              <thead>
                                                  <tr>

                                                        <td>No.</td>
                                                        <td>Vehicle</td>
                                                        <td>Amount</td>
                                                  </tr>
                                                
                                              </thead>
                                            <tbody>
                                                {activeSheet && activeSheet.map((item, key)=>
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{item.vehicle.plate_number}</td>
                                                        <td>
                                                        {this.state.sheetId*1!==item.sheet_id*1 && <span className="tx-bold App-link" onClick={()=>this.onClickSheet(item.sheet_id, item.amount)}>
                                                                {item.amount}
                                                            </span>}
                                                            {this.state.sheetId*1===item.sheet_id*1 &&
                                                                <input 
                                                                    size={2}
                                                                    className="form-control" 
                                                                    type="number" 
                                                                    value={this.state.sheetId*1===item.sheet_id*1?this.state.editedAmount:item.amount} 
                                                                    onChange={this.onChangeAmount}
                                                                    onBlur={this.resetSheetId}
                                                                    autoFocus={true}/>}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Vehicles Total</td>
                                                    <td className="tx-bold">UGX. {this.state.vehicleTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Cheque Total</td>
                                                    <td className="tx-bold">UGX. {this.state.chequeTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="tx-bold">Excess Amount</td>
                                                    <td className="tx-bold">UGX. {this.state.excessAmount}</td>
                                                </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        {!chequeVehicles && <ActivityLoader/>}

                                    </div>
                                </div>
                            </div>




                        </div>}

                        {!this.state.chequeRecord && <ActivityLoader/>}

                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );

    }
}

export default RTJSRecord
