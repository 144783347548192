import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import $ from 'jquery';
import PopVehicleInput from '../../Components/PopVehicleInput'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import Export from '../../Components/Common/Export'
import { Link } from 'react-router-dom'
import PageNav from '../../Components/Common/PageNav'
import InitialAvatar from '../../Components/Common/InitialAvatar'
import PopNewVehicle from '../../Components/Vehicle/PopNewVehicle'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import dictionary from '../../utils/dictionary'
import VehicleContext from '../../Context/VehicleContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'


class ListVehicles extends React.Component {

    static contextType = VehicleContext;

    state = {
        mdlNewVehicle: false,
    }


    onClickNewVehicle = () => {
        this.setState({
            mdlNewVehicle: false
        }, () => {
            this.setState({
                mdlNewVehicle: <PopNewVehicle />
            }, () => functions.openPopup('modal_new_vehicle'))
        })
    }





    buttons = () => {
        return (
            <>
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>

                <Link to="#" className="btn ripple btn-success" onClick={this.onClickNewVehicle}>
                    <i className="fe fe-plus-circle"></i> New Vehicle
                </Link>


            </>
        )
    }



    render() {

        const { transactionList, statistics, setData, filterData, goToNextPage, resetData, loading, metaData, vehicleTypes } = this.context;

        const listOptions = [];
        if (Array.isArray(vehicleTypes)) {
            vehicleTypes.forEach((item) => {
                listOptions.push({ "title": item.type_name, "value": item.type_id })
            })
        }



        return (
            <div className="page">
                {this.state.mdlNewVehicle}

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>

                    <TopBar />

                    <div className="container-fluid">
                        <PageHeader
                            title="Vehicle Records"
                            previousPageTitle="Operations"
                            back="/operations"
                            buttons={<this.buttons />}

                        />

                        <CustomizedFilterBar
                            keyword="Plate Hint"
                            selection={{ title: 'Vehicle Type', options: listOptions }}
                            onReset={resetData}
                            onCo nfirm={filterData}
                        />



                        <div className="row">

                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="card custom-card">

                                    <div className="card-body">

                                        {/* { functions.findInPermission(permissionList, 'USER-002') && */}

                                        

                                        <div className="d-flex justify-content-between align-items-center filter-example mt-3">

                                        <TableHeader
                                            title={"Vehicle Listing"}
                                            subtitle="Viewing vehicle records"
                                        />

                                            <div className="text-wrap">
                                                <div className="">
                                                    {statistics &&
                                                        <div className="btn-list">

                                                            <button type="button"
                                                                className="btn ripple btn-light btn-sm"
                                                                onClick={resetData}>
                                                                All Vehicles  <span className="badge badge-dark ml-2">{statistics.total_vehicles.total_p}</span>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn ripple btn-success btn-sm"
                                                                onClick={() => setData({ "vip_status": "1" })}>
                                                                VIP Vehicles <span className="badge badge-dark ml-2">{statistics.vip_vehicles.total_p}</span>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn ripple btn-primary btn-sm"
                                                                onClick={() => setData({ "vip_status": "0" })}>
                                                                Non VIP Vehicles <span className="badge badge-dark ml-2">{statistics.non_vip_vehicles.total_p}</span>
                                                            </button>

                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {Array.isArray(transactionList) &&
                                                <LoadMoreButton
                                                    action={goToNextPage}
                                                    loading={loading}
                                                    title={metaData.current_page + " of " + metaData.total_pages}
                                                />
                                            }
                                        </div>



                                        <div className="table-responsive allView-table border-top">
                                            <table id="table-vehicles" className="table table-hover text-nowrap mg-b-0 datatable" export-format={dictionary._exportBtnFormats}>
                                                <thead>
                                                    <tr>
                                                        <th>Car</th>
                                                        <th>Plate Number</th>
                                                        <th>Is VIP</th>
                                                        <th>Client Name</th>
                                                        <th>Client Contact</th>
                                                        <th>White Listed</th>
                                                        <th>Clamped</th>
                                                        <th>Balance
                                                            <small className="tx-12 tx-gray-500"> UGX</small>
                                                        </th>
                                                        <th>Created On</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {Array.isArray(transactionList) && transactionList.map((item, key) =>
                                                        <tr
                                                            key={key}
                                                        >
                                                            <td>
                                                                <div className="justify-content-center text-center mt-3 d-flex">
                                                                    <InitialAvatar size={1} value={<i className="fas fa-taxi"></i>} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className="mg-b-1">
                                                                    <Link to={
                                                                        {
                                                                            pathname: `/vehicles/profile/${item.plate_number}`,
                                                                            state: { plateNumber: item.plate_number }
                                                                        }}>
                                                                        {item.plate_number}
                                                                    </Link>
                                                                </h6>

                                                                <small className="tx-12 tx-gray-500">{item.vehicle_type ? item.vehicle_type.type_name : "Type unspecified"}</small>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="btn ripple btn-light btn-sm">{item.is_vip}</span>
                                                            </td>
                                                            <td>{item.client.full_name}</td>
                                                            <td>
                                                                <h6 className="mg-b-1">{item.client.phone_number}</h6>
                                                                <small className="tx-12 tx-gray-500">{item.client.email}</small>
                                                            </td>

                                                            <td>
                                                                {item.white_list
                                                                    ?
                                                                    <span className="btn ripple btn-success btn-sm">YES</span>
                                                                    :
                                                                    <span className="btn ripple btn-light btn-sm">NO</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {/* <span className="btn ripple btn-light btn-sm">{item.clamped ? "YES" : "NO"}</span> */}
                                                                {item.clamped
                                                                    ?
                                                                    <span className="btn ripple btn-success btn-sm">YES</span>
                                                                    :
                                                                    <span className="btn ripple btn-light btn-sm">NO</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.balance.amount_c}
                                                            </td>
                                                            <td>
                                                                {item.created_on.short_date}<br />
                                                                <small className="tx-12 tx-gray-500">{item.created_on.time}</small>
                                                            </td>

                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>




                                            {transactionList === "404" && <NoContent />}
                                            {!transactionList && <ActivityLoader />}

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ListVehicles
