import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
// import ajax from '../../../utils/ajax'
import ajaxLocation from '../../../utils/remote/ajaxLocation';
// import ActivityLoader from '../../../Components/Common/ActivityLoader'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'
import SelectSearch from '../../../Components/Common/SelectSearch'

class UpdateLocation extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    streetId: '',
    locationName: '',
    streetList: false,
    description: '',
    locationId: '',
    lat: '',
    lng: '',
    startLat: '',
    startLng: '',
    endLat: '',
    endLng: '',
    totalSlots: '',
    isNonParkingZone: 0,
    status: ''
  }

  componentDidMount() {

    if (!this.props.location.state) {
      this.props.history.push('/config/locations');
    } else {
      const locationRecord = this.props.location.state.record;
      
      this.setState({
        locationName: locationRecord.location_name,
        streetId: locationRecord.street.street_id,
        streetName: locationRecord.street.street_name,
        locationId: locationRecord.location_id,
        description: locationRecord.description,
        lat: locationRecord.lat || "",
        lng: locationRecord.lng || "",
        startLat: locationRecord.starting_lat || "",
        startLng: locationRecord.starting_lng || "",
        endLat: locationRecord.ending_lat || "",
        endLng: locationRecord.ending_lng || "",
        totalSlots: locationRecord.total_slots.total,
        isNonParkingZone: locationRecord.is_nonparking_zone
      })

      this.getStreets();
    }
  }

  getStreets = async () => {
    const server_response = await ajaxLocation.listStreets(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        streetList: server_response.details.content
      })
    } else {
      this.setState({
        streetList: "404"
      })
    }
  }



  onChangeStreet = (selectedOption) => {
    this.setState({
      streetId: selectedOption.value
    })
  }

  onChangeStreetName = (event) => {
    this.setState({ locationName: event.target.value })
  }

  onChangeDescription = (event) => {
    this.setState({ description: event.target.value })
  }


  onSave = async (event) => {
    event.preventDefault();
    const {
      locationName,
      streetId,
      description,
      locationId,
      lat,
      lng,
      startLat,
      startLng,
      endLat,
      endLng,
      totalSlots,
      isNonParkingZone } = this.state;



    if (
      locationName.length > 0 &&
      streetId.length > 0 &&
      description.length > 0 &&
      locationId.length > 0 &&
      lat.length > 0 &&
      lng.length > 0 &&
      totalSlots
    ) {

      //fetch login from the API
      const server_response = await ajaxLocation.updateLocation(
        locationId,
        locationName,
        streetId,
        description,
        lat,
        lng,
        startLat,
        startLng,
        endLat,
        endLng,
        totalSlots,
        isNonParkingZone
      );

      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._completeFields} />,
        loading: ''
      })
    }


  }

  onChangeSlots = (event) => {
    this.setState({ totalSlots: event.target.value })
  }

  onChangeLat = (event) => {
    this.setState({ lat: event.target.value })
  }

  onChangeLng = (event) => {
    this.setState({ lng: event.target.value })
  }

  onChangeStartLat = (event) => {
    this.setState({ startLat: event.target.value })
  }

  onChangeStartLng = (event) => {
    this.setState({ startLng: event.target.value })
  }

  onChangeEndLat = (event) => {
    this.setState({ endLat: event.target.value })
  }

  onChangeEndLng = (event) => {
    this.setState({ endLng: event.target.value })
  }

  onClickToggle = () => {
    if (this.state.isNonParkingZone === 0) {
      this.setState({
        isNonParkingZone: 1
      })
    } else {
      this.setState({
        isNonParkingZone: 0
      })
    }
  }



  render() {

    const streetList = this.state.streetList;
    const optionsStreet = [];
    if (streetList && streetList !== "404") {
      streetList.map((item, key) =>
        optionsStreet.push({ label: item.street_name, value: item.street_id })
      )
    }


    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Edit Parking Location"
              previousPageTitle="Locations"
              back="/config/locations"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>


                          <TextInput
                            label="Location Name"
                            type="text"
                            onChange={() => this.onChangeStreetName}
                            value={this.state.locationName}
                          />

                          <SelectSearch
                            label="Location Street"
                            onChange={() => this.onChangeStreet}
                            options={optionsStreet}
                            placeholder={this.state.streetName}
                          />

                          <TextInput
                            label="Location Description"
                            type="textarea"
                            onChange={() => this.onChangeDescription}
                            value={this.state.description}
                          />

                          <TextInput
                            label="Total Slots"
                            type="number"
                            onChange={() => this.onChangeSlots}
                            value={this.state.totalSlots}
                          />






                          <div className="switch-toggle d-flex justify-content-start m-0 p-0">
                            <span className="mr-5">Is nonparking zone</span>
                            <div className="onoffswitch2 ml-5" onClick={this.onClickToggle}>
                              <input
                                type="radio"
                                className="onoffswitch2-checkbox ml-5"
                                checked={this.state.isNonParkingZone == 0 ? false : true}
                                onChange={() => this.onClickToggle}
                                value={this.state.isNonParkingZone}
                              />
                              <label htmlFor="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                          </div>

                          <hr />
                          {/* <p>---- Geo coordinates ----</p> */}
                          {/* <hr /> */}

                          <TextInput
                            label="Geo Latitude"
                            type="number"
                            onChange={() => this.onChangeLat}
                            value={this.state.lat}
                          />

                          <TextInput
                            label="Geo Longitude"
                            type="number"
                            onChange={() => this.onChangeLng}
                            value={this.state.lng}
                          />

                          <TextInput
                            label="Start Geo Latitude"
                            type="number"
                            onChange={() => this.onChangeStartLat}
                            value={this.state.startLat}
                          />

                          <TextInput
                            label="Start Geo Longitude"
                            type="number"
                            onChange={() => this.onChangeStartLng}
                            value={this.state.startLng}
                          />

                          <TextInput
                            label="End Geo Latitude"
                            type="number"
                            onChange={() => this.onChangeEndLat}
                            value={this.state.endLat}
                          />

                          <TextInput
                            label="End Geo Longitude"
                            type="number"
                            onChange={() => this.onChangeEndLng}
                            value={this.state.endLng}
                          />


                          <div className="modal-footer">
                            <input className="btn ripple btn-success" type="button" value="Save Changes" onClick={this.onSave} />
                          </div>
                        </form>




                      </div>
                    </div>


                  </div>





                </div>

              </div>

              <ConfigMenu />



            </div>


          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default UpdateLocation
