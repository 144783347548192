import React, { useContext } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import PopSessionInfo from '../../Components/PopSessionInfo'
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import PopNewSession from '../../Components/Session/PopNewSession'
import TableHeader from '../../Components/Common/TableHeader'
import PINLocation from '../../Components/Common/PINLocation'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import Export from '../../Components/Common/Export'
import PopExport from '../../Components/Export/PopExport'
import OperationContext, { OperationConsumer } from '../../Context/OperationContext'
import $ from 'jquery';
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import dictionary from '../../utils/dictionary'
import SessionFilter from '../../Components/Session/SessionFilter'
import ParkingsContext from '../../Context/ParkingsContext'



const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

class ListSession extends React.Component {
    static contextType = ParkingsContext;

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        sessionList: false,
        metaData: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        noOfRecords: 8,
        loadTable: true,
        enableUpdate: false,
        heading: "",
    }

    componentDidMount() {
        // const { getParkingsTransactions } = this.context
        // getParkingsTransactions();
    }

    onClickStartSession = () => {
        $("#modal_new_session").modal('show');
    }



    viewSessionInfo = (record) => {
        this.setState({
            sessionPreview: false
        }, () => {
            this.setState({
                sessionPreview: <PopSessionInfo session={record} onEnd={this.onEndSession} />
            }, () => {
                $("#modal_session_info").modal('show');
            })

        })

    }

    onEndSession = (sessionId) => {
        this.setState({
            activeSessionId: sessionId,
            warnSessionEnd: false
        }, () => {

            this.setState({
                warnSessionEnd: <WarnDelete
                    title="End Session?"
                    description="You are about to end session as the currently logged in user!"
                    buttonLabel="End Session"
                    onConfirm={() => this.onConfirmEndSession()} />
            }, () => {
                functions.closePopup("modal_session_info");
                functions.openPopup("modal_delete");
            })

        })

    }




    onConfirmEndSession = async () => {



        if (this.state.activeSessionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.endSession(
                this.state.activeSessionId,
                this.state.userId
            );
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {
                    const { getParkingsTransactions } = this.context
                    getParkingsTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }




    onConfirmStartSession = (conductor, plateNumber) => {
        $("#modal_new_session").modal('hide');
        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: conductor, vehiclePlate: plateNumber }
        })
    }

    onClickExport = () => {
        this.setState({
            dialogue: false
        }, () => {
            this.setState({
                dialogue: <PopExport
                    pdfWithLimitedRecords={true}

                    pdfUrlWithLimit={"session/export/pdf"}

                />
            }, () => {
                $("#modal_export").modal('show');
            })
        })

    }


    buttons = () => {
        return (
            <>
               
                <button className="btn ripple btn-success" onClick={this.onClickStartSession}>
                    <i className="fe fe-plus-circle mr-2"></i> Start Session
                </button>

               {this.context.parkingTransactions && <Link to="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>}

                <button className="btn ripple btn-light" onClick={this.context.getParkingsTransactions}>
                    <i className="fa fa-redo mr-2"></i> Refresh
                </button>

            </>
        )
    }



    onChangeStatus = (status) => {
        this.setState({
            status: status,
            sessionList: false,
            metaData: false,
            currentPage: 1
        }, () => this.getParkingsTransactions())
    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }


    RenderSessions = () => {

        
                    const sessionList = this.context.parkingTransactions;
                    const {goToNextPage, loading, metaData, title} = this.context;

                    return (
                        <>
                        <div className="table-responsive allView-table border-top">

                            {(sessionList === "404") && <NoContent />}

                            {sessionList && Array.isArray(sessionList) &&
                                <table id="table-sessions" export-format={dictionary._exportBtnFormats} className="table text-nowrap table-hover mg-b-0 datatable">
                                    <thead>
                                       <tr>
                                        <th colSpan={11} style={{textAlign:'center'}}>{title}<br/><br/></th>
                                       </tr>
                                        <tr>
                                            <th>No.</th>
                                            <th>Session ref</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Vehicle</th>
                                            <th>Slots</th>
                                            <th>Status</th>
                                            <th>Location</th>
                                            <th>Start Device</th>
                                            <th>Started By</th>
                                            <th>Ended By</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {sessionList.map((item, key) =>
                                            <tr key={key}>
                                                <td>{key+1}</td>
                                                <td>
                                                    <Link
                                                        to="#"
                                                        onClick={() => this.viewSessionInfo(item)}>
                                                        {item.session_id_c}
                                                    </Link>
                                                </td>


                                                <td>
                                                    {item.start_time.short_date}
                                                    <small className="tx-12 tx-gray-500">&nbsp; {item.start_time.time}</small>
                                                </td>
                                                <td>
                                                    {item.end_time.short_date}
                                                    <small className="tx-12 tx-gray-500">&nbsp; {item.end_time.time}</small>
                                                </td>
                                                <td className="tx-bold">
                                                    <Link to={
                                                        {
                                                            pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                            state: { plateNumber: item.vehicle.plate_number }
                                                        }}>{item.vehicle.plate_number}
                                                    </Link>
                                                </td>
                                              
                                                <td>
                                                    {item.number_of_slots}
                                                </td>
                                                <td>
                                                    {item.active === "1" && <span className="badge rounded badge-success">Active</span>}
                                                    {item.active === "0" && <span className="badge rounded badge-secondary">Ended</span>}
                                                </td>
                                                <td>
                                                    <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                        <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                        {item.location.location_name}
                                                        <small className="tx-12 tx-gray-500"> at {item.location.street.street_name}</small>
                                                    </span>
                                                </td>
                                                <td>
                                                    {item.start_device.tag_number}
                                                </td>
                                                <td>{item.conductor.first_name + ' ' + item.conductor.last_name}</td>
                                                <td>{item.ended_by.full_name}</td>

                                            </tr>
                                        )}


                                    </tbody>
                                </table>
                            }



                            {!sessionList && <ActivityLoader />}

                        </div>
                      

                        {Array.isArray(sessionList) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }
                      
                        </>

                    )

                
    }


    RenderFilter = () => {
        return <SessionFilter/>
    }



    RenderSessionStatus = () => {
        return (
            <OperationConsumer>
                {props => {
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button"
                                        className="btn ripple btn-primary btn-sm"
                                        onClick={() => this.context.setStatus('')}>
                                        All Sessions <span className="badge badge-light ml-2">{props.countAllSessions}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn ripple btn-success btn-sm"
                                        onClick={() => this.context.setStatus(1)}>
                                        Active Sessions <span className="badge badge-dark ml-2">{props.countActiveSessions}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn ripple btn-secondary btn-sm"
                                        onClick={() => this.context.setStatus(0)}>
                                        Ended Sessions <span className="badge badge-dark ml-2">{props.countEndedSessions}</span>
                                    </button>

                                    {/* {sessionList !== "404" && filter && <Export
                                    extraClasses="btn-sm float-right"
                                    excelUrl={"session/filter/export?keyword=" + filter.keyword + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&conductor_id=" + filter.selectedValue + "&status=" + this.state.status}
                                    pdfUrl={"session/filtered/export/pdf?keyword=" + filter.keyword + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&conductor_id=" + filter.selectedValue + "&status=" + this.state.status + "&no_of_records=" + '2'}
                                />} */}
                                </div>
                            </div>
                        </div>
                    )
                }}
            </OperationConsumer>
        )
    }


    render() {

        return (
            <div className="page">
                {this.state.sessionPreview}
                {this.state.warnSessionEnd}
                {this.state.dialogue}


                <PopNewSession onConfirm={this.onConfirmStartSession} />

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="All Sessions"
                            previousPageTitle="Dashboard"
                            // options={this.state.menuOptions}
                            back="/operations"
                            buttons={<this.buttons />}
                        />

                        <this.RenderFilter />


                        {this.state.toast}
                        {this.state.locationPIN}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-between align-items-center filter-example">
                                            <TableHeader
                                                title="All session activity"
                                                subtitle="List of all parking sessions from the most recent"
                                            />

                                            <this.RenderSessionStatus />
                                        </div>

                                        <this.RenderSessions />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>
        );

    }
}

export default ListSession
