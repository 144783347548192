import React from 'react';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from './../Common/AlertError';
import TextInput from './../Common/TextInput';

class PopNewBankingClient extends React.Component {

    state = {
        bankName: '',
        info: '',
        loading: false,
    }

    onChangBankName = (event) => {
        this.setState({ bankName: event.target.value });
    }

  
    onConfirm = (event) => {
        event.preventDefault();
        const { bankName } = this.state;
        if (bankName.length > 0) {
            this.setState({
                loading: true
            })
            this.props.onConfirm(bankName);
        } else {
            this.setState({
                info: <AlertError message={"Enter bank name and try again"} />
            })
        }
    }



    render() {
        return (
            <div className="modal" id="modal_new_banking_client">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Banking Client</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Bank Name"
                                    type="text"
                                    onChange={() => this.onChangBankName}
                                    value={this.state.bankName}
                                />
                             

                            </form>



                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Add Bank</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopNewBankingClient;
