import React, { useState } from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';

const PopEditDevice=(props)=>{

    const [loading, setLoading] = useState(false);
    const [serialNumber, setSerialNumber] = useState(props.record.device_number);
    const [phoneNumber, setPhoneNumber] = useState(props.record.phone_number);
    const [tagNumber, setTagNumber] = useState(props.record.tag_number);
    
    const [info, setInfo] = useState(false);
  

   const onConfirm = (event) => {
        event.preventDefault();
        if (serialNumber.length > 0) {
            setLoading(true);
            props.onConfirm(props.record.device_id, serialNumber, phoneNumber, tagNumber);
        } else {
           
            setInfo(<AlertError message={"Enter device serial number and try again"} />)
            
        }
    }


   
        return (
            <div className="modal" id="modal_update_device">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Edit Device</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                                <TextInput
                                    label="Serial Number"
                                    type="text"
                                    onChange={(e) => setSerialNumber(e.target.value)}
                                    value={serialNumber}
                                    name="serialNumber"
                                />

                                <TextInput
                                    label="Phone Number"
                                    type="text"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    name="phoneNumber"
                                />

                                 <TextInput
                                    label="Tag Number"
                                    type="text"
                                    onChange={(e) => setTagNumber(e.target.value)}
                                    value={tagNumber}
                                    name="tagNumber"
                                />

                            </form>


                        </div>
                        <div className="modal-footer">
                            {!loading && <>
                            <button className="btn ripple btn-primary" type="button" onClick={onConfirm}>Save Changes</button>
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }


export default PopEditDevice;
