import React, { createContext, Component } from 'react';
import functions from '../../utils/functions';
import $ from 'jquery';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';
import Toast from '../../Components/Common/Toast';
import PopViewImage from '../../Components/PopViewImage';
import ajaxPaymentDue from '../../utils/remote/ajaxPaymentDue';
import { detectTable, restoreTable } from '../../utils/script/customTables';


const eformat = dictionary._exportBtnFormats;



const PaymentDueContext = React.createContext({});



export class PaymentDueProvider extends Component {

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        WrongParking: '',
        currentPage: 1,
        defaultersList: false,
        modal: false,
        metaData: false,
        toast: false,
        hasError: false,
        info: false,
        tableId: "table",
        loadTable: true.valueOf,

        countTodaysPaymentDues: '...',
        recentPaymentDues: false,
        countPaidPaymentDues: '...',
        countUnPaidPaymentDues: '...',
        countAllPaymentDues: '...',
        isCleared: '',
        heading: '',
        loading: false,
    }


    componentDidMount() {

        this.listPaymentDueDefaulters();
        this.countTodaysPaymentDueTransactions();

        this.getCountAllPaymentDues();
        this.getCountAllPaidPaymentDues();
        this.getCountAllUnPaidPaymentDues();

        this.getRecentPaymentDuesTransactions();

        // this.timerListPaymentDue = setTimeout(this.funcPaymentDueList = async () => {
        //     await this.listPaymentDueDefaulters();
        //     this.timerListPaymentDue = setTimeout(this.funcPaymentDueList, dictionary._waitTime); // (*)
        // }, dictionary._waitTime);



    }


    componentWillUnmount() {
        clearTimeout(this.timerListWrongParking);
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }
    onConfirmVehicle = () => {
        //hide the modal

        this.setState({
            modal: false
        }, () => {
            this.setState({
                // modal: <PopReportWrongParking
                //     title="Report Wrong Parking"
                //     onReload={this.listWrongParkingTransactions}
                //     onConfirm={this.onConfirmReport} />

            })
        })
    }

    listPaymentDueDefaulters = async () => {
        this.setState({
            loading: true,
        })

        const server_response = await ajax.listPaymentDueDefaulters(this.state.isCleared, this.state.currentPage);
        this.setState({
            loading: false
        })
        if (server_response.status === "OK") {
            this.setState({
                defaultersList: server_response.details.content.list,
                metaData: server_response.details.content.meta,
                tableId: "payment-due"
            }, () => {
                this.setState({
                    loadTable: false
                })

            })

        } else {
            this.setState({
                defaultersList: "404",
                metaData: false
            })
        }
    }



    fetchMore = async (currentPage) => {
        const { defaultersList } = this.state
        this.setState({
            loading: true
        })

        restoreTable(detectTable());
        const server_response = await ajax.listPaymentDueDefaulters(this.state.isCleared, currentPage);
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            this.setState({
                defaultersList: defaultersList.concat(incoming),
                metaData: server_response.details.content.meta
            })
        }

    }

    goToNextPage = () => {
        const { currentPage, metaData } = this.state
        if (currentPage < metaData.total_pages) {
            this.setState({
                currentPage: currentPage + 1
            }, () => this.fetchMore(currentPage + 1))

        }
    }



    getRecentPaymentDuesTransactions = async () => {
        const server_response = await ajaxPaymentDue.listRecentPaymentDue();
        if (server_response.status === 'OK') {
            this.setState({
                recentPaymentDues: server_response.details.recent_payment_due_transactions.list,
            })
        }
        else {
            this.setState({
                recentPaymentDues: "404",
            })
        }

    }



    countTodaysPaymentDueTransactions = async () => {
        const server_response = await ajaxPaymentDue.countTodaysPaymentDues();
        if (server_response.status === 'OK') {
            this.setState({
                countTodaysPaymentDues: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countTodaysPaymentDues: "404",
            })
        }

    }


    getCountAllPaymentDues = async () => {

        const server_response = await ajaxPaymentDue.countAllPaymentDues();
        if (server_response.status === "OK") {
            this.setState({
                countAllPaymentDues: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countAllPaymentDues: "404",
            })
        }
    }

    getCountAllUnPaidPaymentDues = async () => {
        const server_response = await ajaxPaymentDue.countAllUnPaidPaymentDues();
        if (server_response.status === "OK") {
            this.setState({
                countUnPaidPaymentDues: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countUnPaidPaymentDues: "404",
            })
        }
    }


    getCountAllPaidPaymentDues = async () => {
        const server_response = await ajaxPaymentDue.countAllPaidPaymentDues();
        if (server_response.status === "OK") {
            this.setState({
                countPaidPaymentDues: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countPaidPaymentDues: "404",
            })
        }
    }



    resetToast = () => {
        this.setState({
            toast: false
        })
    }


    getPaymentDueByStatus = (isCleared) => {
        this.setState({
            isCleared: isCleared,
            loading: true,
            currentPage: 1,
            defaultersList: false
        })
    }




    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            List: false
        }, () => this.listPaymentDueDefaulters())
    }

    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                List: false

            }, () => {
                this.listPaymentDueDefaulters();
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                List: false
            }, () => {
                this.listPaymentDueDefaulters();
            })
        }

    }



    render() {

        const {
            userId,
            countActiveSessions,
            WrongParking,
            currentPage,
            defaultersList,
            modal,
            metaData,
            toast,
            hasError,
            info,
            countTodaysPaymentDues,
            recentPaymentDues,

            countPaidPaymentDues,
            countUnPaidPaymentDues,
            countAllPaymentDues,
            isCleared,
            heading,
            loading,
        } = this.state;


        return (
            <PaymentDueContext.Provider value={{
                show: "it shows",
                userId: userId,
                countActiveSessions: countActiveSessions,
                currentPage: currentPage,
                defaultersList: defaultersList,
                modal: modal,
                metaData: metaData,
                toast: toast,
                hasError: hasError,
                info: info,
                listDuePayments: this.listPaymentDueDefaulters,
                tableId: this.state.tableId,

                // onClickPage: onClickPage(),
                // onClickNext: onClickNext(),
                // onClickPrevious: onClickPrevious()

                // FuncViewPhoto: this.onClickViewVehiclePhoto
                countTodaysPaymentDues: countTodaysPaymentDues,
                countTodaysPaymentDueTransactions: this.countTodaysPaymentDueTransactions,
                getRecentPaymentDuesTransactions: this.getRecentPaymentDuesTransactions,
                recentPaymentDues: recentPaymentDues,

                countPaidPaymentDues: countPaidPaymentDues,
                countUnPaidPaymentDues: countUnPaidPaymentDues,
                countAllPaymentDues: countAllPaymentDues,
                isCleared: isCleared,
                heading: heading,
                loading: loading,

                getCountAllPaymentDues: this.getCountAllPaymentDues,
                getCountAllPaidPaymentDues: this.getCountAllPaidPaymentDues,
                getCountAllUnPaidPaymentDues: this.getCountAllUnPaidPaymentDues,
                getPaymentDueByStatus: this.getPaymentDueByStatus,
                goToNextPage: this.goToNextPage

            }}>
                {this.props.children}
            </PaymentDueContext.Provider>
        );
    }

}

// export const WrongParkingProvider = (props) => {

//     return (
//         <WrongParkingContext.Provider value={{show:"showing"}}>
//             {props.children}
//         </WrongParkingContext.Provider>
//     );


// }


export default PaymentDueContext;