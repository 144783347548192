import React from 'react';

class PopClampInfo extends React.Component {

    state = {
        clampInfo: this.props.clampInfo

    }

    render() {
        const { clampInfo } = this.state
        return (
            <div className="modal" id="modal_clamp_info">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Clamp Info</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">


                            {clampInfo && <div className="main-contact-info-body ">

                                <div className="media-list">
                                    {clampInfo.clamped_for.item_id == "2" && <div className="d-flex align-items-start ">
                                        <div className="wp-cover">
                                            <div className="media-body flex-column">
                                                <label>Wrong Parking Image</label>

                                                <img className="wp-img mt-3" src={clampInfo.wrong_parking?.wrong_parking_info?.vehicle_photo?.file_path} alt="wrong-park-img" />
                                            </div>
                                        </div>

                                        <div className="media ml-5">
                                            <div className="d-flex flex-column media-body">

                                                <div className="mb-3 border-bottom">
                                                    <label>Vehicle Number</label>
                                                    <span className="tx-medium">{clampInfo.vehicle.plate_number}</span>
                                                </div>

                                                <div className="mb-3 border-bottom">
                                                    <label>Clamped For</label>
                                                    <span className="tx-medium">{clampInfo.clamped_for.item}</span>
                                                </div>

                                                <div className="mb-3 border-bottom">
                                                    <label>Reason</label>
                                                    <span className="tx-medium">{clampInfo.wrong_parking && clampInfo.wrong_parking?.wrong_parking_info?.description?.description}</span>
                                                </div>

                                                <div className="mb-3 border-bottom">
                                                    <label className="d-flex">Reported By</label>
                                                    <span className="d-flex">
                                                        <span className="tx-medium">
                                                            {clampInfo.wrong_parking && clampInfo.wrong_parking?.wrong_parking_info?.reported_by?.username}
                                                        </span>
                                                        <small className="text-secondary ml-1 tx-small">
                                                            ({clampInfo.wrong_parking && clampInfo.wrong_parking?.wrong_parking_info?.reported_by?.user_role?.role_name})
                                                        </small>
                                                    </span>
                                                </div>

                                                <div>
                                                    <label className="mb-1">Clamp Status  </label>
                                                    {/* {clampInfo.status.status == 'PENDING' &&
                                                        <div className="badge badge-info badge-pill">{clampInfo.status.status}</div>} */}

                                                    {clampInfo.status.status === 'PENDING' && clampInfo.is_parked === "1" &&
                                                        <div className="badge badge-info badge-pill">{clampInfo.status.status}</div>}

                                                    {clampInfo.status.status === 'PENDING' && clampInfo.is_parked === "0" && clampInfo.system_abort === "0" &&
                                                        <div className="badge badge-danger   badge-pill">ABORTED</div>}


                                                    {clampInfo.status.status === 'PENDING' && clampInfo.system_abort === "1" &&
                                                        <div className="badge badge-warning   badge-pill">LEFT PARKING</div>}

                                                    {clampInfo.status.status == 'RESPONDED' &&
                                                        <div className="badge badge-secondary badge-pill">{clampInfo.status.status}</div>}
                                                    {clampInfo.status.status == 'CLAMPED' &&
                                                        <div className="badge badge-dark badge-pill text-white">{clampInfo.status.status}</div>}

                                                    {clampInfo.status.status == 'PENDING_UNCLAMP' &&
                                                        <div className="badge badge-primary badge-pill">{clampInfo.status.status}</div>}

                                                    {clampInfo.status.status == 'UNCLAMP_RESPONDED' &&
                                                        <div className="badge badge-light badge-pill">{clampInfo.status.status}</div>}


                                                    {clampInfo.status.status == 'UNCLAMPED' && clampInfo.paid_before_clamp === "0" && clampInfo.towed === "0" && clampInfo.paid_partialy === "0" &&
                                                        <div className="badge badge-success badge-pill">{clampInfo.status.status}</div>}

                                                    {clampInfo.status.status == 'UNCLAMPED' && clampInfo.paid_partialy === "1" &&
                                                        <div className="badge badge-pending badge-pill partial-pay-color">PAID PARTIALY BEFORE CLAMP</div>}

                                                    {clampInfo.status.status == 'UNCLAMPED' && clampInfo.towed === "1" &&
                                                        <div className="badge badge-success badge-pill unclamp-tow-color">UNCLAMPED & TOWED</div>}

                                                    {clampInfo.status.status == 'UNCLAMPED' && clampInfo.paid_before_clamp === "1" &&
                                                        <div className="badge badge-success badge-pill clamp-color ">CLEARED BEFORE CLAMP</div>}

                                                </div>

                                            </div>
                                        </div>
                                    </div>}




                                    {clampInfo.clamped_for.item_id == "2" && <div className="media border-top mt-3">
                                        <div className="media-body mt-3">
                                            <div>
                                                <label>ClampTool Number</label>
                                                <span className="tx-medium">{clampInfo?.clamp_tool ? clampInfo?.clamp_tool.clamp_number : "----"}</span>
                                            </div>

                                            <div>
                                                <label>Clamp ID</label>
                                                <span className="tx-small ">{clampInfo.transaction_id}</span>
                                            </div>



                                            <div>
                                                <label>Clamp REF</label>
                                                <span className="tx-medium">{clampInfo.ref}</span>
                                            </div>
                                        </div>
                                    </div>}

                                    {clampInfo.clamped_for.item_id !== "2" &&
                                        <>
                                            <div className="media">
                                                <div className="media-body">
                                                    <div className="">
                                                        <label>Vehicle Number</label>
                                                        <span className="tx-medium">{clampInfo.vehicle.plate_number}</span>
                                                    </div>

                                                    <div className="">
                                                        <label>Clamped For</label>
                                                        <span className="tx-medium">{clampInfo.clamped_for.item}</span>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="media">
                                                <div className="media-body">
                                                    <div>
                                                        <label>ClampTool Number</label>
                                                        <span className="tx-medium">{clampInfo?.clamp_tool ? clampInfo?.clamp_tool.clamp_number : "----"}</span>
                                                    </div>
                                                    <div>
                                                        <label className="mb-1">Clamp Status  </label>
                                                        {/* {clampInfo.status.status == 'PENDING' &&
                                                        <div className="badge badge-info badge-pill">{clampInfo.status.status}</div>} */}

                                                        {clampInfo.status.status === 'PENDING' && clampInfo.is_parked === "1" &&
                                                            <div className="badge badge-info badge-pill">{clampInfo.status.status}</div>}

                                                        {clampInfo.status.status === 'PENDING' && clampInfo.is_parked === "0" && clampInfo.system_abort === "0" &&
                                                            <div className="badge badge-danger   badge-pill">ABORTED</div>}


                                                        {clampInfo.status.status === 'PENDING' && clampInfo.system_abort === "1" &&
                                                            <div className="badge badge-warning   badge-pill">LEFT PARKING</div>}

                                                        {clampInfo.status.status == 'RESPONDED' &&
                                                            <div className="badge badge-secondary badge-pill">{clampInfo.status.status}</div>}
                                                        {clampInfo.status.status == 'CLAMPED' &&
                                                            <div className="badge badge-dark badge-pill text-white">{clampInfo.status.status}</div>}

                                                        {clampInfo.status.status == 'PENDING_UNCLAMP' &&
                                                            <div className="badge badge-primary badge-pill">{clampInfo.status.status}</div>}

                                                        {clampInfo.status.status == 'UNCLAMP_RESPONDED' &&
                                                            <div className="badge badge-light badge-pill">{clampInfo.status.status}</div>}

                                                        {clampInfo.status.status == 'UNCLAMPED' && clampInfo.paid_before_clamp === "0" && clampInfo.towed === "0" && clampInfo.paid_partialy === "0" &&
                                                            <div className="badge badge-success badge-pill">{clampInfo.status.status}</div>}

                                                        {clampInfo.status.status == 'UNCLAMPED' && clampInfo.paid_partialy === "1" &&
                                                            <div className="badge badge-pending badge-pill partial-pay-color">PAID PARTIALY BEFORE CLAMP</div>}

                                                        {clampInfo.status.status == 'UNCLAMPED' && clampInfo.towed === "1" &&
                                                            <div className="badge badge-success badge-pill unclamp-tow-color">UNCLAMPED & TOWED</div>}

                                                        {clampInfo.status.status == 'UNCLAMPED' && clampInfo.paid_before_clamp === "1" &&
                                                            <div className="badge badge-success badge-pill clamp-color ">CLEARED BEFORE CLAMP</div>}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="media">
                                                <div className="media-body">

                                                    <div>
                                                        <label>Clamp ID</label>
                                                        <span className="tx-small ">{clampInfo.transaction_id}</span>
                                                    </div>



                                                    <div>
                                                        <label>Clamp REF</label>
                                                        <span className="tx-medium">{clampInfo.ref}</span>
                                                    </div>

                                                </div>



                                            </div>
                                        </>
                                    }



                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Added On</label>
                                                <span className="tx-medium">{clampInfo.created_at.long_date}</span>
                                            </div>
                                            <div>
                                                <label>Updated on</label>
                                                <span className="tx-medium">{clampInfo.updated_at.long_date}</span>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Initial Location</label>
                                                <span className="tx-medium">{clampInfo.initial_location ? clampInfo?.initial_location.location_name : "----"}</span>
                                            </div>

                                            <div>
                                                <label>Clamped Location</label>
                                                <span className="tx-medium">{clampInfo.clamped_location ? clampInfo.clamped_location.location_name : "----"}</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Responded Cobra</label>
                                                <span className="tx-medium">{clampInfo.clamped_by ? clampInfo?.clamped_by.first_name : "----"} {clampInfo?.clamped_by.last_name}</span>
                                            </div>

                                            <div>
                                                <label>Responded Zulu</label>
                                                <span className="tx-medium">{clampInfo.unclamped_by ? clampInfo?.unclamped_by?.first_name : "-----"} {clampInfo?.unclamped_by?.last_name}</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Clamped By</label>
                                                <span className="tx-medium">{clampInfo.clamped_by ? clampInfo?.clamped_by.first_name : "----"} {clampInfo?.clamped_by.last_name}</span>
                                            </div>

                                            <div>
                                                <label>UnClamped By</label>
                                                <span className="tx-medium">{clampInfo.unclamped_by ? clampInfo?.unclamped_by?.first_name : "-----"} {clampInfo?.unclamped_by?.last_name}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {clampInfo.clamp_abort_info &&

                                        <><div className="media">
                                            <div className="media-body">
                                                <div>
                                                    <label className="text-danger" >Aborted By</label>
                                                    <span >{clampInfo?.clamp_abort_info?.aborted_user?.first_name} {clampInfo?.clamp_abort_info?.aborted_user?.last_name}</span>
                                                </div>
                                                <div>
                                                    <label className="text-danger">Abort reason</label>
                                                    <span >{clampInfo?.clamp_abort_info?.reason?.reason}</span>
                                                </div>
                                            </div>
                                        </div>

                                            <div className="media">
                                                <div className="media-body">
                                                    <div>
                                                        <label className="text-danger">Aborted on</label>
                                                        <span >{clampInfo?.clamp_abort_info?.created_at.long_date}</span>
                                                    </div>
                                                    <div>
                                                        <label className=" text-danger">Abort Location</label>
                                                        <span >{clampInfo?.clamp_abort_info?.clamp_attempt_location?.location_name} along {clampInfo?.clamp_abort_info?.clamp_attempt_location?.street?.street_name}</span>
                                                    </div>

                                                </div>

                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                            }


                        </div>
                    </div>


                </div>
            </div >
        )


    }
}

export default PopClampInfo;
