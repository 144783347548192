import React from 'react';
import dictionary from '../../../utils/dictionary';
import AlertError from '../../Common/AlertError';
import SelectSearch from '../../Common/SelectSearch';
import TextInput from '../../Common/TextInput';
import ajax from '../../../utils/ajax';
import Toast from '../../../Components/Common/Toast'
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader'
import AlertSuccess from '../../Common/AlertSuccess';
import WrongParkingContext from '../../../Context/ContraventionContext/WrongParkingContext';
import { ConfigurationConsumer } from '../../../Context/ConfigurationContext';
import AlertWarning from '../../Common/AlertWarning';
import ImageUpload from '../../Common/ImageUpload';


class PopReportWrongParking extends React.Component {

    static contextType = WrongParkingContext;

    state = {
        userId: functions.sessionGuard(),
        plateNumber: '',
        // descriptionId: '',
        lat: '',
        lng: '',
        info: '',
        reportedById: '',
        conductorList: false,
        TransactionId: '',
        vehicleId: '',
        searchList: false,
        toast: false,
        modal: '',
        wrongParkingDescriptionList: false,
        locationList: false,
        descriptionId: '',
        locationId: '',
        conductorRoleId: 2,
        clampforId: '',
        clampedForList: '',
        unUsedClampToolList: false,
        clampToolId: '',
        cobraList: '',
        cobraRoleId: 3,
        filter: "",
        loading: false,
        uploadVehiclePhoto: ''




    }

    componentDidMount() {

        // this.getDescriptions();
        // this.getLocations();
        // this.getContraventions();
        // this.getUnUsedClampTools();
        // this.getCobras();

    }















    onClickPlateNumber = (plateNumber) => {
        this.setState({ plateNumber }, () => this.setState({ searchList: false }))
    }


    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value }, () => this.searchVehicle())
    }

    onChangeUser = (selectedOption) => {
        this.setState({
            reportedById: selectedOption.value
        }, () => this.setState({ searchList: false }))
    }

    onChangeDescription = (selectedOption) => {
        this.setState({
            descriptionId: selectedOption.value
        }, () => this.setState({ searchList: false }))
    }

    onChangeAddress = (selectedOption) => {
        this.setState({
            locationId: selectedOption.value
        }, () => this.setState({ searchList: false }))
    }

    onChangeClampReason = (selectedOption) => {
        this.setState({
            clampforId: selectedOption.value
        }, () => this.setState({ searchList: false }))
    }

    onChangeClampTool = (selectedOption) => {
        this.setState({
            clampToolId: selectedOption.value
        })
    }

    onChangeImage = (event) => {

        this.setState({
            fileName: event.target.value
        })
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.setState({
                uploadVehiclePhoto: e.target.result
            })
        }

    }




    searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(this.state.plateNumber);
        if (server_response.status === "OK") {
            this.setState({
                searchList: server_response.details.content
            })
        } else {
            this.setState({
                searchList: false
            })
        }
    }

    onConfirm = (event) => {
        event.preventDefault();
        const { plateNumber, reportedById, locationId, descriptionId, clampforId, clampToolId, uploadVehiclePhoto } = this.state;
        if (plateNumber.length > 0 && reportedById.length > 0 && locationId.length > 0 && descriptionId.length > 0) {
            this.props.onConfirm(plateNumber, reportedById, locationId, descriptionId, clampforId, clampToolId, uploadVehiclePhoto);
        } else {
            this.setState({
                info: ''
            }, () => this.setState({
                info: <AlertError message={"Complete all feilds"} />
            })
            )
        }
    }

    onConfirmReportAndClamp = (event) => {
        event.preventDefault();
        const { plateNumber, reportedById, locationId, descriptionId, clampforId, clampToolId, uploadVehiclePhoto } = this.state;
        if (plateNumber.length > 0 && reportedById.length > 0 && locationId.length > 0 && clampToolId.length > 0) {
            this.props.onConfirm(plateNumber, reportedById, locationId, clampforId, clampToolId, descriptionId, uploadVehiclePhoto);
        } else {
            this.setState({
                info: ''
            }, () => this.setState({
                info: <AlertError message={"Complete all feilds"} />
            })
            )
        }
    }





    // onConfirm = async (event) => {
    //     event.preventDefault();
    //     const { plateNumber, reportedById, locationId, descriptionId, clampforId, clampToolId } = this.state;
    //     if (plateNumber.length > 0 && reportedById.length > 0 && locationId.length > 0 && descriptionId.length > 0) {
    //         this.setState({
    //             loading: true
    //         })

    //         const server_response = await ajax.reportWrongParking(plateNumber, reportedById, locationId, descriptionId);

    //         this.setState({
    //             loading: false
    //         })

    //         if (server_response.status === 'OK') {

    //             this.props.onConfirm(server_response.details.message);
    //             this.setState({
    //                 info: <AlertSuccess message={server_response.details.message} />,
    //                 plateNumber: ''
    //             }, () => {
    //                 const { getWrongParking } = this.context;
    //                 functions.closePopup("modal_report_wrong_parking")
    //                 getWrongParking();
    //             })
    //         } else {
    //             this.setState({
    //                 info: <AlertError message={server_response.details.message} />
    //             })
    //         }


    //     } else {
    //         this.setState({
    //             info: ''
    //         }, () => this.setState({
    //             info: <AlertError message={"Complete all feilds"} />
    //         })
    //         )
    //     }
    // }


    render() {
        const searchList = this.state.searchList;
        const optionsConductor = [];
        const optionsDescription = [];
        const optionsLocation = [];
        const optionsClampedFor = [];
        const optionsClampTools = [];
        const optionsCobra = [];




        return (

            <ConfigurationConsumer>

                {props => {

                    const { locationList, clampedForList, cobraList, unUsedClampToolList,
                        wrongParkingDescriptionList, conductorList } = props;

                    if (wrongParkingDescriptionList && wrongParkingDescriptionList !== "404") {
                        wrongParkingDescriptionList.map((item, key) =>
                            optionsDescription.push({ label: item.description, value: item.description_id })
                        )

                    }


                    if (locationList && locationList !== "404") {
                        locationList.map((item, key) =>
                            optionsLocation.push({ label: item.location_name, value: item.location_id })
                        )

                    }

                    if (clampedForList && clampedForList !== "404") {
                        clampedForList.map((item, key) =>
                            optionsClampedFor.push({ label: item.item_type_name, value: item.item_type_id })
                        )

                    }

                    if (unUsedClampToolList && unUsedClampToolList !== "404") {
                        unUsedClampToolList.map((item, key) =>
                            optionsClampTools.push({ label: item.clamp_number, value: item.clamp_tool_id })
                        )
                    }

                    if (cobraList && cobraList !== "404") {
                        cobraList.map((item, key) =>
                            optionsCobra.push({ label: item.last_name, value: item.user_id })
                        )
                    }




                    if (conductorList && conductorList !== "404") {
                        conductorList.map((item, key) =>
                            optionsConductor.push({ label: item.username, value: item.user_id })
                        )

                    }

                    return (
                        <div className="modal" id="modal_report_wrong_parking">
                            <div className="modal-dialog modal-dialog-centered" role="document">

                                <div className="modal-content modal-content-demo shadow">
                                    <div className="modal-header">
                                        <h6 className="modal-title">{this.props.title}</h6>

                                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    {this.props.wpErrorMessage && <AlertWarning message={
                                        this.props.wpErrorMessage} />}
                                    <div className="modal-body">
                                        {this.state.info}


                                        <form method="post" onSubmit={this.props.reportandclamp ? this.onConfirmReportAndClamp : this.onConfirm}>


                                            <TextInput
                                                label="Plate Number"
                                                type="text"
                                                value={this.state.plateNumber}
                                                onChange={() => this.onChangePlateNumber}
                                            />
                                            {searchList && searchList !== "404" &&
                                                <div className="btn-list">
                                                    {searchList.map((item, key) =>
                                                        <button key={key}
                                                            onClick={() => this.onClickPlateNumber(item.plate_number)}
                                                            type="button"
                                                            className="btn ripple btn-light btn-sm">
                                                            {item.plate_number}
                                                        </button>)}
                                                </div>}

                                            <SelectSearch
                                                label={this.props.title == "Report Wrong Parking" ? "Reported By" : 'Reported and clamped by'}
                                                onChange={() => this.onChangeUser}
                                                options={this.props.title == "Report Wrong Parking" ? optionsConductor : optionsCobra}
                                                placeholder={"Select User"}
                                            />

                                            <SelectSearch
                                                label="Select Location"
                                                onChange={() => this.onChangeAddress}
                                                options={optionsLocation}
                                                placeholder={"Select Location"}
                                            />

                                            {
                                                this.props.title == "Report and clamp vehicle" &&
                                                <SelectSearch
                                                    label="Clamp Reason"
                                                    onChange={() => this.onChangeClampReason}
                                                    options={optionsClampedFor}
                                                    placeholder={"Select Clamp Reason"}
                                                />


                                            }

                                            {this.state.clampforId === "2" && this.props.title === "Report and clamp vehicle" && <SelectSearch
                                                label="Description"
                                                onChange={() => this.onChangeDescription}
                                                options={optionsDescription}
                                                placeholder={"Select Description"}
                                            />}

                                            {this.props.title != "Report and clamp vehicle" && <SelectSearch
                                                label="Description"
                                                onChange={() => this.onChangeDescription}
                                                options={optionsDescription}
                                                placeholder={"Select Description"}
                                            />}



                                            {
                                                this.props.title == "Report and clamp vehicle" &&
                                                <SelectSearch
                                                    label="Clamp Tool"
                                                    onChange={() => this.onChangeClampTool}
                                                    options={optionsClampTools}
                                                    placeholder={"Select Clamp Tool"}
                                                />


                                            }

                                            <ImageUpload
                                                label="Upload Vehicle Image"
                                                id="fileToUpload"
                                                name="fileToUpload"
                                                onChange={() => this.onChangeImage}
                                            />


                                        </form>

                                    </div>
                                    <div className="modal-footer">
                                        {!this.state.loading && <>
                                            <button className="btn ripple btn-primary" type="button" onClick={this.props.reportandclamp ? this.onConfirmReportAndClamp : this.onConfirm}>Confirm Request</button>
                                            <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                                        </>}
                                        {this.state.loading && <ActivityLoader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </ConfigurationConsumer>

        )
    }
}

export default PopReportWrongParking;
