import React, { useEffect, useState } from 'react';
import ajaxPayment from '../utils/remote/ajaxPayment';
import { detectTable, restoreTable } from '../utils/script/customTables';

const MobileMoneyContext = React.createContext();

export const MobileMoneyConsumer = MobileMoneyContext.Consumer;

export const MobileMoneyProvider = (props) => {

    const [transactionListing, setTransactionListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalAmount, setTotalAmount] = useState("UGX...");
    const [providerList, setProviderList] = useState(false);
    const [providerTotalList, setProviderTotalList] = useState(false);


    useEffect(()=>{
        listProviders();
    }, [])

    useEffect(()=>{
       getMobileMoneyTransactions();
    //    getTotalAmount();
        getProvidersTotalAmount();
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getMobileMoneyTransactions=async()=>{
        setLoading(true)
        setTransactionListing(false)
        const server_response = await ajaxPayment.fetchMobileMoneyRecords(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTransactionListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setTransactionListing("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxPayment.fetchMobileMoneyRecords(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTransactionListing(transactionListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
        return getFreshObject();
    }

    const filterData=(options)=>{
        setCurrentPage(1)
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "payment_reason":options.reason,
            "page":"1",
            "plate_number":options.plateNumber,
            "status":options.status,
            "phone_number":options.phoneNumber,
            "provider":options.provider

        })
        
    }


    const getFreshObject=()=>{
        return [
            {
              "phoneNumber": ""
            },
            {
              "status": ""
            },
            {
              "dateFrom": ""
            },
            {
              "dateTo": ""
            },
            {
              "plateNumber": ""
            },
            {
              "reason": ""
            }
          ]
    }


    const getTotalAmount=async()=>{
        const server_response = await ajaxPayment.fetchTotalMobileMoney(data);
        if(server_response.status==="OK"){
            setTotalAmount("UGX. " + server_response.details.content.amount_p);
        }
    }

    const listProviders=async()=>{
        const server_response = await ajaxPayment.fetchMobileMoneyProviders();
        if(server_response.status==="OK"){
            setProviderList(server_response.details.content);
        }else{
            setProviderList("404")
        }
    }

    const getProvidersTotalAmount=async()=>{
        const server_response = await ajaxPayment.fetchMobileMoneyProviderTotals(data);
        if(server_response.status==="OK"){
            setProviderTotalList(server_response.details.content);
        }
    }
  
    return (
        <MobileMoneyContext.Provider value={{
            transactionListing,
            metaData,
            loading,
            totalAmount,
            providerList,
            providerTotalList,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getMobileMoneyTransactions,
        }}
        >
            {props.children}
        </MobileMoneyContext.Provider>
    );
}


export default MobileMoneyContext;
