import React from 'react'
import functions from '../utils/functions';
import TableHeader from './Common/TableHeader';
import GoogleMap from './GoogleMap';


class PlaceGraphHolder extends React.Component {

    state = {
        userId: functions.sessionGuard(),
    }

    render() {

        return (
                <div className="card custom-card">
                    <div className="card-body map-card">

                        <TableHeader
                            title="Online Map"
                            subtitle="The current parking locations."
                            view="View All"
                            onClick={this.props.onClickViewAll}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                            <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12 h-100">
                                <GoogleMap />
                            </div>
                        </div>
                        

                    </div>

                </div>
        )
    }
}

export default PlaceGraphHolder;
