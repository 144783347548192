import React from 'react';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import TopBar from '../../Components/Common/TopBar';
import RightSideBar from '../../Components/Common/RightSideBar';
import Footer from '../../Components/Common/Footer';
import PageHeader from '../../Components/Common/PageHeader';
import functions from '../../utils/functions';
import ajax from '../../utils/ajax';
import TextInput from '../../Components/Common/TextInput';
import { Link } from 'react-router-dom';
import ajaxUser from '../../utils/remote/ajaxUser';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import ajaxAccess from '../../utils/remote/ajaxAccess';
import WarnDelete from '../../Components/WarnDelete';
import WarnAction from '../../Components/WarnAction';
import Toast from '../../Components/Common/Toast';
import AlertError from '../../Components/Common/AlertError';
import { beautifyTable, restoreTable } from '../../utils/script/customTables';
import { RenderSecure } from '../../utils/script/RenderSecure';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import ajaxClamping from '../../utils/remote/ajaxClamping';
import AlertWarning from '../../Components/Common/AlertWarning';
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar';
import ConfigurationContext from '../../Context/ConfigurationContext';
import dictionary from '../../utils/dictionary';
import PopClampInfo from '../../Components/Clamps/PopClampInfo';
import $ from 'jquery';
import PopClampHistory from '../../Components/Clamps/PopClampHistory';


const eformat = dictionary._exportBtnFormats


// import profileHolder from '../../assets/faces/face6.jpg';


const profileStyles = {
    display: 'none'
};

const profileNewStyles = {
    display: 'block'
};

class UserProfile extends React.Component {
    static contextType = ConfigurationContext;

    state = {
        userId: functions.sessionGuard(),
        selectedUserId: this.props.userId,
        userInfo: false,
        // username: this.props.username,

        firstName: '',
        lastName: '',
        username: '',
        photo: '',
        roleName: '',
        phoneNumber: '',
        email: '',

        toast: false,
        loading: false,
        currentOption: "PROFILE",
        permissionList: false,
        mdl: false,
        tableId: "table-user-permissions",
        tabIndex: 1,
        info: false,
        userClampList: "",
        clampStatusId: "",
        createdFrom: "",
        createdTo: "",
        info2: "",
        clampPreview: ""


    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/users/all-users');
        } else {
            this.setState({
                username: this.props.location.state.username
            }, () => {
                this.getUserInfo()
            })
        }
    }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    getUserInfo = async () => {
        const server_response = await ajaxUser.getUserByUsername(this.state.username);

        if (server_response.status === "OK") {

            this.setState({
                userInfo: server_response.details.content
            })
        } else {
            this.setState({
                userInfo: false
            })
        }
    }


    switchTab = (tabIndex) => {
        this.setState({
            tabIndex
        }, () => {
            switch (this.state.tabIndex) {
                case 3:
                    this.getUserPermissions(this.state.userInfo.user_id);
                    break;
                case 4:
                    this.getUserClampHistory(this.state.filter);
                    break;
                default:
                    //
                    break;
            }
        })
    }

    TabOverView = (props) => {

        const userInfo = props.userInfo
        return (
            <div className="tab-pane active" id="tab1over">
                <div className="main-content-label tx-13 mg-b-20">
                    Personal Information
                </div>
                <div className="table-responsive ">
                    <table className="table row table-borderless">
                        <tbody className="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><strong>Full Name :</strong> {userInfo.first_name} {userInfo.last_name}</td>
                            </tr>
                            <tr>
                                <td><strong>Location :</strong> Kampala, Uganda</td>
                            </tr>
                        </tbody>
                        <tbody className="col-lg-12 col-xl-6 p-0">
                            <tr>
                                <td><strong>Email :</strong> {userInfo.email}</td>
                            </tr>
                            <tr>
                                <td><strong>Phone :</strong> +{userInfo.phone_number}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="main-content-label tx-13 mg-b-20 mt-3">
                    About
                </div>
                <p>No work information available</p>
                <p>No log data available. </p>
            </div>
        )
    }


    TabUpdate = (props) => {
        return (
            <div className="tab-pane active" id="tab2rev">
                <div className="card-body">
                    {this.state.info}
                    <form method="post" onSubmit={this.onSubmit}>

                        <TextInput
                            label="First Name"
                            type="text"
                            onChange={() => this.onChangeFirstName}
                            value={this.state.firstName}
                        />

                        <TextInput
                            label="Last Name"
                            type="text"
                            onChange={() => this.onChangeLastName}
                            value={this.state.lastName}
                        />

                        <TextInput
                            label="Username"
                            type="text"
                            onChange={() => this.onChangeUserName}
                            value={this.state.userName}
                        />

                        <TextInput
                            label="Phone Number"
                            type="number"
                            onChange={() => this.onChangePhoneNumber}
                            value={this.state.phoneNumber}
                        />

                        <TextInput
                            label="Email Address"
                            type="email"
                            onChange={() => this.onChangeEmail}
                            value={this.state.email}
                        />

                        <TextInput
                            label="Location"
                            type="text"
                            onChange={() => this.onChangeEmail}
                            value={this.state.email}
                        />

                        <TextInput
                            label="About"
                            type="textarea"
                            onChange={() => this.onChangeEmail}
                            value={this.state.email}
                        />

                        {/* <SelectSearch
                    label="Select Role"
                    onChange={() => this.onChangeRole}
                    options={optRoles}
                    placeholder={"Select Role"}
                /> */}

                        {/* <ImageUpload
                    label="Upload profile picture"
                    id="fileToUpload"
                    name="fileToUpload"
                    onChange={() => this.onChangeImage}
                /> */}

                        <div className="modal-footer">
                            <input
                                className="btn ripple btn-success"
                                type="button"
                                value="Update"
                                onClick={this.onSubmit}
                            />

                            <input
                                className="btn ripple btn-danger"
                                type="button"
                                value="Cancel"
                                onClick={this.onSubmit}
                            />
                        </div>

                    </form>
                </div>
            </div>
        )
    }


    TabPhoto = (props) => {
        return (
            <div className="tab-pane active" id="tab2pic">
                <div className="card-body">
                    <div className="tab-pane fade active show" id="avatar" role="tabpanel" aria-labelledby="avatar-tab">
                        <div className="wrapper mb-5 mt-4">
                            <span className="badge badge-warning text-white">Note : </span>
                            <p className="d-inline ml-3 text-muted">Image size is limited to not greater than 1MB .</p>
                        </div>
                        <form action="#">
                            <div className="dropify-wrapper has-preview">
                                <div className="dropify-message">
                                    <span className="file-icon"></span>
                                    <p>Drag and drop a file here or click</p>
                                    <p className="dropify-error">Ooops, something wrong appended.</p>
                                </div>
                                <div className="dropify-loader" style={profileStyles}></div>
                                <div className="dropify-errors-container">
                                    <ul></ul>
                                </div>
                                <input type="file" className="dropify" data-max-file-size="1mb" data-default-file={this.state.photo} />
                                <button type="button" className="dropify-clear">Remove</button>
                                <div className="dropify-preview" style={profileNewStyles}>
                                    <span className="dropify-render">
                                        <img src={this.state.photo} />
                                    </span>
                                    <div className="dropify-infos">
                                        <div className="dropify-infos-inner">
                                            <p className="dropify-filename">
                                                <span className="file-icon"></span>
                                                <span className="dropify-filename-inner">face6.jpg</span>
                                            </p>
                                            <p className="dropify-infos-message">Drag and drop or click to replace</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-5">
                                <button type="submit" className="btn btn-success mr-2">Update</button>
                                <button className="btn btn-outline-danger">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }


    TabAccess = (props) => {
        const permissionList = this.state.permissionList;
        return (
            <div className="tab-pane active">
                <div className="table-responsive  scroll-widget overflow-auto" style={{ height: "480px" }}>
                    <table id="table-perms" className="table table-striped border-t0 key-buttons text-nowrap w-100 datatable">
                        <thead>
                            {/* <tr><th colSpan={3}>Currently User Assigned Permissions</th></tr> */}
                            <tr>
                                <th>No.</th>
                                <th>Code</th>
                                <th>Description</th>
                                <th>Granted On</th>
                                <th>Granted By</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>

                            {Array.isArray(permissionList) && permissionList.map((item, key) =>
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{item.permission.permission}</td>
                                    <td>{item.permission.description}</td>
                                    <td>{item.created_at.short_date}&nbsp;&nbsp;<small className="tx-12 tx-gray-500">{item.created_at.time}</small></td>
                                    <td>{item.created_by.username}</td>
                                    <td>
                                        <button className='btn btn-sm btn-danger' onClick={() => this.onClickDeny(item.permission.permission, item.permission_id)}>Deny</button>
                                    </td>

                                </tr>)}
                        </tbody>
                    </table>

                    {!permissionList && <ActivityLoader />}
                    {permissionList === "404" && <AlertError type="info" message={"User has no access permissions"} />}
                </div>
            </div>
        )
    }


    getUserPermissions = async (userId) => {
        const server_response = await ajaxAccess.getUserAccess(userId);
        if (server_response.status === "OK") {
            this.setState({
                permissionList: server_response.details.content,

            }, () => {
                beautifyTable("table-perms", true, false);
            })
        } else {
            this.setState({
                permissionList: "404"
            })
        }
    }



    onClickDeny = (code, permission_id) => {
        this.setState({
            mdl: false
        }, () => {

            this.setState({
                mdl: <WarnAction
                    title="Deny Permission"
                    id="warn_deny"
                    buttonLabel="Confirm Deny Permission"
                    description={"Confirm that you want to deny the user permission code: " + code}
                    onConfirm={() => this.denyUserPermission(permission_id)}
                />
            }, () => functions.openPopup("warn_deny"))
        })

    }


    denyUserPermission = async (permission_id) => {
        this.resetToast();
        const server_response = await ajaxAccess.denyUserAccess(this.state.userInfo.user_id, permission_id);
        functions.closePopup("warn_deny")
        if (server_response.status === "OK") {
            restoreTable("table-perms")
            this.setState({
                toast: <Toast type={0} message={server_response.details.message} />
            }, () => this.getUserPermissions(this.state.userInfo.user_id))
        } else {
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }
    }


    onClickResetPassword = () => {
        this.setState({
            mdl: false
        }, () => {
            this.setState({
                mdl: <WarnDelete
                    id="modal_password_reset"
                    title="Are you sure you want to reset password for user?"
                    description="Make sure the user is not currently logged in"
                    buttonLabel="Confirm Reset"
                    onConfirm={() => this.resetPassword()} />
            }, () => functions.openPopup("modal_password_reset"))
        })
    }


    resetPassword = async () => {
        const server_response = await ajaxAccess.requestPasswordReset(this.state.username);
        functions.closePopup("modal_password_reset")
        if (server_response.status === "OK") {
            this.setState({
                info: <AlertSuccess message={server_response.details.message} />
            })
        } else {
            this.setState({
                info: <AlertError message={server_response.details.message} />
            })
        }
    }


    buttons = () => {
        return (<>
            <RenderSecure code="U-USER-PASSWORD-0">
                <button className="btn ripple btn-danger" onClick={this.onClickResetPassword}>
                    <i className="fa fa-lock"></i> Reset Password
                </button>
            </RenderSecure>
        </>
        )
    }


    filterButton = () => {
        return (

            <a href="#" className="btn ripple btn-secondary navresponsive-toggler mb-3" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
            </a>

        )
    }


    onFilter = (filter) => {
        this.setState({
            filter: "",
            userClampList: false,
            metaData: false

        }, () =>
            this.setState({
                filter: filter,
                metaData: false
            }, () => this.getUserClampHistory(filter)))

    }


    resetFilter = (filter) => {
        this.setState({
            filter: false,
            userClampList: false
        },
            () => this.getUserClampHistory(filter))
    }


    getUserClampHistory = async (filter) => {

        const userId = this.state.userInfo.user_id
        if (this.state.filter) {

            // CHECK IF THE USER IS A COBRA OR ZULU
            if (this.state.userInfo.user_role.role_id === "3") {

                var server_response = await ajaxClamping.userClampHistory(userId, "", filter.selectedValue, filter.dateFrom, filter.dateTo);
            } else if (this.state.userInfo.user_role.role_id === "4") {
                var server_response = await ajaxClamping.userClampHistory("", userId, filter.selectedValue, filter.dateFrom, filter.dateTo);
            }

        } else {

            // CHECK IF THE USER IS A COBRA OR ZULU
            if (this.state.userInfo.user_role.role_id === "3") {
                var server_response = await ajaxClamping.userClampHistory(userId);
            } else if (this.state.userInfo.user_role.role_id === "4") {
                var server_response = await ajaxClamping.userClampHistory("", userId);
            }

        }
        if (server_response.status === "OK") {
            this.setState({
                userClampList: server_response.details.content.list
            }, () => {
                // beautifyTable("table-clampHistory", true, false);
            })
        } else {
            this.setState({
                userClampList: "404",
                info2: <AlertError message={server_response.details.message} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }

    onClickRef = (record) => {
        this.setState({
            clampPreview: false
        }, () => {
            this.setState({
                clampPreview: <PopClampHistory clampInfo={record} />
            }, () => {
                $("#modal_clampHistory_info").modal('show');
            })

        })

    }

    TabClampHistory = (props) => {
        const { userClampList } = this.state;
        const clampingStatusOptions = [];

        const { clampingStatusList } = this.context;


        clampingStatusList &&
            clampingStatusList.map((item, key) =>
                clampingStatusOptions.push({ title: item.clamping_status, value: item.clamping_status_id })
            )
        return (
            <div className="tab-pane active">
                {this.state.clampPreview}
                {<this.filterButton />}

                <CustomizedFilterBar
                    selection={{
                        title: 'Clamp status',
                        options: clampingStatusOptions
                    }}

                    onReset={this.resetFilter}
                    onConfirm={this.onFilter}
                />
                <div className="table-responsive  scroll-widget overflow-auto" style={{ height: "500px" }}>
                    {<table id="table-clampHistory" className={"table  border-t0 key-buttons text-nowrap w-100 datatable"} export-format={eformat}>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Plate Number</th>
                                <th>Clamped For</th>
                                <th>Status</th>
                                <th>DATE/TIME</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userClampList && Array.isArray(userClampList) && userClampList.map((item, key) =>
                                <tr key={key}>
                                    <td> <Link
                                        to="#"
                                        onClick={() => this.onClickRef(item)}>
                                        {item.clamp_history_ref} </Link>
                                    </td>
                                    <td>{item.vehicle.vehicle_number}</td>
                                    <td>{item.clamped_for.item}</td>
                                    <td>{item.status.status === 'PENDING' && item.is_parked === "1" &&
                                        <span className="badge badge-info badge-pill">{item.status.status}</span>}
                                        {item.status.status === 'PENDING' && item.is_parked === "0" && item.system_aborted === '0' &&
                                            <span className="badge badge-danger   badge-pill">ABORTED</span>}

                                        {item.status.status === 'PENDING' && item.system_aborted === "1" &&
                                            <span className="badge badge-danger   badge-pill">LEFT PARKING</span>}
                                        {item.status.status === 'RESPONDED' &&
                                            <span className="badge badge-secondary badge-pill">{item.status.status}</span>}
                                        {item.status.status === 'CLAMPED' &&
                                            <span className="badge badge-dark badge-pill">{item.status.status}</span>}
                                        {item.status.status === 'PENDING_UNCLAMP' &&
                                            <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                        {item.status.status === 'UNCLAMP_RESPONDED' &&
                                            <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}
                                        {item.status.status === 'UNCLAMPED' &&
                                            <span className="badge badge-success badge-pill">{item.status.status}</span>}
                                    </td>
                                    <td>{item.created_at.short_date}&nbsp;&nbsp;<br /><small className="tx-12 tx-gray-500">{item.created_at.time}</small></td>
                                </tr>)}
                        </tbody>
                    </table>
                    }

                    {!userClampList && <ActivityLoader />}

                    {/* {userClampList === "404" && !this.state.filter && <AlertWarning message={"User has not carried out any  clamp transactions today. Use filters to see their previous clamp activities."} />} */}

                    {this.state.info2}
                </div>
            </div >
        )
    }



    render() {

        const userInfo = this.state.userInfo;
        const tabIndex = this.state.tabIndex;
        return (
            <div className="page">
                {this.state.mdl}
                {this.state.toast}
                <LeftSideBar />
                {this.state.mdl}
                {this.state.toast}

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title={this.state.username}
                            previousPageTitle="All users"
                            back="/users/all-users"
                            buttons={<this.buttons />}
                        />
                        {this.state.info}

                        {userInfo && userInfo !== "404" &&
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="card custom-card">
                                        <div className="card-body text-center">
                                            <div className="main-profile-overview widget-user-image text-center mt-1">
                                                <div className="main-img-user"><img alt="avatar" src={userInfo.profile_photo.file_path} /></div>
                                            </div>
                                            <div className="item-user pro-user">
                                                <h4 className="pro-user-username text-dark mt-4 mb-1">{userInfo.first_name} {userInfo.last_name}</h4>
                                                <p className="pro-user-desc text-muted mb-3">{userInfo.user_role.role_name}</p>
                                                <p className="user-info-rating">
                                                    <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                    <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                    <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                    <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                    <Link to="#"><i className="far fa-star text-warning"> </i></Link>
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="card custom-card">
                                        <div className="card-header custom-card-header">
                                            <div>
                                                <h6 className="card-title mb-0">Contact Information</h6>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="main-profile-contact-list main-profile-work-list">
                                                <div className="media">
                                                    <div className="media-logo bg-light text-dark">
                                                        <i className="fe fe-smartphone"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <span>Mobile</span>
                                                        <div>{userInfo.phone_number}</div>
                                                    </div>
                                                </div>
                                                <div className="media">
                                                    <div className="media-logo bg-light text-dark">
                                                        <i className="fe fe-mail"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <span>Username</span>
                                                        <div>{userInfo.username}</div>
                                                    </div>
                                                </div>
                                                <div className="media">
                                                    <div className="media-logo bg-light text-dark">
                                                        <i className="fe fe-map-pin"></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <span>Current Address</span>
                                                        <div>Kampala, Uganda</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="card custom-card main-content-body-profile">
                                        <nav className="nav main-nav-line">
                                            <a className={`link nav-link ${tabIndex === 1 ? "active" : ""}`} onClick={() => this.switchTab(1)}>Overview</a>
                                            {/* <a className="nav-link" data-toggle="tab" href="#tab2rev">Update</a> */}
                                            <a className={`link nav-link ${tabIndex === 2 ? "active" : ""}`} onClick={() => this.switchTab(2)}>Profile photo</a>
                                            <a className={`link nav-link ${tabIndex === 3 ? "active" : ""}`} onClick={() => this.switchTab(3)}>Permissions</a>
                                            {(this.state.userInfo.user_role.code === "COBRA" || this.state.userInfo.user_role.code === "ZULU") && <a className={`link nav-link ${tabIndex === 4 ? "active" : ""}`} onClick={() => this.switchTab(4)}>Clamp History</a>}

                                        </nav>
                                        <div className="card-body tab-content h-100">

                                            {tabIndex === 1 && <this.TabOverView userInfo={userInfo} />}
                                            {/* <this.TabUpdate/> */}
                                            {tabIndex === 2 && <this.TabPhoto />}
                                            {tabIndex === 3 && <this.TabAccess />}
                                            {(this.state.userInfo.user_role.code === "COBRA" || this.state.userInfo.user_role.code === "ZULU") && tabIndex === 4 && <this.TabClampHistory />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {!userInfo && <ActivityLoader />}


                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default UserProfile
