import React from 'react';
import { Link } from 'react-router-dom';
import dictionary from '../../utils/dictionary';
import $ from 'jquery';
import PopExport from '../Export/PopExport';





class Export extends React.Component {

  state = {
    modal: "",
    pdfUrl: this.props.pdfUrl,
    // pdfFilterUrl: this.props.pdfFilterUrl,
    excelUrl: this.props.excelUrl,
    isOccupancy: this.props.isOccupancy
    // excelFilterUrl: this.props.excelFilterUrl


  }

  onChangeOccupancySelection = (selectedOption) => {

    this.props.onChangeSelection(selectedOption)

    // this.setState({
    //     noOfRecords: "",
    //     recordlabel: ""
    // }, () => {
    //     this.setState({
    //         noOfRecords: selectedOption.value,
    //         recordlabel: selectedOption.label
    //     })
    // })

  }

  onClickExport = () => {

    // this.props.onExport()
    this.setState({
      modal: false
    }, () => {
      this.setState({
        modal: <PopExport
          closeExport={() => this.closeExport()}
          isOccupancy={this.state.isOccupancy}
          excelUrl={this.state.excelUrl}
          occupancySelectList={this.props.occupancySelectList}
          onChangeSelection={this.props.onChangeSelection}
          pdfUrl={this.state.pdfUrl}

        // pdfFilterUrl={this.state.pdfFilterUrl}
        // excelFilterUrl={this.state.excelFilterUrl}
        />
      }, () => {
        $("#modal_export").modal('show');
      })
    })
  }



  render() {
    return (

      // <a className={"btn ripple border btn-export border-primary text-primary " + this.props.extraClasses}
      //   href={dictionary.apiHost + this.props.url} id="export" target="new" name="export">
      //   <i className="far fa-arrow-alt-circle-down mr-2"></i> Export
      // </a>


      <>
        {/* <PopExport /> */}

        {this.state.modal}
        {/* <button className="btn ripple btn-success" onClick={this.onClickExport}>
                    <i className="fe fe-plus-circle mr-2"></i> export
                </button> */}



        <a className={"btn ripple border btn-export border-primary text-primary dropdown-toggle " + this.props.extraClasses} href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" id="export" target="new" name="export">
          <i className="far fa-arrow-alt-circle-down mr-2"></i>  Export
          <i className="fas fa-caret-down ml-1"></i>
        </a>
        <div className="dropdown-menu tx-13">
          <a className="dropdown-item" href={dictionary.apiHost + this.props.excelUrl} id="export" ><i className="fas fa-file-excel"></i> Export to Excel</a>


          {/* <a className="dropdown-item pointer" */}


          {/* ><i className="fas fa-file-pdf"></i> Export to pdf</a> */}


          <button className="dropdown-item pointer" onClick={this.onClickExport}><i className="fas fa-file-pdf" href="#"></i> Export to pdf</button>
        </div>




      </>
    );
  }
}

export default Export;
