import React, { useContext, useState } from 'react';
import AlertError from '../../Common/AlertError';
import ConfigurationContext from '../../../Context/ConfigurationContext';
import TextInput from '../../Common/TextInput';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';
import dictionary from '../../../utils/dictionary';
import OptionButton from '../../Controls/OptionButton';
import { Link } from 'react-router-dom';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ThemeContext from '../../../Context/ThemeContext';
import TowingContext from '../../../Context/Violation/TowingContext';


const PopReturnVehicle=(props)=> {

    
        const [info, setInfo] = useState(false);
        const [contactName, setContactName] = useState('');
        const [contact, setContact] = useState('');

        const {setDisplayMessage} = useContext(ThemeContext);


        const { getAllTransactions} = useContext(TowingContext)
       


        const onConfirm = async (event) => {
            event.preventDefault();
            if (contactName.length > 0 && contact.length>0) {
                    const data={
                        "tow_id" : props.record.id,
                        "pickup_name" : contactName,
                        "pickup_contact" : contact
                    }
                    const server_response = await ajaxViolation.returnTowedVehicle(data);
                    if(server_response.status === "OK"){
                        setDisplayMessage(functions.templateMessage("Towing:", server_response.message, "info"))
                        setInfo(<AlertSuccess message={server_response.message} />)
                        functions.closePopup("modal-collect-vehicle");
                        getAllTransactions();
                    }else{
                        setDisplayMessage(functions.templateMessage("Towing:", server_response.message, "danger"))
                        setInfo(<AlertError message={server_response.message} />)
                    }

             

            } else {
                setInfo(<AlertError message={dictionary._completeFields} />)
            }
        }



    
        return (
            <div className="modal" id="modal-collect-vehicle">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Collect Vehicle - {props.record.plate_number}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      
                                <TextInput
                                    type="text"
                                    label="Full Name"
                                    onChange={(e) => setContactName(e.target.value)}
                                    placeholder={"Enter collector's name..."}
                                    name="collectorName"
                                    value={contactName}
                                    required
                                />

                                 <TextInput
                                    type="text"
                                    label="Contact"
                                    onChange={(e) => setContact(e.target.value)}
                                    placeholder={"Enter collector's contact..."}
                                    name="collectorName"
                                    value={contact}
                                    required
                                />
                                

                            </form>

                        
                            <p className='small'><b>NOTE:</b> Resolved status means the vehicle has paid all the outstanding balance, otherwise the vehicle still has an outstanding bill.</p>

                        </div>
                        <div className="modal-footer">

                            <button className="btn ripple btn-danger" type="button" onClick={onConfirm}>Confirm Pick up</button>

                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopReturnVehicle;
