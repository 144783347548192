import React, { Component } from 'react';
// import ajax from '../../utils/ajax';
// import functions from '../../utils/functions';
// import ActivityLoader from '../Common/ActivityLoader';
// import dictionary from '../../utils/dictionary'
import { Link } from 'react-router-dom';
// import NoContent from '../Common/NoContent';
import $ from 'jquery';
// import PopClampInfo from './PopClampInfo';
import ajaxClamping from '../../../utils/remote/ajaxClamping';
import ajaxPaymentDue from '../../../utils/remote/ajaxPaymentDue';
import TableHeader from '../../../Components/Common/TableHeader';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import NoContent from '../../../Components/Common/NoContent';
import PopClampInfo from '../../../Components/Clamps/PopClampInfo';
import ajaxWrongParking from '../../../utils/remote/ajaxWrongParking';
import PopViewImage from '../../../Components/PopViewImage';
import PopSessionLocation from '../../../Components/Operations/PopSessionLocation';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
const eformat = dictionary._exportBtnFormats




class FilteredWrongParkingRecords extends Component {

    state = {
        userId: functions.sessionGuard(),
        clampRecord: false,
        selectedClampId: '',
        modal: false,
        buttonName: '',
        showlocation: '',
        clampOptions: "",
        ClampingLocation: "",
        clampedById: '',
        transactionId: "",
        ClampToolId: "",
        showclamptool: "",
        ClampingStatusId: "",
        ClampingStatusList: "",
        toast: false,
        wrongParkingList: false,

        metaData: false,

        vehicleID: this.props.vehicleID,
        plateNumber: this.props.plateNumber,
        createdFrom: this.props.createdFrom,
        createdTo: this.props.createdTo,
        description: this.props.description,
        reportedByID: this.props.reportedByID,
        unClampedByID: this.props.unClampedByID,
        filter: this.props.filter,
        clampPreview: "",
        currentPage: 1,
        itemsPerPage: this.props.itemsPerPage,
        locationPIN: false

    };



    componentDidMount() {
        if (this.state.filter) {
            this.filterWrongParkings();
        }

    }


    componentWillUnmount() {
        // clearTimeout(this.timerListClamps);
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }




    onClickRef = (record) => {
        this.setState({
            clampPreview: false
        }, () => {
            this.setState({
                clampPreview: <PopClampInfo clampInfo={record} />
            }, () => {
                $("#modal_clamp_info").modal('show');
            })

        })

    }




    filterWrongParkings = async () => {
        const { filter } = this.state

        const server_response = await ajaxWrongParking.filterWrongParking(this.state.vehicleID, this.state.plateNumber, this.state.description, this.state.reportedByID, this.state.createdFrom, this.state.createdTo, this.state.currentPage, this.state.itemsPerPage);

        if (server_response.status === 'OK') {
            this.setState({
                wrongParkingList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })
        }
        else {
            this.setState({
                wrongParkingList: "404",
                metaData: false
            })
        }

    }


    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            clampList: false
        }, () => this.filterWrongParkings())
    }


    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                clampList: false

            }, () => {
                this.filterWrongParkings();
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                clampList: false
            }, () => {
                this.filterWrongParkings();
            })
        }

    }


    onClickRecord = (selectedClampId, clampRecord) => {
        this.setState({
            selectedClampId: selectedClampId,
            clampRecord: clampRecord,
            clamp_vehicle: '',
            modal: false
        }, () => {

            this.props.onSelectRecord(selectedClampId, clampRecord)
        })
    }

    onClickViewVehiclePhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewImage image={record} />
            }, () => {
                $("#modal_view_image").modal('show');
            })
        })

    }


    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }



    render() {
        const wrongParkingList = this.state.wrongParkingList
        const loading = this.state.loading;

        console.log(wrongParkingList)

        return (


            <div>

                <div className="table-responsive">
                    {<div className="text-wrap mt-3">
                        <div className="d-flex ">
                            <div className="btn-list mr-2">
                                <button type="button" className="btn ripple btn-light btn-sm">
                                    Total records <span className="badge badge-dark ml-2 font-weight-bold">{this.state.metaData?.count}</span>
                                </button>
                                {/* {/* <button
                                    type="button"
                                    className="btn ripple btn-success btn-sm"
                                    onClick={() => getPaymentDueByStatus(0)}>
                                    Paid Dues <span className="badge badge-dark ml-2">{countPaidPaymentDues}</span>
                                </button> */}
                                <button
                                    type="button"
                                    className="btn ripple btn-light btn-sm">
                                    Total pages <span className="badge badge-dark ml-2 font-weight-bold">{this.state.metaData?.total_pages}</span>
                                </button>
                            </div>
                        </div>
                    </div>}

                    {this.state.imageView}
                    {this.state.locationPIN}



                    {this.state.clampPreview}
                    {(wrongParkingList === "404") && <NoContent />}
                    {wrongParkingList && Array.isArray(wrongParkingList) &&
                        <table id={"wrong-parking-filtered-records"} className="table text-nowrap mg-b-0 table-hover key-buttons datatable" export-format={eformat}>
                            <thead>
                                <tr>
                                    <th>WRONG PARKING REF.</th>
                                    <th>Reported On</th>
                                    <th>Vehicle</th>
                                    <th>PROOF</th>
                                    <th>Reported By</th>
                                    <th className="text-center">Clamp Status</th>
                                    <th>Location</th>
                                    <th>Description</th>
                                </tr>
                            </thead>

                            <tbody>
                                {wrongParkingList && wrongParkingList !== "404" &&
                                    wrongParkingList.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item.ref}</td>
                                            {/* <td>{item.created_at.long_date}</td> */}
                                            <td>
                                                {item.created_at.short_date}<br />
                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                            </td>

                                            <td className="tx-bold">
                                                <Link to={
                                                    {
                                                        pathname: `/vehicles/profile/${item.vehicle.vehicle_number}`,
                                                        state: { plateNumber: item.vehicle.vehicle_number }
                                                    }}>{item.vehicle.vehicle_number}
                                                </Link>
                                            </td>

                                            <td className="pointer">
                                                <div className="main-img-user">
                                                    <img alt="avatar" onClick={() => this.onClickViewVehiclePhoto(item)} src={item?.vehicle_photo?.file_path} />
                                                </div>
                                            </td>

                                            <td>
                                                <h6 className="mg-b-1">{item.reported_by.username}</h6>
                                                <small className="tx-12 tx-gray-500">{item.reported_by.user_role.role_name}</small>
                                            </td>
                                            <td className='text-center' >
                                                {item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "1" &&
                                                    <span className="badge badge-info badge-pill">PENDING  CLAMP</span>}


                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "0" && item.clamping_info?.clamp_info.system_abort === "0" &&
                                                    <span className="badge badge-danger   badge-pill">ABORTED</span>}

                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.system_abort === "1" &&
                                                    <span className="badge badge-warning   badge-pill">LEFT PARKING</span>}

                                                {item.clamping_info?.clamp_info.status.status === 'RESPONDED' &&
                                                    <span className="badge badge-secondary badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}

                                                {item.clamping_info?.clamp_info.status.status === 'CLAMPED' &&
                                                    <span className="badge badge-dark badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}
                                                {item.clamping_info?.clamp_info.status.status === 'PENDING_UNCLAMP' &&
                                                    <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                                {item.clamping_info?.clamp_info.status.status === 'UNCLAMP_RESPONDED' &&
                                                    <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}

                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_partialy === "0" && <span className="badge badge-success badge-pill ">UNCLAMPED</span>}

                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_partialy === "1" && <span className="badge badge-success badge-pill partial-pay-color">PAID PARTIALY BEFORE CLAMP</span>}

                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "1" && <span className="badge badge-success badge-pill unclamp-tow-color">UNCLAMPED & TOWED</span>}


                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "1" && <span className="badge badge-success badge-pill clamp-color ">CLEARED BEFORE CLAMP</span>}
                                            </td>


                                            {/* <td>{item.location.location_name}</td> */}

                                            <td>
                                                <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                    <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                    {item.location.location_name}<br />
                                                    <small className="tx-12 tx-gray-500">{item.location.street.street_name}</small>
                                                </span>
                                            </td>

                                            <td>{item.description.description}</td>
                                        </tr>
                                    )}

                            </tbody>

                        </table>
                    }

                    {/* {defaultersList && Array.isArray(defaultersList) && <LoadMoreButton action={props.fetchMore} loading={props.loading} />} */}


                    {this.state.metaData && <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <li className={`page-item ${this.state.currentPage === 1 && "disabled"}`}>
                                <a
                                    className="page-link"
                                    href="#" tabIndex="-1"
                                    aria-disabled={this.state.currentPage === 1 ? false : true}
                                    onClick={this.onClickPrevious}
                                >Previous</a>
                            </li>

                            {this.state.metaData.list_of_pages.map((item, key) =>
                                <li className={`page-item ${this.state.currentPage === item && "active"}`} key={key}>
                                    <a
                                        className="page-link"
                                        href="#"
                                        onClick={() => this.onClickPage(item)}>{item}</a>
                                </li>)}

                            <li className={`page-item ${this.state.currentPage === this.state.metaData.total_pages && "disabled"}`}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={this.onClickNext}>Next</a>
                            </li>
                        </ul>
                    </nav>}

                    {!wrongParkingList && <ActivityLoader />}
                    {loading && <ActivityLoader />}
                </div>
            </div>
        )
    }
}

export default FilteredWrongParkingRecords;
