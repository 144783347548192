import React from 'react';
import LeftSideBar from '../../../Components/Common/LeftSideBar';
import Footer from '../../../Components/Common/Footer';
import TopBar from '../../../Components/Common/TopBar';
import PageHeader from '../../../Components/Common/PageHeader';
import functions from '../../../utils/functions';
import RightSideBar from '../../../Components/Common/RightSideBar';
import ConfigMenu from '../../../Components/ConfigMenu';
import ajax from '../../../utils/ajax';
// import ActivityLoader from '../../../Components/ActivityLoader'
import TextInput from '../../../Components/Common/TextInput';
import dictionary from '../../../utils/dictionary';
import AlertSuccess from '../../../Components/Common/AlertSuccess';
import AlertError from '../../../Components/Common/AlertError';
import SelectSearch from '../../../Components/Common/SelectSearch';
import ImageUpload from '../../../Components/Common/ImageUpload';
import ActivityLoader from '../../../Components/Common/ActivityLoader';

class UpdateUser extends React.Component {

  _isMounted = false;

  state = {
    userId: functions.sessionGuard(),
    roleId: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    allRoles: false,
    userRecord: false,
    roleName: '',
    fileName: '',
    profilePhoto: '',
    editedUserId: '',
    // info: '',
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchRoles();
    if (!this.props.location.state) {
      this.props.history.push('/users');
    } else {
      this.setState({
        userRecord: this.props.location.state.record
      }, () => {

        this.setState({
          roleId: this.state.userRecord.user_role.role_id,
          firstName: this.state.userRecord.first_name,
          lastName: this.state.userRecord.last_name,
          userName: this.state.userRecord.username,
          email: this.state.userRecord.email,
          phoneNumber: this.state.userRecord.phone_number,
          roleName: this.state.userRecord.user_role.role_name,
          allRoles: false,
          editedUserId: this.state.userRecord.user_id
          // user_Id: userRecord.user_id
        })


      })




    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  // onChangeRoleName=(event)=>{
  //     this.setState({roleName:event.target.value})
  // }

  onChangeFirstName = (event) => {
    this.setState({ firstName: event.target.value })
  }

  onChangeLastName = (event) => {
    this.setState({ lastName: event.target.value })
  }

  onChangeUserName = (event) => {
    this.setState({ userName: event.target.value })
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value })
  }

  onChangePhoneNumber = (event) => {
    this.setState({ phoneNumber: event.target.value })
  }

  onChangeImage = (event) => {

    this.setState({
      fileName: event.target.value
    })
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      this.setState({
        profilePhoto: e.target.result
      })
    }
  }

  onChangeRole = (selectedRole) => {
    this.setState({
      roleId: selectedRole.value
    })
  }


  fetchRoles = async () => {
    const server_response = await ajax.getRoles(this.state.userId);

    if (server_response.status === "OK") {
      this.setState({
        allRoles: server_response.details.content.list
      })
    } else {
      this.setState({
        allRoles: "404"
      })
    }
  }


  onSave = async (event) => {
    event.preventDefault();
    
    this.setState({
      info: <ActivityLoader />
    })

    const {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      profilePhoto,
      roleId,
      editedUserId
    } = this.state;

    if (
      // roleName.length>0 && roleId.length>0
      firstName.length > 0 ||
      lastName.length > 0 ||
      userName.length > 0 ||
      // email.length > 0 ||
      phoneNumber.length > 0 ||
      roleId.length > 0 ||
      editedUserId.length > 0
    ) {
      //fetch login from the API
      const server_response = await ajax.updateUser(
        editedUserId,
        firstName,
        lastName,
        userName,
        email,
        phoneNumber,
        profilePhoto,
        roleId,
        this.state.userId
      );
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._requireRoleName} />,
        loading: ''
      })
    }


  }

  render() {

    const allRoles = this.state.allRoles;
    const optRoles = [];

    if (allRoles && allRoles !== "404") {
      allRoles.map((item, key) =>
        optRoles.push({
          label: item.role_name,
          value: item.role_id
        }))
    }

    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Edit and Update a user"
              previousPageTitle="All Users"
              back="/users/all-users"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>
                          <TextInput
                            label="First Name"
                            type="text"
                            onChange={() => this.onChangeFirstName}
                            value={this.state.firstName}
                          />

                          <TextInput
                            label="Last Name"
                            type="text"
                            onChange={() => this.onChangeLastName}
                            value={this.state.lastName}
                          />

                          <TextInput
                            label="Username"
                            type="text"
                            onChange={() => this.onChangeUserName}
                            value={this.state.userName}
                          />

                          <TextInput
                            label="Phone Number"
                            type="text"
                            onChange={() => this.onChangePhoneNumber}
                            value={this.state.phoneNumber}
                          />

                          <TextInput
                            label="Email Address"
                            type="text"
                            onChange={() => this.onChangeEmail}
                            value={this.state.email}
                          />

                          <SelectSearch
                            label="Select Role"
                            onChange={() => this.onChangeRole}
                            options={optRoles}
                            placeholder={this.state.roleName}
                          // value={this.state.roleName}
                          />

                          <ImageUpload
                            label="Upload new profile picture"
                            id="fileToUpload"
                            name="fileToUpload"
                            onChange={() => this.onChangeImage}
                            value={this.state.profilePhoto.fileName}
                          />

                          <div className="modal-footer">
                            <input className="btn ripple btn-success" type="button" value="Save Changes" onClick={this.onSave} />
                          </div>
                        </form>




                      </div>
                    </div>


                  </div>

                </div>

              </div>

              <ConfigMenu />

            </div>

          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default UpdateUser
