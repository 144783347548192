import React from 'react';


class AlertWarning extends React.Component {
  

  render(){
    
      return (
        <div className="alert alert-warning mg-b-10" role="alert">
        {/* 
          <button aria-label="Close" className="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
        */}
      
            <strong>Warning! </strong>{this.props.message}
        </div>

      );
    
 
  }
}

export default AlertWarning;