import React from 'react'
import Chart from "react-apexcharts";

import TableHeader from '../Common/TableHeader';
// import BarChart from './BarChart';
// import ApexChart from './ApexChart'
// import ApexLine from './ApexLine';
import functions from '../../utils/functions';
import ajax from '../../utils/ajax';
import ActivityLoader from '../Common/ActivityLoader';


class OperationsGraph extends React.Component {

  state = {

    userId: functions.sessionGuard(),
    operationsData: false,
    optionsMixedChart: false,
    seriesMixedChart: false,
  };

  componentDidMount() {
    this.getAnuualOperationsCount();
  }

  setOptionsMixedChart = (data) => {

    const optionsMixedChart = {

      chart: {
        // height: 139,
        // type: 'line',
        stacked: false,
        toolbar: {
          show: false
        }
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          endingShape: 'rounded'
          // distributed: true,
        }
      },

      dataLabels: {
        enabled: false
      },

      colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],

      stroke: {
        show: true,
        width: [0, 2, 4],
        curve: 'smooth',
        // colors: ['transparent']
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },

      // xaxis: {
      //   type: 'datetime'
      // },

      // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],

      markers: {
        size: 0
      },

      // labels: data,

      xaxis: {
        categories: data,
      },

      yaxis: {
        title: {
          // text: 'Points',
          display: false,
        },
        min: 0,
        tickAmount: 7,
        // max: 200,
      },

      legend: {
        display: true,
        position: 'top'
      },

      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " cars";
            }
            return y;

          }
        }
      },

      grid: {
        borderColor: '#f1f1f1'
      },

    }

    this.setState({ optionsMixedChart: optionsMixedChart })

  }


  setSeriesMixedChart = (data_series) => {
    const seriesMixedChart = [];
    const newlist = []
    newlist.push(data_series)

    Object.keys(data_series).map((item, key) => {
      var series = data_series[item]
      var series_data = []
      for (var i = 1; i < 13; i++) {
        series_data.push(series.data[i].total)
      }

      seriesMixedChart.push(
        {
          name: series.title,
          type: series.graph_type,
          data: series_data
          // data={ this.state.chartData }
        }
      )


    })

    // newlist.map((item, key) => {
    //   const count_data = [];

    //   for (const key in item) {
    //     for (const newkey in item[key].data) {
    //       count_data.push(item[key].data[newkey].total);
    //     }

    //     // console.log(key)
    //     // // for (var i = 1; i < 13; i++) {
    //     // // list =[1,2,3,4,5,6,7,8,9,0]
    //     //   console.log(item[key])

    //     // count_data.push(item[key].data[1].total);

    //     // // }

    //     seriesMixedChart.push(
    //       {
    //         name: item[key].title,
    //         type: item[key].graph_type,
    //         data: count_data
    //         // data={ this.state.chartData }
    //       }
    //     )
    //   }
    //   // console.log(item["session_count"])
    //   // for (var i = 1; i < 13; i++) {
    //   //   count_data.push(item[key].data[i].total);
    //   // }
    //   // console.log(count_data);


    // })

    this.setState({ seriesMixedChart: seriesMixedChart })

  }


  // type="line"
  getAnuualOperationsCount = async () => {
    const server_response = await ajax.analyseAnnualSessionCollection();

    if (server_response.status === "OK") {
      this.setState({
        operationsData: server_response.details.content
      }, () => {

        const months = [];
        for (var i = 1; i < 13; i++) {
          months.push(this.state.operationsData.months[i]);
        }

        this.setOptionsMixedChart(months)
        this.setSeriesMixedChart(this.state.operationsData.counts)
      })
    } else {
      this.setState({
        operationsData: '404'
      })
    }
  }

  render() {

    return (
      //   <div className="col-sm-12 col-md-12 col-xl-7 col-lg-7">
      <div className="card custom-card">
        <div className="card-body map-card">

          <TableHeader
            title="Stacked Bar Chart of Operations"
            subtitle="Below is the basic Stacked Bar chart of sessions and contranventions."
          />

          <div className="mt-3 border-top">
            <div className="mt-2">
              {this.state.optionsMixedChart && this.state.seriesMixedChart &&
                <Chart
                  options={this.state.optionsMixedChart}
                  series={this.state.seriesMixedChart}

                  // options={this.state.options}
                  // series={this.state.series}

                  height={360}
                />
              }

              {!this.state.optionsMixedChart && <ActivityLoader />}
            </div>
          </div>
        </div>
      </div>
      //   </div>
    )
  }
}

export default OperationsGraph;
