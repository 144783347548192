import React, { useEffect, useState } from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';


const VehicleInvoices =(props)=> {

  const [balance, setBalance] = useState("...")
  const [plateNumber, setPlateNumber] =useState(props.plateNumber)
  const [refreshCounter, setRefreshCounter] = useState(0);


  useEffect(()=>{
    getBalance();
  }, [refreshCounter])

  
  useEffect(()=>{

    setTimeout(() => {
        setRefreshCounter(refreshCounter+1)
    }, dictionary._waitTime);

}, [refreshCounter])

  const getBalance = async () => {
    const server_response = await ajax.getVehicleOutstandingBalance(plateNumber)
    if (server_response.status === "OK") {

        setBalance("UGX. " + server_response.details.content.amount_p)
     
    }
  }


    return (
      <button
        type="button"
        className="btn ripple btn-dark btn-sm"
        onClick={props.onClick}>
        Outstanding Invoices
        <span className="badge badge-danger rounded ml-2">{balance}</span>
      </button>

    );


  }

export default VehicleInvoices;
