import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';

class PopEditClient extends React.Component {

    state = {
        vehicleInfo: this.props.vehicleInfo,
        plateNumber: '',
        info: '',
        phoneNumber: '',
        email: '',
        fullName: '',
        loading: false,
        firstName:'',
        lastName:'',
        loading:false
    }

    componentDidMount() {
        this.setState({
            plateNumber: this.state.vehicleInfo.plate_number,
            phoneNumber: this.state.vehicleInfo.client.phone_number,
            fullName: this.state.vehicleInfo.client.full_name,
            email: this.state.vehicleInfo.client.email,
            firstName : this.state.vehicleInfo.client.first_name,
            lastName : this.state.vehicleInfo.client.last_name,

        })
    }

    onChangeName = (event) => {
        this.setState({ fullName: event.target.value })
    }

    onChangeFirstName = (event) => {
        this.setState({ firstName: event.target.value })
    }

    onChangeLastName = (event) => {
        this.setState({ lastName: event.target.value })
    }


    onChangePhone = (event) => {
        this.setState({ phoneNumber: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onConfirm = async (event) => {
        event.preventDefault();
        if (this.state.firstName.length > 0 && this.state.phoneNumber.length >= 10) {

            this.setState({ loading: true })
            const server_response = await ajax.updateClientInfo(
                this.state.vehicleInfo.client.client_id,
                this.state.firstName,
                this.state.lastName,
                this.state.phoneNumber,
                this.state.email
            );
            this.setState({ loading: false })
            if (server_response.status === "OK") {

                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                }, () => {
                    functions.closePopup('modal_edit_client');
                    this.props.refresh();
                })
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Enter first Name and correct phone number"} />
            })
        }
    }





    render() {
        return (
            <div className="modal" id="modal_edit_client">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Edit Client - {this.state.plateNumber}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="First Name"
                                    type="text"
                                    required
                                    onChange={() => this.onChangeFirstName}
                                    value={this.state.firstName}
                                />

                            <TextInput
                                    label="Last Name"
                                    type="text"
                                    onChange={() => this.onChangeLastName}
                                    value={this.state.lastName}
                                />

                                <TextInput
                                    label="Phone Number"
                                    type="text"
                                    required
                                    onChange={() => this.onChangePhone}
                                    value={this.state.phoneNumber}
                                />

                                <TextInput
                                    label="Email Address"
                                    type="text"
                                    onChange={() => this.onChangeEmail}
                                    value={this.state.email}
                                />

                            </form>


                        </div>
                        <div className="modal-footer">
                            {this.state.loading && <ActivityLoader />}
                            {!this.state.loading &&
                                <>
                                    <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Save Changes</button>
                                    <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopEditClient;
