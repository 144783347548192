import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from '../../Context/ThemeContext';


const TableTools = () => {

    const { tableDetected, refreshDatatable } = useContext(ThemeContext);

    const onClickTableTools = () => {
        refreshDatatable()
    }

    return (
        <div className="dropdown d-md-flex ">
            {tableDetected && <Link to="#" className="nav-link icon header-search" onClick={onClickTableTools}>
                <i className="fas fa-file-excel tx-light"></i>
            </Link>}

        </div>

    );



}

export default TableTools;