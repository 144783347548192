import React from 'react';
import ajax from '../../utils/ajax';
import ajaxConfig from '../../utils/remote/ajaxConfig';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from './../Common/AlertError';

class PopConfigureSticker extends React.Component {

    state = {
        typeName: '',
        info: '',
        vehicleTypeId:'',
        VehicleTypeList:false,
        stickerInfo: this.props.item,
        selectedData:[],
        loading:false,
        currentConfig:false
    }

    componentDidMount() {

        this.getVehicleTypes();
        this.getCurrentConfigs();
    }
    

    getVehicleTypes = async () => {
        const server_response = await ajax.getVehicleTypes();
        if (server_response.status === "OK") {
            
                this.setState({
                    VehicleTypeList: server_response.details.content
                })

        } else {
            this.setState({
                VehicleTypeList: "404"
            })
        }
    }

    getCurrentConfigs = async () => {
        const server_response = await ajaxConfig.getStickerVehicleTypes(this.state.stickerInfo.sticker_type_id);
        if (server_response.status === "OK") {
            
                this.setState({
                    currentConfig: server_response.details.content
                }, ()=>{
                    const selectedData = [];
                    if(Array.isArray(this.state.currentConfig))
                    {
                        this.state.currentConfig.map((item, key)=>{
                            selectedData.push(item.type_id)
                        })
                        this.setState({selectedData})
                    }
                })

        } else {
            this.setState({
                currentConfig: "404"
            })
        }
    }

    onChangeVehicleType = (selectedOption) => {
        this.setState({
          vehicleTypeId: selectedOption.value
        })
      }
    

      onClickToggle = (value) => {
            const {selectedData} = this.state;
            if(!selectedData.includes(value))
            {
                selectedData.push(value)
            }else{
                selectedData.splice(selectedData.indexOf(value), 1);
            }

            this.setState({selectedData})

        }

        onConfirm =async(event)=>{
            event.preventDefault();
            const {selectedData} = this.state;
            if(selectedData.length>0){
                this.setState({
                    loading:true
                })

                this.props.onConfirm(this.state.stickerInfo.sticker_type_id, selectedData)
            }else{
                this.setState({
                    info: <AlertError message={"Select atleast one vehicle type"} />
                })
            }
        }


    render() {
        const VehicleTypeList = this.state.VehicleTypeList;
        return (
            <div className="modal" id="modal_configure_sticker_type">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title"><i className="fa fa-cog"/> &nbsp;Sticker Configuration</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                          {VehicleTypeList &&  <form method="post" onSubmit={this.onConfirm}>

                             
            

                    <div className="row">
						<div className="col-lg-12 col-md-12">
							<div className="row">
								<div className="card-body">
									<div>
										<h6 className="card-title mb-1">{this.state.stickerInfo.ref_name} Sticker</h6>
										<p className="text-muted card-sub-title">Select vehicle types for which this sticker type can apply</p>
									</div>
								


                                    {this.state.currentConfig && Array.isArray(VehicleTypeList) && VehicleTypeList.map((item, key) =>
                                                                <div
                                                                    className="switch-toggle d-flex"
                                                                    key={key}
                                                                    >
                                                                    <span className="mr-auto">{item.type_name}</span>
                                                                    <div className="onoffswitch2" onClick={()=>this.onClickToggle(item.type_id)}>
                                                                        <input
                                                                            type="radio"
                                                                            className="onoffswitch2-checkbox"
                                                                            checked={this.state.selectedData.includes(item.type_id)? true : false}
                                                                            value={item.type_id}
                                                                            onChange={()=>{}}
                                                                        />
                                                                        <label htmlFor="myonoffswitch" className="onoffswitch2-label"></label>
                                                                    </div>
                                                                </div>)}


                                            {!this.state.currentConfig && <>
                                                <AlertError type="info" message="Retrieving current configurations"/>
                                                <ActivityLoader/>
                                            </>}


								</div>
							</div>
						</div>
					</div>
                            



                                
                            </form>}

                            {!VehicleTypeList && <ActivityLoader/>}



                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && this.state.currentConfig && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Save Configurations</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopConfigureSticker;
