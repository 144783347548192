import React, { useContext, useEffect, useState } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import RecentNotes from '../../Components/Finance/DemandNote/RecentNotes'
import PendingNote from '../../Components/Cards/DemandNote/PendingNote'
import PaidNote from '../../Components/Cards/DemandNote/PaidNote'
import NoteView from '../../Components/Finance/DemandNote/NoteView'
import useStateCallback from '../../utils/customHooks/useStateCallback'
import PopNewDemandNote from '../../Components/Finance/DemandNote/PopNewDemandNote'
import DemandNoteContext from '../../Context/Finance/DemandNoteContext'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import WarnDelete from '../../Components/WarnDelete'
import ThemeContext from '../../Context/ThemeContext'
import ajaxDemandNote from '../../utils/remote/ajaxDemandNote'

const DemandNote = () => {

  const [modal, setModal] = useStateCallback(false)
  const {refresh, batchId, allTransactions} = useContext(DemandNoteContext);
  const {setDisplayMessage} = useContext(ThemeContext)

  useEffect(()=>{
      if(!allTransactions){
        refresh()
      }
  }, [])
   
  const handleNew=()=>{
     
    setModal(<PopNewDemandNote/>, ()=>{
      functions.openPopup('pop-new-demand-note')
    })
  }

  const ConfirmEfris = async()=>{

    const server_response = await ajaxDemandNote.applyEfris({batch_id: batchId});
    functions.closePopup('modal-add-efris')
    if(server_response.status === 'OK'){
      setDisplayMessage(functions.templateMessage("EFRIS: ", server_response.message, "success"))
    }else{
      setDisplayMessage(functions.templateMessage("EFRIS: ", server_response.message, "danger"))
    }
   
  }

  const ConfirmClear = async()=>{

    const server_response = await ajaxDemandNote.postClearBatch(batchId);
    functions.closePopup('modal-clear-batch')
    if(server_response.status === 'OK'){
      refresh();
      setDisplayMessage(functions.templateMessage("Demand Note: ", server_response.message, "success"))
    }else{
      setDisplayMessage(functions.templateMessage("DemandNote: ", server_response.message, "danger"))
    }
   
  }

  const ConfirmCancel = async()=>{

    const server_response = await ajaxDemandNote.postCancelBatch(batchId);
    functions.closePopup('modal-cancel-batch')
    if(server_response.status === 'OK'){
      refresh();
      setDisplayMessage(functions.templateMessage("Demand Note: ", server_response.message, "success"))
    }else{
      setDisplayMessage(functions.templateMessage("DemandNote: ", server_response.message, "danger"))
    }
  
  }

  const handleAddEfris = () =>{

    setModal(false, ()=>{
      setModal( <WarnDelete
        title="Apply EFRIS?"
        description="Corresponding payments made by these vehicles will not generate tax receipts in order to avoid double taxation."
        buttonLabel="Apply EFRIS"
        icon="fas fa-question"
        onConfirm={ConfirmEfris} 
        id="modal-add-efris"
        />, ()=>{
        functions.openPopup('modal-add-efris')
      })
    })

  }


  const handleClearBatch = () =>{

    setModal(false, ()=>{
      setModal( <WarnDelete
        title="Settle Batch"
        description="Please ensure that all vehicle invoices withi this batch have been cleared to 0."
        buttonLabel="Confirm Settlement"
        icon="fas fa-question"
        onConfirm={ConfirmClear} 
        id="modal-clear-batch"
        />, ()=>{
        functions.openPopup('modal-clear-batch')
      })
    })

  }

  const handleCancelBatch = () =>{

    setModal(false, ()=>{
      setModal( <WarnDelete
        title="Cancel Batch"
        description="You are about to cancel this batch, please confirm to proceed."
        buttonLabel="Confirm"
        icon="fas fa-question"
        onConfirm={ConfirmCancel} 
        id="modal-cancel-batch"
        />, ()=>{
        functions.openPopup('modal-cancel-batch')
      })
    })

  }

  const  buttons = () => {

    return (
      <>
          <button className="btn ripple btn-success" onClick={handleNew}>
            <i className="fa fa-edit"></i> Create New
          </button>
       
          <button className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
          </button>

          <div className="dropdown-menu tx-13">
              <Link to="#" className="dropdown-item" onClick={refresh}>
                <i className="fa fa-redo text-primary mr-1"></i>Refresh</Link>
                
              {batchId && <>
              {/* <Link to="#" className="dropdown-item" onClick="" title="Add more vehicles to this invoice">
                <i className="fa fa-plus-circle text-success mr-1"></i>Add vehicle</Link> */}
              
              <Link to="#" className="dropdown-item" onClick={handleClearBatch} title="Mark invoices as settled to 0">
                <i className="fa fa-check-circle text-success mr-1"></i>Settle Batch</Link>

                <Link to="#" className="dropdown-item" onClick={handleCancelBatch} title="Mark batch as invalid.">
                <i className="fa fa-times-circle text-danger mr-1"></i>Cancel Batch</Link>

              <Link to="#" className="dropdown-item" onClick={handleAddEfris} title="Apply EFRIS">
                <i className="fa fa-gavel text-primary mr-1"></i>Apply EFRIS</Link>

               <Link 
                  to="#" className="dropdown-item" 
                  onClick={()=>functions.printDoc('receipt_doc')} 
                  title="Print Invoice" 
                  >
                <i className="fa fa-print text-dark mr-1"></i>Print Invoice</Link>
                </>}
          </div>
      </>
    )
  }

          return (
            <div className="page">
              {modal}
              <LeftSideBar />
              <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                <TopBar />
                <div className="container-fluid">

                  <PageHeader
                    title="Demand Note"
                    previousPageTitle="Dashboard"
                    back="/finance"
                    buttons={buttons()}
                  />

               
                 
                  <div className="row">
                    <div className="col-sm-12 col-xl-6 col-lg-6">
                        <div className="row">
                            <PendingNote />
                            <PaidNote />
                        </div>
                        <RecentNotes />
                    </div>
                    <div className="col-sm-12 col-xl-6 col-lg-6">
                        <NoteView />
                    </div>

                  </div>

                </div>
              </div>
              <RightSideBar />

            </div>


          );


}

export default DemandNote
