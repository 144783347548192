import React from 'react';
import ajax from '../../../utils/ajax';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import AlertSuccess from '../../Common/AlertSuccess';
import TextInput from '../../Common/TextInput';

class PopNewListing extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        title: '',
        description:'',
        info: '',
        loading: false
    }

    onChangeTitle = (event) => {
        this.setState({ title: event.target.value })
    }

    onChangeDescription = (event) => {
        this.setState({ description: event.target.value })
    }


    onConfirm = async (event) => {
        event.preventDefault();
        const {title, description}=this.state;
        if (title.length > 0 && description.length>0) {
            this.setState({
                loading: true
            })

            const server_response=await ajax.createVehicleListing(title, description);
            this.setState({
                loading: false
            })
            if(server_response.status=="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />,
                    title:'',
                    description:''
                }, ()=>this.props.refresh())
            }else{
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }




   
    render() {

        return (
            <div className="modal" id="modal_new_vehicle_listing">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Vehicle Listing</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              

                                <TextInput
                                    label="Title"
                                    type="text"
                                    onChange={() => this.onChangeTitle}
                                    value={this.state.title}
                                />

                                 <TextInput
                                    label="Description"
                                    type="textarea"
                                    onChange={() => this.onChangeDescription}
                                    value={this.state.description}
                                />



                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Create List</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopNewListing;
