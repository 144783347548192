import React from 'react';

class PopClampHistory extends React.Component {

    state = {
        clampInfo: this.props.clampInfo
    }

    render() {
        const { clampInfo } = this.state
        return (
            <div className="modal" id="modal_clampHistory_info">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Clamp Info</h6>

                            <div className="">
                                <div className="">
                                    <div>
                                        {clampInfo.status.status === 'PENDING' && clampInfo.is_parked === "1" &&
                                            <span className="badge badge-info badge-pill">{clampInfo.status.status}</span>}


                                        {clampInfo.status.status === 'PENDING' && clampInfo.is_parked === "0" &&
                                            <span className="badge badge-danger   badge-pill">ABORTED</span>}


                                        {/* {clampInfo.status.status === 'PENDING' && clampInfo.system_abort === "1" &&
                                            <span className="badge badge-warning   badge-pill">LEFT PARKING</span>} */}

                                        {clampInfo.status.status == 'RESPONDED' &&
                                            <span className="badge badge-secondary badge-pill">{clampInfo.status.status}</span>}
                                        {clampInfo.status.status == 'CLAMPED' &&
                                            <span className="badge badge-dark badge-pill">{clampInfo.status.status}</span>}

                                        {clampInfo.status.status == 'PENDING_UNCLAMP' &&
                                            <span className="badge badge-primary badge-pill">{clampInfo.status.status}</span>}

                                        {clampInfo.status.status == 'UNCLAMP_RESPONDED' &&
                                            <span className="badge badge-light badge-pill">{clampInfo.status.status}</span>}


                                        {clampInfo.status.status == 'UNCLAMPED' &&
                                            <span className="badge badge-success badge-pill">{clampInfo.status.status}</span>}
                                    </div>
                                </div>
                            </div>



                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">

                            {clampInfo && <div className="main-contact-info-body">
                                <div className="media-list pb-lg-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Clamp History REF</label>
                                                <span className="tx-medium">{clampInfo.clamp_history_ref}</span>
                                            </div>

                                            <div>
                                                <label>Clamp Transaction ID</label>
                                                <span className="tx-small ">{clampInfo.clamp_transaction_id}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Vehicle Number</label>
                                                <span className="tx-medium">{clampInfo.vehicle.plate_number}</span>
                                            </div>

                                            <div>
                                                <label>Clamped For</label>
                                                <span className="tx-medium">{clampInfo.clamped_for.item}</span>
                                            </div>

                                        </div>
                                        {/* <div>
                                                <label>Updated on</label>
                                                <span className="tx-medium">{clampInfo.updated_at.long_date}</span>
                                            </div> */}

                                    </div>
                                </div>


                                <div className="media">
                                    <div className="media-body">
                                        <div>
                                            <label>ClampTool Number</label>
                                            <span className="tx-medium">{clampInfo?.clamp_tool ? clampInfo?.clamp_tool.clamp_number : "----"}</span>
                                        </div>

                                        <div>
                                            <label>DATE/TIME</label>
                                            <span className="tx-medium">{clampInfo.created_at.long_date}</span>
                                        </div>

                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-body">
                                        <div>
                                            <label>Initial Location</label>
                                            <span className="tx-medium">{clampInfo.initial_location ? clampInfo?.initial_location.location_name : "----"}</span>
                                        </div>

                                        <div>
                                            <label>Clamped Location</label>
                                            <span className="tx-medium">{clampInfo.clamped_location ? clampInfo.clamped_location.location_name : "----"}</span>
                                        </div>
                                    </div>
                                </div>



                                <div className="media">
                                    <div className="media-body">
                                        <div>
                                            <label>Responded Cobra</label>
                                            <span className="tx-medium">{clampInfo.clamped_by ? clampInfo?.clamped_by.first_name : "----"} {clampInfo?.clamped_by.last_name}</span>
                                        </div>

                                        <div>
                                            <label>Responded Zulu</label>
                                            <span className="tx-medium">{clampInfo.unclamped_by ? clampInfo?.unclamped_by?.first_name : "-----"} {clampInfo?.unclamped_by?.last_name}</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="media">
                                    <div className="media-body">
                                        <div>
                                            <label>Clamped By</label>
                                            <span className="tx-medium">{clampInfo.clamped_by ? clampInfo?.clamped_by.first_name : "----"} {clampInfo?.clamped_by.last_name}</span>
                                        </div>

                                        <div>
                                            <label>UnClamped By</label>
                                            <span className="tx-medium">{clampInfo.unclamped_by ? clampInfo?.unclamped_by?.first_name : "-----"} {clampInfo?.unclamped_by?.last_name}</span>
                                        </div>
                                    </div>
                                </div>




                            </div>

                            }


                        </div>
                    </div>


                </div>
            </div >
        )


    }
}

export default PopClampHistory;
