import React from 'react';


class NotificationAvatar extends React.Component {
  

  render(){
    
      return (
        <div className="avatar avatar-xxs d-none d-sm-flex bg-secondary">
            {this.props.value}
		</div>
      );
    
 
  }
}

export default NotificationAvatar;