import React, { useContext, useEffect, useState } from 'react';
import AlertError from '../../Common/AlertError';
import ConfigurationContext from '../../../Context/ConfigurationContext';
import TextInput from '../../Common/TextInput';
import dictionary from '../../../utils/dictionary';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';


const PopRespondUnClamp=(props)=> {

        const vehicleId = props.record.vehicle.vehicle_id;

        const [info, setInfo] = useState(false);
        const [zuluId, setZuluId] = useState('');

        const {zuluList} = useContext(ConfigurationContext);
        const { getAllTransactions } = useContext(ParkingViolationContext)


    
        const onConfirm = async (event) => {
            event.preventDefault();
            if (vehicleId.length > 0 && zuluId.length>0) {

                const server_response = await ajaxViolation.respondToUnClamp(vehicleId, zuluId);
                if(server_response.status === "OK"){
                    setInfo(<AlertSuccess message={server_response.message} />)
                    functions.closePopup("modal_respond_unclamp");
                    getAllTransactions();
                }else{
                    setInfo(<AlertError message={server_response.message} />)
                }

            } else {
                setInfo(<AlertError message={dictionary._completeFields} />)
            }
        }



    const optionZuluList = [];
    if(Array.isArray(zuluList)){
        zuluList.forEach(element=>{
            optionZuluList.push({label:element.full_name, value:element.user_id})
        })
    }

  
        return (
            <div className="modal" id="modal_respond_unclamp">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Respond to unclamp</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      

                                <TextInput
                                    type="select"
                                    label="Field Zulu"
                                    onChange={(option) => setZuluId(option.value)}
                                    options={optionZuluList}
                                    placeholder={"Select zulu..."}
                                    name="Zulu"
                                />



                            </form>

                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-success" type="button" onClick={onConfirm}>Respond</button>
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopRespondUnClamp;
