import React, { Fragment, useContext, useEffect, useState } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import { Link } from 'react-router-dom';
import TableHeader from '../../Components/Common/TableHeader'
import MobileMoneyContext from '../../Context/MobileMoneyContext'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import dictionary from '../../utils/dictionary'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import ajaxPayment from '../../utils/remote/ajaxPayment'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import AlertError from '../../Components/Common/AlertError'
import MobileMoneyFilter from '../../Components/PaymentMode/MobileMoneyFilter'
import { RenderSecure } from '../../utils/script/RenderSecure'
import usePermission from '../../utils/customHooks/usePermission'

const MobileMoneyDashboard=()=>{
  usePermission('R-MOMO-TRANS')
 
const {transactionListing, goToNextPage, metaData, loading, getMobileMoneyTransactions, providerTotalList } = useContext(MobileMoneyContext)
const [info, setInfo] = useState(false)
const providerTheme=["warning", "primary", "success", "danger"];
const buttons = () => {
  return (
      <>
          <button className='btn btn-light' onClick={getMobileMoneyTransactions}><i className='fa fa-redo mr-2' /> Refresh</button>

          {transactionListing  && 
          <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
          </a>
          }

      </>
  )
}


  const RenderFilter = () => {
          
    return <MobileMoneyFilter/>

}


const onClickPoll = async (internal_ref)=>{

    const server_response = await ajaxPayment.pollMobileMoney(internal_ref);
    getMobileMoneyTransactions()
    if(server_response.status === "OK"){
        setInfo(<AlertSuccess message={server_response.details.message}/>)
    }else{
      setInfo(<AlertError message={server_response.details.message}/>)
    }
}


    return (
     
            <div className="page">
              <LeftSideBar />
              <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                <TopBar />
                <div className="container-fluid">

                  <PageHeader
                    title="Mobile Money"
                    previousPageTitle="Dashboard"
                    back="/finance"
                    buttons={buttons()}
                />
                  {info}
                  {RenderFilter()}

                 
                  <div className="row">
                    <div className="col-sm-12 col-xl-12 col-lg-12">
                   
                    <div className="card custom-card">
                            <div className="card-body map-card">

                                <TableHeader
                                    title={"Mobile Money Transactions "}
                                    subtitle="All successful mobile money transactions in order of the most recent."
                                    // rightComponent={
                                    //   <span className='badge badge-warning badge-pill tx-14'><b>{totalAmount}</b></span>
                                    // }
                                />


                                  {Array.isArray(transactionListing) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }

                                <RenderSecure code="R-MOMO-BALANCE">
                                  <div className='text-wrap'>
                                          {Array.isArray(providerTotalList) && providerTotalList.map((item, key)=> <Fragment key={key}>
                                              <span className={`badge badge-pill badge-${providerTheme[key]} tx-14`}>{item.provider}: {item.total_collection.amount_p}</span> &nbsp; </Fragment>
                                          )}            
                                          
                                  </div>
                                </RenderSecure>
                         
                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    <div className="table-responsive">
                                       {Array.isArray(transactionListing) && <table id="table-mm-deposits" className="table table-hover text-nowrap mg-b-0 datatable" export-format={dictionary._exportBtnFormats}>
                                            <thead>
                                                <tr>
                                                    <th>Trans Date</th>
                                                    <th>Provider</th>
                                                    <th>Plate Number</th>
                                                    <th>Phone Number</th>                                                    
                                                    <th>Amount(UGX)</th>
                                                    <th>Status</th>
                                                    <th>Reason</th>
                                                    <th>Internal Ref</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactionListing.map((item, key)=>
                                                    <tr key={key}>
                                                        <td>{item.created_at.short_date + ' ' + item.created_at.time}</td>
                                                        <td>{item.provider_name}</td>
                                                        <td>{item.vehicle.vehicle_number}</td>
                                                        <td>{item.source_mobile}</td>
                                                        <td className='mm-amount'>{item.amount.amount_p}</td>
                                                        <td>
                                                            {item.status.id*1!==1 && <span className={`badge rounded badge-${item.status.color}`}>{item.status.status}</span>}

                                                            {item.status.id*1===1 && <Link to="#" onClick={()=>onClickPoll(item.internal_ref)}>
                                                              <span className={`badge rounded badge-${item.status.color}`}>{item.status.status}</span>
                                                            </Link>}
                                                        </td>
                                                        <td>{item.reason}</td>
                                                        <td>{item.internal_ref}</td>
                                                    </tr>
                                                
                                                )}
                                               
                                               <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td><b>TOTAL</b></td>
                                                    <td>&nbsp;</td>
                                                    <td><b>{functions.sumTableField("mm-amount")}</b></td>                                                    
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                       
                                                
                                            </tbody>
                                        </table>}

                                        {!transactionListing && <ActivityLoader/>}
                                        {transactionListing=="404" && <NoContent/>}
                                    </div>
                                </div>
                            </div>
                        </div>






                    
                    </div>
                   

                  </div>

                </div>
              </div>
              <RightSideBar />

            </div>

        

    )



  }


export default MobileMoneyDashboard
