import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { ClampingConsumer } from '../../Context/Clamping/ClampingContext';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import TableHeader from '../Common/TableHeader';
import ParkingViolationContext from '../../Context/Violation/ParkingViolationContext';


const RecentClamps =(props)=>{

    const { recentTransactions } = useContext(ParkingViolationContext);
    const statusUI=["", "info", "", "secondary", "danger", "primary", "light", "info", "secondary", "danger", "primary", "light", "secondary", "danger", "primary", "light"]


        return <div className="card custom-card">
                            <div className="card-body map-card">

                                <TableHeader
                                    title={"Recent Clamps"}
                                    subtitle="Recent clamping transactions"
                                    view="View All"
                                    href="/enforcement/clamp_transactions"
                                />

                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    <div className="table-responsive">
                                        <table className="table table-hover text-nowrap mg-b-0">
                                            <thead>
                                                <tr>
                                                    <th>Clamp Info</th>
                                                    <th>Clamp Status</th>
                                                    {/* <th>Vehicle</th>
                                            <th>Street</th>
                                            <th>Slots</th>
                                            <th>Location</th>
    
                                            <th>Device ID</th>
                                            <th>Status</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {  Array.isArray(recentTransactions) &&  recentTransactions.map((item, key) =>
                                                            <tr key={key}>
                                                                {/* <td >
                                                            <div className="main-img-user">
                                                                <i className="fa fa-car tx-primary" style={{ fontSize: "34px" }} />
                                                            </div>
                                                        </td> */}

                                                                <td className="tx-bold">
                                                                    <Link to={
                                                                        {
                                                                            pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                            state: { plateNumber: item.vehicle.plate_number }
                                                                        }}>{item.vehicle.plate_number}
                                                                    </Link>
                                                                    <br />
                                                                    <small className="tx-12 tx-gray-500">{item.updated_at.date + " at " + item.updated_at.time}</small>

                                                                    {/* <h6 className="mg-b-0">{item.vehicle.plate_number}</h6> */}


                                                                </td>

                                                                <td className="text-left">
                                                                    <span className={`badge rounded badge-${statusUI[item.status_id]}`}>{item.status}</span>
                                                                    <br />
                                                                    <small className="tx-12 tx-gray-500">{item.ref}</small>

                                                                </td>
                                                                {/* <td >
                                                            <h6 className="mg-b-0 font-weight-bold">{item.days_remaining.total_p}</h6><small className="tx-11 tx-gray-500">Days Remaining</small>
                                                        </td> */}
                                                            </tr>)}

                                            </tbody>
                                        </table>

                                        {!recentTransactions && <ActivityLoader />}
                                        {recentTransactions==="404" && <NoContent />}
                                    </div>
                                </div>
                            </div>
                        </div>

                 
    }



export default RecentClamps
