import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const ACCESS_KEY = 'key=AAAArC0kdw8:APA91bFvA8fKJ2WEBj_vHKnvmUG9ScZ3jG3XwUCBp86YLLLIxVbDUSlUi_IkYJq34en4tSb-R6QureGEi9PXXwbalDJLqb0HO3Ljd59N52TzEFgpJRY7yvKU_XPTrBJLA6pCO20_dK61'
const USER_ID = 'cRKzs_R84p3fCyWgBO8HkM:APA91bEBdNxBEaz_wuHeXHcAOn8b8W7DHo8DF4nKppLKBKKpA4eNr0sX3cOSVOWQIsjRRl2RvNxMKfSktXY3EHI35copn77dWEjeMux77SrSzIBRM1ByH_98IzTw-_aDjdknnex8kLuw'
const apiHost = dictionary.apiHost


export default {

    async sendFirebaseNotification(title, body) {

        let data = {
            // "user_id": user_id,
            "notification": {
                "title": title,
                "body": body
            },
            "to": USER_ID
        }

        try {
            let response = await fetch('https://fcm.googleapis.com/fcm/send',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': ACCESS_KEY
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

}
