import React, { useEffect, useState } from 'react'
import ajax from '../../../utils/ajax';
import dictionary from '../../../utils/dictionary';

const TicketBalance=(props)=> {

  const [vehicleID, setVehicleID] = useState(props.vehicleID)
  const [balance, setBalance] = useState("...")
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(()=>{
    getBalance();
  }, [refreshCounter])

  
  useEffect(()=>{

    setTimeout(() => {
        setRefreshCounter(refreshCounter+1)
    }, dictionary._refreshRate);

}, [refreshCounter])
  


  const getBalance = async () => {

    const server_response = await ajax.getVehicleTicketBalance(vehicleID);
    if (server_response.status === "OK") {
        
      setBalance(server_response.details.content.amount_c)
      
    }
  }



    return (

      <div className="list-group-item  d-flex border-right-0 border-left-0 border-top-0">
        <i className="fas fa-barcode"></i>
        <p className="ml-3 mb-0">Ticket Balance</p>
        <span className="ml-auto font-weight-bold">UGX. {balance}</span>
      </div>
    )
  }


export default TicketBalance
