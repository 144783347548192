import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
// import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
// import Badge from '../../Components/Common/Badge'
// import RoleFilters from '../../Components/Operations/RoleFilters'
import PageNav from '../../Components/Common/PageNav'
// import PopAssignDevice from '../../Components/Operations/PopAssignDevice'
import PopNewReservation from '../../Components/Reservation/PopNewReservation'
import ReservationInvoice from '../../Components/Invoices/ReservationInvoice'
import { Link } from 'react-router-dom'
import ReservationCompanyList from '../../Components/Reservation/ReservationCompanyList'
import PopNewOrganisation from '../../Components/Reservation/PopNewOrganisation'
import ReservationContext, { ReservationConsumer } from '../../Context/ReservationContext'
import NoContent from '../../Components/Common/NoContent'
import TableHeader from '../../Components/Common/TableHeader'


const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

class Reservation extends React.Component {

    static contextType = ReservationContext

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        activeTransactionId: false,
        toast: false,
        selectedRecord: false,
        dialogueNewAssignment: false,
        dialogueDelete: false,
        filterDescription: "List of parking reservation invoices",
        pagination: false,
        roleId: false,
        invoice: false,
        dialogueNewOrganisation: false,
        showOrganisations: true

    }

    componentDidMount() {
        this.setState({
            dialogueNewAssignment: <PopNewReservation />
        })

    }



    onClickReserve = () => {
        this.setState({
            dialogueNewAssignment: false
        }, () => {
            this.setState({
                dialogueNewAssignment: <PopNewReservation onConfirm={this.onConfirmReservation} />
            }, () => {
                functions.openPopup("pop_new_reservation")
            })

        })

    }

    onConfirmReservation = async (organisationId, locationId, totalSlots, reservationPlanId, startDate, amount) => {
        const server_response = await ajax.createReservation(organisationId, locationId, totalSlots, reservationPlanId, startDate, amount);
        const { listResersavations } = this.context;
        if (server_response.status === "OK") {
            listResersavations();
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />,
            }, () => {

                functions.closePopup("pop_new_reservation")

            })
        } else {
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {
                functions.closePopup("pop_new_reservation")
            })
        }

    }





    onSelectRecord = (transactionId, record) => {
        this.setState({
            activeTransactionId: transactionId,
            selectedRecord: record,
            toast: false
        })
    }


    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickReserve}>
                    <i className="fas fa-edit"></i> New Reservation
                </button>

            </>
        )
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }


    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item pointer" onClick={this.onClickReserve}>
                    <i className="fas fa-edit mr-2"></i>New Reservation
                </a>
                <a className="dropdown-item pointer" onClick={this.onClickAddOrganisation}>
                    <i className="fas fa-plus-circle mr-2"></i>Add Organisation
                </a>
                <Link to="/reservation/payment_records" className="dropdown-item pointer">
                    <i className="fas fa-handshake mr-2"></i>Payment Records
                </Link>
            </>
        )
    }





    onClickInvoice = (invoiceData) => {
        this.setState({
            invoice: false
        }, () => {
            this.setState({
                invoice: <ReservationInvoice record={invoiceData} />
            }, () => {
                $("#modal_reservation_invoice").modal('show');
            })

        })

    }



    //==============NAVIGATION CONTROLS END===========================


    onConfirmOrganisation = () => {
        const { listCompanies } = this.context;

        listCompanies();
    }


    onClickAddOrganisation = () => {
        this.setState({
            dialogueNewOrganisation: false
        }, () => {
            this.setState({
                dialogueNewOrganisation: <PopNewOrganisation onConfirm={this.onConfirmOrganisation} />
            }, () => {
                functions.openPopup('pop_new_organisation')
            })

        })

    }


    RenderTransactions = () => {
        const { reservationListing } = this.context;
        const transactionList = reservationListing;
        return (

            <div className="table-responsive">

                {(transactionList === "404") && <NoContent />}

                {transactionList && Array.isArray(transactionList) &&
                    <table id="reserve-table" export-format={exportFormats} className="table table-bordered border-t0 key-buttons text-nowrap w-100 datatable mb-0 table-hover">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <td>Invoice Ref</td>
                                <th>Organisation</th>
                                <th>Plan</th>
                                <th>Start Date</th>
                                <th>Expiry Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList.map((item, key) =>
                                <tr key={key}>
                                    <td>{item.created_at.when}</td>
                                    <td>
                                        <Link
                                            to="#"
                                            onClick={() => this.onClickInvoice(item)}>
                                            {item.invoice_ref}
                                        </Link>

                                    </td>
                                    <td>{item.organisation.title}</td>
                                    <td>{item.reservation_plan.plan}</td>
                                    <td>{item.start_date.short_date}</td>
                                    <td>{item.expiry_date.short_date}</td>
                                    <td>{item.amount.total_c}</td>
                                </tr>)}


                        </tbody>
                    </table>
                }

                {!transactionList && <ActivityLoader />}


            </div>
        )
    }


    render() {
        return (
            <div className="page">
                {this.state.sessionPreview}
                {this.state.dialogueDelete}
                {this.state.invoice}


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Reservation"
                            previousPageTitle="Parking Space"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                            back="/operations"
                        />

                        {this.state.toast}
                        {this.state.dialogueNewAssignment}
                        {this.state.dialogueNewOrganisation}

                        <div className="row">
                            <div className="col-sm-12 col-xl-8 col-lg-8">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        {/* <div>
                                            <h6 className="card-title mb-1">Parking Reservation Invoices</h6>
                                            <p>{this.state.filterDescription}</p>
                                        </div> */}

                                        <TableHeader
                                            title="Parking Reservation Invoices"
                                            subtitle={this.state.filterDescription}
                                        />

                                        <this.RenderTransactions />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-xl-4 col-lg-4">
                                {this.state.showOrganisations && <ReservationCompanyList />}
                            </div>

                        </div>






                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default Reservation
