import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';
import functions from '../../utils/functions'
import ajaxConfig from '../../utils/remote/ajaxConfig';
import ajaxSticker from '../../utils/remote/ajaxSticker';

class PopEditStickerDuration extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        effectiveDate:'',
        expiryDate:'',
        stickerId:'',
        info: '',
        loading: false
    }

    componentDidMount() {
        const record = this.props.record
        this.setState({
            effectiveDate:record.effective_date.db,
            expiryDate:record.expiry_date.db,
            stickerId:record.sale_id
        })
    }
    

  

    onConfirm = async (event) => {
        event.preventDefault();
        const {stickerId, effectiveDate, expiryDate}=this.state;
        if (stickerId.length > 0 && effectiveDate.length>0 && expiryDate.length>0) {
            this.setState({
                loading: true
            })

            const server_response = await ajaxSticker.updateStickerDuration(stickerId, effectiveDate, expiryDate);
             this.setState({
                loading: false
            })
            if(server_response.status=="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

           
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }




   
    render() {

        return (
            <div className="modal" id="modal_sticker_duration">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Edit Sticker Duration</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              <p className='mb-4'><b>Note:</b> It's highly recommended to record a new sticker sale instead of extending the duration of an existing sticker.</p>

                              <TextInput
                                    label="Effective Date"
                                    name="EffectiveDate"
                                    type="date"
                                    onChange={(e) => this.setState({effectiveDate:e.target.value})}
                                    value={this.state.effectiveDate}
                                />
                                 <TextInput
                                    label="Expiry Date"
                                    name="ExpiryDate"
                                    type="date"
                                    onChange={(e) => this.setState({expiryDate:e.target.value})}
                                    value={this.state.expiryDate}
                                />
                          



                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Update Duration</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopEditStickerDuration;
