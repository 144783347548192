import React from "react"
/**
 * 
 * @param title theme
 * @returns 
 * @children 
 * <Link to="#" className="dropdown-item" onClick="">
                    <i className="fas fa-ban text-danger mr-2"></i>Link Name</Link>

 */
const OptionButton=(props)=>{


    return <>
            <button className={`btn ripple btn-${props.theme || 'light'} dropdown-toggle`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-cog mr-1"></i> {props.title || "Options"} <i className="fas fa-caret-down ml-1"></i>
              </button>
              <div className="dropdown-menu tx-13">
                   {props.children}
                  
              </div>
    </>

}


export default OptionButton