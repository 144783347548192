import React, { useState } from 'react'
import PageHeader from '../../../Components/Common/PageHeader'
import { Link } from 'react-router-dom'
import XReport from '../XReport'
import CobraPerformance from '../../../Components/Operations/Violation/Report/CobraPerformance'
import ZuluPerformance from '../../../Components/Operations/Violation/Report/ZuluPerformance'
import PageContainer from '../../../Components/Layout/PageContainer'
import XReportFilter from '../../../Components/Operations/Violation/Report/Filters/XReportFilter'
import CobraReportFilter from '../../../Components/Operations/Violation/Report/Filters/CobraReportFilter'
import ZuluReportFilter from '../../../Components/Operations/Violation/Report/Filters/ZuluReportFilter'




const UserFieldReport =()=>{

    const [activeTab, setActiveTab] = useState(1);

    const buttons=()=>{
        return (
            <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
        </a>
        )
    }

    const RenderFilter=()=>{
        return <>
            {activeTab == 1 && <XReportFilter/>}
            {activeTab == 2 && <CobraReportFilter/>}
            {activeTab == 3 && <ZuluReportFilter/>}
        </>
    }


        return (
            <PageContainer>
           
                        <PageHeader
                            title="User Field Performance"
                            previousPageTitle="Reports"
                            back="/operations"
                            buttons={buttons()}
                        />
                        {RenderFilter()}
                        <div className="">
                            <div className="card custom-card main-content-body-profile ">
                                <nav className="nav main-nav-line">
                                    <Link to='#' className={`nav-link ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)} data-toggle="tab" href="#tab1over">X Report</Link>
                                    <Link to='#' className={`nav-link ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)} data-toggle="tab" href="#tab2rev">Cobras Performance</Link>
                                    <Link to='#' className={`nav-link ${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)} data-toggle="tab" href="#tab2pass">Zulus Performance</Link>
                                </nav>
                                <div className="card-body tab-content h-100">

                                    <div className={`tab-pane ${activeTab == 1 ? 'active' : ''}`} id="tab1over">
                                        {activeTab == 1 && <XReport/>}
                                    </div>

                                    <div className={`tab-pane ${activeTab == 2 ? 'active' : ''}`} id="tab2rev">
                                        {activeTab == 2  && <CobraPerformance />}
                                    </div>

                                    <div className={`tab-pane ${activeTab == 3 ? 'active' : ''}`} id="tab2pass">
                                       {activeTab == 3 && <ZuluPerformance />}
                                    </div>


                                </div>
                            </div>
                        </div>

                </PageContainer>

        );
    
}
export default UserFieldReport
