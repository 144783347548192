import React from 'react';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';





class PopValidateClamp extends React.Component {

    state = {
        
        info:""
    }

    

    



    render() {
       

        return (
            <div className="modal" id="validate_clamp">
                <div className="modal-dialog modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Validate Clamp</h6>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <p>Clamp validation checks if the vehicle clamp can be lifted after a payment or white listing process. Normally this is performed automatically after a payment.
                                <br/><br/>A successful clamp validation will initialize the vehicle unclamping process
                            </p>

                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Validate Clamp</button>
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopValidateClamp;
