import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import Export from '../../Components/Common/Export'
import { Link } from 'react-router-dom'
import PageNav from '../../Components/Common/PageNav'
import ajaxVendor from '../../utils/remote/ajaxVendor'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'

class ListResellers extends React.Component {


    state = {
        userId: functions.sessionGuard(),
        //PAGINATION
        currentPage: 1,
        transactionList: false,
        metaData: false,
        pagination: false,
        //EO PAGINATION
        filterTitle: 'List of All Vendors',
        vipStatus: '',
        mdlNewPayment: false,

        filter: false,
    }

    componentDidMount() {

        this.listAllRecords();
    }




    listAllRecords = async () => {

        if (this.state.filter) {
            const filter = this.state.filter;
            var server_response = await ajaxVendor.filterVendorRecords(
                filter.dateFrom,
                filter.dateTo,
                filter.selectedValue,
                filter.keyword,
                this.state.currentPage,
                // this.state.status
            );

        } else {
            this.setState({
                transactionList: false,
                metaData: false
            })

            var server_response = await ajaxVendor.getVendorRecords(this.state.currentPage);
        }

        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())
        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }
    }




    buttons = () => {
        return (
            <>
                {this.state.filter && <button className="btn ripple btn-primary" onClick={this.resetFilter}>
                    Reset
                </button>}

                {/* <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a> */}



                {/* <Export
                    url="#"
                /> */}
            </>
        )
    }



    onFilter = async (filter) => {
        this.setState({
            filter: false,
            transactionList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllRecords())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            transactionList: false
        }, () => this.listAllRecords())
    }





    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                page: this.state.page + 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    onClickPage = (page) => {
        this.setState({
            page: page,
            transactionList: false
        }, () => this.listAllRecords())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                page: this.state.page - 1,
                transactionList: false

            }, () => {
                this.listAllRecords();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {

                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }

    //==============NAVIGATION CONTROLS END===========================





    render() {

        const transactionList = this.state.transactionList;

        return (
            <div className="page">
                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Resellers"
                            previousPageTitle="Vendors"
                            buttons={<this.buttons />}

                        />

                        <CustomizedFilterBar
                            keyword="keyword"
                            // selection={{ title: 'Is VIP', options: listOptions }} 
                            onReset={this.resetFilter}
                            onConfirm={this.onFilter}
                        />



                        <div className="row">

                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        {/* { functions.findInPermission(permissionList, 'USER-002') && */}
                                        <TableHeader
                                            title={this.state.filterTitle}
                                            subtitle="All third party app accounts"
                                        />
                                        <div className="text-wrap">
                                            <div className="example">
                                                {this.state.metaData && <div className="btn-list">

                                                    <button type="button"
                                                        className="btn ripple btn-light btn-sm"
                                                    >
                                                        Total  <span className="badge badge-dark ml-2">{this.state.metaData.count}</span>
                                                    </button>

                                                </div>}
                                            </div>
                                        </div>

                                        {/* } */}
                                        <div className="table-responsive">
                                            <table id="users" className="table text-nowrap table-hover mg-b-0">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Vendor Name</th>
                                                        <th>Contact Person</th>
                                                        <th>Contact</th>
                                                        <th>Email</th>
                                                        <th>Float balance
                                                            <small className="tx-12 tx-gray-500"> UGX</small>
                                                        </th>
                                                        <th>Created On</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {transactionList && transactionList !== "404" &&

                                                        transactionList.map((item, key) =>
                                                            <tr
                                                                key={key}
                                                            >
                                                                <td>{key + 1}</td>
                                                                <td className="text-center">{item.user.username}</td>
                                                                <td>
                                                                    <h6 className="mg-b-1">{item.user.last_name}</h6>
                                                                    <small className="tx-12 tx-gray-500">{item.user.first_name}</small>
                                                                </td>
                                                                <td>{item.user.phone_number}</td>
                                                                <td>
                                                                    <span className="btn ripple btn-light btn-sm">{item.user.email}</span>
                                                                </td>
                                                                <td>
                                                                    <Link to={
                                                                        {
                                                                            pathname: `/vendor/statement/${item.user.username}`,
                                                                            state: { vendorUserId: item.user.user_id }
                                                                        }
                                                                    }
                                                                        className="tx-14"
                                                                        replace>
                                                                        {item.float.amount_c}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {item.updated_at.short_date}<br />
                                                                    <small className="tx-12 tx-gray-500">{item.updated_at.time}</small>
                                                                </td>

                                                            </tr>
                                                        )}

                                                </tbody>
                                            </table>


                                            {this.state.metaData && this.state.pagination}

                                            {!transactionList && <ActivityLoader />}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ListResellers
