import React, { useContext } from 'react';
import StatisticCard from '../../StatisticCard';
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext';


const CreditNotesLastMonth =()=>{

    const {lastMonthCreditNote} = useContext(FiscalReceiptContext);
 
    return <StatisticCard
                title="Credit Notes" 
                value={lastMonthCreditNote ? lastMonthCreditNote : '...'}
                caption="Last month"
                icon={"fas fa-ban"}
                color={"text-danger"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-danger"}
                />
 
}

export default CreditNotesLastMonth;