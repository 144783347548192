import React, { useEffect, useState } from 'react';
import ajaxOperation from '../../utils/remote/ajaxOperation';
import { detectTable, restoreTable } from '../../utils/script/customTables';

const VIPListContext = React.createContext();

export const VIPListConsumer = VIPListContext.Consumer;

export const VIPListProvider = (props) => {

    const [transactionListing, setTransactionListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1"})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);



    useEffect(()=>{
       getVIPTransactions();
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getVIPTransactions=async()=>{
        setLoading(true)
        setTransactionListing(false)
        const server_response = await ajaxOperation.fetchVIPVehicles(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTransactionListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setTransactionListing("404");
            setMetaData(false);
        }
    }

  


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxOperation.fetchVIPVehicles(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTransactionListing(transactionListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "status":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        })
        
    }

    const onChangeStatus=(status)=>{

    }


    const refreshList=()=>{
        
      resetData()
    }
      


  
    return (
        <VIPListContext.Provider value={{
            transactionListing,
            metaData,
            loading,
            goToNextPage,
            setData,
            resetData,
            filterData,
            getVIPTransactions,
            onChangeStatus,
            refreshList
        }}
        >
            {props.children}
        </VIPListContext.Provider>
    );
}


export default VIPListContext;
