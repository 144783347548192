import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopInUseClampInfo from '../../../Components/clampTools/PopInUseClampInfo'
import { Link } from 'react-router-dom'
import dictionary from '../../../utils/dictionary'
import ConfigurationContext from '../../../Context/ConfigurationContext'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import PopImportClampTools from '../../../Components/PopImportClampTools'
import FilterClampTools from './FilterClampTool'
import ToolStats from '../../../Components/clampTools/ToolStats'
import PageContainer from '../../../Components/Layout/PageContainer'
import RefreshTools from '../../../Components/clampTools/RefreshTools'
const eformats = dictionary._exportConfigBtnFormats

class ConfigClampTool extends React.Component {

    static contextType = ConfigurationContext;

    state = {
        userId: functions.sessionGuard(),
        clampToolList: false,
        clampToolId: false,
        clampToolRecord: false,
        toast: '',
        modal: "",
        model: false,
        clampToolsByStatusList: "",
        inUse: "",
        filterTitle: "All ClampTools",
        clampToolStatistics: ""


    }

    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/clamptools/new'
        })
    }



    onClickImportClampTools = () => {
        const { getClampTools } = this.context;
        this.setState({
            model: false
        }, () => {
            this.setState({
                model: <PopImportClampTools
                    refresh={getClampTools}
                />
            }, () => functions.openPopup('modal_import_clamp_tools'))

        })
    }

    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-1"></i> Add
                </button>

                <a className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                   <Link to="#" className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fas fa-edit text-success mr-2"></i>New Tool</Link>

                   {this.state.clampToolRecord && <a to="#" className="dropdown-item" onClick={this.onClickEdit}>
                    <i className="fas fa-edit text-primary mr-2"></i>Edit Tool</a>}

                    <RefreshTools/>
    

              </div>

        
            </>
        )
    }


    onClickEdit = () => {

        if (this.state.clampToolRecord) {

            this.props.history.push({
                pathname: '/config/clamptools/edit',
                search: '?q=1',
                state: { record: this.state.clampToolRecord }
            })
        }

    }

    onClickDelete = () => {
        if (this.state.clampToolRecord) {
            $("#modal_delete").modal('show')
        }
    }

    onClickInUse = (item) => {
        this.setState({
            modal: false
        }, () =>
            this.setState({
                modal: <PopInUseClampInfo
                    clampTool={item}
                />
            }, () => {
                $("#modal_config_clamp_tool").modal('show')
            })
        )

    }

    onChangeStatus = (inUse, filterTitle) => {
        const { getClampTools } = this.context;

        if (filterTitle == "All ClampTools") {
            this.setState({
                clampToolsByStatusList: false,
                filterTitle

            }, () => getClampTools())
        } else {
            this.setState({
                inUse,
                filterTitle
            }, () => this.clampToolsByStatus())
        }
    }

    clampToolsByStatus = async () => {
        this.setState({
            clampToolsByStatusList: false,
            clampToolList: false
        })
        const server_response = await ajax.listClampToolsByStatus(this.state.inUse);

        if (server_response.status === "OK") {
            this.setState({
                clampToolsByStatusList: server_response.details.ClampTools
            })
        } else {
            this.setState({
                clampToolsByStatusList: "404"
            })
        }
    }







    onConfirmDelete = async () => {

        if (this.state.clampToolId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteClampTool(this.state.clampToolId, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.clampToolId);
                    //Refresh list
                    this.getClampTools();
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }

    onSelectClampToolRecord = (clampToolId, clampToolRecord) => {
        this.setState({
            clampToolId: clampToolId,
            clampToolRecord: clampToolRecord,
            toast: ''
        })
    }

    render() {
        const { clampToolList} = this.context


        return (
           <PageContainer>
          
                <PageHeader
                    title="Clamp Tools"
                    previousPageTitle="Configurations"
                    back="/config"
                    buttons={<this.buttons />}
                />

                        {this.state.toast}
                        {this.state.model}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">
                                    {this.state.modal}


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <div className="d-flex justify-content-between align-items-center filter-example">

                                                    <TableHeader
                                                        title="List Of Company clamp tools"
                                                        subtitle="List of all clamp tools managed by Mutiplex parking"
                                                    />


                                                    <div className="text-wrap d-flex">
                                                      <ToolStats/>
                                                    </div>

                                                </div>

                                                <div className="table-responsive allView-config border-top">
                                                    {(clampToolList === "404") && <NoContent />}

                                                    {clampToolList && Array.isArray(clampToolList) &&
                                                        <table id="config-clampTools" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>No.</th> */}
                                                                    <th>Clamp Tool Number</th>
                                                                    <th> Status</th>
                                                                    <th>Created at</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(clampToolList) &&
                                                                    clampToolList.map((item, key) =>
                                                                        <tr
                                                                            id={"ct_" + item.clamp_tool_id}
                                                                            key={key}
                                                                            className={`${this.state.clampToolId == item.clamp_tool_id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelectClampToolRecord(item.clamp_tool_id, item)}
                                                                        >
                                                                            <td>{item.clamp_number}</td>
                                                                            <td className="font-weight-bold">{item.in_use}</td>
                                                                            <td>
                                                                                {item.created_at.date} <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                            </td>

                                                                        </tr>
                                                                    )

                                                                }

                                                              

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!clampToolList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ConfigMenu />



                        </div>


            </PageContainer>
        );

    }
}

export default ConfigClampTool
