import React from 'react'
import { Link } from 'react-router-dom';
import TableHeader from '../Common/TableHeader';
import $ from 'jquery';
import WrongParkingContext from '../../Context/ContraventionContext/WrongParkingContext';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import PopSessionLocation from '../Operations/PopSessionLocation';
import PopViewImage from '../PopViewImage';

class WrongParkingReport extends React.Component {

    static contextType = WrongParkingContext;

    state = {
        userId: functions.sessionGuard(),
        sessionList: false,
        locationPIN: false,
        imageView: false,
    }

    componentDidMount() {
        const { getCountReportedByWrongParking } = this.context;
        getCountReportedByWrongParking();
    }

    onClickViewVehiclePhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewImage image={record} />
            }, () => {
                $("#modal_view_image").modal('show');
            })
        })

    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    ViewAllSessions = () => {
        this.props.history.push('/parking_sessions/sessions')
    }


    render() {

        const { countReportedByWP } = this.context

        return (
            <div className="card custom-card ">
                {this.state.imageView}
                <div className="card-body map-card">

                    <TableHeader
                        title="Top Wrong Parking Reporters"
                        subtitle="Users who have reported wrong parking."
                        // view="View All"
                        // href={this.props.href}
                    />

                    {/* <div className="border-top mt-3"></div> */}
                    {this.state.locationPIN}
                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Reporter</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (countReportedByWP === "404")
                                            ?
                                            <tr>
                                                <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="7">
                                                    {/* <img alt="no-content" src={nocontent} /> */}
                                                    <NoContent />
                                                </td>
                                            </tr>
                                            :
                                            countReportedByWP && countReportedByWP !== "404" &&
                                            countReportedByWP.map((item, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        <div className="main-img-user">
                                                            <img alt="avatar" src={item.reported_by.profile_photo.file_path} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h6 className="mg-b-1">{item.reported_by.first_name} {item.reported_by.last_name}</h6>
                                                        <small className="tx-12 tx-gray-500">@ {item.reported_by.user_role.role_name}</small>
                                                    </td>
                                                    {/* <td>
                                                        <h6 className="mg-b-1">{item.reported_by.username}</h6>
                                                        <small className="tx-12 tx-gray-500">{item.reported_by.user_role.role_name}</small>
                                                    </td> */}
                                                    <td>{item.total_c}</td>
                                                    {/* <td className="pointer">
                                                        <div className="main-img-user">
                                                            <img alt="avatar" onClick={() => this.onClickViewVehiclePhoto(item)} src={item?.vehicle_photo?.file_path} />
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )}


                                </tbody>

                            </table>

                            {!countReportedByWP && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

export default WrongParkingReport
