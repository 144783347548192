import React, { useEffect, useState } from 'react';
import ajaxViolation from '../../utils/remote/ajaxViolation';

const ParkingViolationContext = React.createContext();

export const ParkingViolationConsumer = ParkingViolationContext.Consumer;

export const ParkingViolationProvider = (props)=> {

  const [recentTransactions, setRecentTransactions] = useState(false);
  const [weeklyTransactionGraphData, setWeeklyTransactionGraphData] = useState(false);
  const [allTransactions, setAllTransactions] = useState(false);

  const [violationList, setViolationList] = useState(false);
  const [availableToolList, setAvailableToolList] = useState(false);
  const [clampIncidents, setClampIncidents] = useState(false);
  const [clampCauses, setClampCauses] = useState(false);
  const [clampStatuses, setClampStatuses] = useState(false);

  const [data, setData] = useState({"page":"1"})
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(false);

  const [reportedEnforcementCount, setReportedEnforcementCount] = useState(false);
  const [clampedEnforcementCount, setClampedEnforcementCount] = useState(false);
  const [reporterStats, setReporterStats] = useState(false);

  const [toolStat, setToolStat] = useState(false);

  

  useEffect(()=>{
    getRecentTransactions();
    getViolationList();
    getAvailableTools();
    getClampIncidents();
    getClampCauses();
    getClampStatuses();
    getReportedEnforcementCount();
    getClampedEnforcementCount();
    getReporterStats();
    getToolStats();
    // getWeeklyGraph();
  }, [])


  useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])


useEffect(()=>{
  getAllTransactions();
}, [data])


  const getRecentTransactions=async()=>{
    setRecentTransactions(false);
    const server_response = await ajaxViolation.fetchRecentTransactions();
    if(server_response.status==="OK"){
      setRecentTransactions(server_response.details.content)
    }else{

      setRecentTransactions("404");
    }
  }

  const getWeeklyGraph=async()=>{
    const server_response = await ajaxViolation.fetchWeeklyGraph();
    if(server_response.status==="OK"){
      setWeeklyTransactionGraphData(server_response.details);
    }else{
      setWeeklyTransactionGraphData("404");
    }
  }


  const getAllTransactions=async()=>{
    setLoading(true)
    setAllTransactions(false)
    const server_response = await ajaxViolation.fetchClampTransactions(data);
    setLoading(false)
    if(server_response.status==="OK"){
      setAllTransactions(server_response.details.content.list)
      setMetaData(server_response.details.content.meta);
    }else{

      setAllTransactions("404");
      setMetaData("404");
    }
  }



  const getViolationList=async()=>{
    const server_response = await ajaxViolation.fetchViolationList();
    if(server_response.status==="OK"){
      setViolationList(server_response.details.content);
    }else{
      setViolationList("404");
    }
  }


  const getAvailableTools=async()=>{
    setAvailableToolList(false)
    const server_response = await ajaxViolation.fetchAvailableTools();
    if(server_response.status==="OK"){
      setAvailableToolList(server_response.details.content);
    }else{
      setAvailableToolList("404");
    }
  }


  const getReportedEnforcementCount=async()=>{
    setReportedEnforcementCount(false);
    const server_response = await ajaxViolation.countReportedEnforcements();
    if(server_response.status==="OK"){
      setReportedEnforcementCount(server_response.details.content);
    }
  }

  const getClampedEnforcementCount=async()=>{
    setClampedEnforcementCount(false);
    const server_response = await ajaxViolation.countClampedEnforcements();
    if(server_response.status==="OK"){
      setClampedEnforcementCount(server_response.details.content);
    }
  }


  const getToolStats=async()=>{
    setToolStat(false)
    const server_response = await ajaxViolation.fetchToolStatistics();
    if(server_response.status==="OK"){
      setToolStat(server_response.details.content);
    }else{
      setToolStat("404");
    }
  }


  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);
    // restoreTable(detectTable());

    const server_response = await ajaxViolation.fetchClampTransactions(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.content.list);
        setAllTransactions(allTransactions.concat(incoming));
        setMetaData(server_response.details.content.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "clamp_cause":options.clampCause,
        "page":"1",
        "plate_number":options.plateNumber,
        "status":options.status,
        "location":options.location,
        "clamp_incident":options.incident,
        "cobra":options.cobra

    })
    
}


const getClampIncidents=async()=>{
  const server_response = await ajaxViolation.fetchClampCancellationReasons();
  if(server_response.status==="OK"){
    setClampIncidents(server_response.details.content);
  }else{
    setClampIncidents("404");
  }
}

const getClampCauses=async()=>{
  const server_response = await ajaxViolation.fetchClampCauses();
  if(server_response.status==="OK"){
    setClampCauses(server_response.details.content);
  }else{
    setClampCauses("404");
  }
}

const getClampStatuses=async()=>{
  const server_response = await ajaxViolation.fetchClampStatuses();
  if(server_response.status==="OK"){
    setClampStatuses(server_response.details.content);
  }else{
    setClampStatuses("404");
  }
}

const getReporterStats=async()=>{
  
  setReporterStats(false);
  const server_response = await ajaxViolation.fetchReporterStats();
  if(server_response.status==="OK"){
    setReporterStats(server_response.details.content);
  }else{
    setReporterStats("404");
  }
  
}
    
    return (
           <ParkingViolationContext.Provider value={
               {
                  recentTransactions,
                  weeklyTransactionGraphData,
                  allTransactions,
                  metaData,
                  loading,
                  violationList,
                  availableToolList,
                  clampIncidents,
                  clampCauses,
                  clampStatuses,
                  reportedEnforcementCount,
                  clampedEnforcementCount,
                  reporterStats,
                  toolStat,
                  getAvailableTools,
                  getAllTransactions,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData,
                  getRecentTransactions,
                  getReportedEnforcementCount,
                  getClampedEnforcementCount,
                  getReporterStats,
                  getToolStats
               }
               }>
               {props.children}
           </ParkingViolationContext.Provider>
        );
    
}

export default ParkingViolationContext;