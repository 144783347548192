import React from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import ajaxOperation from '../utils/remote/ajaxOperation';
import { RenderSecure } from '../utils/script/RenderSecure';
import AlertError from './Common/AlertError';
import AlertSuccess from './Common/AlertSuccess';
import TextInput from './Common/TextInput';

class PopSessionInfo extends React.Component {

    state = {
        sessionInfo: this.props.session,
        editMode:false,
        plateNumber:'',
        slots:'',
        info:''
    }

    componentDidMount() {
        this.setState({
            editMode:false
        })
        
        const session = this.state.sessionInfo;
        if(session){
            this.setState({
                plateNumber:session.vehicle.vehicle_number,
                slots:session.number_of_slots
            })
        }
    }


    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value })
    }

    onChangeSlots = (event) => {
        this.setState({ slots: event.target.value })
    }


    onClickEdit=()=>{
        this.setState({editMode:true})
    }

    updateSession=async(e)=>{
        e.preventDefault();

        const {plateNumber, slots} = this.state;
        if(plateNumber.length>0 && slots*1>0){

            const server_response = await ajaxOperation.updateSessionInfo(
                this.state.sessionInfo.session_id,
                plateNumber,
                slots
            );

            if(server_response.status==="OK"){
                this.setState({info:<AlertSuccess message={server_response.details.message}/>})
            }else{
                this.setState({info:<AlertError message={server_response.details.message}/>})

            }

        }else{
            this.setState({info:<AlertError message={dictionary._completeFields}/>})
            setTimeout(()=>{
                this.setState({info:''})
            }, 3000)
        }
    }


    RenderEdit=()=>{


        return(
            <form method="post" onSubmit={this.updateSession}>
                {this.state.info}
                    <TextInput
                        label="Plate Number"
                        type="text"
                        onChange={() => this.onChangePlateNumber}
                        value={this.state.plateNumber}
                    />

                    <TextInput
                        label="Number of Slots"
                        type="number"
                        onChange={() => this.onChangeSlots}
                        value={this.state.slots}
                    />

            </form>
        )

    }
    

    render() {
        const session = this.state.sessionInfo

        return (
            <div className="modal" id="modal_session_info">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Session Info</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">

                            {session && !this.state.editMode && <div className="main-contact-info-body">
                                <div className="media-list pb-lg-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Session ID</label>
                                                <span className="tx-medium">{session.session_id_c}</span>
                                            </div>
                                            <div>
                                                <label>Vehicle</label>
                                                <span className="tx-medium">{session.vehicle.vehicle_number}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Start Time</label>
                                                <span className="tx-medium">{session.start_time.long_date}</span>
                                            </div>
                                            <div>
                                                <label>End Time</label>
                                                <span className="tx-medium">{session.end_time.long_date}</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Start Device</label>
                                                <span className="tx-medium">{session.start_device.tag_number}</span>
                                            </div>
                                            <div>
                                                <label>Conductor</label>
                                                <span className="tx-medium">{session.conductor.first_name + ' ' + session.conductor.last_name}</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Session Code</label>
                                                <span className="tx-medium">{session.session_id}</span>
                                            </div>
                                           
                                        </div>
                                    </div>


                                </div>
                            </div>}

                            {this.state.editMode && <this.RenderEdit/>}


                        </div>
                        <div className="modal-footer">
                            {session && session.active === "1" && !this.state.editMode && <>
                                <RenderSecure code="U-SESSION-05">
                                    <button className="btn ripple btn-danger" type="button" onClick={() => this.props.onEnd(session.session_id)}>
                                        End Session
                                    </button>
                                </RenderSecure>
                               
                                <RenderSecure code="U-SESSION-06">
                                    <button className="btn ripple btn-warning" type="button" onClick={this.onClickEdit}>
                                        Edit Session
                                    </button>
                                </RenderSecure>
                              
                                </>}

                            {this.state.editMode &&  <button className="btn ripple btn-success" type="button" onClick={this.updateSession}>
                                    Update Session
                                </button>}
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopSessionInfo;
