import React from 'react'
// import { Link } from 'react-router-dom';
// import ajax from '../../utils/ajax';
// import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import '../../assets/css/receipt.css'
class POSReceipt extends React.Component {

	print = () => {
		functions.printPOS('receipt_document');
	}



	render() {
		const receipt = this.props.receipt;
		return (
			<div className="modal" id="pos_receipt">
				<div className="modal-dialog modal-dialog-centered modal-sm" role="document">

					<div className="modal-content modal-content-demo shadow">
						<div className="modal-header">
							<h6 className="modal-title">Receipt</h6>
							<i className="fas fa-print App-link" title="Print" onClick={this.print}></i>
							<i className="fas fa-share-alt App-link" title="Share"></i>
							<button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12" id="receipt_document">
									{/**START RECEIPT BODY */}
									{!receipt && <ActivityLoader />}

									{receipt &&
										<div className="invoice-POS">
											<center id="top">
												<div className="logo"></div>
												<div className="info">
													<h2>MULTIPLEX</h2>
												</div>{/**<!--End Info-->**/}
											</center>{/* <!--End InvoiceTop--> */}

											<div id="mid">
												<div className="info">
													<p className="company-info">
														P. O Box 3874 Kampala<br />
                                    TIN: 1000073445 <br />
                                    Tel No.: 0393360100<br />
													</p>
												</div>
												<h3>PAYMENT-RECEIPT</h3>
												<p className="doc-type">-- {receipt.invoice.invoice_type.invoice_name} --</p>

											</div>{/* <!--End Invoice Mid--> */}


											<div id="bot">

												<div id="table">
													<table>

														<tr className="service">
															<td className="itemtext"><h2>RECEIPTNO :</h2></td>
															<td className="itemtext"><h2>{receipt.payment_id_c}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>INVOICE REF :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.invoice_id_c}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>ENTRY TIME :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.session_info.start_time.long_date}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>PAYMENT TIME :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.session_info.end_time.long_date}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>VEHICLE NO :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.vehicle.plate_number}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>NO. OF SLOTS :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.session_info.number_of_slots}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>LOCATION :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.session_info.location.location_name}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>DURATION :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.duration_c}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>AMOUNT PAID:</h2></td>
															<td className="itemtext"><h2>UGX {receipt.amount.amount_c}</h2></td>
														</tr>
														<tr className="service">
															<td className="itemtext"><h2>BALANCE AFTER :</h2></td>
															<td className="itemtext"><h2>UGX {receipt.balance_after.amount_c}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>PAYMENT MODE :</h2></td>
															<td className="itemtext"><h2>{receipt.payment_mode.mode_name} </h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>SERVED BY :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.session_info.conductor.first_name + ' ' + receipt.invoice.particulars.session_info.conductor.last_name}</h2></td>
														</tr>

														<tr className="service">
															<td className="itemtext"><h2>DEVICE NO :</h2></td>
															<td className="itemtext"><h2>{receipt.invoice.particulars.session_info.start_device.device_number}</h2></td>
														</tr>

														{receipt.white_list && <tr className="service">
														<td className="itemtext"><h2>Balance Date</h2></td>
														<td className="itemtext">
															<h2>{receipt.white_list.balance_date.short_date}</h2>
														</td>
														</tr>}


													</table>
												</div>{/* <!--End Table--> */}

												<div id="legalcopy">
													<p className="legal"><strong>Thank you!</strong><br />
						multiplexug@gmail.com<br />
						Customer Care: 0393360100

						</p>
												</div>


											</div>{/* <!--End InvoiceBot--> */}

										</div>}{/* <!--End Invoice--> */}


									{/**END RECEIPT BODY */}


								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

		)
	}

}

export default POSReceipt
