import React from 'react';
class TextInputDisabled extends React.Component {
  

  render(){

    
      return (

        <div className="row row-xs align-items-center mg-b-20">
            <div className="col-md-3 text-left">
                <label className="mg-b-0">{this.props.label}</label>
            </div>
            <div className="col-md-9 mg-t-5 mg-md-t-0">
                <span className="tx-bold">{this.props.value}</span>
            </div>
        </div>
      );
    
 
  }
}

export default TextInputDisabled;