import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OperationConsumer } from '../../Context/OperationContext';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

const PopNewSession=()=> {

    
        const [userId, setUserId ] = useState(functions.sessionGuard())
        const [plateNumber, setPlateNumber] = useState('');
        const [info, setInfo] = useState(false);
        const [searchList, setSearchList] = useState(false);
        const [conductorList, setConductorList] = useState(false);
        const [conductorRecord, setConductorRecord] = useState(false);
        const nav = useHistory();


        useEffect(()=>{
            searchVehicle()
        },[plateNumber])

   

        const onChangePlateNumber = (event) => {
                setPlateNumber(event.target.value)
            }

        const onConfirm = (event) => {
            event.preventDefault();
        if (plateNumber.length > 0 && conductorRecord) {

            functions.closePopup("modal_new_session");
            nav.push({
                pathname: '/operations/sessions/new',
                search: '?q=1',
                state: { conductor: conductorRecord, vehiclePlate: plateNumber }
            })

        } else {
            setInfo(<AlertError message={dictionary._completeFields} />)
        }
        }




   const searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(plateNumber);
        if (server_response.status === "OK") {
            setSearchList(server_response.details.content)
           
        } else {
            setSearchList(false)
        }
    }



    const onChangeConductor = (selectedOption) => {
       
        setConductorRecord(selectedOption.value)
    }

    
        return (
            <div className="modal" id="modal_new_session">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Session</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                              <OperationConsumer>
                                  {props=>{

                                        const conductorList = props.assignedConductorList;
                                        const optionsConductor = [];
                                        if (conductorList && conductorList !== "404") {
                                            conductorList.map((item, key) =>
                                                optionsConductor.push({ label: item.user_profile.first_name + ' ' + item.user_profile.last_name, value: item.user_profile })
                                            )
                                        }

                                      return(
                                        <SelectSearch
                                            label="Conductor"
                                            onChange={() => onChangeConductor}
                                            options={optionsConductor}
                                            placeholder={"Select Conductor..."}
                                        />
                                      )
                                  }}
                                
                            </OperationConsumer>

                                <TextInput
                                    label="Vehicle Number"
                                    type="text"
                                    onChange={onChangePlateNumber}
                                    value={plateNumber}
                                    name="vehicleNumber"
                                />


                            </form>

                            {searchList && searchList !== "404" && <div className="btn-list">
                                {searchList.map((item, key) =>
                                    <button key={key}
                                        onClick={() => setPlateNumber(item.plate_number)}
                                        type="button"
                                        className="btn ripple btn-light btn-sm">
                                        {item.plate_number}
                                    </button>)}
                            </div>}


                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-primary" type="button" onClick={onConfirm}>Continue</button>
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopNewSession;
