import React from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import AlertError from './Common/AlertError';
import SelectSearch from './Common/SelectSearch';
import TextInput from './Common/TextInput';
import functions from '../utils/functions';

class PopPermissionInput extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        permission: '',
        departmentModuleId: '',
        description: '',
        info: '',
        modulesList: false,
        toast: false,
        modal: '',
    }

    componentDidMount() {
        this.getDepartmentModules();
    }

    onChangePermission = (event) => {
        this.setState({ permission: event.target.value })
    }

    onChangeModule = (selectedOption) => {
        this.setState({
            departmentModuleId: selectedOption.value
        })
    }

    onChangeDescription = (event) => {
        this.setState({ description: event.target.value })
    }

    onConfirm = (event) => {
        event.preventDefault();
        if (this.state.permission.length > 0 && this.state.departmentModuleId > 0 && this.state.description.length > 0) {
            this.props.onConfirm(this.state.permission, this.state.departmentModuleId, this.state.description);
        } else {
            this.setState({
                info: ''
            }, () => this.setState({
                info: <AlertError message={dictionary._requirePermission} />
            })
            )
        }
    }

    // onConfirmRequest = async () => {
    //     const { roleName } = this.state;
    //     if (this.state.permission, this.state.departmentModuleId, this.state.description) {
    //         const server_response = await ajax.createPermission(roleName);
    //         if (server_response.status === 'OK') {
    //             this.setState({
    //                 // info: <AlertSuccess message={server_response.details.message} />

    //                 toast: <Toast message={server_response.details.message} />
    //             }, () => this.props.onReload())
    //             // $("#modal_report_wrong_parking").modal('hide');
    //         } else {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} type={2} />
    //                 // info: <AlertError message={server_response.details.message} />
    //             })
    //         }
    //     } else {
    //         this.setState({
    //             info: <AlertError message={
    //                 dictionary._completeFields} />,
    //             loading: ''
    //         })
    //     }
    // }

    getDepartmentModules = async () => {

        const server_response = await ajax.listDepartmentModules();

        if (server_response.status === "OK") {
            this.setState({
                modulesList: server_response.details.modules.list
            })
        } else {
            this.setState({
                modulesList: "404"
            })
        }
    }

    render() {

        const modulesList = this.state.modulesList;
        const optionsDepartmentModules = [];
        if (modulesList && modulesList !== "404") {
            modulesList.map((item, key) =>
                optionsDepartmentModules.push({ label: item.department_module, value: item.department_module_id })
            )
        }

        return (
            <div className="modal" id="modal_permission_input">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Permission"
                                    type="text"
                                    onChange={() => this.onChangePermission}
                                    value={this.state.permission}
                                />

                                <SelectSearch
                                    label="Department Module"
                                    onChange={() => this.onChangeModule}
                                    options={optionsDepartmentModules}
                                    placeholder={"Select Department Module..."}
                                />

                                <TextInput
                                    label="Description"
                                    type="text"
                                    onChange={() => this.onChangeDescription}
                                    value={this.state.description}
                                />

                            </form>


                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm permission</button>
                            <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopPermissionInput;
