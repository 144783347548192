import React, { Component } from 'react';
// import ajax from '../../utils/ajax';
// import functions from '../../utils/functions';
// import ActivityLoader from '../Common/ActivityLoader';
// import dictionary from '../../utils/dictionary'
import { Link } from 'react-router-dom';
// import NoContent from '../Common/NoContent';
import $ from 'jquery';
// import PopClampInfo from './PopClampInfo';
import ajaxClamping from '../../../utils/remote/ajaxClamping';
import ajaxPaymentDue from '../../../utils/remote/ajaxPaymentDue';
import TableHeader from '../../../Components/Common/TableHeader';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import NoContent from '../../../Components/Common/NoContent';
import PopClampInfo from '../../../Components/Clamps/PopClampInfo';
const eformat = dictionary._exportBtnFormats




class FilteredDuePayments extends Component {

    state = {
        userId: functions.sessionGuard(),
        clampRecord: false,
        selectedClampId: '',
        modal: false,
        buttonName: '',
        showlocation: '',
        clampOptions: "",
        ClampingLocation: "",
        clampedById: '',
        transactionId: "",
        ClampToolId: "",
        showclamptool: "",
        ClampingStatusId: "",
        ClampingStatusList: "",
        toast: false,
        paymentDueList: false,

        metaData: false,

        vehicleID: this.props.vehicleID,
        plateNumber: this.props.plateNumber,
        createdFrom: this.props.createdFrom,
        createdTo: this.props.createdTo,
        payementStatusID: this.props.payementStatusID,
        amountDue: this.props.amountDue,
        unClampedByID: this.props.unClampedByID,
        filter: this.props.filter,
        clampPreview: "",
        currentPage: 1,
        itemsPerPage: this.props.itemsPerPage

    };



    componentDidMount() {
        if (this.state.filter) {
            this.filterDuePayments();
        }

    }


    componentWillUnmount() {
        // clearTimeout(this.timerListClamps);
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }




    onClickRef = (record) => {
        this.setState({
            clampPreview: false
        }, () => {
            this.setState({
                clampPreview: <PopClampInfo clampInfo={record} />
            }, () => {
                $("#modal_clamp_info").modal('show');
            })

        })

    }




    filterDuePayments = async () => {
        const { filter } = this.state

        const server_response = await ajaxPaymentDue.filterPaymentDue(this.state.vehicleID, this.state.plateNumber, this.state.createdFrom, this.state.createdTo, this.state.payementStatusID, this.state.amountDue, this.state.currentPage, this.state.itemsPerPage);

        if (server_response.status === 'OK') {
            this.setState({
                paymentDueList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })
        }
        else {
            this.setState({
                paymentDueList: "404",
                metaData: false
            })
        }

    }


    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            clampList: false
        }, () => this.filterDuePayments())
    }


    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                clampList: false

            }, () => {
                this.filterDuePayments();
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                clampList: false
            }, () => {
                this.filterDuePayments();
            })
        }

    }


    onClickRecord = (selectedClampId, clampRecord) => {
        this.setState({
            selectedClampId: selectedClampId,
            clampRecord: clampRecord,
            clamp_vehicle: '',
            modal: false
        }, () => {

            this.props.onSelectRecord(selectedClampId, clampRecord)
        })
    }


    render() {
        const paymentDueList = this.state.paymentDueList
        const loading = this.state.loading;

        return (


            <div>

                <div className="table-responsive">

                    {<div className="text-wrap mt-3">
                        <div className="d-flex ">
                            <div className="btn-list mr-2">
                                <button type="button"
                                    className="btn ripple btn-light btn-sm"
                                >
                                    Total records <span className="badge badge-dark ml-2 font-weight-bold">{this.state.metaData?.count}</span>
                                </button>
                                {/* {/* <button
                                    type="button"
                                    className="btn ripple btn-success btn-sm"
                                    onClick={() => getPaymentDueByStatus(0)}>
                                    Paid Dues <span className="badge badge-dark ml-2">{countPaidPaymentDues}</span>
                                </button> */}
                                <button
                                    type="button"
                                    className="btn ripple btn-light btn-sm"
                                >
                                    Total pages <span className="badge badge-dark ml-2 font-weight-bold">{this.state.metaData?.total_pages}</span>
                                </button>
                            </div>
                        </div>
                    </div>}



                    {this.state.clampPreview}
                    {(paymentDueList === "404") && <NoContent />}
                    {paymentDueList && Array.isArray(paymentDueList) &&
                        <table id={this.props.filterclampsTableID} className={"table table-hover text-nowrap mg-b-0 key-buttons datatable "} export-format={eformat}>
                            <thead>
                                <tr>
                                    {this.state.currentPage == 1 && <th >No.</th>}

                                    <th >PAYMENT DUE REF.</th>
                                    <th>DATE</th>
                                    <th>Vehicle</th>
                                    <th className="text-center">Payment Status</th>
                                    <th className="text-center">Amount Due (UGX)</th>
                                    <th className="text-center"> Clamp Status</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    // defaultersList && defaultersList !== "404" &&
                                    paymentDueList.map((item, key) =>
                                        <tr key={key}>
                                            {this.state.currentPage == 1 && <td>
                                                {key + 1}
                                            </td>}
                                            <td >{item.ref}</td>
                                            <td>
                                                {item.updated_at.date}
                                                <small className="tx-12 tx-gray-500"> at {item.updated_at.time}</small>
                                            </td>

                                            <td className="tx-bold">
                                                <Link to={
                                                    {
                                                        pathname: `/vehicles/profile/${item.vehicle}`,
                                                        state: { plateNumber: item.vehicle }
                                                    }}>{item.vehicle}
                                                </Link>
                                            </td>


                                            <td className="text-center">
                                                {item.uncleared == 1 && <span className="badge rounded badge-secondary">UNCLEARED</span>
                                                }
                                                {item.uncleared == 0 && <span className="badge rounded badge-success">RESOLVED</span>}
                                            </td>

                                            <td className="text-center tx-bold">{item.total_amount_c}</td>


                                            <td className="text-center">
                                                {!item.clamping_info &&

                                                    <span className="badge badge-light badge-pill ">UNINITIALISED</span>

                                                }


                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.paid_partialy === "0" && <span className="badge badge-success badge-pill ">UNCLAMPED</span>}

                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_partialy === "1" && <span className="badge badge-pending badge-pill partial-pay-color">PAID PARTIALY BEFORE CLAMP</span>}

                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status && item.clamping_info.clamp_info
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" &&
                                                    item.clamping_info.clamp_info.towed === "1" && <span className="badge  badge-success badge-pill unclamp-tow-color">UNCLAMPED & TOWED</span>}


                                                {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                    === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "1" && <span className="badge badge-success badge-pill clamp-color ">CLEARED BEFORE CLAMP</span>}


                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "1" &&
                                                    <span className="badge badge-info badge-pill">PENDING  CLAMP</span>}

                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "0" && item.clamping_info?.clamp_info.cobra_abort === "1" &&
                                                    <>
                                                        <span className="badge badge-danger   badge-pill fw-bold"> Cobra Aborted</span>
                                                        <br />
                                                        <small className="tx-12 tx-gray-500"> {item.clamping_info?.clamp_info?.clamp_abort_info?.reason.reason} </small>

                                                    </>
                                                }



                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "0" && item.clamping_info?.clamp_info.admin_abort === "1" &&
                                                    <>
                                                        <span className="badge badge-secondary   badge-pill fw-bold"> Clamp removed by Ops</span>
                                                        <br />
                                                        <small className="tx-12 tx-gray-500"> {item.clamping_info?.clamp_info?.clamp_abort_info?.reason?.reason} </small>

                                                    </>
                                                }


                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.system_abort === "1" &&
                                                    <>
                                                        <span className="badge badge-warning   badge-pill">Left Parking</span>
                                                        <br />
                                                        <small className="tx-12 tx-gray-500"> Session Ended </small></>

                                                }


                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'RESPONDED' &&
                                                    <span className="badge badge-secondary badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}

                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'CLAMPED' &&
                                                    <span className="badge badge-dark badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}
                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING_UNCLAMP' &&
                                                    <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                                {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'UNCLAMP_RESPONDED' &&
                                                    <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}
                                                {/* {item.clamping_info != false && clamping_info?.clamp_info.status.status === 'UNCLAMPED' &&
                                                                            <span className="badge badge-success badge-pill">UNCLAMPED</span>} */}
                                            </td>

                                            {/* <td>{item.created_at.long_date}</td> */}


                                        </tr>
                                    )}

                            </tbody>
                        </table>
                    }

                    {/* {defaultersList && Array.isArray(defaultersList) && <LoadMoreButton action={props.fetchMore} loading={props.loading} />} */}






                    {this.state.metaData && <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <li className={`page-item ${this.state.currentPage === 1 && "disabled"}`}>
                                <a
                                    className="page-link"
                                    href="#" tabIndex="-1"
                                    aria-disabled={this.state.currentPage === 1 ? false : true}
                                    onClick={this.onClickPrevious}
                                >Previous</a>
                            </li>

                            {this.state.metaData.list_of_pages.map((item, key) =>
                                <li className={`page-item ${this.state.currentPage === item && "active"}`} key={key}>
                                    <a
                                        className="page-link"
                                        href="#"
                                        onClick={() => this.onClickPage(item)}>{item}</a>
                                </li>)}

                            <li className={`page-item ${this.state.currentPage === this.state.metaData.total_pages && "disabled"}`}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={this.onClickNext}>Next</a>
                            </li>
                        </ul>
                    </nav>}

                    {!paymentDueList && <ActivityLoader />}
                    {loading && <ActivityLoader />}
                </div>
            </div>
        )
    }
}

export default FilteredDuePayments;
