import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';
import functions from '../../utils/functions'
import ajaxConfig from '../../utils/remote/ajaxConfig';
import ajaxSticker from '../../utils/remote/ajaxSticker';

class PopEffectStickerPayments extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        effectiveDate:'',
        info: '',
        loading: false
    }

  

    onConfirm = async (event) => {
        event.preventDefault();
        const {effectiveDate}=this.state;
        if (effectiveDate.length > 0 ) {
            this.setState({
                loading: true
            })

            const server_response = await ajaxSticker.effectStickerPayment(effectiveDate);
             this.setState({
                loading: false
            })
            if(server_response.status=="OK")
            {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })
            }else{
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

           
        } else {
            this.setState({
                info: <AlertError message={"Enter sticker effective date and try again"} />
            })
        }
    }




   
    render() {

        return (
            <div className="modal" id="modal-effect-sticker">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Effect Sticker Payment</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                              <p className='mb-4'><b>Note:</b> In some cases a sticker might have been purchased at a later time after parking. In such cases you can manually trigger the sticker to clear the back invoices.</p>

                              <TextInput
                                    label="Effective Date"
                                    name="Effective Date"
                                    type="date"
                                    onChange={(e) => this.setState({effectiveDate:e.target.value})}
                                    value={this.state.effectiveDate}
                                />
                                

                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Effect Payment</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopEffectStickerPayments;
