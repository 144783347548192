import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');

const apiHost = dictionary.apiHost

export default {

    async listStreets(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'street/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listLocations() {

        try {
            let response = await fetch(apiHost + 'location/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async createLocation(
        location_name,
        street_id,
        description,
        lat,
        lng,
        start_lat,
        start_lng,
        end_lat,
        end_lng,
        slots,
        is_nonparking_zone
    ) {

        let data = {
            "location_name": location_name,
            "street_id": street_id,
            "description": description,
            "lat": lat,
            "lng": lng,
            "start_lat": start_lat,
            "start_lng": start_lng,
            "end_lat": end_lat,
            "end_lng": end_lng,
            "slots": slots,
            "is_nonparking_zone": is_nonparking_zone

        }
        try {
            let response = await fetch(apiHost + 'location.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async updateLocation(
        location_id,
        location_name,
        street_id,
        description,
        lat,
        lng,
        start_lat,
        start_lng,
        end_lat,
        end_lng,
        totalSlots,
        is_nonparking_zone
    ) {

        let data = {
            "location_name": location_name,
            "street_id": street_id,
            "description": description,
            "location_id": location_id,
            "lat": lat,
            "lng": lng,
            "start_lat": start_lat,
            "start_lng": start_lng,
            "end_lat": end_lat,
            "end_lng": end_lng,
            "slots": totalSlots,
            "is_nonparking_zone": is_nonparking_zone
        }

        try {
            let response = await fetch(apiHost + 'location.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async importLocations(file) {

        let data = {
            "file": file
        }

        try {
            let response = await fetch(apiHost + 'location/import',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async getVehiclesInSession(location_id) {
        let response = await apiCall("location/sessions/active/list", { "location_id": location_id });
        return response;
    },

    async fetchBranchLocations(branch_id) {
        let response = await apiCall("branch.location/list", { "branch_id": branch_id });
        return response;
    },


    async importUpdateLocations(file) {

        let data = {
            "file": file
        }

        try {
            let response = await fetch(apiHost + 'location/import_update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async fetchActiveSlots()//GET
    {
        let response = await apiCall("locations/location/count_active_slots", []);
        return response;

    },

}
