import React, { Component } from 'react';
import ActivityLoader from '../../Common/ActivityLoader';
import TableHeader from '../../Common/TableHeader';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import WarnDelete from '../../WarnDelete';
import Toast from '../../Common/Toast';
import dictionary from '../../../utils/dictionary';
import PopImportList from './PopImportList';
import NoContent from '../../Common/NoContent';

class VehicleListingInfo extends Component {

    state = {
        listInfo: false,
        listId:this.props.listId,
        userId: functions.sessionGuard(),
        model:false,
        toast:false
    
      }
    
    
      componentDidMount() {
        this.getList()
    
      }
    
      componentWillUnmount() {
        clearTimeout(this.timerCount)
      }
    
    
      getList = async () => {
    
        const server_response = await ajax.getVehicleListingInfo(this.state.listId);
        if (server_response.status === "OK") {
          this.setState({
            listInfo: server_response.details.content
          })
        }
      }


      onClickDeleteItem=(item_id, plate_number)=>{
        this.setState({
          model:false
        }, ()=>{

          this.setState({
            model: <WarnDelete
            title="Remove list item ?"
            description={"Are you sure you want to remove " + plate_number + " from this list" }
            buttonLabel="Remove item"
            onConfirm={() => this.deleteListItem(item_id)} />
          }, ()=>{
            functions.openPopup("modal_delete");
          })

        })
       
      }


      deleteListItem=async(item_id)=>{
        functions.closePopup("modal_delete");

        this.setState({
          toast: <Toast
                  message={"Removing...please wait..."}
                  type={4}
                  onClose={() => this.resetToast()} />
          })

        const server_response=await ajax.removeVehicleListingItem(item_id);
        if(server_response.status=="OK")
        {
            this.getList();
            this.setState({
                toast: <Toast
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
            })
        }else{
            this.setState({
                toast: <Toast
                        type={4}
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
            })
        }
      }
    

      resetToast = () => {
        this.setState({
            toast: false
        })
      }


      onClickImporList=()=>{
        this.setState({
            model:false
        }, ()=>{
            this.setState({
              model:<PopImportList refresh={this.getList} list={this.state.listInfo.info}/>
            }, ()=>functions.openPopup('modal_import_vehicle_listing'))
            
        })
    }


      listOptions=()=>{
        return <>
           <a className="dropdown-item pointer"><i className="fas fa-file-excel text-success mr-1"></i>Export list</a>
            <a onClick={this.onClickImporList} className="dropdown-item pointer"><i className=" fas fa-external-link-alt text-primary mr-1"></i>Import list</a>    
            <a href={dictionary.apiHost + "vehicle/listing/template"} className="dropdown-item pointer"><i className=" fas fa-table text-danger mr-1"></i>List template...</a>    
            <a href={dictionary.apiHost + "vehicle/listing/template/sticker"} className="dropdown-item pointer"><i className=" fas fa-table text-danger mr-1"></i>Sticker template...</a>    

        </>
      }
    

    render() {
        const listInfo = this.state.listInfo;
        return (
          <>
            {this.state.model}
            {this.state.toast}
              <div className="row">

              {listInfo && <div className="col-12 scroll-widget overflow-auto">
             
                    <div className="card custom-card">
                      
                        <div className="card-body d-flex ml-1 mt-3 mb-3">

                        <div className="table-responsive text-nowrap">
                            <TableHeader
                              title={"Vehicle Listing: " + listInfo.info.title}
                              subtitle={listInfo.info.description}
                              options={<this.listOptions/>}
                            />
                       
                           {!listInfo.items && 
                          <NoContent />
                           }

                          {listInfo.items &&
                          
                          
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Vehicle</th>
                                <th>Client Name</th>
                                <th>Client Contact</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listInfo.items.map((item, key)=>
                                <tr key={key}>
                                  <td>{key+1}</td>
                                  <td>{item.vehicle.plate_number}</td>
                                  <td>{item.vehicle.client.full_name}</td>
                                  <td>{item.vehicle.client.phone_number}</td>
                                  <td>
                                      <a onClick={()=>this.onClickDeleteItem(item.item_id, item.vehicle.plate_number)} class="btn ripple btn-danger btn-sm text-white" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="fe fe-trash-2"></i>
                                      </a>
                                  </td>
                                </tr>
                              )}

                            </tbody>
                            </table>}
                          </div> 
                            
                        </div>
                    </div>
                </div>}
              
              
              
              {!listInfo && <ActivityLoader/>}

            
                
              </div>
            </>
        );
    }
}

export default VehicleListingInfo;