import apiCall from "./apiCall";


export default {


    async fetchReceiptRecords(data)
    {
        let response = await apiCall("finance/tax/efrishistory/list_receipts", data)
        return response;

    },
    async fetchTaxConfig()
    {
        let response = await apiCall("finance/tax/taxconfig/configure_tax", [])
        return response;

    },



    async fetchLastMonthTax()
    {
        let response = await apiCall("finance/tax/efrishistory/last_month_tax", [])
        return response;

    },

    async fetchCurrentMonthTax()
    {
        let response = await apiCall("finance/tax/efrishistory/current_month_tax", [])
        return response;
    },

    async postTaxConfigOn()
    {
        let response = await apiCall("finance/tax/taxconfig/turn_on", [])
        return response;
    },

    async postTaxConfigOff()
    {
        let response = await apiCall("finance/tax/taxconfig/turn_off", [])
        return response;
    },

    async postMonthlyTarget(amount)
    {
        let response = await apiCall("finance/tax/taxconfig/set_monthly_target", {amount:amount})
        return response;
    },

    async postDailyTarget(amount)
    {
        let response = await apiCall("finance/tax/taxconfig/set_daily_target", {amount:amount})
        return response;
    },

    async postEfrisWhiteVehicle(plate_number)
    {
        let response = await apiCall("finance/tax/efriswhitevehicle/add_vehicle", {plate_number:plate_number})
        return response;
    },

    
}