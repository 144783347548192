import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import TitleValueCard from '../../Components/Common/TitleValueCard'
import ConfigMenu from '../../Components/ConfigMenu'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import WarnDelete from '../../Components/WarnDelete'
import $ from 'jquery';
import PopPermissionInput from '../../Components/PopPermissionInput';
import Toast from '../../Components/Common/Toast'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import AlertError from '../../Components/Common/AlertError'
import dictionary from '../../utils/dictionary'
import TableHeader from '../../Components/Common/TableHeader'
import PopPermissionAssign from '../../Components/Usermanagement/PopPermissionAssign'

class RequestedPermission extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        permissionList: false,
        selectedPermissionId: false,
        permissionId: '',
        permissionRecord: false,
        toast: false,

        activeLocationPath: window.location.pathname,
        currentOption: "OPERATIONS",
        loading: false,
        tabOption: false,
        permissionModuleList: false,
        moduleId: '',

        info: false,
        modal: false,
        CreatePermission: '',

        dialogueNewAssignment: false,
    }

    componentDidMount() {
        this.getRequestedPermissions();
        // this.getDepartments();
        // this.getPermissionsModule();

        this.setState({

            CreatePermission: {
                "create_new_permission": () => this.onClickAddPermission()
            },
            modal: <PopPermissionInput
                title="Create New Permission"
                onReload={this.getPermissions}
                onConfirm={this.onSave} />
        });
    }

    getRequestedPermissions = async () => {
        const server_response = await ajax.listAllRequestedPermissions(this.state.userId);
        // console.log(server_response)
        
        if (server_response.status === "OK") {
            this.setState({
                permissionList: server_response.details.content
            })
        } else {
            this.setState({
                permissionList: "404"
            })
        }
    }

    // getDepartments = async () => {
    //     const server_response = await ajax.listDepartments();

    //     if (server_response.status === "OK") {
    //         this.setState({
    //             departmentsList: server_response.details.content
    //         })
    //     } else {
    //         this.setState({
    //             departmentsList: "404"
    //         })
    //     }
    // }

    // getPermissionsModule = async () => {
    //     const server_response = await ajax.listPermissionsByModule(this.state.moduleId);

    //     if (server_response.status === "OK") {
    //         this.setState({
    //             permissionModuleList: server_response.details.content
    //         })
    //     } else {
    //         this.setState({
    //             permissionModuleList: "404"
    //         })
    //     }
    // }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    refresh = () => {
        this.setState({
            loading: true,
            currentOption: 'LOADING'
        }, () => {
            this.setState({
                loading: false,
                currentOption: 'OPERATIONS'
            })
        })
    }

    onClickOption = (option) => {
        this.setState({
            currentOption: option
        })
    }

    onClickAddPermission = () => {
        this.resetToast();
        $("#modal_permission_input").modal('show');
        // $("#modal_permission_input" + this.state.permissionId).modal('show');
    }

    // onClickAdd = () => {
    //     $("#modal_permission_input").modal('show');
    //     // $("#modal_permission_input" + this.state.permissionId).modal('show');
    // }

    onClickTab = (option) => {
        this.setState({
            tabOption: option
        }, () =>
            this.setState({
                tabOption: false
            }, () =>
                this.setState({
                    tabOption: option
                })
            )
        )
    }

    // onSave = async (permission) => {
    //     // event.preventDefault();
    //     // const { permission } = this.state;

    //     if (permission.length > 0) {
    //         //fetch login from the API
    //         const server_response = await ajax.createPermission(permission);
    //         if (server_response.status === "OK") {
    //             this.setState({
    //                 info: <AlertSuccess message={server_response.details.message} />
    //             })
    //             this.setState({
    //                 permission: ''
    //             })

    //         } else {

    //             this.setState({
    //                 info: <AlertError message={server_response.details.message} />
    //             })

    //         }

    //     } else {
    //         this.setState({
    //             info: <AlertError message={dictionary._requirePermission} />,
    //             loading: ''
    //         })
    //     }


    // }

    onSave = async (permission, departmentModuleId, description) => {
        // event.preventDefault();
        // const { permission } = this.state;
        if (permission.length > 0 && departmentModuleId > 0 && description.length) {
            //fetch login from the API
            const server_response = await ajax.createPermission(permission, departmentModuleId, description);
            if (server_response.status === "OK") {
                $("#modal_permission_input").modal('hide');
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                }, () => {
                    this.setState({
                        modal: <PopPermissionInput
                            title="Create New Permission"
                            onReload={this.getPermissions}
                            onConfirm={this.onSave} />

                    },
                        () => this.getPermissions())
                })
            } else {
                $("#modal_permission_input").modal('hide');
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} />
                },
                    () => {
                        this.setState({
                            modal: <PopPermissionInput
                                title="Create New Permission"
                                onReload={this.getPermissions}
                                onConfirm={this.onSave} />

                        })
                    }
                )
            }
        } else {
            this.setState({
                info: <AlertError message={dictionary._requirePermission} />,
                loading: ''
            })
        }
    }

    // onClickRespondToClampRequest = () => {
    //     $("#modal_clamp_input" + this.state.selectedClampId).modal('show');

    // }


    // onClickEdit = () => {

    //     if (this.state.branchRecord) {

    //         this.props.history.push({
    //             pathname: '/config/branches/edit',
    //             search: '?q=1',
    //             state: { record: this.state.branchRecord }
    //         })
    //     }

    // }

    // onClickDelete = () => {
    //     if (this.state.branchRecord) {
    //         $("#modal_delete").modal('show')
    //     }
    // }

    // onConfirmDelete = async () => {

    //     if (this.state.branchId) {
    //         $("#modal_delete").modal('hide');

    //         const server_response = await ajax.deleteBranch(this.state.branchId, this.state.userId);
    //         if (server_response.status === "OK") {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} />
    //             }, () => {

    //                 //hide record
    //                 functions.hide('br_' + this.state.branchId);
    //                 //Refresh list
    //                 this.getBranches();
    //             })
    //         } else {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} />
    //             })
    //         }

    //     }

    // }

    onSelectPermission = (permissionId, permissionRecord) => {
        this.setState({
            permissionId: permissionId,
            permissionRecord: permissionRecord,
            toast: ''
        })
        // this.setState({
        //     menuOptions: {
        //         // "add": () => this.onClickAdd(),
        //         "edit": () => this.onClickEdit(),
        //         "delete": () => this.onClickDelete()
        //     }
        // })
    }

    onClickAssign = () => {
        this.setState({
            dialogueNewAssignment: false
        }, () => {
            this.setState({
                dialogueNewAssignment: <PopPermissionAssign onConfirm={this.onConfirmAssignment} />
            }, () => {

                $("#modal_assign_permission").modal('show');
            })
        })
    }

    onConfirmAssignment = async (roleId, permissionId) => {
        const server_response = await ajax.assign_permission(roleId, permissionId);
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />,
            }, () => {
                $("#modal_assign_permission").modal('hide');

            })
        } else {
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {
                $("#modal_assign_permission").modal('hide');
            })
        }

    }


    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAssign}>
                    <i className="fas fa-map-pin"></i> Assign Permissions
                </button>
            </>
        )
    }

    render() {

        const permissionList = this.state.permissionList;
        const departmentsList = this.state.departmentsList;
        const permissionModuleList = this.state.permissionModuleList;
        const path = this.state.activeLocationPath;

        return (
            <div className="page">
                {/* <WarnDelete
                    title="Are you deleting the permission?"
                    description="Make sure the permission has no roles assigned to it"
                    onConfirm={() => this.onConfirmDelete()} /> */}

                {/* <PopPermissionInput
                    // modalID={this.state.selectedClampId}
                    title="Add a new permission"
                    // clampInfo={this.state.clampRecord}
                    onSave={this.onSave}
                // onReload={this.getClampTransactions}
                // showlocation="showlocation"
                // showclamptool="showclamptool"
                /> */}

                {this.state.modal}
                {this.state.dialogueNewAssignment}

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="All Requests for access"
                            previousPageTitle="All Permissions"
                            back="/config/permissions"
                            {...this.state.CreatePermission}
                            buttons={<this.buttons />}
                            // options={{
                            //     "add": () => this.onClickAddPermission(),
                            //     "edit": () => this.onClickEdit(),
                            //     "delete": () => this.onClickDelete()
                            // }}
                        />

                        {this.state.toast && this.state.toast}

                        <div className="row">

                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                {/* <div>
                                                    <h3 className="card-title mb-2">System Permissions</h3>
                                                </div>
                                                <p className="card-description">
                                                    A tabular representation of all the system permissions showing the role they are attached or assigned to.
                                                </p> */}

                                                <TableHeader
                                                    title="System Requests for Permissions"
                                                    subtitle="A tabular representation of all the system requests for permissions showing who is requesting permissions."
                                                // view="View All"
                                                />

                                                {this.state.toast}

                                                <div className="table-responsive">
                                                    <table className="table text-nowrap mb-0 table-hover">
                                                        <thead>
                                                            <tr>
                                                                {/* <th>No.</th> */}
                                                                <th>Reuested Permission</th>
                                                                <th>Requested by</th>
                                                                {/* <th>Module | Department</th>
                                                                <th>Description</th> */}
                                                                <th>Created on</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {permissionList && permissionList !== "404" &&
                                                                permissionList.map((item, key) =>
                                                                    <tr
                                                                        id={"br_" + item.permission_id}
                                                                        key={key}
                                                                        className={`${this.state.permissionId === item.request_permission_id ? 'flag-row' : ''}`}
                                                                        onClick={() => this.onSelectPermission(item.request_permission_id, item)}>
                                                                        <td><h6 className="mg-b-1">{item.request_permission}</h6></td>
                                                                        {/* <td>Not yet assigned</td> */}
                                                                        <td>
                                                                            <h6 className="mg-b-1">{item.created_by.username}</h6>
                                                                            <small className="tx-12 tx-gray-500">{item.created_by.user_role.role_name}</small>
                                                                        </td>

                                                                        {/* <td>{item.description}</td> */}
                                                                        <td>
                                                                            {item.created_at.date}<br />
                                                                            <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {!permissionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfigMenu />
                        </div>
                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );
    }
}

export default RequestedPermission
