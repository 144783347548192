import React, { useContext, useEffect, useState } from 'react';
import AlertError from '../../Common/AlertError';
import ConfigurationContext from '../../../Context/ConfigurationContext';
import TextInput from '../../Common/TextInput';
import ajax from '../../../utils/ajax';
import ParkingViolationContext from '../../../Context/Violation/ParkingViolationContext';
import dictionary from '../../../utils/dictionary';
import OptionButton from '../../Controls/OptionButton';
import { Link } from 'react-router-dom';
import ajaxViolation from '../../../utils/remote/ajaxViolation';
import AlertSuccess from '../../Common/AlertSuccess';
import functions from '../../../utils/functions';
import ThemeContext from '../../../Context/ThemeContext';


const PopReportClamp=()=> {

    
        const [plateNumber, setPlateNumber] = useState('');
        const [info, setInfo] = useState(false);
        const [searchList, setSearchList] = useState(false);
        const [locationId, setLocationId] = useState('');
        const [violationId, setViolationId] = useState('');
        const [cobraId, setCobraId] = useState('');
        const [toolId, setToolId] = useState('');
        const {setDisplayMessage} = useContext(ThemeContext);
        const [fileName, setFileName] = useState('');
        const [fileContent, setFileContent] = useState('');



        const {locationList, cobraList} = useContext(ConfigurationContext);
        const {violationList, getAllTransactions, availableToolList, getAvailableTools} = useContext(ParkingViolationContext);

        useEffect(()=>{
            searchVehicle()
        },[plateNumber])

   

        const onChangePlateNumber = (event) => {
                setPlateNumber(event.target.value)
            }

        const onConfirm=(event)=>{
            event.preventDefault();
        }

        const onConfirmReport = async (event) => {
            event.preventDefault();
            if (plateNumber.length > 0 && locationId.length>0 && violationId.length>0) {

                const server_response = await ajaxViolation.initializeClamping(plateNumber, violationId, locationId, fileContent);
                if(server_response.status === "OK"){
                    setDisplayMessage(functions.templateMessage("Clamping:", server_response.message, "success"))
                    setInfo(<AlertSuccess message={server_response.message} />)
                    functions.closePopup("modal_clamp");
                    getAllTransactions();
                }else{
                    setInfo(<AlertError message={server_response.message} />)
                }

            } else {
                setDisplayMessage(functions.templateMessage("Clamping:", server_response.message, "warning"))
                setInfo(<AlertError message={dictionary._completeFields} />)
            }
        }

        const onChangeImage = (event) => {

            setFileName(event.target.value);
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                setFileContent(e.target.result)
            }
    
        }


        const onConfirmClamp = async (event) => {
            event.preventDefault();
            if (plateNumber.length > 0 && locationId.length>0 && violationId.length>0) {

                if(cobraId.length>0 && toolId.length>0){

                    const server_response = await ajaxViolation.directlyClamp(plateNumber, violationId, locationId, cobraId, toolId, fileContent);
                    if(server_response.status === "OK"){
                        setInfo(<AlertSuccess message={server_response.message} />)
                        functions.closePopup("modal_clamp");
                        setDisplayMessage(functions.templateMessage("Clamping:", server_response.message, "success"))
                        getAllTransactions();
                    }else{
                        setInfo(<AlertError message={server_response.message} />)
                        setDisplayMessage(functions.templateMessage("Clamping:", server_response.message, "warning"))
                    }

                }else{
                    setInfo(<AlertError message={"Attach cobra and tool, then try again"} />)
                }

            } else {
                setInfo(<AlertError message={dictionary._completeFields} />)
            }
        }



    const optionsLocations = [];
    if(Array.isArray(locationList)){
        locationList.forEach(element => {
            optionsLocations.push({label:element.location_name + " - " + element.street.street_name, value:element.location_id})
        });
    }

    const optionViolations = [];
    if(Array.isArray(violationList)){
        violationList.forEach(element=>{
            optionViolations.push({label:element.violation_name + "(" + element.cause.cause +")", value:element.id})
        })
       
    }

    const optionToolList = [];
    if(Array.isArray(availableToolList)){
        availableToolList.forEach(element=>{
            optionToolList.push({label:element.clamp_number, value:element.clamp_tool_id})
        })
    }


    const optionCobraList = [];
    if(Array.isArray(cobraList)){
        cobraList.forEach(element=>{
            optionCobraList.push({label:element.full_name, value:element.user_id})
        })
    }

   const searchVehicle = async () => {
        const server_response = await ajax.searchVehicle(plateNumber);
        if (server_response.status === "OK") {
            setSearchList(server_response.details.content)
           
        } else {
            setSearchList(false)
        }
    }


    
        return (
            <div className="modal" id="modal_clamp">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Vehicle Clamp</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      
                                <TextInput
                                    type="select"
                                    label="Location"
                                    onChange={(option) => setLocationId(option.value)}
                                    options={optionsLocations}
                                    placeholder={"Select clamp location..."}
                                    name="location"
                                    required
                                />
                                 

                                <TextInput
                                    label="Vehicle"
                                    placeholder="Enter Vehicle Number"
                                    type="text"
                                    onChange={(e)=>setPlateNumber(e.target.value)}
                                    value={plateNumber}
                                    name="vehicleNumber"
                                    required
                                />

                                <TextInput
                                    type="select"
                                    label="Violation"
                                    onChange={(option) => setViolationId(option.value)}
                                    options={optionViolations}
                                    placeholder={"Select violation..."}
                                    name="violation"
                                    required
                                />



                                <TextInput
                                    type="select"
                                    label="Field cobra"
                                    onChange={(option) => setCobraId(option.value)}
                                    options={optionCobraList}
                                    placeholder={"Select cobra..."}
                                    name="Cobra"
                                />

                                <TextInput
                                    type="select"
                                    label="Clamp Tool"
                                    onChange={(option) => setToolId(option.value) }
                                    options={optionToolList}
                                    placeholder={"Select clamp tool..."}
                                    name = "clampTool"
                                /> 

                                 <TextInput
                                    type="file"
                                    label="Evidence"
                                    onChange={onChangeImage}
                                    placeholder={"Select photo"}
                                    name="evidence"
                                    value={fileName}
                                /> 


                            </form>

                            {searchList && searchList !== "404" && <div className="btn-list">
                                {searchList.map((item, key) =>
                                    <button key={key}
                                        onClick={() => setPlateNumber(item.plate_number)}
                                        type="button"
                                        className="btn ripple btn-light btn-sm">
                                        {item.plate_number}
                                    </button>)}
                            </div>}


                        </div>
                        <div className="modal-footer">

                            <OptionButton title="Clamp Action" theme="primary">
                                <Link to="#" className="dropdown-item" onClick={onConfirmReport}>Report Clamp</Link>
                                <Link to="#" className="dropdown-item" onClick={onConfirmClamp}>Clamp Vehicle</Link>
                                <Link to="#" className="dropdown-item" onClick={getAvailableTools}>Refresh Tools</Link>


                            </OptionButton>
                           
                           
                            <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopReportClamp;
