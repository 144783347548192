import React from 'react';
import { RenderSecure } from '../../../utils/script/RenderSecure';


class PopManageBankDeposit extends React.Component {
  state={
      loading:false,
      transactionRecord:this.props.record,
      verification:false,
      toast:false
  }


 
  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  
//   onClickCancel=()=>{
//           this.setState({
//               verification:<PopVerifyAction
//                 title="Account Authentication"
//                 description="Enter your account password to cancel this topup request"
//                 requestId={this.state.transactionRecord.request_id}
//                 action={"CANCEL-TOPUP-REQUEST"}
//               />
//           }, ()=>{
//             functions.closePopup('mdl-topup-approval');
//             functions.openPopup('pop-verify-action');

//           })
//   }

//   onClickApprove=()=>{
//     this.setState({
//         verification:<PopVerifyAction
//           title="Account Authentication"
//           description="Enter your account password to approve topup request"
//           requestId={this.state.transactionRecord.request_id}
//           action={"APPROVE-TOPUP-REQUEST"}
//         />
//     }, ()=>{
//       functions.closePopup('mdl-topup-approval');
//       functions.openPopup('pop-verify-action');

//     })
// }

// onClickProcess=()=>{
//     const {accountId}=this.state
  
//         this.setState({
//             verification:<PopVerifyAction
//             title="Account Authentication"
//             description="Enter your account password to process topup request"
//             requestId={this.state.transactionRecord.request_id}
//             action={"PROCESS-TOPUP-REQUEST"}
//             accountId={this.state.accountId}
//             />
//         }, ()=>{
//         functions.closePopup('mdl-topup-approval');
//         functions.openPopup('pop-verify-action');

//         })
   
// }



  resetToast=()=>{
    this.setState({
        toast:false
    })
}

  render(){
    const transactionList=this.state.transactionRecord;
   
      return (
      <>
        {this.state.verification}
        
        <div className="modal" id="mdl-manage-bank-deposit">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Manage Bank Deposit</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    {this.state.toast}
                </div>
                <div className="modal-body">



                            {transactionList && transactionList!=="404" &&
                            <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                       
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Amount</label> 
                                                <span className="tx-medium">{transactionList.amount.amount_c}</span>
                                            </div>
                                            <div>
                                                <label>Status</label> 
                                                <span className="tx-medium">{transactionList.status.status}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Record Date</label> 
                                                <span className="tx-medium">{transactionList.created_at.long_date}</span>
                                            </div>
                                           
                                            <div>
                                                <label>Bank Deposit Date</label> 
                                                <span className="tx-medium">{transactionList.deposit_date.short_date}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Beneficiary Account</label> 
                                                <span className="tx-medium">{transactionList.local_account.username}</span>
                                            </div>

                                            <div>
                                                <label>Beneficiary Contact</label> 
                                                <span className="tx-medium">{transactionList.local_account.phone_number}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                        


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Bank Name</label> 
                                                <span className="tx-medium">{transactionList.bank.bank_name}</span>
                                            </div>
                                            <div>
                                                <label>Bank Ref.</label> 
                                                <span className="tx-medium">{transactionList.bank_ref}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">

                                                <div>
                                                    <label>Created By</label> 
                                                    <span className="tx-medium">{transactionList.created_by.last_name + ' ' + transactionList.created_by.first_name}</span>
                                                </div>
                                                <div>
                                                    <label>Last updated on</label> 
                                                    <span className="tx-medium">{transactionList.updated_at.short_date + ' ' + transactionList.updated_at.time}</span>
                                                </div>
                                           
                                            </div>
                                        </div>



                                        <div className="media">
                                            <div className="media-body">

                                            <div>
                                                <label>Approved By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.approved_by?transactionList.approved_by.last_name + ' ' + transactionList.approved_by.first_name:'Not Approved'}
                                                </span>
                                            </div>

                                            <div>
                                                <label>Processed By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.processed_by?transactionList.processed_by.last_name + ' ' + transactionList.processed_by.first_name:'Not Processed'}
                                                </span>
                                            </div>
                                    
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">

                                            {transactionList.cancelled_by && <div>
                                                <label>Cancelled By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.cancelled_by.last_name + ' ' + transactionList.cancelled_by.first_name}
                                                </span>
                                            </div>}

                                          
                                    
                                            </div>
                                        </div>
                                      
                                      
               
                                        </div>
                                    </div>
                                    </div>
                                </div>}


                              
                            
                                
                </div>
                <div className="modal-footer">
                {transactionList.status.status_id*1!==2 && transactionList.status.status_id*1!==3 && 
                   <RenderSecure code="D-PAY-BANKSLIP-01">
                   <input 
                        className="btn ripple btn-danger" 
                        type="button" 
                        value="Cancel Request" 
                        onClick={()=>this.props.handleApproval(2)}/></RenderSecure>}
                    {transactionList.status.status_id*1===0 && <RenderSecure code="U-PAY-BANKSLIP-03">
                    <input 
                        className="btn ripple btn-primary" 
                        type="button" value={transactionList.next_status} 
                        onClick={()=>this.props.handleApproval(1)}/></RenderSecure>}

                    {transactionList.status.status_id*1===1 && <RenderSecure code="U-PROCESS-BANKSLIP-02">
                    <input 
                        className="btn ripple btn-success" 
                        type="button" value={transactionList.next_status} 
                        onClick={()=>this.props.handleApproval(3)}/></RenderSecure>}

                   
                </div>
            </div>
        </div>
    </div>
    </>
     )
    
 
  }
}

export default PopManageBankDeposit;