import React from 'react';
import ModalBox from '../Common/ModalBox';

const PopLinkingOption =(props)=>{

   
        return <ModalBox id="pop-linking-options" title="Vehicle Linking" cancel>
                <div className="list-group">
                    <a className="list-group-item list-group-item-action">
                        <b>Choose linking option</b>
                    </a>
                    <a onClick={props.handleSingle} className="list-group-item list-group-item-action" href="#">Link single vehicle</a>
                    <a onClick={props.handleBulky} className="list-group-item list-group-item-action" href="#">Bulky Linking</a>
                </div>
              </ModalBox>     
        
}

export default PopLinkingOption;
