import React, { createContext, Component, useEffect, useState } from 'react';
import dictionary from '../../utils/dictionary';
import ajaxReports from '../../utils/remote/ajaxReports';
import { detectTable, restoreTable } from '../../utils/script/customTables';



const eformat = dictionary._exportBtnFormats;
const OccupancyContext = React.createContext({});
export const OccupancyConsumer = OccupancyContext.Consumer;


export const OccupancyProvider = (props) => {

    const [occupancyList, setVehicleOccupancy] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [data, setdata] = useState({ "page": 1, "street_id": "", "date_from": "", "date_to": "", "location_id": "" });
    const [metaData, setMetaData] = useState(false)

    useEffect(() => {
        getVehicleOccupancy();

    }, [data])

    useEffect(() => {
        if (currentPage > 1) {
            fetchMoreTrigger()
        }
    }, [currentPage])


    const fetchMoreTrigger = () => {
        const newData = {
            ...data,
            "page": currentPage,
        }
        fetchMore(newData)
    }


    const filterData = (options) => {
        setCurrentPage(1);
        setdata({
            "page": 1,
            "street_id": options.selectedValue,
            "date_from": options.dateFrom,
            "date_to": options.dateTo,
            "location_id": options.selectedValue6
        })
    }

    const resetData = () => {
        setCurrentPage(1)
        setdata({ "page": 1, "street_id": "", "date_from": "", "date_to": "", "location_id": "" })
    }

    const getVehicleOccupancy = async () => {
        setLoading(true)
        setMetaData(false)
        setVehicleOccupancy(false)
        const server_response = await ajaxReports.listVehicleOccupancy(data)
        setLoading(false)
        if (server_response.status === 'OK') {
            setVehicleOccupancy(server_response.details.content.list)
            setMetaData(server_response.details.content.meta)
        }
        else {
            setVehicleOccupancy("404")
            setMetaData(false)
        }

    }

    /**Fethes more data from the server 
     * and appends it to already existing data */
    const fetchMore = async (newData) => {
        setLoading(true)
        restoreTable(detectTable())
        const server_response = await ajaxReports.listVehicleOccupancy(newData);
        setLoading(false)
        if (server_response.status === "OK") {

            const incoming = Array.from(server_response.details.content.list);
            setVehicleOccupancy(occupancyList.concat(incoming));
            setMetaData(server_response.details.content.meta)
        }
    }


    const goToNextPage = () => {
        if (currentPage < metaData.total_pages) {
            setCurrentPage(currentPage + 1);
        }
    }


    const getFreshObject = () => {

        return [{
            "street": ""
        },

        {
            "branch": ""
        },
        {
            "dateFrom": ""
        },
        {
            "dateTo": ""
        }
        ]
    }







    return (
        <OccupancyContext.Provider value={{
            getVehicleOccupancy,
            occupancyList,
            fetchMore,
            loading,
            metaData,
            filterData,
            resetData,
            goToNextPage
        }}>
            {props.children}
        </OccupancyContext.Provider>
    );


}
export default OccupancyContext;