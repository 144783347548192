import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import DashCard from '../../Components/Cards/OperationCards/DashCard'
import RecentSessions from '../../Components/RecentSessions'
import StreetLocations from '../../Components/Location/StreetLocations'
import WeeklyStreetOccupancyGraph from '../../Components/Location/WeeklyStreetOccupancyGraph'

class StreetOccupancy extends React.Component {

    state = {
       streetId:'',
       spaceAvailability:false,
       streetInfo:false
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/parking-space/available-space');
          } else {
            this.setState({
              streetId: this.props.location.state.streetId
            }, () => {
                this.getStreet();
                this.getSpaceAvailability();
            })
          }
    }


    getSpaceAvailability = async () => {
        const server_response = await ajax.getStreetSpaceAvailability(this.state.streetId);
        if (server_response.status === "OK") {
            this.setState({
                spaceAvailability: server_response.details.content
            })
        } else {
            this.setState({
                spaceAvailability: "404"
            })
        }
    
    }
    

    getStreet = async () => {
        const server_response = await ajax.getStreet(this.state.streetId);
        if (server_response.status === "OK") {
            this.setState({
                streetInfo: server_response.details.content
            })
        }
    
    }

    ViewAllSessions = () => {
        this.props.history.push('/parking_sessions/sessions')
      }
    

    render() {
        const space=this.state.spaceAvailability;
        const streetInfo=this.state.streetInfo;
        return (
            <div className="page">

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                       
                       {streetInfo && <>
                        <PageHeader
                            title={streetInfo.street_name}
                            previousPageTitle="Available Space"
                            back="/parking-space/available-space"
                        />

                       {this.state.spaceAvailability && this.state.spaceAvailability!=="404" && 
                            <div className="row">

                            <DashCard className="col-sm-6 col-md-4 col-xl-3"
                                title="Available Parking"
                                icon="fas fa-hourglass-start"
                                //href="/parking-space/available-space"
                                value={this.state.spaceAvailability.available_slots.total_p}
                                color="icon-service rounded-circle bg-success-transparent text-success"
                            />

                            <DashCard className="col-sm-6 col-md-4 col-xl-3"
                                title="Occupied Slots"
                                icon="fas fa-hourglass-start"
                               // href="/parking-space/available-space"
                                value={this.state.spaceAvailability.used_slots.total_p}
                                color="icon-service rounded-circle bg-danger-transparent text-danger"
                            />

                             <DashCard className="col-sm-6 col-md-4 col-xl-3"
                                title="Reserved Slots"
                                icon="fas fa-hourglass-start"
                               // href="/parking-space/available-space"
                                value={this.state.spaceAvailability.reserved.total_p}
                                color="icon-service rounded-circle bg-primary-transparent text-primary"
                            />

                              <DashCard className="col-sm-6 col-md-4 col-xl-3"
                                title="Total Slots"
                                icon="fas fa-hourglass-start"
                                href="/parking-space/available-space"
                                value={this.state.spaceAvailability.total_slots.total_p}
                                color="icon-service rounded-circle bg-secondary-transparent text-secondary"
                            />

                        </div>}

                        <div className="row">
                            <div className="col-sm-12 col-xl-8 col-lg-8">

                             <WeeklyStreetOccupancyGraph streetId={this.state.streetId}/>
                             <RecentSessions streetId={this.state.streetId} onClickViewAll={this.ViewAllSessions} />

                            </div>
                            <div className="col-sm-12 col-xl-4 col-lg-4">
                                <StreetLocations streetId={this.state.streetId}/>

                            </div>


                        </div>
                        </>}
                 

                    {!this.state.streetInfo && <ActivityLoader/>}

                    </div>
                </div>
                
                <RightSideBar />
                 
            </div>

        );

    }
}

export default StreetOccupancy
