import React, { createContext, Component } from 'react';
import functions from '../../utils/functions';
import $ from 'jquery';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';
import { detectTable, restoreTable } from '../../utils/script/customTables';
import ajaxClamping from '../../utils/remote/ajaxClamping';
import ActivityLoader from '../../Components/Common/ActivityLoader';



const eformat = dictionary._exportBtnFormats;
const ClampingContext = React.createContext({});
export const ClampingConsumer = ClampingContext.Consumer;


export class ClampingProvider extends Component {

    state = {
        userId: functions.sessionGuard(),
        clampRecord: false,
        clampList: "",
        clampsFiteredByStatusList: "",
        selectedClampId: '',
        modal: false,
        buttonName: '',
        showlocation: '',
        clampOptions: "",
        ClampingLocation: "",
        clampedById: '',
        transactionId: "",
        ClampToolId: "",
        showclamptool: "",
        ClampingStatusId: "",
        ClampingStatusList: "",
        toast: false,

        metaData: false,
        currentPage: 1,
        clampPreview: "",
        pendingClamps: 1,
        respondingClamps: 2,
        unUsedClampToolList: false,

        recentClamps: false,
        towByStatusList: "",
        metaDataList: "",
        TowingStatusId: "",
        isParked: "",
        loading: false,
        towLoading: false,

        countPendingClamps: '...',
        countRespondedClamps: '...',
        countClamped: '...',
        countPendingUnClamps: '...',
        countRespondedUnClamps: '...',
        countUnClamped: '...',
        countAllClamps: '...',

        clampAbortList: "",
        abortMetaData: "",
        abortCurrentPage: 1,
        filterClampStatus: "",
        clampsByStatusCustomItemsPerPage: "",
        ClampsByStatusMetaData: "",
        clearedBeforeTow: ""



    };


    componentDidMount() {
        this.getClampTransactions();
        this.getClampTrasactionsByStatus(this.state.ClampingStatusId, this.state.isParked, this.state.currentPage, this.state.clampsByStatusCustomItemsPerPage);
        this.getPendingClampTrasactions();
        this.getUnUsedClampTools();
        this.getRecentClampTransactions();
        this.getTowTransactionsByStatus(this.state.TowingStatusId, this.state.clearedBeforeTow);
        this.getCountClampedVehicles();
        this.getCountAllClampActivity();
        this.getAbortedClamps(this.state.cobraAborts, this.state.adminAborts, this.state.systemAborts, this.state.abortCurrentPage, this.state.clampsByStatusCustomItemsPerPage);

    }

    getUnUsedClampTools = async () => {
        const server_response = await ajax.listClampToolsByStatus(0);
        if (server_response.status === 'OK') {
            this.setState({
                unUsedClampToolList: server_response.details.ClampTools
            })
        }
        else {
            this.setState({
                unUsedClampToolList: "404"
            })
        }

    }

    getClampTransactions = async () => {
        const server_response = await ajax.listClampTransactions(this.state.currentPage);
        if (server_response.status === 'OK') {
            this.setState({
                clampList: server_response.details.clamp_transactions.list,
                metaData: server_response.details.clamp_transactions.meta
            })
        }
        else {
            this.setState({
                clampList: "404",
                metaData: false
            })
        }

    }


    getCountClampedVehicles = async () => {

        const server_response = await ajax.countClampedVehicles();
        if (server_response.status === "OK") {
            // countClampedVehicles(server_response.details.content.total_c)
            this.setState({
                countClamped: server_response.details.content.total_c
            })
        }
        else {
            this.setState({
                countClamped: "404",
            })
        }
    }


    getCountAllClampActivity = async () => {

        const server_response = await ajaxClamping.countAllClampActivity();
        if (server_response.status === "OK") {
            // countClampedVehicles(server_response.details.content.total_c)
            this.setState({
                countAllClamps: server_response.details.content.total_c
            })
        }
        else {
            this.setState({
                countAllClamps: "404",
            })
        }
    }


    getRecentClampTransactions = async () => {
        const server_response = await ajaxClamping.listRecentClamps();
        if (server_response.status === 'OK') {
            this.setState({
                recentClamps: server_response.details.clamp_transactions.list,
            })
        }
        else {
            this.setState({
                recentClamps: "404",
            })
        }

    }


    getClampTrasactionsByStatus = async (ClampingStatusId, isParked, currentPage, clampsByStatusCustomItemsPerPage) => {


        this.setState({ loading: true, clampsFiteredByStatusList: false, ClampsByStatusMetaData: false });

        const server_response = await ajax.listClampTransactionsByStatus(ClampingStatusId, isParked, currentPage, clampsByStatusCustomItemsPerPage);
        this.setState({ loading: false });

        if (server_response.status === 'OK') {

            this.setState({
                clampsFiteredByStatusList: server_response.details.clamp_transactions.list,
                ClampsByStatusMetaData: server_response.details.clamp_transactions.meta,

            })
        }
        else {
            this.setState({
                clampsFiteredByStatusList: "404",
                ClampsByStatusMetaData: false
            })
        }

    }


    getAbortedClamps = async (cobraAborts, adminAborts, systemAborts, abortCurrentPage, clampsByStatusCustomItemsPerPage) => {
        this.setState({ loading: true, clampAbortList: false });

        const server_response = await ajaxClamping.listAbortedClamps(cobraAborts, adminAborts, systemAborts, abortCurrentPage, clampsByStatusCustomItemsPerPage);
        this.setState({ loading: false });

        if (server_response.status === 'OK') {

            this.setState({
                clampAbortList: server_response.details.clamp_transactions.list,
                abortMetaData: server_response.details.clamp_transactions.meta,

            })
        }
        else {
            this.setState({
                clampAbortList: "404",
                abortMetaData: false
            })
        }

    }

    // getClampTrasactionsByStatus = async () => {
    //     const server_response = await ajax.listClampTransactionsByStatus(this.state.ClampingStatusId, this.state.currentPage);
    //     if (server_response.status === 'OK') {
    //         this.setState({
    //             clampsFiteredByStatusList: server_response.details.clamp_transactions.list,
    //             metaData: server_response.details.clamp_transactions.meta
    //         })
    //     }
    //     else {
    //         this.setState({
    //             clampsFiteredByStatusList: "404",
    //             metaData: false
    //         })
    //     }

    // }


    getPendingClampTrasactions = async () => {
        const server_response = await ajax.listClampTransactionsByStatus(this.state.pendingClamps, this.state.currentPage);
        if (server_response.status === 'OK') {
            this.setState({
                clampsFiteredByStatusList: server_response.details.clamp_transactions.list,
                metaData: server_response.details.clamp_transactions.meta
            })
        }
        else {
            this.setState({
                clampsFiteredByStatusList: "404",
                metaData: false
            })
        }

    }


    getRespondingClampTrasactions = async () => {
        const server_response = await ajax.listClampTransactionsByStatus(this.state.pendingClamps, this.state.currentPage);
        if (server_response.status === 'OK') {
            this.setState({
                clampsFiteredByStatusList: server_response.details.clamp_transactions.list,
                metaData: server_response.details.clamp_transactions.meta
            })
        }
        else {
            this.setState({
                clampsFiteredByStatusList: "404",
                metaData: false
            })
        }

    }

    /**Fethes more data from the server and appends it to already existing data */
    fetchMore = () => {
        const { filter, paymentMethod, currentPage, LocationList } = this.state;

        this.setState({
            currentPage: currentPage + 1,
            loading: true
        })

        setTimeout(async () => {

            // if (filter) {
            //     var server_response = await ajax.filterSpaceInventory(
            //         filter.selectedValue,
            //         filter.dateFrom,
            //         filter.dateTo,
            //         filter.selectedValue1,
            //         this.state.currentPage
            //     );
            // }

            const server_response = await ajax.listClampTransactions(this.state.currentPage);
            // }

            this.setState({
                loading: false
            }, () => restoreTable(detectTable()))

            if (server_response.status === "OK") {

                const incoming = Array.from(server_response.details.clamp_transactions.list);

                this.setState({
                    clampList: this.state.clampList.concat(incoming),
                    metaData: server_response.details.clamp_transactions.meta,
                    loadTable: true
                })

            }

        }, 10);

    }

    getTowTransactionsByStatus = async (TowingStatusId, clearedBeforeTow) => {
        // const { TowingStatusId } = this.state;
        this.setState({ towLoading: true, towByStatusList: false, currentPage: 1 });
        const server_response = await ajax.listTowTransactionsByStatus(TowingStatusId, 1, clearedBeforeTow);
        this.setState({ towLoading: false });

        if (server_response.status === 'OK') {
            this.setState({
                towByStatusList: server_response.details.tow_transactions.list,
                metaDataList: server_response.details.tow_transactions.meta

            })
        }
        else {
            this.setState({
                towByStatusList: "404",
                metaDataList: false

            })
        }

    }

    /**Fethes more data from the server and appends it to already existing data */
    fetchMoreTowRecords = (TowingStatusId, clearedBeforeTow) => {
        const { filter, paymentMethod, currentPage, towByStatusList } = this.state;

        this.setState({
            currentPage: currentPage + 1,
            towLoading: true
        })

        setTimeout(async () => {


            // if (filter) {
            //     var server_response = await ajax.filterSpaceInventory(
            //         filter.selectedValue,
            //         filter.dateFrom,
            //         filter.dateTo,
            //         filter.selectedValue1,
            //         this.state.currentPage
            //     );
            // }

            const server_response = await ajax.listTowTransactionsByStatus(TowingStatusId, this.state.currentPage, clearedBeforeTow);
            // }

            this.setState({
                towLoading: false
            }, () => restoreTable(detectTable()))

            if (server_response.status === "OK") {
                const incoming = Array.from(server_response.details.tow_transactions.list);

                this.setState({
                    towByStatusList: towByStatusList.concat(incoming),
                    metaDataList: server_response.details.tow_transactions.meta,
                    loadTable: true
                })

            }

        }, 10);

    }



    render() {

        return (
            <ClampingContext.Provider value={{
                ClampingStatusId: this.state.ClampingStatusId,
                clampList: this.state.clampList,
                clampsFiteredByStatusList: this.state.clampsFiteredByStatusList,
                getPendingClampTrasactions: this.getPendingClampTrasactions,
                fetchMore: this.fetchMore,
                loading: this.state.loading,
                getClampTransactions: this.getClampTransactions,
                unUsedClampToolList: this.state.unUsedClampToolList,
                getUnUsedClampTools: this.getUnUsedClampTools,
                getClampTrasactionsByStatus: this.getClampTrasactionsByStatus,
                recentClamps: this.state.recentClamps,
                getRecentClampTransactions: this.getRecentClampTransactions,
                towByStatusList: this.state.towByStatusList,
                TowingStatusId: this.state.TowingStatusId,
                metaDataList: this.state.metaDataList,
                getTowTransactionsByStatus: this.getTowTransactionsByStatus,
                isParked: this.state.isParked,
                towLoading: this.state.towLoading,

                countPendingClamps: this.state.countPendingClamps,
                countRespondedClamps: this.state.countRespondedClamps,
                countClamped: this.state.countClamped,
                countPendingUnClamps: this.state.countPendingUnClamps,
                countRespondedUnClamps: this.state.countRespondedUnClamps,
                countUnClamped: this.state.countUnClamped,
                countAllClamps: this.state.countAllClamps,
                getCountClampedVehicles: this.getCountClampedVehicles,
                getCountAllClampActivity: this.getCountAllClampActivity,
                metaData: this.state.metaData,
                clampAbortList: this.state.clampAbortList,
                getAbortedClamps: this.getAbortedClamps,
                abortMetaData: this.state.abortMetaData,
                abortCurrentPage: this.state.abortCurrentPage,
                ClampsByStatusMetaData: this.state.ClampsByStatusMetaData,
                clearedBeforeTow: this.state.clearedBeforeTow,
                fetchMoreTowRecords: this.fetchMoreTowRecords,
                metaDataList: this.state.metaDataList
            }}>
                {this.props.children}
            </ClampingContext.Provider>
        );
    }

}



export default ClampingContext;