import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopSessionInfo from '../../../Components/PopSessionInfo'
import Toast from '../../../Components/Common/Toast'
import TableHeader from '../../../Components/Common/TableHeader'
import PageNav from '../../../Components/Common/PageNav'
import { Link } from 'react-router-dom'
import Export from '../../../Components/Common/Export'
import CustomizedFilterBar from '../../../Components/Common/CustomizedFilterBar'
import dictionary from '../../../utils/dictionary'
import LoadMoreButton from '../../../Components/Other/LoadMoreButton'
import ajaxClamping from '../../../utils/remote/ajaxClamping'
import AlertError from '../../../Components/Common/AlertError'
import ConfigurationContext from '../../../Context/ConfigurationContext'
import CountButton from '../../../Components/Filters/CountButton'
import NoContent from '../../../Components/Common/NoContent'
import ajaxUnclamping from '../../../utils/remote/ajaxUnclamping'
import PopClampHistory from '../../../Components/Clamps/PopClampHistory'
const eformats = dictionary._exportBtnFormats


class UserPerformanceReport extends React.Component {
    static contextType = ConfigurationContext;


    state = {
        userId: functions.sessionGuard(),
        currentPage: 1,
        metaData: false,
        toast: false,
        pagination: false,
        filter: false,
        userClampHistroryList: false,
        locationId: false,
        locationRecord: false,
        toast: '',

        weekend: false,
        weekday: false,
        streets: "",
        Locations: "",
        page: "",
        userId: "8",
        clampPreview: "",
        info2: "",
        clampedCount: "",
        clampPreview: "",
        clampedStatus: "3",
        respondedStatus: "2",
        penndingUnclampStatus: "4",
        clampedStatus: "3",
        respondedUnclampsStatus: "5",
        unClampStatus: "6",
        // clampCount: "----",
        respondedCount: "",
        paidCount: "",
        firstName: "",
        pagination: "",
        metaData: "",
        page: "",
        roleCode: "",
        respondedUnClampsCount: "",
        unClampsCount: "",
        clampPreview: "",
        createdFrom: "",
        createdTo: ""

    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                userId: this.props.location.state.userID,
                firstName: this.props.location.state.firstName,
                roleCode: this.props.location.state.roleCode,
                createdFrom: this.props.location.state.createdFrom,
                createdTo: this.props.location.state.createdTo

            }, () => {
                this.getUserClampHistory();
                this.countCobraClamps();
                this.countRespondedClamps();
                this.countPaidClamps();
                this.countrespondedUnclamps();
                this.countUnClamps();
            })
        } else {
            this.props.history.push('/reports/cobra-performance');
        }


        // const { listspaceInventory
        // } = this.context;
        // listspaceInventory();
    }


    buttons = () => {
        return (
            <>
                {/* {this.state.filter && <button className="btn ripple btn-primary" onClick={this.resetFilter}>
          Reset
        </button>} */}
                <ol className="customButtonList">

                    <li>

                        <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                        </a>
                    </li>
                    <li>

                        {/* {!this.state.filter && <Export
                            excelUrl={"location/export"}
                            pdfUrl={"space-inventory/export/pdf?"}
                        />} */}


                    </li>
                </ol>


            </>
        )
    }

    onClickRef = (record) => {
        this.setState({
            clampPreview: false
        }, () => {
            this.setState({
                clampPreview: <PopClampHistory clampInfo={record} />
            }, () => {
                $("#modal_clampHistory_info").modal('show');
            })

        })

    }



    onFilter = async (filter) => {
        const { onFilter } = this.context;

        // this.setState({
        //   filter: false,
        //   userClampHistroryList: false,
        //   metaData: false
        // }, () => {
        //   this.setState({ filter: filter }, () => getLocations())
        // })
        onFilter(filter)

    }


    onFilter = (filter) => {
        this.setState({
            filter: "",
            userClampHistroryList: false,
            metaData: false

        }, () =>
            this.setState({
                filter: filter,
                metaData: false
            }, () => this.getUserClampHistory(filter)))

    }

    resetFilter = () => {
        this.setState({
            filter: "",
            userClampHistroryList: false,
            metaData: false

        }, () => this.getUserClampHistory())

    }

    getUserClampHistory = async (filter) => {
        const { userId, roleCode, createdFrom, createdTo,
            respondedStatus,
            penndingUnclampStatus,
            respondedUnclampsStatus,
            unClampStatus } = this.state


        this.setState({
            userClampHistroryList: false,
            metaData: false,
            loading: true
        })



        if (this.state.filter) {
            if (roleCode == "COBRA") {
                var server_response = await ajaxClamping.userClampHistory(userId, this.state.currentPage, "", filter.selectedValue, filter.dateFrom, filter.dateTo);

            }
            if (roleCode == "ZULU") {
                var server_response = await ajaxClamping.userClampHistory("", this.state.currentPage, userId, filter.selectedValue, filter.dateFrom, filter.dateTo);

            }
        } else {

            // CHECK IF THE USER IS A COBRA OR ZULU

            if (roleCode == "COBRA") {
                var server_response = await ajaxClamping.userClampHistory(userId, this.state.currentPage, "", "", createdFrom, createdTo, respondedStatus, penndingUnclampStatus);

            }
            if (roleCode == "ZULU") {
                var server_response = await ajaxClamping.userClampHistory("", this.state.currentPage, userId, "", createdFrom, createdTo, respondedUnclampsStatus, unClampStatus);

            }

        }
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            this.setState({
                userClampHistroryList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => {
                this.paginate();
                this.countCobraClamps(filter);
                this.countRespondedClamps(filter);
                this.countPaidClamps(filter);
                this.countrespondedUnclamps(filter)
                this.countUnClamps(filter);
            })
        } else {
            this.setState({
                userClampHistroryList: "404",
                info2: <AlertError message={server_response.details.message} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }



    getCountData = () => {


    }


    countCobraClamps = async (filter) => {

        const { userId, clampedStatus, createdFrom, createdTo } = this.state;
        this.setState({
            clampedCount: "0",
            loading: true
        })

        if (this.state.filter) {
            var clamped_response = await ajaxClamping.countCobraClamps(userId, clampedStatus, filter.dateFrom, filter.dateTo);
        } else {
            var clamped_response = await ajaxClamping.countCobraClamps(userId, clampedStatus, createdFrom, createdTo);
        }

        this.setState({
            loading: false
        })

        if (clamped_response.status === "OK") {
            this.setState({
                clampedCount: clamped_response.details.content.total_c,

            })
        } else {
            this.setState({
                info2: <AlertError message={clamped_response.details.total_c} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }




    countRespondedClamps = async (filter) => {

        const { userId, respondedStatus, createdFrom, createdTo } = this.state;
        this.setState({
            respondedCount: "0",
            loading: true
        })

        if (this.state.filter) {
            var responded_response = await ajaxClamping.countCobraClamps(userId, respondedStatus, filter.dateFrom, filter.dateTo);
        } else {
            var responded_response = await ajaxClamping.countCobraClamps(userId, respondedStatus, createdFrom, createdTo);
        }

        this.setState({
            loading: false
        })

        if (responded_response.status === "OK") {
            this.setState({
                respondedCount: responded_response.details.content.total_c,
            })
        } else {
            this.setState({
                info2: <AlertError message={responded_response.details.total_c} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }





    countPaidClamps = async (filter) => {

        const { userId, penndingUnclampStatus, createdFrom, createdTo } = this.state;


        this.setState({
            paidCount: "0",
            loading: true
        })

        if (this.state.filter) {
            var paid_response = await ajaxClamping.countCobraClamps(userId, penndingUnclampStatus, filter.dateFrom, filter.dateTo);

        } else {
            var paid_response = await ajaxClamping.countCobraClamps(userId, penndingUnclampStatus, createdFrom, createdTo);

        }
        this.setState({
            loading: false
        })

        if (paid_response.status === "OK") {
            this.setState({
                paidCount: paid_response.details.content.total_c,
            })
        } else {
            this.setState({
                info2: <AlertError message={paid_response.details.total_c} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }

    countrespondedUnclamps = async (filter) => {

        const { userId, respondedUnclampsStatus, createdFrom, createdTo } = this.state;


        this.setState({
            respondedUnClampsCount: "0",
            loading: true
        })

        if (this.state.filter) {
            var server_response = await ajaxUnclamping.countZuluClamps(userId, respondedUnclampsStatus, filter.dateFrom, filter.dateTo);

        } else {
            var server_response = await ajaxUnclamping.countZuluClamps(userId, respondedUnclampsStatus, createdFrom, createdTo);
        }
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            this.setState({
                respondedUnClampsCount: server_response.details.content.total_c,
            })
        } else {
            this.setState({
                info2: <AlertError message={server_response.details.total_c} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }

    countUnClamps = async (filter) => {

        const { userId, unClampStatus, createdFrom, createdTo } = this.state;


        this.setState({
            unClampsCount: "0",
            loading: true
        })

        if (this.state.filter) {
            var server_response = await ajaxUnclamping.countZuluClamps(userId, unClampStatus, filter.dateFrom, filter.dateTo);

        } else {
            var server_response = await ajaxUnclamping.countZuluClamps(userId, unClampStatus, createdFrom, createdTo);

        }
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            this.setState({
                unClampsCount: server_response.details.content.total_c,
            })
        } else {
            this.setState({
                info2: <AlertError message={server_response.details.total_c} />

            }, () =>
                setTimeout(() => {
                    this.setState({
                        info2: false
                    })
                }, 12000))
        }
    }











    //==============NAVIGATION CONTROLS START===========================

    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;

        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                userClampHistroryList: false

            },
                //  () => {
                //     this.getUserClampHistory();
                // }

            )
        }

    }

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            userClampHistroryList: false
        }, () => this.getUserClampHistory())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                userClampHistroryList: false

            }, () => {
                this.getUserClampHistory();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {
                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }







    //==============NAVIGATION CONTROLS END===========================





    render() {
        // const { filter, streets, Locations } = this.state
        const { userClampHistroryList, filter, clampedCount, respondedCount, paidCount, respondedUnClampsCount,
            unClampsCount } = this.state;
        const clampingStatusOptions = [];

        const { clampingStatusList } = this.context;


        clampingStatusList &&
            clampingStatusList.map((item, key) =>
                clampingStatusOptions.push({ title: item.clamping_status, value: item.clamping_status_id })
            )

        return (
            <div className="page">


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title={`${this.state.firstName + "'s"} Field Performance`}
                            previousPageTitle={this.state.roleCode == "COBRA" ? "Cobra Performance" : "Zulu Performance"}
                            back={this.state.roleCode == "COBRA" ? "/reports/cobra-performance" : "/reports/zulu-performance"}
                            modalContainer={<this.buttons />}
                        />



                        {this.state.toast}



                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <TableHeader
                                            title={`${this.state.firstName + "'s"} daily field  performance report`}
                                            subtitle="This report tracks a a cobra or zulu field performance."

                                        />

                                        <div className="d-flex justify-content-between">

                                            {/* <div className="text-wrap">

                                            {userClampHistroryList !== "404" && filter &&
                                            <Export
                                                extraClasses="btn-sm float-right mb-5"
                                                excelUrl={"location/filtered/export?street_id=" + filter.selectedValue + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&location_id=" + filter.selectedValue1}

                                                pdfUrl={"space-inventory/filtered/export/pdf?" + filter.selectedValue + "&created_from=" + filter.dateFrom + "&created_to=" + filter.dateTo + "&location_id=" + filter.selectedValue1}
                                            />
                                            }

                                        </div> */}

                                            <div className="text-wrap mt-3 ">
                                                <div className="btn-list ">
                                                    {/* <div className="example"> */}
                                                    {this.state.roleCode == "COBRA" && <><CountButton
                                                        btnColor="btn-primary"
                                                        getCountData={() => this.getCountData}
                                                        count={clampedCount}
                                                        title="Clamped Vehicles"

                                                    />

                                                        {/* </div> */}
                                                        <CountButton
                                                            btnColor="btn-success"
                                                            getCountData={() => this.getCountData}
                                                            count={respondedCount}
                                                            title="Responded Vehicles"

                                                        />

                                                        <CountButton
                                                            btnColor="btn-secondary"
                                                            getCountData={() => this.getCountData}
                                                            count={paidCount}
                                                            title="Paid Vehicles"

                                                        />
                                                    </>
                                                    }

                                                    {this.state.roleCode == "ZULU" && <><CountButton
                                                        btnColor="btn-primary"
                                                        getCountData={() => this.getCountData}
                                                        count={respondedUnClampsCount}
                                                        title="responded unClamps"

                                                    />

                                                        {/* </div> */}
                                                        <CountButton
                                                            btnColor="btn-success"
                                                            getCountData={() => this.getCountData}
                                                            count={unClampsCount}
                                                            title="unClamped Vehicles"

                                                        />

                                                    </>
                                                    }
                                                </div>
                                            </div>

                                        </div>



                                        <div className="table-responsive">
                                            {this.state.clampPreview}
                                            {/* {<this.filterButton />} */}

                                            <CustomizedFilterBar
                                                selection={{
                                                    title: 'Clamp status',
                                                    options: clampingStatusOptions
                                                }}

                                                onReset={this.resetFilter}
                                                onConfirm={this.onFilter}
                                            />


                                            <table id="table-clampHistory" className="table text-nowrap table-hover mg-b-0 datatable" export-format={eformats}>
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Plate Number</th>
                                                        <th>CLAMP DATE</th>
                                                        {this.state.roleCode == "COBRA" && <th>Cobra</th>}
                                                        {this.state.roleCode == "ZULU" && <th>Zulu</th>}

                                                        <th>Clamped For</th>
                                                        <th>Status</th>
                                                    </tr>


                                                </thead>

                                                <tbody>
                                                    {userClampHistroryList && Array.isArray(userClampHistroryList) && userClampHistroryList.map((item, key) =>
                                                        <tr key={key} className="text-center">
                                                            <td> <Link
                                                                to="#"
                                                                onClick={() => this.onClickRef(item)}>
                                                                {item.clamp_history_ref} </Link>
                                                            </td>
                                                            <td>{item.vehicle.vehicle_number}</td>
                                                            <td>{item.created_at.short_date}&nbsp;&nbsp;<br /><small className="tx-12 tx-gray-500">{item.created_at.time}</small></td>
                                                            {this.state.roleCode == "COBRA" && <>
                                                                <td>{item.clamped_by.first_name}</td>
                                                                <td>{item.clamped_for.item}</td>
                                                            </>}
                                                            {this.state.roleCode == "ZULU" && <>
                                                                <td>{item.unclamped_by ? item.unclamped_by.first_name : "N/A"}</td>
                                                                <td>{item.clamped_for.item}</td>
                                                            </>}
                                                            <td>
                                                                {item.status.status === 'PENDING' && item.is_parked === "1" &&
                                                                    <span className="badge badge-info badge-pill">{item.status.status}</span>}


                                                                {item.status.status === 'PENDING' && item.is_parked === "0" &&
                                                                    <span className="badge badge-danger   badge-pill">ABORTED</span>}

                                                                {/* {item.status.status === 'PENDING' && item.system_abort === "1" &&
                                                                    <span className="badge badge-danger   badge-pill">LEFT PARKING</span>} */}


                                                                {item.status.status === 'RESPONDED' &&
                                                                    <span className="badge badge-secondary badge-pill">{item.status.status}</span>}
                                                                {item.status.status === 'CLAMPED' &&
                                                                    <span className="badge badge-dark badge-pill">{item.status.status}</span>}
                                                                {item.status.status === 'PENDING_UNCLAMP' &&
                                                                    <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                                                {item.status.status === 'UNCLAMP_RESPONDED' &&
                                                                    <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}
                                                                {item.status.status === 'UNCLAMPED' &&
                                                                    <span className="badge badge-success badge-pill">{item.status.status}</span>}
                                                            </td>

                                                        </tr>)}

                                                </tbody>


                                            </table>
                                            {this.state.info2}
                                            {/* {this.state.pagination} */}

                                            {(userClampHistroryList === "404") && <NoContent />}



                                            {/* {userClampHistroryList && Array.isArray(userClampHistroryList) && <LoadMoreButton action={fetchMore} loading={loading} />} */}


                                            {!userClampHistroryList && <ActivityLoader />}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
                <RightSideBar />
                <Footer />
            </div>


        );

    }
}

export default UserPerformanceReport