import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import SearchBar from '../../Components/Common/SearchBar'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import Toast from '../../Components/Common/Toast'
import RecentTicketSales from '../../Components/Finance/RecentTicketSales'
import TextInput from '../../Components/Common/TextInput'
import TicketFloatCard from '../../Components/Finance/TicketFloatCard'
import ClientVehicle from '../../Components/Vehicle/ClientVehicle'
class NewTicket extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        vehicleInfo: false,
        plateNumber: '',
        autoVehicle: false,
        loading: false,
        home: true,
        toast: false,
        stickerTypes: false,
        stickerTypeId: '',
        amount: '',
        dialogue: ''
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                plateNumber: this.props.location.state.vehiclePlate,
                autoVehicle: true
            }, () => this.onSearch(this.state.plateNumber))

        }

        //this.getStickerTypes();
    }


    onSearch = (plateNumber) => {
        this.searchVehicle(plateNumber);
    }

    searchVehicle = async (plateNumber) => {
        const server_response = await ajax.getVehicleByPlate(plateNumber, this.state.userId)
        this.setState({
            plateNumber: plateNumber,
            toast: false,
            vehicleInfo: false
        })
        if (server_response.status === "OK") {
            this.setState({
                vehicleInfo: server_response.details.content,
                autoVehicle: false
            })
        } else {
            this.setState({
                vehicleInfo: false,
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
        }
    }


    getStickerTypes = async () => {
        const server_response = await ajax.listStickerTypes()
        if (server_response.status === "OK") {
            this.setState({
                stickerTypes: server_response.details.content,
            })
        } else {
            this.setState({
                stickerTypes: '404',
                toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
            })
        }
    }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }



    onConfirmPurchase = async () => {
        this.resetToast();
        const { amount } = this.state;
        if (amount.length > 0 && amount > 0) {

            const server_response = await ajax.sellParkingTicket(this.state.amount, this.state.vehicleInfo.plate_number)
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast type={0} message={server_response.details.message} onClose={this.resetToast} />,
                    vehicleInfo: false
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} onClose={this.resetToast} />
                })
            }

        } else {
            this.setState({
                toast: <Toast type={2} message={"Enter correct amount and try again"} onClose={this.resetToast} />
            })
        }
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }


    onRefresh = () => {
        this.searchVehicle(this.state.plateNumber);
    }


    render() {
        // const vehicleInfo = this.state.vehicleInfo

        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Sell Ticket"
                            // previousPageTitle="Finance"
                            back="/finance"
                            custom={<TicketFloatCard />}
                        />
                        {this.state.toast}
                        <SearchBar
                            title="Enter Vehicle Number"
                            onSearch={this.onSearch}
                        />


                        {!this.state.loading &&
                            <>
                                {!this.state.vehicleInfo &&
                                    <RecentTicketSales />}
                                {this.state.vehicleInfo && this.state.vehicleInfo !== "404" &&

                                    <div className="row">
                                        <div className="col-sm-12 col-xl-6 col-lg-6 panel">
                                            <ClientVehicle
                                                vehicleInfo={this.state.vehicleInfo}
                                                refresh={this.onRefresh} />
                                        </div>

                                        <div className="col-sm-12 col-xl-6 col-lg-6 panel">
                                            <div className="card custom-card">
                                                <div className="modal-header">
                                                    <p className="modal-title">Purchase Details</p>
                                                </div>
                                                <div className="card-body">
                                                    <form method="post">
                                                        <TextInput
                                                            type="number"
                                                            label="Ticket Amount"
                                                            onChange={() => this.onChangeAmount}
                                                            value={this.state.amount}
                                                        />


                                                    </form>
                                                    <p><b>Note:</b> Outstanding vehicle parking session invoices will be settled automatically if any.<br /> <br /> </p>
                                                    <button
                                                        className="btn ripple btn-primary"
                                                        type="button"
                                                        onClick={this.onConfirmPurchase}>Confirm Purchase</button>

                                                    <br />
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                }

                            </>}

                        {this.state.loading && <ActivityLoader />}

                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default NewTicket
