import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {



    async getTowingFee()//GET
    {


        try {
            let response = await fetch(apiHost + 'towing/fees',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createTowingFee(amount)//POST
    {

        let data = {
            "amount": amount
        }

        try {
            let response = await fetch(apiHost + 'towing/fee.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deleteTowingFee(id)//POST
    {

        let data = {
            "fee_id": id
        }

        try {
            let response = await fetch(apiHost + 'towing/fee.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getActiveTowFee()//GET
    {

        try {
            let response = await fetch(apiHost + 'towing/fee/active',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async towVehicleDirectly(plate_number, towed_by_id, tow_station_id, clamped_location_id) {

        let data = {
            "plate_number": plate_number,
            "clamped_location_id": clamped_location_id,
            'towed_by_id': towed_by_id,
            'tow_station_id': tow_station_id
        }
        try {
            let response = await fetch(apiHost + 'towing/tow.direct',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }
    },

    async abortTowVehicle(vehicle_id, plate_number, abort_reason) {

        let data = {
            'vehicle_id': vehicle_id,
            "plate_number": plate_number,
            "abort_reason": abort_reason
        }

        try {
            let response = await fetch(apiHost + 'towing/tow.abort',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }

            return connError;
        }
    },


    async listTowStatuses() {
        // let data = {
        //     "clamp_status_id":clamp_status_id
        // }


        try {
            let response = await fetch(apiHost + 'towing/statuses/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }


    },

    // FILTER TOW TRANSACTIONS
    async filterTowTransactions(vehicle_id, plate_number, clamped_location_id, created_from, created_to, tow_status_id, towed_by_id, page) {
        let data = {
            "vehicle_id": vehicle_id,
            "plate_number": plate_number,
            "clamped_location_id": clamped_location_id,
            "created_from": created_from,
            "created_to": created_to,
            "tow_status_id": tow_status_id,
            "towed_by_id": towed_by_id,
            "page": page

        }
        try {
            let response = await fetch(apiHost + 'filter/tow-transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


    // LIST SIINGLE TOW TRANSACTIONS
    async listSingleTowTransaction(tow_transaction_id) {
        let data = {
            "tow_transaction_id": tow_transaction_id
        }
        try {
            let response = await fetch(apiHost + 'towing/single/record',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },


}