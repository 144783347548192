import React, { useContext } from "react"
import ScrollFilterBar from "../../Common/Filter/ScrollFilterBar";
import FilterTextInput from "../../Common/Filter/FilterTextInput";
import FilterSelectInput from "../../Common/Filter/FilterSelectInput";
import StickerContext from "../../../Context/StickerContext";
import ManageUserContext from "../../../Context/ManageUserContext";

const StickerSaleFilter=(props)=>{

    const {userList} = useContext(ManageUserContext)

    const {stickerTypeList, resetData, filterData} = useContext(StickerContext);
    const cashierOptions = [];
    Array.isArray(userList) && userList.map((item, key) =>
        cashierOptions.push({
            title: item.full_name,
            value: item.user_id
        })
    );

  
    const stickerOptions = [];
    Array.isArray(stickerTypeList) && stickerTypeList.map((item, key) =>
      stickerOptions.push({ title: item.ref_name, value: item.sticker_type_id })
    );


    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Sales Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Sales Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Plate Number"
            type="text"
            placeholder="Enter plate number"
            onChange={onSet}
            name="plateNumber"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterSelectInput
            key={key}
            label="Sticker Type"
            options={stickerOptions}
            onChange={onSet}
            name="stickerType"
            currentValues={currentValues}
          />,

        ],
        names:["dateFrom", "dateTo", "plateNumber", "stickerType"]
    }}
    secondScreen={
        {
          inputs:[
           (onSet, key, currentValues)=> <FilterSelectInput 
              key={key}
              label="Issued By"
              options={cashierOptions}
              onChange={onSet}
              name="issuedBy"
              currentValues={currentValues}
              />,

          ],
          names:["issuedBy"]
        }
      }
/>

}

export default StickerSaleFilter