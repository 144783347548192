import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import PopNewSession from '../../Components/Session/PopNewSession'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import PINLocation from '../../Components/Common/PINLocation'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import Export from '../../Components/Common/Export'
import Badge from '../../Components/Common/Badge'
import PageNav from '../../Components/Common/PageNav'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import NoContent from '../../Components/Common/NoContent'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import VIPListContext from '../../Context/VIP/VIPListContext'
import dictionary from '../../utils/dictionary'

class VIPListing extends React.Component {

    static contextType = VIPListContext

   

    paginator=()=>{

        const {transactionListing, goToNextPage, loading, metaData} = this.context
        
        if(Array.isArray(transactionListing)){
           
           return <LoadMoreButton
                action={goToNextPage}
                loading={loading}
                title={metaData.current_page + " of " + metaData.total_pages}
            />
        }else{
            return <></>
        }
    
    }



    buttons = () => {
        return (
            <>
                <button className='btn btn-light' onClick={this.context.refreshList}><i className='fa fa-redo mr-2' /> Refresh</button>
      
                {this.context.transactionListing  && 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                }
      
            </>
        )
      }



    RenderFilter = () => {
          
    
        return (
            <CustomizedFilterBar
                keyword="Plate Number"
                onReset={this.context.resetData}
                onConfirm={this.context.filterData}
            />
        )
    
        
    }
    
      



    render() {

        const { transactionListing} = this.context;
       
        return (<div className="page">



                            <LeftSideBar />
                            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                                <TopBar />
                                <div className="container-fluid">
                                    <PageHeader
                                        title="VIP Listing"
                                        previousPageTitle="Dashboard"
                                        // options={this.state.menuOptions}
                                        back="/operations"
                                        buttons={<this.buttons />}
                                    />

                                  <this.RenderFilter/>

                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12 col-lg-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center filter-example">
                                                    <TableHeader
                                                        title="VIP List"
                                                        subtitle="List of all VIP records from the most recent."
                                                    />


                                                        <this.paginator />


                                                    </div>


                                                    <div className="table-responsive">

                                                    {(transactionListing === "404") && <NoContent />}

                                                    {Array.isArray(transactionListing) &&
                                                        <table className="table text-nowrap mb-0 table-hover table-ellipsis datatable" id="table-vip" export-format={dictionary._exportBtnFormats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Issue Date</th>
                                                                    <th>Expiry Date</th>
                                                                    <th>Vehicle</th>
                                                                    <th>VIP Status</th>
                                                                    <th>VIP Reason</th>    
                                                                    <th>Issued By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {transactionListing.map((item, key) =>

                                                                    <tr key={key}>
                                                                        <td>{item.created_at.when}</td>
                                                                        <td>{item.expiry_date.short_date}</td>
                                                                        <td className="tx-bold">
                                                                            <Link to={
                                                                                {
                                                                                    pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                                    state: { plateNumber: item.vehicle.plate_number }
                                                                                }}>{item.vehicle.plate_number}
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            { item.vehicle.is_vip==="YES" && <Badge value="ACTIVE" type={0} />}
                                                                            { item.vehicle.is_vip!=="YES" && <Badge value="INACTIVE" type={4} />}

                                                                        </td>
                                                                        <td> <span>{item.reason}</span></td>
                                                                        <td>{item.issued_by.last_name + " " + item.issued_by.first_name}</td>
                                                                       
                                                                    </tr>
                                                                )}

                                                            </tbody>
                                                        </table>
                }

                                                       
                                                        {!transactionListing && <ActivityLoader />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <RightSideBar />
                             
                        </div>)
                }}
           


export default VIPListing
