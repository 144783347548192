import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from './ActivityLoader';
import AlertError from './AlertError';
import TextInput from './TextInput';

class PopNewFirebaseNotification extends React.Component {

    state = {
        title: '',
        body: '',
        info: '',
        searchList: false,
        buttonLabel: this.props.buttonLabel || "Confirm Amount",
        loading: false
    }

    onChangeTitle = (event) => {
        this.setState({ title: event.target.value });
    }

    onChangeBody = (event) => {
        this.setState({ body: event.target.value });
    }

    onConfirm = (event) => {
        event.preventDefault();
        if (this.state.title.length > 0 && this.state.body.length > 0) {
            this.setState({
                loading: true
            })
            this.props.onConfirm(this.state.title, this.state.body);
        } else {
            this.setState({
                info: <AlertError message={dictionary._requireContent} />
            })
        }
    }



    render() {
        return (
            <div className="modal" id="modal_amount_input">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Enter title"
                                    type="text"
                                    onChange={() => this.onChangeTitle}
                                    value={this.state.title}
                                />

                                <TextInput
                                    label="Enter body"
                                    type="textarea"
                                    onChange={() => this.onChangeBody}
                                    value={this.state.body}
                                />

                            </form>

                            {this.props.description}


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>{this.state.buttonLabel}</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopNewFirebaseNotification;
