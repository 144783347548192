import React, { useContext, useEffect, useState } from 'react';
import functions from '../utils/functions';
import ajaxTicket from '../utils/remote/ajaxTicket';
import { detectTable, restoreTable } from '../utils/script/customTables';
import AuthContext from './AuthContext';
import BranchContext from './BranchContext';

const PaperTicketContext = React.createContext();
const branch_id = functions.extractToken()['branch_id'] || 1

export const PaperTicketConsumer = PaperTicketContext.Consumer;

export const PaperTicketProvider = (props) => {
     const { switchBranch } = useContext(BranchContext)

    const [ticketListing, setTicketListing] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [data, setData] = useState({"page":"1", "branch_id":branch_id})
    const [totalDailySales, setTotalDailySales] = useState("...");
    const [totalDailyVendors, setTotalDailyVendors] = useState("...");
    const [totalDailyVehicles, setTotalDailyVehicles] = useState("...");
    const [totalWeeklyVehicles, setTotalWeeklyVehicles] = useState("...");
    const [totalMonthlyVehicles, setTotalMonthlyVehicles] = useState("...");
    const [totalWeeklySales, setTotalWeeklySales] = useState("...");
    const [totalMonthlySales, setTotalMonthlySales] = useState("...");
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [agentSalesSummary, setAgentSalesSummary] = useState(false)
    const [branchId, setBranchId] = useState(branch_id);
    const { secureCall } = useContext(AuthContext);
    const [userId, setUserId] = useState(functions.sessionGuard())




    useEffect(()=>{
        if(userId>0)
        {
            secureCall(getPaperTickets, "R-BRANCH-MONITOR-00");
            secureCall(getTotalDailySales, "R-BRANCH-MONITOR-00");
            secureCall(getTotalDailyVendors, "R-BRANCH-MONITOR-00")
            secureCall(getTotalDailyVehicles, "R-BRANCH-MONITOR-00")
            secureCall(getTotalWeeklySales, "R-BRANCH-MONITOR-00")
            secureCall(getTotalMonthlySales, "R-BRANCH-MONITOR-00")
            secureCall(getTotalWeeklyVehicles, "R-BRANCH-MONITOR-00")
            secureCall(getTotalMonthlyVehicles, "R-BRANCH-MONITOR-00")
            secureCall(getPaperTicketAgentSummary, "R-BRANCH-MONITOR-00")
        }
      
    }, [data])

    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreTrigger()
        }
    }, [currentPage])



    const getPaperTickets=async()=>{
        setLoading(true)
        setTicketListing(false)
        const server_response = await ajaxTicket.fetchPaperTickets(data);
        setLoading(false)
        if(server_response.status==="OK"){
            setTicketListing(server_response.details.content.list);
            setMetaData(server_response.details.content.meta);
        }else{
            setTicketListing("404");
            setMetaData(false);
        }
    }

    const getTotalDailySales=async()=>{
        const server_response = await ajaxTicket.fetchTotalDailyPaperTicketSales(data);
        if(server_response.status==="OK"){
            setTotalDailySales(server_response.details.content.amount_c);
        }
    }

    const getTotalDailyVendors=async()=>{
        const server_response = await ajaxTicket.fetchTotalPaperTicketVendors(data);
        if(server_response.status==="OK"){
            setTotalDailyVendors(server_response.details.content.total_p);
        }
    }

    const getTotalDailyVehicles=async()=>{
        const server_response = await ajaxTicket.fetchTotalPaperTicketVehicles(data);
        if(server_response.status==="OK"){
            setTotalDailyVehicles(server_response.details.content.total_p);
        }
    }

    const getTotalWeeklyVehicles=async()=>{
        const server_response = await ajaxTicket.fetchWeeklyTotalPaperTicketVehicles(data);
        if(server_response.status==="OK"){
            setTotalWeeklyVehicles(server_response.details.content.total_p);
        }
    }

    const getTotalMonthlyVehicles=async()=>{
        const server_response = await ajaxTicket.fetchMonthlyTotalPaperTicketVehicles(data);
        if(server_response.status==="OK"){
            setTotalMonthlyVehicles(server_response.details.content.total_p);
        }
    }

    const getTotalWeeklySales=async()=>{
        const server_response = await ajaxTicket.fetchTotalWeeklyPaperTicketSales(data);
        if(server_response.status==="OK"){
            setTotalWeeklySales(server_response.details.content.amount_c);
        }
    }

    const getTotalMonthlySales=async()=>{
        const server_response = await ajaxTicket.fetchTotalMonthlyPaperTicketSales(data);
        if(server_response.status==="OK"){
            setTotalMonthlySales(server_response.details.content.amount_c);
        }
    }


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxTicket.fetchPaperTickets(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTicketListing(ticketListing.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1", "branch_id":branchId})
    }

    const filterData=(options)=>{
        const newData = {
            ...data,
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "user_id":options.selectedValue,
            "page":"1",
            "plate_number":options.keyword
        }
        setData(newData)
        
    }


    const getPaperTicketAgentSummary=async()=>{
        setAgentSalesSummary(false)
        const server_response = await ajaxTicket.fetchPaperTicketAgentSummary(data);
        if(server_response.status==="OK"){
            setAgentSalesSummary(server_response.details.content);
        }else{
            setAgentSalesSummary("404")
        }
    }


    const switchTicketBranch=(branchId)=>{
        setBranchId(branchId)
        switchBranch(branchId)
        setData({"page":"1", "branch_id":branchId})
    }


  
    return (
        <PaperTicketContext.Provider value={{
            ticketListing,
            metaData,
            totalDailySales,
            totalDailyVendors,
            totalDailyVehicles,
            totalWeeklyVehicles,
            totalMonthlyVehicles,
            totalWeeklySales,
            totalMonthlySales,
            loading,
            agentSalesSummary,
            switchTicketBranch,
            getPaperTicketAgentSummary,
            goToNextPage,
            setData,
            resetData,
            filterData
        }}
        >
            {props.children}
        </PaperTicketContext.Provider>
    );
}


export default PaperTicketContext;
