import { useContext, useEffect } from "react";
import ActivityLoader from "../../../Common/ActivityLoader";
import NoContent from "../../../Common/NoContent";
import TableHeader from "../../../Common/TableHeader";
import dictionary from "../../../../utils/dictionary";
import CobraReportContext from "../../../../Context/Violation/CobraReportContext";
const eformats = dictionary._exportBtnFormats

const CobraPerformance = () =>{

    const {performanceData, setData, loading, title} = useContext(CobraReportContext);

    useEffect(()=>{

        if(!performanceData){
            setData({
                "date_from":'',
                "date_to":'',
            })
        }
     

    }, [])


    return(
        <div className="row">
          <div className="col-sm-12 col-xl-12 col-lg-12">

            <TableHeader
                title="Cobra Performance"
                subtitle="Analysis of cobras' efficiency."
            />
                <div className="table-responsive  allView-table border-top">
                {Array.isArray(performanceData) && <table id="table-cobra-perform" className={`table table-hover text-nowrap mg-b-0 datatable`} export-format={eformats}>
                 <thead>
                    <tr>
                        <th colSpan={8} style={{textAlign:'center'}}>{title}<br/><br/></th>
                    </tr>
                    <tr>
                    <th>No.</th>
                    <th>Cobra Name</th>
                    <th>Branch</th>
                    <th>Reported vehicles</th>
                    <th>Clamped Wrong Parkings</th>
                    <th>Clamped Non Payments</th>
                    <th>Total Clamped</th>
                    <th>Performance</th>
                    </tr>
                    </thead>
                    <tbody>
                        {performanceData.map((item, key)=>
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.user.full_name}</td>
                                <td>{item.user.branch.branch_name}</td>
                                <td style={{textAlign:'center'}}>{item.report.total_reported.total_p}</td>
                                <td style={{textAlign:'center'}}>{item.report.total_clamped_wrong_parkings.total_p}</td>
                                <td style={{textAlign:'center'}}>{item.report.total_clamped_non_payments.total_p}</td>
                                <td style={{textAlign:'center'}}>{item.report.total_clamped.total_p}</td>
                                <td>{item.report.performance}%</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
               
               {performanceData === '404' && <NoContent/>}
               {!(performanceData || loading) && <ActivityLoader/>}

            </div>
        </div>
    </div>)
}

export default CobraPerformance;