import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import $ from 'jquery';
import Toast from '../Common/Toast';
import Badge from '../Common/Badge';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';

class PopPermissionAssign extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        info: '',
        hint: '',
        userResults: false,
        permissionResults: false,
        sessionResults: false,
        invoiceResults: false,
        toast: false,


        userId: functions.sessionGuard(),
        info: '',
        loading: false,

        roleId: '',
        roleName: "Select a role...",
        roleList: false,
        roleRecord: '',
        selectedRoleId: '',

        permissionId: '',
        permission: '',
        searchList: false,
        permissionList: false,
        permissionRecord: '',

        multilineValues: []
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles = async () => {

        const server_response = await ajax.getRoles(this.state.userId);

        if (server_response.status === "OK") {
            this.setState({
                roleList: server_response.details.content.list
            })
        } else {
            this.setState({
                roleList: "404"
            })
        }
    }

    onChangeRole = (selectedOption) => {
        this.setState({
            roleId: selectedOption.value,
            roleName: selectedOption.label
        })
    }

    onChangePermission = (event) => {
        this.setState({
            permission: event.target.value
        }, () => this.searchPermission())
    }

    // searchPermission = async () => {
    //     const server_response = await ajax.searchPermission(this.state.permission);

    //     if (server_response.status === "OK") {
    //         this.setState({
    //             searchList: server_response.details.content
    //         })
    //     } else {
    //         this.setState({
    //             searchList: false
    //         })
    //     }
    // }

    onClickPermission = (permission, permissionId) => {

        this.setState({ permission, permissionId }, () =>

            this.state.multilineValues.push({
                label: this.state.permission,
                value: this.state.permissionId
            }))

    }


    onConfirm = async () => {
        this.setState({
            info: ''
        })
        const { roleId, permissionId } = this.state;


        if (roleId.length > 0 && permissionId.length > 0) {
            this.setState({
                loading: true
            }, () => this.props.onConfirm(roleId, permissionId))
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }

    onSearch = (event) => {
        event.preventDefault();
        this.findResults();
    }

    findResults = () => {
        this.findUser();
        this.findPermission();
    }

    onChangeHint = (event) => {
        this.setState({
            hint: event.target.value
        }, () => this.findResults());
    }

    findUser = async () => {
        if (this.state.hint.length > 0) {
            const server_response = await ajax.searchUser(this.state.hint, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    userResults: server_response.details.content
                })
            } else if (server_response.status === "Fail") {
                this.setState({
                    userResults: false
                })
            }

        } else {
            this.setState({
                userResults: false
            })
        }
    }

    findPermission = async () => {
        if (this.state.hint.length > 0) {
            const server_response = await ajax.searchPermission(this.state.hint, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    permissionResults: server_response.details.content,
                    toast: <Toast
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
                })
            } else if (server_response.status === "Fail") {
                this.setState({
                    permissionResults: false,
                    toast: <Toast
                        type={4}
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
                })
            }

        } else {
            this.setState({
                permissionResults: false
            })
        }
    }


    hideFinder = () => {
        $("#mdl-sys-finder").modal('hide');
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    onSelectPermission = (permissionId, permissionRecord) => {
        this.setState({
            permissionId: permissionId,
            permissionRecord: permissionRecord,
            toast: ''
        })

        // console.log(permissionId)
    }


    render() {

        const { multilineValues, roleList } = this.state;
        const optionsRole = [];

        if (roleList && roleList !== "404") {
            roleList.map((item, key) =>
                optionsRole.push({
                    label: item.role_name,
                    value: item.role_id
                })
            )
        }

        const searchList = this.state.searchList;

        return (
            <div className="modal " id="modal_assign_permission">
                <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                New Permission Assignment
                            </h6>
                            {/* <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button> */}
                            {this.state.toast}
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <div className="form-group">

                                <form 
                                    method="post" 
                                    // onSubmit={this.onSearch}
                                    onSubmit={this.onConfirm}
                                >
                                    <SelectSearch
                                        label="Select Role to assign..."
                                        // divClass="col-md-12"
                                        className=" form-input"
                                        onChange={() => this.onChangeRole}
                                        options={optionsRole}
                                        placeholder={"Select Role to assign..."}
                                    />

                                    {/* <input
                                        type="text"
                                        className="form-control input-lg"
                                        placeholder="Enter Permission...."
                                        value={this.state.hint}
                                        onFocus={functions.handleFocus}
                                        onChange={this.onChangeHint}
                                    /> */}

                                    <TextInput
                                        label="Enter Permission..."
                                        type="text"
                                        // onChange={() => this.onChangePermission}
                                        // value={
                                        //     multilineValues.map((item, key) =>
                                        //         item.label)
                                        // }
                                        placeholder="Enter Permission...."
                                        value={this.state.hint}
                                        onFocus={functions.handleFocus}
                                        onChange={() => this.onChangeHint}
                                    />
                                </form>

                            </div>

                            <div className="modal-footer">
                                {!this.state.loading && <>
                                    <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Assign Permission</button>
                                    <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                                {this.state.loading && <ActivityLoader />}
                            </div>

                            <div className="overflow-hidden">
                                <div className="table-responsive">
                                    {/* <Link to="/config/permissions">
                                    <Badge value="See All Permissions" type={6} />
                                </Link> &nbsp;&nbsp;
                                <Link to="/users/all-users" onClick={this.hideFinder}>
                                    <Badge value="See All Users" type={6} />
                                </Link> */}

                                    {this.state.permissionResults &&
                                        <table className="table mg-b-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan="3">
                                                        Permission Results
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.permissionResults.map((item, key) =>
                                                    <tr key={key}>
                                                        <td className="">

                                                            <div className="form-options-wrapper">
                                                                <div className="custom-control custom-checkbox login-card-check-box">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        // id="customCheck1" 
                                                                        id={item.permission_id}
                                                                        name=""
                                                                        value={item.permission_id}
                                                                        onClick={() => this.onSelectPermission(item.permission_id, item)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={item.permission_id}></label>
                                                                </div>
                                                            </div>

                                                            {/* <div className="custom-control custom-checkbox login-card-check-box">
                                                                <input
                                                                    type="checkbox"
                                                                    // className="custom-control-input" 
                                                                    // id="customCheck1" 
                                                                    onClick={this.showPassword}

                                                                    id={"br_" + item.permission_id}
                                                                    key={key}
                                                                    className={`${this.state.permissionId === item.permission_id ? 'flag-row' : ''}`}
                                                                    onClick={() => this.onSelectPermission(item.permission_id, item)} />
                                                                <label className="custom-control-label" htmlFor="customCheck1"></label>
                                                            </div> */}
                                                        </td>
                                                        <td className="">
                                                            <h6 className="mg-b-0 tx-gray-500">{item.permission}</h6>
                                                            <small className="tx-12 tx-gray-700">
                                                                Created on: {item.created_at.date}&nbsp;&nbsp;<small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                            </small>
                                                            <br />
                                                            <small className="tx-12 tx-gray-700">
                                                                PermissionId: {item.permission_id}
                                                            </small>&nbsp;&nbsp;

                                                            {/* {item.white_list && <Badge value="White Listed" type={3} />}
                                                        {item.clamped && <Badge value="Clamped" type={2} />}
                                                        <br /> */}
                                                            {/* <small className="tx-11 tx-gray-700">
                                                            Balance: UGX. {item.balance.amount_c}
                                                        </small>&nbsp;&nbsp; */}

                                                            {/* <br /> */}
                                                        </td>
                                                        <td className="">
                                                            <h6 className="mg-b-0">Permission Info</h6>
                                                            <small className="tx-12 tx-gray-700">Description: {item.description}</small><br></br>
                                                            <small className="tx-12 tx-gray-700">Module: {item.module.department_module}</small><br></br>


                                                            {/* <Link to={
                                                            {
                                                                pathname: `/vehicles/profile/${item.plate_number}`,
                                                                state: { plateNumber: item.plate_number }
                                                            }
                                                        }
                                                            className="tx-14"
                                                            onClick={this.hideFinder}
                                                            replace>
                                                            Go to vehicle
                                                        </Link> */}

                                                        </td>


                                                        {/* <td className="bd-t-0">
                                                        <h6 className="mg-b-0">Client Info</h6>
                                                        <small className="tx-14 tx-gray-900">Name: {item.client.full_name}</small><br></br>
                                                        <small className="tx-14 tx-gray-900">Phone: {item.client.phone_number}</small><br></br>


                                                    </td> */}



                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    }

                                    {/* {this.state.userResults && <table className="table mg-b-0">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">
                                                User Results
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.userResults.map((item, key) =>

                                            <tr key={key}>
                                                <td className="bd-t-0 wd-20">
                                                    <div className="main-img-user">
                                                        <img alt="avatar" src={item.profile_photo.file_path} />
                                                    </div>
                                                    <small className="tx-11 tx-gray-700">{item.user_role.role_name + "-" + item.user_id}</small><br></br>
                                                </td>
                                                <td className="bd-t-0">
                                                    <h6 className="mg-b-0">About</h6>
                                                    <small className="tx-14 tx-gray-900">Name: {item.first_name + ' ' + item.last_name}</small><br></br>
                                                    <small className="tx-14 tx-gray-900">Phone: {item.phone_number}</small><br></br>
                                                    <small className="tx-14 tx-gray-900">Username: {item.username}</small><br></br>

                                                    <Link
                                                        to="#"
                                                        onClick={this.hideFinder}
                                                        className="tx-14"
                                                        replace>View Profile</Link>

                                                </td>

                                            </tr>

                                        )}




                                    </tbody>
                                </table>} */}
                                </div>
                            
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default PopPermissionAssign;
