import React from 'react'
import { Link } from 'react-router-dom'


class DashRow3 extends React.Component {

    render() {
        return (
            // <div className={this.props.className}>
            //     <Link to={this.props.href}>
            //         <div className="card custom-card pinned-card">
            <div className="card-body d-flex ml-2 mt-3 mb-3">
                <div className={this.props.color}>
                    <i className={this.props.icon}></i>
                </div>
                <div className="ml-4">
                    <p className="mb-2 text-muted">{this.props.title}</p>
                    <h3 className="mb-0">{this.props.value}</h3>

                    <span className="d-inline-block text-truncate" style={{maxWidth:"100px"}}>{this.props.caption}</span>

                </div>
                
            </div>
            //         </div>
            //     </Link>
            // </div>
        )
    }

}

export default DashRow3
