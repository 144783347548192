import React from 'react';


class ImageUpload extends React.Component {
  

  render(){

    const inputType=this.props.type || "file"
    
      return (

        <div className="row row-xs align-items-center mg-b-20">
            <div className="col-md-3 text-left">
                <label className="mg-b-0">{this.props.label}</label>
            </div>
            <div className="col-md-9 mg-t-5 mg-md-t-0">
            
              
            
            {/* <input type="file" name="fileToUpload" id="fileToUpload"></input> */}
              
              <input 
                type={ inputType || "file" }
                name={this.props.name}
                id={this.props.id}
                // className="form-control" 
                // placeholder={this.props.label}
                onChange={this.props.onChange()}
                value={this.props.value}
                // autoFocus={this.props.autoFocus}
                // readOnly={this.props.readonly || false}
              />
            
            
            </div>
        </div>
      );
    
      }

}

export default ImageUpload;