import React from 'react';
import ajax from '../../utils/ajax';
import ActivityLoader from '../Common/ActivityLoader';
import InitialAvatar from '../Common/InitialAvatar';


class StreetLocations extends React.Component {
  state={
    locations:false,
    streetId:this.props.streetId
  }

  async componentDidMount()
  {
      await this.getLocations();

       this.timerCount = setTimeout(this.funcCount=async()=> {
        await this.getLocations();
        this.timerCount = setTimeout(this.funcCount, 5000); // (*)
      }, 5000)
  }

  componentWillUnmount()
  {
    clearTimeout(this.timerCount)
  }
  

  
getLocations = async () => {
    const server_response = await ajax.listStreetLocation(this.state.streetId);
    if (server_response.status === "OK") {
        this.setState({
            locations: server_response.details.content
        })
    } else {
        this.setState({
            locations: "404"
        })
    }

}




  render(){
      const locations=this.state.locations
    
      return (
     
      <>
       {locations && locations!=="404" &&  
       <div className="card custom-card">


								<div className="card-body">
									<div>
										<h6 className="card-title mb-1">Street Locations</h6>
										<p className="text-muted mb-0 card-sub-title">List of street parking locations.</p>
									</div>
								</div>
								<div className="scroll-widget bd-t overflow-auto">
                                        <div className="table-responsive">
										<table className="table mg-b-0">
											<tbody>
                                            {locations.map((item, key)=>
												<tr key={key}>
													<td className="bd-t-0">
														<div className="main-img-user">
                                                            <InitialAvatar 
                                                            value={<i className="fas fa-map-marker-alt tx-success"/>} 
                                                            background="bg-success-transparent"
                                                            size={1}/>
                                                            
                                                        </div>
													</td>
													<td className="bd-t-0">
														<h6 className="mg-b-0">{item.location_name}</h6>
                                                        <small className="tx-11 tx-gray-500">{item.lat + ', ' + item.lng}</small>
													</td>
													<td className="bd-t-0">
														<h6 className="mg-b-0 font-weight-bold">{item.available_parking.total_p}</h6>
                                                        <small className="tx-11 tx-gray-500">Avail. Of {item.total_slots.total_p} slots</small>
													</td>
												</tr>)}
                                        
                                        </tbody>
                                    </table>
                                    </div>

										
									
								</div>
							

    </div>}
    {!locations && <ActivityLoader/>}
    </>
      );
    
 
  }
}

export default StreetLocations;