import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import TableHeader from '../../Common/TableHeader';
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext';
import ActivityLoader from '../../Common/ActivityLoader';

const EfrisTransactions =(props)=> {

    const {allTransactions} = useContext(FiscalReceiptContext);


    return <div className="card custom-card">
            <div className="card-body map-card">

                <TableHeader
                    title={"Fiscal Transaction"}
                    subtitle="List of all VAT transactions."
                    // view="View All"
                    // href="/finance/invoices"
                />
                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                    <div className="table-responsive">
                        <table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <td>Receipt Date</td>
                                    <th>Customer Name</th>
                                    <th>Receipt No.</th>
                                    <th>Invoice ID</th>
                                    <th>Invoice No.</th>
                                    <th>Net Amount</th>
                                    <th>Tax Amount</th>
                                    <th>Gross Amount</th>
                                    <th>Product Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(allTransactions) && allTransactions.map((item, key)=>
                                    <tr 
                                        key={key} 
                                        className={`${item.cancelled*1 ? 'flag-row': ''}`}
                                        title={item.cancelled*1 ? 'Credit Note id: ' + item.credit_note_ref :''}
                                        >
                                        <td>{key+1}</td>
                                        <td>{item.created_at.short_date} {item.created_at.time}</td>
                                        <td>{item.customer_name}</td>
                                        <td>{item.deposit_id}</td>
                                        <td>{item.business_invoice_id}</td>
                                        <td>{item.invoice_number}</td>
                                        <td>{item.net_amount}</td>
                                        <td>{item.tax_amount}</td>
                                        <td>{item.gross_amount}</td>
                                        <td>{item.product_code}</td>
                                    </tr>
                                )}
                               
                            </tbody>
                        </table>
                        {!allTransactions && <ActivityLoader />}
                    </div>
                </div>
            </div>
        </div>

}

export default EfrisTransactions
