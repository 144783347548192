import React from 'react';
import ActivityLoader from '../../Common/ActivityLoader';
import NewClampViolation from './NewClampViolation';
import NewPaymentDue from './NewPaymentDue';
import NewWrongParking from './NewWrongParking';

class PopCustomInvoicing extends React.Component {

    state = {
        info: '',
        loading: false,
        form:false
    }

    componentDidMount() {

    }


    onClickWrongParking=()=>{
        this.setState({
            form:<NewWrongParking {...this.props}/>
        })
    }


    onClickPaymentDue=()=>{
        this.setState({
            form:<NewPaymentDue {...this.props}/>
        })
    }
    
    
    onClickClampViolation=()=>{
        this.setState({
            form:<NewClampViolation {...this.props}/>
        })
    }
    

   
    render() {
        const list_bg=['bg-danger', 'bg-success', 'bg-warning', 'bg-secondary', 'bg-primary'];
        const listing = [
            {
                "title":"Wrong Parking Invoice",
                "description":"Adds wrong parking invoice but does not clamp vehicle",
                "action":this.onClickWrongParking
            },
            {
                "title":"Payment Due Invoice",
                "description":"Adds Non Payment invoice but does not clamp vehicle",
                "action":this.onClickPaymentDue
            },
            {
                "title":"Clamp Violation Invoice",
                "description":"Requires clamp invoice selection from 'invoice history'",
                "action":this.onClickClampViolation
            }
        ]

        return (
            <div className="modal" id={"modal-custom-invoicing"}>
                <div className="modal-dialog modal-dialog-centered" role="document">


                   {!this.state.form && <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                <i className="fa fa-edit text-danger mr-1"/>
                                Vehicle Custom Invoicing
                            </h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <h6 className="card-title mb-3">{this.props.description || "Select payment mode to continue"}</h6>
                            </div>
                            <div className="">
                               
                                    <ul className="task-list">
                                     
                                     {listing.map((item, key)=> <li className="pointer" key={key} onClick={item.action}>
                                       <i className="task-icon bg-primary"></i>
                                       <h6>{item.title}<small className="float-right text-primary tx-11"></small></h6>
                                       <span className="text-muted tx-12 d-inline-block text-truncate" style={{maxWidth: "90%"}}>
                                          {item.description}
                                        </span>
                                      </li>
                                      )}
                                       
                                    </ul>
                            </div>              
                           
                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>}

                    {this.state.form}


                </div>
            </div>
        )


    }
}

export default PopCustomInvoicing;
