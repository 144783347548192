import React, { useContext, useState } from 'react';
import ModalBox from '../../Common/ModalBox';
import TextInput from '../../Common/TextInput';
import ActivityLoader from '../../Common/ActivityLoader';
import ajaxOperation from '../../../utils/remote/ajaxOperation';
import AlertSuccess from '../../Common/AlertSuccess';
import AlertError from '../../Common/AlertError';
import ajaxDemandNote from '../../../utils/remote/ajaxDemandNote';
import dictionary from '../../../utils/dictionary';
import DemandNoteContext from '../../../Context/Finance/DemandNoteContext';

const PopNewDemandNote =(props)=>{

    const {refresh} = useContext(DemandNoteContext);

    const [info, setInfo] = useState(false);
    const [fileName, setFileName] = useState('');
    const [actualFile, setActualFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [batchName, setBatchName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerContact, setCustomerContact] = useState('');
    const [vehicleSource, setVehicleSource] = useState("1");
    const [vehicleList, setVehicleList] = useState("");
    const [clientVehicle, setClientVehicle] = useState("");

    const onConfirm=async(event)=>{

        event.preventDefault();
        if (batchName.length > 0 &&  clientVehicle.length > 0 && customerName.length > 0 && customerContact.length > 0) {
            setLoading(true);
            const server_response = await ajaxDemandNote.createDemandNote({
                batch_name : batchName,
                client_vehicle: clientVehicle,
                customer_name: customerName,
                customer_contact: customerContact,
                vehicle_list: vehicleList,
                data_file : actualFile,
                data_source: vehicleSource
            });
            setLoading(false)
            if(server_response.status=="OK"){ 
                refresh();        
                setInfo(<AlertSuccess message={server_response.details.message} />);
                setFileName('');
                setActualFile('');
                setBatchName('');
                setVehicleList('');
                setCustomerContact('');
                setCustomerName(''); 
                setClientVehicle('');     
            }else{
                setInfo(<AlertError message={server_response.details.message} />)
            }

           
        } else {
            setInfo(<AlertError message={dictionary._completeFields} />);
        }

    }

    const onChangeFile = (event) => {
        
         setFileName(event.target.value);
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
          setActualFile({file:  e.target.result})
         }
         
    }


    const renderVehicleSource = () =>{

        const options = [
            {label: "Type vehicles", value: "1"},
            {label: "Upload vehicles", value: "2"}
        ]

        return(
            <TextInput
            label="Vehicle Source"
            type="select"
            onChange={(e)=>setVehicleSource(e.value)}
            name="VehicleSource"
            options={options}
        />
        )
    }


   
        return <ModalBox 
                    id="pop-new-demand-note" title="New Invoice"
                    footer={
                        <>
                           {!loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={onConfirm}>Generate Invoice</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {loading && <ActivityLoader />}
                        </>
                    }
                    >
               
                        {info}
                            <form method="post" onSubmit={onConfirm}>

                            {vehicleSource === "2" && <p>Note: The CSV file must have one column labelled <b>Vehicle Number</b></p>}

                                 <TextInput
                                    label="Batch Name"
                                    type="text"
                                    onChange={(e)=>setBatchName(e.target.value)}
                                    value={batchName}
                                    name="BatchName"
                                />
                                 <TextInput
                                    label="Tax Profile Vehicle"
                                    type="text"
                                    onChange={(e)=>setClientVehicle(e.target.value)}
                                    value={clientVehicle}
                                    name="ClientVehicle"
                                />
                                 <TextInput
                                    label="Customer Name"
                                    type="text"
                                    onChange={(e)=>setCustomerName(e.target.value)}
                                    value={customerName}
                                    name="CustomerName"
                                />
                                 <TextInput
                                    label="Customer Contact"
                                    type="text"
                                    onChange={(e)=>setCustomerContact(e.target.value)}
                                    value={customerContact}
                                    name="CustomerContact"
                                />
                                {renderVehicleSource()}
                                {vehicleSource === "2" && <TextInput
                                    label="Select CSV file"
                                    type="file"
                                    onChange={onChangeFile}
                                    value={fileName}
                                    name="FileName"
                                />}

                                {vehicleSource === "1" && <TextInput
                                    label="Vehicle List"
                                    type="text"
                                    onChange={(e)=>setVehicleList(e.target.value)}
                                    value={vehicleList}
                                    name="VehicleList"
                                    placeholder="Separate each vehicle with a comma"
                                />}



                            </form>
              </ModalBox>     
        
}

export default PopNewDemandNote;
