import React, { useContext } from 'react';
import CashierContext from '../Context/CashierContext';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxPayment from '../utils/remote/ajaxPayment';
import ActivityLoader from './Common/ActivityLoader';
import AlertError from './Common/AlertError';
import AlertSuccess from './Common/AlertSuccess';
import TextInput from './Common/TextInput';

class PopDatedPayment extends React.Component {

    state = {
        amount: '',
        depositorContact: '',
        depositorName: '',
        modeCode:'',
        reference:'',
        paymentDate:'',
        info: ''
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }


    makeOfficePayment = async (amount, depositorName, depositorContact, paymentDate, reference, paymentMode) => {

        this.setState({
            info: <ActivityLoader />,
            officePayment: false
        })

        const data={
            'plate_number':this.props.plateNumber, 
            'amount':amount, 
            'depositor_contact':depositorContact, 
            'depositor_name':depositorName, 
            'payment_mode':paymentMode, 
            'payment_date':paymentDate, 
            'reference':reference
        }


        const server_response = await ajaxPayment.postDatedPayment(data)

        if (server_response.status === "OK") {
            //load receipt
            functions.closePopup("modal_dated_payment");
            this.props.onConfirm(<AlertSuccess message={server_response.details.message} />)
        } else {
            this.setState({
                info: <AlertError message={server_response.details.message} />
            })
        }
    }




    onConfirm = (event) => {
        event.preventDefault();
        const { amount, depositorContact, depositorName, paymentDate,  reference, modeCode} = this.state
        if (amount.length > 0 && depositorContact.length > 0 && depositorName.length > 0 && paymentDate.length>0 && reference.length>0 && modeCode.length>0) {

            this.makeOfficePayment(amount, depositorName, depositorContact, paymentDate, reference, modeCode);

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }



    onChangePaymentMode=(item)=>{
        this.setState({
            modeCode:item.value
        })
    }


    RenderPaymentMode=()=>{
        const {datedPaymentModes} = useContext(CashierContext)

        const paymentOptions  =[];
        if(Array.isArray(datedPaymentModes))
        {
            datedPaymentModes.map((item, key)=>{
                paymentOptions.push({label:item.mode_name, value:item.code})
            })
        } 

        return (
            <TextInput
            label="Select Payment Mode"
            type="select"
            name="PaymentMode"
            options={paymentOptions}
            placeholder="Select Payment Mode"
            onChange={this.onChangePaymentMode}
        />

        )
    }



    render() {
        return (
            <>
                {this.state.receipt}
                <div className="modal" id="modal_dated_payment">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New Dated Payment</h6>
                                <span>Amount Due: {this.props.balance}</span>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <form method="post" onSubmit={this.onConfirm}>

                                    <this.RenderPaymentMode/>

                                    <TextInput
                                        label="Payment Date"
                                        type="date"
                                        name="PaymentDate"
                                        onChange={(e) => this.setState({paymentDate:e.target.value})}
                                        value={this.state.paymentDate}
                                    />

                                    <TextInput
                                        label="Amount"
                                        type="number"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                                    <TextInput
                                        label="Depositor's Name"
                                        type="text"
                                        onChange={() => this.onChangeDepositorName}
                                        value={this.state.depositorName}
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                                    <TextInput
                                        label="Reference"
                                        type="text"
                                        placeholder="Payment Reference or Naration"
                                        name="Reference"
                                        onChange={(e) => this.setState({reference:e.target.value})}
                                        value={this.state.reference}
                                    />

                                </form>




                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Submit Payment</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }
}

export default PopDatedPayment;
