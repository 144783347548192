import React from 'react';
import CashierContext from '../../../Context/CashierContext';
import functions from '../../../utils/functions';
import ajaxPayment from '../../../utils/remote/ajaxPayment';
import ActivityLoader from '../../Common/ActivityLoader';
import CompanyHeader from '../../Config/CompanyHeader';

class PopCashierDailySummary extends React.Component {

    static contextType = CashierContext

    state = {
        cashierId: this.props.cashierId,
        summary:false
    }

    componentDidMount() {
        // this.getDailySummary();
        this.context.setCashierId(this.state.cashierId)
    }
    
   
   

    render() {
        const summary=this.context.cashierSummary
        return (
            <div className="modal" id="modal_cashier_daily_summary">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Cashier Daily Collection Summary</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body" id="cashier-summary">    
                        {summary && <div className='docBody'>

                            <div className="row">
										<div className="col-lg-6">
											<CompanyHeader/>
										</div>
										<div className="col-lg-6 text-right">
											<p className="h3">Cashier:</p>
											<address>
                                                {summary.user.last_name + ' ' + summary.user.first_name}
											<br/>
											{summary.user.phone_number}
											<br/>
											</address>
										</div>
									</div>

                       <div className="table-responsive mg-t-0">
							<table className="table table-invoice table-bordered" border="1" cellSpacing={0}>
                            <thead>
                            <tr>
                                <th colSpan="2">Invoice Break-down</th>
                            </tr>
                            </thead>
                            <tbody>
                            {summary.invoice_breakdown.map((item, key)=>
                             <tr key={key}>
                                <td>{item.invoice_type.invoice_name}</td>
                                <td>UGX. {item.collection.amount_p}</td>
                             </tr>
                            )}



                             <tr>
                                <th colSpan="2">Totals</th>
                            </tr>
                            <tr>
                                <td>Cash</td>
                                <td>UGX. {summary.cash_bank.cash.amount_p}</td>
                            </tr>
                            <tr>
                                <td>Bank</td>
                                <td>UGX. {summary.cash_bank.bank.amount_p}</td>
                            </tr>

                            </tbody>
                        </table>
                        </div>
                        </div>
                        }

                            {!summary && <ActivityLoader/>}
                        </div>
                        <div className="modal-footer">

                            <button type="button" 
												onClick={()=>functions.printStrip("cashier-summary") }
												className="btn ripple btn-info mb-1">
													<i className="fe fe-printer mr-1"></i> Print</button>

                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>

                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopCashierDailySummary;
