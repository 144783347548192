import React from 'react';
import ActivityLoader from './Common/ActivityLoader';

class PopViewPlexImage extends React.Component {

    state = {
        plexRecord: this.props.image
    }

    render() {

        const record = this.state.plexRecord;

        return (
            <div className="modal" id="modal_view_plex_image">
                <div className="modal-dialog modal-dialog-centered modal-lg justify-content-center align-items-center" role="document">

                    {record && <img alt="avatar" src={record.profile_picture.file_path} />}

                    {!record && <ActivityLoader />}

                </div>
            </div>
        )


    }
}

export default PopViewPlexImage;
