import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
// import $ from 'jquery';
// import WarnDelete from '../../Components/WarnDelete'
// import Toast from '../../Components/Common/Toast'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import PopPaymentSummary from '../../Components/Payments/PopPaymentSummary'
import POSReceipt from '../../Components/Payments/POSReceipt'
import WrongParkingReceipt from '../../Components/Payments/WrongParkingReceipt'
import PopInvoice from '../../Components/PopInvoice'
import PageNav from '../../Components/Common/PageNav'
import ReservationInvoice from '../../Components/Invoices/ReservationInvoice'
import PopReservationReceipt from '../../Components/Reservation/PopReservationReceipt'

class ReservationPaymentRecords extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        menuOptions: '',
        currentPage: 1,
        transactionList: false,
        metaData: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        status: '',
        paymentView: false,
        receiptView: false,
        invoiceView: false,
        pagination: false,
        paymentMethod: '',
        paymentMethods:false
    }


    componentDidMount() {
        this.listTransactions();
        this.getPaymentMethods();

    }

    componentWillUnmount() {
        //clearTimeout(this.timerCountSession)
    }


    listTransactions = async () => {

        const server_response = await ajax.getReservationPaymentRecords(this.state.currentPage, this.state.paymentMethod);

        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())
        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }


    }



    onSelectRecord = (transactionId, record) => {
        this.setState({
            activeTransactionId: transactionId,
            selectedRecord: record,
            toast: false
        })
    }


    buttons = () => {
        return (
            <>
                <Link to="/finance/new-payment" className="btn ripple btn-success" onClick={this.onClickAddPayment}>
                    <i className="fe fe-plus-circle"></i> New Payment
            </Link>

            </>
        )
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }





    //==============NAVIGATION CONTROLS START===========================
    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.page + 1,
                transactionList: false

            }, () => {
                this.listTransactions();
            })
        }

    }

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            transactionList: false
        }, () => this.listTransactions())
    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.page - 1,
                transactionList: false

            }, () => {
                this.listTransactions();
            })
        }

    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {

                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })

            })

        }
    }





    //==============NAVIGATION CONTROLS END===========================



    onClickInvoice = (invoiceData) => {
        this.setState({
            invoiceView: false
        }, () => {
            this.setState({
                invoiceView: <ReservationInvoice record={invoiceData} />
            }, () => {
                functions.openPopup('modal_reservation_invoice');

            })

        })

    }

   
    onClickReceipt = (record) => {
       
        this.setState({
            receiptView: false
        }, () => {
            this.setState({
                receiptView: <PopReservationReceipt record={record} />
            }, () => {
                functions.openPopup('modal_payment_summary');
            })

        })
    }


    onChangePaymentMethod = (method) => {
        this.setState({
            paymentMethod: method,
            metaData: false,
            currentPage: 1,
            transactionList: false
        }, () => this.listTransactions())
    }



    getPaymentMethods = async () => {
       
        const server_response = await ajax.getAllPaymentModes();
        if (server_response.status === "OK") {
            this.setState({
                paymentMethods: server_response.details.content
            })
        } 
    }




    PaymentOptions=()=>{

            const paymentMethods=this.state.paymentMethods;
            const themes=['btn-secondary', 'btn-success', 'btn-dark'];
            return(
            <div className="text-wrap">
            <div className="example">
               
               {paymentMethods && <div className="btn-list">
               <button type="button"
                        className="btn ripple btn-primary btn-sm"
                        onClick={() => this.onChangePaymentMethod("")}
                    >
                        All Payments <span className="badge badge-light ml-2">...</span>
                    </button>
                 {paymentMethods.map((item, key)=>
                 <button
                        key={key}
                        type="button"
                        className={`btn ripple ${themes.length>key?themes[key]:'btn-light'} btn-sm`}
                        onClick={() => this.onChangePaymentMethod(item.mode_id)}
                    >
                        {item.mode_name} 
                        {/* <span className="badge badge-dark ml-2">...</span> */}
                    </button>
                 )}
                    
                   
                </div>}
            </div>
        </div>

            )
    }


    render() {
        const transactionList = this.state.transactionList;


        return (
            <div className="page">


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Reservation Payment records"
                            previousPageTitle="Reservation"
                            // options={this.state.menuOptions}
                            back="/parking-space/reservation"
                            //buttons={<this.buttons />}
                        />

                     

                        {this.state.toast}
                        {this.state.invoiceView}
                        {this.state.receiptView}

                       

                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Payment receipts"
                                            subtitle="List of all payment records from the most recent."
                                        />

                                        <this.PaymentOptions/>
                                       
                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Payment Date</th>
                                                        <th>Receipt ID</th>
                                                        <th>Invoice ID</th>
                                                        <th>Organisation</th>
                                                        <th>Payment Mode</th>
                                                        <th>Contact</th>
                                                        <th>Amount(UGX)</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {transactionList && transactionList !== "404" &&
                                                        transactionList.map((item, key) =>
                                                            <tr key={key}>
                                                                <td>{item.payment_date.when}</td>
                                                                <td>
                                                                    <Link to="#" onClick={() => this.onClickReceipt(item)}>
                                                                        {item.payment_ref}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to="#" onClick={() => this.onClickInvoice(item.invoice)}>
                                                                        {item.invoice.invoice_ref}
                                                                    </Link>
                                                                </td>
                                                                <td>{item.invoice.organisation.title}</td>
                                                                <td>{item.payment_mode.mode_name}</td>
                                                                <td>{item.depositor_contact}</td>
                                                                <td>{item.amount.amount_c}</td>
                                                               
                                                            </tr>)}

                                                </tbody>
                                            </table>


                                            {this.state.metaData && this.state.pagination}

                                            {!transactionList && <ActivityLoader />}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ReservationPaymentRecords
