import React, { useContext } from 'react'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import { RenderSecure } from '../../utils/script/RenderSecure'
import ContraventionsDashCard from '../../Components/Cards/OperationCards/ContraventionCard'
import RecentReportedWrongParking from '../../Components/RecentReportedWrongParkings'
import WrongParkingReport from '../../Components/Contraventions/WrongParkingReport'
import RecentPaymentDues from '../../Components/RecentPaymentDue'
import PaymentDueGraph from '../../Components/BarChart/PaymentDueGraph'
import PageContainer from '../../Components/Layout/PageContainer'
import ParkingViolationContext from '../../Context/Violation/ParkingViolationContext'
import { Link } from "react-router-dom"
import RecentEnforcement from '../../Components/Operations/Violation/RecentEnforcement'
import ReporterStats from '../../Components/Operations/Violation/ReporterStats'

const ContraventionsDashboard =()=> {

    const {
        reportedEnforcementCount,
        clampedEnforcementCount,
        getReportedEnforcementCount,
        getClampedEnforcementCount,
        getRecentTransactions
        
    } = useContext(ParkingViolationContext)

    const refreshCounts=()=>{
        getReportedEnforcementCount();
        getClampedEnforcementCount();
    }

    const refreshTransactions=()=>{
        getRecentTransactions()
    }

    const buttons = () => {
        return (
            <>

             <a className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                  
                    <Link to="/enforcement/clamp_transactions" className="dropdown-item">
                        <i className="fa fa-list text-primary mr-2"></i>Clamp Transactions</Link>
                    <Link to="#" className="dropdown-item" onClick={refreshCounts}>
                    <i className="fa fa-redo text-secondary mr-2"></i>Refresh Counts</Link>
                    <Link to="#" className="dropdown-item" onClick={refreshTransactions}>
                    <i className="fa fa-redo text-secondary mr-2"></i>Refresh Transactions</Link>

                  

              </div>


      
            </>
        )
      }

    const CardSection = () => {

        return (
            <>

                <RenderSecure code="R-WPARK-02">
                    <ContraventionsDashCard
                        className="col-sm-6 col-md-6 col-xl-6"
                        title1="Reported Wrong Parkings"
                        title2="Clamped Wrong Parkings"
                        icon="fa fa-gavel"
                        value1={reportedEnforcementCount?reportedEnforcementCount.wrong_parking.total_p : '...'}
                        value2={clampedEnforcementCount?clampedEnforcementCount.wrong_parking.total_p : '...'}
                        color1="mb-0 text-primary fs-13 ml-1"
                        color2="icon-service rounded-circle bg-secondary-transparent text-secondary"
                        href="#"
                    />
                </RenderSecure>

                <RenderSecure code="R-DUE-PAYMENT-02">
                    <ContraventionsDashCard
                        className="col-sm-6 col-md-6 col-xl-6"
                        title1="Reported Non-Payments"
                        title2="Clamped Non-Payments"
                        icon="fa fa-gavel"
                        value1={reportedEnforcementCount?reportedEnforcementCount.non_payment.total_p : '...'}
                        value2={clampedEnforcementCount?clampedEnforcementCount.non_payment.total_p : '...'}
                        color1="mb-0 text-success fs-13 ml-1"
                        color2="icon-service rounded-circle bg-success-transparent text-success"
                        href="#"
                    />
                </RenderSecure>

            </>
        )


    }


        return (
            <PageContainer>

                 <PageHeader
                        title="Contraventions"
                        previousPageTitle="Dashboard"
                        back="/operations"
                        buttons={buttons()}
                    />
                    <div className="row">
                        {CardSection()}
                    </div>

                    <div className="row">
                        <RenderSecure code='R-SESSION-02'>
                            <div className="col-sm-12 col-xl-8 col-lg-8">
                                <RecentEnforcement />
                                {/* <RecentReportedWrongParking href="/contraventions/wrong_parking" /> */}
                            </div>
                        </RenderSecure>

                        <RenderSecure code="R-MAP-02">
                            <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">

                               <ReporterStats />
                            </div>
                        </RenderSecure>
                    </div>
                    <div className="row">
                        <RenderSecure code='R-SESSION-02'>
                            <div className="col-sm-12 col-md-12 col-xl-5 col-lg-5">
                                {/* <RecentPaymentDues href="contraventions/payment_due" /> */}
                            </div>
                        </RenderSecure>

                        <RenderSecure code="R-MAP-02">
                            <div className="col-sm-12 col-md-12 col-xl-7 col-lg-7">
                                {/* <PaymentDueGraph /> */}
                            </div>
                        </RenderSecure>
                    </div>

            </PageContainer>

    );

}

export default ContraventionsDashboard
