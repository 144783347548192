import React, { useState } from 'react';
import ModalBox from '../Common/ModalBox';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';
import ajaxOperation from '../../utils/remote/ajaxOperation';
import AlertSuccess from '../Common/AlertSuccess';
import AlertError from '../Common/AlertError';

const PopBulkyLinking =(props)=>{

    const [info, setInfo] = useState(false);
    const [fileName, setFileName] = useState('');
    const [actualFile, setActualFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useState(props.clientId);

    const onConfirm=async(event)=>{

        event.preventDefault();
        if (fileName.length > 0) {
            setLoading(true);
            const server_response = await ajaxOperation.postBulkyVehicleLink({
                client_id : clientId,
                data_file : actualFile
            });
            setLoading(false)
            if(server_response.status=="OK"){         
                setInfo(<AlertSuccess message={server_response.details.message} />);
                setFileName('');
                setActualFile('');       
            }else{
                setInfo(<AlertError message={server_response.details.message} />)
            }

           
        } else {
            setInfo(<AlertError message={"Select CSV file and try again"} />);
        }

    }

    const onChangeFile = (event) => {
        
         setFileName(event.target.value);
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
          setActualFile({file:  e.target.result})
         }
         
       }


   
        return <ModalBox 
                    id="pop-bulky-linking" title="Bulky Vehicle Linking"
                    footer={
                        <>
                           {!loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={onConfirm}>Confirm Linking</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {loading && <ActivityLoader />}
                        </>
                    }
                    >
               
                        {info}
                            <form method="post" onSubmit={onConfirm}>

                              <p>Note: The CSV file must have one column labelled <b>Vehicle Number</b></p>

                            <TextInput
                                    label="Select CSV file"
                                    type="file"
                                    onChange={onChangeFile}
                                    value={fileName}
                                    name="FileName"
                                />



                            </form>
              </ModalBox>     
        
}

export default PopBulkyLinking;
