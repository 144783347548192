import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class PopNewTicketFloat extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        amount: '',
        info: '',
        searchList: false,
        userList: false,
        userRecord: false,
        loading: false
    }

    componentDidMount() {
        this.getUser()
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onConfirm = (event) => {
        event.preventDefault();
        if (this.state.amount > 0 && this.state.userRecord) {
            this.setState({
                loading: true
            })
            this.props.onConfirm(this.state.userRecord, this.state.amount);
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }




    getUser = async () => {

        const server_response = await ajax.listUsers();
        if (server_response.status === "OK") {
            this.setState({
                userList: server_response.details.content.list
            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }

    onChangeUser = (selectedOption) => {
        this.setState({
            userRecord: selectedOption.value
        })
    }

    render() {

        const userList = this.state.userList;
        const optionsUser = [];
        if (userList && userList !== "404") {
            userList.map((item, key) =>
                optionsUser.push({ label: item.first_name + ' ' + item.last_name + ' @' + item.username, value: item.user_id })
            )
        }

        const searchList = this.state.searchList;
        return (
            <div className="modal" id="modal_new_ticket_float">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Load Ticket Float</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <SelectSearch
                                    label="Sales Agent"
                                    onChange={() => this.onChangeUser}
                                    options={optionsUser}
                                    placeholder={"Select Sales Agent..."}
                                />


                                <TextInput
                                    label="Float Amount"
                                    type="text"
                                    onChange={() => this.onChangeAmount}
                                    value={this.state.plateNumber}
                                />


                            </form>

                            {searchList && searchList !== "404" && <div className="btn-list">
                                {searchList.map((item, key) =>
                                    <button key={key}
                                        onClick={() => this.onClickPlateNumber(item.plate_number)}
                                        type="button"
                                        className="btn ripple btn-light btn-sm">
                                        {item.plate_number}
                                    </button>)}
                            </div>}


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Load Float</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopNewTicketFloat;
