import React, { useContext, useEffect, useState } from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import Toast from '../../../Components/Common/Toast'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import PopUploadVehicleBalances from '../../../Components/DataMigration/PopUploadVehicleBalances'
import ajaxConfig from '../../../utils/remote/ajaxConfig'
import MigrationContext from '../../../Context/MigrationContext'
import AlertError from '../../../Components/Common/AlertError'
import WarnSecureDelete from '../../../Components/WarnSecureDelete'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import { restoreTable } from '../../../utils/script/customTables'
import PopUploadSticker from '../../../Components/DataMigration/PopUploadSticker'
const eformats = dictionary._exportConfigBtnFormats


const MigrateBalances=(props)=>{

    const [mdl, buildMdl] = useState(false);
    const [info, setInfo] = useState(false);
    const { balanceList, getBalanceList, message } = useContext(MigrationContext);

    useEffect(()=>{
        if(mdl){
            functions.openPopup("modal_vehicle_balances")
        }
    }, [mdl])

    const onClickUpload = ()=>{
        if(mdl)
        {
            buildMdl(false);
            setTimeout(() => {
                buildMdl(<PopUploadVehicleBalances refresh={getBalanceList}/>)
            }, 10);
           
        }else{
            buildMdl(<PopUploadVehicleBalances refresh={getBalanceList}/>)
        }

    }


    const onClickUploadSticker = ()=>{
        if(mdl)
        {
            buildMdl(false);
            setTimeout(() => {
                buildMdl(<PopUploadSticker/>)
            }, 10);
           
        }else{
            buildMdl(<PopUploadSticker/>)
        }

    }


    const onClickConfirm=()=>{

        if(mdl)
        {
            buildMdl(false);
            setTimeout(() => {
                buildMdl(<WarnSecureDelete
                    id="modal_vehicle_balances" 
                    title="Confirm Migration"
                    description="Confirm with your account password that you want to migrate pending opening balances; Payment due invoices will be created on the respective vehicle accounts."
                    icon="fas fa-question"
                    buttonLabel={"Confirm Migration"}
                    buttonType="btn-success"
                    onConfirm={confirMigrations}
                    />)
            }, 10);
           
        }else{
            
            buildMdl(<WarnSecureDelete
                id="modal_vehicle_balances" 
                title="Confirm Migration"
                description="Confirm with your account password that you want to migrate pending opening balances. Payment due invoices will be created on the respective vehicle accounts."
                icon="fas fa-question"
                buttonLabel={"Confirm Migration"}
                buttonType="btn-success"
                onConfirm={confirMigrations}
                />)
        }

    }

   

    const confirMigrations=async(password)=>{
        setInfo(false)
        const server_response = await ajaxConfig.processMigrationVehicleBalances(password);
        functions.closePopup("modal_vehicle_balances");
        if(server_response.status==="OK")
        {   restoreTable("table-migration") 
            getBalanceList();  
            setInfo(<AlertSuccess message={server_response.details.message}/>)          
        }else{
           setInfo(<AlertError message={server_response.details.message}/>)          
        }
    }


   const buttons = () => {
        return (
            <>
                <button className="btn ripple btn-primary" onClick={onClickUpload}>
                    <i className="fe fe-plus-circle mr-1"></i> Vehicle Balances
                </button>
                <button className="btn ripple btn-secondary" onClick={onClickUploadSticker}>
                    <i className="fe fe-plus-circle mr-1"></i> Stickers
                </button>
                <button className="btn ripple btn-success" onClick={onClickConfirm}>
                    <i className="fe fe-check-circle mr-1"></i> Confirm Migration
                </button>
            </>
        )
    }

        return (
            <div className="page">
                {mdl}
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Migration"
                            previousPageTitle="Configurations"
                            back="/config"
                            buttons={buttons()}
                        />



                        <div className="row">
                          
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">
                              

                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                <div>
                                                    <h6 className="card-title mb-1">Vehicle Balances</h6>
                                                    <p className="card-description">Upload vehicles with their total respective opening balances</p>
                                                </div>
                                                {info}

                                                <div className="table-responsive">
                                                       {Array.isArray(balanceList) && <table id="table-migration" className="table text-wrap mb-0 table-striped datatable" export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>No.</th>
                                                                    <th>Vehicle</th>
                                                                    <th>Opening Balance</th>
                                                                    <th>Status</th>
                                                                    <th>Last Modified</th>
                                                                    <th>Updated by</th>
                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {balanceList.map((item, key)=>
                                                                    <tr key={key}>
                                                                        <td>{key+1}</td>
                                                                        <td>{item.plate_number}</td>
                                                                        <td>{item.balance.amount_c}</td>
                                                                        <td>{item.status}</td>
                                                                        <td>{item.updated_at.short_date + " " + item.updated_at.time }</td>
                                                                        <td>{item.created_by.username}</td>
                                                                    </tr>
                                                                )}

                                                            </tbody>

                                                        </table>}
                                                        {!balanceList && <ActivityLoader/>}
                                                        {balanceList == "404" && <AlertError type="info" message={message}/>}
                                                </div>

                                               
                                            </div>
                                           
                                        </div>





                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>


                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    
}

export default MigrateBalances
