import { useContext, useEffect } from "react";
import ActivityLoader from "../../../Common/ActivityLoader";
import NoContent from "../../../Common/NoContent";
import ViolationReportContext from "../../../../Context/Violation/ViolationReportContext";
import TableHeader from "../../../Common/TableHeader";
import dictionary from "../../../../utils/dictionary";
const eformats = dictionary._exportBtnFormats

const ViolationAnalysis = () =>{

    const {
        clampReasonAnalysis,
        getClampReasonAnalysis,
        title
    } = useContext(ViolationReportContext)

    useEffect(()=>{
        if(!clampReasonAnalysis){
            getClampReasonAnalysis();
        }
    }, [])


    return(
        <div className="row">
          <div className="col-sm-12 col-xl-12 col-lg-12">

            <TableHeader
                title="Contravention type performance"
                subtitle="Analysis by Contravention type."
            />
                <div className="table-responsive  allView-table border-top">
                {Array.isArray(clampReasonAnalysis) && <table id="clamp-reason-analy" className={`table border-t0 key-buttons text-nowrap w-100 datatable`} export-format={eformats}>
                    <thead>
                     <tr>
                        <th colSpan={4} style={{textAlign:'center'}}>{title}<br/><br/></th>
                     </tr>
                     <tr>
                        <th>NO.</th>
                        <th>Type of Parking Contravention</th>
                        <th className='text-center'>Number of Penalties Issued</th>
                        <th className='text-center'>Street with Highest Violation </th>
                    </tr>
                    </thead>
                    <tbody>
                   { clampReasonAnalysis.map((item, key) =>
                                    <tr key={key}>
                                        <td>
                                            {key + 1}
                                        </td>

                                        <td>
                                            {item.violation.violation_name}
                                        </td>

                                        <td className='text-center'>
                                            {item.total_clamps.total_c}
                                        </td>

                                        <td className='text-center'>{item.highest_street.street.street_name}</td>

                                    </tr>
                                )}
                    </tbody>
                </table>}
                {clampReasonAnalysis === "404" && <NoContent />}
                {!clampReasonAnalysis && <ActivityLoader />}

            </div>
        </div>
    </div>)
}

export default ViolationAnalysis;