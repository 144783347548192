import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async getApprovedVehicleWhitelistRecord(vehicle_id) {

        let data = {
            "vehicle_id": vehicle_id
        }

        try {
            let response = await fetch(apiHost + 'whitelist/vehicle/approved',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    



}