import React from 'react'
import dictionary from '../../../utils/dictionary';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import NoContent from '../../../Components/Common/NoContent';
import TableHeader from '../../../Components/Common/TableHeader';
import LoadMoreButton from '../../../Components/Other/LoadMoreButton';
import ContraventionTypeContext from '../../../Context/ContraventionContext/ContraventionTypeContext';
const eformats = dictionary._exportBtnFormats

class AnalysisByViolationType extends React.Component {

    static contextType = ContraventionTypeContext;




    RenderLoadMore = () => {
        const { goToNextPage, loading, metaData, contraventionTypeTransactions } = this.context
        return <>
            {Array.isArray(contraventionTypeTransactions) &&
                <LoadMoreButton
                    action={goToNextPage}
                    loading={loading}
                    title={metaData.current_page + " of " + metaData.total_pages}
                />
            }
        </>

    }



    render() {

        const { contraventionTypeTransactions } = this.context

        return (

            <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">

                    <TableHeader
                        title="Contravention type performance"
                        subtitle="Analysis by Contravention type."
                    />

                    <div className="table-responsive  allView-table border-top">
                        <table id={this.props.tableID} className={`table  border-t0 key-buttons text-nowrap w-100 ${this.props.datatable}`} export-format={eformats}>
                            <thead>
                                <tr>
                                    <th>NO.</th>

                                    <th>Type of Parking Contravention</th>
                                    <th className='text-center'>Number of Penalties Issued</th>

                                    <th className='text-center'>Street with Highest Violation </th>

                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(contraventionTypeTransactions) && contraventionTypeTransactions.map((item, key) =>
                                    <tr key={key}>
                                        <td>
                                            {key + 1}
                                        </td>

                                        <td>
                                            {item.contravention_type}
                                        </td>

                                        <td className='text-center'>
                                            {item.total_penalties.total_c}
                                        </td>

                                        <td className='text-center'>{item.highest_street.street_name}</td>

                                    </tr>
                                )}


                            </tbody>

                        </table>








                        {contraventionTypeTransactions === "404" && <NoContent />}
                        {!contraventionTypeTransactions && <ActivityLoader />}

                    </div>
                    <this.RenderLoadMore />
                </div>
            </div>



        )

    }

}

export default AnalysisByViolationType
