import React from 'react';
import ajax from '../../utils/ajax';
import ActivityLoader from '../Common/ActivityLoader';
import { Line } from 'react-chartjs-2';
import TableHeader from '../Common/TableHeader';


class WeeklyStreetOccupancyGraph extends React.Component {
    state = {
        streetId: this.props.streetId,
        chartData: false,
        transactionData: false,
        dataSections: false,
        borderColors: ['rgba(113, 76, 190, 0.9)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.6)', 'rgba(228, 37, 40, 0.6)', 'rgba(228, 224, 37, 0.6)'],
        backgroundColors: ['rgba(113, 76, 190, 0.5)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.8)', 'rgba(228, 37, 40, 0.8)', 'rgba(228, 224, 37, 0.8)']

    }

    async componentDidMount() {
        await this.fetchData();

        this.timerCount = setTimeout(this.funcCount = async () => {
            await this.fetchData();
            this.timerCount = setTimeout(this.funcCount, 5000); // (*)
        }, 5000)
    }

    componentWillUnmount() {
        clearTimeout(this.timerCount)
    }




    fetchData = async () => {
        const server_reponse = await ajax.getWeeklyStreetOccupancyData(this.state.streetId);
        if (server_reponse.status === "OK") {
            this.setState({
                transactionData: server_reponse.details.content
            }, () => {

                const days = [this.state.transactionData.days[7]];//start with sunday
                for (var i = 1; i < 7; i++) {
                    days.push(this.state.transactionData.days[i]);
                }
                const chartData = { labels: days, datasets: [] };



                const rowData = [this.state.transactionData.data['7']['total']];//start with sunday

                for (var i = 1; i < 7; i++) {
                    rowData.push(this.state.transactionData.data[i]['total']);
                }

                chartData.datasets.push({
                    label: "Occupied Slots",
                    data: rowData,
                    fill: true,
                    lineTension: 0.5,
                    borderWidth: 2,
                    backgroundColor: this.state.backgroundColors[1],
                    borderColor: this.state.borderColors[1]
                })


                this.setState({ chartData: chartData })
            })
        }
    }





    render() {

        return (
            <div className="card custom-card">
                <div className="card-body">

                    <div className="card-option d-flex">
                        {/* <div>
                            <h6 className="card-title mb-1">Street Weekly Occupancy</h6>
                            <p className="text-muted card-sub-title">Overview of total slots occupancy in this week.</p>
                        </div> */}

                        <TableHeader
                            title={"Street Weekly Occupancy"}
                            subtitle="Overview of total slots occupancy in this week"
                        // view="View All"
                        // href="/finance/sticker-records"
                        />

                        <div className="card-option-title ml-auto">
                            <div className="btn-group p-0">
                                <button
                                    className="btn btn-light btn-sm"
                                    type="button"
                                //onClick={()=>this.props.onChangeView('WEEK')}
                                >Weekly</button>
                                {/* <button 
                        className="btn btn-outline-light btn-sm" 
                        type="button"
                        onClick={()=>this.props.onChangeView('MONTH')}
                        >Monthly</button> */}
                            </div>
                        </div>
                    </div>
                    <div>


                        {this.state.chartData && this.state.chartData !== "404" && <Line
                            data={this.state.chartData}
                            height={80}
                            options={{
                                title: {
                                    display: false,
                                    //text:'Graph title',
                                    fontSize: 20
                                },
                                legend: {
                                    display: false,
                                    position: 'top'
                                }

                            }}
                        />}

                        {!this.state.chartData && <ActivityLoader />}


                    </div>


                </div>
            </div>
        );


    }
}

export default WeeklyStreetOccupancyGraph;