import React, { Component } from 'react';
import Footer from '../../Components/Common/Footer';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import PageHeader from '../../Components/Common/PageHeader';
import RightSideBar from '../../Components/Common/RightSideBar';
import TitleValueCard from '../../Components/Common/TitleValueCard';
import TopBar from '../../Components/Common/TopBar';
import ConfigMenu from '../../Components/ConfigMenu';
import functions from '../../utils/functions';
import Code from './Code';
import ProjectMenu from './ProjectMenu';

class ProjectDocument extends Component {

    state = {}

    componentDidMount() { }

    render() {
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">

                        <PageHeader
                            title="Project Documentation"
                            previousPageTitle="Dashboard"
                            back="/config"
                        // options={{
                        //       "add": () => this.onClickAdd(),
                        //       "edit": () => this.onClickEdit(),
                        //       "delete": () => this.onClickDelete()
                        //   }}
                        />

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">

                                <div className="card custom-card">

                                    <div className="card-body">

                                        {/* <div className="row">

                                    <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "rgb(63, 225, 147) none repeat scroll 0% 0%" }}>
                                                    <img src="images/icon/124.svg" alt="icon" />
                                                </div>
                                                <div class="text">
                                                    <h4>Sports &amp; Fitness</h4>
                                                    <p>Personal trainers, Gyms Fitness classes, Yoga classes Golf classes, Sport items renting</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "rgb(255, 79, 134) none repeat scroll 0% 0%" }}>
                                                    <img src="images/icon/125.svg" alt="icon" />
                                                </div>
                                                <div class="text">
                                                    <h4>Beauty and Wellness</h4>
                                                    <p>Eyelash extensions , Hair salons, Spa salons Beauty salons, Nail salons</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "rgb(255, 207, 57) none repeat scroll 0% 0%" }}>
                                                    <img src="images/icon/126.svg" alt="icon" />
                                                </div>
                                                <div class="text">
                                                    <h4>Events &amp; entertainment</h4>
                                                    <p>Art classes, Escape rooms Photographers, Equipment Rental &amp; more.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                        <div class="block-style-twentyFour">
                                            <div class="d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center" style={{ background: "rgb(109, 100, 255) none repeat scroll 0% 0%" }}>
                                                    <img src="images/icon/127.svg" alt="icon" />
                                                </div>
                                                <div class="text">
                                                    <h4>Officials &amp; Financial</h4>
                                                    <p>Embassies and consulates, City councils, Call centers Financial services, Interview scheduling.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                                        {/* <div className="row">

                                    <TitleValueCard href="/config/branches" title="Total Branches" value={this.state.totalBranches} icon="fas fa-code-branch" color="icon-service rounded-circle bg-primary-transparent text-primary" />
                                    <TitleValueCard href="/config/streets" title="Total Streets" value={this.state.totalStreets} icon="fas fa-street-view" color="icon-service bg-info-transparent rounded-circle text-info" />
                                    <TitleValueCard href="/config/locations" title="Total Locations" value={this.state.totalLocations} icon="fas fa-location-arrow" color="icon-service bg-success-transparent rounded-circle text-success" />
                                    <TitleValueCard href="/config/devices" title="Total Devices" value={this.state.totalDevices} icon="fas fa-mobile-alt" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />
                                    <TitleValueCard href="/config/clamp_tools" title="Total Clamp Tools" value={this.state.totalClampTools} icon="fas fa-magnet" color="icon-service rounded-circle bg-warning-transparent text-warning" />
                                    <TitleValueCard href="/config/clamping/thresholdfee" title="Clamp Threshold" value={this.state.clampThreshold} icon="fas fa-fire" color="icon-service bg-secondary-transparent rounded-circle text-secondary" />
                                    <TitleValueCard href="/config/wrongparking" title="Wrong Parking" value={this.state.wrongParkingFee} icon="fas fa-parking" color="icon-service bg-success-transparent rounded-circle text-success" />
                                    <TitleValueCard href="/config/holiday" title="Public Holidays" value={this.state.totalHolidays} icon="fas fa-gift" color="icon-service rounded-circle bg-info-transparent text-info" />
                                    <TitleValueCard href="/config/surcharge" title="Surcharge" value={this.state.surcharge} icon="fas fa-money-bill-alt" color="icon-service rounded-circle bg-primary-transparent text-primary" />
                                    <TitleValueCard href="/config/roles" title="Total Roles" value={this.state.totalRoles} icon="fa fa-tasks" color="icon-service bg-secondary-transparent rounded-circle text-secondary" />
                                    <TitleValueCard href="/config/permissions" title="Total Permissions" value={this.state.totalPermissions} icon="fas fa-lock-open" color="icon-service rounded-circle bg-warning-transparent text-warning" />
                                    <TitleValueCard href="/config/notifications" title="Notifications" value={this.state.totalNotifications} icon="fas fa-bell" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />
                                    <TitleValueCard href="/config/payment_modes" title="Payment Modes" value={this.state.totalPaymentModes} icon="fas fa-hand-holding-usd" color="icon-service bg-primary-transparent rounded-circle text-primary" />
                                    <TitleValueCard href="/config/towing-stations" title="Towing Stations" value={this.state.totalTowStations} icon="fas fa-parking" color="icon-service bg-info-transparent rounded-circle text-info" />
                                    <TitleValueCard href="/vehicles/all-vehicles" title="Registered vehicles" value={this.state.statistics} icon="fas fa-car" color="icon-service bg-success-transparent rounded-circle text-success" />
                                    <TitleValueCard href="/config/sticker-types" title="Sticker Types" value={this.state.totalStickerTypes} icon="fas fa-barcode" color="icon-service rounded-circle bg-info-transparent text-info" />
                                    <TitleValueCard href="/config/car-types" title="Vehicle Types" value={this.state.totalVehicleTypes} icon="fas fa-car" color="icon-service rounded-circle bg-primary-transparent text-primary" />


                                    <TitleValueCard href="/config/bankingclient" title="Banking Clients" value={this.state.totalBanks} icon="fas fa-university" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />

                                </div> */}


                                        <section class="wrapper__content">
                                            <h2 style={{ marginBottom: "25px" }}>Welcome to the Multiplex PMIS</h2>

                                            <div className="content__default">
                                                <p>
                                                    The Parking Management System (PMIS) is an information system that manages the parking in the kampala streets and other areas partaining in parking outside kampala. Its major role is to provide real time information about what is taking place during parking activity of the people and their cars on the streets of kampala. This information provided is later used for important information generation that will govern the day to day activities or in this case street parking on the kampala streets. This platform is also used for automating and creating of reports that will in the end be used for information generation.
                                                </p>
                                                <h4 style={{ margin: "37px 0 25px" }}>
                                                    <a href="#get-started" class="header-anchor">#</a> Get started
                                                </h4>
                                                <p>
                                                    In this documentation, we will take you through a <a href="#" class="">step-by-step tutorial</a> to help you navigate through the system. To get started with PMIS, you need to have an account to access the system which then you can log in to it and start using it. For next steps, you need access to the system and to do that you need permissions to be given or issued to you by the administation.
                                                </p>
                                                {/* <h4 style={{ margin: "37px 0 25px" }}>
                                                    <a href="#discover-netlify" class="header-anchor">#</a> Discover Netlify
                                                </h4>
                                                <p>
                                                    can learn how to <a href="/site-deploys/create-deploys/" class="">create deploys</a>. Deployment options range from connecting a Git repository for <a href="/configure-builds/overview/" class="">continuous deployment</a> to publishing a site folder with <a href="https://app.netlify.com/drop/" target="_blank" rel="noopener noreferrer">drag and drop</a>. Use the <strong>Deploy to Netlify</strong> button on our <a href="https://templates.netlify.com" target="_blank" rel="noopener noreferrer">templates</a> to create a Git repository linked to a Netlify site that you can experiment with
                                                </p>
                                                <p>
                                                    Explore our documentation to learn about Netlify products and features. Here are some highlights from each section of the docs:
                                                </p>
                                                <p>
                                                    <strong>Configure builds</strong> – Netlify can run your build command and deploy the result whenever you push to your Git repo. Get started with <a href="/configure-builds/overview/" class="">basic build settings</a>, learn about <a href="/configure-builds/manage-dependencies/" class="">managing build dependencies</a>, and explore additional options available with <a href="/configure-builds/file-based-configuration/" class="">file-based configuration</a>.
                                                </p>
                                                <p>
                                                    <strong>Site deploys</strong> – <a href="/site-deploys/overview/" class="">Atomic deploys</a> with Netlify guarantee that your site is always consistent. Learn how to <a href="/site-deploys/manage-deploys/" class="">manage deploys</a>, enable <a href="/site-deploys/notifications/" class="">deploy notifications</a>, and run a <a href="/site-deploys/split-testing/" class="">branch-based split test</a>. Use the power of <a href="/site-deploys/deploy-previews/" class="">Deploy Previews</a> to review site changes and collaborate with team members.
                                                </p>
                                                <p>
                                                    <strong>Monitor sites</strong> – Learn how <a href="/monitor-sites/analytics/" class="">Netlify Analytics</a> lets you monitor trends in site activity. <a href="/monitor-sites/monitor-builds/" class="">Monitor builds</a> to understand the role individual sites play in your team’s builds usage. Explore what kinds of <a href="/monitor-sites/logs/" class="">logs</a> and <a href="/monitor-sites/notifications/" class="">notifications</a> are available for your sites.
                                                </p> */}
                                            </div>

                                        </section>


                                    </div>
                                </div>

                            </div>
                            <ProjectMenu />
                            {/* <Code /> */}
                        </div>
                    </div>
                </div>
                <RightSideBar />
                 
            </div>
        );
    }
}

export default ProjectDocument;
