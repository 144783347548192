import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import functions from '../../../../utils/functions';
import PopTaxProfileForm from './PopTaxProfileForm';

class PopUpdateTaxProfile extends Component {

    state={
        mdl:false
    }

    handleClick=()=>{
        this.setState({
            mdl:false
        }, ()=>{
            this.setState({
                mdl:<PopTaxProfileForm {...this.props}/>
            }, ()=>{
                functions.openPopup("modal_tax_profile_form")
            })
           
        })
    }


    render() {
        return (
            <>
            {this.state.mdl}
            <Link to="#" className="card-link" onClick={this.handleClick}>
            <i className="fas fa-user tx-danger mr-1"/>
            Update Tax Profile
        </Link>
        </>
        );
    }
}

export default PopUpdateTaxProfile;