import React from 'react';


class FltVehicleInfo extends React.Component {
  

  render(){
    
      return (
        <button 
        type="button" 
        className="btn ripple btn-primary btn-sm"
        onClick={this.props.onClick}>
        Vehicle Profile
        </button>
      );
    
 
  }
}

export default FltVehicleInfo;