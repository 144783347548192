import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'
import AlertWarning from '../../../Components/Common/AlertWarning'
import ajaxViolation from '../../../utils/remote/ajaxViolation'
import ActivityLoader from '../../../Components/Common/ActivityLoader'

class CreateClampTool extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    clampNumber: '',
    //   lat:'',
    //   lng:'',
    info: '',
    loading:false
  }



  onChangeclampNumber = (event) => {
    this.setState({ clampNumber: event.target.value })
  }

  // onChangeLat=(event)=>{
  //     this.setState({lat:event.target.value})
  // }

  // onChangeLng=(event)=>{
  //     this.setState({lng:event.target.value})
  // }

  onSave = async (event) => {
    event.preventDefault();
    const { clampNumber } = this.state;

    if (clampNumber.length > 0) {
      this.setState({
        info: false,
        loading:true
      })
      //fetch login from the API
      const server_response = await ajaxViolation.createClampTool({'tool_name':clampNumber});
      this.setState({
        loading:false
      })
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })
        this.setState({
          clampNumber: '',
          lat: '',
          lng: ''
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._requireclampNumber} />,
        loading: ''
      })
    }


  }

  render() {
    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="New Clamp tool"
              previousPageTitle="Clamp tools"
              back="/config/clamp_tools"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>

                          {!this.state.info && <AlertWarning message="Use this format 'C1-01'. Do not include the 'MLPX/SP' tag, It will be added automatically. " />}

                          <TextInput
                            label="ClampTool Number"
                            type="text"
                            onChange={() => this.onChangeclampNumber}
                            value={this.state.clampNumber}
                          />

                          {/* <TextInput 
                                              label="Geo Latitude"
                                              type="text"
                                              onChange={()=>this.onChangeLat}
                                              value={this.state.lat}
                                              />

                                          <TextInput 
                                              label="Geo Longitude"
                                              type="text"
                                              onChange={()=>this.onChangeLng}
                                              value={this.state.lng}
                                              />
 */}

                          <div className="modal-footer">
                             {!this.state.loading && <input className="btn ripple btn-success" type="button" value="Save ClampTool" onClick={this.onSave} />}
                             {this.state.loading && <ActivityLoader/>}
                          </div>
                        </form>
                      </div>
                    </div>


                  </div>





                </div>

              </div>

              <ConfigMenu />



            </div>
          </div>
        </div>
        <RightSideBar />

      </div>


    );

  }
}

export default CreateClampTool
