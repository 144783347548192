import React from 'react';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import AlertError from '../../../Components/Common/AlertError';
import Footer from '../../../Components/Common/Footer';
import LeftSideBar from '../../../Components/Common/LeftSideBar';
import PageHeader from '../../../Components/Common/PageHeader';
import RightSideBar from '../../../Components/Common/RightSideBar';
import TableHeader from '../../../Components/Common/TableHeader';
import TopBar from '../../../Components/Common/TopBar';
import ConfigMenu from '../../../Components/ConfigMenu';
import functions from '../../../utils/functions';
import dictionary from '../../../utils/dictionary';
import PopAssignPermission from '../../../Components/Usermanagement/PopAssignPermission';
import ajaxAccess from '../../../utils/remote/ajaxAccess';
import WarnAction from '../../../Components/WarnAction';
import Toast from '../../../Components/Common/Toast';

class ManageRoleAccess extends React.Component {

    state = {
       
        userId: functions.sessionGuard(),
        info: '',
        loading: false,

        permissionId: '',
        permission: '',
        searchList: false,
        permissionList: false,
        permissionRecord: '',

        selectedPermissions:[],
        mdl: false,
        selectAll:false,

        roleId:'',
        roleName:'',
        toast:false
    }

   
   componentDidMount() {
    if (!this.props.location.state) {
        this.props.history.push('/config/roles');
      } else {
        this.setState({
            roleId: this.props.location.state.role_id,
            roleName: this.props.location.state.role_name
        }, () => {
            this.getRolePermissions(this.state.roleId)
        })
      }
   }

    onClickPermission = (permissionId) => {
       
            const selectedPermissions = this.state.selectedPermissions;
            const objectIndex = functions.findInObject(selectedPermissions, permissionId);
            if(objectIndex)
            {
                selectedPermissions.splice(objectIndex, 1); 
                this.setState({selectedPermissions: selectedPermissions}, ()=>this.setState({selectAll:false}))

            }else{
                selectedPermissions.push(permissionId);
                this.setState({selectedPermissions: selectedPermissions})

            }
          
    }



    onConfirm = async () => {
        this.setState({
            info: ''
        })
        const { roleId, permissionId } = this.state;


        if (roleId.length > 0 && permissionId.length > 0) {
            this.setState({
                loading: true
            }, () => this.props.onConfirm(roleId, permissionId))
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }

   
    buttons = () => {
       
        return (
            <>
            {this.state.selectedPermissions.length>0 && <>
                <button className="btn ripple btn-success" onClick={this.onClickAssignToUser}>
                    <i className="fe fe-plus-circle mr-1"></i> Assign to user ( {this.state.selectedPermissions.length} )
                </button>

                <button className="btn ripple btn-secondary" onClick={this.onClickAssignToRole}>
                    <i className="fas fa-plus mr-1"></i> Add to role ( {this.state.selectedPermissions.length} )
                </button>
                </>}
            </>
        )
    }


    onClickAssignToUser=()=>{
        this.setState({
            mdl:false
        }, ()=>{

            this.setState({
                mdl:<PopAssignPermission 
                        target="USER"
                        permissions={this.state.selectedPermissions}
                        />
            }, ()=>functions.openPopup("modal_assign_permission"))

        })
       
    }


    getRolePermissions=async(roleId)=>{
        const server_response = await ajaxAccess.getRoleAccess(roleId);
        if(server_response.status==="OK")
        {
          this.setState({
            permissionList:server_response.details.content
          })
        }else{
          this.setState({
              permissionList:"404"
          })
        }
      }


    onClickDeny=(code, permission_id)=>{
        this.setState({
            mdl:false
        }, ()=>{

            this.setState({
                mdl:<WarnAction 
                        title="Deny Permission" 
                        id="warn_deny"
                        buttonLabel="Confirm Deny Permission"
                        description={"Confirm that you want to deny this role permission code: " + code + ". Users with this role will be denied this permission."}
                        onConfirm={()=>this.denyRolePermission(permission_id)}
                        />
            }, ()=>functions.openPopup("warn_deny"))
        })
       
    }


    denyRolePermission=async(permission_id)=>{
        this.resetToast();
        const server_response = await ajaxAccess.denyRoleAccess(this.state.roleId, permission_id);
        functions.closePopup("warn_deny")
        if(server_response.status==="OK")
        {
          this.setState({
            toast:<Toast type={0} message={server_response.details.message}/>
          }, ()=>this.getRolePermissions(this.state.roleId))
        }else{
          this.setState({
            toast:<Toast type={2} message={server_response.details.message}/>
          })
        }
      }


      resetToast = () => {
        this.setState({
            toast: false
        })
    }
   
  

    RenderPermissions=()=>{
        const permissionList = this.state.permissionList;

            return(
               <>
                <table className="table m-auto">
                    <thead>
                        <tr><th colSpan={3}>Currently Role Assigned Permissions</th></tr>
                    </thead>
                            <tbody>
                               
                                {Array.isArray(permissionList) && permissionList.map((item, key) =>
                                        <tr key={key}>
                                
                                            <td className="">
                                                <h6 className="mg-b-0">{item.permission.permission}</h6>
                                                <small className="tx-12 tx-gray-700">
                                                    Granted on: {item.created_at.short_date}&nbsp;&nbsp;<small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                </small>
                                                <br />
                                                <small className="tx-12 tx-gray-700">
                                                    PermissionId: {item.permission_id}
                                                </small>&nbsp;&nbsp;
    
                                            
                                            </td>
                                            <td className="">
                                                <h6 className="mg-b-0">Permission Info</h6>
                                                <small className="tx-12 tx-gray-700">Description: {item.permission.description}</small><br></br>
                                                <small className="tx-12 tx-gray-700">Module: {item.permission.module.department_module}</small><br></br>
                                            </td>
                                            <td>
                                                <button className='btn btn-sm btn-danger' onClick={()=>this.onClickDeny(item.permission.permission, item.permission_id)}>Deny</button>
                                            </td>
    
                                        </tr>)}
                            </tbody>
                        </table>
    
                        {!permissionList && <ActivityLoader/>}
                        {permissionList==="404" && <AlertError type="info" message={"Role has no access permissions"}/>}
             </>
        )
    }

    render() {
       
        return (
            <div className="page">
                {this.state.mdl}
                {this.state.toast}
                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />

                    <div className="container-fluid">
                        <PageHeader
                            title="Manage Role Permissions"
                            previousPageTitle="System Roles"
                            back="/config/roles"
                            buttons={<this.buttons />}
                            
                        />

                        <div className="row">


                            <div className="col-xl-9 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title={this.state.roleName}
                                            subtitle="Review and deny role assigned permissions"
                                        />
                                        <div className="overflow-auto perms-table">

                                        <div className="table-responsive">
                                           <this.RenderPermissions/>
                                        </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <ConfigMenu />
                        </div>
                    </div>
                </div>

                <RightSideBar />
                 
            </div>
        );
    }
}

export default ManageRoleAccess;