import React, { useEffect, useState } from 'react';
import ajaxEfris from '../../utils/remote/ajaxEfris';

const FiscalReceiptContext = React.createContext();

export const FiscalReceiptConsumer = FiscalReceiptContext.Consumer;

export const FiscalReceiptProvider = (props)=> {

  const [allTransactions, setAllTransactions] = useState(false);

  const [data, setData] = useState({"page":"1"})
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(false);
  const [currentMonthTax, setCurrentMonthTax] = useState(false);
  const [lastMonthTax, setLastMonthTax] = useState(false);
  const [currentMonthCreditNote, setCurrentMonthCreditNote] = useState(false);
  const [lastMonthCreditNote, setLastMonthCreditNote] = useState(false);
  const [dailyTax, setDailyTax] = useState(false);

  useEffect(()=>{
    getLastMonthTax();
    getCurrentMonthTax();
    getCurrentMonthCreditNote();
    getLastMonthCreditNote();
    getDailyTax();
  }, []);

  useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])


useEffect(()=>{
  getAllTransactions();
}, [data])


  const getAllTransactions=async()=>{
    setLoading(true)
    setAllTransactions(false)
    const server_response = await ajaxEfris.fetchReceiptRecords(data);
    setLoading(false)
    if(server_response.status==="OK"){
      setAllTransactions(server_response.details.content.list)
      setMetaData(server_response.details.content.meta);
    }else{

      setAllTransactions("404");
      setMetaData("404");
    }
  }



  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);
    // restoreTable(detectTable());

    const server_response = await ajaxEfris.fetchReceiptRecords(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.content.list);
        setAllTransactions(allTransactions.concat(incoming));
        setMetaData(server_response.details.content.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "clamp_cause":options.clampCause,
        "page":"1",
        "plate_number":options.plateNumber,
        "status":options.status,
        "location":options.location,
        "clamp_incident":options.incident,
        "cobra":options.cobra

    })
    
}



const getLastMonthTax=async()=>{
    setLastMonthTax(false);
    const server_response = await ajaxEfris.fetchLastMonthTax();
    if(server_response.status === "OK"){
        setLastMonthTax(server_response.details.content);
    }
}

const getCurrentMonthTax=async()=>{
    setCurrentMonthTax(false);
    const server_response = await ajaxEfris.fetchCurrentMonthTax();
    if(server_response.status === "OK"){
        setCurrentMonthTax(server_response.details.content);
    }
}


const getLastMonthCreditNote=async()=>{
    setLastMonthCreditNote(false);
    const server_response = await ajaxEfris.fetchLastMonthCreditNote();
    if(server_response.status === "OK"){
        setLastMonthCreditNote(server_response.details.content);
    }
}

const getCurrentMonthCreditNote=async()=>{
    setCurrentMonthCreditNote(false);
    const server_response = await ajaxEfris.fetchCurrentMonthCreditNote();
    if(server_response.status === "OK"){
        setCurrentMonthCreditNote(server_response.details.content);
    }
}

const getDailyTax=async()=>{
    setDailyTax(false);
    const server_response = await ajaxEfris.fetchDailyTax();
    if(server_response.status === "OK"){
        setDailyTax(server_response.details.content);
    }
}


const refreshTax = async()=>{
    getCurrentMonthCreditNote();
    getLastMonthCreditNote();
    getCurrentMonthTax();
    getLastMonthTax();
    getDailyTax();
    resetData();

}
    
    return (
           <FiscalReceiptContext.Provider value={
               {
                  allTransactions,
                  metaData,
                  loading,
                  currentMonthTax,
                  lastMonthTax,
                  dailyTax,
                  currentMonthCreditNote,
                  lastMonthCreditNote,
                  getAllTransactions,
                  getCurrentMonthTax,
                  getDailyTax,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData,
                  refreshTax
               }
               }>
               {props.children}
           </FiscalReceiptContext.Provider>
        );
    
}

export default FiscalReceiptContext;