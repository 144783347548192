import React from 'react';
import $ from 'jquery';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import AlertError from '../../../Components/Common/AlertError';
import Footer from '../../../Components/Common/Footer';
import LeftSideBar from '../../../Components/Common/LeftSideBar';
import PageHeader from '../../../Components/Common/PageHeader';
import RightSideBar from '../../../Components/Common/RightSideBar';
import TableHeader from '../../../Components/Common/TableHeader';
import TopBar from '../../../Components/Common/TopBar';
import ConfigMenu from '../../../Components/ConfigMenu';
import functions from '../../../utils/functions';
import dictionary from '../../../utils/dictionary';
import PopAssignPermission from '../../../Components/Usermanagement/PopAssignPermission';
import { ManageUserConsumer } from '../../../Context/ManageUserContext';

class AssignPermsToRole extends React.Component {

    state = {
       
        userId: functions.sessionGuard(),
        info: '',
        loading: false,

        permissionId: '',
        permission: '',
        searchList: false,
        permissionList: false,
        permissionRecord: '',

        selectedPermissions:[],
        mdl: false,
        selectAll:false
    }

   
    componentDidMount() {
       
    }
  

    onClickPermission = (permissionId) => {
       
            const selectedPermissions = this.state.selectedPermissions;
            const objectIndex = functions.findInObject(selectedPermissions, permissionId);
            if(objectIndex)
            {
                selectedPermissions.splice(objectIndex, 1); 
                this.setState({selectedPermissions: selectedPermissions}, ()=>this.setState({selectAll:false}))

            }else{
                selectedPermissions.push(permissionId);
                this.setState({selectedPermissions: selectedPermissions})

            }
          
    }



    onConfirm = async () => {
        this.setState({
            info: ''
        })
        const { roleId, permissionId } = this.state;


        if (roleId.length > 0 && permissionId.length > 0) {
            this.setState({
                loading: true
            }, () => this.props.onConfirm(roleId, permissionId))
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }

   
    buttons = () => {
       
        return (
            <>
            {this.state.selectedPermissions.length>0 && <>
                <button className="btn ripple btn-success" onClick={this.onClickAssignToUser}>
                    <i className="fe fe-plus-circle mr-1"></i> Assign to user ( {this.state.selectedPermissions.length} )
                </button>

                <button className="btn ripple btn-secondary" onClick={this.onClickAssignToRole}>
                    <i className="fas fa-plus mr-1"></i> Assign to role ( {this.state.selectedPermissions.length} )
                </button>
                </>}
            </>
        )
    }


    onClickAssignToUser=()=>{
        this.setState({
            mdl:false
        }, ()=>{

            this.setState({
                mdl:<PopAssignPermission 
                        target="USER"
                        permissions={this.state.selectedPermissions}
                        />
            }, ()=>functions.openPopup("modal_assign_permission"))

        })
       
    }
   
    onClickAssignToRole=()=>{

        this.setState({
            mdl:false
        }, ()=>{
            this.setState({
                mdl:<PopAssignPermission target="ROLE" permissions={this.state.selectedPermissions}/>
            }, ()=>functions.openPopup("modal_assign_permission"))
        })
    }

    setSelectAll=(permissionList)=>{
        this.setState({
            selectAll:!this.state.selectAll
        }, ()=>{
            if(this.state.selectAll)
            {
                //Loop through all permissions
                const list =[];
                if(Array.isArray(permissionList)){
                    permissionList.map((item, key) =>{
                        list.push(item.permission_id)
                    })

                    this.setState({
                        selectedPermissions:list
                    })
                }
                                                        
            }else{
                this.setState({
                    selectedPermissions:[]
                })
            }
        })
    }


    RenderPermissions=()=>{
        return(
            <ManageUserConsumer>
                {props=>{
                     const permissionList = props.permissionList;
                    return(
                        <div className="scroll-widget" style={{height:"480px", overflow:"auto"}}>
                        <div className="table-responsive">
                        <table id={props.permissionTable} className="table table-striped text-nowrap w-100 datatable"> 
                        <thead>
                            <tr>
                               
                                <th>
                                    <div className="form-options-wrappe">
                                        <div className="custom-control custom-checkbox login-card-check-box">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"                                                                                // onClick={() => this.onClickPermission(item.permission_id)}
                                                checked={this.state.selectAll}
                                                value={1}
                                                readOnly
                                            />
                                            <label className="custom-control-label" onClick={()=>this.setSelectAll(permissionList)}>All</label>
                                        </div>
                                    </div>
                                </th>
                                <th>Code</th>
                                <th>Module</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            {permissionList && permissionList !== "404" &&
                                permissionList.map((item, key) =>
                                    <tr
                                        id={"br_" + item.permission_id}
                                        key={key}
                                        // className={`${this.state.permissionId === item.permission_id ? 'flag-row' : ''}`}
                                        onClick={() => this.onClickPermission(item.permission_id)}
                                    >

                                        <td>

                                        <div className="form-options-wrappe">
                                                <div className="custom-control custom-checkbox login-card-check-box">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={item.permission_id}
                                                        value={item.permission_id}
                                                        // onClick={() => this.onClickPermission(item.permission_id)}
                                                        checked={functions.findInObject(this.state.selectedPermissions, item.permission_id)}
                                                        readOnly
                                                   />
                                                    <label className="custom-control-label" htmlFor={item.permission_id}></label>
                                                </div>
                                            </div>

                                        </td>
                                        <td>{item.permission}</td>
                                        <td>{item.module.department_module}</td>
                                        <td>{item.description}</td>

                                    </tr>)}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    )
                }}
            </ManageUserConsumer>
        )
    }

    render() {
       
        return (
            <div className="page">
                {this.state.mdl}
                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />

                    <div className="container-fluid">
                        <PageHeader
                            title="Permission Assignment"
                            previousPageTitle="User Management"
                            back="/users"
                            buttons={<this.buttons />}
                            
                        />

                        <div className="row">


                            <div className="col-xl-9 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="All system Permissions"
                                            subtitle="All the system permissions."
                                        />
                                        <div className="overflow-auto perms-table">

                                        <div className="table-responsive">
                                           <this.RenderPermissions/>
                                        </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <ConfigMenu />
                        </div>
                    </div>
                </div>

                <RightSideBar />
                 
            </div>
        );
    }
}

export default AssignPermsToRole;