import React, { Component } from 'react';
import LocationPerformance from '../../Components/Operations/Monitor/LocationPerformance';
import OpClamps from '../../Components/Operations/Monitor/OpClamps';
import OpSessions from '../../Components/Operations/Monitor/OpSessions';
import OpStatistics from '../../Components/Operations/Monitor/OpStatistics';
import SessionEndRequests from '../../Components/Operations/Monitor/SessionEndRequests';
import PageContainer from '../../Components/Layout/PageContainer';

class OperationMonitorPage extends Component {
    render() {
        return (
            <PageContainer>
           
                        <div className="row mt-3">
                            <OpStatistics />
                        </div>
                        <SessionEndRequests/>

                        <div className="row row-sm">
                            <OpSessions />
                            <OpClamps />
                            <LocationPerformance/>
                        </div>


            </PageContainer>
        );
    }
}

export default OperationMonitorPage;
