import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../Context/AuthContext';
import ManageUserContext from '../../Context/ManageUserContext';
import ThemeContext from '../../Context/ThemeContext';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';

const PopRoleList =()=>{

    const {roleList} = useContext(ManageUserContext);
    const [roleId, setRoleId] = useState(false)
    const [roleName, setRoleName] = useState(false)
    const [info, setInfo] = useState(false);

    const {setViewAsRole} = useContext(ThemeContext)
    const {viewAsRole, loading} = useContext(AuthContext);

  


    const roleOptions  =[];
    if(Array.isArray(roleList))
    {
        roleList.map((item, key)=>{
            roleOptions.push({label:item.role_name, value:item.role_id})
        })
    } 


    const onChangeRole=(item)=>{
        setRoleId(item.value);
        setRoleName(item.label)
    }


    const onConfirm=(e)=>{
        e.preventDefault()
        if(roleId)
        {   
            functions.closePopup("modal_view_as")
            setViewAsRole(roleName)
            setTimeout(()=>{
                viewAsRole(roleId)
            }, 1000)
          

        }else{
            setInfo(<AlertError message="Select user role and try again"/>)
            setTimeout(()=>{
                setInfo(false)
            }, 3000)
        }
    }

   

        return (
            <div className="modal" id="modal_view_as">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">View As</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                        <div className="list-group">
                            <a className="list-group-item list-group-item-action">
                                <b>How do other users view the system according to assigned permissions ?</b>
                            </a>
                             {info}                       
                           <form method="post" onSubmit={onConfirm}>

                                <TextInput
                                    label="Select Role"
                                    type="select"
                                    name="UserRole"
                                    options={roleOptions}
                                    placeholder="Select Role"
                                    onChange={onChangeRole}
                                />

                              

                            </form>

                                                    
                        </div>


                        </div>
                        <div className="modal-footer">
                            {loading && <ActivityLoader/>}
                            {!loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={onConfirm} >Load View</button>
                                <button className="btn ripple btn-light" type="button" data-dismiss="modal">Cancel</button>
                            </>}
                        </div>
                    </div>


                </div>
            </div>
        )

    }


export default PopRoleList;
