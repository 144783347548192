import React, { useContext, useEffect, useState } from 'react';
import ajaxAnalysis from '../../utils/remote/ajaxAnalysis';
import AuthContext from '../AuthContext';

const AnalyticsContext = React.createContext();

export const AnalyticsConsumer = AnalyticsContext.Consumer;

export const AnalyticsProvider = (props) => {

    const [transactionListing, setTransactionListing] = useState(false);
    const [loading, setLoading] = useState(false)
    const { secureCall } = useContext(AuthContext);



    useEffect(()=>{
        secureCall (getPerformanceTransactions, "OPERATIONS-00");
    }, [])

  

    const getPerformanceTransactions=async()=>{
        setLoading(true)
        setTransactionListing(false)
        const server_response = await ajaxAnalysis.analyseLocationPerformance();
        setLoading(false)
        if(server_response.status==="OK"){
            setTransactionListing(server_response.details.content);
        }else{
            setTransactionListing("404");
        }
    }

  
    const refreshPerformance=()=>{

        if(transactionListing){
            setTransactionListing(false);
            getPerformanceTransactions();
        }
      
    }

  
    return (
        <AnalyticsContext.Provider value={{
            transactionListing,
            loading,
            refreshPerformance
        }}
        >
            {props.children}
        </AnalyticsContext.Provider>
    );
}


export default AnalyticsContext;
