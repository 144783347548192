import React, { useEffect, useState } from "react";
import ajax from "../utils/ajax";
import dictionary from "../utils/dictionary";
import functions from "../utils/functions";
import ajaxUser from "../utils/remote/ajaxUser";
import { beautifyTable, detectTable, restoreTable } from "../utils/script/customTables";

const ManageUserContext = React.createContext();

export const ManageUserConsumer = ManageUserContext.Consumer;


export const ManageUserProvider=(props)=>{

    const [userId, setUserId] = useState(functions.sessionGuard());
    const [userCount, setUserCount] = useState("...");
    const [roleCount, setRoleCount] = useState("...");
    const [permissionCount, setPermissionCount] = useState("...");
    const [recentUsers, setRecentUsers] = useState(false);
    const [recentRoles, setRecentRoles] = useState(false);
    const [roleList, setRoleList] = useState(false);
    const [userList, setUserList] = useState(false);
    const [permissionList, setPermissionList] = useState(false)
    const [permissionTable, setPermissionTable] = useState("table")
    const [loadTable, setLoadTable] = useState(true);
    const [refreshRate, setRefreshRate] = useState(0);
    const [data, setData] = useState({"page":"1"})
    const [currentPage, setCurrentPage] = useState(1);
    const [metaData, setMetaData] = useState(false);
    const [loading, setLoading] = useState(false)



    useEffect(()=>{
        countUsers();
        countRoles();
        countPermissions();
        getRecentRoles();
        getRoles();
        getPermissions();
        getRecentUsers();
    }, [])

    useEffect(()=>{
        getAllUsers();
       }, [data])


    useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
    }, [currentPage])



    const countUsers = async () => {
        const server_response = await ajax.countUsers(userId);
        if (server_response.status === "OK") {
            setUserCount(server_response.details.content.total_c)
        }
    }

    const countRoles = async () => {
        const server_response = await ajax.countRoles(userId);
        if (server_response.status === "OK") {
            setRoleCount(server_response.details.content.total_c)
        }
    }

    const countPermissions = async () => {
        const server_response = await ajax.countPermissions(userId);
        if (server_response.status === "OK") {
           setPermissionCount(server_response.details.content.total_c);
        }
    }

    const getRecentUsers = async () => {
        const server_response = await ajax.listRecentUsers(userId);

        if (server_response.status === "OK") {
            setRecentUsers(server_response.details.content.list)
        } else {
            setRecentUsers("404")
        }
    }

    const getRecentRoles = async () => {
        const server_response = await ajax.listRecentRoles(userId);

        if (server_response.status === "OK") {
            setRecentRoles(server_response.details.content.list);
           
        } else {
           setRecentRoles("404")
        }
    }

    const getRoles = async () => {
        setRoleList(false)
        const server_response = await ajax.getRoles(userId);

        if (server_response.status === "OK") {
            setRoleList(server_response.details.content.list)
        } else {
            setRoleList("404")
        }
    }

    const getAllUsers = async () => {

        setUserList(false)
        const server_response = await ajax.listUsers(data);

        if (server_response.status === "OK") {
            setUserList(server_response.details.content.list)
            setMetaData(server_response.details.content.meta);
        } else {
            setUserList("404")
            setMetaData(false)
        }
    }


    const hotUpdateBranchName=(key, branchName)=>{
        userList[key].branch={"branch_id":"0", "branch_name":branchName}
        // setUserList(newListing)
    }


    const getPermissions = async () => {
        const server_response = await ajax.listPermissions(userId);
        if (server_response.status === "OK") {
            setPermissionList(server_response.details.content)
            setPermissionTable("ptable")
            // beautifyTable(permissionTable, loadTable, false)
            setLoadTable(false)
        } else {
            setPermissionList("404")
        }
    }




    // useEffect(()=>{
    //     setTimeout(()=>{
    //         setRefreshRate(refreshRate + 1)
    //     }, dictionary._refreshRate)

    // }, [refreshRate])


    const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());
        const server_response = await ajax.listUsers(newData);
        setLoading(false);
           
        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setUserList(userList.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }



    return (
        <ManageUserContext.Provider value={{
           permissionCount,
           roleCount,
           userCount,
           recentUsers,
           recentRoles,
           roleList,
           userList,
           permissionList,
           permissionTable,
           metaData,
           loading,
           getRecentRoles,
           getRoles,
           getAllUsers,
           getPermissions,
           getRecentUsers,
           hotUpdateBranchName,
           goToNextPage
         }}
         >
            {props.children}
        </ManageUserContext.Provider>
     );


}

export default ManageUserContext;