import React from 'react'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
class TableHeader extends React.Component {

    render() {
        return (


            <div className="table-header align-items-center">
                <div>
                    <h5 className="card-title mb-1">{this.props.title}</h5>
                    <p className="text-muted card-sub-title m-0">{this.props.subtitle}</p>
                </div>

                {this.props.href &&
                    <Link
                        to={this.props.href}
                        className="btn ripple btn-primary btn-sm see-all-btn"
                    >
                        View All
                    </Link>
                }
                {this.props.filterButton}

                {this.props.loadMore}



                {this.props.button &&
                    <button
                        className="btn ripple btn-success"
                        onClick={this.props.onClick}
                    >
                        <i className="far fa-edit mr-1"></i> {this.props.value ? this.props.value : "Add"}
                    </button>
                }

                {this.props.showTooltip && <Tippy content={!this.props.towtooltip ? <span>Click to display <br /> filter buttons</span> : <span>Click to display <br /> tow button</span>}>
                    <span className='hidden-tooltip'>
                        .
                    </span>
                </Tippy>}

                {this.props.options &&
                    <>
                        <a className="bt btn-sm ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i className="fas fa-ellipsis-v mr-1"></i>
                        </a>
                        <div className="dropdown-menu tx-13">
                            {this.props.options}
                        </div>
                    </>
                }


                {this.props.rightComponent && <div>
                    {this.props.rightComponent}
                </div>
                }


                {/* <div>
                    <h5 class="card-title mb-2">Stacked Bar Chart</h5>
                    <p class="text-muted card-sub-title card-description">Below is the basic Stacked Bar chart example.</p>
                </div> */}

            </div>

        )
    }

}

export default TableHeader
