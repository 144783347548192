import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import NewPaymentMode from '../../../Components/PaymentMode/NewPaymentMode'
import EditPaymentMode from '../../../Components/PaymentMode/EditPaymentMode'
import dictionary from '../../../utils/dictionary';
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
const eformats = dictionary._exportConfigBtnFormats;
class ConfigPaymentMode extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlSetup: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        this.setState({
            transactionId: false,
            transactionRecord: false
        })
        const server_response = await ajax.getAllPaymentModes();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content
            })
        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlSetup: false
        }, () => {
            this.setState({
                mdlSetup: <NewPaymentMode
                    onConfirm={this.onConfirmCreate}
                />
            }, () => {
                functions.openPopup("modal_set_payment_mode")
            })
        })
    }


    onConfirmCreate = (message) => {

        this.setState({
            toast: <Toast message={message} onClose={this.refreshToast} />
        }, () => {

            this.getTransactions()
            functions.closePopup("modal_set_payment_mode")
        })
    }


    onClickEdit = () => {

        if (this.state.transactionRecord) {
            this.setState({
                mdlSetup: false
            }, () => {
                this.setState({
                    mdlSetup: <EditPaymentMode
                        onConfirm={this.onConfirmCreate}
                        record={this.state.transactionRecord}
                    />
                }, () => {
                    functions.openPopup("modal_set_payment_mode")
                })
            })
        } else {
            this.setState({
                toast: <Toast message={"Select target payment mode, then try again"} type={1} onClose={this.refreshToast} />
            })
        }
    }




    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Mode
                </button>
            </>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Mode
                </a>
                <a className="dropdown-item" onClick={this.onClickEdit}>
                    <i className="fe fe-edit-2 mr-2"></i>Edit Mode
                </a>

            </>
        )
    }


    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlSetup}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Payment Modes"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center filter-example">
                                                <TableHeader
                                                    title="Payment Mode"
                                                    subtitle="Manage your payment methods. Enable/Disable payment methods"
                                                />

                                                </div>

                                                <div className="table-responsive allView-config border-top">
                                                    {(transactionList === "404") && <NoContent />}

                                                    {transactionList && Array.isArray(transactionList) &&
                                                        <table id="config-paymentModes" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Mode Name</th>
                                                                    <th>Mode Code</th>
                                                                    <th>User Triggered</th>
                                                                    <th>Enabled</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transactionList && transactionList !== "404" &&
                                                                    transactionList.map((item, key) =>
                                                                        <tr
                                                                            id={"br_" + item.id}
                                                                            key={key}
                                                                            className={`${this.state.transactionId == item.mode_id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelectTransaction(item.mode_id, item)}>
                                                                            <td>{item.mode_name}</td>
                                                                            <td><span className="btn ripple btn-light btn-sm">{item.code}</span></td>
                                                                            
                                                                            <td>{item.manual ? <span className="btn ripple btn-success btn-sm">YES</span> : <span className="btn ripple btn-danger btn-sm">NO</span>}</td>
                                                                            <td>{item.enabled ? <span className="btn ripple btn-success btn-sm">YES</span> : <span className="btn ripple btn-danger btn-sm">NO</span>}</td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!transactionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigPaymentMode
