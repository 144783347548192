import React from 'react'
import functions from '../utils/functions'
import ajax from '../utils/ajax'
import $ from 'jquery';
import dictionary from '../utils/dictionary';
import ActivityLoader from '../Components/Common/ActivityLoader'
import TableHeader from '../Components/Common/TableHeader'
import RoleRow from './Common/RoleRow'
import { ManageUserConsumer } from '../Context/ManageUserContext';

class RecentRoles extends React.Component {


    state = {
        userId: functions.sessionGuard(),
        roleList: false,
        menuOptions: '',
        roleRecord: '',
        selectedRoleId: '',
    }




    onSelectRecord = (selectedUserId, userRecord) => {
        this.setState({
            selectedUserId: selectedUserId,
            userRecord: userRecord
        }, () => {
            //change menu according to selected role
            if (this.state.userRecord.user_role.role_id === "2") {
                this.setState({
                    menuOptions: {
                        "startSession": () => this.onClickStartSession(),
                        "add": () => this.onClickAdd(),
                    }
                })

            } else {
                this.setState({
                    menuOptions: {
                        "add": () => this.onClickAdd(),
                    }
                })

            }

        })
    }

    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/users/new'
        })
    }

    onClickStartSession = () => {
        $("#modal_vehicle_input").modal('show');
    }

    onConfirmVehicle = (plateNumber) => {
        $("#modal_vehicle_input").modal('hide');

        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: this.state.userRecord, vehiclePlate: plateNumber }
        })
    }


    renderRoleList = () => {
        return (
            <ManageUserConsumer>
                {prop => {
                    const roleList = prop.recentRoles;
                    return (
                        <div className="role-body">
                            <div className="activity-block">
                                <ul className="task-list">
                                    {roleList && roleList !== "404" &&
                                        roleList.map((item, key) =>
                                            <RoleRow
                                                key={key}
                                                title={item.role_name}
                                                date={item.created_at.date}
                                                perms="#8976"
                                                color="task-icon bg-secondary"
                                            />
                                        )}

                                </ul>
                                {!roleList && <ActivityLoader />}
                            </div>
                        </div>
                    )
                }}
            </ManageUserConsumer>
        )
    }


    render() {
        const roleList = this.state.roleList

        return (

            
                <div className="card custom-card">
                    <div className="card-body map-card">
                        <TableHeader
                            title="Recent Roles"
                            subtitle="System Roles at multiplex."
                            view="View All"
                            href={this.props.href}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                            <this.renderRoleList />
                        </div>
                    </div>
                </div>
                

        );

    }
}

export default RecentRoles
