import React from 'react';


class FltAllNotifications extends React.Component {
  

  render(){
      return (
        <span 
        type="button" 
        className=" badge badge-pill badge-secondary ml-3"
        onClick={this.props.onClick}>
        Veiw All
        </span>
      );
  }
}


export default FltAllNotifications;