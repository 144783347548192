import React from 'react';
import ajax from '../../utils/ajax';
import SelectSearch from './SelectSearch';


class CustomizedFilterBar extends React.Component {

	state = {
		dateFrom: '',
		dateTo: '',
		selectedValue: "",
		selectedValue1: "",
		selectedValue2: "",
		selectedValue3: "",
		selectedValue4: "",
		selectedValue5: "",
		selectedValue6: "",
		selectSearchID: "",
		keyword: '',
		refresh: false,
		searchList: "",
		keyword1: "",
		keyword2: ""

	}



	onChangeDateFrom = (event) => {
		this.setState({ dateFrom: event.target.value });
	}

	onChangeDateTo = (event) => {
		this.setState({ dateTo: event.target.value });
	}

	onChangeSelection = (event) => {
		this.setState({ selectedValue: event.target.value });
	}

	onChangeSelection1 = (event) => {
		this.setState({ selectedValue1: event.target.value });
	}

	onChangeSelection2 = (event) => {
		this.setState({ selectedValue2: event.target.value });
	}

	onChangeSelection3 = (event) => {
		this.setState({ selectedValue3: event.target.value });
	}

	onChangeSelection4 = (event) => {
		this.setState({ selectedValue4: event.target.value });
	}

	onChangeSelection5 = (event) => {
		this.setState({ selectedValue5: event.target.value });
	}
	onChangeSelection6 = (event) => {
		this.setState({ selectedValue6: event.target.value });
	}
	onChangeKeyword = (event) => {

		this.setState({ keyword: event.target.value }, () => this.searchVehicle());
	}


	onChangeKeyword1 = (event) => {

		this.setState({ keyword1: event.target.value });
	}

	onChangeSelectSearch = (selectedOption) => {

		this.setState({ selectSearchID: selectedOption.value });
	}


	onChangeKeyword2 = (event) => {

		this.setState({ keyword2: event.target.value });
	}


	onApply = (func) => {
		this.props.onConfirm(this.state, func)
	}


	onClickPlateNumber = (keyword) => {
		this.setState({ keyword }, () => this.setState({ searchList: false }))
	}


	onChangePlateNumber = (event) => {
		this.setState({ keyword: event.target.value }, () => this.searchVehicle())
	}


	searchVehicle = async () => {
		const server_response = await ajax.searchVehicle(this.state.keyword);
		if (server_response.status === "OK") {
			this.setState({
				searchList: server_response.details.content
			})
		} else {
			this.setState({
				searchList: "404"
			})
		}
	}

	onClickReset = () => {
		this.setState({
			dateFrom: "",
			dateTo: "",
			selectedValue: false,
			selectedValue1: false,
			keyword: "",
			refresh: true

		}, () => {
			this.setState({
				refresh: false
			})
			this.props.onReset()

		});


	}



	RenderFilter = () => {

		const { searchList } = this.state;
		if (this.state.refresh) {
			return <></>
		} else {
			return (
				<div className="responsive-background">
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<div className="advanced-search">
							<div className="row align-items-center mt-3">

								<div className="col-md-6">
									<div className="row">
										<div className="col-md-6">
											<div className="form-group mb-lg-0">
												<label className="">From :</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<div className="input-group-text">
															<i className="fe fe-calendar lh--9 op-6"></i>
														</div>
													</div>
													<input
														className="form-control fc-datepicker"
														placeholder="11/01/2019"
														type="date"
														value={this.state.dateFrom}
														onChange={this.onChangeDateFrom} />
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group mb-lg-0">
												<label className="">To :</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<div className="input-group-text">
															<i className="fe fe-calendar lh--9 op-6"></i>
														</div>
													</div>
													<input
														className="form-control fc-datepicker"
														placeholder="11/08/2019" type="date"
														value={this.state.dateTo}
														onChange={this.onChangeDateTo} />
												</div>
											</div>
										</div>
									</div>
								</div>

								{this.props.keyword && <div className={"col-md-3"}>
									<div className="form-group mb-lg-0">
										<label className="">{this.props.keyword}</label>
										<input
											className="form-control"
											placeholder={"Search " + this.props.keyword}
											type="text"
											value={this.state.keyword}
											onChange={this.onChangeKeyword} />
									</div>


									{this.props.searchPlate && searchList && searchList !== "404" &&
										<div className=" btn-list mt-4">
											{searchList.map((item, key) =>
												<button key={key}
													onClick={() => this.onClickPlateNumber(item.plate_number)}
													type="button"
													className="btn ripple btn-light btn-sm">
													{item.plate_number}
												</button>)}
										</div>}
								</div>}

								{this.props.keyword2 && <div className="col-md-3">

									<div className="form-group mb-lg-0">
										<label className="">{this.props.keyword2}</label>
										<input
											className="form-control"
											placeholder={this.props.keyword2}
											type="text"
											readOnly={this.props.readOnly}
											value={this.state.keyword2}
											onChange={this.onChangeKeyword1} />
									</div>


								</div>}

								{this.props.selectsearch &&

									<div className="col-md-3">

										<div className="form-group mb-lg-0">
											{/* <label className="mb-0">{this.props.selectsearch.label}</label> */}

											<SelectSearch
												label={this.props.selectsearch.label}
												onChange={() => this.onChangeSelectSearch}
												options={this.props.selectsearch.options}
												placeholder={this.props.selectsearch.placeholder}
												// customStyles={"form-control"}
												// className="form-control"
												divClass="col-md-12"
												labelClass="col-md-12 text-left mb-2 mt-3"
											/>
										</div>


									</div>
								}



								{this.props.keyword1 && <div className={this.props.keyword1ClassName ? "col-md-3 mt-3" : "col-md-3"}> <div className="form-group mb-lg-0">
									<label className="">{this.props.keyword1}</label>
									<input
										className="form-control"
										placeholder={this.props.keyword1}
										type="text"
										value={this.state.keyword1}
										onChange={this.onChangeKeyword1} />
								</div>
								</div>
								}



								<div className={this.props.className ? this.props.className : "col-md-3"}>
									{this.props.selection && <div className="form-group mb-lg-0">
										<label className="">{this.props.selection.title}</label>
										<select
											className="form-control select2-flag-search"
											data-placeholder={this.props.selection.title}
											onChange={this.onChangeSelection}
										>
											<option value="">-Select-</option>
											{this.props.selection.options.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>}
								</div>

								{this.props.selection1 && <div className="col-md-3 mt-3">
									<div className="form-group mb-lg-0">
										<label className="">{this.props.selection1.title1}</label>
										<select
											className="form-control select2-flag-search "
											data-placeholder={this.props.selection1.title1}
											onChange={this.onChangeSelection1}
										>
											<option value=""> -Select- </option>
											{this.props.selection1.options1.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>
								</div>}

								{this.props.selection2 && <div className="col-md-3 mt-3">
									<div className="form-group mb-lg-0">
										<label className="">{this.props.selection2.title2}</label>
										<select
											className="form-control select2-flag-search "
											data-placeholder={this.props.selection2.title2}
											onChange={this.onChangeSelection2}
										>
											<option value=""> -Select- </option>
											{this.props.selection2.options2.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>
								</div>}

								{this.props.selection3 && <div className="col-md-3 mt-3">
									<div className="form-group mb-lg-0">
										<label className="">{this.props.selection3.title3}</label>
										<select
											className="form-control select2-flag-search "
											data-placeholder={this.props.selection3.title3}
											onChange={this.onChangeSelection3}
										>
											<option value=""> -Select- </option>
											{this.props.selection3.options3.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>
								</div>}

								{this.props.selection4 && <div className="col-md-3 mt-3">
									<div className="form-group mb-lg-0">
										<label className="">{this.props.selection4.title4}</label>
										<select
											className="form-control select2-flag-search "
											data-placeholder={this.props.selection4.title4}
											onChange={this.onChangeSelection4}
										>
											<option value=""> -Select- </option>
											{this.props.selection4.options4.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>
								</div>}

								{this.props.selection5 && <div className="col-md-3 mt-3">
									<div className="form-group mb-lg-0">
										<label className="">{this.props.selection5.title5}</label>
										<select
											className="form-control select2-flag-search "
											data-placeholder={this.props.selection5.title5}
											onChange={this.onChangeSelection5}
										>
											<option value=""> -Select- </option>
											{this.props.selection5.options5.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>
								</div>}

								{this.props.selection6 && <div className="col-md-3 ">
									<div className="form-group mb-lg-0">
										<label className="">{this.props.selection6.title6}</label>
										<select
											className="form-control select2-flag-search "
											data-placeholder={this.props.selection6.title6}
											onChange={this.onChangeSelection6}
										>
											<option value=""> -Select- </option>
											{this.props.selection6.options6.map((item, key) =>
												<option key={key} value={item.value}>{item.title}</option>
											)}
										</select>
									</div>
								</div>}


							</div>
							<hr />
							<div className="text-right">
								{!this.props.clampfilter && <button onClick={this.onApply} className="btn btn-primary" data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">Apply</button>}
								{/* <a href="#" className="btn btn-primary" data-toggle="collapse" data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Close</a> */}
								{this.props.clampfilter && <a href="#" onClick={this.onApply} className="btn btn-primary" data-toggle="collapse" data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Apply</a>}
								<button onClick={this.onClickReset} className="btn btn-light" data-toggle="collapse" data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Reset</button>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}


	render() {

		return (

			<this.RenderFilter />

		);


	}
}

export default CustomizedFilterBar;
