import React, { useContext, useState } from 'react';
import AlertError from '../../../Common/AlertError';
import TextInput from '../../../Common/TextInput';
import AlertSuccess from '../../../Common/AlertSuccess';
import functions from '../../../../utils/functions';
import ThemeContext from '../../../../Context/ThemeContext';
import ActivityLoader from '../../../Common/ActivityLoader';
import ajaxEfrisHistory from '../../../../utils/remote/ajaxEfrisHistory';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';


const PopNewEfrisWhitelist=(props)=> {

    
        const [info, setInfo] = useState(false);
        const [plateNumber, setPlateNumber] = useState('');

        const {setDisplayMessage} = useContext(ThemeContext);

        const { refreshTax } = useContext(EfrisHistoryContext);
       
        const [loading, setLoading] = useState(false);


        const onConfirm = async (event) => {
            event.preventDefault();
            if (plateNumber.length > 0) {
                    setLoading(true)
                    const server_response = await ajaxEfrisHistory.postEfrisWhiteVehicle(plateNumber);
                    setLoading(false)
                    if(server_response.status === "OK"){
                        setDisplayMessage(functions.templateMessage("EFRIS Config: ", server_response.message, "info"))
                        setInfo(<AlertSuccess message={server_response.message} />);
                        // functions.closePopup("modal-efris-whitelist");
                        // refreshTax();
                    }else{
                        setDisplayMessage(functions.templateMessage("EFRIS Config: ", server_response.message, "danger"))
                        setInfo(<AlertError message={server_response.message} />)
                    }

             

            } else {
                setInfo(<AlertError message={"Enter plateNumber, then try again."} />)
            }
        }



    
        return (
            <div className="modal" id="modal-efris-whitelist">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Efris Whitelist</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      
                                <TextInput
                                    type="text"
                                    label="Plate Number"
                                    onChange={(e) => setPlateNumber(e.target.value)}
                                    placeholder={"Enter plate number"}
                                    name="plate_number"
                                    value={plateNumber}
                                    required
                                />
                                

                            </form>

                        
                            <p className='small'><b>NOTE:</b> Whitelisted vehicles will always be submitted even when tax saving is turned on.</p>

                        </div>
                        <div className="modal-footer">
                            {!loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={onConfirm}>Add Vehicle</button>
                                <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopNewEfrisWhitelist;
