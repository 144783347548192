import React from 'react'
import { Link } from 'react-router-dom'

class Footer extends React.Component{

    render(){

        const multiplex = () => {
            window.open("https://multiplexstreetparking.com/");
        }

        return(
            <div className="main-footer text-center">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
                            <span>@Copyright{" "}
                            <Link to="#" href="#" onClick={multiplex}>Multiplex Uganda</Link>.
                            </span>
						</div>
					</div>
				</div>
			</div>
        )
    }

}

export default Footer
