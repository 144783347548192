import React from 'react';
import ActivityLoader from './Common/ActivityLoader';


class WarnAction extends React.Component {
    state = {
        buttonLabel: this.props.buttonLabel || "Confirm Delete",
        loading: false
    }

    onConfirm = () => {
        this.setState({
            loading: true
        }, () => this.props.onConfirm())
    }

    render() {

        return (
            <div className="modal" id={this.props.id || "modal_action"}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content tx-size-sm">
                        <div className="modal-body tx-center pd-y-20 pd-x-20">
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                                <span aria-hidden="true">&times;</span></button>
                            <i className={`icon icon ${this.props.icon || 'ion-ios-close-circle-outline'} tx-100 tx-danger lh-1 mg-t-20 d-inline-block`}></i>
                            <h4 className="tx-danger mg-b-20">{this.props.title}</h4>
                            <p className="mg-b-20 mg-x-20">{this.props.description}</p>

                            {!this.state.loading && <button
                                className={`btn ripple btn-${this.props.buttonTheme || 'danger'} pd-x-25`}
                                type="button"
                                onClick={this.onConfirm}>{this.state.buttonLabel}</button>
                            }

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>
        )


    }
}

export default WarnAction;
