import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import PopSessionInfo from '../../Components/PopSessionInfo'
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import PopNewSession from '../../Components/Session/PopNewSession'
import TableHeader from '../../Components/Common/TableHeader'
import FilterBar from '../../Components/Common/FilterBar'
import PINLocation from '../../Components/Common/PINLocation'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import Export from '../../Components/Common/Export'
import Badge from '../../Components/Common/Badge'
import PageNav from '../../Components/Common/PageNav'
import PopWhiteListRecord from '../../Components/WhiteListing/PopWhiteListRecord'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import WhiteListContext, { WhiteListConsumer } from '../../Context/WhiteList/WhiteListContext'
import NoContent from '../../Components/Common/NoContent'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import dictionary from '../../utils/dictionary'

class WhiteListing extends React.Component {

    static contextType = WhiteListContext

    state={
        recordView:false
    }
  

    viewRequest = (record) => {
        this.setState({
            recordView: false
        }, () => {
            this.setState({
                recordView: <PopWhiteListRecord
                    record={record}
                    refresh={this.context.getWhiteListTransactions} />
            }, () => {
                functions.openPopup("modal_white_list")
            })

        })

    }


    paginator=()=>{

        const {transactionListing, goToNextPage, loading, metaData} = this.context
        
        if(Array.isArray(transactionListing)){
           
           return <LoadMoreButton
                action={goToNextPage}
                loading={loading}
                title={metaData.current_page + " of " + metaData.total_pages}
            />
        }else{
            return <></>
        }
    
    }



    buttons = () => {
        return (
            <>
                <button className='btn btn-light' onClick={this.context.refreshList}><i className='fa fa-redo mr-2' /> Refresh</button>
      
                {this.context.transactionListing  && 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                }
      
            </>
        )
      }



    RenderFilter = () => {
          
        const listOptions = [{title:"All Requests", value:""}, {title:"Pending Requests", value:"0"}, {title:"Approved Requests", value:"1"}];
    
        return (
            <CustomizedFilterBar
                selection={{ title: 'Request Status', options: listOptions }}
                keyword="Plate Number"
                onReset={this.context.resetData}
                onConfirm={this.context.filterData}
            />
        )
    
        
    }
    
      



    render() {

        const { transactionListing} = this.context;
       
        return (<div className="page">

                            {this.state.recordView}


                            <LeftSideBar />
                            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                                <TopBar />
                                <div className="container-fluid">
                                    <PageHeader
                                        title="White Listing"
                                        previousPageTitle="Dashboard"
                                        // options={this.state.menuOptions}
                                        back="/operations"
                                        buttons={<this.buttons />}
                                    />

                                    

                                  <this.RenderFilter/>

                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12 col-lg-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center filter-example">
                                                    <TableHeader
                                                        title="White List Records"
                                                        subtitle="List of all white list records from the most recent."
                                                    />


                                                    <this.paginator />



                                            

                                                    </div>


                                                    <div className="table-responsive">

                                                    {(transactionListing === "404") && <NoContent />}

                                                    {Array.isArray(transactionListing) &&
                                                        <table className="table text-nowrap mb-0 table-hover table-ellipsis datatable" id="table-white-listing" export-format={dictionary._exportBtnFormats}>
                                                        <thead>
                                                                <tr>
                                                                    <th>Request Date</th>
                                                                    <th>Balance Date</th>
                                                                    <th>Vehicle</th>
                                                                    <th>Client Contact</th>
                                                                    <th>Commitment(UGX)</th>
                                                                    <th>Status</th>
                                                                    <th>Comment</th>
                                                                    <th>Requested By</th>
                                                                    <td></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {transactionListing.map((item, key) =>

                                                                    <tr key={key}>
                                                                        <td>{item.created_at.when}</td>
                                                                        <td>{item.balance_date.short_date}</td>
                                                                        <td className="tx-bold">
                                                                            <Link to={
                                                                                {
                                                                                    pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                                    state: { plateNumber: item.vehicle.plate_number }
                                                                                }}>{item.vehicle.plate_number}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{item.vehicle.client.phone_number}</td>
                                                                        <td>{item.due_amount.amount_c}</td>
                                                                        <td>
                                                                            {item.status.status_id * 1 == 0 && <Badge value={item.status.status} type={1} />}
                                                                            {item.status.status_id * 1 == 1 && <Badge value={item.status.status} type={3} />}
                                                                            {item.status.status_id * 1 == 2 && <Badge value={item.status.status} type={2} />}
                                                                            {item.status.status_id * 1 == 3 && <Badge value={item.status.status} type={0} />}
                                                                            {item.status.status_id * 1 == 4 && <Badge value={item.status.status} type={4} />}


                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {item.comment}
                                                                            </span>
                                                                        </td>
                                                                        <td>{item.created_by.username}</td>
                                                                        <td>
                                                                            <Link to="#" className="tx-bold" onClick={() => this.viewRequest(item)}>
                                                                                <i className="fas fa-eye" />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                            </tbody>
                                                        </table>
                }

                                                       
                                                        {!transactionListing && <ActivityLoader />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <RightSideBar />
                             
                        </div>)
                }}
           


export default WhiteListing
