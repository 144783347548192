import React, { useContext, useEffect, useState } from "react"
import NoContent from "../../../Components/Common/NoContent"
import ActivityLoader from "../../../Components/Common/ActivityLoader"
import PageContainer from "../../../Components/Layout/PageContainer"
import PageHeader from "../../../Components/Common/PageHeader"
import dictionary from "../../../utils/dictionary"
import TableHeader from "../../../Components/Common/TableHeader"
import LoadMoreButton from "../../../Components/Other/LoadMoreButton"
import { Link } from "react-router-dom"
import functions from "../../../utils/functions"
import TowingContext from "../../../Context/Violation/TowingContext"
import OptionButton from "../../../Components/Controls/OptionButton"
import useStateCallback from "../../../utils/customHooks/useStateCallback"
import PopReturnVehicle from "../../../Components/Operations/Violation/PopReturnVehicle"
import TowFilter from "../../../Components/Operations/Violation/TowFilter"

const TowingPage =(props)=>{

    const { 
            allTransactions, 
            goToNextPage,
            getAllTransactions,
            metaData,
            loading 
        } = useContext(TowingContext)

    const [selection, setSelection] = useState(false);
    const [modal, setModal] = useStateCallback(false);


    const handlePickup=()=>{
        setModal(false, ()=>setModal(<PopReturnVehicle record={selection}/>, ()=>functions.openPopup("modal-collect-vehicle")))
    }

    

    
  const RenderFilter = () => {
          
    return <TowFilter />

  }



    const buttons = () => {
        return (
            <>

              {selection && <OptionButton title="Action" theme="danger">
                <Link to="#" className="dropdown-item" onClick={handlePickup}>Return Vehicle</Link>
              </OptionButton>}

                {allTransactions  && 
                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
                }

             <button 
                className="btn ripple btn-light" 
                onClick={getAllTransactions}
                >
                <i className="fa fa-redo mr-1"></i> Refresh
              </button>
             


      
            </>
        )
      }

    return  <PageContainer>

                <PageHeader
                    title="Towing"
                    previousPageTitle="Dashboard"
                    back="/operations"
                    buttons={buttons()}
                    />
                 {modal}
                 {RenderFilter()}

                 <div className="row">
                  <div className="col-sm-12 col-xl-12 col-lg-12">

                    <div className="card custom-card">
                        <div className="card-body map-card">

                          <TableHeader
                                    title={"Towing Transactions"}
                                    subtitle="List of all tow transactions"
                                />

                            
                             {Array.isArray(allTransactions) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }
                        
                            <div className="table-responsive allView-table border-top">


                                    {Array.isArray(allTransactions) &&
                                        <table id="table-parking-enforcement" export-format={dictionary._exportBtnFormats} className="table text-nowrap table-hover mg-b-0 datatable">
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Clamp ref</th>
                                                    <th>Vehicle</th>
                                                    <th>Tow Date</th>
                                                    <th>Status</th>
                                                    <th>Towed By</th>
                                                    <th>Violation</th>
                                                    <th>Clamping Location</th>
                                                    <th>Resolved By</th>
                                                    <th>Pick-Up Name</th>
                                                    <th>Pick-Up Contact</th>
                                                    <th>Last Updated</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                {allTransactions.map((item, key)=>
                                                    <tr key={key} onClick={()=>setSelection(item)} className={`${item.id*1==selection.id && 'flag-row'}`}>
                                                        <td>{key + 1}</td>
                                                        <td>{item.enforcement.ref}</td>
                                                        <td>{item.plate_number}</td>
                                                        <td>{item.towed_at.short_date + " " + item.towed_at.time}</td>
                                                        <td>{item.status.status}</td>
                                                        <td>{item.towed_by.full_name}</td>
                                                        <td>{item.enforcement.clamped_for.violation_name}</td>
                                                        <td>{item.enforcement.clamping_location?item.enforcement.clamping_location.location_name : ''} {item.enforcement.clamping_location?item.enforcement.clamping_location.street.street_name : ''}</td>
                                                        <td>{item.resolved_by?item.resolved_by.full_name : "N/A"}</td>
                                                        <td>{item.pickup_name}</td>
                                                        <td>{item.pickup_contact}</td>
                                                        <td>{item.updated_at.short_date + " " + item.updated_at.time}</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>}


                                        {(allTransactions === "404") && <NoContent />}
                                        {!allTransactions && <ActivityLoader />}
                                </div>

                            </div>
                        </div>
                    </div>
        </div>
</PageContainer>}


export default TowingPage