import React, { useContext } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import PopReportWrongParking from '../../Components/Contraventions/WrongParking/PopReportWrongParking';
import $ from 'jquery';
import Toast from '../../Components/Common/Toast'
import dictionary from '../../utils/dictionary'
import PopViewImage from '../../Components/PopViewImage'
import { Link } from 'react-router-dom'
import Export from '../../Components/Common/Export'
import WrongParkingContext from '../../Context/ContraventionContext/WrongParkingContext'
import TableHeader from '../../Components/Common/TableHeader'
import NoContent from '../../Components/Common/NoContent'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import FilteredWrongParkingRecords from './Filters/FilteredWrongParking'
import { ConfigurationConsumer } from '../../Context/ConfigurationContext';


const eformat = dictionary._exportBtnFormats;


class ListWrongParkingTransactions extends React.Component {
    static contextType = WrongParkingContext;

    state = {
        userId: functions.sessionGuard(),
        countActiveSessions: '...',
        WrongParking: '',
        currentPage: 1,
        List: false,
        modal: false,
        metaData: false,
        toast: false,
        hasError: false,
        info: false,
        imageView: false,
        loadTable: true,
        enableUpdate: false,
        List: "",
        locationPIN: false,
        closeFilter: false

    }

    componentDidMount() {
        const {
            getWrongParking
        } = this.context;

        getWrongParking();

        this.setState({
            load: true,
            WrongParking: {
                "report_wrong_parking": () => this.onClickReportVehicle()
            },
            modal: <PopReportWrongParking
                title="Report Wrong Parking"
                onConfirm={this.onConfirmReport} />
        })

    }


    // ConfirmReport = (message) => {
    //     this.setState({
    //         toast: <Toast message={message} />
    //     })
    // }

    onConfirmReport = async (plateNumber, reportedById, locationId, descriptionId, vehicle_photo) => {
        const { getWrongParking } = this.context;
        if (plateNumber && reportedById && locationId && descriptionId) {
            const server_response = await ajax.reportWrongParking(plateNumber, reportedById, locationId, descriptionId, vehicle_photo);
            if (server_response.status === 'OK') {

                $("#modal_report_wrong_parking").modal('hide');
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                },
                    () => {
                        this.setState({
                            modal: <PopReportWrongParking
                                title="Report Wrong Parking"
                                onReload={this.listWrongParkingTransactions}
                                onConfirm={this.onConfirmReport} />

                        },
                            () => getWrongParking())
                    })
            } else {
                $("#modal_report_wrong_parking").modal('hide');
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} />
                },
                    () => {
                    }
                )
            }
        }

    }



    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }
    onConfirmVehicle = () => {
        //hide the modal

        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopReportWrongParking
                    title="Report Wrong Parking"
                    onReload={this.listWrongParkingTransactions}
                    onConfirm={this.ConfirmReport} />

            })
        })
    }





    resetToast = () => {
        this.setState({
            toast: false
        })
    }




    onClickReportVehicle = () => {
        this.resetToast();
        $("#modal_report_wrong_parking").modal('show');
    }

    onClickViewVehiclePhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewImage image={record} />
            }, () => {
                $("#modal_view_image").modal('show');
            })
        })

    }

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            List: false
        }, () => this.listWrongParkingTransactions())
    }

    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                List: false

            }, () => {
                this.listWrongParkingTransactions();
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                List: false
            }, () => {
                this.listWrongParkingTransactions();
            })
        }

    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    buttons = () => {
        return (
            <>
                <Link
                    to="#"
                    className="btn ripple btn-secondary navresponsive-toggler mb-3" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>

            </>
        )
    }

    onFilter = (filter) => {
        this.setState({
            filter: "",
            metaData: false

        }, () =>
            this.setState({
                filter: filter,
                metaData: false
            }))
    }

    resetFilter = () => {
        this.setState({
            filter: false
        },

        )
    }




    render() {
        const { List, goToNextPage, metaData, loading, reportedByList } = this.context
        const { filter } = this.state


        const Itemsperpage = [15, 20, 25, 30, 35, 40, 45, 50]

        const itemsPerPageOptions = [];


        return (

            <ConfigurationConsumer>

                {props => {
                    const { clampedForList, locationList, wrongParkingDescriptionList } = props;



                    const contraventionOptions = [];
                    const locationOptions = [];

                    const WPdescriptionOptions = [];

                    const reportedByOptions = [];



                    if (wrongParkingDescriptionList && wrongParkingDescriptionList !== "404") {
                        wrongParkingDescriptionList.map((item, key) =>
                            WPdescriptionOptions.push({
                                title: item.description, value: item.description_id
                            })

                        )

                    }


                    if (locationList && locationList !== "404") {

                        locationList && locationList.map((item, key) =>
                            locationOptions.push({
                                title: item.location_name,
                                value: item.location_id
                            })
                        );
                    }


                    if (clampedForList && clampedForList !== "404") {
                        clampedForList.map((item, key) =>
                            contraventionOptions.push({
                                title: item.item_type_name,
                                value: item.item_type_id
                            })
                        )

                    }

                    if (reportedByList && reportedByList !== "404") {
                        reportedByList.map((item, key) =>
                            reportedByOptions.push({
                                label: item.user_role.role_name + " - " + item.first_name + " " + item.last_name,
                                value: item.user_id
                            })
                        )

                    }



                    // if (clampingStatusList && clampingStatusList !== "404") {
                    //     clampingStatusList.map((item, key) =>
                    //         clampStatusOptions.push({
                    //             title: item.clamping_status,
                    //             value: item.clamping_status_id
                    //         })
                    //     )

                    // }

                    return (
                        <div className="page">
                            {this.state.modal}
                            <LeftSideBar />
                            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                                <TopBar />
                                <div className="container-fluid">
                                    <PageHeader
                                        back="/contraventions"

                                        title="Wrong parking reports"
                                        previousPageTitle="Contraventions"
                                        {...this.state.WrongParking}
                                        buttons={<this.buttons />}


                                    />

                                    {!this.state.closeFilter &&
                                        <CustomizedFilterBar
                                            keyword="Plate Number"
                                            searchPlate="searchPlate"
                                            // keyword1="Enter correct Username "
                                            selectsearch={{ label: "Reported By", options: reportedByOptions, placeholder: "Enter correct username" }}
                                            // keyword2="Enter items per page (max 50 items)"
                                            // keyword1ClassName="keyword1ClassName"
                                            // selection5={{ title5: 'No. of Records per page', options5: itemsPerPageOptions }}

                                            selection={{ title: 'Description', options: WPdescriptionOptions }}
                                            // className="col-md-3 mt-3"
                                            // selection1={{ title1: 'Reported By', options1: reportedByOptions }}
                                            // selection4={{ title4: 'Un Clamped By', options4: unClampedByOptions }}
                                            onReset={this.resetFilter}
                                            onConfirm={this.onFilter}
                                        // clampfilter="clampfilter"

                                        />
                                    }
                                    {this.state.toast}

                                    {/* <PopViewImage/> */}
                                    {this.state.imageView}
                                    {this.state.locationPIN}

                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12 col-lg-12">
                                            <div className="card custom-card">
                                                <div className="card-body">

                                                    <div className="d-flex justify-content-between align-items-center filter-example">
                                                        <TableHeader
                                                            title="All wrong parking reports"
                                                            subtitle="List of the wrong parking reports in the system"
                                                        />

                                                        {Array.isArray(List) && !this.state.filter &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages + " pages"}
                                                            // title={metaData.current_page + "" + metaData.current_page == 1 ? + " Page" : + " Pages"}
                                                            />
                                                        }

                                                    </div>

                                                    {this.state.filter &&
                                                        <FilteredWrongParkingRecords
                                                            vehicleID=""
                                                            plateNumber={filter.keyword}
                                                            description={filter.selectedValue}
                                                            reportedByID={filter.selectSearchID}
                                                            amountDue={filter.keyword1}
                                                            itemsPerPage={filter.selectedValue5}
                                                            createdFrom={filter.dateFrom}
                                                            createdTo={filter.dateTo}
                                                            filter={filter}
                                                            filterclampsTableID={"listFiteredPaymentDueID"}

                                                        />
                                                    }

                                                    {!this.state.filter && <div className="table-responsive allView-table border-top">

                                                        {(List === "404") && <NoContent />}

                                                        {List && Array.isArray(List) &&
                                                            <table id={"wrong-parking"} className="table text-nowrap mg-b-0 table-hover key-buttons datatable" export-format={eformat}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>WRONG PARKING REF.</th>

                                                                        <th>Reported On</th>
                                                                        <th>Vehicle</th>
                                                                        <th>PROOF</th>
                                                                        <th>Reported By</th>
                                                                        <th className="text-center">Clamp Status</th>
                                                                        <th>Location</th>
                                                                        <th>Description</th>



                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {List && List !== "404" &&
                                                                        List.map((item, key) =>
                                                                            <tr key={key}>
                                                                                <td>{item.ref}</td>
                                                                                {/* <td>{item.created_at.long_date}</td> */}
                                                                                <td>
                                                                                    {item.created_at.date}<br />
                                                                                    <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                                </td>

                                                                                <td className="tx-bold">
                                                                                    <Link to={
                                                                                        {
                                                                                            pathname: `/vehicles/profile/${item.vehicle.vehicle_number}`,
                                                                                            state: { plateNumber: item.vehicle.vehicle_number }
                                                                                        }}>{item.vehicle.vehicle_number}
                                                                                    </Link>
                                                                                </td>

                                                                                <td className="pointer">
                                                                                    <div className="main-img-user">
                                                                                        <img alt="avatar" onClick={() => this.onClickViewVehiclePhoto(item)} src={item?.vehicle_photo?.file_path} />
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <h6 className="mg-b-1">{item.reported_by.username}</h6>
                                                                                    <small className="tx-12 tx-gray-500">{item.reported_by.user_role.role_name}</small>
                                                                                </td>
                                                                                <td className='text-center' >
                                                                                    {item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "1" &&
                                                                                        <span className="badge badge-info badge-pill">PENDING  CLAMP</span>}


                                                                                    {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.is_parked === "0" && item.clamping_info?.clamp_info.system_abort === "0" &&
                                                                                        <span className="badge badge-danger   badge-pill">ABORTED</span>}

                                                                                    {item.clamping_info != false && item.clamping_info?.clamp_info.status.status === 'PENDING' && item.clamping_info?.clamp_info.system_abort === "1" &&
                                                                                        <span className="badge badge-danger   badge-pill">LEFT PARKING</span>}

                                                                                    {item.clamping_info?.clamp_info.status.status === 'RESPONDED' &&
                                                                                        <span className="badge badge-secondary badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}

                                                                                    {item.clamping_info?.clamp_info.status.status === 'CLAMPED' &&
                                                                                        <span className="badge badge-dark badge-pill">{item.clamping_info?.clamp_info.status.status}</span>}
                                                                                    {item.clamping_info?.clamp_info.status.status === 'PENDING_UNCLAMP' &&
                                                                                        <span className="badge badge-primary badge-pill">PENDING UNCLAMP </span>}
                                                                                    {item.clamping_info?.clamp_info.status.status === 'UNCLAMP_RESPONDED' &&
                                                                                        <span className="badge badge-light badge-pill">UNCLAMP RESPONDED </span>}

                                                                                    {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                                        === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_partialy === "0" && <span className="badge badge-success badge-pill ">UNCLAMPED</span>}

                                                                                    {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                                        === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "0" && item.clamping_info.clamp_info.paid_partialy === "1" && <span className="badge badge-success badge-pill partial-pay-color">PAID PARTIALY BEFORE CLAMP</span>}

                                                                                    {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                                        === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "0" && item.clamping_info.clamp_info.towed === "1" && <span className="badge badge-success badge-pill unclamp-tow-color">UNCLAMPED & TOWED</span>}


                                                                                    {item.clamping_info != false && item.clamping_info.clamp_info.status.status
                                                                                        === 'UNCLAMPED' && item.clamping_info.clamp_info.paid_before_clamp === "1" && <span className="badge badge-success badge-pill clamp-color ">CLEARED BEFORE CLAMP</span>}
                                                                                </td>


                                                                                {/* <td>{item.location.location_name}</td> */}

                                                                                <td>
                                                                                    <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                                                        <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                                                        {item.location.location_name}<br />
                                                                                        <small className="tx-12 tx-gray-500">{item.location.street.street_name}</small>
                                                                                    </span>
                                                                                </td>

                                                                                <td>{item.description.description}</td>


                                                                            </tr>
                                                                        )}

                                                                </tbody>

                                                            </table>
                                                        }

                                                        {!List && <ActivityLoader />}







                                                    </div>}


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <RightSideBar />

                        </div>

                    )
                }
                }
            </ConfigurationConsumer>
        );

    }
}

export default ListWrongParkingTransactions
