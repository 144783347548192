import React from 'react';
import Loader from 'react-loader-spinner';

class ActivityLoader extends React.Component {
  

  render(){
     
    const types=['Circles', 'Audio', 'Ball-Triangle', 'Bars', 'Grid', 'Hearts', 'Oval', 'Puff', 'Rings', 'TailSpin', 'ThreeDots']
    const type=this.props.type || 0
    const height=this.props.height || 50
    const width=this.props.width || 80
    const color=this.props.color || "#FF4800"
    const customClass=this.props.customClass || " col-12 text-center "
      return (
        <span className={customClass}>
             <Loader
                type={types[type]}
                color={color}
                height={height}
                width={width}
            />
        </span>

      );
    
 
  }
}

export default ActivityLoader