import React, { useContext } from "react"
import ScrollFilterBar from "../../Common/Filter/ScrollFilterBar";
import FilterTextInput from "../../Common/Filter/FilterTextInput";
import FilterSelectInput from "../../Common/Filter/FilterSelectInput";
import ParkingViolationContext from "../../../Context/Violation/ParkingViolationContext";
import ConfigurationContext from "../../../Context/ConfigurationContext";

const ViolationFilter=(props)=>{

    const { clampIncidents,
            clampCauses,
            clampStatuses,
            resetData,
            filterData
        } = useContext(ParkingViolationContext)

    const {locationList, cobraList} = useContext(ConfigurationContext);

    const optionCauses = [];
    if(Array.isArray(clampCauses)){
        
        clampCauses.forEach((item)=>{
          optionCauses.push({"title":item.cause, "value":item.id})
        })

    }

    const optionStatus = [];
    if(Array.isArray(clampStatuses)){
        
        clampStatuses.forEach((item, key)=>{
          optionStatus.push({"title":item.status, "value":item.id})
        })

    }


    const optionIncident = [];
    if(Array.isArray(clampIncidents)){
        
        clampIncidents.forEach((item, key)=>{
          optionIncident.push({"title":item.reason, "value":item.id})
        })
    }

    const optionLocations = [];
    if(Array.isArray(locationList)){
        locationList.forEach(element => {
            optionLocations.push({title:element.location_name + " - " + element.street.street_name, value:element.location_id})
        });
    }

    const optionCobraList = [];
    if(Array.isArray(cobraList)){
        cobraList.forEach(element=>{
            optionCobraList.push({title:element.full_name, value:element.user_id})
        })
    }



    return  <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
        inputs:[
            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Incident Date From"
            type="date"
            onChange={onSet}
            name="dateFrom"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Incident Date To"
            type="date"
            onChange={onSet}
            name="dateTo"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterTextInput
            key={key}
            label="Plate Number"
            type="text"
            placeholder="Enter plate number"
            onChange={onSet}
            name="plateNumber"
            currentValues={currentValues}
            />,

            (onSet, key, currentValues)=><FilterSelectInput
            key={key}
            label="Status"
            options={optionStatus}
            onChange={onSet}
            name="status"
            currentValues={currentValues}
          />,

        ],
        names:["dateFrom", "dateTo", "plateNumber", "status"]
    }}
    secondScreen={
        {
          inputs:[
           (onSet, key, currentValues)=> <FilterSelectInput 
              key={key}
              label="Clamp Location"
              options={optionLocations}
              onChange={onSet}
              name="location"
              currentValues={currentValues}
              />,

              (onSet, key, currentValues)=><FilterSelectInput
                key={key}
                label="Clamp Incident"
                options={optionIncident}
                onChange={onSet}
                name="incident"
                currentValues={currentValues}
              />,

              (onSet, key, currentValues)=> <FilterSelectInput 
                key={key}
                label="Cobra"
                options={optionCobraList}
                onChange={onSet}
                name="cobra"
                currentValues={currentValues}
              />,

              (onSet, key, currentValues)=><FilterSelectInput
              key={key}
              label="Clamp Cause"
              options={optionCauses}
              onChange={onSet}
              name="clampCause"
              currentValues={currentValues}
              hide={props.nonPayment}
            />,
          ],
          names:["location", "incident", "cobra", "clampCause"]
        }
      }
/>

}

export default ViolationFilter