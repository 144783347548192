import React, { Component } from 'react';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import TableHeader from '../../../Components/Common/TableHeader';
import TitleValueCard from '../../../Components/Common/TitleValueCard';
import { CashierConsumer } from '../../../Context/CashierContext';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';

class DailyCollectionGroups extends Component {

    state = {
        groupings: false,
        userId: functions.sessionGuard()
    
      }
    
    
      getGroupings = async () => {
    
        const server_response = await ajax.groupCashierDailyInvoiceTypes(this.state.userId);
        if (server_response.status === "OK") {
          this.setState({
            groupings: server_response.details.content
          })
        }
      }
    
    

    render() {
        return (
            <CashierConsumer>
              {props=>{
                 const groupings = props.dailySaleSummary
                 const reservationCollection = 0;
                  return(
                    <>
                    <TableHeader
                                    title="Daily Collections"
                                    subtitle="Summary of daily collections according to invoice category"
                                />
                              <div className="row mt-3">
    
                             {!groupings && <ActivityLoader/>}
    
                            {groupings && groupings.map((item, key)=>
                                <TitleValueCard 
                                    key={key}
                                    href="#" 
                                    title={item.invoice_type.invoice_name} 
                                    value={item.collection.amount_p} 
                                    icon={item.collection.amount>0?"fas fa-calendar-check":"fas fa-calendar-times"} 
                                    color={item.collection.amount>0?"icon-service bg-success-transparent rounded-circle text-success":"icon-service bg-danger-transparent rounded-circle text-danger"} 
                                    titleSize={"16px"}
                                    />
                            )}


                           
                                </div>
                </>
                  )
              }}
            </CashierConsumer>
           
        );
    }
}

export default DailyCollectionGroups;