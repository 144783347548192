import React from 'react';
import InitialAvatar from './Common/InitialAvatar';
import PopEditClient from './Vehicle/PopEditClient';
import $ from 'jquery';
import PopVehicleInput from './PopVehicleInput';
import functions from '../utils/functions';
import WarnDelete from './WarnDelete';
import ajax from '../utils/ajax';
import { Link } from 'react-router-dom';
import Toast from './Common/Toast';
import PopVehicleOptions from './Vehicle/PopVehicleOptions';
import PopLinkingOption from './Vehicle/PopLinkingOption';
import PopBulkyLinking from './Vehicle/PopBulkyLinking';


class ClientView extends React.Component {
  state={
      vehicleInfo:this.props.vehicleInfo,
      dialogue:false,
      mdlVehicle:false,
      mdlLink:false,
      newPlateNumber:'',
      clientVehicles:false,
      toast:false,
      mdlVehicleOption:false,
      linkedVehicle:false

  }

  componentDidMount()
  {
      this.getLinkedVehicles()
  }

  onClickEdit=()=>{
        this.setState({
            dialogue:false
        }, ()=>{
            this.setState({
                dialogue: <PopEditClient vehicleInfo={this.state.vehicleInfo} refresh={this.props.refresh}/>
            }, ()=>{
                $("#modal_edit_client").modal('show');
            })
        })
        
    }


    /**
     * Single vehicle linking
     */
    onClickLink=()=>{
        functions.closePopup('pop-linking-options');
        this.setState({
            mdlVehicle:false
        }, ()=>{
            this.setState({
                mdlVehicle: <PopVehicleInput title="Link Vehicle" onConfirm={this.onConfirmLink}/>
            }, ()=>{
                functions.openPopup('modal_vehicle_input')
            })
        })
        
    }

     /**
     * Bulky vehicle linking
     */
     onClickBulkyLink=()=>{
        functions.closePopup('pop-linking-options');
        this.setState({
            mdlVehicle:false
        }, ()=>{
            this.setState({
                mdlVehicle: <PopBulkyLinking 
                                    clientId={this.state.vehicleInfo.client.client_id}/>
            }, ()=>{
                functions.openPopup('pop-bulky-linking')
            })
        })
        
    }


    handleLinking=()=>{

        this.setState({
            mdlVehicle:false
        }, ()=>{
            this.setState({
                mdlVehicle: <PopLinkingOption 
                                handleSingle={this.onClickLink}
                                handleBulky={this.onClickBulkyLink}
                />
            }, ()=>{
                functions.openPopup('pop-linking-options')
            })
        })
    }


    onConfirmLink=(plate_number)=>{
        functions.closePopup('modal_vehicle_input')
        this.setState({
            mdlLink:false,
            newPlateNumber:plate_number
        }, ()=>{
            
            this.setState({
                mdlLink:<WarnDelete 
                title="Link Vehicle"
                description={"Please confirm that you want to link vehicle " + plate_number + " to this client."}
                icon="fas fa-question"
                buttonLabel="Confirm Link"
                onConfirm={this.linkVehicle}
                />
            }, ()=>{
                functions.openPopup('modal_delete')
            })
        })
    }

    onClickUnlink=()=>{
        functions.closePopup("modal_vehicle_option");
        this.setState({
            mdlLink:false,
        }, ()=>{
            
            this.setState({
                mdlLink:<WarnDelete 
                title="UnLink Vehicle"
                description={"Please confirm that you want to unlink vehicle " + this.state.linkedVehicle.plate_number + " from this client."}
                icon="fas fa-question"
                buttonLabel="Confirm unLink"
                onConfirm={this.unlinkVehicle}
                />
            }, ()=>{
                functions.openPopup('modal_delete')
            })
        })
    }

    linkVehicle=async()=>{
        const server_response=await ajax.link_vehicle(this.state.newPlateNumber, this.state.vehicleInfo.client.client_id);
        functions.closePopup('modal_delete')
        if(server_response.status==="OK")
        {
            this.setState({
                toast:<Toast 
                message={server_response.details.message} 
                onClose={()=>this.resetToast()}/>
            }, ()=>this.getLinkedVehicles())
        }else{
            this.setState({
                toast:<Toast 
                type={4}
                message={server_response.details.message} 
                onClose={()=>this.resetToast()}/>
            })
        }
    }


    unlinkVehicle=async()=>{
        const server_response=await ajax.unlink_vehicle(this.state.linkedVehicle.plate_number);
        functions.closePopup('modal_delete')
        if(server_response.status==="OK")
        {
            this.setState({
                toast:<Toast 
                message={server_response.details.message} 
                onClose={()=>this.resetToast()}/>
            }, ()=>this.getLinkedVehicles())
        }else{
            this.setState({
                toast:<Toast 
                type={4}
                message={server_response.details.message} 
                onClose={()=>this.resetToast()}/>
            })
        }
    }

    getLinkedVehicles=async()=>{
        const server_response=await ajax.list_client_vehicle(this.state.vehicleInfo.client.client_id);
        if(server_response.status==="OK")
        {
            this.setState({
                clientVehicles:server_response.details.content
            })
        }
    }


    resetToast=()=>{
        this.setState({
            toast:false
        })
      }


      onClickPlate=(vehicle)=>{
          this.setState({
              mdlVehicleOption:false,
              linkedVehicle:vehicle
          }, ()=>{

            this.setState({
                mdlVehicleOption:<PopVehicleOptions 
                                    vehicleInfo={this.state.linkedVehicle}
                                    onUnlink={this.onClickUnlink}/>
            }, ()=>{
              functions.openPopup("modal_vehicle_option");
            })
          
          })
         
      }
    

  render(){
     const vehicleInfo=this.state.vehicleInfo
    
      return (
       
        <div className="col-lg-8 col-md-12">
        {this.state.dialogue}
        {this.state.mdlVehicle}
        {this.state.mdlLink}
        {this.state.toast}
        {this.state.mdlVehicleOption}
        <div className="card custom-card">
        
            {vehicleInfo &&  vehicleInfo!=="404" && 

        <div className="">
        <div className="main-content-body main-content-body-contacts">
        <div className="main-contact-info-header pt-3">
        <div className="media">
            <div className="main-img-user">
                <InitialAvatar value={vehicleInfo.client.initials}/>
            </div>
            <div className="media-body">
                <h4>{vehicleInfo.client.full_name?vehicleInfo.client.full_name:"(No Name)"}</h4>
                <p>Client</p>
            
            </div>
        </div>
        <div className="main-contact-action btn-list pt-3 pr-3">
            <a 
                className="pointer btn ripple btn-primary text-white btn-icon" 
                data-placement="top" 
                data-toggle="tooltip"
                onClick={this.onClickEdit} 
                title="Edit Profile"><i className="fe fe-edit"></i></a>
            <a href="#" 
                className="btn ripple btn-secondary text-white btn-icon" 
                data-placement="top" 
                data-toggle="tooltip" 
                onClick={this.handleLinking}
                title="Link Vehicle">
                    <i className="fe fe-link"></i></a>

        </div>
        </div>
                <div className="main-contact-info-body">
                            
                                <div className="media-list pb-lg-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Full Name</label> <span className="tx-medium">{vehicleInfo.client.full_name}</span>
                                            </div>
                                            <div>
                                                <label>Phone Number</label> <span className="tx-medium">{vehicleInfo.client.phone_number}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Email</label> <span className="tx-medium">{vehicleInfo.client.email}</span>
                                            </div>
                                            <div>
                                                <label>Client ID</label> 
                                                <span className="tx-medium">{vehicleInfo.client.client_id}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <label>Linked Vehicles</label> 
                                                <span className="tx-medium">
                                                    {this.state.clientVehicles && this.state.clientVehicles!=="404" && this.state.clientVehicles.map((item, key)=>
                                                    <span key={key}>
                                                        {item.vehicle_id!==vehicleInfo.vehicle_id && 
                                                        <span className="float-left mr-3">
                                                            <Link to="#" onClick={()=>this.onClickPlate(item)}>{item.plate_number}</Link>
                                                        </span>
                                                        }
                                                       
                                                       </span>
                                                    )}
                                                    __
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media mb-0">
                                        <div className="media-body">
                                            <div>
                                                <label>Added Since</label> 
                                                <span className="tx-medium">{vehicleInfo.client.created_at}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                </div>
        </div>
        </div>}

          

        </div>  
    </div>  

  
      )
    
 
  }
}

export default ClientView;
