import React, { useContext, useEffect} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../Context/AuthContext";

const usePermission = (code) => {

  const history = useHistory();
  const {accessGranted} = useContext(AuthContext);

  useEffect(()=>{
    if(!accessGranted(code))
    history.push('/');
  }, []);
  
    return true;
  };
  

  export default usePermission