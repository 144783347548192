import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode.react';

const QRCodeGenerator = ({ text }) => {
 
  return (
    <div>
      <QRCode value={text} />
    </div>
  );
};

export default QRCodeGenerator;
