import React from 'react'
import { Link } from 'react-router-dom'

class TitleValueCard extends React.Component {

    render() {
        return (
          
            <div className="col-sm-6 col-md-4">
                <Link to={this.props.href}>
                    <div className="card custom-card pinned-card">
                        <div className="card-body d-flex ml-1 mt-3 mb-3">
                            <div className={this.props.color}>
                                <i className={this.props.icon}></i>
                            </div>
                            <div className="ml-4">
                                <p className="mb-2 text-muted">{this.props.title}</p>
                                <h3 className="mb-0" style={{fontSize:this.props.titleSize}}>{this.props.value}</h3>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

}

export default TitleValueCard
