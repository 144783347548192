import React from 'react';
import TableHeader from './TableHeader';


class SearchBar extends React.Component {
    state = {
        query: ''
    }

    componentDidMount() {

    }


    onChangeQuery = (event) => {
        this.setState({ query: event.target.value });
    }

    onSearch = (event) => {
        event.preventDefault();
        if (this.state.query.length > 0) {
            this.props.onSearch(this.state.query);
        }
    }

    render() {



        return (
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {/* <div>
                                        <h6 className="card-title mb-1">{this.props.title}</h6>
										<p className="text-muted card-sub-title"></p>
									</div> */}

                            <TableHeader
                                title={this.props.title}
                                subtitle="Enter the vehicle plate number to search"
                            />

                            {/* <div className="row row-sm"> */}

                            <form method="post" onSubmit={this.onSearch} className="col-lg-9 mt-4 mb-2 ">
                                {/* <div> */}
                                <div className="input-group">

                                    <input
                                        className="form-control"
                                        placeholder={this.props.placeholder || "Search for..."}
                                        type="text"
                                        autoFocus={true}
                                        onChange={this.onChangeQuery} />
                                    <span className="input-group-btn">
                                        <button className="btn ripple btn-primary" type="button" onClick={this.onSearch}>
                                            <span className="input-group-btn"><i className="fa fa-search mr-1"></i> Search</span>
                                        </button>
                                    </span>


                                </div>
                                {/* </div> */}
                            </form>


                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

        );


    }
}

export default SearchBar;