import React, { useContext } from 'react';
import StatisticCard from '../../../StatisticCard';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';
import FiscalReceiptContext from '../../../../Context/Efris/FiscalReceiptContext';


const EMonthlyTarget =()=>{

    const {taxConfig} = useContext(EfrisHistoryContext);
    const {currentMonthTax} = useContext(FiscalReceiptContext)

 
    return <StatisticCard
                title="Monthly Collection" 
                value={currentMonthTax}
                caption={taxConfig ? "Target: UGX. " + taxConfig.monthly_target.amount_p : "..."}
                icon={"fa fa-calendar"}
                color={"text-danger"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-danger"}
                />
 
}

export default EMonthlyTarget;