import apiCall from "./apiCall";


export default {

    async analyseAnnualContraventionCollection()
    {
        let response = await apiCall("analysis/contraventions/annual", [])
        return response;
    },


    async analyseLocationPerformance()
    {
        let response = await apiCall("analytics/location/performance/listing", [])
        return response;
    }



}
