import React from 'react'
import { Link } from 'react-router-dom';
import { FinanceConsumer } from '../../Context/FinanceContext';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import TableHeader from '../Common/TableHeader';
import StickerReceipt from '../Payments/StickerReceipt';


class RecenTicketMonitor extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        receiptView: false
    }



    onClickReceipt = (record) => {
        this.setState({
            receiptView: false
        }, () => {

            this.setState({
                receiptView: <StickerReceipt
                    receipt={record}
                />
            }, () => {
                functions.openPopup("pos_receipt");
            })

        })

    }




    render() {
        return (
            <FinanceConsumer>
                {props => {
                    const transactionList = props.recentTicketSales
                    return (

                        <div className="card custom-card">
                            <div className="card-body map-card">
                                <TableHeader
                                    title={"Recent Ticket Purchases"}
                                    subtitle="Sold tickets across sales agents"
                                    view="View All"
                                    href="/finance/ticket-records"
                                />


                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    <div className="table-responsive">
                                        <table className="table mg-b-0">
                                            <thead>
                                                <tr>
                                                    <th></th>

                                                    <th>Ticket info</th>
                                                    <th>Purchaser</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (transactionList === "404")
                                                        ?
                                                        <tr>
                                                            <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="3">
                                                                <NoContent />
                                                            </td>
                                                        </tr>
                                                        :
                                                        transactionList && transactionList !== "404" && transactionList.map((item, key) =>
                                                            <tr key={key}>
                                                                <td className="bd-t-0">
                                                                    <div className="main-img-user">
                                                                        {/* <i className="fa fa-car text-secondary" style={{ fontSize: "34px" }} /> */}
                                                                        {/* <i className="fad fa-sticky-note" style={{ fontSize: "34px" }} ></i> */}
                                                                        <i className="fa fa-sticky-note text-secondary" style={{ fontSize: "37px" }}></i>
                                                                    </div>
                                                                </td>

                                                                <td className="bd-t-0">
                                                                    <div className="tx-bold">
                                                                        <Link to={
                                                                            {
                                                                                pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                                                state: { plateNumber: item.vehicle.plate_number }
                                                                            }}
                                                                        >
                                                                            {item.vehicle.plate_number}
                                                                        </Link>
                                                                    </div>
                                                                    <small className="tx-12">{item.created_at.short_date}</small>
                                                                    <br />
                                                                    <small className="tx-12 tx-gray-500">{item.created_at.time}</small>

                                                                    {/* <h6 className="mg-b-0">{item.vehicle.plate_number}</h6> */}


                                                                </td>

                                                                {/* <td className="bd-t-0">
                                                                    <h6 className="mg-b-0">{item.vehicle.plate_number}</h6><small className="tx-11 tx-gray-500">{item.created_at.short_date + " " + item.created_at.time}</small>
                                                                </td> */}
                                                                <td className="bd-t-0">
                                                                    <h6 className="mg-b-0 font-weight-bold">{item.amount.amount_c}</h6>

                                                                    <small className="tx-12 tx-gray-500">Purchased By:</small>
                                                                    <br />
                                                                    <small className="tx-12 tx-gray-500">{item.sold_by.username}</small>
                                                                </td>
                                                            </tr>
                                                        )
                                                }

                                            </tbody>
                                        </table>
                                        {!transactionList && <ActivityLoader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                }}
            </FinanceConsumer>

        )
    }

}

export default RecenTicketMonitor
