import React from 'react'
import { Link } from 'react-router-dom'


class DashRow3 extends React.Component {

    render() {
        return (
            <div className={`d-flex flex-row text-center align-items-center ${this.props.className}`}>
                <div className={`${this.props.color}`}>
                    <i className={this.props.icon}></i>
                </div>
                <div className="p-4">
                    <p className="mb-2 text-muted">{this.props.title}</p>
                    <h3 className="mb-0">{this.props.value}</h3>
                </div>
            </div>
    
        )
    }

}

export default DashRow3
