import React from 'react'
import functions from '../../../utils/functions';
import TableHeader from '../../Common/TableHeader';
import CashierBankTotal from './CashierBankTotal';
import CashierCashTotal from './CashierCashTotal';

class BankCashSummary extends React.Component {

    state = {
        userId: functions.sessionGuard(),

    }



    render() {
        return (

            <div className="card custom-card">
                <div className="card-body">
                    
                    <TableHeader
                        title="Daily Collection Totals"
                        subtitle="Summary of collected cash and bank"
                    // view="View All"
                    // onClick={this.props.onClickViewAll}
                    />

                    <div className="row mt-3">
                    <CashierCashTotal/>

                    <CashierBankTotal/>


                </div>
                   


                </div>
            </div>
        )
    }

}

export default BankCashSummary