import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TaskBoardCard extends Component {
    render() {
        return (
            <div className="col-md-12">
                <Link to={this.props.href}>
                <div className="card custom-card pinned-card bg-company">
                    <div className="col-12">
                        <div className="card-body text-center">
                            <h6 className="mb-0 text-muted">{this.props.title}</h6>
                            <h2 className="mb-1 mt-2 number-font"><span className="counter">
                                {this.props.value}
                            </span></h2>
                            <p className="mb-0 text-muted">{this.props.caption}</p>
                        </div>
                    </div>

            </div>
            </Link>
        </div>
        );
    }
}

export default TaskBoardCard;