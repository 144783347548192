import React, { useEffect, useState } from 'react';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxClamping from '../utils/remote/ajaxClamping';
import ajaxLog from '../utils/remote/ajaxLog';
import ajaxSession from '../utils/remote/ajaxSession';
import { detectTable, restoreTable } from '../utils/script/customTables';

const VehicleContext = React.createContext();

export const VehicleConsumer = VehicleContext.Consumer;

export const VehicleProvider = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [transactionList, setTransactionList] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [filterTitle, setFilterTitle] = useState('All Vehicles');
    const [vipStatus, setVipStatus] = useState('');
    const [statistics, setStatistics] = useState(false);
    const [filter, setFilter] = useState(false);

    const [fallbackPage, setFallbackPage] = useState(1);
    const [tempVehicleData, setTempVehicleData] = useState(false);
    const [loading, setLoading] = useState(false);


    const [plateNumber, setPlateNumber] = useState("")
    const [vehicleInfo, setVehicleInfo] = useState(false)
    const [data, setData] = useState({"page":"1"})
    const [vehicleTypes, setVehicleTypes] = useState(false);
   
    useEffect(() => {
        getVehicleStatistics();
        getVehicleTypes();
    }, [])

    useEffect(()=>{
        listAllRecords();
     }, [data])
 
     useEffect(()=>{
         if(currentPage>1)
         {
             fetchMoreTrigger()
         }
     }, [currentPage])
 

    useEffect(()=>{
        if(plateNumber.length>0)
        {
            getVehicle()
        }
    }, [plateNumber])


    const getVehicleStatistics = async () => {
        const server_response = await ajax.getVehicleStatistics();

        if (server_response.status === "OK") {
            setStatistics(server_response.details.content)
        }
    }

    const listAllRecords = async () => {

        setTransactionList(false)
        const server_response = await ajax.getVehicleRecords(data);
        if (server_response.status === "OK") {
            if (transactionList) {
                setTransactionList(server_response.details.content.list);
                setMetaData(server_response.details.content.meta);
            } else {
                setTransactionList(server_response.details.content.list);
                setMetaData(server_response.details.content.meta);
            }

        } else {
            setTransactionList("404");
            setMetaData(false);

        }

    }

    const refreshVehicleData = () => {
        if (tempVehicleData) {
            setFilter(false);
            setCurrentPage(fallbackPage);
            setTransactionList(tempVehicleData);
        }
    }

  

    const getVehicle = async () => {
        setLoading(true)
        const server_response = await ajax.getVehicleByPlate(plateNumber)
        setLoading(false)
        if (server_response.status === "OK") {
          
           setVehicleInfo(server_response.details.content)
        
        } else {
            setVehicleInfo(false)
        }
      }





      const fetchMoreTrigger=()=>{
        const newData={
            ...data,
            "page":currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async(newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajax.getVehicleRecords(newData);

        setLoading(false);
           

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setTransactionList(transactionList.concat(incoming));
            setMetaData(server_response.details.content.meta);
        }
       
    }


    const goToNextPage=()=>{
        if(currentPage < metaData.total_pages){
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData=()=>{
        setCurrentPage(1)
        setData({"page":"1"})
    }

    const filterData=(options)=>{
        setData({
            "date_from":options.dateFrom,
            "date_to":options.dateTo,
            "car_type":options.selectedValue,
            "page":"1",
            "plate_hint":options.keyword
        })
        
    }



   const getVehicleTypes = async () => {

        const server_response = await ajax.getVehicleTypes();
        if (server_response.status === "OK") {
            setVehicleTypes(server_response.details.content)
        } else {
           setVehicleTypes("404")
        }
    }




    return (
        <VehicleContext.Provider value={{
            transactionList,
            loading,
            vehicleInfo,
            plateNumber,
            statistics,
            metaData,
            loading,
            vehicleTypes,
            listAllRecords,
            refreshVehicleData,
            listAllRecords,
            getVehicle,
            setPlateNumber,
            getVehicleStatistics,
            filterData,
            resetData,
            setData,
            goToNextPage
        }}
        >
            {props.children}
        </VehicleContext.Provider>
    );
}


export default VehicleContext;
