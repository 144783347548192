import React from 'react';
import $ from 'jquery';
import PINLocation from '../Common/PINLocation';
import ajax from '../../utils/ajax';
import ActivityLoader from '../Common/ActivityLoader';
import Toast from '../Common/Toast';


class PopSessionLocation extends React.Component {
  state = {
    userId: this.props.userId,
    position: false,
    center: false,
    toast: false
  }

  componentDidMount() {
    const sessionData = this.props.session
    this.setState({
      position: [{
        latitude: sessionData.location.lat * 1,
        longitude: sessionData.location.lng * 1,
        description: sessionData.location.location_name + ' along ' + sessionData.location.street.street_name
      }],
      center: {
        latitude: sessionData.location.lat * 1,
        longitude: sessionData.location.lng * 1,
      }
    })
  }




  render() {

    return (
      <div className="modal" id="pop-session-location">
        {this.state.toast}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
              <h6 className="modal-title">Session Location</h6>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-bod">
              {this.state.position && <PINLocation coords={this.state.position} center={this.state.center} /> || <ActivityLoader />}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PopSessionLocation;
