import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'

class UpdateTowingStation extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    towingStation: '',
    towingStationID: '',
    info: '',
    description: '',
    lat: '',
    lng: ''
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/config/towingstations');
    } else {
      const towStationRecord = this.props.location.state.record;
      this.setState({
        towingStation: towStationRecord.station_name,
        towingStationID: towStationRecord.tow_station_id,
        description: towStationRecord.description,
        lat: towStationRecord.lat,
        lng: towStationRecord.lng,

      })

    }
  }


  onChangetowingStation = (event) => {
    this.setState({ towingStation: event.target.value })
  }

  onChangeDescription = (event) => {
    this.setState({ description: event.target.value })
  }

  onChangeLat = (event) => {
    this.setState({ lat: event.target.value })
  }

  onChangeLng = (event) => {
    this.setState({ lng: event.target.value })
  }

  onSave = async (event) => {
    event.preventDefault();
    const { towingStation, towingStationID, description, lat, lng } = this.state;
    if (towingStation.length > 0 && towingStationID.length > 0) {
      //fetch login from the API
      const server_response = await ajax.updateTowingStation(towingStationID, towingStation, description, lat, lng);
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._requiretowingStation} />,
        loading: ''
      })
    }


  }

  render() {
    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Edit Towing Station "
              previousPageTitle="Towing Station"
              back="/config/towing-stations"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>


                          <TextInput
                            label="Towing Station"
                            type="text"
                            onChange={() => this.onChangetowingStation}
                            value={this.state.towingStation}
                          />
                          <TextInput
                            label="Description"
                            type="text"
                            onChange={() => this.onChangeDescription}
                            value={this.state.description}
                          />
                          <TextInput
                            label="Geo Latitudes"
                            type="text"
                            onChange={() => this.onChangeLat}
                            value={this.state.lat}
                          />
                          <TextInput
                            label="Geo Longitudes"
                            type="text"
                            onChange={() => this.onChangeLng}
                            value={this.state.lng}
                          />


                          <div className="modal-footer">
                            <input className="btn ripple btn-success" type="button" value="Save Changes" onClick={this.onSave} />
                          </div>
                        </form>

                      </div>
                    </div>


                  </div>


                </div>

              </div>

              <ConfigMenu />



            </div>

          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default UpdateTowingStation
