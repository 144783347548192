import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';
import PopPaymentSummary from '../Payments/PopPaymentSummary';
import PopReservationReceipt from './PopReservationReceipt';

class PopCashPayment extends React.Component {

    state = {
        amount: '',
        depositorContact: '',
        depositorName: '',
        info: ''
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    onChangeDepositorContact = (event) => {
        this.setState({ depositorContact: event.target.value })
    }

    onChangeDepositorName = (event) => {
        this.setState({ depositorName: event.target.value })
    }


    makeOfficePayment = async (amount, depositorName, depositorContact) => {

        this.setState({
            info: <ActivityLoader />,
            officePayment: false
        })
        const server_response = await ajax.makeReservationCashPayment(
            this.props.invoiceId,
            amount,
            depositorName,
            depositorContact
        )

        if (server_response.status === "OK") {
            //load receipt
            functions.closePopup("modal_office_payment");
            this.props.onConfirm(<AlertSuccess message={server_response.details.message} />)
            this.setState({
                receipt: <PopReservationReceipt record={server_response.details.content} />
            }, () => {
                functions.openPopup("modal_payment_summary");
            })
        } else {
            this.setState({
                info: <AlertError message={server_response.details.message} />
            })
        }
    }




    onConfirm = (event) => {
        event.preventDefault();
        const { amount, depositorContact, depositorName } = this.state
        if (amount.length > 0 && depositorContact.length > 0 && depositorName.length > 0) {

            this.makeOfficePayment(amount, depositorName, depositorContact);

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }




    render() {
        return (
            <>
                {this.state.receipt}
                <div className="modal" id="modal_office_payment">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New Cash Payment</h6>
                                <span>Amount Due: {this.props.balance}</span>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <form method="post" onSubmit={this.onConfirm}>

                                    <TextInput
                                        label="Amount"
                                        type="number"
                                        onChange={() => this.onChangeAmount}
                                        value={this.state.amount}
                                    />

                                    <TextInput
                                        label="Depositor's Name"
                                        type="text"
                                        onChange={() => this.onChangeDepositorName}
                                        value={this.state.depositorName}
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={() => this.onChangeDepositorContact}
                                        value={this.state.depositorContact}
                                    />

                                </form>




                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Confirm Payment</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }
}

export default PopCashPayment;
