import React from 'react';
import ActivityLoader from './Common/ActivityLoader';

class PopViewImage extends React.Component {

    render() {


        return (
            <div className="modal" id="modal_view_image">
                <div className="modal-dialog modal-dialog-centered modal-lg justify-content-center align-items-center" role="document">

                    {this.props.path && <img alt="avatar" src={this.props.path} />}

                    {!this.props.path && <ActivityLoader />}

                </div>
            </div>
        )


    }
}

export default PopViewImage;
