import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import TitleValueCard from '../../../Components/Common/TitleValueCard'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import ConfigurationContext from '../../../Context/ConfigurationContext'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import dictionary from '../../../utils/dictionary'


const eformats = dictionary._exportConfigBtnFormats

class ConfigWrongParkingDescription extends React.Component {
    static contextType = ConfigurationContext;


    state = {
        userId: functions.sessionGuard(),
        // wrongParkingDescriptionList: false,
        descriptionID: false,
        description: false,
        toast: ''
    }


    componentDidMount() {
        const { getWrongParkingDescriptions } = this.context;
        getWrongParkingDescriptions();
    }



    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/create/wrongparking/description'
        })
    }


    onClickEdit = () => {

        if (this.state.description) {

            this.props.history.push({
                pathname: '/config/update/wrongparking/description',
                search: '?q=1',
                state: { record: this.state.description }
            })
        }

    }

    // onClickDelete = () => {
    //     if (this.state.description) {
    //         $("#modal_delete").modal('show')
    //     }
    // }

    // onConfirmDelete = async () => {

    //     if (this.state.descriptionID) {
    //         $("#modal_delete").modal('hide');

    //         const server_response = await ajax.deleteBranch(this.state.descriptionID, this.state.userId);
    //         if (server_response.status === "OK") {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} />
    //             }, () => {

    //                 //hide record
    //                 functions.hide('br_' + this.state.descriptionID);
    //                 //Refresh list
    //                 this.getBranches();
    //             })
    //         } else {
    //             this.setState({
    //                 toast: <Toast message={server_response.details.message} />
    //             })
    //         }

    //     }

    // }

    onSelectRecord = (descriptionID, description) => {
        this.setState({
            descriptionID: descriptionID,
            description: description,
            toast: ''
        })
    }


    // RenderWPDescriptionCount = () => {
    //     return (
    //         <ConfigurationConsumer>
    //             {props => {
    //                 const countAllClampAbortReasons = props.totalAbortReasons
    //                 return (
    //                     <div className="text-wrap">
    //                         <div className="">
    //                             <div className="btn-list">

    //                                 <button type="button" className="btn ripple btn-primary btn-sm">
    //                                     Total Clamp Abort Reasons <span className="badge badge-light ml-2">{countAllClampAbortReasons}</span>
    //                                 </button>

    //                             </div>
    //                         </div>
    //                     </div>
    //                 )
    //             }}
    //         </ConfigurationConsumer>
    //     )
    // }

    render() {

        const { 
            wrongParkingDescriptionList, 
            getWrongParkingDescriptions, 
            countAllWrongParkingDescriptions 
        } = this.context;

        return (
            <div className="page">

                <WarnDelete
                    title="Delete Branch?"
                    description="Make sure this branch has not streets and locations under it"
                    onConfirm={() => this.onConfirmDelete()} 
                />

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="W.P reasons"
                            previousPageTitle="System Settings"
                            back="/config"
                            options={{
                                "add": () => this.onClickAdd(),
                                "edit": () => this.onClickEdit(),
                                // "delete": () => this.onClickDelete()
                            }}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <div className="d-flex justify-content-between align-items-center filter-example">
                                                    <TableHeader
                                                        title="List of wrong parking reasons"
                                                        subtitle="Represents the wrong parking examples"
                                                    />

                                                    <div className="text-wrap">
                                                        <div className="">
                                                            <div className="btn-list">

                                                                <button type="button" className="btn ripple btn-primary btn-sm">
                                                                    Total Wrong Parking Descriptions <span className="badge badge-light ml-2">{countAllWrongParkingDescriptions}</span>
                                                                </button>

                                                                <button className="btn ripple btn-light btn-sm" onClick={this.context.getWrongParkingDescriptions}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive allView-table border-top">

                                                    {(wrongParkingDescriptionList === "404") && <NoContent />}

                                                    {wrongParkingDescriptionList && Array.isArray(wrongParkingDescriptionList) &&
                                                        <table id="config-wpreasons" export-format={eformats} className="table text-nowrap mb-0 table-hover database">
                                                            <thead>
                                                                <tr>
                                                                    <th>No.</th>
                                                                    <th>Description</th>
                                                                    <th>Created at</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {wrongParkingDescriptionList && wrongParkingDescriptionList !== "404" && wrongParkingDescriptionList.map((item, key) =>
                                                                    <tr
                                                                        id={"wpd_" + item.description_id}
                                                                        key={key}
                                                                        className={`${this.state.descriptionID == item.description_id ? 'flag-row' : ''}`}
                                                                        onClick={() => this.onSelectRecord(item.description_id, item)}
                                                                    >
                                                                        <td>{key + 1}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>
                                                                            {item.created_at.date} <br />
                                                                            <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    }

                                                    {!wrongParkingDescriptionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfigMenu />
                        </div>
                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigWrongParkingDescription
