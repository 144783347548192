import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import DashCard from '../../Components/Cards/OperationCards/DashCard'
import RecentSessions from '../../Components/RecentSessions'
import ajax from '../../utils/ajax'
import dictionary from '../../utils/dictionary'
import $ from 'jquery';
import PopNewSession from '../../Components/Session/PopNewSession'
import { Link } from 'react-router-dom'
import ReservationChart from '../../Components/BarChart/ReservationChart'
import PlaceGraphHolder from '../../Components/SmallParkingMap'
import OperationsGraph from '../../Components/BarChart/OperationsGraph'
import BarChart from '../../Components/BarChart/BarChart'
import SpaceAvailabilityCard from '../../Components/Location/SpaceAvailabilityCard'
import { OperationConsumer } from '../../Context/OperationContext'
import { AuthConsumer } from '../../Context/AuthContext'
import { RenderSecure } from '../../utils/script/RenderSecure'
import RecentClamps from '../../Components/Clamps/RecentClamps'

class Dashboard extends React.Component {

  state = {
    userId: functions.sessionGuard()
  }




  ViewAllSpaces = () => {
    this.props.history.push('/parking-space/available-space')
  }

  ViewFullMap = () => {
    this.props.history.push('/parkingmap')
  }

  onClickStartSession = () => {
    $("#modal_new_session").modal('show');
  }



  buttons = (props) => {

    return (
      <>
        <RenderSecure code="C-SESSION-01">
          <button className="btn ripple btn-success" onClick={this.onClickStartSession}>
            <i className="far fa-edit"></i> Start Session
          </button>
        </RenderSecure>

        <RenderSecure code="FIELD-ACTIVITY-00">
          <Link to="/operations/supervision" className="btn ripple btn-light">
            <i className="fa fa-user-check"></i> Field Supervision</Link>
        </RenderSecure>

      </>
    )
  }

  customHeaderOptions = (props) => {

    const permissionList = props.permissionList

    return (
      <>

        <RenderSecure code='R-SESSION-02'>
          <Link className="dropdown-item" to="/parking_sessions/sessions">
            <i className="fe fe-list mr-2"></i>Parking Sessions
          </Link>
        </RenderSecure>
        <RenderSecure code='R-SESSION-02'>
          <Link className="dropdown-item" to="/operations/supervision">
            <i className="fe fe-user mr-2"></i>Field Supervision
          </Link>
        </RenderSecure>
        <RenderSecure code='R-LOCATION-DISTRIB-02'>
          <Link className="dropdown-item" to="/operations/user-location-mapping">
            <i className="fas fa-map-pin mr-2"></i>Location Distribution
          </Link>
        </RenderSecure>

        <RenderSecure code='R-DEVICE-DISTRIB-02'>
          <Link className="dropdown-item" to="/operations/device-distribution">
            <i className="fab fa-android mr-2"></i>Device Distribution
          </Link>
        </RenderSecure>

      </>
    )
  }



  CardSection = () => {

    return (
      <OperationConsumer>
        {props => {

          return (
            <>
              <RenderSecure code="R-SESSION-02">
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Active Sessions"
                  icon="far fa-clock"
                  value={props.countActiveSessions}
                  color="icon-service rounded-circle bg-primary-transparent text-primary"
                  href={
                    {
                      pathname: '/parking_sessions/sessions',
                      state: { status: 1 }
                    }
                  }
                />
              </RenderSecure>
              <RenderSecure code="R-AVAIL-PARKING-02">
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Available Parking"
                  icon="fas fa-hourglass-start"
                  href="/parking-space/available-space"
                  value={props.countAvailableParking}
                  color="icon-service rounded-circle bg-secondary-transparent text-secondary"
                />
              </RenderSecure>
              <RenderSecure code="R-CLAMP-02">
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Clamped Vehicles"
                  icon="fas fa-ban"
                  href="/enforcement/clamp_transactions"
                  value={props.countClampedVehicles}
                  color="icon-service bg-success-transparent rounded-circle text-success"
                />
              </RenderSecure>
              <RenderSecure code="R-TOW-02">
                <DashCard className="col-sm-6 col-md-4 col-xl-3"
                  title="Towed Vehicles"
                  icon="fas fa-wrench"
                  href="/enforcement/tow_transactions"
                  value={props.countTowedVehicles}
                  color="icon-service bg-info-transparent rounded-circle text-info"
                />
              </RenderSecure>

            </>
          )

        }}
      </OperationConsumer>
    )

  }



  render() {
    return (
      <AuthConsumer>
        {props => {
          const permissionList = props.permissionList
          return (
            <div className="page">
              <LeftSideBar />
              <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                <TopBar />
                <div className="container-fluid">

                  <PageHeader
                    title="Operations"
                    previousPageTitle="Dashboard"
                    back="/operations"
                    // options={{
                    //   "customOptions": <this.customHeaderOptions permissionList={permissionList} />
                    // }}
                    buttons={<this.buttons permissionList={permissionList} />}
                  />

                  <PopNewSession onConfirm={this.onConfirmStartSession} />

                  <div className="row">
                    <this.CardSection />
                  </div>

                  <div className="row">
                    <RenderSecure code='R-SESSION-02'>
                      <div className="col-sm-12 col-md-12 col-xl-8 col-lg-8">

                        <RecentSessions href="/parking_sessions/sessions" />
                      </div>
                    </RenderSecure>

                    <RenderSecure code="R-MAP-02">
                      <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">
                        {/* <PlaceGraphHolder onClickViewAll={this.ViewFullMap} /> */}
                        <ReservationChart />
                        {/* <RecentClamps /> */}
                      </div>
                    </RenderSecure>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-5 col-lg-5">
                      {/* <ReservationChart /> */}
                      <RecentClamps />
                    </div>

                    <RenderSecure code='R-SESSION-02'>
                      <div className="col-sm-12 col-md-12 col-xl-7 col-lg-7">
                        <OperationsGraph />
                      </div>
                    </RenderSecure>


                  </div>

                </div>

              </div>
              <RightSideBar />

            </div>
          );

        }}

      </AuthConsumer>
    )



  }
}

export default Dashboard
