import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import Footer from '../Components/Common/Footer'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import functions from '../utils/functions'
import RightSideBar from '../Components/Common/RightSideBar'
import ajax from '../utils/ajax'
import $ from 'jquery';
import dictionary from '../utils/dictionary';

// import Toast from '../Components/Common/Toast'
import PopVehicleInput from '../Components/PopVehicleInput'
import ActivityLoader from '../Components/Common/ActivityLoader'
// import DashRow from '../Components/DashRow'
import TableHeader from '../Components/Common/TableHeader'
import { ManageUserConsumer } from '../Context/ManageUserContext'

class RecentlyAddedUsers extends React.Component {


    state = {
        userId: functions.sessionGuard(),
        userList: false,
        menuOptions: '',
        userRecord: '',
        selectedUserId: '',
    }



    onSelectRecord = (selectedUserId, userRecord) => {
        this.setState({
            selectedUserId: selectedUserId,
            userRecord: userRecord
        }, () => {
            //change menu according to selected role
            if (this.state.userRecord.user_role.role_id === "2") {
                this.setState({
                    menuOptions: {
                        "startSession": () => this.onClickStartSession(),
                        "add": () => this.onClickAdd(),
                    }
                })

            } else {
                this.setState({
                    menuOptions: {
                        "add": () => this.onClickAdd(),
                    }
                })

            }

        })
    }

    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/users/new'
        })
    }

    onClickStartSession = () => {
        $("#modal_vehicle_input").modal('show');
    }

    onConfirmVehicle = (plateNumber) => {
        $("#modal_vehicle_input").modal('hide');

        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: this.state.userRecord, vehiclePlate: plateNumber }
        })
    }


    userListing = () => {
        return (
            <ManageUserConsumer>
                {props => {
                    const userList = props.recentUsers;
                    return(
                    <div className="scroll-widget overflow-auto">
                        <div className="table-responsive">
                        <table className="table text-nowrap mg-b-0 table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Full name</th>
                                    <th>Role</th>
                                    <th>Phone number</th>
                                    <th>Created on</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {userList && userList !== "404" &&

                                    userList.map((item, key) =>
                                        <tr key={key}>
                                            <td>
                                                <div className="main-img-user">
                                                    <img alt="avatar" src={item.profile_photo.file_path} />
                                                </div>
                                            </td>
                                            <td>
                                                <h6 className="mg-b-1">{item.first_name} {item.last_name}</h6>
                                                <small className="tx-12 tx-gray-500">@ {item.username}</small>
                                            </td>
                                            <td className="text-center">
                                                <span className="btn ripple btn-light btn-sm">{item.user_role.role_name}</span>
                                            </td>
                                            <td>{item.phone_number}</td>
                                            <td>
                                                {item.created_at.date}<br />
                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <p className={`task-icon-user m-0 bg-${item.activity.status_id==1?"success":item.activity.status_id==2?"primary":"danger"} mr-2`}></p>
                                                    <span className="text-muted tx-13 m-0">{item.activity.status_name}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                            </tbody>
                        </table>
                        {!userList && <ActivityLoader />}
                    </div>
                    </div>
                    )
                }}
            </ManageUserConsumer>
        )
    }

    render() {

        return (


            
                <div className="card custom-card">

                    <div className="card-body map-card">

                        <TableHeader
                            title="Recently added system users"
                            subtitle="System users with their role at multiplex."
                            view="View All"
                            href={this.props.href}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                            <this.userListing />
                        </div>

                    </div>
                </div>





        );

    }
}

export default RecentlyAddedUsers
