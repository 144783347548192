import React, { useContext } from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import PopSessionInfo from '../../Components/PopSessionInfo'
import WarnDelete from '../../Components/WarnDelete'
import Toast from '../../Components/Common/Toast'
import PopNewSession from '../../Components/Session/PopNewSession'
import TableHeader from '../../Components/Common/TableHeader'
import PINLocation from '../../Components/Common/PINLocation'
import PopSessionLocation from '../../Components/Operations/PopSessionLocation'
import Export from '../../Components/Common/Export'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import PopExport from '../../Components/Export/PopExport'
import OperationContext, { OperationConsumer } from '../../Context/OperationContext'
import $ from 'jquery';
import { beautifyTable } from '../../utils/script/customTables'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import BranchSessionContext from '../../Context/BranchSessionContext'
import { RenderSecure } from '../../utils/script/RenderSecure'
import BranchContext from '../../Context/BranchContext'



const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

class ListBranchSessions extends React.Component {
    static contextType = BranchSessionContext;

    state = {
        userId: functions.sessionGuard(),
        menuOptions: '',
        sessionList: false,
        metaData: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        noOfRecords: 8,
        loadTable: true,
        enableUpdate: false,
        heading: "",
    }

    componentDidMount() {
        const { listSessions } = this.context
        listSessions();
    }

    onClickStartSession = () => {
        $("#modal_new_session").modal('show');
    }



    viewSessionInfo = (record) => {
        this.setState({
            sessionPreview: false
        }, () => {
            this.setState({
                sessionPreview: <PopSessionInfo session={record} onEnd={this.onEndSession} />
            }, () => {
                $("#modal_session_info").modal('show');
            })

        })

    }

    onEndSession = (sessionId) => {
        this.setState({
            activeSessionId: sessionId,
            warnSessionEnd: false
        }, () => {

            this.setState({
                warnSessionEnd: <WarnDelete
                    title="End Session?"
                    description="You are about to end session as the currently logged in user!"
                    buttonLabel="End Session"
                    onConfirm={() => this.onConfirmEndSession()} />
            }, () => {
                functions.closePopup("modal_session_info");
                functions.openPopup("modal_delete");
            })

        })

    }




    onConfirmEndSession = async () => {



        if (this.state.activeSessionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.endSession(
                this.state.activeSessionId,
                this.state.userId
            );
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {
                    const { listAllSessions } = this.context
                    listAllSessions();
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }




    onConfirmStartSession = (conductor, plateNumber) => {
        $("#modal_new_session").modal('hide');
        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: conductor, vehiclePlate: plateNumber }
        })
    }

    onClickExport = () => {
        this.setState({
            dialogue: false
        }, () => {
            this.setState({
                dialogue: <PopExport
                    pdfWithLimitedRecords={true}

                    pdfUrlWithLimit={"session/export/pdf"}

                />
            }, () => {
                $("#modal_export").modal('show');
            })
        })

    }


    buttons = () => {

        const {branchListing} = useContext(BranchContext)
    
        return (
          <>
           <div className="btn btn-list">
                  <RenderSecure code="U-SWITCH-BRANCH-0">
                  <a className="btn ripple btn-primary dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i className="fa fa-exchange-alt mr-1"></i> Switch Branch <i className="fas fa-caret-down ml-1"></i>
                  </a>
                  <div className="dropdown-menu tx-13">
                    {Array.isArray(branchListing) && branchListing.map((item, key)=>
                      <Link to="#" className="dropdown-item" key={key} onClick={()=>this.context.switchSessionBranch(item.branch_id)}>
                        <i className="fa fa-university text-secondary mr-1"></i>{item.branch_name} Branch</Link>
                    )}
    
                  </div>
                  </RenderSecure>

                  {Array.isArray(this.context.sessionListing) &&
                    <Link to="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                    </Link>}
                 
    
                <button className="btn ripple btn-light" onClick={this.context.listSessions}>
                  <i className="fa fa-redo"></i> Refresh
                </button>
           </div>
             
             
           
    
          </>
        )
      }









    onChangeStatus = (status) => {
        this.setState({
            status: status,
            sessionList: false,
            metaData: false,
            currentPage: 1
        }, () => this.listAllSessions())
    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }


    RenderSessions = () => {

        const sessionList = this.context.sessionListing

        return (
          
                        <>
                        <div className="table-responsive allView-table border-top">


                            {Array.isArray(sessionList) &&
                                <table id="table-branch-session" export-format={exportFormats} className="table text-nowrap table-hover mg-b-0 datatable">
                                    <thead>
                                        <tr>
                                            <th>Session ref</th>
                                            <th>Start Time</th>
                                            <th>Vehicle</th>
                                            {/* <th>Street</th> */}

                                            <th>Slots</th>
                                            <th>Status</th>
                                            <th>Location</th>
                                            <th>Start Device</th>
                                            <th>Started By</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {sessionList.map((item, key) =>
                                            <tr key={key}>
                                                <td>
                                                    <Link
                                                        to="#"
                                                        onClick={() => this.viewSessionInfo(item)}>
                                                        {item.session_id_c}
                                                    </Link>
                                                </td>


                                                <td>
                                                    {item.start_time.date}
                                                    <small className="tx-12 tx-gray-500"> at {item.start_time.time}</small>
                                                </td>
                                                <td className="tx-bold">
                                                    <Link to={
                                                        {
                                                            pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                                            state: { plateNumber: item.vehicle.plate_number }
                                                        }}>{item.vehicle.plate_number}
                                                    </Link>
                                                </td>
                                                {/* <td>
                                                    <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                        <i className="fas fa-map-marker-alt text-primary " /> &nbsp;
                                                        {item.location.street.street_name}
                                                    </span>
                                                </td> */}


                                                {/* <td>
                                                    {item.location.location_name}
                                                </td> */}
                                                <td>
                                                    {item.number_of_slots}
                                                </td>
                                                <td>
                                                    {item.active === "1" && <span className="badge rounded badge-success">Active</span>}
                                                    {item.active === "0" && <span className="badge rounded badge-secondary">Ended</span>}
                                                </td>
                                                <td>
                                                    <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                        <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                        {item.location.location_name}
                                                        <small className="tx-12 tx-gray-500"> at {item.location.street.street_name}</small>
                                                    </span>
                                                </td>
                                                <td>
                                                    {item.start_device.tag_number}
                                                </td>
                                                <td>{item.conductor.first_name + ' ' + item.conductor.last_name}</td>

                                            </tr>
                                        )}


                                    </tbody>
                                </table>
                            }

                            {(sessionList === "404") && <NoContent />}
                            {!sessionList && <ActivityLoader />}

                        </div>
                        {/* {Array.isArray(sessionList) && <LoadMoreButton action={props.fetchMore} loading={props.loading} />} */}
                        </>

                    )

    }


    RenderFilter = () => {
        const {branchUserListing} = useContext(BranchContext)
        const userOptions = [];
                    Array.isArray(branchUserListing) && branchUserListing.map((item, key) =>
                        userOptions.push({
                            title: item.user.first_name + " " + item.user.last_name,
                            value: item.user.user_id
                        })
                    );


                    
            return (
                <CustomizedFilterBar
                    selection={{ title: 'Started By', options: userOptions }}
                    keyword="Plate Number"
                    onReset={this.context.resetData}
                    onConfirm={this.context.filterData}
                />
            )
        
    }



   

    render() {

        return (
            <div className="page">
                {this.state.sessionPreview}
                {this.state.warnSessionEnd}
                {this.state.dialogue}


                <PopNewSession onConfirm={this.onConfirmStartSession} />

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Branch Session History"
                            previousPageTitle="Dashboard"
                            // options={this.state.menuOptions}
                            back="/branch/monitor"
                            buttons={<this.buttons />}
                        />

                        <this.RenderFilter />


                        {this.state.toast}
                        {this.state.locationPIN}


                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-between align-items-center filter-example">
                                            <TableHeader
                                                title="All session activity"
                                                subtitle="List of all parking sessions from the most recent"
                                            />


                                                {Array.isArray(this.context.sessionListing) &&
                                                    <LoadMoreButton
                                                        action={this.context.goToNextPage}
                                                        loading={this.context.loading}
                                                        title={this.context.metaData.current_page + " of " + this.context.metaData.total_pages}
                                                        />
                                                    }

                                        </div>

                                        <this.RenderSessions />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>
        );

    }
}

export default ListBranchSessions
