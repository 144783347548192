import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewStickerType from '../../../Components/Stickers/PopNewStickerType'
import PopEditStickerType from '../../../Components/Stickers/PopEditStickerType'
import PopConfigureSticker from '../../../Components/Stickers/PopConfigureSticker'
import ajaxConfig from '../../../utils/remote/ajaxConfig'
import TableHeader from '../../../Components/Common/TableHeader'

class ConfigStickerTypes extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewRecord: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.listStickerTypes();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: false
            }, () => {
                this.setState({
                    transactionList: server_response.details.content
                })
            })

        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewRecord: false
        }, () => {
            this.setState({
                mdlNewRecord: <PopNewStickerType
                    onConfirm={this.onConfirmNewRecord} />
            }, () => {
                functions.openPopup("modal_new_sticker_type");
            })
        })
    }



    onConfirmEditRecord = async (id, type_name, price, duration, decrement, decrementLimit, stickerClassId) => {
        const server_response = await ajax.updateStickerType(id, type_name, price, duration, decrement, decrementLimit, stickerClassId);

        if (server_response.status === "OK") {
            functions.closePopup("modal_edit_sticker_type");
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            functions.closePopup("modal_edit_sticker_type");
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }


    onClickEdit = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlNewRecord: false
            }, () => {
                this.setState({
                    mdlNewRecord: <PopEditStickerType item={this.state.transactionRecord}
                        onConfirm={this.onConfirmEditRecord} />
                }, () => {
                    functions.openPopup("modal_edit_sticker_type");
                })
            })
        }else{
            this.setState({
                toast: <Toast type={2} message={"Select record and try again"} />
            })
        }
    }


    onConfirmNewRecord = async (type_name, price, duration, decrement, decrementLimit, stickerClassId) => {
        const server_response = await ajax.createStickerType(type_name, price, duration, decrement, decrementLimit, stickerClassId);

        if (server_response.status === "OK") {
            functions.closePopup("modal_new_sticker_type");
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getTransactions();
            })
        } else {
            functions.closePopup("modal_new_sticker_type");
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }

    onClickDeactivate = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Deactivate Sticker Type?"
                        description="Sticker type will nolonger be available for sale, are you sure?"
                        onConfirm={() => this.onConfirmDeactivate()} />
                }, () => {
                    functions.openPopup("modal_delete");
                })
            })


        }
    }


    onClickActivate = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        icon="fas fa-question"
                        title="Activate Sticker Type?"
                        buttonLabel="Confirm Activation"
                        description="Sticker type will now be available for sale, are you sure?"
                        onConfirm={() => this.onConfirmActivate()} />
                }, () => {
                    functions.openPopup("modal_delete");
                })
            })


        }
    }

    onConfirmDeactivate = async () => {

        if (this.state.transactionId) {
            functions.closePopup("modal_delete");

            const server_response = await ajax.deactivateStickerType(this.state.transactionId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }



    onConfirmActivate = async () => {

        if (this.state.transactionId) {
            functions.closePopup("modal_delete");

            const server_response = await ajax.activateStickerType(this.state.transactionId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }


    onConfirmConfigure =async(sticker_type_id, vehicle_types)=>{
       if(sticker_type_id>0 && vehicle_types.length>0)
       {
        const server_response = await ajaxConfig.configureStickerVehicleTypes(sticker_type_id, vehicle_types);
            
        functions.closePopup("modal_configure_sticker_type")
        
        if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }
       }else{
        functions.closePopup("modal_configure_sticker_type")
        this.setState({
            toast: <Toast type={2} message={"No vehicle type data selected"} />
        })
       }
        
    }


    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Sticker Type
                </button>

            </>
        )
    }

    customHeaderOptions = () => {
        const item=this.state.transactionRecord;
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Sticker Type
                </a>
                <a className="dropdown-item" onClick={this.onClickEdit}>
                    <i className="fas fa-pencil-alt mr-2"></i>Edit Sticker Type
                </a>
                {item &&
                    <>
                    {item.active=="1" && <>
                    <a className="dropdown-item" onClick={this.onClickDeactivate}>
                    <i className="fas fa-toggle-off text-danger mr-2"></i>Deactivate Sticker Type
                    </a>
                    </>}

                    {item.active=="0" && <>
                    <a className="dropdown-item" onClick={this.onClickActivate}>
                    <i className="fas fa-toggle-on text-success mr-2"></i>Activate Sticker Type
                    </a>
                    </>}

                    <a className="dropdown-item" onClick={this.onClickConfigure}>
                    <i className="fas fa-cog mr-2"></i>Configure Sticker
                    </a>

                    </>
                }

            </>
        )
    }


    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }





    onClickConfigure = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlNewRecord: false
            }, () => {
                this.setState({
                    mdlNewRecord: <PopConfigureSticker item={this.state.transactionRecord}
                        onConfirm={this.onConfirmConfigure} />
                }, () => {
                    functions.openPopup("modal_configure_sticker_type");
                })
            })
        }else{
            this.setState({
                toast: <Toast type={2} message={"Select record and try again"} />
            })
        }
    }



    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlNewRecord}
                {this.state.mdlDelete}
                {this.state.toast}


                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Stickers"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                       


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <div className="d-flex justify-content-between align-items-center filter-example">
                                                    <TableHeader
                                                        title="Sticker Types"
                                                        subtitle="Register your valid vehicle sticker types corresponding to each car type."
                                                    />
                                                </div>

                                                <div className="table-responsive allView-config border-top">
                                                    <table className="table text-nowrap mb-0 table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Sticker Type</th>
                                                                <th>Vehicle Types</th>
                                                                <th>Init Price</th>
                                                                <th>Cur. Price</th>
                                                                <th>Init Duration</th>
                                                                <th>Cur. Duration</th>
                                                                <th>Sticker Class</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transactionList && transactionList !== "404" &&
                                                                transactionList.map((item, key) =>
                                                                    <tr
                                                                        id={"br_" + item.holiday_id}
                                                                        key={key}
                                                                        className={`${this.state.transactionId == item.sticker_type_id ? 'flag-row' : ''}`}
                                                                        onClick={() => this.onSelectTransaction(item.sticker_type_id, item)}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{item.ref_name}</td>
                                                                        <td>
                                                                            <ul>
                                                                            {Array.isArray(item.vehicle_type) && item.vehicle_type.map((objType, type_key)=>
                                                                                <li key={type_key}>{objType.type_name}</li>
                                                                            )}
                                                                            </ul>
                                                                        </td>
                                                                        <td>{item.initial_price.amount_p}</td>
                                                                        <td>{item.amount.amount_p}</td>
                                                                        <td>{item.duration_c}</td>
                                                                        <td>{item.days_c}</td>
                                                                        <td>{item.sticker_class.class_name}</td>
                                                                        <td>
                                                                            {item.active=="1"? <span className="btn ripple btn-success btn-sm">ACTIVE</span> : <span className="btn ripple btn-danger btn-sm">INACTIVE</span>}
                                                                        </td>
                                                                        {/* <td className="text-center">
                                                                                {item.status_name === 'ACTIVE' &&
                                                                                    <span className="btn ripple btn-success btn-sm">{item.status_name}</span>
                                                                                }

                                                                                {item.status_name === 'INACTIVE' &&
                                                                                    <
                                                                                }
                                                                            </td> */}
                                                                    </tr>
                                                                )
                                                            }

                                                        </tbody>

                                                    </table>
                                                    {!transactionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigStickerTypes
