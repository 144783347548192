import { useContext } from "react";
import ParkingViolationContext from "../../Context/Violation/ParkingViolationContext";
import functions from "../../utils/functions";
import FilterClampTools from "../../Pages/Config/ClampTools/FilterClampTool";
import CountButton from "../Filters/CountButton";

const ToolStats = props =>{

    const {toolStat} = useContext(ParkingViolationContext);

    return (
        <div className="">
        {functions.isObject(toolStat) &&
            <div className="btn-list">

                <CountButton btnColor="btn-primary" title="Total Tools" count={toolStat.total_clamp_tools.total_p}/>
                <CountButton btnColor="btn-success" title="Available" count={toolStat.unused_clamp_tools.total_p}/>
                <CountButton btnColor="btn-secondary" title="In Use" count={toolStat.used_clamp_tools.total_p}/>


            </div>
        }
    </div>
    )

}


export default ToolStats;