import React from 'react';
class BoolInput extends React.Component {
  

  render(){

    const inputType=this.props.type || "text"
    
      return (

        <div className="row row-xs align-items-center mg-b-20">
            <div className="col-md-3 text-left">
                <label className="mg-b-0">{this.props.label}</label>
            </div>
            <div className="col-md-9 mg-t-5 mg-md-t-0">

                <div
                    className="onoffswitch2"
                    onClick={this.props.onClick()}
                >
                    <input
                        type="radio"
                        className="onoffswitch2-checkbox "
                        checked={this.props.value}
                    />
                    <label htmlFor="myonoffswitch" className="onoffswitch2-label"></label>
                </div>

        
            </div>
        </div>
      );
    
 
  }
}

export default BoolInput;