import React, { useEffect, useState } from "react"

const FilterTextInput=(props)=>{

    const [value, setValue] = useState(()=>{
		if(!props.currentValues){
			return "";
		}
		for(var i=0; i<props.currentValues.length; i++){
			if(props.currentValues[i][props.name]){
				return props.currentValues[i][props.name]
			}
		}

		return ""
	});
	
	useEffect(()=>{
		props.onChange(props.name, value)
	}, [value])

    return(
        <div className={"col-md-3"}>
				<div className="form-group mb-lg-0">
					<label className="">{props.label}</label>
					<input
						className="form-control"
						placeholder={props.placeholder}
						type={props.type || "text"}
						value={value}
						name={props.name}
						onChange={(e)=>setValue(e.target.value)} />
				</div>
				</div>
    )

}

export default FilterTextInput