import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ajaxPayment from '../../utils/remote/ajaxPayment';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import AlertWarning from '../Common/AlertWarning';
import BoolInput from '../Common/BoolInput';
import TextInput from '../Common/TextInput';

class PopEditPayment extends React.Component {

    state = {
       plateNumber:"",
       paymentId:this.props.paymentId*1,
       info:"",
       loading:false
    }

    onChangePlateNumber = (event) => {
        this.setState({ plateNumber: event.target.value });
    }

  
    onConfirm = async(event) => {
        event.preventDefault();
        const{plateNumber, paymentId}=this.state;
        
        if (plateNumber.length>0 && paymentId>0) {
            this.setState({
                loading: true
            })

            const server_response = await ajaxPayment.correctPayment(paymentId, plateNumber);
            this.setState({
                loading: false
            })
            if (server_response.status === "OK") {

                this.setState({info: <AlertSuccess message={server_response.details.message} />})
                this.props.refresh()
                setTimeout(()=>{
                    functions.closePopup("modal_edit_payment")
                }, 2000)
            } else {
                this.setState({info: <AlertError message={server_response.details.message} />})
            }
    

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }



    render() {
        return (
            <div className="modal" id="modal_edit_payment">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Correct Payment</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <TextInput
                                    label="Plate Number"
                                    placeholder="Enter correct plate number"
                                    type="text"
                                    onChange={() => this.onChangePlateNumber}
                                    value={this.state.plateNumber}
                                />

                                

                            </form>

                            <span><b>Note:</b> The original vehicle invoice will be restored and the amount deposited on another vehicle.</span>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Correct Payment</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopEditPayment;
