import React from 'react';
import './assets/css/App.css';
import './assets/css/monitor.css';

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import Login from './Pages/auth/Login';
import Dashboard from './Pages/Operations/Dashboard';
import PasswordReset from './Pages/auth/ResetPassword';
import functions from './utils/functions';
// import Users from './Pages/Users';
import Users from './Pages/Users/Users';
import SystemSettings from './Pages/Config/SystemSettings';
import ConfigBranch from './Pages/Config/Branch/ConfigBranch';
import CreateBranch from './Pages/Config/Branch/CreateBranch';
import UpdateBranch from './Pages/Config/Branch/UpdateBranch';
import ConfigStreet from './Pages/Config/Street/ConfigStreet';
import CreateStreet from './Pages/Config/Street/CreateStreet';
import UpdateStreet from './Pages/Config/Street/UpdateStreet';
import ConfigLocation from './Pages/Config/Location/ConfigLocation';
import CreateLocation from './Pages/Config/Location/CreateLocation';
import UpdateLocation from './Pages/Config/Location/UpdateLocation';
import ConfigDevice from './Pages/Config/Device/ConfigDevice';
import CreateDevice from './Pages/Config/Device/CreateDevice';
import DeviceInfo from './Pages/Config/Device/DeviceInfo';
import NewSession from './Pages/Operations/NewSession';
import Finance from './Pages/Finance/Finance';
import ListSession from './Pages/Operations/ListSession';
import ListInvoices from './Pages/Finance/ListInvoices';

import ConfigClampTool from './Pages/Config/ClampTools/ConfigClampTool';
import CreateClampTool from './Pages/Config/ClampTools/CreateClampTool';
import UpdateClampTool from './Pages/Config/ClampTools/UpdateClampTool';
import WrongParking from './Pages/Contravention/WrongParking';

import CreateUser from './Pages/Config/users/CreateUser';
import ListUsers from './Pages/Users/ListUsers';
import CreateRole from './Pages/Config/roles/CreateRole';
import UpdateRole from './Pages/Config/roles/UpdateRole';
import UpdateUser from './Pages/Config/users/UpdateUser';
import ConfigPermissions from './Pages/Config/Permissions/ConfigPermissions';
import ParkingMap from './Pages/ParkingMap';
import Profile from './Pages/Users/Profile';
import UserProfile from './Pages/Users/UserProfile';
import PopSystemSearch from './Components/Common/PopSystemSearch';
import VehicleProfile from './Pages/Vehicle/VehicleProfile';
import ConfigRoles from './Pages/Config/roles/ConfigRoles';
import PaymentDue from './Pages/Contravention/PaymentDue';
import ConfigClampThreshold from './Pages/Config/ClampThreshold/ConfigClampThreshold';
import ConfigPublicHolidays from './Pages/Config/PublicHolidays/ConfigPublicHolidays';
import ConfigSurcharge from './Pages/Config/Surcharge/ConfigSurcharge';
import ConfigWrongParking from './Pages/Config/WrongParking/ConfigWrongParking';
import ConfigWorkDay from './Pages/Config/WorkDay/ConfigWorkDay';
import ConfigWorkHour from './Pages/Config/WorkDay/ConfigWorkHour';

import UserLocationMapping from './Pages/Operations/UserLocationMapping';
import DeviceDistribution from './Pages/Operations/DeviceDistribution';
import StickerRecords from './Pages/Finance/StickerRecords';
import TicketRecords from './Pages/Finance/TicketRecords';
import NewTicket from './Pages/Finance/NewTicket';
import Reservation from './Pages/Reservation/Reservation';
import ParkingSpace from './Pages/Reservation/ParkingSpace';

import ConfigAppNotifications from './Pages/Config/Notifications/ConfigAppNotifications';
import XReport from './Pages/Reports/XReport';
import PaymentRecords from './Pages/Finance/PaymentRecords';
import GeneralVehicleStatement from './Pages/Finance/GeneralVehicleStatement';
import WhiteListing from './Pages/WhiteList/WhiteListing';
import ListBalances from './Pages/Finance/ListBalances';
import ConfigPaymentMode from './Pages/Config/PaymentMode/ConfigPaymentMode';
import PaymentCheque from './Pages/Finance/PaymentCheque';
import ChequeRecord from './Pages/Finance/ChequeRecord';


import ConfigTowingStations from './Pages/Config/TowingStations/ConfigTowingStations';
import CreateTowingStation from './Pages/Config/TowingStations/CreateTowingStation';
import UpdateTowingStation from './Pages/Config/TowingStations/UpdateTowingStation';
import RTJSPayment from './Pages/Finance/RTJSPayment';
import RTJSRecord from './Pages/Finance/RTJSRecord';
import ListVehicles from './Pages/Vehicle/ListVehicles';
import NewReservationPayment from './Pages/Finance/NewReservationPayment';
import ReservationPaymentRecords from './Pages/Reservation/ReservationPaymentRecords';
import SpaceInventory from './Pages/Reports/SpaceInventory';
import ParkingEnforcements from './Pages/Reports/ParkingEnforcements.js';
import PaymentsTracking from './Pages/Reports/PaymentsTracking';

import VendorTicketRecords from './Pages/Finance/VendorTicketRecords';
import SessionInSingleLocation from './Pages/Operations/SessionsInSingleLocation';
import StreetOccupancy from './Pages/Reservation/StreetOccupancy';
import CashierDashboard from './Pages/Finance/Cashier/CashierDashboard';
import ConfigStickerTypes from './Pages/Config/Stickers/ConfigStickerTypes';
import ConfigVehicleTypes from './Pages/Config/VehicleTypes/ConfigVehicleTypes';
import WelcomePage from './Pages/WelcomePage';
import RequestedPermission from './Pages/Users/RequestedPermissions';
import StickerChequeRecord from './Pages/Finance/StickerChequeRecord';
import ListResellers from './Pages/Reseller/ListResellers';
import ResellerStatement from './Pages/Reseller/ResellerStatement';
import BankPaymentRecords from './Pages/Finance/BankDeposit/BankPaymentRecords';
import ConfigBankingClient from './Pages/Config/BankingClient/ConfigBankingClient';
import AssignPermsToRole from './Pages/Config/roles/AssignPermsToRole';
import SalesPerCashier from './Pages/Finance/Cashier/SalesPerCashier';
import OperationMonitorPage from './Pages/Operations/OperationMonitorPage';

import ProjectDocument from './Pages/Documentation/ProjectDocument';
import APIDocument from './Pages/Documentation/ApiDocument';
import SuperProvider from './Context/SuperProvider';
import { AuthConsumer } from './Context/AuthContext';
import LoadingPage from './Pages/auth/LoadingPage';
import ManageRoleAccess from './Pages/Config/roles/ManageRoleAccess';
import { RenderSecure } from './utils/script/RenderSecure';
import ReservationCashier from './Pages/Finance/Cashier/ReservationCashier';
import ReserveChequeRecord from './Pages/Finance/ReserveChequeRecord';
import MigrateBalances from './Pages/Config/Migration/MigrateBalances';
import ReserveRTGSRecord from './Pages/Finance/ReserveRTGSRecord';
import Supervisor from './Pages/Operations/Supervisor/Supervisor';
import VehicleScanRecords from './Pages/Operations/Supervisor/VehicleScanRecords';

import ConfigWrongParkingDescription from './Pages/Config/WrongParking/WrongParkingDescription';
import CreateWrongParkingReason from './Pages/Config/WrongParking/CreateWrongParkingReason';
import EditWrongParkingReason from './Pages/Config/WrongParking/EditWrongParkingReason';
import ConfigClampViolationReasons from './Pages/Config/ClampAttemptReasons/ConfigClampViolationReasons';
import ConfigClampAbortReasons from './Pages/Config/ClampAttemptReasons/ConfigClampAbortReasons';
import ContraventionsDashboard from './Pages/Contravention/Contraventions';
import UserPerformanceReport from './Pages/Reports/UserFieldPerformance/UserPerformanceReport';
import ConductorPerformanceReport from './Pages/Reports/UserFieldPerformance/ZulusPerformance';
import ZulusPerformanceReport from './Pages/Reports/UserFieldPerformance/ZulusPerformance';
import ConfigClampingFee from './Pages/Config/ClampingFee/ConfigClampingFee';
import ConfigTowingFee from './Pages/Config/TowingFee/ConfigTowingFee';
import PaperTicketDashboard from './Pages/Finance/PaperTicketDashboard';
import MobileMoneyDashboard from './Pages/Finance/MobileMoneyDashboard';
import BranchMonitor from './Pages/Operations/BranchMonitor';
import ConfigPlexApps from './Pages/Config/PlexApps/ConfigPlexApps';
import ImportSession from './Pages/Operations/ImportSession';
import PaperTicketAgentCollection from './Pages/Finance/PaperTicketAgentCollection';
import ImportPayments from './Pages/Operations/ImportPayments';
import ListBranchSessions from './Pages/Operations/ListBranchSessions';
import VIPListing from './Pages/VIP/VIPListing';
import AnalysisByBranch from './Pages/Reports/EnforcementAnalysis/AnalysisByBranch';
import ContraventionPerformance from './Pages/Reports/EnforcementAnalysis/ContraventionPerformance';
import ParkingDemandSupply from './Pages/Reports/ParkingDemand&Supply';
import UserFieldReport from './Pages/Reports/UserFieldPerformance/UserFieldReport';
import ParkingEnforcementPage from './Pages/Operations/Violation/ParkingEnforcementPage';
import NonPaymentPage from './Pages/Operations/Violation/NonPaymentPage';
import TowingPage from './Pages/Operations/Violation/TowingPage';
import SupervisorFieldReport from './Pages/Operations/Supervisor/SupervisorFieldReport';
import EfrisDashboard from './Pages/Finance/Efris/EfrisDashboard';
import EfrisConfiguration from './Pages/Finance/Efris/EfrisConfiguration.js';
import DemandNote from './Pages/Finance/DemandNote.js';






class App extends React.Component {

  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.permissionGuard(),

    notification: {
      title: '',
      body: ''
    },
    showToast: false,
    isTokenFound: false,
    getFcmToken: '',
  }

  componentDidMount() {
    this.checkLogin();
    this.getToken();

    this.timerBalance = setTimeout(this.funcNotification = async () => {
      await this.messageTrigger();
      this.timerBalance = setTimeout(this.funcNotification, 1000); // (*)
    }, 1000);
  }

  getToken() {
    // fetchToken(this.state.isTokenFound, this.state.getFcmToken);
  }

  messageTrigger() {
    // onMessageListener().then(payload => {
    //   this.setState({
    //     showToast: false,

    //     notification: {
    //       title: payload.notification.title,
    //       body: payload.notification.body
    //     },
    //     // showToast: true,
    //     // showToast: <Toast
    //     //   message={payload.notification.body}
    //     //   title={payload.notification.title}
    //     //   type={6}
    //     //   // onClose={this.resetToast}
    //     // />

    //   }, () => {
    //     this.setState({
    //       showToast: <Toast
    //         message={payload.notification.body}
    //         title={payload.notification.title}
    //         type={6}
    //       // onClose={this.resetToast}
    //       />
    //     })
    //   });
    //   console.log(payload);
    // }).catch(err => console.log('failed: ', err));
  }

  resetToast = () => {
    this.setState({
      showToast: false
    })
  }

  checkLogin() {
    if (!localStorage.getItem("parking@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }



  dashboards = () => {

    return (
      <AuthConsumer>
        {props => {
          const accessInfo = props.permissionList

          if (functions.findInPermission(accessInfo, "OPERATIONS-00")) {
            return (
              <Dashboard />
            )
            // } else if (roleList === "CASH") {
          } else if (functions.findInPermission(accessInfo, "OPERATIONS-00")) {
            return (
              <ContraventionsDashboard />
            )
            // } else if (roleList === "CASH") {
          } else if (functions.findInPermission(accessInfo, "CASHIER-00")) {
            return (
              <CashierDashboard />
            )
          } else if (functions.findInPermission(accessInfo, "FINANCE-00")) {
            return (
              <Finance />
            )
          } else if (functions.findInPermission(accessInfo, "R-MOMO-TRANS")) {
            return <MobileMoneyDashboard />
          }
          else if (functions.findInPermission(accessInfo, "USERS-00")) {
            return (
              <Users />
            )
          }
          else if (functions.findInPermission(accessInfo, "CONFIG-00")) {
            return (
              <SystemSettings />
            )
          } else if (functions.findInPermission(accessInfo, "R-BRANCH-MONITOR-00")) {
            return (
              <BranchMonitor />
            )
          }


          else {
            return (
              <WelcomePage />
            )
          }

        }}

      </AuthConsumer>

    )
  }

  render() {


    const roleList = functions.roleGuard()
    const USER_BOUNDARY = functions.userBoundary();
    // console.log(roleList)

    return (
      <SuperProvider>
        {this.state.showToast}
        <AuthConsumer>
          {props => {
            const permissionList = props.permissionList
            if (!permissionList && this.state.loggedIn) {
              return (
                <LoadingPage />
              )
            } else {
              return (
                <div id="app_container" onClick={functions.closeHeaderDropdowns}>
                  <Router forceRefresh={false}>
                    {!this.state.loggedIn && <Redirect to="/login" />}
                    <PopSystemSearch />
                    <Switch>

                      {!this.state.loggedIn && <Route exact path="/" component={Login} />}

                      {USER_BOUNDARY == "VENDOR" &&
                        <>
                          <Route exact path="/" component={NewTicket} />
                          <Route exact path="/ticket-sale-records" component={VendorTicketRecords} />
                          <Route exact path="/new-ticket" component={NewTicket} />
                          <Route exact path="/password-reset" component={PasswordReset} />
                        </>
                      }



                      {USER_BOUNDARY !== "VENDOR" &&
                        <>

                          {permissionList && <Route exact path="/" component={this.dashboards} />}
                          <Route exact path="/login" component={Login} />

                          <Route exact path="/password-reset" component={PasswordReset} />
                          <Route exact path="/unauthorized" component={WelcomePage} />
                          <RenderSecure code="OPERATIONS-00">
                            <Route exact path="/operations" component={Dashboard} />
                            <Route exact path="/contraventions" component={ContraventionsDashboard} />
                            <Route exact path="/operations/supervision" component={Supervisor} />
                            <Route exact path="/operations/supervision/transactions" component={VehicleScanRecords} />
                            <Route exact path="/operations/supervision/report" component={SupervisorFieldReport} />
                          </RenderSecure>
                          <RenderSecure code="R-BRANCH-MONITOR-00">
                            <Route exact path="/branch/monitor" component={BranchMonitor} />
                            <Route exact path="/parking_sessions/branch" component={ListBranchSessions} />
                            <Route exact path="/finance/paper-tickets-summary" component={PaperTicketAgentCollection} />

                          </RenderSecure>

                          <RenderSecure code="R-MAP-02">
                            <Route exact path="/parkingmap" component={ParkingMap} />
                          </RenderSecure>
                          <RenderSecure code="USERS-00">
                            <Route exact path="/users" component={Users} />
                          </RenderSecure>
                          <RenderSecure code="USERS-00">
                            <Route exact path="/users/all-users" component={ListUsers} />
                            <Route exact path="/users/profile" component={Profile} />
                          </RenderSecure>
                          <RenderSecure code="C-SESSION-01">
                            <Route exact path="/operations/sessions/new" component={NewSession} />
                          </RenderSecure>

                          <RenderSecure code="R-SESSION-IMPORT-01">
                            <Route exact path="/parking_sessions/import" component={ImportSession} />
                          </RenderSecure>



                          <RenderSecure code='FINANCE-00'>
                            <Route exact path="/finance" component={Finance} />
                            <Route exact path="/finance/bankdeposit/list" component={BankPaymentRecords} />
                            <Route exact path="/finance/efris" component={EfrisDashboard} />
                            <Route exact path="/efris/config" component={EfrisConfiguration} />

                          </RenderSecure>

                          <RenderSecure code="R-CASHIER-MGT">
                            <Route exact path="/finance/cashier/sales" component={SalesPerCashier} />
                          </RenderSecure>


                          <RenderSecure code="R-SESSION-02">
                            <Route exact path="/parking_sessions/sessions" component={ListSession} />
                          </RenderSecure>

                          <RenderSecure code="R-INVOICE-02">
                            <Route exact path="/finance/invoices" component={ListInvoices} />
                            <Route exact path="/finance/paper-tickets" component={PaperTicketDashboard} />
                            <Route exact path="/finance/demand-note" component={DemandNote} />

                          </RenderSecure>


                          <RenderSecure code='R-CLAMP-02'>
                            {/* <Route exact path="/enforcement/clamp_transactions" component={ClampTransactions} /> */}
                            <Route exact path="/enforcement/clamp_transactions" component={ParkingEnforcementPage} />

                            <Route exact path="/enforcement/transactions" component={ParkingEnforcementPage} />
                            <Route exact path="/enforcement/nonpayments" component={NonPaymentPage} />


                          </RenderSecure>
                          <RenderSecure code='R-WPARK-02'>
                            <Route exact path="/contraventions/wrong_parking" component={WrongParking} />
                          </RenderSecure>
                          <RenderSecure code='R-DUE-PAYMENT-02'>
                            <Route exact path="/contraventions/payment_due" component={PaymentDue} />
                          </RenderSecure>
                          <RenderSecure code='R-VEHICLE-PROFILE-02'>
                            <Route exact path="/vehicles/profile/:vehicle" component={VehicleProfile} />
                          </RenderSecure>

                          <RenderSecure code="R-TOW-02">
                            <Route exact path="/enforcement/tow_transactions" component={TowingPage} />
                          </RenderSecure>

                          <RenderSecure code="R-LOCATION-DISTRIB-02">
                            <Route exact path="/operations/user-location-mapping" component={UserLocationMapping} />
                          </RenderSecure>

                          <RenderSecure code="R-DEVICE-DISTRIB-02">
                            <Route exact path="/operations/device-distribution" component={DeviceDistribution} />
                          </RenderSecure>
                          <RenderSecure code="R-STICKER-02">
                            <Route exact path="/finance/sticker-records" component={StickerRecords} />
                          </RenderSecure>
                          <RenderSecure code="R-TICKET-02">
                            <Route exact path="/finance/ticket-records" component={TicketRecords} />
                          </RenderSecure>

                          <RenderSecure code='R-RESERVATION-02'>
                            <Route exact path="/parking-space/reservation" component={Reservation} />
                          </RenderSecure>

                          <RenderSecure code='R-AVAIL-PARKING-02'>
                            <Route exact path="/parking-space/available-space" component={ParkingSpace} />
                          </RenderSecure>
                          <RenderSecure code="R-X-REPORTS-02">
                            <Route exact path="/reports/xreport" component={XReport} />
                          </RenderSecure>
                          <RenderSecure code="R-PAYMENT-REPORTS-02">
                            <Route exact path="/finance/payments" component={PaymentRecords} />
                            <Route exact path="/finance/general-invoice-payment-statement" component={GeneralVehicleStatement} />
                            <Route exact path="/finance/mobile-money" component={MobileMoneyDashboard} />

                          </RenderSecure>
                          <RenderSecure code="R-WHITELIST-02">
                            <Route exact path="/whitelisting" component={WhiteListing} />
                          </RenderSecure>
                          <RenderSecure code="R-VEHICLE-BALANCES">
                            <Route exact path="/finance/invoices/balances" component={ListBalances} />
                          </RenderSecure>
                          <RenderSecure code="R-PAY-CHEQUE-02">
                            <Route exact path="/finance/payment_cheques" component={PaymentCheque} />
                            <Route exact path="/finance/cheques/sticker" component={StickerChequeRecord} />
                            <Route exact path="/finance/cheques/reserve" component={ReserveChequeRecord} />
                          </RenderSecure>
                          <RenderSecure code="U-PAY-CHEQUE-03">
                            <Route exact path="/finance/payment_cheques/manage" component={ChequeRecord} />
                          </RenderSecure>
                          <RenderSecure code="R-PAY-RTGs-02">
                            <Route exact path="/finance/rtjs_payment" component={RTJSPayment} />
                          </RenderSecure>
                          <RenderSecure code="U-PAY-RTGs-03">
                            <Route exact path="/finance/rtjs_payment/manage" component={RTJSRecord} />
                            <Route exact path="/finance/rtgs/reserve" component={ReserveRTGSRecord} />

                          </RenderSecure>
                          <RenderSecure code="C-VEHICLES-01">
                            <Route exact path="/vehicles/all-vehicles" component={ListVehicles} />
                          </RenderSecure>

                          <RenderSecure code="R-BATCH-PAYMENT-01">
                            <Route exact path="/payment/batch" component={ImportPayments} />
                          </RenderSecure>

                          <RenderSecure code="CASHIER-00">
                            <Route exact path="/cashier" component={CashierDashboard} />
                            <Route exact path="/cashier/reservation" component={ReservationCashier} />
                            <Route exact path="/reservation/pay_invoice/:ref" component={NewReservationPayment} />
                            <Route exact path="/reservation/payment_records" component={ReservationPaymentRecords} />
                          </RenderSecure>

                          {/* REPORT ROUTES */}
                          <RenderSecure code="R-OCCUPANCY-REPORTS-02">
                            <Route exact path="/reports/demand_supply/occupancy" component={ParkingDemandSupply} />
                          </RenderSecure>
                          <RenderSecure code="R-INVENTORY-REPORTS-02">
                            <Route exact path="/reports/demand_supply/space_inventory" component={SpaceInventory} />
                          </RenderSecure>
                          <RenderSecure code="R-PAYMENT-REPORTS-02">
                            <Route exact path="/reports/payments_tracking" component={PaymentsTracking} />
                          </RenderSecure>

                          <RenderSecure code="R-REPORTS-02">
                            <Route exact path="/sessions/in/:location" component={SessionInSingleLocation} />
                            <Route exact path="/available-space/street-occupancy/:street_name" component={StreetOccupancy} />
                            <Route exact path="/reports/enforcements/contravention_type" component={ParkingEnforcements} />
                          </RenderSecure>

                          <RenderSecure code="R-USER-PERFORMANCE-REPORTS-02">
                            <Route exact path="/reports/:user/field-performance" component={UserPerformanceReport} />
                          </RenderSecure>

                          <RenderSecure code="R-USER-PERFORMANCE-REPORTS-02">
                            <Route exact path="/reports/conductor-performance" component={ConductorPerformanceReport} />
                          </RenderSecure>

                          <RenderSecure code="R-USER-PERFORMANCE-REPORTS-02">
                            <Route exact path="/reports/user-performance" component={UserFieldReport} />
                          </RenderSecure>

                          <RenderSecure code="R-USER-PERFORMANCE-REPORTS-02">
                            <Route exact path="/reports/zulu-performance" component={ZulusPerformanceReport} />
                          </RenderSecure>

                          <RenderSecure code="R-ENFORCEMENT-ANALYSIS">
                            <Route exact path="/reports/enforcement/analysis" component={AnalysisByBranch} />
                          </RenderSecure>


                          <RenderSecure code="R-ENFORCEMENT-ANALYSIS">
                            <Route exact path="/reports/contravention/analysis" component={ContraventionPerformance} />
                          </RenderSecure>



                          {/* END REPORTS ROUTES */}

                          <RenderSecure code="R-VENDOR-LIST">
                            <Route exact path="/vendor/list" component={ListResellers} />
                          </RenderSecure>
                          <RenderSecure code="R-VENDOR-STATEMENT">
                            <Route exact path="/vendor/statement/:username" component={ResellerStatement} />
                          </RenderSecure>

                          <RenderSecure code="FIELD-ACTIVITY-00">
                            <Route exact path="/monitor" component={OperationMonitorPage} />
                          </RenderSecure>

                          {/* CONFIGURATION ROUTES */}
                          <RenderSecure code='CONFIG-00'>
                            <Route exact path="/config/car-types" component={ConfigVehicleTypes} />
                            <Route exact path="/config/sticker-types" component={ConfigStickerTypes} />
                            <Route exact path="/config/towing-stations" component={ConfigTowingStations} />
                            <Route exact path="/config/towing-station/new" component={CreateTowingStation} />
                            <Route exact path="/config/towing-station/edit" component={UpdateTowingStation} />
                            <Route exact path="/config/notifications" component={ConfigAppNotifications} />
                            <Route exact path="/config/payment_modes" component={ConfigPaymentMode} />
                            <Route exact path="/config/clamping/thresholdfee" component={ConfigClampThreshold} />
                            <Route exact path="/config/holiday" component={ConfigPublicHolidays} />
                            <Route exact path="/config/surcharge" component={ConfigSurcharge} />
                            <Route exact path="/config/wrongparking" component={ConfigWrongParking} />
                            <Route exact path="/config/workdays" component={ConfigWorkDay} />
                            <Route exact path="/config/workinghours" component={ConfigWorkHour} />
                            <Route exact path="/config/clamp_tools" component={ConfigClampTool} />
                            <Route exact path="/config/clamptools/new" component={CreateClampTool} />
                            <Route exact path="/config/clamptools/edit" component={UpdateClampTool} />
                            <Route exact path="/config/roles" component={ConfigRoles} />
                            <Route exact path="/config/roles/new" component={CreateRole} />
                            <Route exact path="/config/roles/edit" component={UpdateRole} />
                            <Route exact path="/config/roles/assigns" component={AssignPermsToRole} />
                            <Route exact path="/config/users/new" component={CreateUser} />
                            <Route exact path="/config/users/edit" component={UpdateUser} />
                            <Route exact path="/config" component={SystemSettings} />
                            <Route exact path="/config/permissions" component={ConfigPermissions} />
                            <Route exact path="/config/permissions/requests" component={RequestedPermission} />
                            <Route exact path="/config/permissions/new" component={CreateBranch} />
                            <Route exact path="/config/permissions/edit" component={UpdateBranch} />
                            <Route exact path="/config/branches" component={ConfigBranch} />
                            <Route exact path="/config/branches/new" component={CreateBranch} />
                            <Route exact path="/config/branches/edit" component={UpdateBranch} />
                            <Route exact path="/config/streets" component={ConfigStreet} />
                            <Route exact path="/config/streets/new" component={CreateStreet} />
                            <Route exact path="/config/streets/edit" component={UpdateStreet} />
                            <Route exact path="/config/locations" component={ConfigLocation} />
                            <Route exact path="/config/locations/new" component={CreateLocation} />
                            <Route exact path="/config/locations/edit" component={UpdateLocation} />
                            <Route exact path="/config/devices" component={ConfigDevice} />
                            <Route exact path="/config/devices/new" component={CreateDevice} />
                            <Route exact path="/config/devices/deviceinfo" component={DeviceInfo} />
                            <Route exact path="/config/bankingclient" component={ConfigBankingClient} />
                            <Route exact path="/users/profile/:user" component={UserProfile} />
                            <Route exact path="/user/access" component={AssignPermsToRole} />
                            <Route exact path="/config/roles/permissions/:role_name" component={ManageRoleAccess} />
                            <Route exact path="/config/migration" component={MigrateBalances} />

                            <Route exact path="/config/w.p-description" component={ConfigWrongParkingDescription} />
                            <Route exact path="/config/create/wrongparking/description" component={CreateWrongParkingReason} />
                            <Route exact path="/config/update/wrongparking/description" component={EditWrongParkingReason} />

                            <Route exact path="/config/clamp-violation-reasons" component={ConfigClampViolationReasons} />
                            <Route exact path="/config/clamp-abort-reasons" component={ConfigClampAbortReasons} />


                            <Route exact path="/config/clampingfee" component={ConfigClampingFee} />
                            <Route exact path="/config/towingfee" component={ConfigTowingFee} />

                            <Route exact path="/config/plex-apps" component={ConfigPlexApps} />
                            {/* <Route exact path="/config/streets/new" component={CreateStreet} /> */}


                          </RenderSecure>


                          {/* # DOCUMENTATION WORK */}
                          <RenderSecure code="R-DOCUMENTATION-00">
                            <Route exact path="/documentation/api-docs" component={APIDocument} />
                            <Route exact path="/documentation/project-docs" component={ProjectDocument} />
                          </RenderSecure>





                          <RenderSecure code="C-VIP-01">
                            <Route exact path="/viplisting" component={VIPListing} />
                          </RenderSecure>


                        </>
                      }

                    </Switch>
                  </Router>
                </div>

              )
            }
          }}
        </AuthConsumer>

      </SuperProvider>
    )

  }

}

export default App;

{/* <Route exact path="/finance/new-payment" component={NewPayment} /> */ }
{/* <Route exact path="/finance/new-sticker" component={NewSticker} /> */ }
{/* <Route exact path="/finance/new-ticket" component={NewTicket} /> */ }
