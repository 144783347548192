import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import TableHeader from '../../../Components/Common/TableHeader'
import FilterBar from '../../../Components/Common/FilterBar'
import { Link } from 'react-router-dom'
import PageNav from '../../../Components/Common/PageNav'
import ajaxPayment from '../../../utils/remote/ajaxPayment'
import PopCashierDailySummary from '../../../Components/Finance/Cashier/PopCashierDailySummary'
import CustomizedFilterBar from '../../../Components/Common/CustomizedFilterBar'
import CashierContext from '../../../Context/CashierContext'
import NoContent from '../../../Components/Common/NoContent'

class SalesPerCashier extends React.Component {

    static contextType = CashierContext

    state = {
        userId: functions.sessionGuard(),
        //PAGINATION
        currentPage: 1,
        transactionList: false,
        metaData: false,
        pagination: false,
        //EO PAGINATION
        filterTitle: 'List of daily sales per cashier',
        vipStatus: '',
        mdlNewPayment: false,

        filter: false,
        mdlCashierSlip:false
    }

   

   


    

    buttons = () => {
        return (
            <>
              

                <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>

                <button className="btn ripple btn-light" onClick={this.context.refresh}>
                <i className="fa fa-redo"></i> Refresh
              </button>


               
            </>
        )
    }

  

    onFilter = async (filter) => {
        this.setState({
            filter: false,
            transactionList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllRecords())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            transactionList: false
        }, () => this.listAllRecords())
    }




    onViewSlip=(cashierId)=>{
        this.setState({
            mdlCashierSlip:false
        }, ()=>{
            this.setState({
                mdlCashierSlip:<PopCashierDailySummary cashierId={cashierId}/>
            }, ()=>functions.openPopup('modal_cashier_daily_summary'))
        })
    }


    render() {

        const transactionList = this.context.salesListing
        return (
            <div className="page">
                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Cashier Sales"
                            previousPageTitle="Finance"
                            buttons={<this.buttons />}
                            back="/finance"
                        />
                        {this.state.mdlCashierSlip}

                        <CustomizedFilterBar
                            // keyword="keyword"
                            // selection={{ title: 'Is VIP', options: listOptions }} 
                            onReset={this.context.resetData}
                            onConfirm={this.context.filterData}
                        />
                       



                        <div className="row">

                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        {/* { functions.findInPermission(permissionList, 'USER-002') && */}
                                        <TableHeader
                                            title={this.state.filterTitle}
                                            subtitle="Sales corresponding to each cashier including bank and cash transactions"
                                        />
                                       
                                        {/* } */}
                                        <div className="table-responsive">
                                            <table id="users" className="table text-nowrap table-hover mg-b-0">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Role</th>
                                                        <th>Cashier Name</th>
                                                        <th>Cashier Contact</th>
                                                        <th>Username</th>
                                                        <th>Total Cash
                                                            <small className="tx-12 tx-gray-500"> UGX</small>
                                                        </th>
                                                        <th>Total Bank
                                                            <small className="tx-12 tx-gray-500"> UGX</small>
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {transactionList && transactionList !== "404" &&

                                                        transactionList.map((item, key) =>
                                                            <tr
                                                                key={key}
                                                            >
                                                                <td>{key+1}</td>
                                                                <td>{item.user_info.user_role?item.user_info.user_role.role_name:"N/A"}</td>
                                                                <td>{item.user_info.last_name + ' ' + item.user_info.first_name}</td>
                                                                <td>{item.user_info.phone_number}</td>
                                                                <td>{item.user_info.username}</td>
                                                                <td>{item.sales_info.cash_bank.cash.amount_p}</td>
                                                                <td>{item.sales_info.cash_bank.bank.amount_p}</td>
                                                                <td>
                                                                    <span className="btn ripple btn-primary btn-sm" onClick={()=>this.onViewSlip(item.user_info.user_id)}>VIEW SLIP</span>
                                                                </td>
                                                            </tr>
                                                        )}

                                                </tbody>
                                            </table>



                                            {!transactionList && <ActivityLoader />}
                                            {transactionList==="404" && <NoContent/> }

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default SalesPerCashier
