import React from 'react'
import { Link } from 'react-router-dom'
import DashRow from '../UserCards/DashRow'
import DashRow2 from '../UserCards/DashRow2'
import DashCard from './DashCard'
import DashRow3 from './DashRow3'

class ContraventionsDashCard extends React.Component {

    render() {
        return (
            <div className={this.props.className}>
                <Link to={this.props.href}>
                    <div className="card custom-card pinned-card">
                       
                        <div className="row">
                            <DashRow 
                                size="col-xl-6 col-lg-6 col-md-6 col-sm-6 border-right" 
                                title={this.props.title1}
                                // icon="fe fe-arrow-down" 
                                value={this.props.value1}
                                // percentage="5%" 
                                color={this.props.color1} 
                                caption="Today" 
                            />

                            <DashRow3 
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6"
                                title={this.props.title2}
                                icon={this.props.icon}
                                value={this.props.value2}
                                color={this.props.color2}
                            />
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

}

export default ContraventionsDashCard
