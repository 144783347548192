import React, { useContext } from "react";
import ConfigurationContext from "../../Context/ConfigurationContext";
import EnforcementContext from "../../Context/ContraventionContext/EnforcementContext";
import FilterTextInput from "../Common/Filter/FilterTextInput";
import FilterSelectInput from "../Common/Filter/FilterSelectInput";
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar";
import BranchAnalysisContext from "../../Context/Violation/BranchAnalysisContext";

const EnforcementFilter =(props)=>{


    const { branchList, streetList } = useContext(ConfigurationContext);

    const {resetData, filterData } = useContext(BranchAnalysisContext)

    const branchOptions = [];
    const streetOptions = [];


    
    Array.isArray(branchList)  && branchList.map((item, key) =>
        branchOptions.push({
            title: item.branch_name, value: item.branch_id
        })

    )

    

    
   Array.isArray(streetList) && streetList.map((item, key) =>
            streetOptions.push({
                title: item.street_name, value: item.street_id
            })

        )

     
    return( <ScrollFilterBar
                onReset={resetData}
                onConfirm={filterData}
                firstScreen={{
                    inputs:[
                        (onSet, key, currentValues)=><FilterTextInput
                        key={key}
                        label="Date From"
                        type="date"
                        onChange={onSet}
                        name="dateFrom"
                        currentValues={currentValues}
                    />,
            
                    (onSet, key, currentValues)=><FilterTextInput
                        key={key}
                        label="Date To"
                        type="date"
                        onChange={onSet}
                        name="dateTo"
                        currentValues={currentValues}
                        />,

                        (onSet, key, currentValues)=> <FilterSelectInput 
                        key={key}
                        label="Analysis By Branch"
                        options={branchOptions}
                        onChange={onSet}
                        name="branch"
                        currentValues={currentValues}
                        />,
            
                        (onSet, key, currentValues)=> <FilterSelectInput 
                        key={key}
                        label="Analysis By Street"
                        options={streetOptions}
                        onChange={onSet}
                        name="street"
                        currentValues={currentValues}
                        />,
                    ],
                    names:["dateFrom", "dateTo", "branch", "street"]
                }}
    />
    )

}


export default EnforcementFilter;