import React from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../Common/ActivityLoader';

const LoadMoreButton = (props) => {

    return (
        <ul className="pagination justify-content-end">
            {!props.loading &&
                <li className={`page-item`}>
                    <Link
                        className="page-link"
                        to="#"
                        onClick={props.action}
                    >
                        {props.title || "Load older records"} &nbsp;&nbsp;
                        <i className='fa fa-chevron-right' />
                    </Link>
                </li>
            }

            {props.loading &&
                <li className={`page-item`}>
                    <Link
                        className="page-link"
                        to="#"
                    >
                        Loading Older &nbsp;&nbsp;<ActivityLoader height={20} width={20} customClass='page-lin' />
                    </Link>
                </li>
            }

        </ul>
    )

}

export default LoadMoreButton
