import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';
import ActivityLoader from '../../../Components/Common/ActivityLoader';
import NoContent from '../../../Components/Common/NoContent';
import TableHeader from '../../../Components/Common/TableHeader';
import PageHeader from '../../../Components/Common/PageHeader';
import AnalysisByViolationType from './AnalysisByViolationType';
import EnforcementContext from '../../../Context/ContraventionContext/EnforcementContext';
import EnforcementFilter from '../../../Components/Reports/EnforcementFilter';
import ViolationTypeFilter from '../../../Components/Reports/ViolationTypeFilter';
import PageContainer from '../../../Components/Layout/PageContainer';
import BranchAnalysis from '../../../Components/Operations/Violation/Report/BranchAnalysis';
import ViolationAnalysis from '../../../Components/Operations/Violation/Report/ViolationAnalysis';
import BranchAnalysisContext from '../../../Context/Violation/BranchAnalysisContext';
import ViolationReportContext from '../../../Context/Violation/ViolationReportContext';

const AnalysisByBranch =()=> {
    const [tab, setTab] = useState(1);

    const { getStreetClampAnalysis }  = useContext(BranchAnalysisContext);
    const { getClampReasonAnalysis } = useContext(ViolationReportContext);

  
    const handleRefresh=()=>{
        if(tab==1){
            getStreetClampAnalysis();
        }else{
            getClampReasonAnalysis();
        }
    }
  
    const buttons = () => {

        return (<>
                <Link
                    to="#"
                    className="btn ripple btn-secondary navresponsive-toggler mb-3 ml-0" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                            <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                        </Link>

                  
                            <button className="btn ripple btn-light" onClick={handleRefresh}>
                                <i className="fa fa-redo mr-2"></i> Refresh
                            </button>
            </>
        )
    }


    const RenderFilter = () => {

        if (tab === 1) {
            return (
                <EnforcementFilter />
            )
        } else {
            return <ViolationTypeFilter />
        }


    }


        return (
            
            <PageContainer>
                <PageHeader
                    title="Enforcement Analysis"
                    previousPageTitle="Reports"
                    back="/operations"
                    buttons={buttons()}
                />
                {RenderFilter()}

                        <div className="">
                            <div className="card custom-card main-content-body-profile ">
                                <nav className="nav main-nav-line">
                                    <Link to='#' className={`nav-link ${tab==1 && 'active'}`} onClick={() => setTab(1)}>Analysis by branch</Link>
                                    <Link to='#' className={`nav-link ${tab==2 && 'active'}`} onClick={() => setTab(2)}>Analysis by type of contravention</Link>
                                </nav>
                                <div className="card-body tab-content h-100">

                                    <div className={`tab-pane ${tab==1 && 'active'}`}>
                                        {tab==1 && <BranchAnalysis/>}
                                    </div>

                                    <div className={`tab-pane ${tab==2 && 'active'}`}>
                                       {tab==2 && <ViolationAnalysis/>}
                                    </div>


                                </div>
                            </div>
                        </div>


        </PageContainer>

        )

    

}

export default AnalysisByBranch
