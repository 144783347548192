import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import RecentInvoices from '../../Components/RecentInvoices'
import CountMonthlyInvoices from '../../Components/Cards/FinanceCards/CountMonthlyInvoices'
import MonthlyParkingRevenue from '../../Components/Cards/FinanceCards/MonthlyParkingRevenue'
import MonthlyWrongParkingRevenue from '../../Components/Cards/FinanceCards/MonthlyWrongParkingRevenue'
import { Link } from 'react-router-dom';
import SalesGraph from '../../Components/Finance/SalesGraph'
import RecentActiveStickers from '../../Components/Finance/RecentActiveStickers'
import RecenTicketMonitor from '../../Components/Finance/RecenTicketMonitor'
import { AuthConsumer } from '../../Context/AuthContext'
import { RenderSecure } from '../../utils/script/RenderSecure'
import AnnualReservationRevenue from '../../Components/Cards/FinanceCards/AnnualReservationRevenue'

class Finance extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    menuOptions: '',
    invoiceView: false
  }

  componentDidMount() {


    this.setState({
      menuOptions: {
        "addInvoice": () => this.onClickAddInvoice(),
        "addPayment": () => this.onClickAddPayment()
      }
    })
  }

  


  onClickAddInvoice = () => {

  }

  onClickAddPayment = () => {
    this.props.history.push('/finance/new-payment')
  }



  customHeaderOptions = (props) => {


    return (
      <>
        <RenderSecure code='R-STICKER-02'>
          <Link to="/finance/sticker-records" className="dropdown-item pointer">
            <i className="fas fa-tags text-primary mr-2"></i>Sticker Sales
          </Link>
        </RenderSecure>

        <RenderSecure code='R-INVOICE-02'>
          <Link to="/finance/invoices" className="dropdown-item pointer">
            <i className="far fa-list-alt mr-2"></i>Invoice Records
          </Link>
        </RenderSecure>

        <RenderSecure code='R-TICKET-02'>
          <Link to="/finance/ticket-records" className="dropdown-item pointer">
            <i className="fas fa-barcode text-primary mr-2"></i>Virtual Ticket Sales
          </Link>
        </RenderSecure>

        <RenderSecure code='R-PAY-02'>
          <Link to="/finance/payments" className="dropdown-item pointer">
            <i className="fas fa-handshake text-success mr-2"></i>Payment Tracking
          </Link>
        </RenderSecure>
        <RenderSecure code="R-REVENUE-00">
          <Link to="/finance/general-invoice-payment-statement" className="dropdown-item pointer">
            <i className="fas fa-chart-area mr-2"></i>Invoices&Payments
          </Link>
        </RenderSecure>

         <RenderSecure code="R-PAY-CHEQUE-02">
            <Link to="/finance/payment_cheques" className="dropdown-item pointer">
            <i className="far fa-envelope-open mr-2"></i>Payment Cheques
          </Link>
         </RenderSecure>

         <RenderSecure code="R-PAY-02">
            <Link to="/finance/paper-tickets" className="dropdown-item pointer">
              <i className="fas fa-tags text-danger mr-2"></i>Paper Tickets
            </Link>
         </RenderSecure>

         <RenderSecure code="R-MOMO-TRANS">
            <Link to="/finance/mobile-money" className="dropdown-item pointer">
              <i className="fas fa-mobile text-dark mr-2"></i>MobileMoney
            </Link>
         </RenderSecure>

         <RenderSecure code="R-BATCH-PAYMENT-01">
            <Link to="/payment/batch" className="dropdown-item pointer">
              <i className="fas fa-file-invoice text-danger mr-2"></i>Batch Payments
            </Link>
         </RenderSecure>

         <RenderSecure code="R-BATCH-PAYMENT-01">
            <Link to="/finance/efris" className="dropdown-item pointer">
              <i className="fa fa-gavel text-success mr-2"></i>URA EFRIS
            </Link>
         </RenderSecure>
        
         <RenderSecure code="R-BATCH-PAYMENT-01">
            <Link to="/finance/demand-note" className="dropdown-item pointer">
              <i className="fa fa-hand-holding text-danger mr-2"></i>Demand Notes
            </Link>
         </RenderSecure>
         
         
        
      </>

    )
  }

  buttons = (props) => {

    return (
      <>
        <RenderSecure code="R-CASHIER-MGT">
          <Link to="/finance/cashier/sales" className="btn ripple btn-success">
            <i className="fa fa-user"></i> Cashier Sales
          </Link>
        </RenderSecure>
        <RenderSecure code="R-RESERVATION-02">
          <Link to="/parking-space/reservation" className="btn ripple btn-primary">
            <i className="fa fa-car"></i> Reservations
          </Link>
        </RenderSecure>

      </>
    )
  }

  render() {

    return (
      <AuthConsumer>
        {props => {

          const permissionList = props.permissionList;
          return (
            <div className="page">
              <LeftSideBar />
              <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                <TopBar />
                <div className="container-fluid">

                  <PageHeader
                    title="Finance"
                    previousPageTitle="Dashboard"
                    back="/finance"
                    options={{
                      "customOptions": <this.customHeaderOptions permissionList={permissionList} />
                    }}
                    buttons={<this.buttons permissionList={permissionList} />}
                  />

                  <RenderSecure code="R-REVENUE-00">
                    <div className="row">
                      <CountMonthlyInvoices />
                      <MonthlyParkingRevenue />
                      <MonthlyWrongParkingRevenue />
                      {/* <MonthlyTowingRevenue /> */}
                      <AnnualReservationRevenue />
                    </div>
                  </RenderSecure>
                  {/* { functions.findInPermission(permissionList, 'FINANCE-00') && */}
                  <div className="row">
                    <div className="col-sm-12 col-xl-8 col-lg-8">

                      <RenderSecure code='R-INVOICE-02'>
                        <RecentInvoices />
                      </RenderSecure>
                      <RenderSecure code="R-REVENUE-00">
                        <SalesGraph />
                      </RenderSecure>
                    </div>
                    {/* <PrepaidGraph/> */}
                    <div className="col-sm-12 col-xl-4 col-lg-4">
                      <RenderSecure code="R-STICKER-02">
                        <RecentActiveStickers />
                      </RenderSecure>
                      <RenderSecure code="R-TICKET-02">
                        <RecenTicketMonitor />
                      </RenderSecure>
                    </div>

                  </div>
                  {/* } */}

                </div>
              </div>
              <RightSideBar />

            </div>


          );

        }}

      </AuthConsumer>
    )



  }
}

export default Finance
