import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class PopVehicleType extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        info: '',
        typeList: false,
        typeId:'',
        loading:false
    }

    componentDidMount() {
        this.getVehicleType()
    }

   
    onConfirm = (event) => {
        event.preventDefault();
        if (this.state.typeId.length > 0) {
            this.props.onConfirm(this.state.typeId);
        } else {
            this.setState({
                info: <AlertError message={"Select vehicle type. then try again"} />
            })
        }
    }

  



    getVehicleType = async () => {

        const server_response = await ajax.getVehicleTypes();
        if (server_response.status === "OK") {
            this.setState({
                typeList: server_response.details.content
            })
        } else {
            this.setState({
                typeList: "404"
            })
        }
    }

    onChangeType = (selectedOption) => {
        this.setState({
            typeId: selectedOption.value
        })
    }

    render() {

        const typeList = this.state.typeList;
        const optionsType = [];
        if (typeList && typeList !== "404") {
            typeList.map((item, key) =>
                optionsType.push({ label: item.type_name + ' - vehicle has ' + item.slots + ' slot(s)', value: item.type_id })
            )
        }

        return (
            <div className="modal" id="mdl_vehicle_type">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Set Vehicle Type</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            
                            {typeList && <form method="post" onSubmit={this.onConfirm}>

                                <SelectSearch
                                    label="Select Type"
                                    onChange={() => this.onChangeType}
                                    options={optionsType}
                                    placeholder={"Select vehicle type..."}
                                />



                            </form>}

                            {!typeList && <ActivityLoader/>}


                        </div>
                        <div className="modal-footer">
                        {typeList && !this.state.loading && <>
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Vehicle Type</button>
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                         </>}
                         {this.state.loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopVehicleType;
