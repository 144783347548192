import React, { useContext } from 'react';
import StatisticCard from '../../StatisticCard';
import FiscalReceiptContext from '../../../Context/Efris/FiscalReceiptContext';


const CreditNotesCurrentMonth =()=>{

    const {currentMonthCreditNote} = useContext(FiscalReceiptContext);
 
    return <StatisticCard
                title="Credit Notes" 
                value={currentMonthCreditNote ? currentMonthCreditNote : '...'}
                caption="This month"
                icon={"fas fa-ban"}
                color={"text-info"}
                progressbar={"progress-bar progress-bar-xs wd-60p bg-info"}
                />
 
}

export default CreditNotesCurrentMonth;