import { useContext } from "react"
import StatisticCard from "../../StatisticCard"
import DemandNoteContext from "../../../Context/Finance/DemandNoteContext"

const PendingNote = () => {

    const {totalUnsettled} = useContext(DemandNoteContext);

    return( <StatisticCard
                title="Unsettled Invoices" 
                value={totalUnsettled}
                caption="All unpaid - UGX"
                icon={"fas fa-chart-bar"}
                color={"text-danger"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-danger"}
                sizeClass="col-sm-12 col-md-6 col-xl-6 col-lg-6"
                />
    )

}

export default PendingNote