import React from 'react'
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import $ from 'jquery';
import Notifications from './Notifications';



class WelcomeTopBar extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: ''
    }

    componentDidMount() {
        this.getUser();
    }


    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path

            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }


    toggleSideBar = () => {
        let element = document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            element[0].classList.toggle('main-sidebar-hide');
        } else {
            element[0].classList.toggle('main-sidebar-show');
        }
    }


    openDrawer = () => {
        setTimeout(() => {
            document.getElementById('system_drawer').classList.add('sidebar-open')

        }, 100);
    }


    closeSideBar = () => {
        let element = document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            // element[0].classList.toggle('main-sidebar-hide');
            element[0].classList.add('main-sidebar-hide');
        } else {
            //element[0].classList.toggle('main-sidebar-show');
            element[0].classList.add('main-sidebar-show');
        }
    }

    toggleProfileAccess = () => {
        let element = document.getElementById('profile-menu-access');
        element.classList.toggle('show');

    }

    onLogout = () => {
        // const cookies = new Cookies();
        // cookies.remove('parking@user');
        // window.location.replace('/login')

        localStorage.removeItem('parking@user');
        window.location.replace('/login');
    }

    logos = () => {
        return (
            <Link className="main-logo d-lg-none" to="/">
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img desktop-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img icon-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img desktop-logo theme-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img icon-logo theme-logo" alt="logo" />
            </Link>
        )
    }

    toggleDropdown = (idSelector) => {

        //functions.removeClassFromElements('header-dropdown', 'show') 
        setTimeout(() => {
            document.getElementById(idSelector).classList.toggle('show');

        }, 100);
    }




    onClickSearch = () => {
        $("#mdl-sys-finder").modal('show');
    }


    render() {
        const USER_BOUNDARY=functions.userBoundary();
        return (

            <div className="main-header side-header">
                <div className="container-fluid">
                    <div className="main-header-left">
                        <this.logos />
                        <a className="main-header-menu-icon" href="#" onClick={this.toggleSideBar} id="mainSidebarToggle"><span></span></a>
                    </div>

                    <div className="main-header-right">
                    {USER_BOUNDARY!=="VENDOR" && <div className="dropdown d-md-flex header-search">
                            <Link to="#" className="nav-link icon header-search">
                                <i className="fe fe-search" onClick={this.onClickSearch}></i>
                            </Link>
                            <div className="dropdown-menu">
                                <div className="main-form-search p-2">
                                    <input className="form-control" placeholder="Search" type="search" />
                                    <button className="btn"><i className="fe fe-search"></i></button>
                                </div>
                            </div>
                        </div>}
                        <Notifications />
                        <div className="dropdown main-profile-menu header-dropdown" id="profile_menu">

                          
                            <a className="main-img-user" href="#" onClick={() => this.toggleDropdown('profile_menu')}>
                                <img alt="avatar" src={this.state.photo} />
                            </a>



                            <div className="dropdown-menu">
                                <div className="header-navheading">
                                    <h6 className="main-notification-title">{this.state.username}</h6>
                                    <p className="main-notification-text">{this.state.roleName}</p>
                                </div>
                                <Link className="dropdown-item" to="/users/profile">
                                    <i className="fe fe-user"></i> My Profile
                                </Link>
                                <a className="dropdown-item" href="#">
                                    <i className="fe fe-edit"></i> Edit Profile
                                </a>
                                <a className="dropdown-item" href="#">
                                    <i className="fe fe-compass"></i> Activity
                                </a>
                                <Link className="dropdown-item" to="#" onClick={this.onLogout}>
                                    <i className="fe fe-power"></i> Sign Out
                                </Link>
                            </div>
                        </div>
                        
                        {USER_BOUNDARY!=="VENDOR" && <div className="dropdown d-md-flex header-settings">
                            {/* <a href="#" className="nav-link icon" data-toggle="sidebar-right" data-target=".sidebar-right" onClick={this.openDrawer}>
                                <i className="fe fe-settings"></i>
                            </a> */}

                            {/* <a className="main-header-menu-icon" href="#" onClick={this.openDrawer}id="mainSidebarToggle"><span></span></a> */}

                            <a href="#" className="main-header-menu-icon mr-0 ml-2 align-right" data-toggle="sidebar-right" data-target=".sidebar-right" onClick={this.openDrawer}>
                                {/* <i className="fe fe-align-right"></i> */}
                                <span></span>
                            </a>
                        </div>}
                    </div>
                </div>
            </div>

        )
    }

}

export default WelcomeTopBar
