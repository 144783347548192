import React, { useContext } from 'react'
import PageHeader from '../../../Components/Common/PageHeader'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import TableHeader from '../../../Components/Common/TableHeader'
import LoadMoreButton from '../../../Components/Other/LoadMoreButton'
import SupervisorContext from '../../../Context/SupervisorContext'
import NoContent from '../../../Components/Common/NoContent'
import PageContainer from '../../../Components/Layout/PageContainer'
import SupervisorFilter from '../../../Components/Supervisor/SupervisorFilter'
const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

const VehicleScanRecords = (props) => {

    const { scanRecords, goToNextPage, metaData, loading, getScanRecords } = useContext(SupervisorContext)

    const buttons = () => {
        return (
            <>
               
                <Link to="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>
                <Link className="btn ripple btn-light" to="#" onClick={getScanRecords}>
                    <i className="fa fa-redo mr-2"></i> Refresh
                </Link>

            </>
        )
    }





    const RenderRecords = () => {


        return (
            <div className="table-responsive">

                {scanRecords == "404" &&
                    <>
                        {/* <AlertError message={sessionMessage} type="info" /> */}
                        <NoContent />
                    </>
                }

                {Array.isArray(scanRecords) &&

                    <table id="table-scan-records" export-format={exportFormats} className="table table-bordered border-t0 key-buttons text-nowrap w-100 datatable">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Supervisor Name</th>
                                <th>Conductor</th>
                                <th>Vehicle</th>
                                <th>Location</th>
                                <th>Street</th>
                                <th>Comment</th>
                                <th>In Session</th>
                                <th>Date</th>

                            </tr>
                        </thead>

                        <tbody>
                            {scanRecords.map((item, key) =>
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{item.supervisor.full_name}<small className="tx-12 tx-gray-500"> @{item.supervisor.username}</small></td>
                                    <td>{item.conductor.full_name}<small className="tx-12 tx-gray-500"> @{item.conductor.username}</small></td>
                                    <td>{item.vehicle.plate_number}</td>
                                    <td>{item.location.location_name}</td>
                                    <td>{item.location.street.street_name}</td>
                                    <td>{item.comment}</td>
                                    <td>
                                        {item.found_in_session === "YES" && <span className="badge badge-success">{item.found_in_session}</span>}
                                        {item.found_in_session === "NO" && <span className="badge badge-secondary">{item.found_in_session}</span>}
                                    </td>
                                    <td>
                                        {item.created_at.short_date} <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                    </td>


                                </tr>
                            )}


                        </tbody>
                    </table>}


                {!scanRecords && <ActivityLoader />}

            </div>


        )

    }


   
    return ( <PageContainer>
      
                    <PageHeader
                        title="Supervisor Transactions"
                        previousPageTitle="Supervisor"
                        back="/operations/supervision"
                        buttons={buttons()}
                    />

                    <SupervisorFilter/>

                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-lg-12">
                            <div className="card custom-card">
                                <div className="card-body">

                                    <TableHeader
                                        title="Scan History"
                                        subtitle="List of all supervisor scan records."
                                    />

                                                {Array.isArray(scanRecords) &&
                                                            <LoadMoreButton
                                                                action={goToNextPage}
                                                                loading={loading}
                                                                title={metaData.current_page + " of " + metaData.total_pages}
                                                            />
                                                        }


                                    {RenderRecords()}



                                </div>
                            </div>
                        </div>
                    </div>

    </PageContainer>
    );

}

export default VehicleScanRecords
