import React, { useContext, useState } from 'react';
import AlertError from '../../../Common/AlertError';
import AlertSuccess from '../../../Common/AlertSuccess';
import functions from '../../../../utils/functions';
import ThemeContext from '../../../../Context/ThemeContext';
import FiscalReceiptContext from '../../../../Context/Efris/FiscalReceiptContext';
import ajaxEfris from '../../../../utils/remote/ajaxEfris';
import WarnAction from '../../../WarnAction';
import ajaxEfrisHistory from '../../../../utils/remote/ajaxEfrisHistory';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';


const PopEfrisSwitch=(props)=> {

    
        const [info, setInfo] = useState(false);

        const {setDisplayMessage} = useContext(ThemeContext);

        const { getTaxConfig } = useContext(EfrisHistoryContext)
       
        const [prompt, setPrompt] = useState(props.value==='OFF'? "Confirm that you want to turn off tax saving" : "Confirm that you want to turn on tax saving");


        const onConfirm = async () => {
            var server_response;
            if(props.value === "ON"){
                server_response = await ajaxEfrisHistory.postTaxConfigOn();
            }else{
                server_response = await ajaxEfrisHistory.postTaxConfigOff();
            }
            if(server_response.status === "OK"){
                setDisplayMessage(functions.templateMessage("EFRIS Config: ", server_response.message, "info"))
                setInfo(<AlertSuccess message={server_response.message} />)
                functions.closePopup("efris_switch");
                getTaxConfig();
            }else{
                setDisplayMessage(functions.templateMessage("EFRIS Config: ", server_response.message, "danger"))
                setInfo(<AlertError message={server_response.message} />)
                functions.closePopup("efris_switch");
            }

            
        }



    
        return <WarnAction 
                    title="EFRIS Switch" 
                    id="efris_switch"
                    buttonLabel={"TURN " + props.value}
                    description={prompt}
                    onConfirm={onConfirm}
                    buttonTheme={props.value === 'OFF' ? 'danger' : 'success'}
                    icon="ion-ios-help-circle-outline"
                />


    }

export default PopEfrisSwitch;
