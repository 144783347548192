import React, { useContext } from 'react';
import AuthContext from '../../Context/AuthContext';
import functions from '../functions';

export const RenderSecure=(props)=>{

    const {permissionList} = useContext(AuthContext);

    if(functions.findInPermission(permissionList, props.code))
    {
        return <>{props.children}</>
    }else{
        return <></>
    }
           

}