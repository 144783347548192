import React from 'react'
import { Link } from 'react-router-dom'
import functions from '../../utils/functions';
import $ from 'jquery';
import PopNewSession from '../Session/PopNewSession';
import WarnSuccess from './WarnSuccess';
import { AuthConsumer } from '../../Context/AuthContext';
import { RenderSecure } from '../../utils/script/RenderSecure';
class LeftSideBar extends React.Component {

    state = {

        sideBarInitiated: true,
        newSessionDialogue: false,
        newSessionConfirmation: false,
        activeLocationPath: window.location.pathname,
        activeRoot: ''
    }

    componentDidMount() {

        const pathSections = this.state.activeLocationPath.split("/");
        this.setState({
            activeRoot: "/" + pathSections[1]
        })
    }


    toggleMenu = (e) => {
        e.preventDefault();
        if (e.target.parentElement.nodeName === "A") {

            functions.removeClassFromElementSiblings(e.target.parentElement.offsetParent, 'show')
            e.target.parentElement.offsetParent.classList.toggle('show');
        } else {
            functions.removeClassFromElementSiblings(e.target.parentElement, 'show')
            e.target.parentElement.classList.toggle('show');
        }

    }

    toggleSideBar = () => {
        let element = document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            element[0].classList.toggle('main-sidebar-hide');
        } else {
            element[0].classList.toggle('main-sidebar-show');
        }
    }

    openSideBar = () => {

        const controlElement = document.getElementsByClassName('main-sidebar-hide');
        if (controlElement.length > 0) {

            let element = document.getElementsByTagName('body');
            element[0].classList.add('main-sidebar-open')
            this.setState({
                sideBarInitiated: true
            })
        }

    }

    closeSideBar = () => {

        const controlElement = document.getElementsByClassName('main-sidebar-hide');
        if (controlElement.length > 0) {
            let element = document.getElementsByTagName('body');
            element[0].classList.remove('main-sidebar-open')
            this.setState({
                sideBarInitiated: false
            })

        }

    }

    logos = () => {
        return (
            <Link replace className="main-logo" to="#">
                <img src={process.env.PUBLIC_URL + "/images/logo-normal.svg"} className="header-brand-img desktop-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/parking_p.png"} className="header-brand-img icon-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/logo-white.svg"} className="header-brand-img desktop-logo theme-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/parking_f.png"} className="header-brand-img icon-logo theme-logo" alt="logo" />
            </Link>
        )
    }


    onClickStartSession = () => {

        this.setState({
            newSessionDialogue: false
        }, () => {
            this.setState({
                newSessionDialogue: <PopNewSession onConfirm={this.onConfirmStartSession} />,

            }, () => {
                $("#modal_new_session").modal('show');
            })
        })


    }

    onConfirmStartSession = (conductor, plateNumber) => {
        $("#modal_new_session").modal('hide');
        this.setState({
            newSessionConfirmation: false
        }, () => {
            this.setState({
                newSessionConfirmation: <WarnSuccess
                    title="Ready"
                    description={"You have chosen to start a session for " + plateNumber}
                    route={{
                        pathname: '/operations/sessions/new',
                        search: '?q=1',
                        state: { conductor: conductor, vehiclePlate: plateNumber }
                    }
                    }
                />
            }, () => {
                $("#pop_success").modal('show');
            })

        })


    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {

        return (
            <AuthConsumer>
                {props => {
                    const permissionList = props.permissionList;
                    const activeRoot = this.state.activeRoot;
                    const path = this.state.activeLocationPath;
                    const USER_BOUNDARY = functions.userBoundary();
                    const roleList = functions.roleGuard()
                    return (

                        <>
                            {this.state.newSessionConfirmation}
                            {this.state.newSessionDialogue}

                            <div className="main-sidebar main-sidebar-sticky side-menu"
                                onMouseEnter={this.openSideBar}
                                onMouseLeave={this.closeSideBar}
                            >
                                <div className="sidemenu-logo mt-2">
                                    <div className="touch-close-menu">
                                        <i className="fas fa-align-left custom-menu-icon" onClick={this.toggleSideBar}></i>
                                    </div>
                                    <this.logos />
                                </div>

                                <div className="main-sidebar-body">
                                    {USER_BOUNDARY == "VENDOR" &&
                                        <ul className="nav">
                                            <li className="nav-label">Ticket Sales</li>
                                            <li className={`nav-item show ${(activeRoot === '/') && 'active'}`}>
                                                <Link className="nav-link" to="/">
                                                    <i className="fas fa-edit"></i>
                                                    <span className="sidemenu-label">New ticket sale</span>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show ${(activeRoot === '/ticket-sale-records') && 'active'}`}>
                                                <Link className="nav-link" to="/ticket-sale-records">
                                                    <i className="fas fa-bookmark"></i>
                                                    <span className="sidemenu-label">Ticket Sales</span>
                                                </Link>
                                            </li>


                                            <li className="nav-label">Payments</li>
                                            <li className={`nav-item show ${(activeRoot === '/pay-parking') && 'active'}`}>
                                                <Link className="nav-link" to="#">
                                                    <i className="fas fa-car"></i>
                                                    <span className="sidemenu-label">Pay for parking</span>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show ${(activeRoot === '/parking-payment-records') && 'active'}`}>
                                                <Link className="nav-link" to="#">
                                                    <i className="fas fa-bookmark"></i>
                                                    <span className="sidemenu-label">Payment Records</span>
                                                </Link>
                                            </li>


                                            <li className="nav-label">Statements</li>
                                            <li className={`nav-item show ${(activeRoot === '/float-transactions') && 'active'}`}>
                                                <Link className="nav-link" to="#">
                                                    <i className="fas fa-exchange-alt"></i>
                                                    <span className="sidemenu-label">Float Transaction</span>
                                                </Link>
                                            </li>


                                        </ul>
                                    }

                                    {USER_BOUNDARY !== "VENDOR" &&

                                        <ul className="nav">
                                            <li className="nav-label">Dashboards</li>

                                            <RenderSecure code="OPERATIONS-00">
                                                <li
                                                    className={`nav-item show ${(activeRoot === '/operations') && 'active'}`}>
                                                    <Link className="nav-link" to="/operations">
                                                        <i className="fas fa-people-carry"></i>
                                                        <span className="sidemenu-label"> Operations</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>



                                            <RenderSecure code="OPERATIONS-00">
                                                <li
                                                    className={`nav-item show ${(activeRoot === '/monitor') && 'active'}`}>
                                                    <Link className="nav-link" to="/monitor">
                                                        <i className="fas fa-desktop"></i>
                                                        <span className="sidemenu-label"> Field Activity</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>

                                            <RenderSecure code="OPERATIONS-00">
                                                <li
                                                    className={`nav-item show ${(activeRoot === '/contraventions') && 'active'}`}>
                                                    <Link className="nav-link" to="/contraventions">
                                                        <i className="fa fa-gavel"></i>
                                                        <span className="sidemenu-label"> Contraventions</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>

                                            <RenderSecure code='FINANCE-00'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/finance' && 'active'}`}>
                                                    <Link className="nav-link" to="/finance">
                                                        <i className="fas fa-chart-bar"></i>
                                                        <span className="sidemenu-label"> Finance</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>

                                            <RenderSecure code='CASHIER-00'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/cashier' && 'active'}`}>
                                                    <Link className="nav-link" to="/cashier">
                                                        {/* <Link className="nav-link" to="/config"> */}
                                                        <i className="far fa-money-bill-alt"></i>
                                                        <span className="sidemenu-label"> Cashier</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>

                                            <RenderSecure code='USERS-00'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/users' && 'active'}`}>
                                                    <Link className="nav-link" to="/users">
                                                        <i className="fa fa-users"></i>
                                                        <span className="sidemenu-label"> Manage Users</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>


                                            <RenderSecure code="R-BRANCH-MONITOR-00">
                                                <li
                                                    className={`nav-item show ${(activeRoot === '/branch/monitor') && 'active'}`}>
                                                    <Link className="nav-link" to="/branch/monitor">
                                                        <i className="fas fa-desktop"></i>
                                                        <span className="sidemenu-label"> Branch Monitor</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>



                                            <RenderSecure code='CONFIG-00'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/config' && 'active'}`}>
                                                    <Link className="nav-link" to="/config">
                                                        {/* <Link className="nav-link" to="/config"> */}
                                                        <i className="fe fe-settings"></i>
                                                        <span className="sidemenu-label"> Configurations</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>

                                            <li className="nav-label">Applications</li>

                                            <RenderSecure code='R-MAP-02'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/parkingmap' && 'active'}`}>
                                                    <Link className="nav-link" to="/parkingmap">
                                                        <i className="fas fa-map-pin"></i>
                                                        <span className="sidemenu-label">Live Parking</span>
                                                    </Link>
                                                </li>
                                            </RenderSecure>


                                            <RenderSecure code='R-SESSION-02'>
                                                <li
                                                    className={`nav-item ${activeRoot === '/parking_sessions' && 'active show'}`}
                                                    onClick={this.toggleMenu}>
                                                    <Link className="nav-link with-sub" to="#">
                                                        <i className="far fa-clock"></i>
                                                        <span className="sidemenu-label">Parking Sessions</span>
                                                        <i className="angle fe fe-chevron-right"></i>
                                                    </Link>
                                                    <ul className="nav-sub">
                                                        <RenderSecure code='C-SESSION-01'>
                                                            <li
                                                                className={`nav-sub-item ${path === '/operations/sessions/new?q=1' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="#" onClick={this.onClickStartSession}>Start Session</Link>
                                                            </li>
                                                        </RenderSecure>
                                                        <RenderSecure code='R-SESSION-02'>
                                                            <li className={`nav-sub-item ${path === '/parking_sessions/sessions' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/parking_sessions/sessions">List Sessions</Link>
                                                            </li>
                                                        </RenderSecure>

                                                        <RenderSecure code='R-SESSION-IMPORT-01'>
                                                            <li className={`nav-sub-item ${path === '/parking_sessions/import' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/parking_sessions/import">Import Sessions</Link>
                                                            </li>
                                                        </RenderSecure>
                                                    </ul>
                                                </li>
                                            </RenderSecure>

                                            <RenderSecure code='R-AVAIL-PARKING-02'>
                                                <>
                                                    <li
                                                        className={`nav-item ${activeRoot === '/parking-space' && 'active show'}`}>
                                                        <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                            <i className="fas fa-hourglass-end"></i>
                                                            <span className="sidemenu-label">Parking Space</span>
                                                            <i className="angle fe fe-chevron-right"></i>
                                                        </Link>
                                                        <ul className="nav-sub">
                                                            <RenderSecure code="R-RESERVATION-02">
                                                                <li
                                                                    className={`nav-sub-item ${path === '/parking-space/reservation' && 'active'}`}
                                                                >
                                                                    <Link className="nav-sub-link" to="/parking-space/reservation">Reservations</Link>
                                                                </li>
                                                            </RenderSecure>
                                                            <li
                                                                className={`nav-sub-item ${path === '/parking-space/available-space' && 'active'}`}
                                                            >
                                                                <Link className="nav-sub-link" to="/parking-space/available-space">Available Space</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            </RenderSecure>

                                            <RenderSecure code='R-BRANCH-MONITOR-00'>
                                                <li
                                                    className={`nav-item ${activeRoot === '/operations' && 'active show'}`}
                                                    onClick={this.toggleMenu}>
                                                    <Link className="nav-link with-sub" to="#">
                                                        <i className="fas fa-desktop"></i>
                                                        <span className="sidemenu-label">Branch Management</span>
                                                        <i className="angle fe fe-chevron-right"></i>
                                                    </Link>
                                                    <ul className="nav-sub">
                                                        {/* <RenderSecure code='C-SESSION-01'>
                                                            <li
                                                                className={`nav-sub-item ${path === '/operations/sessions/new?q=1' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="#" onClick={this.onClickStartSession}>Start Session</Link>
                                                            </li>
                                                        </RenderSecure> */}
                                                        {/* <RenderSecure code='R-SESSION-02'>
                                                            <li className={`nav-sub-item ${path === '/parking_sessions/sessions' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/parking_sessions/sessions">List Sessions</Link>
                                                            </li>
                                                        </RenderSecure> */}


                                                        {/* <RenderSecure code='R-SESSION-02'>
                                                            <li className={`nav-sub-item ${path === '/operations/supervision' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/operations/supervision">Field Supervision</Link>
                                                            </li>
                                                        </RenderSecure> */}
                                                        <RenderSecure code='R-LOCATION-DISTRIB-02'>
                                                            <li className={`nav-sub-item ${path === '/operations/user-location-mapping' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/operations/user-location-mapping">Location Distribution</Link>
                                                            </li>
                                                        </RenderSecure>

                                                        <RenderSecure code='R-DEVICE-DISTRIB-02'>
                                                            <li className={`nav-sub-item ${path === '/operations/device-distribution' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/operations/device-distribution">Device Distribution</Link>
                                                            </li>
                                                        </RenderSecure>
                                                        <li className={`nav-sub-item ${path === '/finance/paper-tickets-summary' && 'active'}`}>
                                                            <Link className="nav-sub-link" to="/finance/paper-tickets-summary">Paper Ticket Summary</Link>
                                                        </li>

                                                    </ul>
                                                </li>
                                            </RenderSecure>



                                            <RenderSecure code='R-AVAIL-PARKING-02'>
                                                <>
                                                    {/* <li
                                                        className={`nav-item ${activeRoot === '/parking-space' && 'active show'}`}>
                                                        <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                            <i className="fas fa-hourglass-end"></i>
                                                            <span className="sidemenu-label">Parking Space</span>
                                                            <i className="angle fe fe-chevron-right"></i>
                                                        </Link>
                                                        <ul className="nav-sub">
                                                            <RenderSecure code="R-RESERVATION-02">
                                                                <li
                                                                    className={`nav-sub-item ${path === '/parking-space/reservation' && 'active'}`}
                                                                >
                                                                    <Link className="nav-sub-link" to="/parking-space/reservation">Reservations</Link>
                                                                </li>
                                                            </RenderSecure>
                                                            <li
                                                                className={`nav-sub-item ${path === '/parking-space/available-space' && 'active'}`}
                                                            >
                                                                <Link className="nav-sub-link" to="/parking-space/available-space">Available Space</Link>
                                                            </li>
                                                        </ul>
                                                    </li> */}

                                                    {/* <li
                                                        className={`nav-item ${activeRoot === '/contraventions' && 'active show'}`}>
                                                        <Link className="nav-link with-sub" to="/#" onClick={this.toggleMenu}>
                                                            <i className="fa fa-gavel"></i><span className="sidemenu-label">Contraventions</span><i className="angle fe fe-chevron-right"></i></Link>
                                                        <ul className="nav-sub">
                                                            <li
                                                                className={`nav-sub-item ${path === '/contraventions/wrong_parking' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/contraventions/wrong_parking">Wrong Parking</Link>
                                                            </li>
                                                            <li
                                                                className={`nav-sub-item ${path === '/contraventions/payment_due' && 'active'}`}>

                                                                <Link className="nav-sub-link" to="/contraventions/payment_due">Due Payments</Link>
                                                            </li>
                                                        </ul>
                                                    </li> */}

                                                    {/* <li
                                                        className={`nav-item ${activeRoot === '/contraventions' && 'active show'}`}>
                                                        <Link className="nav-link with-sub" to="/#" onClick={this.toggleMenu}>
                                                            <i className="fa fa-gavel"></i><span className="sidemenu-label">Contraventions</span><i className="angle fe fe-chevron-right"></i></Link>
                                                        <ul className="nav-sub">
                                                            <li
                                                                className={`nav-sub-item ${path === '/contraventions/wrong_parking' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/contraventions/wrong_parking">Wrong Parking</Link>
                                                            </li>
                                                            <li
                                                                className={`nav-sub-item ${path === '/contraventions/payment_due' && 'active'}`}>

                                                                <Link className="nav-sub-link" to="/contraventions/payment_due">Due Payments</Link>
                                                            </li>
                                                            <li
                                                                className={`nav-sub-item ${path === '/whitelisting' && 'active'}`}>

                                                                <Link className="nav-sub-link" to="/whitelisting">White Listing</Link>
                                                            </li>
                                                        </ul>
                                                    </li> */}

                                                    <li 
                                                        className={`nav-item ${activeRoot === '/enforcement' && 'active show'}`}>
                                                        <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                            <i className="fas fa-ban"></i><span className="sidemenu-label">Enforcements</span><i className="angle fe fe-chevron-right"></i></Link>
                                                        <ul className="nav-sub">
                                                            <li
                                                                className={`nav-sub-item ${path === '/enforcement/clamp_transactions' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/enforcement/clamp_transactions">Clamping</Link>
                                                            </li>
                                                            {/* <li
                                                                className={`nav-sub-item ${path === '/enforcement/transactions' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/enforcement/transactions">Clamp Transactions</Link>
                                                            </li> */}
                                                           
                                                            <li className={`nav-sub-item ${path === '/enforcement/tow_transactions' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/enforcement/tow_transactions"> Towing</Link>
                                                            </li>

                                                        </ul>
                                                    </li>

                                                    {/* <li className={`nav-item ${activeRoot === '/towing' && 'active show'}`}>
                                                        <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                            <i className="fas fa-wrench"></i><span className="sidemenu-label">Towing</span><i className="angle fe fe-chevron-right"></i></Link>
                                                        <ul className="nav-sub">
                                                            <li className={`nav-sub-item ${path === '/enforcement/tow_transactions' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/enforcement/tow_transactions"> Tow Transactions</Link>
                                                            </li>


                                                        </ul>
                                                    </li> */}
                                                </>
                                            </RenderSecure>


                                            <RenderSecure code='R-WHITELIST-02'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/whitelisting' && 'active'}`}>
                                                    <Link className="nav-link" to="/whitelisting">
                                                        <i className="fas fa-check"></i>
                                                        <span className="sidemenu-label">White Listing</span>
                                                    </Link>
                                                </li>


                                            </RenderSecure>


                                            <RenderSecure code='C-VIP-01'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/viplisting' && 'active'}`}>
                                                    <Link className="nav-link" to="/viplisting">
                                                        <i className="fas fa-user-tie"></i>
                                                        <span className="sidemenu-label">VIP Listing</span>
                                                    </Link>
                                                </li>


                                            </RenderSecure>

                                            <RenderSecure code='R-REPORTS-02'>
                                                <li className={`nav-item ${activeRoot === '/reports' && 'active show'}`}>
                                                    <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                        <i className="fas fa-chart-area"></i><span className="sidemenu-label">Reports</span><i className="angle fe fe-chevron-right"></i></Link>

                                                    <ul className="nav-sub">
                                                        {/* <RenderSecure code='R-X-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/xreport' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/xreport">X-Report</Link>
                                                            </li>
                                                        </RenderSecure> */}

                                                        {/* <RenderSecure code='R-USER-PERFORMANCE-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/userfield-performance' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/userfield-performance">UserField Performance</Link>
                                                            </li>
                                                        </RenderSecure> */}

                                                        {/* <RenderSecure code='R-USER-PERFORMANCE-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/conductor-performance' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/conductor-performance">Conductor Performance</Link>
                                                            </li>
                                                        </RenderSecure> */}

                                                        <RenderSecure code='R-USER-PERFORMANCE-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/user-performance' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/user-performance">User Field Performance</Link>
                                                            </li>
                                                        </RenderSecure>

                                                        {/* <RenderSecure code='R-USER-PERFORMANCE-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/zulu-performance' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/zulu-performance">Zulu Performance</Link>
                                                            </li>
                                                        </RenderSecure> */}


                                                        {/* <RenderSecure code='R-X-REPORTS-02'>
                                                <li className={`nav-sub-item ${path === '/reports/cobra-report' && 'active'}`}>
                                                    <Link className="nav-sub-link" to="/reports/cobra-report">CobrasReport</Link>
                                                </li>
                                            </RenderSecure> */}

                                                        <RenderSecure code='R-INVENTORY-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/demand_supply/occupancy' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/demand_supply/occupancy">Parking Demand & Supply</Link>
                                                            </li>
                                                        </RenderSecure>
                                                        {/* <RenderSecure code='R-OCCUPANCY-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/reports/demand_supply/occupancy' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/demand_supply/occupancy">Occupancy Analysis</Link>
                                                            </li>
                                                        </RenderSecure> */}

                                                        <RenderSecure code='R-ENFORCEMENT-ANALYSIS'>
                                                            <li className={`nav-sub-item ${path === '/reports/enforcement/analysis' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/enforcement/analysis">Enforcement Analysis</Link>
                                                            </li>
                                                        </RenderSecure>

                                                        {/* <RenderSecure code='R-ENFORCEMENT-ANALYSIS'>
                                                            <li className={`nav-sub-item ${path === '/reports/contravention/analysis' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/contravention/analysis/contravention_type">                                                         Analysis By Violation Type
                                                                </Link>
                                                            </li>
                                                        </RenderSecure> */}


                                                        {/* <RenderSecure code='R-ENFORCEMENT-ANALYSIS'>
                                                            <li className={`nav-sub-item ${path === '/reports/contravention/analysis' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/reports/contravention/analysis"> Contravention Analysis</Link>
                                                            </li>
                                                        </RenderSecure> */}




                                                        <RenderSecure code='R-PAYMENT-REPORTS-02'>
                                                            <li className={`nav-sub-item ${path === '/finance/payments' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/finance/payments">Payment Tracking</Link>
                                                            </li>
                                                        </RenderSecure>

                                                        <RenderSecure code='R-INVOICE-02'>
                                                            <li className={`nav-sub-item ${path === '/finance/invoices' && 'active'}`}>
                                                                <Link className="nav-sub-link" to="/finance/invoices">Vehicle Invoices</Link>
                                                            </li>
                                                        </RenderSecure>





                                                    </ul>
                                                </li>
                                            </RenderSecure>


                                            {/* <RenderSecure code='R-VEHICLES-02'>

                                                <li className={`nav-item ${activeRoot === '/vehicles' && 'active show'}`}>
                                                    <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                        <i className="fas fa-car"></i><span className="sidemenu-label">Vehicles</span><i className="angle fe fe-chevron-right"></i></Link>

                                                    <ul className="nav-sub">
                                                        <li className={`nav-sub-item ${path === '/vehicles/all-vehicles' && 'active'}`}>
                                                            <Link className="nav-sub-link" to="/vehicles/all-vehicles">Vehicle Records</Link>
                                                        </li>


                                                    </ul>
                                                </li>
                                            </RenderSecure> */}

                                            <RenderSecure code='R-VEHICLES-02'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/vehicles' && 'active'}`}>
                                                    <Link className="nav-link" to="/vehicles/all-vehicles">
                                                        <i className="fas fa-car"></i>
                                                        <span className="sidemenu-label">Vehicles</span>
                                                    </Link>
                                                </li>

                                                {/* <li
                                                    className={`nav-sub-item ${path === '/whitelisting' && 'active'}`}>

                                                    <Link className="nav-sub-link" to="/whitelisting">White Listing</Link>
                                                </li> */}
                                            </RenderSecure>




                                            <RenderSecure code="R-VENDOR-LIST">

                                                <li className={`nav-item ${activeRoot === '/vendor' && 'active show'}`}>
                                                    <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                        <i className="fas fa-mobile"></i><span className="sidemenu-label">Resellers</span><i className="angle fe fe-chevron-right"></i></Link>
                                                    <ul className="nav-sub">
                                                        <li className={`nav-sub-item ${path === '/vendor/list' && 'active'}`}>
                                                            <Link className="nav-sub-link" to="/vendor/list"> List Resellers</Link>
                                                        </li>


                                                    </ul>
                                                </li>
                                            </RenderSecure>


                                            {/* <RenderSecure code="R-DOCUMENTATION-00">
                                                <li className={`nav-item ${activeRoot === '/documentation' && 'active show'}`}>
                                                    <Link className="nav-link with-sub" to="#" onClick={this.toggleMenu}>
                                                        <i className="fas fa-book"></i><span className="sidemenu-label">Documentation</span><i className="angle fe fe-chevron-right"></i></Link>

                                                    <ul className="nav-sub">
                                                        <li className={`nav-sub-item ${path === '/documentation/project-docs' && 'active'}`}>
                                                            <Link className="nav-sub-link" to="/documentation/project-docs">Project Docs</Link>
                                                        </li> */}
                                                        {/* {functions.findInPermission(permissionList, 'R-INVENTORY-REPORTS-02') && */}
                                                        {/* <li className={`nav-sub-item ${path === '/documentation/api-docs' && 'active'}`}>
                                                            <Link className="nav-sub-link" to="/documentation/api-docs">API Docs</Link>
                                                        </li> */}
                                                        {/* } */}

                                                    {/* </ul>
                                                </li>
                                            </RenderSecure> */}
                                            {/* } */}


                                            <RenderSecure code='R-DOCUMENTATION-00'>
                                                <li
                                                    className={`nav-item show ${activeRoot === '/documentation' && 'active'}`}>
                                                    <Link className="nav-link" onClick={() => openInNewTab('https://pmisdocs.thrivetecdev.com/login')} to="#">
                                                        <i className="fas fa-book"></i>
                                                        <span className="sidemenu-label">Documentation</span>
                                                    </Link>
                                                </li>

                                                {/* <li
                                                    className={`nav-sub-item ${path === '/whitelisting' && 'active'}`}>

                                                    <Link className="nav-sub-link" to="/whitelisting">White Listing</Link>
                                                </li> */}
                                            </RenderSecure>


                                        </ul>}

                                </div>
                            </div>
                        </>
                    )



                }}
            </AuthConsumer>)

    }

}

export default LeftSideBar