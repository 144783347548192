import React from 'react';
import ajax from '../../../utils/ajax';
import functions from '../../../utils/functions';
import ActivityLoader from '../../Common/ActivityLoader';

class PopVehicleListing extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        info: '',
        loading: false,
        vehicleListing:false
    }

    componentDidMount() {
        this.getLists();
    }
    

   
    
    getLists=async()=>{
        const server_response = await ajax.getUserVehicleListing(this.state.userId)
        if (server_response.status === "OK") {
            this.setState({
                vehicleListing: server_response.details.content
            })
        }
        
    }
   


   
    render() {
        const listing=this.state.vehicleListing;
        const list_bg=['bg-danger', 'bg-success', 'bg-warning', 'bg-secondary', 'bg-primary'];

        return (
            <div className="modal" id={this.props.id || "modal_vehicle_listing"}>
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                <i className="fas fa-capsules text-success mr-1"/>
                                Vehicle Listing
                            </h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <h6 className="card-title mb-3">{this.props.description || "Select list to add vehicle"}</h6>
                            </div>
                            <div className="scroll-widget overflow-auto">
                                {this.state.loading && <ActivityLoader />}
                                {listing && 
                                    <ul class="task-list" style={{lineHeight:1.5}}>
                                       {listing.map((item, key)=>
                                       <li className="pointer" key={key} onClick={()=>this.props.onSelect(item.list_id, this.props.callBack)}>
                                       <i class={`task-icon ${list_bg[key] || 'bg-light'}`}></i>
                                       <h6>{item.title}<small class="float-right text-muted tx-11">{item.created_at.short_date}</small></h6>
                                       <span class="text-muted tx-12 d-inline-block text-truncate" style={{maxWidth: "90%"}}>
                                           {item.description}
                                        </span>
                                        </li>)
                                       }
                                    </ul>} 
                            </div>              
                           
                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopVehicleListing;
