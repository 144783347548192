import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');

const apiHost = dictionary.apiHost

export default {

    async importClampTools(file) {

        let data = {
            "file": file
        }

        try {
            let response = await fetch(apiHost + 'clamping/tool/import',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

}
