import React from 'react'
import { Link } from 'react-router-dom';
import WrongParkingContext from '../Context/ContraventionContext/WrongParkingContext';
import { OperationConsumer } from '../Context/OperationContext';
import ajax from '../utils/ajax';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ActivityLoader from './Common/ActivityLoader';
import NoContent from './Common/NoContent';
import TableHeader from './Common/TableHeader';
import PopSessionLocation from './Operations/PopSessionLocation';
import PopViewImage from './PopViewImage';
import $ from 'jquery';

class RecentReportedWrongParking extends React.Component {

    static contextType = WrongParkingContext;

    state = {
        userId: functions.sessionGuard(),
        sessionList: false,
        locationPIN: false,
        imageView: false,
    }

    componentDidMount() {
        const { getRecentWrongParkingTransactions } = this.context;
        getRecentWrongParkingTransactions();
    }

    onClickViewVehiclePhoto = (record) => {
        this.setState({
            imageView: false
        }, () => {

            this.setState({
                imageView: <PopViewImage image={record} />
            }, () => {
                $("#modal_view_image").modal('show');
            })
        })

    }

    onClickLocation = (record) => {
        this.setState({
            locationPIN: false
        }, () => {
            this.setState({
                locationPIN: <PopSessionLocation session={record} />
            }, () => {
                functions.openPopup('pop-session-location')
            })
        })
    }

    ViewAllSessions = () => {
        this.props.history.push('/parking_sessions/sessions')
    }


    render() {

        const { recentWrongParking } = this.context

        return (
            <div className="card custom-card ">
                {this.state.imageView}
                <div className="card-body map-card">

                    <TableHeader
                        title="Recent Wrong Parkings"
                        subtitle="A representation of the recent wrong parking activity."
                        view="View All"
                        href={this.props.href}
                    />

                    {/* <div className="border-top mt-3"></div> */}
                    {this.state.locationPIN}
                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                        <div className="table-responsive">
                            <table className="table table-hover text-nowrap mg-b-0">
                                <thead>
                                    <tr>
                                        <th>Wrong Parking ref</th>

                                        <th>Reported On</th>
                                        <th>Vehicle</th>
                                        
                                        
                                        {/* <th>Street</th> */}
                                        <th>Proof</th>
                                        <th>Reported By</th>
                                        <th>Location</th>
                                        

                                        {/* <th>Description</th> */}
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (recentWrongParking === "404")
                                            ?
                                            <tr>
                                                <td className="flag-row-no-content justify-content-center text-center m-auto" colSpan="7">
                                                    {/* <img alt="no-content" src={nocontent} /> */}
                                                    <NoContent />
                                                </td>
                                            </tr>
                                            :
                                            recentWrongParking && recentWrongParking !== "404" &&
                                            recentWrongParking.map((item, key) =>
                                                <tr key={key}>
                                                    {/* <td>
                                                            {item.start_time.long_date}
                                                        </td> */}
                                                    <td>
                                                        {item.ref}
                                                    </td>
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                        {/* {item.created_at.date} */}
                                                    </td>
                                                    <td className="tx-bold">
                                                        <Link to={
                                                            {
                                                                pathname: `/vehicles/profile/${item.vehicle.vehicle_number}`,
                                                                state: { plateNumber: item.vehicle.vehicle_number }
                                                            }}>{item.vehicle.vehicle_number}
                                                        </Link>
                                                    </td>
                                                    

                                                    <td className="pointer">
                                                        <div className="main-img-user">
                                                            <img alt="avatar" onClick={() => this.onClickViewVehiclePhoto(item)} src={item?.vehicle_photo?.file_path} />
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <h6 className="mg-b-1">{item.reported_by.username}</h6>
                                                        <small className="tx-12 tx-gray-500">{item.reported_by.user_role.role_name}</small>
                                                    </td>
                                                    
                                                    <td>
                                                        <span className="pointer" onClick={() => this.onClickLocation(item)}>
                                                            <i className="fas fa-map-marker-alt text-primary" /> &nbsp;
                                                            {item.location.location_name}<br />
                                                            <small className="tx-12 tx-gray-500">{item.location.street.street_name}</small>
                                                        </span>
                                                    </td>
                                                    
                                                    {/* <td>{item.description.description}</td> */}
                                                    
                                                </tr>
                                            )}


                                </tbody>

                            </table>
                            {!recentWrongParking && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

export default RecentReportedWrongParking
