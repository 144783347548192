import React from 'react'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import { Link } from 'react-router-dom'
import NoContent from '../../Components/Common/NoContent'
import dictionary from '../../utils/dictionary'
import OccupancyContext from '../../Context/Reports/OccupancyContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import OccupancyFilter from '../../Components/Reports/OccupancyFilter'
import TableTools from '../../Components/Common/TableTools'
const eformats = dictionary._exportBtnFormats

class Occupancy extends React.Component {
    static contextType = OccupancyContext;



    buttons = () => {
        return (
            <>
                <ol className="customButtonList">
                    <li>
                        <Link to="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                        </Link>
                    </li>

                    <li>
                        <button className="btn ripple btn-light" onClick={this.context.getVehicleOccupancy}>
                            <i className="fa fa-redo mr-2"></i> Refresh
                        </button>

                    </li>

                    {/* <li>
                        <a href="#" className="btn ripple btn-primary text-white navresponsive-toggler" >
                            <TableTools size />
                        </a>

                    </li> */}

                </ol>
            </>
        )
    }





    RenderLoadMore = () => {
        const { occupancyList, filter, goToNextPage, metaData, loading } = this.context;
        return <>
            {Array.isArray(occupancyList) &&
                <div className="">
                    <LoadMoreButton
                        action={goToNextPage}
                        loading={loading}
                        title={metaData == false ? "Refresh" : (metaData.current_page + " of " + metaData.total_pages + " pages")}
                    />
                </div>
            }
        </>

    }




    render() {

        const { occupancyList, filter, fetchMore, metaData, loading } = this.context;

        return (

            <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">

                    <TableHeader
                        title={!filter ? " Today's Occupancy Analysis" : "Occupancy Analysis for filtered Data"}
                        subtitle="This report handles two parameters Occupancy and Turnover."
                        filterButton={occupancyList && occupancyList !== "404" && <this.buttons />}

                    />

                    <OccupancyFilter />

                    <div className="table-responsive">

                        <table id={this.props.tableID} className={`table mb-0 table-hover table-bordered occupancy-table text-nowrap ${this.props.dataTable}`} export-format={eformats}>
                            <thead>
                                <tr className="text-center border-1">
                                    <th>Street Name</th>
                                    <th className='text-center'>Location Name</th>
                                    {/* <th>Location Description</th> */}
                                    <th className='text-center '>Total bays</th>
                                    <th colSpan="6">Average Occupancy</th>
                                    <th colSpan="6">Average Turnover</th>
                                </tr>
                                <tr className="text-center border-1">
                                    <td></td>
                                    <td></td>
                                    {/* <th></th> */}
                                    <td></td>
                                    <th colSpan="4">Weekday</th>
                                    <th colSpan="2">Saturday</th>
                                    <th colSpan="4">Weekday (Vehicles)</th>
                                    <th colSpan="2">Saturday (Vehicles)</th>
                                    {/* <td></td> */}
                                </tr>
                                <tr className="text-center border-1">
                                    <td></td>
                                    <td></td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td>8:00am - 10:00am</td>
                                    <td>10:00am - 12:00pm</td>
                                    <td>12:00pm - 03:00pm</td>
                                    <td>03:00pm - 06:00pm</td>
                                    <td>8:00am - 1:00pm</td>
                                    <td>1:00pm - 6:00pm</td>
                                    <td>8:00am - 10:00am</td>
                                    <td>10:00am - 12:00pm</td>
                                    <td>12:00pm - 03:00pm</td>
                                    <td>03:00pm - 06:00pm</td>
                                    <td>8:00am - 1:00pm</td>
                                    <td>1:00pm - 6:00pm</td>
                                    {/* <td></td> */}
                                </tr>
                            </thead>
                            <tbody>
                                {occupancyList && occupancyList !== "404" &&
                                    occupancyList.map((item, key) =>

                                        <tr key={key}>
                                            <td>{item.location_info.street.street_name}</td>
                                            <td className='text-center '>{item.location_info.location_name}</td>
                                            {/* <td>{item.locations.location_info.description}</td> */}
                                            {<td className='text-center '>{item.location_info.total_slots.total}</td>}
                                            <td className='w-100px text-center'>
                                                <Tippy content={<span>so - slots occupied<br /> ts - total slots<br />    nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekly_occupancy"]["08:00-10:00"]["avg_occupancy"]}
                                                        <br />

                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekly_occupancy"]["08:00-10:00"]["occupancy_formular"]}</small>
                                                    </span>
                                                </Tippy>
                                            </td>

                                            <td className='text-center'>
                                                <Tippy content={<span>so - slots occupied<br /> ts - total slots<br />    nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekly_occupancy"]["10:00-12:00"]["avg_occupancy"]}
                                                        <br />

                                                        <small className="tx-12 tx-gray-500">{item.occupancy && item.occupancy["weekly_occupancy"]["10:00-12:00"]["occupancy_formular"]}
                                                        </small>
                                                    </span>
                                                </Tippy>
                                            </td>
                                            <td className='text-center'>
                                                <Tippy content={<span>so - slots occupied<br /> ts - total slots<br />    nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekly_occupancy"]["12:00-15:00"]["avg_occupancy"]}
                                                        <br />
                                                        <small className="tx-12 tx-gray-500">{item.occupancy && item.occupancy["weekly_occupancy"]["12:00-15:00"]["occupancy_formular"]}
                                                        </small>
                                                    </span>
                                                </Tippy>
                                            </td>
                                            <td className='text-center'>
                                                <Tippy content={<span>so - slots occupied<br /> ts - total slots<br />    nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekly_occupancy"]["15:00-18:00"]["avg_occupancy"]}


                                                        <br />


                                                        <small className="tx-12 tx-gray-500">{item.occupancy && item.occupancy["weekly_occupancy"]["15:00-18:00"]["occupancy_formular"]}</small>
                                                    </span>
                                                </Tippy>
                                            </td>
                                            <td className='text-center'>
                                                <Tippy content={<span>so - slots occupied<br /> ts - total slots<br />    nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekend_occupancy"]["08:00-13:00"]["avg_occupancy"]}

                                                        <br />

                                                        <small className="tx-12 tx-gray-500">{item.occupancy && item.occupancy["weekend_occupancy"]["08:00-13:00"]["occupancy_formular"]}</small>
                                                    </span>
                                                </Tippy>
                                            </td>
                                            <td className='text-center'>

                                                <Tippy content={<span>so - slots occupied<br /> ts - total slots<br />    nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekend_occupancy"]["13:00-18:00"]["avg_occupancy"]}

                                                        <br />

                                                        <small className="tx-12 tx-gray-500">{item.occupancy && item.occupancy["weekend_occupancy"]["13:00-18:00"]["occupancy_formular"]}</small>
                                                    </span>
                                                </Tippy>
                                            </td>
                                            <td className='text-center'>
                                                <Tippy content={<span> tv - total vehicles<br />
                                                    ts - Total Slots <br /> nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy && item.occupancy["weekly_occupancy"]["08:00-10:00"]["turn_over"]}

                                                        <br />


                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekly_occupancy"]["08:00-10:00"]["turnover_formular"]}</small>
                                                    </span>
                                                </Tippy>

                                            </td>
                                            <td className='text-center'>
                                                <Tippy content={<span> tv - total vehicles<br />
                                                    ts - Total Slots <br /> nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy["weekly_occupancy"]["10:00-12:00"]["turn_over"]}

                                                        <br />


                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekly_occupancy"]["10:00-12:00"]["turnover_formular"]}</small>
                                                    </span>
                                                </Tippy>
                                            </td>
                                            <td className='text-center'>
                                                <Tippy content={<span> tv - total vehicles<br />
                                                    ts - Total Slots <br /> nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy["weekly_occupancy"]["12:00-15:00"]["turn_over"]}

                                                        <br />


                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekly_occupancy"]["12:00-15:00"]["turnover_formular"]}</small>

                                                    </span>
                                                </Tippy>
                                            </td>

                                            <td className='text-center'>
                                                <Tippy content={<span> tv - total vehicles<br />
                                                    ts - Total Slots <br /> nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy["weekly_occupancy"]["15:00-18:00"]["turn_over"]}


                                                        <br />


                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekly_occupancy"]["15:00-18:00"]["turnover_formular"]}</small>
                                                    </span>
                                                </Tippy>
                                            </td>

                                            <td className='text-center'>

                                                <Tippy content={<span> tv - total vehicles<br />
                                                    ts - Total Slots <br /> nd - number of days  </span>}>
                                                    <span>
                                                        {item.occupancy["weekend_occupancy"]["08:00-13:00"]["turn_over"]}


                                                        <br />


                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekend_occupancy"]["08:00-13:00"]["turnover_formular"]}</small>


                                                    </span>
                                                </Tippy>
                                            </td>

                                            <td className='text-center'>
                                                <Tippy content={<span> tv - total vehicles<br />
                                                    ts - Total Slots <br /> nd - number of days  </span>}>
                                                    <span>

                                                        {item.occupancy["weekend_occupancy"]["13:00-18:00"]["turn_over"]}

                                                        <br />


                                                        <small className="tx-12 tx-gray-500 ">{item.occupancy && item.occupancy["weekend_occupancy"]["13:00-18:00"]["turnover_formular"]}</small>

                                                    </span>
                                                </Tippy>
                                            </td>

                                        </tr>


                                    )}
                            </tbody>
                        </table>

                        {!occupancyList && <ActivityLoader />}

                        {(occupancyList === "404") && <NoContent />}
                        <this.RenderLoadMore />
                        {/* {Array.isArray(occupancyList) &&
                            <div className="">
                                <LoadMoreButton
                                    action={metaData.total_pages > 1 ? fetchMore : ""}
                                    loading={loading}
                                    title={metaData == false ? "Refresh" : (metaData.current_page + " of " + metaData.total_pages + " pages")}
                                />
                            </div>
                        } */}
                    </div>
                </div>

            </div>


        );

    }
}

export default Occupancy;
