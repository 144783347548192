import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
// import TitleValueCard from '../../../Components/Common/TitleValueCard'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import { Link } from 'react-router-dom'
import ConfigurationContext, { ConfigurationConsumer } from '../../../Context/ConfigurationContext'
import dictionary from '../../../utils/dictionary'
import PopNewDevice from '../../../Components/Device/PopNewDevice'
import PopEditDevice from '../../../Components/Device/PopEditDevice'
import ajaxConfig from '../../../utils/remote/ajaxConfig'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
const eformats = dictionary._exportConfigBtnFormats

class ConfigDevice extends React.Component {
    static contextType = ConfigurationContext
    state = {
        userId: functions.sessionGuard(),
        DeviceList: false,
        deviceId: false,
        deviceRecord: false,
        toast: '',
        modal: false
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    onSave = async (serialNumber, phoneNumber, tagNumber) => {
        const { getDevices } = this.context;
        //fetch login from the API
        const server_response = await ajax.createDevice(serialNumber, phoneNumber, tagNumber);

        if (server_response.status === "OK") {
            functions.closePopup("modal_new_device");

            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
            }, () =>

                getDevices());
        } else {
            functions.closePopup("modal_new_device");


            this.setState({
                toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />
            })
        }


    }


    onUpdate = async (deviceId, serialNumber, phoneNumber, tagNumber) => {
        const { getDevices } = this.context;
        //fetch login from the API
        const server_response = await ajaxConfig.updateDevice(deviceId, serialNumber, phoneNumber, tagNumber);
        functions.closePopup("modal_update_device");
        if (server_response.status === "OK") {
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />
            })

            getDevices();
        } else {

            this.setState({
                toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />
            })
        }


    }

    onDelete = async () => {

        if (this.state.deviceId) {

            const { getDevices } = this.context;
            const server_response = await ajaxConfig.deleteDevice(this.state.deviceId);
            functions.closePopup("modal_delete_device")
            if (server_response.status === "OK") {
                getDevices();
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} />
                })
            }
        }
    }

    onClickAdd = () => {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopNewDevice
                    onConfirm={this.onSave}
                />
            }, () => functions.openPopup("modal_new_device"))
        })
    }


    onClickEdit = () => {

        if (this.state.deviceRecord) {

            this.setState({
                modal: false
            }, () => {
                this.setState({
                    modal: <PopEditDevice
                        record={this.state.deviceRecord}
                        onConfirm={this.onUpdate}
                    />
                }, () => functions.openPopup("modal_update_device"))
            })
        } else {
            this.setState({
                toast: <Toast message={"Select record and try again"} type={4} onClose={this.resetToast} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.deviceRecord) {
            this.setState({
                modal: false
            }, () => {

                this.setState({
                    modal: <WarnDelete
                        id="modal_delete_device"
                        title="Are you sure you want to delete selected device?"
                        description="Make sure the device has no transaction record"
                        onConfirm={this.onDelete} />
                }, () => functions.openPopup("modal_delete_device"))
            })
        }
    }



    onSelectRecord = (deviceId, deviceRecord) => {
        this.setState({
            deviceId: deviceId,
            deviceRecord: deviceRecord,
            toast: ''
        })
    }


    RenderDevice = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const DeviceList = props.deviceList
                    return (
                        // <div className="scroll-widget border-top overflow-auto" style={{height:"490px"}}>
                        <div className="table-responsive allView-config border-top">

                            {(DeviceList === "404") && <NoContent />}

                            {DeviceList && Array.isArray(DeviceList) &&
                                <table id="config-device" className="table text-wrap mb-0 table-hover datatable" export-format={eformats}>
                                    <thead>
                                        <tr>
                                            {/* <th>No.</th> */}
                                            <th>Serial Number</th>
                                            <th>Phone Number</th>
                                            <th>Tag Number</th>
                                            <th>Created at</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DeviceList && DeviceList !== "404" &&
                                            DeviceList.map((item, key) =>
                                                <tr
                                                    id={"div_" + item.device_id}
                                                    key={key}
                                                    className={`${this.state.deviceId == item.device_id ? 'flag-row' : ''}`}
                                                    onClick={() => this.onSelectRecord(item.device_id, item)}
                                                >
                                                    {/* <td>{"#D" + item.device_id}</td> */}
                                                    <td>
                                                        <Link to={
                                                            {
                                                                pathname: '/config/devices/deviceinfo',
                                                                state: { record: item }
                                                            }
                                                        }>{item.device_number}</Link>
                                                    </td>
                                                    <td>{item.phone_number}</td>
                                                    <td>{item.tag_number}</td>
                                                    <td>
                                                        {item.created_at.date} <br />
                                                        <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>

                                </table>
                            }

                            {!DeviceList && <ActivityLoader />}
                        </div>
                        // </div>
                    )
                }}
            </ConfigurationConsumer>

        )
    }



    buttons = () => {

        return (
          <>
              <button className="btn ripple btn-light" onClick={this.context.getDevices}>
                <i className="fa fa-redo"></i> Refresh
              </button>
            
          </>
        )
      }

    RenderDeviceCount = () => {
        return (
            <ConfigurationConsumer>
                {props => {
                    const countAllDevices = props.totalDevices
                    return (
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">

                                    <button type="button" className="btn ripple btn-primary btn-sm">
                                        Total Devices <span className="badge badge-light ml-2">{countAllDevices}</span>
                                    </button>

                                    <button className="btn ripple btn-light btn-sm" onClick={this.context.getDevices}>
                                        <i className="fa fa-redo mr-1"></i> Refresh
                                    </button>

                                </div>
                            </div>
                        </div>
                    )
                }}
            </ConfigurationConsumer>
        )
    }



    render() {
        return (
            <div className="page">
                {this.state.modal}
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Devices"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "add": () => this.onClickAdd(),
                                "edit": () => this.onClickEdit(),
                                "delete": () => this.onClickDelete()
                            }}
                            buttons={<this.buttons/>}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center filter-example">
                                                <TableHeader
                                                    title="List Of Company Devices"
                                                    subtitle="List of all devices managed by Mutiplex parking"
                                                />

                                                <this.RenderDeviceCount />
                                                </div>

                                                <this.RenderDevice />
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigDevice
