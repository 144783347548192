import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async countBankingClients()//GET
    {

        try {
            let response = await fetch(apiHost + 'system/bank.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createBankingClient(bank_name)//POST
    {

        let data = {
            "bank_name": bank_name
        }

        try {
            let response = await fetch(apiHost + 'system/bank.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async migrateVehicleBalances(file) {

        let data = {
            "file": file
        }

        let response = await apiCall("migration/vehicle/balances/new", data)
        return response;
    },

    async getMigrationVehicleBalances(page) {

        let data = {
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'migration/vehicle/balances/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async migrateStickerBalances(file, depositor_name, depositor_contact, batch_name, client_vehicle, credit) {

        let data = {
            "file": file,
            "depositor_name":depositor_name,
            "depositor_contact":depositor_contact,
            "batch_name":batch_name,
            "client_vehicle":client_vehicle,
            "credit":credit
        }

        console.log(data);

        const response = await apiCall("sticker/migrate", data);
        return response;
    },

    async processMigrationVehicleBalances(password) {

        let data = {
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'migration/vehicle/balances/confirm',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async updateDevice(deviceId, serialNumber, phoneNumber, tagNumber) {

        let data = {
            "device_id": deviceId,
            "device_number": serialNumber,
            "phone_number": phoneNumber,
            "tag_number": tagNumber

        }
        try {
            let response = await fetch(apiHost + 'device.creat',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async updateDevice(deviceId, serialNumber, phoneNumber, tagNumber) {

        let data = {
            "device_number": serialNumber,
            "phone_number": phoneNumber,
            "tag_number": tagNumber,
            "device_id": deviceId
        }

        try {
            let response = await fetch(apiHost + 'device.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async deleteDevice(deviceId) {
        let data = {
            device_id: deviceId
        }
        try {
            let response = await fetch(apiHost + 'device.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    }
    ,

    async createWrongParkingDescription(description) {

        let data = {
            "description": description,

        }
        try {
            let response = await fetch(apiHost + 'description/create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    async updateWrongParkingDescription(description_id, description) {

        let data = {
            "description": description,
            "description_id": description_id
        }
        try {
            let response = await fetch(apiHost + 'description/update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async configureStickerVehicleTypes(sticker_id, vehicle_types) {

        const data = {
            "sticker_type_id": sticker_id,
            "vehicle_type_ids": vehicle_types
        }
        const response = await apiCall("configure/sticker/vehicle_type", data);
        return response
    },

    async getStickerVehicleTypes(sticker_id) {

        const data = {
            "sticker_type_id": sticker_id,
        }
        const response = await apiCall("sticker/vehicle_type/list", data);
        return response
    }



}