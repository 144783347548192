import React from 'react'
import { Link } from 'react-router-dom'
import { RenderSecure } from '../../utils/script/RenderSecure'
import Notifier from './Notifier'

class PageHeader extends React.Component {

  render() {
    return (
      <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">{this.props.title}
            &nbsp; {this.props.badge}
          </h2>
          {this.props.previousPageTitle && <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={this.props.back}>{this.props.previousPageTitle}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">{this.props.title}</li>
          </ol>}
        </div>



        <div className="btn btn-list">

          {this.props.options &&
            <>
              <a className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fe fe-settings mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                {this.props.options.startSession && <a className="dropdown-item" onClick={this.props.options.startSession}><i className="far fa-clock mr-1"></i>Start Session</a>}
                {this.props.options.view && <a className="dropdown-item" onClick={this.props.options.view}><i className="fe fe-eye mr-1"></i>View</a>}
                {this.props.options.add && <a className="dropdown-item" onClick={this.props.options.add}><i className="fe fe-plus-circle mr-1"></i>Add</a>}
                {this.props.options.edit && <a className="dropdown-item" onClick={this.props.options.edit}><i className="fe fe-edit mr-1"></i>Edit</a>}
                {this.props.options.email && <a className="dropdown-item" onClick={this.props.options.email}><i className="fe fe-mail mr-1"></i>Email</a>}
                {this.props.options.save && <a className="dropdown-item" onClick={this.props.options.save}><i className="fe fe-folder-plus mr-1"></i>Save</a>}
                {this.props.options.delete && <a className="dropdown-item" onClick={this.props.options.delete}><i className="fe fe-trash-2 mr-1"></i>Delete</a>}
                {this.props.options.more && <a className="dropdown-item" onClick={this.props.options.more}><i className="fe fe-settings mr-1"></i>More</a>}

                {this.props.options.resetPassword && <a className="dropdown-item" onClick={this.props.options.resetPassword}><i className="far fa-clock mr-1"></i>Reset Password</a>}

                {this.props.options.addInvoice && <a className="dropdown-item" onClick={this.props.options.addInvoice}><i className="fe fe-plus-circle mr-1"></i>Create Invoice</a>}
                {this.props.options.addPayment && <a className="dropdown-item" onClick={this.props.options.addPayment}><i className="fe fe-plus-circle mr-1"></i>Add Payment</a>}
                {this.props.options.addSession && <a className="dropdown-item" onClick={this.props.options.addSession}><i className="fe fe-plus-circle mr-1"></i>Create Session</a>}


                {this.props.options.customOptions}


              </div>
            </>
          }



          {this.props.respond_request && <div className="btn btn-list ">
            <a className="btn ripple btn-info" onClick={this.props.respond_request} href="#" >
              <i className="fas fa-link mr-1"></i> {this.props.name}
            </a>
          </div>}
          {this.props.clamp_vehicle && <div className="btn btn-list">
            <a className="btn ripple btn-secondary" onClick={this.props.clamp_vehicle} href="#" >
              <i className="fas fa-ban mr-1"></i> {this.props.name}
            </a>
          </div>}
          {this.props.respond_unclamp_vehicle && <div className="btn btn-list">
            <a className="btn ripple btn-primary" onClick={this.props.respond_unclamp_vehicle} href="#" >
              <i className="fas fa-ban mr-1"></i> {this.props.name}
            </a>
          </div>}
          {this.props.unclamp_vehicle && <div className="btn btn-list">
            <a className="btn ripple btn-light" onClick={this.props.unclamp_vehicle} href="#" >
              <i className="fas fa-ban mr-1"></i> {this.props.name}
            </a>
          </div>}
          {this.props.report_wrong_parking && <div className="btn btn-list">
            <a className="btn ripple btn-primary " onClick={this.props.report_wrong_parking} href="#" >
              <i className="fas fa-link mr-1"></i> Report Wrong Parking
            </a>
          </div>}

          {this.props.tow_vehicle && <div className="btn btn-list">
            <a className="btn ripple btn-info " onClick={this.props.tow_vehicle} href="#" >
              <i className="fas fa-link"></i> Tow Vehicle
            </a>
          </div>}

          {this.props.pick_vehicle && <div className="btn btn-list">
            <a className="btn ripple btn-primary " onClick={this.props.pick_vehicle} href="#" >
              <i className="fas fa-link"></i> Pick Up Vehicle
            </a>
          </div>}
          <RenderSecure code='U-ABORT-TOW'>
            {this.props.abort_vehicle && <div className="btn btn-list">
              <a className="btn ripple btn-danger " onClick={this.props.abort_vehicle} href="#" >
                <i className="fas fa-ban"></i> Abort Tow
              </a>
            </div>}
          </RenderSecure>



          {this.props.abort_clamp && <div className="btn btn-list">
            <a className="btn ripple btn-danger" onClick={this.props.abort_clamp} href="#" >
              <i className="fas fa-ban mr-1"></i> {this.props.name}
            </a>
          </div>}
          {this.props.report_and_clamp && <div className="btn btn-list ">
            <a className="btn ripple btn-success  " onClick={this.props.report_and_clamp} href="#" >
              <i className="fas fa-link mr-1"></i> Report and Clamp
            </a>
          </div>}

          {this.props.tow_direct && <div className="btn btn-list ">
            <a className="btn ripple btn-success  " onClick={this.props.tow_direct} href="#" >
              <i className="fas fa-link mr-1"></i> Tow Direct
            </a>
          </div>}


          {this.props.filter && <div className="btn btn-list ">
            <a className="btn ripple btn-success  " onClick={this.props.tow_direct} href="#" >
              <i className="fas fa-link mr-1"></i> Filter
            </a>
          </div>}

          <RenderSecure code='U-ABORT-TOW'>
            {this.props.abort_tow && <div className="btn btn-list ">
              <a className="btn ripple btn-danger  " onClick={this.props.abort_tow} href="#" >
                <i className="fas fa-link mr-1"></i> Abort Tow
              </a>
            </div>}
          </RenderSecure>

          {this.props.buttons}
          {this.props.custom}
          {this.props.export}

        </div>
        {this.props.modalContainer}
      </div>
      <Notifier/>
      </>
    )
  }

}

export default PageHeader
