import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import $ from 'jquery';
import dictionary from '../../utils/dictionary';

import DashRow from '../../Components/Cards/UserCards/DashRow'
import DashRow2 from '../../Components/Cards/UserCards/DashRow2'
import RecentlyAddedUsers from '../../Components/RecentlyAddedUsers'
import RecentRoles from '../../Components/RecentRoles'
import { ManageUserConsumer } from '../../Context/ManageUserContext'
import { Link } from 'react-router-dom'
// import ReservationChart from '../../Components/BarChart/ReservationChart'
import UsersChart from '../../Components/BarChart/UsersChart'


class Users extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        userList: false,
        menuOptions: '',
        userRecord: '',
        selectedUserId: '',
    }

    componentDidMount() {

        this.setState({
            menuOptions: {
                "add": () => this.onClickAdd(),
            }
        })
    }


    onSelectRecord = (selectedUserId, userRecord) => {
        this.setState({
            selectedUserId: selectedUserId,
            userRecord: userRecord
        }, () => {
            //change menu according to selected role
            if (this.state.userRecord.user_role.role_id === "2") {
                this.setState({
                    menuOptions: {
                        "startSession": () => this.onClickStartSession(),
                        "add": () => this.onClickAdd(),
                    }
                })

            } else {
                this.setState({
                    menuOptions: {
                        "add": () => this.onClickAdd(),
                    }
                })

            }

        })
    }

    onClickAdd = () => {
        this.props.history.push({
            pathname: '/config/users/new'
        })
    }

    onClickStartSession = () => {
        $("#modal_vehicle_input").modal('show');
    }

    onConfirmVehicle = (plateNumber) => {
        $("#modal_vehicle_input").modal('hide');

        this.props.history.push({
            pathname: '/operations/sessions/new',
            search: '?q=1',
            state: { conductor: this.state.userRecord, vehiclePlate: plateNumber }
        })
    }



    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-1"></i> Create user
                </button>

                <Link to="/user/access" className="btn ripple btn-primary">
                    <i className="fas fa-lock mr-1"></i> Manage Permissions
                </Link>
            </>
        )
    }


    statisticsTiles = () => {
        return (
            <ManageUserConsumer>
                {props => {
                    return (
                        <div className="col-md-12">
                            <div className="card custom-card">
                                <div className="row">
                                    <DashRow size="col-xl-3 col-lg-3 col-md-6 col-sm-6 border-right" title="System Users" icon="fe fe-arrow-down" value={props.userCount} percentage="5%" color="mb-0 text-danger fs-13 ml-1" caption="This month" />
                                    <DashRow size="col-xl-3 col-lg-3 col-md-6 col-sm-6 border-right" title="System Roles" icon="fe fe-arrow-up" value={props.roleCount} percentage="0.27%" color="mb-0 text-success fs-13 ml-1" caption="This month" />
                                    <DashRow size="col-xl-3 col-lg-3 col-md-6 col-sm-6 border-right" title="Permissions Added" icon="fe fe-arrow-down" value={props.permissionCount} percentage="0.87%" color="mb-0 text-danger fs-13 ml-1" caption="This month" />
                                    <DashRow2 size="col-xl-3 col-lg-3 col-md-6 col-sm-6" title="Assigned Permissions" icon="fe fe-arrow-up" value="35" percentage="22%" color="mb-0 text-success fs-13 ml-1" caption="This month" />
                                </div>
                            </div>
                        </div>
                    )
                }}
            </ManageUserConsumer>
        )
    }

    render() {

        return (

            <div className="page">


                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="User Management"
                            previousPageTitle="Dashboard"
                            back="/users"
                            // options={this.state.menuOptions}
                            buttons={<this.buttons />}
                        />

                        <div className="row">
                            <this.statisticsTiles />
                        </div>

                        <div className="row">
                            {/* <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">
                                <RecentRoles href="/config/roles" />
                            </div> */}

                            <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">
                                <UsersChart />
                            </div>

                            <div className="col-sm-12 col-md-12 col-xl-8 col-lg-8">
                                <RecentlyAddedUsers href="/users/all-users" />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-8 col-lg-8">
                                <RecentlyAddedUsers href="/users/all-users" />
                            </div>

                            <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">
                                <RecentRoles href="/config/roles" />
                            </div>

                        </div>


                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default Users
