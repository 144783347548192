import React, { useContext, useEffect } from 'react'
import PageHeader from '../../../Components/Common/PageHeader'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import PageContainer from '../../../Components/Layout/PageContainer'
import SupervisorReportFilter from '../../../Components/Supervisor/SupervisorReportFilter'
import SupervisorReportContext from '../../../Context/SupervisorReportContext'
const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

const SupervisorFieldReport = (props) => {

    const {fieldReport, getFieldReport, title} = useContext(SupervisorReportContext)

    useEffect(()=>{
        getFieldReport();
    }, [])

    const buttons = () => {
        return (
            <>
               
                <Link to="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </Link>
                <Link className="btn ripple btn-light" to="#" onClick={getFieldReport}>
                    <i className="fa fa-redo mr-2"></i> Refresh
                </Link>

            </>
        )
    }





    const RenderRecords = () => {


        return (
            <div className="table-responsive">

                {fieldReport == "404" &&
                    <>
                        <NoContent />
                    </>
                }

                {Array.isArray(fieldReport) &&

                    <table id="table-supervisor-report" export-format={exportFormats} className="table table-bordered border-t0 key-buttons text-nowrap w-100 datatable">
                        <thead>
                            <tr>
                                <th colSpan={9} style={{textAlign:'center'}}>{title}<br/><br/></th>
                            </tr>
                            <tr>
                                <th>No.</th>
                                <th>Supervisor Name</th>
                                <th>Username</th>
                                <th>Branch</th>
                                <th>Sessions</th>
                                <th>Scanned</th>
                                <th>Started</th>
                                <th>Coverage</th>
                                <th>Efficiency</th>

                            </tr>
                        </thead>

                        <tbody>
                            {fieldReport.map((item, key) =>
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{item.user.full_name}</td>
                                    <td>{item.user.username}</td>
                                    <td>{item.user.branch.branch_name}</td>
                                    <td>{item.data.total_sessions.total_p}</td>
                                    <td>{item.data.total_scanned.total_p}</td>
                                    <td>{item.data.total_started.total_p}</td>
                                    <td>{item.data.coverage}%</td>
                                    <td>{item.data.efficiency}%</td>
                                </tr>
                            )}


                        </tbody>
                    </table>}


                {!fieldReport && <ActivityLoader />}

            </div>


        )

    }


   
    return ( <PageContainer>
      
                    <PageHeader
                        title="Supervisor Field Report"
                        previousPageTitle="Supervisor"
                        back="/operations/supervision"
                        buttons={buttons()}
                    />

                    <SupervisorReportFilter/>

                    <div className="row">
                        <div className="col-sm-12 col-xl-12 col-lg-12">
                            <div className="card custom-card">
                                <div className="card-body">

                                    <TableHeader
                                        title="Field Report"
                                        subtitle="Supervisor field report"
                                    />


                                    {RenderRecords()}



                                </div>
                            </div>
                        </div>
                    </div>

    </PageContainer>
    );

}

export default SupervisorFieldReport
