import React, { useEffect, useState } from 'react';
import ajaxEfrisHistory from '../../utils/remote/ajaxEfrisHistory';
import functions from '../../utils/functions';

const EfrisHistoryContext = React.createContext();

export const EfrisHistoryConsumer = EfrisHistoryContext.Consumer;

export const EfrisHistoryProvider = (props)=> {

  const [allTransactions, setAllTransactions] = useState(false);

  const [data, setData] = useState({"page":"1"})
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(false);
  const [currentMonthTax, setCurrentMonthTax] = useState(false);
  const [lastMonthTax, setLastMonthTax] = useState(false);

  const [taxConfig, setTaxConfig] = useState(false);

  useEffect(()=>{
    if(functions.roleGuard()==="SUPER"){
        getTaxConfig();
        getLastMonthTax();
        getCurrentMonthTax();
    }
  }, []);

  useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])


useEffect(()=>{
  getAllTransactions();
}, [data])


  const getAllTransactions=async()=>{
    setLoading(true)
    setAllTransactions(false)
    const server_response = await ajaxEfrisHistory.fetchReceiptRecords(data);
    setLoading(false)
    if(server_response.status==="OK"){
      setAllTransactions(server_response.details.content.list)
      setMetaData(server_response.details.content.meta);
    }else{

      setAllTransactions("404");
      setMetaData("404");
    }
  }



  const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "page":currentPage,
    }
    fetchMore(newData)
}


const fetchMore = async(newData) => {
    setLoading(true);
    // restoreTable(detectTable());

    const server_response = await ajaxEfris.fetchReceiptRecords(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.content.list);
        setAllTransactions(allTransactions.concat(incoming));
        setMetaData(server_response.details.content.meta);
    }
   
}


const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
}


const resetData=()=>{
    setCurrentPage(1)
    setData({"page":"1"})
}

const filterData=(options)=>{
    setCurrentPage(1)
    setData({
        "date_from":options.dateFrom,
        "date_to":options.dateTo,
        "clamp_cause":options.clampCause,
        "page":"1",
        "plate_number":options.plateNumber,
        "status":options.status,
        "location":options.location,
        "clamp_incident":options.incident,
        "cobra":options.cobra

    })
    
}



const getLastMonthTax=async()=>{
    setLastMonthTax(false);
    const server_response = await ajaxEfrisHistory.fetchLastMonthTax();
    if(server_response.status === "OK"){
        setLastMonthTax(server_response.details.content);
    }
}

const getCurrentMonthTax=async()=>{
    setCurrentMonthTax(false);
    const server_response = await ajaxEfrisHistory.fetchCurrentMonthTax();
    if(server_response.status === "OK"){
        setCurrentMonthTax(server_response.details.content);
    }
}


const getTaxConfig=async()=>{
    setTaxConfig(false);
    const server_response = await ajaxEfrisHistory.fetchTaxConfig();
    if(server_response.status === "OK"){
        setTaxConfig(server_response.details.content);
    }
}

const refreshTax = async()=>{
    getTaxConfig();
    getCurrentMonthTax();
    getLastMonthTax();
    resetData();

}
    
    return (
           <EfrisHistoryContext.Provider value={
               {
                  allTransactions,
                  metaData,
                  loading,
                  currentMonthTax,
                  lastMonthTax,
                  taxConfig,
                  getAllTransactions,
                  goToNextPage,
                  setData,
                  resetData,
                  filterData,
                  refreshTax,
                  getTaxConfig
               }
               }>
               {props.children}
           </EfrisHistoryContext.Provider>
        );
    
}

export default EfrisHistoryContext;