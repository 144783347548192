import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


async getSessionsToday()//GET
{

    try {
        let response = await fetch(apiHost + 'session/list/today',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},
async countAllSessions() {
    try {
      let response = await fetch(apiHost + 'session/all/count',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async countEndedSessions() {

    let data = {
        "source": "admin"
    }

    try {
        let response = await fetch(apiHost + 'session/ended/count',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},
  async getClientSessionEndRequests()
  {
      const response = await apiCall("session/pending/client-requests/list", {
          "source":"admin"
      });
      return response
  },

  async migrateParkings(file) {

    let data = {
        "file": file
    }
    let response = await apiCall("migration/session/import", data)
    return response;
},


async fetchMigratedParkings(data) {
    let response = await apiCall("migration/session/list", data)
    return response;
},

async approveMigratedParkings(password) {
    let data = {
        "password": password
    }
    let response = await apiCall("migration/session/approve", data)
    return response;
},


async deleteMigratedParking(password, id) {
    let data = {
        "password": password,
        "id":id
    }
    let response = await apiCall("migration/session/delete", data)
    return response;
}


}