import React from 'react';
import { Link } from 'react-router-dom';
// import ajax from '../../utils/ajax';
// import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
// import ActivityLoader from '../Common/ActivityLoader';
// import AlertError from '../Common/AlertError';
// import AlertSuccess from '../Common/AlertSuccess';
import InitialAvatar from '../Common/InitialAvatar';
// import TextInput from '../Common/TextInput';

class PopVehicleOptions extends React.Component {

  
    destroyModal=()=>{
        functions.closePopup("modal_vehicle_option");
    }

  render(){
      const vehicle=this.props.vehicleInfo
      return (
        <div className="modal" id="modal_vehicle_option">
        <div className="modal-dialog modal-dialog-centered" role="document">
            

        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Linked Vehicle</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                
                <div class="justify-content-center text-center mt-3 d-flex">
                    <InitialAvatar value={<i className="fas fa-car"></i>}/>
                </div>
                <div class="justify-content-center text-center mt-3 d-flex">
                    <h5 class="mb-1 mt-0 ">{vehicle.plate_number}</h5>
                </div>
                <div class="justify-content-center text-center mt-3 d-flex">
                    <p class="mb-1">{"Added since " + vehicle.created_at } </p>
                </div>
               
               
                <div class="justify-content-center text-center mt-3 d-flex">
                    
                   
                    <Link 
                        to={
                            {
                                pathname:`/vehicles/profile/${vehicle.plate_number}`,
                                state:{plateNumber:vehicle.plate_number}
                            }} class="btn ripple btn-info btn-icon mr-3"
                        title="View Vehicle Profile"
                        onClick={this.destroyModal}>
                        <i class="fe fe-eye"></i>
                    </Link>
                    <a 
                        href="#" 
                        class="btn ripple btn-danger btn-icon" 
                        title="Unlink Vehicle"
                        onClick={this.props.onUnlink}>
                        <i class="fas fa-unlink"></i>
                    </a>
                   
                </div>
            
            </div>
            <div className="modal-footer">
              
            </div>
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopVehicleOptions;
