import React, { Component } from 'react';
import CashPaymentForm from '../../Payments/CashPaymentForm';
import ChequePaymentForm from '../../Payments/ChequePaymentForm';

class PaySticker extends Component {

    state={
        paymentMode:this.props.paymentModeCode
    }


    render() {

        const paymentMode=this.state.paymentMode

        return (
            <div className="row row-sm">
            <div className="col-md">
                <div className="card custom-card">
                    <div className="card-body">
                        <h5 className="card-title tx-dark tx-medium mg-b-10">
                            <a href="#" onClick={this.props.onExit}>
                                 <i className="fas fa-arrow-alt-circle-left text-primary"/>
                            </a>&nbsp;&nbsp;
                            New Sticker
                        </h5>
                        <div className="card-text">

                           {paymentMode == "CHEQUE" && <ChequePaymentForm vehicleInfo={this.props.vehicleInfo}/>}

                           {paymentMode == "CASH" && <CashPaymentForm vehicleInfo={this.props.vehicleInfo}/>}

                         

                        </div>
                           
                          
                           
                    </div>
                </div>
            </div>


          



        </div>


        );
    }
}

export default PaySticker;