import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async logOnline() {

        let data = {
            "device": "BROWSER"
        }
        let response = await apiCall('log/online', data);
        return response;
    },

    

}