import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';

class PopNewReservation extends React.Component {

    state = {
        info: '',
        locationId: '',
        organisationId: '',
        deviceList: false,
        organisationList: false,
        userRecord: false,
        loading: false,
        locationList: false,
        totalSlots: '',
        reservationPlanList: false,
        reservationPlanId: '',
        startDate: '',
        amount: '',

    }
    componentDidMount() {
        this.getOrganisations();
        this.getLocations();
        this.listReservationPlans();

    }

    onChangeLocation = (selectedOption) => {
        this.setState({
            locationId: selectedOption.value
        })
    }


    getLocations = async () => {

        const server_response = await ajax.listLocations();
        if (server_response.status === "OK") {
            this.setState({
                locationList: server_response.details.content
            })
        } else {
            this.setState({
                locationList: "404"
            })
        }
    }


    getOrganisations = async () => {

        const server_response = await ajax.listOrganisations();
        if (server_response.status === "OK") {
            this.setState({
                organisationList: server_response.details.content.list
            })
        } else {
            this.setState({
                organisationList: "404"
            })
        }
    }


    onChangeOrganisation = (selectedOption) => {
        this.setState({
            organisationId: selectedOption.value
        })
    }

    onConfirm = async () => {
        this.setState({
            info: ''
        })
        const { organisationId, locationId, totalSlots, reservationPlanId, startDate, amount } = this.state;
        if (organisationId.length > 0 && locationId.length > 0 && totalSlots.length > 0 && reservationPlanId.length > 0 && startDate.length > 0 && amount > 0) {
            this.setState({
                loading: true
            }, () => this.props.onConfirm(organisationId, locationId, totalSlots, reservationPlanId, startDate, amount))
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />
            })
        }
    }


    onChangeSlots = (event) => {
        this.setState({ totalSlots: event.target.value })
    }

    onChangeAmount = (event) => {
        this.setState({ amount: event.target.value })
    }

    listReservationPlans = async () => {

        const server_response = await ajax.listReservationPlans();
        if (server_response.status === "OK") {
            this.setState({
                reservationPlanList: server_response.details.content
            })
        } else {
            this.setState({
                reservationPlanList: "404"
            })
        }
    }
    onChangeReservationPlan = (selectedOption) => {
        this.setState({ reservationPlanId: selectedOption.value })
    }


    onChangeStartDate = (event) => {
        this.setState({ startDate: event.target.value })
    }


    render() {
        const organisationList = this.state.organisationList;
        const optionsOrganisation = [];
        if (organisationList && organisationList !== "404") {
            organisationList.map((item, key) =>
                optionsOrganisation.push({ label: item.title, value: item.organisation_id })
            )
        }


        const locationList = this.state.locationList;
        const optionsLocation = [];
        if (locationList && locationList !== "404") {
            locationList.map((item, key) =>
                optionsLocation.push({ label: item.location_name + ' along ' + item.street.street_name, value: item.location_id })
            )
        }

        const reservationPlanList = this.state.reservationPlanList;
        const optionsReservationPlanList = [];
        if (reservationPlanList && reservationPlanList !== "404") {
            reservationPlanList.map((item, key) =>
                optionsReservationPlanList.push({ label: item.plan, value: item.plan_id })
            )
        }

        return (
            <div className="modal" id="pop_new_reservation">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">New Reservation</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>

                                <SelectSearch
                                    label="Organisation"
                                    onChange={() => this.onChangeOrganisation}
                                    options={optionsOrganisation}
                                    placeholder={"Select Organisation..."}
                                />

                                <SelectSearch
                                    label="Location"
                                    onChange={() => this.onChangeLocation}
                                    options={optionsLocation}
                                    placeholder={"Select Location..."}
                                />


                                <TextInput
                                    label="No. Of Slots"
                                    type="number"
                                    onChange={() => this.onChangeSlots}
                                    value={this.state.totalSlots}
                                />


                                <SelectSearch
                                    label="Reservation Plan"
                                    onChange={() => this.onChangeReservationPlan}
                                    options={optionsReservationPlanList}
                                    placeholder={"Select Reservation Plan..."}
                                />

                                <TextInput
                                    label="Start Date"
                                    type="date"
                                    onChange={() => this.onChangeStartDate}
                                    value={this.state.startDate}
                                />

                                <TextInput
                                    label="Total Amount"
                                    type="number"
                                    placeholder="Total Amount to be paid"
                                    onChange={() => this.onChangeAmount}
                                    value={this.state.amount}
                                />


                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Reserve Location</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>

            </div>
        )


    }
}

export default PopNewReservation;
