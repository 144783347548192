import React, {useEffect, useState } from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import TaskBoardCard from './TaskBoardCard';

const WhiteListing=()=>{

  const [count, setCount] = useState("...")
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(()=>{
    countRecords();
  }, [refreshCounter])

  
  useEffect(()=>{

    setTimeout(() => {
        setRefreshCounter(refreshCounter+1)
    }, dictionary._waitTime);

}, [refreshCounter])

  
  

  const countRecords=async ()=>{

    const server_response = await ajax.countPendingWhiteList();
    if(server_response.status==="OK")
    {
        setCount(server_response.details.content.total_p)
    }
  }



        return (
          <TaskBoardCard
            title={"Vehicle White List"}
            value={count}
            caption={"Pending approval"}
            href="/whitelisting"
          />
        );
    }

export default WhiteListing;