import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../../utils/ajax';
import dictionary from '../../../utils/dictionary';
import functions from '../../../utils/functions';

const CashierBankTotal=()=> {

  const [balance, setBalance] = useState("...");
  const [userId, setUserId] = useState(functions.sessionGuard())
  const [refreshCounter, setRefreshCounter] = useState(0);

  
  useEffect(()=>{
    getBalance();
  }, [refreshCounter])

  
  useEffect(()=>{

    setTimeout(() => {
        setRefreshCounter(refreshCounter+1)
    }, dictionary._waitTime);

}, [refreshCounter])
  
  


  const getBalance = async () => {

    const server_response = await ajax.getCashierDailyBankTotal(userId);
    if (server_response.status === "OK") {
      
        setBalance(server_response.details.content.amount_p)
    
    }
  }



    return (
      <Link to="#" className="col-6 col-md-6 text-center bg-company-block">
      <div>
        <h6 className="mt-1 text-mute">Bank</h6>
        <h4 className="mb-1"><span className="minor-tag">ugx</span>{balance}</h4>
        {/* <span className="text-muted fs-12"><i className="fas fa-caret-up mr-1 text-success"></i>Total Balance</span> */}
      </div>
    </Link>

    )
  }


export default CashierBankTotal
