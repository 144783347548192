import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import dictionary from '../../../utils/dictionary'
import NoContent from '../../../Components/Common/NoContent'
import TableHeader from '../../../Components/Common/TableHeader'
const eformats = dictionary._exportConfigBtnFormats

class ConfigWrongParking extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        transactionList: false,
        transactionId: false,
        transactionRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = async () => {
        const server_response = await ajax.getWrongParkingFees();
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content
            })
        } else {
            this.setState({
                transactionList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewAmount: false
        }, () => {
            this.setState({
                mdlNewAmount: <PopNewAmount
                    title="New Wrong Parking Fee"
                    buttonLabel="Confirm Amount"
                    description="This fee will automatically override the existing amount.
                              only a single fee can be active at a time"
                    onConfirm={this.onConfirmFee} />
            }, () => {
                $("#modal_amount_input").modal('show')
            })
        })
    }


    onConfirmFee = async (amount) => {

        const server_response = await ajax.createWrongParkingFee(amount);
        if (server_response.status === "OK") {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
            }, () => {

                this.getTransactions();
            })
        } else {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.transactionRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Delete Wrong Parking Fee?"
                        description="Are you sure you want to delete this wrong parking fee?"
                        onConfirm={() => this.onConfirmDelete()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        }
    }

    onConfirmDelete = async () => {

        if (this.state.transactionId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteWrongParkingFee(this.state.transactionId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
                }, () => {

                    //hide record
                    functions.hide('br_' + this.state.transactionId);
                    //Refresh list
                    this.getTransactions();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
                })
            }

        }

    }



    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                    <i className="far fa-edit mr-1"></i> New Fee
                </button>
            </>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>New Fee
                </a>
                <a className="dropdown-item" onClick={this.onClickDelete}>
                    <i className="fe fe-trash-2 mr-2"></i>Delete Fee
                </a>

            </>
        )
    }


    onSelectTransaction = (recordId, record) => {
        this.setState({
            transactionId: recordId,
            transactionRecord: record,
            toast: ''
        })
    }

    refreshToast = () => {
        this.setState({
            toast: false
        })
    }

    render() {
        const transactionList = this.state.transactionList
        return (
            <div className="page">
                {this.state.mdlNewAmount}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Wrong Parking Fees"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                                <TableHeader
                                                    title="Wrong Parking Fees"
                                                    subtitle="This represents the amount of money charged whenever a vehicle is reported for wrong parking"
                                                />

                                                <div className="table-responsive">
                                                    {(transactionList === "404") && <NoContent />}

                                                    {transactionList && Array.isArray(transactionList) &&
                                                        <table id="config-wrongParkingFees" className="table text-nowrap mb-0 table-hover datatable" export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Amount</th>
                                                                    <th>Status</th>
                                                                    <th>Created At</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transactionList && transactionList !== "404" &&
                                                                    transactionList.map((item, key) =>
                                                                        <tr
                                                                            id={"br_" + item.id}
                                                                            key={key}
                                                                            className={`${this.state.transactionId == item.id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelectTransaction(item.id, item)}>
                                                                            <td>{item.amount}</td>
                                                                            <td>{item.status_name}</td>
                                                                            <td>{item.created_at.long_date}</td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!transactionList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>





                                </div>

                            </div>

                            <ConfigMenu />



                        </div>















                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ConfigWrongParking
