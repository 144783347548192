import React from 'react';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import ajaxPayment from '../../utils/remote/ajaxPayment';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import CompanyHeader from '../Config/CompanyHeader';
import QRCodeGenerator from '../Common/QrCode/QRCodeGenerator';


class PopPaymentSummary extends React.Component {

    state={
	   depositRef : this.props.deposit_ref,
       receipts : this.props.record,
	   fiscal_receipt : false,
	   info : false
    }


	componentDidMount() {
		if(!this.props.record){
			this.getPayment();
		}
		
	}

	getPayment = async()=>{
		const server_response = await ajaxPayment.getPaymentDeposit(this.state.depositRef);
		if(server_response.status == "OK")
		{
			this.setState({
				receipts:server_response.details.content
			})
		}else{
			this.setState({
				receipts:"404",
				info : <AlertError message={server_response.details.message}/>
			})
		}
	}

    onClickPay=()=>{
        this.props.onClickPay(this.state.invoiceRecord.vehicle.plate_number)
    }

	 
	print = () => {
		functions.printDoc('receipt_doc');
	}


  render(){
	  const record=this.state.receipts;
      return (
        <div className="modal" id="modal_payment_summary">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Receipt</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
               
            
                <div className="row">
						{record && record!=="404" && <div className="col-lg-12 col-md-12">
							<div className="card custom-card">
								<div className="card-body" id="receipt_doc">
									<div className="d-lg-flex">
                                        <h2 className="card-title mb-1">
											{"RECEIPT"}<br/>
                                            REF: {record.ref}<br/>
											NO: {record.payment_id}
                                        </h2>
										<div className="ml-auto">
                                            <p className="mb-1"><span className="font-weight-bold">Receipt Date :</span> {record.payment_date.long_date}</p>
										</div>
									</div>
									<hr className="mg-b-40"/>
									<div className="row">
										<div className="col-lg-6">
											<CompanyHeader/>
										</div>
										<div className="col-lg-6 text-right">
											<p className="h3">Payment From:</p>
											<address>
												{"By: " + record.depositor_name}<br/>
                                                {record.contact}<br/>
												
											</address>
										</div>
									</div>
									<div className="table-responsive mg-t-40">
										<table className="table table-invoice table-bordered">
											<thead>
												<tr>
													<th className="wd-5p">#NO.</th>
													<th className="wd-25p">Vehicle</th>
                                                    <th className="wd-25p">Invoice No.</th>
                                                    <th className="wd-25p">Amount Paid.</th>
													<th className="wd-25p">Bal. After</th>
												</tr>
											</thead>
											<tbody>

												{record.receipts.receipts.map((item, key)=>
                                                    <tr key={key}>
                                                    <td>{key+1}</td>
                                                    <td className="tx-12">{item.invoice.vehicle.plate_number}</td>
													<td>{item.invoice.invoice_id_c}</td>
													<td>{item.amount.amount_c}</td>
													<td>{item.balance_after.amount_c}</td>
												</tr>)}
												
												<tr>
													<td className="valign-middle" colSpan="2" rowSpan={record.fiscal_receipt ? 6 : 3}>
														<div className="invoice-notes">
															
															<label className="main-content-label tx-13">CASHIER SIGN.</label>
															
														</div>
													</td>
													<td className="tx-right">Total Deposit</td>
                                                    <td className="tx-right" colSpan="2">UGX.{record.amount.amount_c}</td>
												</tr>
												<tr>
													<td className="tx-right">Total Deduction</td>
													<td className="tx-right" colSpan="2">UGX.{record.receipts.totals.total.amount_c}</td>
												</tr>
												
												<tr>
													<td className="tx-right">Change</td>
													<td className="tx-right" colSpan="2">
														UGX.{record.receipts.totals.change.amount_c}
													</td>
												</tr>
												{record.white_list && <tr>
													<td className="tx-right">Balance Date</td>
													<td className="tx-right" colSpan="2">
														{record.white_list.balance_date.short_date}
													</td>
												</tr>}

												{record.fiscal_receipt && <>
													<tr>
														<td className="tx-right" rowSpan={3}>Tax</td>
														<td className="tx-right">VAT. Amount</td>
														<td className="tx-right">{record.fiscal_receipt.tax_amount}</td>
													</tr>
													<tr>
														<td className="tx-right">Net. Amount</td>
														<td className="tx-right">{record.fiscal_receipt.net_amount}</td>
													</tr>
													<tr>
														<td className="tx-right">Gross. Amount</td>
														<td className="tx-right">{record.fiscal_receipt.gross_amount}</td>
													</tr>									
												</>}
											</tbody>
										</table>
										{record.fiscal_receipt &&<div style={{display:'flex', alignContent:'center', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
											<div style={{flex:1}} id="generatedQRCode">
												{/* <QRCodeGenerator text={record.fiscal_receipt.qr_code} /> */}
												<img src={record.fiscal_receipt.qr_image} alt="Qrcode" style={{width:'100px'}}/>
											</div>
										    <div style={{flex:1}}>
												<span>FDN NO: {record.fiscal_receipt.invoice_number}</span><br/>
												{/* <span>Invoice ID: {record.fiscal_receipt.business_invoice_id}</span><br/> */}
												<span>VERIFICATION CODE: {record.fiscal_receipt.anti_fake_code}</span>

											</div>
										</div>}
									</div>
								</div>
								
                                
                                <div className="card-footer text-right">
                                   
									{/* <button type="button" className="btn ripple btn-secondary mb-1"><i className="fe fe-send mr-1"></i> Send Invoice</button> */}
									<button 
										type="button" 
										onClick={this.print}
										className="btn ripple btn-info mb-1"><i className="fe fe-printer mr-1"></i> Print</button>
								</div>
							</div>
						</div>}

                        {!record && <ActivityLoader/>}
						{this.state.info}
					</div>




            
            </div>
          
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopPaymentSummary;