import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
// import SearchBar from '../../Components/Common/SearchBar'
import ActivityLoader from '../../Components/Common/ActivityLoader'
// import RecentStickerSales from '../../Components/Finance/RecentStickerSales'
// import Toast from '../../Components/Common/Toast'
// import SelectSearch from '../../Components/Common/SelectSearch'
import StickerReceipt from '../../Components/Payments/StickerReceipt'
import { Link } from 'react-router-dom'
import NoContent from '../../Components/Common/NoContent'
import PageNav from '../../Components/Common/PageNav'
import dictionary from '../../utils/dictionary'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import TableHeader from '../../Components/Common/TableHeader'
import PopPaymentSummary from '../../Components/Payments/PopPaymentSummary'
import PopUploadSticker from '../../Components/DataMigration/PopUploadSticker'
import { RenderSecure } from '../../utils/script/RenderSecure'
import StickerContext from '../../Context/StickerContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import WarnDelete from '../../Components/WarnDelete'
import ajaxSticker from '../../utils/remote/ajaxSticker'
import Toast from '../../Components/Common/Toast'
import PopEditStickerDuration from '../../Components/Stickers/PopEditStickerDuration'
import PopEditStickerVehicle from '../../Components/Stickers/PopEditStickerVehicle'
import PopEffectStickerPayments from '../../Components/Stickers/PopEffectStickerPayments'
import PageContainer from '../../Components/Layout/PageContainer'
import StickerSaleFilter from '../../Components/Finance/Filters/StickerSaleFilter'

class StickerRecords extends React.Component {
  static contextType = StickerContext
  state = {
    userId: functions.sessionGuard(),
    vehicleInfo: false,
    plateNumber: '',
    autoVehicle: false,
    loading: false,
    home: true,
    toast: false,
    stickerTypes: false,
    stickerTypeId: '',
    pagination: false,
    currentPage: 1,
    metaData: false,
    filter: false,
    paymentView:false,
    modal:'',
    selectedId:false,
    selectedRecord:false,
    toast:false
  }

  resetToast = () => {
    this.setState({
      toast: false
    })
  }



  onClickReceipt = (record) => {
    this.setState({
      receiptView: false
    }, () => {

      this.setState({
        receiptView: <StickerReceipt
          receipt={record}
        />
      }, () => {
        functions.openPopup("pos_receipt");
      })

    })

  }



  onClickUpload=()=>{
      this.setState({
        modal:<PopUploadSticker/>
      }, ()=>functions.openPopup("modal_vehicle_stickers"))
  }



  onClickDelete = () => {
    if (this.state.selectedId) {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                 modal: <WarnDelete
                    title="Delete Sticker?"
                    description="You are about to delete this sticker sale record from the system. All payments made using this sticker record will be deleted."
                    onConfirm={() => this.onConfirmDelete()} />
            }, () => {
                functions.openPopup("modal_delete")
            })
        })


    }
  }



  onClickChangeDuration = () => {
    if (this.state.selectedId) {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                 modal: <PopEditStickerDuration record={this.state.selectedRecord}/>
            }, () => {
                functions.openPopup("modal_sticker_duration")
            })
        })


    }
  }


  onClickChangeVehicle = () => {
    if (this.state.selectedId) {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                 modal: <PopEditStickerVehicle record={this.state.selectedRecord}/>
            }, () => {
                functions.openPopup("modal-sticker-vehicle")
            })
        })


    }
  }


  onClickEffectPayment = () => {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                 modal: <PopEffectStickerPayments/>
            }, () => {
                functions.openPopup("modal-effect-sticker")
            })
        })
  }



  onConfirmDelete=async()=>{
      const server_response = await ajaxSticker.deleteSticker(this.state.selectedId);
      functions.closePopup("modal_delete")
      if(server_response.status==="OK")
      {
        this.setState({
          toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
        })
      }else{
        this.setState({
          toast: <Toast message={server_response.details.message} type={4} onClose={this.refreshToast} />
        })
      }
  }


  refreshToast = () => {
    this.setState({
        toast: false
    })
}


  buttons = () => {
    return (

        <div className="btn btn-list">
                
                  <RenderSecure code="C-STICKER-IMPORT-01">
                  <button className="btn ripple btn-success" onClick={this.onClickUpload}>
                    <i className="fa fa-upload"></i> Import Stickers
                  </button>
                </RenderSecure>
                 
                 {Array.isArray(this.context.stickerListing) && <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                  </a>}


                  <a className="btn ripple btn-primary dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
                  </a>

                  <div className="dropdown-menu tx-13">
                      <Link to="#" className="dropdown-item" onClick={this.context.getStickers}>
                        <i className="fa fa-redo text-primary mr-1"></i>Refresh</Link>
                        
                      <Link to="#" className="dropdown-item" onClick={this.onClickEffectPayment} title="Settle outstanding parking invoices using active sticker">
                        <i className="fa fa-sync text-success mr-1"></i>Effect Payment</Link>
                      {this.state.selectedId && <>
                      
                        <RenderSecure code="U-STICKER-03">
                          <Link to="#" className="dropdown-item" onClick={this.onClickChangeVehicle}>
                          <i className="fa fa-pencil-alt text-secondary mr-1"></i>Change Vehicle</Link>
                        </RenderSecure>
                      
                        <RenderSecure code="U-STICKER-04">
                          <Link to="#" className="dropdown-item" onClick={this.onClickChangeDuration}>
                          <i className="fa fa-calendar-alt text-primary mr-1"></i>Change Validity</Link>
                        </RenderSecure>
                       
                        <RenderSecure code="D-STICKER-04">
                          <Link to="#" className="dropdown-item" onClick={this.onClickDelete}>
                              <i className="fa fa-trash-alt text-danger mr-1"></i>Delete Sticker
                          </Link>
                        </RenderSecure>
                       
                      </>}
                    
                  </div>

           </div>
    )
  }



  onClickPayment = (deposit_ref) => {
    this.setState({
        paymentView: false
    }, () => {

        this.setState({
            paymentView: <PopPaymentSummary deposit_ref={deposit_ref} />
        }, () => {
            functions.openPopup("modal_payment_summary");
        })
    })

}




onSelectRecord=(selectedId, selectedRecord)=>{
  this.setState({
    selectedRecord,
    selectedId
  })
}


  render() {
    const transactionList = this.context.stickerListing;
    const stickerOptions = [];
    Array.isArray(this.context.stickerTypeList) && this.context.stickerTypeList.map((item, key) =>
      stickerOptions.push({ title: item.ref_name, value: item.sticker_type_id })
    );

    return (<PageContainer>

         {this.state.toast}
            <PageHeader
              title="Sticker Sales"
              previousPageTitle="Finance"
              back="/finance"
              buttons={<this.buttons />}
            />
            {this.state.toast}
            {this.state.modal}
            {this.state.paymentView}


            {/* <CustomizedFilterBar
              selection={{ title: 'Sticker Type', options: stickerOptions }}
              keyword="Plate Number"
              onReset={this.context.resetData}
              onConfirm={this.context.filterData}
            /> */}
            <StickerSaleFilter />



            <div className="row">
              {this.state.receiptView}
              <div className="col-sm-12 col-xl-12 col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">

                    {/* <div>
                      <h6 className="card-title mb-1">Parking Sticker Sales</h6>
                      <p>Parking sticker sale records</p>
                    </div> */}

                    <TableHeader
                      title={"Parking Sticker Sales"}
                      subtitle="Parking sticker sale records"
                    />

                      {Array.isArray(transactionList) &&
                              <LoadMoreButton
                                  action={this.context.goToNextPage}
                                  loading={this.context.loading}
                                  title={this.context.metaData.current_page + " of " + this.context.metaData.total_pages}
                                  />
                              }

                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                      <div className="table-responsive">

                        {Array.isArray(transactionList) &&
                          <table id="table-stickers" className="table table-hover text-nowrap mb-0 datatable" export-format={dictionary._exportBtnFormats}>
                            <thead>
                              <tr>
                                <th>Purchase Date</th>
                                <th>Serial No.</th>
                                <th>Vehicle</th>
                                <th>Sticker Type</th>
                                <th>Effective Date</th>
                                <th>Expiry Date</th>
                                <th>Amount Paid(UGX)</th>

                                <th>Deposit Ref</th>
                                <th>Payment Mode</th>
                                <th>Issued By</th>
                                <th>Batch</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactionList.map((item, key) =>
                                <tr 
                                  key={key} 
                                  onClick={()=>this.onSelectRecord(item.invoice_id, item)}
                                  className={`${item.invoice_id===this.state.selectedId?"flag-row":""}`}
                                  >
                                  <td>{item.created_at.long_date}</td>
                                  <td>
                                    <Link to="#" onClick={() => this.onClickReceipt(item)}>
                                      {item.sale_id_c}
                                    </Link>
                                  </td>
                                  <td className="tx-bold">
                                    <Link to={
                                      {
                                        pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                        state: { plateNumber: item.vehicle.plate_number }
                                      }}>{item.vehicle.plate_number}
                                    </Link>
                                  </td>
                                  <td>{item.sticker_type.ref_name}</td>
                                  <td>{item.effective_date.short_date}</td>
                                  <td>{item.expiry_date.short_date}</td>
                                  <td>{item.amount.amount_c}</td>
                                  <td>
                                    <Link to="#" onClick={() => this.onClickPayment(item.deposit_ref_id)}>
                                          {item.deposit_ref?item.deposit_ref.ref:"N/A"}
                                    </Link>
              
                                  </td>
                                  <td>{item.deposit_ref?item.deposit_ref.payment_mode.mode_name:'-'}</td>
                                  <td>{item.issued_by?item.issued_by.last_name + ' ' + item.issued_by.first_name: '--'}</td>
                                  <td>{item.batch ? item.batch.batch_name : 'N/A'}</td>
                                </tr>)}
                            </tbody>
                          </table>}
                        {transactionList === "404" && <NoContent />}
                        {!transactionList && <ActivityLoader />}
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

      </PageContainer>
    );

  }
}

export default StickerRecords
