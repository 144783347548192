import React from 'react';


class CountButton extends React.Component {


    render() {

        return (
            <button type="button"
                className={`btn ripple btn-sm ${this.props.btnColor}`}
                onClick={() => this.props.getCountData}>
                {this.props.title} <span className="badge badge-light ml-2 font-weight-bold">{this.props.count}</span>
            </button>
        );


    }
}

export default CountButton;