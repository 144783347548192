import React from 'react'

class LoadingPage extends React.Component {

    state = {
      progress:10
    }


    componentDidMount() {
       setInterval(() => {
           if(this.state.progress<90){
                this.setState({
                    progress:this.state.progress + 10
                })
            }
       }, 1000);
    }

    logos = () => {
        return (
            <div className="main-logo">
                <img src={process.env.PUBLIC_URL + "/images/logo-normal.svg"} className="header-brand-img desktop-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/parking_p.png"} className="header-brand-img icon-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/logo-white.svg"} className="header-brand-img desktop-logo theme-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/parking_f.png"} className="header-brand-img icon-logo theme-logo" alt="logo" />
            </div>
        )
    }


    render() {

        return (

            <div className="page">
                {/* <div className="main-content"> */}
                    <div className="container-fluid">

                        <div 
                            style={
                                {
                                    display:"flex", justifyContent:"center", alignContent:"center", alignItems:"center", flexDirection:"column",
                                    width:"25%",
                                    margin:"auto",
                                    height:"100vh"
                                }
                            }
                        >
                            <this.logos />

                            <div className="progress mg-b-10 my-5" style={{width:"100%"}}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar" 
                                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.progress + "%"}}>{this.state.progress}%
                                </div>
                            </div>
                            <div><b>Loading PMIS...</b></div>

                        </div>
                       
                    </div>
                {/* </div> */}
            
            </div>

        );

    }
}

export default LoadingPage;
