import React, { useContext, useState } from 'react';
import AlertError from '../../../Common/AlertError';
import TextInput from '../../../Common/TextInput';
import AlertSuccess from '../../../Common/AlertSuccess';
import functions from '../../../../utils/functions';
import ThemeContext from '../../../../Context/ThemeContext';
import FiscalReceiptContext from '../../../../Context/Efris/FiscalReceiptContext';
import ajaxEfris from '../../../../utils/remote/ajaxEfris';
import ActivityLoader from '../../../Common/ActivityLoader';
import ajaxEfrisHistory from '../../../../utils/remote/ajaxEfrisHistory';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';


const PopSetDailyTarget=(props)=> {

    
        const [info, setInfo] = useState(false);
        const [amount, setAmount] = useState('');

        const {setDisplayMessage} = useContext(ThemeContext);

        const { refreshTax } = useContext(EfrisHistoryContext);
       
        const [loading, setLoading] = useState(false);


        const onConfirm = async (event) => {
            event.preventDefault();
            if (amount > 0) {
                    setLoading(true)
                    const server_response = await ajaxEfrisHistory.postDailyTarget(amount);
                    setLoading(false)
                    if(server_response.status === "OK"){
                        setDisplayMessage(functions.templateMessage("EFRIS Config:", server_response.message, "info"))
                        setInfo(<AlertSuccess message={server_response.message} />)
                        functions.closePopup("modal-daily-target");
                        refreshTax();
                    }else{
                        setDisplayMessage(functions.templateMessage("EFRIS Config:", server_response.message, "danger"))
                        setInfo(<AlertError message={server_response.message} />)
                    }

             

            } else {
                setInfo(<AlertError message={"Enter amount, then try again."} />)
            }
        }



    
        return (
            <div className="modal" id="modal-daily-target">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Set Daily Target</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {info}
                            <form method="post" onSubmit={onConfirm}>

                      
                                <TextInput
                                    type="text"
                                    label="Amount"
                                    onChange={(e) => setAmount(e.target.value)}
                                    placeholder={"Enter daily target"}
                                    name="target_amount"
                                    value={amount}
                                    required
                                />
                                

                            </form>

                        
                            <p className='small'><b>NOTE:</b> Set how much you expect to remit to URA today.</p>

                        </div>
                        <div className="modal-footer">
                            {!loading && <>
                                <button className="btn ripple btn-success" type="button" onClick={onConfirm}>Save Target</button>
                                <button className="btn ripple btn-light" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {loading && <ActivityLoader/>}
                        </div>
                    </div>


                </div>
            </div>
        )


    }

export default PopSetDailyTarget;
