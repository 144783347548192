import React from 'react';
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import ActivityLoader from './Common/ActivityLoader';
import AlertError from './Common/AlertError';
import AlertSuccess from './Common/AlertSuccess';
import TextInputWrap from './Common/TextInputWrap';


class WarnSecureDelete extends React.Component {
    state = {
        buttonLabel: this.props.buttonLabel || "Confirm Delete",
        loading: false,
        password:'',
        info:false,
        buttonType:this.props.buttonType || 'btn-danger'
    }


  
    onChangePassword=(event)=>{
        this.setState({
            password:event.target.value
        })
      }
    
      onConfirm=async(event)=>{
          event.preventDefault();
          const {password }=this.state;
          if(password.length>0)
          {  

            this.setState({
                loading: true
            }, async() => {
                const server_response=await ajax.verifyUserPassword(this.state.password);
                if(server_response.status=="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details.message}/>
                    })
                    functions.closePopup("modal_secure_delete");
                    this.props.onConfirm(password)

                }else
                {
                    this.setState({
                        loading:false,
                        info:<AlertError message={server_response.details.message}/>
                    })
                }
            })
           
          }else{
            this.setState({
                info:<AlertError message="Enter your account password"/>
            })
          }
        }


    render() {

        return (
            <div className="modal" id={this.props.id || "modal_secure_delete"}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content tx-size-sm">
                        <div className="modal-body tx-center pd-y-20 pd-x-20">
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                                <span aria-hidden="true">&times;</span></button>
                            <i className={`icon icon ${this.props.icon || 'ion-ios-close-circle-outline'} tx-100 tx-danger lh-1 mg-t-20 d-inline-block`}></i>
                            <h4 className="tx-danger mg-b-20">{this.props.title}</h4>
                            <p className="mg-b-20 mg-x-20 tx-bold">{this.props.description}</p>


                        {this.state.info}
                        <form method="post" onSubmit={this.onConfirm}>
                        <TextInputWrap 
                            type="password" 
                            onChange={()=>this.onChangePassword}
                            placeholder={"Enter your account password"}
                            autoFocus={true}
                            />

                            {!this.state.loading && <button
                                className={`btn ripple ${this.state.buttonType} pd-x-25`}
                                type="button"
                                onClick={this.onConfirm}>{this.state.buttonLabel}</button>
                            }

                         {this.state.loading && <ActivityLoader/>}
                    </form>



                           

                        </div>
                    </div>
                </div>
            </div>
        )


    }
}

export default WarnSecureDelete;
