const LiveTile=(props)=>{

    return <div className="card custom-card">
            <div className="card-body">
                <div>
                    <h6>{props.title}</h6>
                    <h6 className='d-flex align-items-center'>
                        <span className="fs-30 mr-2">{props.value}</span>
                        <span className={`badge badge-${props.theme ||'success'}`}>{props.comment || 'Normal'}</span>
                    </h6>
                    <span className="text-muted">{props.description}</span>
                </div>
            </div>
        </div>
}

export default LiveTile