import React from 'react';
import ajax from '../../utils/ajax';
import Filter from '../Common/Filter';

class RoleFilters extends React.Component {

    state = {
        roleList: false

    }
    componentDidMount() {
        this.getRoles()
    }

    getRoles = async () => {
        const server_response = await ajax.getRoles();
        if (server_response.status === "OK") {
            this.setState({
                roleList: server_response.details.content.list
            })
        }
    }



    render() {
        const roleList = this.state.roleList;
        return (
            <div className="text-wrap mt-3">
                <div className="">
                    <div className="btn-list">
                        {/* <Filter
                            type={3}
                            // key={"key"}
                            title={"All Users"}
                            onClick={() => this.props.onClick(false)} 
                        />

                        <Filter
                            type={0}
                            // key={"key"}
                            title={"Conductors"}
                            onClick={() => this.props.onClick(false)} 
                        />

                        <Filter
                            type={2}
                            // key={"key"}
                            title={"Cobras"}
                            onClick={() => this.props.onClick(false)} 
                        />

                        <Filter
                            type={5}
                            // key={"key"}
                            title={"Zulus"}
                            onClick={() => this.props.onClick(false)} 
                        />

                        <Filter
                            type={6}
                            // key={"key"}
                            title={"Supervisors"}
                            onClick={() => this.props.onClick(false)} 
                        /> */}

                        {/* {roleList && roleList.map((item, key) =>
                            <Filter
                                type={key}
                                title={item.role_name}
                                key={key}
                                onClick={() => this.props.onClick(item)} 
                            />
                        )} */}


                    </div>
                </div>
            </div>
        )


    }
}

export default RoleFilters;
