import React, { useEffect, useState } from "react";
import ajax from "../utils/ajax";
import functions from "../utils/functions";
import ajaxReservation from "../utils/remote/ajaxReservation";

const ReservationContext = React.createContext();

export const ReservationConsumer = ReservationContext.Consumer;


export const ReservationProvider=(props)=>{

  
    const [currentPage, setCurrentPage] = useState(1);
    const [reservationListing, setReservationListing] = useState(false);
    const [metaReservationList, setMetaReservationList] = useState(false);
    const [outstandingInvoices, setOutstandingInvoices] = useState(false);
    const [annualReserationRevenue, setAnnualReserationRevenue] = useState("...");
    const [companyList, setCompanyList ] = useState(false);
    
    useEffect(()=>{
        listResersavations();
        listOutstandingInvoices();
        getAnnualReservationRevenu();
        listCompanies();
    }, [])
  

    const listResersavations = async () => {
        

        const server_response = await ajax.listResersavations(currentPage);

        if (server_response.status === "OK") {
            setReservationListing(server_response.details.content.list);
            setMetaReservationList(server_response.details.content.meta)
        } else {
            setReservationListing("404")
        }


    }


    const listOutstandingInvoices = async () => {

        const server_response = await ajaxReservation.getOutstandingInvoices();

        if (server_response.status === "OK") {
            setOutstandingInvoices(server_response.details.content);
        } else {
            setOutstandingInvoices("404")
        }


    }


    const getAnnualReservationRevenu = async () => {
        

        const server_response = await ajaxReservation.getAnnualReservation();

        if (server_response.status === "OK") {
            setAnnualReserationRevenue(server_response.details.content.amount_p);
        } 


    }

    const listCompanies = async () => {
        const server_response = await ajax.listAllOrganisations()
        if (server_response.status === "OK") {
            setCompanyList(server_response.details.content.list)
        } else {
           
            setCompanyList('404')
        }
    }


    return (
        <ReservationContext.Provider value={{
            reservationListing,
            outstandingInvoices,
            annualReserationRevenue,
            companyList,
            listResersavations,
            listOutstandingInvoices,
            listCompanies
         }}
         >
            {props.children}
        </ReservationContext.Provider>
     );


}

export default ReservationContext;