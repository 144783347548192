import React from 'react';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import TopBar from '../../Components/Common/TopBar';
import RightSideBar from '../../Components/Common/RightSideBar';
import Footer from '../../Components/Common/Footer';
import PageHeader from '../../Components/Common/PageHeader';
import functions from '../../utils/functions';
import ajax from '../../utils/ajax';
import TextInput from '../../Components/Common/TextInput';
import { Link } from 'react-router-dom';
// import profileHolder from '../../assets/faces/face6.jpg';


const profileStyles = {
    display: 'none'
};

const profileNewStyles = {
    display: 'block'
};

class Profile extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        firstName: '',
        lastName: '',
        username: '',
        photo: '',
        roleName: '',
        phoneNumber: '',
        email: ''
    }

    componentDidMount() {
        this.getUser();
    }


    getUser = async () => {
        const server_response = await ajax.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                firstName: server_response.details.content.first_name,
                lastName: server_response.details.content.last_name,
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                phoneNumber: server_response.details.content.phone_number,
                photo: server_response.details.content.profile_photo.file_path,
                email: server_response.details.content.email

            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }

    render() {

        return (
            <div className="page">

                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Personal Profile"
                            previousPageTitle="Manage users"
                            back="/users"
                            // options={{
                            //     "add": () => this.onClickAdd(),
                            //     "edit": () => this.onClickEdit(),
                            //     "delete": () => this.onClickDelete()
                            // }}
                        />


                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="card custom-card">
                                    <div className="card-body text-center">
                                        <div className="main-profile-overview widget-user-image text-center mt-1">
                                            <div className="main-img-user"><img alt="avatar" src={this.state.photo} /></div>
                                        </div>
                                        <div className="item-user pro-user">
                                            <h4 className="pro-user-username text-dark mt-4 mb-1">{this.state.firstName} {this.state.lastName}</h4>
                                            <p className="pro-user-desc text-muted mb-3">{this.state.roleName}</p>
                                            <p className="user-info-rating">
                                                <Link href="#"><i className="fa fa-star text-warning"> </i></Link>
                                                <Link href="#"><i className="fa fa-star text-warning"> </i></Link>
                                                <Link href="#"><i className="fa fa-star text-warning"> </i></Link>
                                                <Link href="#"><i className="fa fa-star text-warning"> </i></Link>
                                                <Link href="#"><i className="far fa-star text-warning"> </i></Link>
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="card-footer p-0">
                                        <div className="row text-center">
                                            <div className="col-sm-12">
                                                <div className="description-block">
                                                    <h5 className="description-header mb-1">689k</h5>
                                                    <span className="text-muted">Followers</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="card custom-card">
                                    <div className="card-header custom-card-header">
                                        <div>
                                            <h6 className="card-title mb-0">Contact Information</h6>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="main-profile-contact-list main-profile-work-list">
                                            <div className="media">
                                                <div className="media-logo bg-light text-dark">
                                                    <i className="fe fe-smartphone"></i>
                                                </div>
                                                <div className="media-body">
                                                    <span>Mobile</span>
                                                    <div>{this.state.phoneNumber}</div>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="media-logo bg-light text-dark">
                                                    <i className="fe fe-mail"></i>
                                                </div>
                                                <div className="media-body">
                                                    <span>Username</span>
                                                    <div>{this.state.username}</div>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="media-logo bg-light text-dark">
                                                    <i className="fe fe-map-pin"></i>
                                                </div>
                                                <div className="media-body">
                                                    <span>Current Address</span>
                                                    <div>Kampala, Uganda</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="card custom-card">
                                    <div className="card-header custom-card-header">
                                        <div>
                                            <h6 className="card-title mb-0">User Permissions</h6>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="skill-tags">
                                            <ul className="list-unstyled mb-0">
                                                <li><a href="#">HTML5</a></li>
                                                <li><a href="#">CSS</a></li>
                                                <li><a href="#">Java Script</a></li>
                                                <li><a href="#">Photo Shop</a></li>
                                                <li><a href="#">Php</a></li>
                                                <li><a href="#">Wordpress</a></li>
                                                <li><a href="#">Sass</a></li>
                                                <li><a href="#">Angular</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="card custom-card main-content-body-profile">
                                    <nav className="nav main-nav-line">
                                        <a className="nav-link active" data-toggle="tab" href="#tab1over">Overview</a>
                                        <a className="nav-link" data-toggle="tab" href="#tab2rev">Update</a>
                                        {/* <a className="nav-link" data-toggle="tab" href="#tab2pass">Password</a> */}
                                        <a className="nav-link" data-toggle="tab" href="#tab2pic">Profile photo</a>
                                    </nav>
                                    <div className="card-body tab-content h-100">

                                        <div className="tab-pane active" id="tab1over">
                                            <div className="main-content-label tx-13 mg-b-20">
                                                Personal Information
										    </div>
                                            <div className="table-responsive ">
                                                <table className="table row table-borderless">
                                                    <tbody className="col-lg-12 col-xl-6 p-0">
                                                        <tr>
                                                            <td><strong>Full Name :</strong> {this.state.firstName} {this.state.lastName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Location :</strong> Kampala, Uganda</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody className="col-lg-12 col-xl-6 p-0">
                                                        <tr>
                                                            <td><strong>Email :</strong> {this.state.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Phone :</strong> +{this.state.phoneNumber}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="main-content-label tx-13 mg-b-20 mt-3">
                                                About
										    </div>
                                            <p>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy  when an unknown printer took a galley of type and scrambled Lorem Ipsum has been the industry's standard dummy  when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived .</p>
                                            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit Lorem Ipsum has been the industry's standard dummy  when an unknown printer took a galley of type and scrambled in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                                        </div>
                                        
                                        <div className="tab-pane" id="tab2rev">
                                            <div className="card-body">
                                                {this.state.info}
                                                <form method="post" onSubmit={this.onSubmit}>

                                                    <TextInput
                                                        label="First Name"
                                                        type="text"
                                                        onChange={() => this.onChangeFirstName}
                                                        value={this.state.firstName}
                                                    />

                                                    <TextInput
                                                        label="Last Name"
                                                        type="text"
                                                        onChange={() => this.onChangeLastName}
                                                        value={this.state.lastName}
                                                    />

                                                    <TextInput
                                                        label="Username"
                                                        type="text"
                                                        onChange={() => this.onChangeUserName}
                                                        value={this.state.userName}
                                                    />

                                                    <TextInput
                                                        label="Phone Number"
                                                        type="number"
                                                        onChange={() => this.onChangePhoneNumber}
                                                        value={this.state.phoneNumber}
                                                    />

                                                    <TextInput
                                                        label="Email Address"
                                                        type="email"
                                                        onChange={() => this.onChangeEmail}
                                                        value={this.state.email}
                                                    />

                                                    <TextInput
                                                        label="Location"
                                                        type="text"
                                                        onChange={() => this.onChangeEmail}
                                                        value={this.state.email}
                                                    />

                                                    <TextInput
                                                        label="About"
                                                        type="textarea"
                                                        onChange={() => this.onChangeEmail}
                                                        value={this.state.email}
                                                    />

                                                    {/* <SelectSearch
                                                        label="Select Role"
                                                        onChange={() => this.onChangeRole}
                                                        options={optRoles}
                                                        placeholder={"Select Role"}
                                                    /> */}

                                                    {/* <ImageUpload
                                                        label="Upload profile picture"
                                                        id="fileToUpload"
                                                        name="fileToUpload"
                                                        onChange={() => this.onChangeImage}
                                                    /> */}

                                                    <div className="modal-footer">
                                                        <input
                                                            className="btn ripple btn-success"
                                                            type="button"
                                                            value="Update"
                                                            onClick={this.onSubmit}
                                                        />

                                                        <input
                                                            className="btn ripple btn-danger"
                                                            type="button"
                                                            value="Cancel"
                                                            onClick={this.onSubmit}
                                                        />
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                        
                                        <div className="tab-pane" id="tab2pic">
                                            <div className="card-body">
                                                <div className="tab-pane fade active show" id="avatar" role="tabpanel" aria-labelledby="avatar-tab">
                                                    <div className="wrapper mb-5 mt-4">
                                                        <span className="badge badge-warning text-white">Note : </span>
                                                        <p className="d-inline ml-3 text-muted">Image size is limited to not greater than 1MB .</p>
                                                    </div>
                                                    <form action="#">
                                                        <div className="dropify-wrapper has-preview">
                                                            <div className="dropify-message">
                                                                <span className="file-icon"></span>
                                                                <p>Drag and drop a file here or click</p>
                                                                <p className="dropify-error">Ooops, something wrong appended.</p>
                                                            </div>
                                                            <div className="dropify-loader" style={profileStyles}></div>
                                                            <div className="dropify-errors-container">
                                                                <ul></ul>
                                                            </div>
                                                            <input type="file" className="dropify" data-max-file-size="1mb" data-default-file={this.state.photo} />
                                                            <button type="button" className="dropify-clear">Remove</button>
                                                            <div className="dropify-preview" style={profileNewStyles}>
                                                                <span className="dropify-render">
                                                                    <img src={this.state.photo} />
                                                                </span>
                                                                <div className="dropify-infos">
                                                                    <div className="dropify-infos-inner">
                                                                        <p className="dropify-filename">
                                                                            <span className="file-icon"></span>
                                                                            <span className="dropify-filename-inner">face6.jpg</span>
                                                                        </p>
                                                                        <p className="dropify-infos-message">Drag and drop or click to replace</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mt-5">
                                                            <button type="submit" className="btn btn-success mr-2">Update</button>
                                                            <button className="btn btn-outline-danger">Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default Profile
