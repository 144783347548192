import React, { useEffect, useState } from 'react';
import AlertError from '../Components/Common/AlertError';
import ajax from '../utils/ajax';
import ajaxPayment from '../utils/remote/ajaxPayment';
import { detectTable, restoreTable } from '../utils/script/customTables';

const PaymentContext = React.createContext();

export const PaymentConsumer = PaymentContext.Consumer;

export const PaymentProvider = (props) => {

    const [paymentRecords, setPaymentRecords] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentMethods, setPaymentMethods] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [metaData, setMetaData] = useState(false);
    const [depositRef, setDepositRef] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [plateNumber, setPlateNumber] = useState(null);
    const [cashierListing, setCashierListing] = useState(false)
    const [data, setData] = useState({ "page": "1" })

    useEffect(() => {
        getPaymentRecords();
    }, [data])

    useEffect(() => {
        if (currentPage > 1) {
            fetchMoreTrigger()
        }
    }, [currentPage])



    useEffect(() => {
        getPaymentMethods();
        getCashiers();
    }, [])



    // useEffect(()=>{
    //     restoreTable("table-payments");
    //     getPaymentRecords(true);
    // }, [paymentMethod])

    // useEffect(()=>{ //Resetting filter
    //     restoreTable("table-payments");
    //     if(plateNumber==null && dateFrom==null && dateTo==null)
    //     {
    //         getPaymentRecords(true);
    //     }

    // }, [plateNumber, dateFrom, dateTo])


    // useEffect(()=>{ //Filtering payment records
    //     restoreTable("table-payments");
    //     if(plateNumber!==null && dateFrom!==null && dateTo!==null && currentPage===1)
    //     {       
    //         getPaymentRecords(true);
    //     }

    // }, [plateNumber, dateFrom, dateTo])


    const getPaymentRecords = async (init) => {


        setPaymentRecords(false)
        setCurrentPage(1);

        const server_response = await ajaxPayment.getPaymentRecords(data);

        if (server_response.status === "OK") {
            setPaymentRecords(server_response.details.content.list)
            setMetaData(server_response.details.content.meta);

        } else {
            // setInfo(<AlertError type="info" message={ server_response.details.message }/>)
            setPaymentRecords("404")
            setMetaData(false)
        }


    }


    const getPaymentMethods = async () => {

        const server_response = await ajax.getAllPaymentModes();
        if (server_response.status === "OK") {
            setPaymentMethods(server_response.details.content)
        }
    }




    const getCashiers = async () => {

        const server_response = await ajaxPayment.fetchCashiers();
        if (server_response.status === "OK") {
            setCashierListing(server_response.details.content)
        }
    }



    // const fetchMore=()=>{

    //     setCurrentPage(currentPage+1);
    //     setLoading(true);

    //     setTimeout(async() => {

    //         const data ={

    //             "page": currentPage,
    //             "payment_method": paymentMethod,
    //             "created_from": dateFrom,
    //             "created_to": dateTo,
    //             "plate_number": plateNumber

    //         }

    //         const server_response = await ajaxPayment.getPaymentRecords(data);

    //         setLoading(false);
    //         restoreTable(detectTable());

    //         if (server_response.status === "OK") {

    //           const incoming = Array.from(server_response.details.content.list);
    //           setPaymentRecords(paymentRecords.concat(incoming));
    //           setMetaData(server_response.details.content.meta);

    //         }


    //     }, 10);




    // }


    // const filterRecords=(data)=>{
    //         setCurrentPage(1);
    //         setPlateNumber(data.keyword);
    //         setDateFrom(data.dateFrom);
    //         setDateTo(data.dateTo);
    // }

    // const resetFilter=()=>{
    //     setCurrentPage(1);
    //     setPlateNumber(null);
    //     setDateFrom(null);
    //     setDateTo(null);

    // }



    const fetchMoreTrigger = () => {
        const newData = {
            ...data,
            "page": currentPage,
        }
        fetchMore(newData)
    }


    const fetchMore = async (newData) => {
        setLoading(true);
        restoreTable(detectTable());

        const server_response = await ajaxPayment.getPaymentRecords(newData);

        setLoading(false);


        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            setPaymentRecords(paymentRecords.concat(incoming));
            setMetaData(server_response.details.content.meta);
        } else {
            // setMetaData(false)
            // setPaymentRecords(false)
        }

    }


    const goToNextPage = () => {
        if (currentPage < metaData.total_pages) {
            setCurrentPage(currentPage + 1);
        }
    }


    const resetData = () => {
        setCurrentPage(1)
        setData({ "page": "1" })
    }

    const filterData = (options) => {
        const newData = {
            ...data,
            "date_from": options.dateFrom,
            "date_to": options.dateTo,
            "cashier_id": options.cashier,
            "page": "1",
            "plate_number": options.plateNumber
        }
        setData(newData)

    }

    const selectPaymentMethod = (method) => {
        setCurrentPage(1);
        const newData = {
            ...data,
            "page": 1,
            "payment_method": method
        }

        setData(newData)

    }


    return (
        <PaymentContext.Provider
            value={{
                paymentRecords,
                paymentMethods,
                info,
                loading,
                cashierListing,
                metaData,
                getPaymentRecords,
                setPaymentMethod,
                fetchMore,
                setPlateNumber,
                selectPaymentMethod,
                resetData,
                filterData,
                goToNextPage

            }}
        >
            {props.children}
        </PaymentContext.Provider>
    );
}

export default PaymentContext;