import apiCall from "./apiCall";


export default {


    async fetchReceiptRecords(data)
    {
        let response = await apiCall("finance/tax/efrisreceipt/list_receipts", data)
        return response;

    },

    async fetchLastMonthTax()
    {
        let response = await apiCall("finance/tax/efrisreceipt/last_month_tax", [])
        return response;

    },

    async fetchCurrentMonthTax()
    {
        let response = await apiCall("finance/tax/efrisreceipt/current_month_tax", [])
        return response;

    },

    async fetchDailyTax()
    {
        let response = await apiCall("finance/tax/efrisreceipt/daily_tax", [])
        return response;

    },

    async fetchLastMonthCreditNote()
    {
        let response = await apiCall("finance/tax/efrisreceipt/last_month_credit_note", [])
        return response;

    },

    async fetchCurrentMonthCreditNote()
    {
        let response = await apiCall("finance/tax/efrisreceipt/current_month_credit_note", [])
        return response;

    },
    
    async createCreditNote(deposit_id)
    {
        const data = {
            deposit_id : deposit_id
        }
        let response = await apiCall("finance/tax/efrisreceipt/request_refund", data)
        return response;

    },

    async cancelCreditNote(deposit_id)
    {
        const data = {
            deposit_id : deposit_id
        }
        let response = await apiCall("finance/tax/efrisreceipt/cancel_credit_note", data)
        return response;

    },

    async postTaxProfileUpdate(data)
    {
        let response = await apiCall("client/client/update_tax_profile", data)
        return response;

    },

}