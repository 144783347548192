import React, { useContext } from 'react'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import TableHeader from '../../Components/Common/TableHeader'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import NoContent from '../../Components/Common/NoContent'
import PageContainer from '../../Components/Layout/PageContainer'
import { RenderSecure } from '../../utils/script/RenderSecure'
import WarnSecureDelete from '../../Components/WarnSecureDelete'
import ajaxSession from '../../utils/remote/ajaxSession'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import AlertError from '../../Components/Common/AlertError'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import PaymentMigrationContext from '../../Context/Migration/PaymentMigrationContext'
import PopUploadPaymentData from '../../Components/DataMigration/PopUploadPaymentData'
import ajaxPayment from '../../utils/remote/ajaxPayment'



const exportFormats = ['copy', 'excel', 'csv', 'pdf', 'print']

class ImportPayments extends React.Component {
    static contextType = PaymentMigrationContext;

    state = {
       modal:false,
       info:'',
       selectedId:false,
       selectedItem:false
    }


    onClickUpload=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<PopUploadPaymentData/>
            }, ()=>functions.openPopup("modal_import_payments"))

        })
      
    }
    hideInfo=()=>{
        // setTimeout(() => {
        //     this.setState({info:''})
        // }, 5000);
   }


    onClickApprove=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<WarnSecureDelete
                id="modal_approve_payment" 
                title="Approve Batch Payments"
                description="Confirm with your account password that you want to approve pending batch payment records; Respective outstanding vehicle balances will be reduced."
                icon="fas fa-question"
                buttonLabel={"Confirm Payments"}
                buttonType="btn-success"
                onConfirm={this.confirMigrations}
                />
            }, ()=>functions.openPopup("modal_approve_payment"))
        })
    }


    confirMigrations=async(password)=>{
        this.setState({info:''})
        const server_response = await ajaxPayment.approveMigratedPayments(password);
        functions.closePopup("modal_approve_payment");
        if(server_response.status==="OK")
        {  
            this.setState({info:<AlertSuccess message={server_response.details.message}/>}, ()=>this.hideInfo())          
        }else{
           this.setState({info:<AlertError message={server_response.details.message}/>}, ()=>this.hideInfo())          
        }
    }


    onClickDelete=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<WarnSecureDelete
                id="modal_delete_payment" 
                title="Delete Batch Payment"
                description="Confirm with your account password that you want to delete the selected payment record; Record cannot be deleted if already approved."
                icon="fas fa-question"
                buttonLabel={"Confirm Delete"}
                buttonType="btn-danger"
                onConfirm={this.confirmDelete}
                />
            }, ()=>functions.openPopup("modal_delete_payment"))
        })
    }


    confirmDelete=async(password)=>{
        this.setState({info:''})
        const server_response = await ajaxPayment.deleteMigratedPayment(password, this.state.selectedId);
        functions.closePopup("modal_delete_payment");
        if(server_response.status==="OK")
        {  
            this.setState({info:<AlertSuccess message={server_response.details.message}/>}, ()=>this.hideInfo())          
        }else{
           this.setState({info:<AlertError message={server_response.details.message}/>}, ()=>this.hideInfo())          
        }
    }


    onSelectRow=(id, item)=>{
        this.setState({
            selectedId:id,
            selectedItem:item
        })
    }
  

    buttons = () => {
        return (
            <>

            <div className="btn btn-list">

               <RenderSecure code="C-BATCH-PAYMENT-01">
                    <button className="btn ripple btn-primary" onClick={this.onClickUpload}>
                        <i className="fa fa-plus mr-2"></i>New Upload
                    </button>
                </RenderSecure>


              <a className="btn ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="fa fa-cog mr-1"></i> Options <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                  <Link to="#" className="dropdown-item" onClick={this.context.getMigrations}>
                    <i className="fa fa-redo text-success mr-1"></i>Refresh Records</Link>
                    <RenderSecure code="U-BATCH-PAYMENT-01">
                        <Link to="#" className="dropdown-item" onClick={this.onClickApprove}>
                        <i className="fa fa-check text-danger mr-1"></i>Approve Bulky Payments</Link>
                    </RenderSecure>


                    {this.state.selectedItem && this.state.selectedItem.status==="PENDING" && <RenderSecure code="U-BATCH-PAYMENT-01">
                        <Link to="#" className="dropdown-item" onClick={this.onClickSingleApprove}>
                        <i className="fa fa-check text-success mr-1"></i>Approve Selected Payment</Link>
                    </RenderSecure>}

                    {this.state.selectedId && <RenderSecure code="D-BATCH-PAYMENT-01">
                        <Link to="#" className="dropdown-item" onClick={this.onClickDelete}>
                        <i className="fa fa-trash-alt text-danger mr-1"></i>Delete Record</Link>
                    </RenderSecure>}

              </div>
             

              {this.context.migrationList  && 
                    <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                    </a>
                    }

               
            </div>
               
              
             
              
              
              
               
             

            </>
        )
    }



    RenderFilter = () => {
          
        const statuses = [
            {title:"PENDING", value:"1"}, 
            {title:"APPROVED", value:"2"} 
            // {title:"PROCESSED", value:"3"}
        ];
    
        return (
            <CustomizedFilterBar
                selection={{ title: 'Approval Status', options: statuses }}
                keyword="Plate Number"
                onReset={this.context.resetData}
                onConfirm={this.context.filterData}
            />
        )
    
        
    }




    onClickSingleApprove=()=>{
        this.setState({
            modal:false
        }, ()=>{
            this.setState({
                modal:<WarnSecureDelete
                id="modal_approve_payment" 
                title="Approve Selected Payment"
                description="Confirm with your account password that you want to approve the selected payment records; Respective outstanding vehicle balance will be deducted."
                icon="fas fa-question"
                buttonLabel={"Confirm Payment"}
                buttonType="btn-success"
                onConfirm={this.confirmSingleMigration}
                />
            }, ()=>functions.openPopup("modal_approve_payment"))
        })
    }



    confirmSingleMigration=async(password)=>{
        this.setState({info:''})
        const server_response = await ajaxPayment.approveSingleMigratedPayment(password, this.state.selectedId);
        functions.closePopup("modal_approve_payment");
        if(server_response.status==="OK")
        {  
            this.setState({info:<AlertSuccess message={server_response.details.message}/>}, ()=>this.hideInfo())          
        }else{
           this.setState({info:<AlertError message={server_response.details.message}/>}, ()=>this.hideInfo())          
        }
    }




    render() {

        const { migrationList } = this.context

        return (

            <PageContainer>

                    <PageHeader
                            title="Batch Payments"
                            previousPageTitle="Dashboard"
                            // options={this.state.menuOptions}
                            back="/"
                            buttons={<this.buttons />}
                        />  
                        <this.RenderFilter/>
                        {this.state.modal}

                        <div className="row">
                    <div className="col-sm-12 col-xl-12 col-lg-12">

                    <div className="card custom-card">
                            <div className="card-body map-card">

                                <TableHeader
                                    title={"Imported Payments "}
                                    subtitle="List of all vehicle imported payments"
                                />


                                 {Array.isArray(this.context.migrationList) &&
                                                            <LoadMoreButton
                                                                action={this.context.goToNextPage}
                                                                loading={this.context.loading}
                                                                title={this.context.metaData.current_page + " of " + this.context.metaData.total_pages}
                                                            />
                                                        }

                         
                                <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                                    {this.state.info}
                                    <div className="table-responsive allView-table border-top">



                           {Array.isArray(migrationList) && <table id="table-session-import" export-format={exportFormats} className="table text-nowrap table-hover mg-b-0 datatable">
                                    <thead>
                                        <tr>
                                            <th>NO</th>
                                            <th>Payment Date</th>
                                            <th>Vehicle</th>
                                            <th>P. Mode</th>
                                            <th>Amount Paid</th>
                                            <th>Outstanding</th>
                                            <th>Depositor contact</th>
                                            <th>Depositor Name</th>
                                            <th>Reference</th>
                                            <th>Status</th>
                                            <th>Imported By</th>
                                            <th>Approved By</th>
                                            <th>Last Updated</th>
                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {migrationList.map((item, key)=>
                                            <tr key={key} onClick={()=>this.onSelectRow(item.import_id, item)} className={`${this.state.selectedId===item.import_id?'flag-row':''}`}>
                                                <td>{key+1}</td>
                                                <td>{item.payment_date.short_date}</td>
                                                <td>{item.plate_number}</td>
                                                <td>{item.payment_mode}</td>
                                                <td>{item.amount_paid.total_c}</td>
                                                <td>{item.actual_balance.total_c}</td>
                                                <td>{item.depositor_contact}</td>
                                                <td>{item.depositor_name}</td>
                                                <td>{item.reference}</td>
                                                <td>{item.status}</td>
                                                <td>{item.created_by.username}</td>
                                                <td>{item.approved_by?item.approved_by.username:'-'}</td>
                                                <td>{item.updated_at.short_date + " " + item.updated_at.time}</td>
                                            </tr>
                                        )}

                                </tbody>
                            </table>}

                            {!migrationList && <ActivityLoader/>}

                            {migrationList=="404" && <NoContent/>}


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>

            </PageContainer>

           
        );

    }
}

export default ImportPayments
