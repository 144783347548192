import React from 'react';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import ActivityLoader from '../Common/ActivityLoader';

class PopPaymentMode extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        info: '',
        loading: false,
        paymentModes:false
    }

    componentDidMount() {
        this.getPaymentModes();
    }
    

   
    
    
    getPaymentModes = async () => {
        const server_response = await ajax.getOfficePaymentModes()
        if (server_response.status === "OK") {
            this.setState({
                paymentModes: server_response.details.content
            })
        }
    }
   


   
    render() {
        const listing=this.state.paymentModes;
        const list_bg=['bg-danger', 'bg-success', 'bg-warning', 'bg-secondary', 'bg-primary'];

        return (
            <div className="modal" id={this.props.id || "modal_payment_mode"}>
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                <i className="fas fa-hand-holding-usd text-success mr-1"/>
                                Payment Mode
                            </h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <h6 className="card-title mb-3">{this.props.description || "Select payment mode to continue"}</h6>
                            </div>
                            <div className="scroll-widget overflow-auto">
                                {this.state.loading && <ActivityLoader />}
                                {!listing && <ActivityLoader />}
                                {listing && 
                                    <ul className="task-list" style={{lineHeight:1.5}}>
                                       {listing.map((item, key)=>
                                       <li className="pointer" key={key} onClick={()=>this.props.onSelect(item.code)}>
                                       <i className={`task-icon ${list_bg[key] || 'bg-light'}`}></i>
                                       <h6>{item.mode_name}<small className="float-right text-muted tx-11"></small></h6>
                                       <span className="text-muted tx-12 d-inline-block text-truncate" style={{maxWidth: "90%"}}>
                                          {item.code + ' - '} {item.enabled?"Active":"Inactive"}
                                        </span>
                                        </li>)
                                       }
                                    </ul>} 
                            </div>              
                           
                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopPaymentMode;
