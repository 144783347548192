import React from 'react'
import functions from '../../utils/functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import TableHeader from '../../Components/Common/TableHeader'
import dictionary from '../../utils/dictionary'
import SpaceInventoryContext from '../../Context/Reports/SpaceInventoryContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import SpaceInventroyFilter from '../../Components/Reports/SpaceInventoryFilter'
import TableTools from '../../Components/Common/TableTools'
const eformats = dictionary._exportBtnFormats


class SpaceInventory extends React.Component {
    static contextType = SpaceInventoryContext;

    state = {
        userId: functions.sessionGuard(),
        currentPage: 1,
        metaData: false,
        toast: false,
        pagination: false,
        filter: false,
        spaceInventoryList: false,
        locationId: false,
        locationRecord: false,
        toast: '',

        weekend: false,
        weekday: false,
        streets: "",
        Locations: "",
        page: ""
    }



    buttons = () => {
        const { listspaceInventory } = this.context;
        return (
            <>
                <ol className="customButtonList">
                    <li>
                        <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                        </a>
                    </li>
                    <li>
                        <button className="btn ripple btn-light" onClick={listspaceInventory}>
                            <i className="fa fa-redo mr-2"></i> Refresh
                        </button>
                    </li>
                </ol>
            </>
        )
    }




    //==============NAVIGATION CONTROLS START===========================
    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            clampList: false
        }
            // , () => this.filterClampTransations()

        )
    }


    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                clampList: false

            }, () => {
            })
        }

    }

    onClickPrevious = () => {
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                clampList: false
            }, () => {
                // this.filterClampTransations();
            })
        }

    }





    //==============NAVIGATION CONTROLS END===========================





    render() {
        // const { filter, streets, Locations } = this.state
        const { spaceInventoryList, Locations, filter, streets, fetchMore, loading, metaData, currentPage } = this.context;


        return (

            <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <TableHeader
                        title=" Today's Space Inventory analysis"
                        subtitle="Block-by-block parking space inventory and utilization analysis "

                        filterButton={spaceInventoryList && spaceInventoryList !== "404" && <this.buttons />}

                    />
                    <SpaceInventroyFilter />

                    <div className="text-wrap">
                    </div>
                    <div className="table-responsive">
                        <table id={this.props.tableID} className={`table mb-0 table-hover table-bordered occupancy-table ${this.props.dataTable}`} export-format={eformats}>
                            <thead>
                                <tr className="text-center">
                                    <th>Street Name</th>
                                    <th>Location (Parking Area)</th>
                                    <th  >Parking Area Description</th>
                                    <th>Total bays (Spaces)</th>
                                    <th>Metering Type</th>
                                    <th colSpan="2">Average Parking Duration per Slot</th>
                                    <th colSpan="2">Average Daily Collection per Slot (Shs)</th>
                                </tr>

                                <tr className="text-center">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>Weekday</th>
                                    <th>Saturday</th>
                                    <th>Weekday</th>
                                    <th>Saturday</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Array.isArray(spaceInventoryList) && spaceInventoryList.map((item, key) =>
                                    <tr key={key}
                                    // id={"loc_" + item.location_id}
                                    // className={`${this.state.locationId == item.location_id ? 'flag-row' : ''}`}
                                    // onClick={() => this.onSelectRecord(item.location_id, item)}
                                    >
                                        {/* <td rowSpan="true">{item.street.street_name}</td> */}
                                        <td>{item.location_info.street.street_name}</td>
                                        <td className='text-center'>{item.location_info.location_name}</td>
                                        {/* <td style={{ width: "170px" }}>{item.location_info.description.length >= 40 ? (item.location_info.description.charAt(0).toUpperCase() + item.location_info.description.slice(1).toLowerCase()).substring(0, 40) + "..." : item.location_info.description}</td> */}

                                        <td >{item.location_info.description}</td>

                                        {/* {item.description.charAt(0).toUpperCase() + item.description.slice(1).toLowerCase()} */}
                                        <td className='text-center'>{item.location_info.total_slots.total_c}</td>
                                        <td className='text-center'>POS (HandHeld)</td>
                                        <td className='text-nowrap text-center'>{item.weekly_average_duration_per_slot}
                                            <br />

                                            <small className="tx-12 tx-gray-500">{item.weekly_average_duration_per_slot && item.formular_weekly_average_duration_per_slot}
                                            </small>

                                        </td>
                                        <td className='text-nowrap text-center'> {item.weekend_average_duration_per_slot}

                                            <br />

                                            <small className="tx-12 tx-gray-500">{item.weekend_average_duration_per_slot && item.formular_weekend_average_duration_per_slot}
                                            </small>

                                        </td>

                                        <td className='text-nowrap text-center'>{item.weekly_average_collection_per_slot.amount_c}
                                            <br />

                                            <small className="tx-12 tx-gray-500">{item.weekly_average_collection_per_slot && item.formular_weekly_average_collection_per_slot}
                                            </small>

                                        </td>
                                        <td className='text-nowrap text-center'>{item.weekend_average_collection_per_slot.amount_c}

                                            <br />

                                            <small className="tx-12 tx-gray-500 text-center">{item.weekend_average_collection_per_slot.amount_c && item.formular_weekend_average_collection_per_slot}
                                            </small>
                                        </td>

                                    </tr>
                                )}


                            </tbody>


                        </table>

                        {Array.isArray(spaceInventoryList) &&
                            < div className="">
                                <LoadMoreButton
                                    action={metaData.total_pages > 1 ? fetchMore : ""}
                                    loading={loading}
                                    title={metaData == false ? "Refresh" : (metaData.current_page + " of " + metaData.total_pages + " pages")}
                                />
                            </div>
                        }


                        {/* {spaceInventoryList && Array.isArray(spaceInventoryList) && <LoadMoreButton action={fetchMore} loading={loading} />} */}

                        {/* {metaData &&

                        <PageNav
                          totalPages={metaData.total_pages}
                          pageList={metaData.list_of_pages}
                          currentPage={currentPage}
                          onClickNext={this.onClickNext}
                          onClickPage={this.onClickPage}
                          onClickPrevious={this.onClickPrevious}
                        />

                      } */}
                        {!spaceInventoryList && <ActivityLoader />}


                    </div>
                </div>
            </div>

        );

    }
}

export default SpaceInventory