import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
// import ActivityLoader from '../../../Components/Common/ActivityLoader'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'
import SelectSearch from '../../../Components/Common/SelectSearch'

class CreateStreet extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    branchId: '',
    streetName: '',
    branchList: false
  }

  componentDidMount() {
    this.getBranches();
  }

  getBranches = async () => {
    const server_response = await ajax.listBranches(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        branchList: server_response.details.content
      })
    } else {
      this.setState({
        branchList: "404"
      })
    }
  }


  onChangeBranch = (selectedOption) => {
    this.setState({
      branchId: selectedOption.value
    })
  }

  onChangeStreetName = (event) => {
    this.setState({ streetName: event.target.value })
  }


  onSave = async (event) => {
    event.preventDefault();
    const { streetName, branchId } = this.state;

    if (streetName.length > 0 && branchId.length > 0) {
      //fetch login from the API
      const server_response = await ajax.createStreet(streetName, branchId);
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })
        this.setState({
          streetName: ''
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._completeFields} />,
        loading: ''
      })
    }


  }

  render() {

    const branchList = this.state.branchList;
    const optionsBranch = [];
    if (branchList && branchList !== "404") {
      branchList.map((item, key) =>
        optionsBranch.push({ label: item.branch_name, value: item.branch_id })
      )
    }


    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="New Street"
              previousPageTitle="Streets"
              back="/config/streets"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>


                          <TextInput
                            label="Street Name"
                            type="text"
                            onChange={() => this.onChangeStreetName}
                            value={this.state.streetName}
                          />

                          <SelectSearch
                            label="Street Branch"
                            onChange={() => this.onChangeBranch}
                            options={optionsBranch}
                            placeholder={"Select branch..."}
                          />




                          <div className="modal-footer">
                            <input className="btn ripple btn-success" type="button" value="Save Street" onClick={this.onSave} />
                          </div>
                        </form>




                      </div>
                    </div>


                  </div>


                </div>

              </div>

              <ConfigMenu />



            </div>


          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default CreateStreet
