import React, { useContext } from "react";
import ConfigurationContext from "../../Context/ConfigurationContext";
import SpaceInventoryContext from "../../Context/Reports/SpaceInventoryContext";
import CustomizedFilterBar from "../Common/CustomizedFilterBar";

const SpaceInventroyFilter = (props) => {


    const { locationList, streetList } = useContext(ConfigurationContext);

    const { resetData, filterData } = useContext(SpaceInventoryContext)


    const listOptions = [];
    const locationOptions = [];

    Array.isArray(streetList) && streetList.map((item, key) =>
        listOptions.push({
            title: item.street_name,
            value: item.street_id
        })
    );

    Array.isArray(locationList) && locationList.map((item, key) =>
        locationOptions.push({
            title: item.location_name,
            value: item.location_id
        })
    );

    return (
        <CustomizedFilterBar
            selection={{ title: 'Street', options: listOptions }}
            selection6={{ title6: 'Location', options6: locationOptions }}
            onReset={resetData}
            onConfirm={filterData}
        />



    )
}


export default SpaceInventroyFilter;