import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


    async postCustomClampInvoice(data){
        let response = await apiCall("invoice/clamp/new", data)
        return response;
    },

    async postCustomClampViolation(data){
        let response = await apiCall("invoice/clamp-violation/new", data)
        return response;
    },

    



}