import React, { useContext } from "react";
import ConfigurationContext from "../../Context/ConfigurationContext";
import OperationContext from "../../Context/OperationContext";
import ParkingsContext from "../../Context/ParkingsContext";
import FilterSelectInput from "../Common/Filter/FilterSelectInput";
import FilterTextInput from "../Common/Filter/FilterTextInput";
import ScrollFilterBar from "../Common/Filter/ScrollFilterBar";

const SessionFilter = (props) => {


  const { conductorList } = useContext(OperationContext);
  const { branchList, streetList, locationList } = useContext(ConfigurationContext);
  const { resetData, filterData } = useContext(ParkingsContext)


  const userOptions = [];
  Array.isArray(conductorList) && conductorList.forEach((item) =>
    userOptions.push({
      title: item.full_name + " (" + item.username + ")",
      value: item.user_id
    })
  );

  const branchOptions = [];
  Array.isArray(branchList) && branchList.forEach((item) =>
    branchOptions.push({
      title: item.branch_name,
      value: item.branch_id
    })
  );

  const streetOptions = [];
  Array.isArray(streetList) && streetList.forEach((item) =>
    streetOptions.push({
      title: item.street_name,
      value: item.street_id
    })
  );

  const locationOptions = [];
  Array.isArray(locationList) && locationList.forEach((item) =>
    locationOptions.push({
      title: item.location_name + " - " + item.street.street_name + " - " + item.total_slots.total_p + (" slot(s)"),
      value: item.location_id
    })
  );


  return <ScrollFilterBar
    onReset={resetData}
    onConfirm={filterData}
    firstScreen={{
      inputs: [
        (onSet, key, currentValues) => <FilterTextInput
          key={key}
          label="Parking Date From"
          type="date"
          onChange={onSet}
          name="dateFrom"
          currentValues={currentValues}
        />,

        (onSet, key, currentValues) => <FilterTextInput
          key={key}
          label="Parking Date To"
          type="date"
          onChange={onSet}
          name="dateTo"
          currentValues={currentValues}
        />,

        (onSet, key, currentValues) => <FilterTextInput
          key={key}
          label="Plate Number"
          type="text"
          placeholder="Enter plate number"
          onChange={onSet}
          name="plateNumber"
          currentValues={currentValues}
        />,

        (onSet, key, currentValues) => <FilterSelectInput
          key={key}
          label="Started By"
          options={userOptions}
          onChange={onSet}
          name="conductorId"
          currentValues={currentValues}
        />,

      ],
      names: ["dateFrom", "dateTo", "plateNumber", "conductorId"]
    }}
    secondScreen={
      {
        inputs: [

          (onSet, key, currentValues) => <FilterSelectInput
            key={key}
            label="Parking Street"
            options={streetOptions}
            onChange={onSet}
            name="street"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues) => <FilterSelectInput
            key={key}
            label="Parking Location"
            options={locationOptions}
            onChange={onSet}
            name="location"
            currentValues={currentValues}
          />,

          (onSet, key, currentValues) => <FilterSelectInput
            key={key}
            label="Branch"
            options={branchOptions}
            onChange={onSet}
            name="branch"
            currentValues={currentValues}
          />,
        ],



        names: ["street", "location", "branch"]
      }
    }
  />

}


export default SessionFilter;