import React from 'react';
import Chart from 'chart.js';

class BarChart extends React.Component {

  canvasRef = React.createRef();

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
        // maintainAspectRatio: false,
        // animation: {
        //   duration: 10,
        // },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
            }
          }
        },
        aspectRatio: 1.9,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                max: 350
              }
            }
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false },
              barPercentage: 0.7
              // ticks: {
              //   min: 0,
              //   max: 160
              // }
            }
          ],

        }
      },
      data: {
        labels: this.props.data.map(d => d.label),
        datasets: [
          {
            label: this.props.title,
            data: this.props.data.map(d => d.value),
            backgroundColor: this.props.color
          },
          {
            label: this.props.title2,
            data: this.props.data2.map(d => d.value),
            backgroundColor: this.props.color2
          },
        ]
      }
    });
  }

  // Return with commas in between
  // numberWithCommas = function (x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };

  render() {
    return (
      <div className="mt-3">
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}

export default BarChart;
