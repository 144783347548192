import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import functions from '../../utils/functions';
import ajaxUser from '../../utils/remote/ajaxUser';
import ActivityLoader from '../Common/ActivityLoader';
import NoContent from '../Common/NoContent';
import TableHeader from '../Common/TableHeader';
import ApexPolar from './ApexPolar';
import ApexRadioChart from './ApexRadioChart';
import SideBarChart from './SideBarChart';




const UsersChart = (props) => {

    const [userRoleCount, setUserRoleCount] = useState(false)
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {
        getUserRoleCount();
    }, [refreshCounter])


    useEffect(() => {

        setTimeout(() => {
            setRefreshCounter(refreshCounter + 1)
        }, dictionary._waitTime);

    }, [refreshCounter])





    const getUserRoleCount = async () => {
        const server_response = await ajaxUser.getRoleCount();
        if (server_response.status === "OK") {

            setUserRoleCount(server_response.details.content)

        } else {
            setUserRoleCount("404")

        }

    }




    const transaction = userRoleCount

    return (

        <div className="card custom-card">
            <div className="card-body map-card">

                <TableHeader
                    title="User Role stats"
                    subtitle="Contrast between all the user roles in the system."
                    view="View All"
                // onClick={props.onClickViewAll}
                />


                <div className="text-center user-manager mt-3 scroll-widget border-top overflow-auto">

                    <div className="mt-3 mb-3">

                        {transaction && transaction != "404" &&
                            <>
                                <SideBarChart
                                    colors={
                                        [
                                            '#7c59e6',
                                            '#dfe2e6',
                                            '#eb6f33',
                                            '#01b8ff',
                                            '#47C895',

                                        ]
                                    }

                                    // colors={
                                    //     [
                                    //         '#1ab7ea',
                                    //         '#0084ff',
                                    //         '#39539E',
                                    //         '#0077B5'
                                    //     ]
                                    // }
                                    displayKey={true}
                                    // labels={
                                    //     [
                                    //         'Vimeo',
                                    //         'Messenger',
                                    //         'Facebook',
                                    //         'LinkedIn'
                                    //     ]
                                    // }
                                    labels={
                                        [
                                            'Super Admins',
                                            'Conductors',
                                            'Cobras',
                                            'Zulus',
                                            'Admins',
                                            'Operations',
                                            'Finance',
                                            'Supervisors',
                                            'Displays',
                                            'Branch Mangers',
                                            'Vendors',

                                        ]
                                    }
                                    values={
                                        [
                                            transaction.super_admins.total * 1,
                                            transaction.conductors.total * 1,
                                            transaction.cobras.total * 1,
                                            transaction.zulus.total * 1,
                                            transaction.admins.total * 1,
                                            transaction.operations.total * 1,
                                            transaction.finance.total * 1,
                                            transaction.supervisors.total * 1,
                                            transaction.display.total * 1,
                                            transaction.branch_managers.total * 1,
                                            transaction.vendors.total * 1,

                                            // 23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 50
                                        ]
                                    }
                                />

                                {/* <ApexRadioChart
                                    // colors={
                                    //     [
                                    //         '#7c59e6',
                                    //         '#dfe2e6',
                                    //         '#eb6f33',
                                    //         '#01b8ff',
                                    //         '#47C895',

                                    //     ]
                                    // }

                                    colors= {['#1ab7ea', '#0084ff', '#39539E', '#0077B5']}
                                    displayKey={true}
                                    labels= {['Vimeo', 'Messenger', 'Facebook', 'LinkedIn']}
                                    // labels={
                                    //     [
                                    //         'Super Admins',
                                    //         'Conductors',
                                    //         'Cobras',
                                    //         'Zulus',
                                    //         'Admins',
                                    //         'Operations',
                                    //         'Finance',
                                    //         'Supervisors',
                                    //         'Displays',
                                    //         'Branch Mangers',
                                    //         'Vendors',

                                    //     ]
                                    // }
                                    values={
                                        [
                                            // transaction.super_admins.total * 1,
                                            // transaction.conductors.total * 1,
                                            // transaction.cobras.total * 1,
                                            // transaction.zulus.total * 1,
                                            // transaction.admins.total * 1,
                                            // transaction.operations.total * 1,
                                            // transaction.finance.total * 1,
                                            // transaction.supervisors.total * 1,
                                            // transaction.display.total * 1,
                                            // transaction.branch_managers.total * 1,
                                            // transaction.vendors.total * 1,

                                            23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 50
                                        ]
                                    }
                                /> */}

                                {/* <p className="mt-n5 mb-1 tx-inverse">Available Space</p>

                                <h4 className="mb-1">
                                    {transaction.available_slots.total * 1 + '/' + transaction.total_slots.total * 1}<span className="minor-tag">{transaction.total_slots.total * 1 > 1 ? ' Slots' : 'Slot'}</span>
                                </h4>

                                <span className="text-muted tx-12">
                                    <i className="fas fa-caret-up mr-1 text-success"></i>{transaction.net_availablity} % Availability
                                </span> */}
                            </>
                        }

                        {!transaction && <ActivityLoader />}

                    </div>


                </div>

            </div>
        </div>
    )
}



export default UsersChart
