import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ActivityLoader from '../../../Components/Common/ActivityLoader'
import WarnDelete from '../../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../../Components/Common/Toast'
import PopNewAmount from '../../../Components/Common/PopNewAmount'
import dictionary from '../../../utils/dictionary'
import TableHeader from '../../../Components/Common/TableHeader'
import NoContent from '../../../Components/Common/NoContent'
import ajaxClamping from '../../../utils/remote/ajaxClamping'
const eformats = dictionary._exportConfigBtnFormats

class ConfigClampingFee extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        clampFeeList: false,
        clampFeeId: false,
        clampFeeRecord: false,
        toast: '',
        mdlNewAmount: false,
        mdlDelete: false
    }

    componentDidMount() {
        this.getClampFee();
    }

    getClampFee = async () => {
        const server_response = await ajaxClamping.getClampingFee();

        if (server_response.status === "OK") {
            this.setState({
                clampFeeList: server_response.details.content
            })
        } else {
            this.setState({
                clampFeeList: "404"
            })
        }
    }


    onClickAdd = () => {
        this.setState({
            mdlNewAmount: false
        }, () => {
            this.setState({
                mdlNewAmount: <PopNewAmount
                    title="New Clamping Charge"
                    buttonLabel="Confirm Clamp Charge"
                    description="This Clamp Charge will automatically override the existing amount.
                              only a single Clamp Charge can be active at a time"
                    onConfirm={this.onConfirmNewClampCharge} />
            }, () => {
                $("#modal_amount_input").modal('show')
            })
        })
    }


    onConfirmNewClampCharge = async (amount) => {

        const server_response = await ajaxClamping.createClampingFee(amount);
        if (server_response.status === "OK") {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast message={server_response.details.message} />
            }, () => {

                this.getClampFee();
            })
        } else {
            $("#modal_amount_input").modal('hide')
            this.setState({
                toast: <Toast type={2} message={server_response.details.message} />
            })
        }

    }

    onClickDelete = () => {
        if (this.state.clampFeeRecord) {
            this.setState({
                mdlDelete: false
            }, () => {
                this.setState({
                    mdlDelete: <WarnDelete
                        title="Delete Clamping Fee?"
                        description="Are you sure you want to delete this clamp Fee?"
                        onConfirm={() => this.onConfirmDelete()} />
                }, () => {
                    $("#modal_delete").modal('show')
                })
            })


        }
    }

    onConfirmDelete = async () => {

        if (this.state.clampFeeId) {
            $("#modal_delete").modal('hide');

            const server_response = await ajaxClamping.deleteClampingFee(this.state.clampFeeId);
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                }, () => {

                    //hide record
                    functions.hide('c_' + this.state.clampFeeId);
                    //Refresh list
                    this.getClampFee();
                })
            } else {
                this.setState({
                    toast: <Toast type={2} message={server_response.details.message} />
                })
            }

        }

    }



    buttons = () => {
        return (

            <button className="btn ripple btn-success" onClick={this.onClickAdd}>
                <i className="far fa-edit mr-1"></i> New Clamping Fee
            </button>
        )
    }

    customHeaderOptions = () => {
        return (
            <>
                <a className="dropdown-item" onClick={this.onClickAdd}>
                    <i className="fe fe-plus-circle mr-2"></i>Add Clamping Fee
                </a>
                <a className="dropdown-item" onClick={this.onClickDelete}>
                    <i className="fe fe-trash-2 mr-2"></i>Delete Clamping Fee
                </a>

            </>
        )
    }


    onSelectClampFee = (recordId, record) => {
        this.setState({
            clampFeeId: recordId,
            clampFeeRecord: record,
            toast: ''
        })
    }

    render() {
        const clampFeeList = this.state.clampFeeList
        return (
            <div className="page">
                {this.state.mdlNewAmount}
                {this.state.mdlDelete}



                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Clamping Fee"
                            previousPageTitle="Configurations"
                            back="/config"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                            }}
                            buttons={<this.buttons />}
                        />

                        {this.state.toast}


                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="row">


                                    <div className="col-sm-12 col-xl-12 col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center filter-example">
                                                <TableHeader
                                                    title="Clamping Charge fee"
                                                    subtitle="This represents the money charged after clamping a vehicle"
                                                />
                                                </div>

                                                <div className="table-responsive allView-config border-top">
                                                    {(clampFeeList === "404") && <NoContent />}

                                                    {clampFeeList && Array.isArray(clampFeeList) &&
                                                        <table id="config-clampFee" className="table table-hover text-nowrap mb-0 datatable " export-format={eformats}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Amount</th>
                                                                    <th className="text-center">Status</th>
                                                                    <th>Created At</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {clampFeeList && clampFeeList !== "404" &&
                                                                    clampFeeList.map((item, key) =>
                                                                        <tr
                                                                            id={"c_" + item.id}
                                                                            key={key}
                                                                            className={`${this.state.clampFeeId == item.id ? 'flag-row' : ''}`}
                                                                            onClick={() => this.onSelectClampFee(item.id, item)}>
                                                                            <td>{item.amount.amount_c}</td>
                                                                            {/* <td>{item.status_name}</td> */}
                                                                            <td className="text-center">
                                                                                {item.status_name === 'ACTIVE' &&
                                                                                    <span className="btn ripple btn-success btn-sm">{item.status_name}</span>
                                                                                }

                                                                                {item.status_name === 'INACTIVE' &&
                                                                                    <span className="btn ripple btn-danger btn-sm">{item.status_name}</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {item.created_at.date} <br />
                                                                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            </tbody>

                                                        </table>
                                                    }

                                                    {!clampFeeList && <ActivityLoader />}
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>

                            <ConfigMenu />



                        </div>

                    </div>
                </div>
                <RightSideBar />

            </div>


        );

    }
}

export default ConfigClampingFee
