import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import Toast from '../../Components/Common/Toast'
import { Link } from 'react-router-dom'
import NoContent from '../../Components/Common/NoContent'
import PopNewTicketFloat from '../../Components/Finance/PopNewTicketFloat'
import CustomizedFilterBar from '../../Components/Common/CustomizedFilterBar'
import TableHeader from '../../Components/Common/TableHeader'
import VirtualTicketContext from '../../Context/VirtualTicketContext'
import LoadMoreButton from '../../Components/Other/LoadMoreButton'
import dictionary from '../../utils/dictionary'

class TicketRecords extends React.Component {

  static contextType = VirtualTicketContext

  state = {
    userId: functions.sessionGuard(),
    vehicleInfo: false,
    plateNumber: '',
    autoVehicle: false,
    loading: false,
    home: true,
    toast: false,
    stickerTypes: false,
    stickerTypeId: '',
    pagination: false,
    currentPage: 1,
    metaData: false,
    filter: false,
    floatDialogue: false
  }

  resetToast = () => {
    this.setState({
      toast: false
    })
  }


  onClickLoadFloat = () => {
    this.setState({
      floatDialogue: false
    }, () => {

      this.setState({
        floatDialogue: <PopNewTicketFloat onConfirm={this.onConfirmLoadFloat} />
      }, () => {
        functions.openPopup("modal_new_ticket_float");
      })

    })

  }


  buttons = () => {
    return (
      <>
        <Link to="/finance/paper-tickets" className='btn ripple btn-primary'>
        <i className="fa fa-tag"></i> Paper Tickets</Link>

        <button className="btn ripple btn-light" onClick={this.context.getVirtualTickets}>
          <i className="fa fa-redo"></i> Refresh
        </button>
        {Array.isArray(this.context.ticketListing) && <a href="#" className="btn ripple btn-secondary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
        </a>}

      </>
    )
  }

 

  refreshToast = () => {
    this.setState({
      toast: false
    })
  }

  onConfirmLoadFloat = async (userId, amount) => {
    const server_response = await ajax.loadTicketFloat(userId, amount);
    if (server_response.status === "OK") {
      functions.closePopup("modal_new_ticket_float");
      this.setState({
        toast: <Toast message={server_response.details.message} onClose={this.refreshToast} />
      })
    } else {
      functions.closePopup("modal_new_ticket_float");
      this.setState({
        toast: <Toast type={2} message={server_response.details.message} onClose={this.refreshToast} />
      })
    }
  }


  RenderFilter=()=>{
      const userOptions = [];
      if(Array.isArray(this.context.ticketSellerListing)){
          this.context.ticketSellerListing.map((item, key)=>{
            userOptions.push({title:item.last_name + " " + item.first_name, value:item.user_id})
          })
      }
      return(
        <CustomizedFilterBar
        selection={{ title: 'Sale Agent', options: userOptions }}
        keyword="Plate Number"
        onReset={this.context.resetData}
        onConfirm={this.context.filterData}
      />

      )
  }





  render() {
    const transactionList = this.context.ticketListing;

    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Virtual Ticket Sales"
              previousPageTitle="Finance"
              back="/finance"
              buttons={<this.buttons />}
            />

             
         

            <this.RenderFilter/>


            <div className="row">
              {this.state.receiptView}
              <div className="col-sm-12 col-xl-12 col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">


                    <TableHeader
                      title={"Parking Ticket Sales"}
                      subtitle="All parking ticket sale records"
                    />

                     {Array.isArray(transactionList) &&
                              <LoadMoreButton
                                  action={this.context.goToNextPage}
                                  loading={this.context.loading}
                                  title={this.context.metaData.current_page + " of " + this.context.metaData.total_pages}
                                  />
                              }

                    <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                      <div className="table-responsive">

                        {Array.isArray(transactionList) &&
                          <table id="table-v-tickets" className="table table-hover text-nowrap mb-0 datatable" export-format={dictionary._exportBtnFormats}>
                          <thead>
                              <tr>
                                <th>Sale Date</th>
                                <th>Sale ID</th>
                                <th>Sale Agent</th>
                                <th>Vehicle</th>
                                <th>Float Ref</th>
                                <th>Amount(UGX)</th>
                                <th>Float Account</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactionList.map((item, key) =>
                                <tr key={key}>
                                  <td>{item.created_at.long_date}</td>
                                  <td>{item.sale_id_c}</td>
                                  <td>{item.sold_by.last_name + ' ' + item.sold_by.first_name}</td>
                                  <td className="tx-bold">
                                    <Link to={
                                      {
                                        pathname: `/vehicles/profile/${item.vehicle.plate_number}`,
                                        state: { plateNumber: item.vehicle.plate_number }
                                      }}>{item.vehicle.plate_number}
                                    </Link>
                                  </td>
                                  <td>{item.float_id}</td>
                                  <td className='ticket-amount-field'>{item.amount.amount_c}</td>
                                  <td>{item.float_agent.username}</td>

                                </tr>
                              )}

                               <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td><b>TOTAL</b></td>
                                <td><b>{functions.sumTableField("ticket-amount-field")}</b></td>
                                <td>&nbsp;</td>
                              </tr>

                            </tbody>
                          </table>}
                        {transactionList === "404" && <NoContent />}
                        {!transactionList && <ActivityLoader />}
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>








          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default TicketRecords
