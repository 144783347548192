import React from 'react';
import LeftSideBar from '../Components/Common/LeftSideBar';
import Footer from '../Components/Common/Footer';
import TopBar from '../Components/Common/TopBar';
import PageHeader from '../Components/Common/PageHeader';
import functions from '../utils/functions';
import dictionary from '../utils/dictionary';
import ActivityLoader from '../Components/Common/ActivityLoader';
import RightSideBar from '../Components/Common/RightSideBar';
import ajax from '../utils/ajax';
import { MapConsumer } from '../Context/MapContext';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer,
  Polyline,
  AutoComplete,
  Search
} from 'react-google-maps';

// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow,
//   DirectionsRenderer,
//   Polyline,
//   AutoComplete,
//   Search
// } from '@react-google-maps/api';

import { Link } from 'react-router-dom';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import GoogleParkingMap from '../Components/GoogleMap';

// import "../assets/paper-push-pin.png";
// import $ from 'jquery';

// import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

// import ParkingMapMarker from '../Components/ParkingMapMarker';
// import Markers from '../Components/CustomMarker';



const mapStyles = {
  // position: "relative",
  width: "100%",
  height: "77vh",
  // border: "1px solid red",
  // zIndex: "1"
};

const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

class ParkingMap extends React.Component {


  state = {
    userId: functions.sessionGuard(),
    locationList: false,
    locationParkingZoneList: false,
    locationNonParkingZoneList: false,
    status: '',

    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {},          // Shows the InfoWindow to the selected place upon a marker

    coords: this.props.coords,
    // defaultCenter: { lat: 0.347600, lng: 32.582500 },
    // defaultCenter: { lat: 0.303916, lng: 32.575631 },
    // defaultCenter: { lat: 0.295070, lng: 32.555040 },
    // defaultCenter: { lat:0.3477422, lng:32.6092213 },
    defaultCenter: { lat: 0.319936, lng: 32.590523 },
    // lat: 0.347600,
    // lng: 32.582500
    center: this.props.center,

    // mapMarker: null,
    // showingInfoWindow: false,
    showParkingInfoIndex: '',
    showNonParkingInfoIndex: '',

    selectedCenter: '',
    setSelectedCenter: '',
    plateNumber: '',

    directions: null,
  }


  componentDidMount() {
    // await this.getLocationCoordinates();
    // await this.getLocationZones();

  }


  getDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();

    const origin = this.state.currentLocation;
    const destination = this.state.markerPos;

    if (origin !== null && destination !== null) {
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    } else {
      console.log('Please mark your destination in the map first!');
    }
  };



  MapView = withScriptjs(withGoogleMap((props) =>

    <GoogleMap
      defaultZoom={17}
      defaultCenter={props.defaultCenter}
      options={{
        mapTypeControl: false,
      }}
      defaultOptions={{
        // ...DEFAULT_MAP_OPTIONS,
        // styles: mapStyles,
        suppressMarkers: false,
        preserveViewport: true
      }}
    >

      {/* <ReactGoogleAutocomplete
        style={{
          width: '100%',
          height: '40px',
          paddingLeft: '17px',
          // marginTop: '2px',
          // marginBottom: '2rem'
        }}
        onPlaceSelected={props.onPlaceSelected}
        types={['(regions)']}
      /> */}

      {/* <DirectionsRenderer
        directions={props.directions}
      /> */}

      {/* <DirectionsRenderer
        directions={props.directions}
        options={{
          polylineOptions: {
            zIndex: 50,
            strokeColor: "#1976D2",
            strokeWeight: 5,
          },
        }}
      /> */}

      {/* <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        // controlPosition={google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Customized your placeholder"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `27px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox> */}

      {/* <Polyline
        path={props.directions}
        options={{
          geodesic: true,
          strokeColor: '#669DF6',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }}
      /> */}

      {/* <Polyline
            key={index}
            options={{
              strokeColor: "#669DF6",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
            path={
              [
                {
                  lat: item.start_lat * 1,
                  lng: item.start_lng * 1
                },
                {
                  lat: item.end_lat * 1,
                  lng: item.end_lng * 1
                }
              ]
            }
          /> */}


      {/* <DirectionsRenderer
            directions={this.state.directions}
            defaultOptions={{
              suppressMarkers: true
            }}
            options={{
              polylineOptions: {
                zIndex: 50,
                strokeColor: "#1976D2",
                strokeWeight: 5,
              },
            }}
          /> */}

      {/* <Polyline path={[{ lat: -34.397, lng: 150.644 }, { lat: -35.397, lng: 151.644 }]} /> */}

      {props.locationParkingZoneList && props.locationParkingZoneList !== "404" && props.locationParkingZoneList.map((item, index) =>

        <>

          <Marker
            key={index}
            options={{ icon: dictionary.apiAssets + 'multi.svg' }}
            position={{
              lat: item.lat * 1,
              lng: item.lng * 1
            }}
            // animation={window.google.maps.Animation.BOUNCE}
            // onMouseover={this.props.mouseEnterHandler}
            // onMouseout={this.props.mouseLeaveHandler}
            clickable
            onClick={() => props.showParkingInfo(item.location_id)}
            // options={{icon: 'http://www.firepineapple.com/application/views/images/map_marker.gif'}}
          >

            {props.showParkingInfoIndex == item.location_id &&
              <InfoWindow onCloseClick={props.onInfoWindowClose}>

                <div>
                  <h5 className="text-secondary">{item.location_name}</h5>
                  <span>Total slots: {item.total_slots.total}</span><br />
                  <span>Active slots: <span className="text-success font-weight-bold">{item.active_slots.total}</span></span><br /><br />
                  <span className="font-weight-bold">Vehicles in session: </span><br />
                  <span>
                    <ol>
                      {item.vehicles_in_session.map((item, key) =>
                        <li className="tx-bold"><Link to={
                          {
                            pathname: `/vehicles/profile/${item}`,
                            state: { plateNumber: item }
                          }}>{item}
                        </Link>
                        </li>
                      )}
                    </ol>
                  </span>
                </div>

              </InfoWindow>
            }

          </Marker>

        </>

      )
      }

      {props.locationNonParkingZoneList && props.locationNonParkingZoneList !== "404" && props.locationNonParkingZoneList.map((item, index) =>
        <>

          <Marker
            key={index}
            options={{ icon: dictionary.apiAssets + 'multi-non.svg' }}
            position={{
              lat: item.lat * 1,
              lng: item.lng * 1
            }}
            // title={item.location_name}
            // onMouseover={this.props.mouseEnterHandler}
            // onMouseout={this.props.mouseLeaveHandler}
            // }}

            // animation={window.google.maps.Animation.BOUNCE}
            // onMouseover={this.props.mouseEnterHandler}
            // onMouseout={this.props.mouseLeaveHandler}
            clickable
            onClick={() => props.showNonParkingInfo(item.location_id)}
          // options={{icon: 'http://www.firepineapple.com/application/views/images/map_marker.gif'}}
          >

            {props.showNonParkingInfoIndex == item.location_id &&
              <InfoWindow onCloseClick={props.onInfoWindowClose}>

                <div>
                  <h5 className="text-secondary">{item.location_name}</h5>
                </div>

              </InfoWindow>
            }

          </Marker>

        </>

      )
      }

    </GoogleMap>

  ))


  render() {

    // console.log(this.state.showParkingInfoIndex);

    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">

            <PageHeader
              title="Parking Spots Map"
              previousPageTitle="Dashboard"
              back="/operations"
            />

            {/* <Autocomplete
              style={{
                width: '100%',
                height: '40px',
                paddingLeft: '17px',
                // marginTop: '2px',
                // marginBottom: '2rem'
              }}
              onPlaceSelected={this.onPlaceSelected}
              types={['(regions)']}
              googleMapsScriptBaseUrl={
                `https://maps.googleapis.com/maps/api/js?key=${dictionary._apiKey}&v=3.exp&libraries=geometry,drawing,places`
              }
            /> */}

            <div className="">
              <div
                style={mapStyles}
              >

                <GoogleParkingMap />


              </div>
            </div>

          </div>
        </div>
        <RightSideBar />

      </div>


    );

  }
}

export default ParkingMap;
