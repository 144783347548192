import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


async countPendingBankDeposits()//GET
{

    try {
        let response = await fetch(apiHost + 'payment/bankdeposit/pending.count',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},



async createBankDeposit(
    depositor_name,
    depositor_contact,
    amount,
    bank_ref,
    deposit_date,
    bank_id,
    depositor_id
)//POST
{

    let data = {
        "depositor_name": depositor_name,
        "depositor_contact": depositor_contact,
        "amount": amount,
        "bank_ref": bank_ref,
        "deposit_date": deposit_date,
        "bank_id": bank_id,
        "depositor_id": depositor_id
    }

    try {
        let response = await fetch(apiHost + 'payment/bankdeposit.create',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},


async listBankDepositRecords(status, page) {

    let data = {
        "status": status,
        "page": page
    }


    try {
        let response = await fetch(apiHost + 'payment/bankdeposit/list',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + ACCESS_TOKEN
                },
                body: JSON.stringify(data),
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},

async cancelBankDeposit(deposit_id, password) {

    let data = {
        "deposit_id": deposit_id,
        "password": password
    }

    try {
        let response = await fetch(apiHost + 'payment/bankdeposit/cancel',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + ACCESS_TOKEN
                },
                body: JSON.stringify(data),
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},

async approveBankDeposit(deposit_id, password) {

    let data = {
        "deposit_id": deposit_id,
        "password": password
    }

    try {
        let response = await fetch(apiHost + 'payment/bankdeposit/approve',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + ACCESS_TOKEN
                },
                body: JSON.stringify(data),
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},

async processBankDeposit(deposit_id, password) {

    let data = {
        "deposit_id": deposit_id,
        "password": password
    }

    try {
        let response = await fetch(apiHost + 'payment/bankdeposit/process',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + ACCESS_TOKEN
                },
                body: JSON.stringify(data),
            });

        let result = await response.json();

        return result;
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

},



}