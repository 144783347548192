import React from 'react'
import { Link } from 'react-router-dom'
import DashRow from './DashRow'
import DashRow3 from './DashRow3'

class PairedCard extends React.Component {

    render() {
        return (
            <div className={this.props.className}>
                <Link to={this.props.href}>
                    <div className="card custom-card pinned-card">
            

                        <div className="row">
                            <DashRow 
                                size="col-xl-6 col-lg-6 col-md-6 col-sm-6 border-right" 
                                title={this.props.title1}
                                value={this.props.value1}
                                color={this.props.color1} 
                                caption={this.props.caption1} 
                            />

                            <DashRow3 
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6"
                                title={this.props.title2}
                                icon={this.props.icon}
                                value={this.props.value2}
                                color={this.props.color2}
                                caption={this.props.caption2} 

                            />
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

}

export default PairedCard
