import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost=dictionary.apiHost

export default {


  async getOutstandingInvoices() {
    try {
      let response = await fetch(apiHost + 'reservation/outstanding_invoices',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  },

  async getAnnualReservation() {
    try {
      let response = await fetch(apiHost + 'reservation/income/annual',
        {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + ACCESS_TOKEN,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        });
      let result = await response.json();
      return result;
    } catch (error) {
      let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
      return connError;
    }
  }

    



}