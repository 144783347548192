import React from 'react';
import Chart from "react-apexcharts";

class SideBarChart extends React.Component {

    state = {

        series: [
            {
                data: this.props.values,
            }
        ],

        options: {
            chart: {
                // type: 'bar',
                // height: 350
                stacked: false,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '50%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#7c59e6', '#dfe2e6', '#eb6f33'],
            stroke: {
                show: true,
                width: [0, 2, 4],
                curve: 'smooth',
                colors: ['transparent']
            },
            fill: {
                opacity: [0.85, 0.75, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: this.props.labels,
            },
            legend: {
                display: true,
                position: 'top'
            },

            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " users";
                        }
                        return y;

                    }
                }
            },

            grid: {
                borderColor: '#f1f1f1'
            },
        },


    };

    render() {
        return (

            <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height={360}
            />
        )
    }
}

export default SideBarChart;
