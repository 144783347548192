import React, { createContext, Component } from 'react';
import functions from '../../utils/functions';
import $ from 'jquery';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';
import Toast from '../../Components/Common/Toast';
import PopViewImage from '../../Components/PopViewImage';
import ajaxWrongParking from '../../utils/remote/ajaxWrongParking';
import ajaxPaymentDue from '../../utils/remote/ajaxPaymentDue';
import { detectTable, restoreTable } from '../../utils/script/customTables';
import ajaxEnforcementAnalysis from '../../utils/remote/ajaxEnforcementAnalysis';




const WrongParkingContext = React.createContext({});



export class WrongParkingProvider extends Component {

    state = {
        userId: functions.sessionGuard(),

        countTodaysWrongParkings: '...',
        countTodaysPaymentDues: '...',
        countTodaysPaidWrongParkings: '...',
        countTodaysPaidPaymentDues: '...',

        WrongParking: '',
        currentPage: 1,
        List: false,
        modal: false,
        metaData: false,
        toast: false,
        hasError: false,
        info: false,
        imageView: false,
        loadTable: true,
        enableUpdate: false,
        imageView: "",

        recentWrongParking: false,
        enforcementAnalysisByBranchList: false,
        contraventionAnalysisByBranchList: false,
        countReportedByWP: false,
        loading: "",
        reportedByList: "",
        EList: false,
        EcurrentPage: 1,
        EmetaData: false,
        Eloading: false,
        backupElist: false,
        backupEmetaData: false,
        filterEnforcementByBranchList: false,
        filterEmetaData: false,
        EfilteredcurrentPage: 1

    }

    componentDidMount() {

        this.listWrongParkingTransactions();
        this.getRecentWrongParkingTransactions();
        this.countTodaysWrongParkingTransactions();
        this.countTodaysPaymentDueTransactions();
        this.getCountReportedByWrongParking();
        this.countTodaysPaidPaymentDueTransactions();
        this.getReportedByUsers();
        this.getBranchEnforcementAnalysis();


        this.setState({

            WrongParking: {
                "report_wrong_parking": () => this.onClickReportVehicle()
            },
        })


    }


    getReportedByUsers = async () => {
        const server_response = await ajaxWrongParking.listReportedByUsers();
        if (server_response.status === 'OK') {
            this.setState({
                reportedByList: server_response.details.content
            })
        }
        else {
            this.setState({
                reportedByList: "404"
            })
        }

    }


    goToNextPage = () => {
        const { currentPage, metaData } = this.state
        if (currentPage < metaData.total_pages) {
            this.setState({
                currentPage: currentPage + 1
            }, () => this.fetchMore(currentPage + 1))

        }
    }


    onConfirmVehicle = () => {

        this.setState({
            modal: false
        })
    }



    onConfirmReport = async (plateNumber, reportedById, locationId, descriptionId) => {
        if (plateNumber && reportedById && locationId && descriptionId) {
            const server_response = await ajax.reportWrongParking(plateNumber, reportedById, locationId, descriptionId);
            if (server_response.status === 'OK') {

                $("#modal_report_wrong_parking").modal('hide');
                this.setState({
                    modal: false,
                    toast: <Toast message={server_response.details.message} />
                },
                    () => {
                        this.setState({
                            // modal: <PopReportWrongParking
                            //     title="Report Wrong Parking"
                            //     onReload={this.listWrongParkingTransactions}
                            //     onConfirm={this.onConfirmReport} />

                        },
                            () => this.listWrongParkingTransactions())
                    })
            } else {
                $("#modal_report_wrong_parking").modal('hide');
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} />
                },
                    () => {
                        this.setState({
                            // modal: <PopReportWrongParking
                            //     title="Report Wrong Parking"
                            //     onReload={this.listWrongParkingTransactions}
                            //     onConfirm={this.onConfirmReport} />

                        })
                    }
                )
            }
        }

    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }


    // fetchMoreTrigger = () => {
    //     const newData = {
    //         ...data,
    //         "page": currentPage,
    //     }
    //     fetchMore(newData)
    // }



    listWrongParkingTransactions = async () => {
        this.setState({
            loading: true,
        })
        const server_response = await ajax.listWrongParkingTransactions(this.state.currentPage);
        this.setState({
            loading: false
        })
        if (server_response.status === "OK") {
            this.setState({
                List: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })
        } else {
            this.setState({
                List: "404",
                metaData: false
            })
        }
    }

    fetchMore = async (currentPage) => {
        const { List } = this.state
        this.setState({
            loading: true
        })

        restoreTable(detectTable());

        const server_response = await ajax.listWrongParkingTransactions(currentPage);
        this.setState({
            loading: false
        })

        if (server_response.status === "OK") {
            const incoming = Array.from(server_response.details.content.list);
            this.setState({
                List: List.concat(incoming),
                metaData: server_response.details.content.meta
            })
        }

    }

    goToNextEnforcementPage = (filter) => {
        const { EcurrentPage, EmetaData } = this.state
        if (EcurrentPage < EmetaData.total_pages) {
            this.setState({
                EcurrentPage: EcurrentPage + 1
            }, () => this.fetchMoreEReport(filter, EcurrentPage + 1))

        }
    }

    goToNextFilteredEnforcementPage = (filter) => {
        const { EcurrentPage, filterEmetaData, EfilteredcurrentPage } = this.state
        if (EfilteredcurrentPage < filterEmetaData.total_pages) {
            this.setState({
                EfilteredcurrentPage: EfilteredcurrentPage + 1
            }, () => this.fetchMoreEReport(filter, EfilteredcurrentPage + 1))

        }
    }


    // enforcement analysis report
    fetchMoreEReport = async (filter, EcurrentPage) => {
        const { enforcementAnalysisByBranchList, filterEnforcementByBranchList } = this.state
        this.setState({
            Eloading: true

        })
        restoreTable(detectTable());


        if (!filter) {
            this.setState({
                enforcementAnalysisByBranchList: false,
                EmetaData: false

            })
            var server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch("", "", "", "", EcurrentPage);

            this.setState({
                Eloading: false
            })

            if (server_response.status === "OK") {
                const Eincoming = Array.from(server_response.details.content.list);
                this.setState({
                    enforcementAnalysisByBranchList: enforcementAnalysisByBranchList.concat(Eincoming),
                    EmetaData: server_response.details.content.meta
                })
            }

        } else {
            this.setState({
                filterEnforcementByBranchList: false,
                filterEmetaData: false

            })
            var server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch(filter.selectedValue6, filter.selectedValue, filter.dateFrom, filter.dateTo, this.state.EfilteredcurrentPage);

            this.setState({
                Eloading: false
            })

            if (server_response.status === "OK") {
                const Eincoming = Array.from(server_response.details.content.list);
                this.setState({
                    filterEnforcementByBranchList: filterEnforcementByBranchList.concat(Eincoming),
                    filterEmetaData: server_response.details.content.meta
                })
            }
        }


        // const server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch(EcurrentPage);


    }



    getRecentWrongParkingTransactions = async () => {
        const server_response = await ajaxWrongParking.listRecentWrongParking();
        if (server_response.status === 'OK') {
            this.setState({
                recentWrongParking: server_response.details.recent_wrong_parking_transactions.list,
            })
        }
        else {
            this.setState({
                recentWrongParking: "404",
            })
        }

    }

    getBranchEnforcementAnalysis = async (filter, currentPage) => {
        if (!filter) {
            this.setState({
                enforcementAnalysisByBranchList: false,
                EmetaData: false

            })
            var server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch("", "", "", "", currentPage);

            if (server_response.status === 'OK') {
                this.setState({
                    enforcementAnalysisByBranchList: server_response.details.content.list,
                    EmetaData: server_response.details.content.meta

                })
            }
            else {
                this.setState({
                    enforcementAnalysisByBranchList: "404",
                })
            }

        } else {
            this.setState({
                filterEnforcementByBranchList: false,
                filterEmetaData: false
            })

            var server_response = await ajaxEnforcementAnalysis.listEnforcementAnalysisByBranch(filter.selectedValue6, filter.selectedValue, filter.dateFrom, filter.dateTo, currentPage);
            if (server_response.status === 'OK') {
                this.setState({
                    filterEnforcementByBranchList: server_response.details.content.list,
                    filterEmetaData: server_response.details.content.meta
                })
            }
            else {
                this.setState({
                    filterEnforcementByBranchList: "404",
                    filterEmetaData: false
                })
            }
        }



    }

    getBranchContraventionAnalysis = async () => {
        // c stands for contravention
        const server_response = await ajaxEnforcementAnalysis.listContraventionAnalysisByBranch();
        if (server_response.status === 'OK') {
            this.setState({
                contraventionAnalysisByBranchList: server_response.details.content.list,
                CmetaData: server_response.details.content.meta

            })
        }
        else {
            this.setState({
                contraventionAnalysisByBranchList: "404",
            })
        }

    }





    getCountReportedByWrongParking = async () => {
        const server_response = await ajaxWrongParking.countReportedByWrongParking();
        if (server_response.status === 'OK') {
            this.setState({
                countReportedByWP: server_response.details.count,
            })
        }
        else {
            this.setState({
                countReportedByWP: "404",
            })
        }

    }

    countTodaysWrongParkingTransactions = async () => {
        const server_response = await ajaxWrongParking.countTodaysWrongParking();
        if (server_response.status === 'OK') {
            this.setState({
                countTodaysWrongParkings: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countTodaysWrongParkings: "404",
            })
        }

    }

    countTodaysPaymentDueTransactions = async () => {
        const server_response = await ajaxPaymentDue.countTodaysPaymentDues();
        if (server_response.status === 'OK') {
            this.setState({
                countTodaysPaymentDues: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countTodaysPaymentDues: "404",
            })
        }

    }



    countTodaysPaidPaymentDueTransactions = async () => {
        const server_response = await ajaxPaymentDue.countTodaysPaidPaymentDues();
        if (server_response.status === 'OK') {
            this.setState({
                countTodaysPaidPaymentDues: server_response.details.content.total,
            })
        }
        else {
            this.setState({
                countTodaysPaidPaymentDues: "404",
            })
        }

    }



    onClickReportVehicle = () => {
        this.resetToast();
        // this.setState({
        //     info:false,
        // })
        $("#modal_report_wrong_parking").modal('show');
    }

    // onClickViewVehiclePhoto = (record) => {
    //     this.setState({
    //         imageView: false
    //     }, () => {

    //         this.setState({
    //             imageView: <PopViewImage image={record} />
    //         }, () => {
    //             $("#modal_view_image").modal('show');
    //         })
    //     })

    // }



    // onClickViewVehiclePhoto = (record) => {
    //     this.setState({
    //         imageView: false
    //     }, () => {

    //         this.setState({
    //             imageView: <PopViewImage image={record} />
    //         }, () => {
    //             $("#modal_view_image").modal('show');
    //         })
    //     })

    // }


    render() {

        // const List = this.state.List;
        const {
            userId,
            countTodaysWrongParkings,
            WrongParking,
            currentPage,
            List,
            modal,
            metaData,
            toast,
            hasError,
            info,
            imageView,
            loadTable,
            enableUpdate,
            recentWrongParking,
            countTodaysPaymentDues,
            countReportedByWP,
            countTodaysPaidWrongParkings,
            countTodaysPaidPaymentDues,
            loading,
            reportedByList,
            enforcementAnalysisByBranchList,
            Eloading
        } = this.state;
        // const {listWrongParkingTransactions}=this.listWrongParkingTransactions

        return (
            <WrongParkingContext.Provider value={{
                userId: userId,
                countTodaysWrongParkings: countTodaysWrongParkings,
                WrongParking: WrongParking,
                currentPage: currentPage,
                List: List,
                modal: modal,
                metaData: metaData,
                toast: toast,
                hasError: hasError,
                info: info,
                imageView: imageView,
                loadTable: loadTable,
                enableUpdate: enableUpdate,
                getWrongParking: this.listWrongParkingTransactions,
                recentWrongParking: recentWrongParking,
                getRecentWrongParkingTransactions: this.getRecentWrongParkingTransactions,
                countTodaysWrongParkingTransactions: this.countTodaysWrongParkingTransactions,
                countTodaysPaymentDueTransactions: this.countTodaysPaymentDueTransactions,
                countTodaysPaymentDues: countTodaysPaymentDues,
                countReportedByWP: countReportedByWP,
                getCountReportedByWrongParking: this.getCountReportedByWrongParking,
                countTodaysPaidWrongParkings: countTodaysPaidWrongParkings,
                countTodaysPaidPaymentDues: countTodaysPaidPaymentDues,
                countTodaysPaidPaymentDueTransactions: this.countTodaysPaidPaymentDueTransactions,
                enforcementAnalysisByBranchList: enforcementAnalysisByBranchList,
                goToNextPage: this.goToNextPage,
                loading: loading,
                reportedByList: reportedByList,
                EmetaData: this.state.EmetaData,
                goToNextEnforcementPage: this.goToNextEnforcementPage,
                Eloading: Eloading,
                getBranchEnforcementAnalysis: this.getBranchEnforcementAnalysis,
                filterEnforcementByBranchList: this.state.filterEnforcementByBranchList,
                filterEmetaData: this.state.filterEmetaData,
                goToNextFilteredEnforcementPage: this.goToNextFilteredEnforcementPage,
                EfiltereddcurrentPage: this.state.EfiltereddcurrentPage

                // FuncViewPhoto: this.onClickViewVehiclePhoto

            }}>
                {this.props.children}
            </WrongParkingContext.Provider>
        );
    }

}

// export const WrongParkingProvider = (props) => {

//     return (
//         <WrongParkingContext.Provider value={{show:"showing"}}>
//             {props.children}
//         </WrongParkingContext.Provider>
//     );


// }


export default WrongParkingContext;