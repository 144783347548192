import React from 'react'
import LeftSideBar from '../../../Components/Common/LeftSideBar'
import Footer from '../../../Components/Common/Footer'
import TopBar from '../../../Components/Common/TopBar'
import PageHeader from '../../../Components/Common/PageHeader'
import functions from '../../../utils/functions'
import RightSideBar from '../../../Components/Common/RightSideBar'
import ConfigMenu from '../../../Components/ConfigMenu'
import ajax from '../../../utils/ajax'
// import ActivityLoader from '../../../Components/Common/ActivityLoader'
import TextInput from '../../../Components/Common/TextInput'
import dictionary from '../../../utils/dictionary'
import AlertSuccess from '../../../Components/Common/AlertSuccess'
import AlertError from '../../../Components/Common/AlertError'
import SelectSearch from '../../../Components/Common/SelectSearch'

class CreateLocation extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    streetId: '',
    locationName: '',
    streetList: false,
    description: '',
    lat: '',
    lng: '',
    startLat: '',
    startLng: '',
    endLat: '',
    endLng: '',
    totalSlots: '',
    isNonParkingZone: 0,
    status: ''
  }

  componentDidMount() {
    this.getBranches();
  }

  getBranches = async () => {
    const server_response = await ajax.listStreets(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        streetList: server_response.details.content
      })
    } else {
      this.setState({
        streetList: "404"
      })
    }
  }



  onChangeStreet = (selectedOption) => {
    this.setState({
      streetId: selectedOption.value
    })
  }

  onChangeStreetName = (event) => {
    this.setState({ locationName: event.target.value })
  }

  onChangeDescription = (event) => {
    this.setState({ description: event.target.value })
  }

  onChangeSlots = (event) => {
    this.setState({ totalSlots: event.target.value })
  }

  onClickToggle = () => {
    if (this.state.isNonParkingZone === 0) {
      this.setState({
        isNonParkingZone: 1
      })
    } else {
      this.setState({
        isNonParkingZone: 0
      })
    }
  }

  onChangeLat = (event) => {
    this.setState({ lat: event.target.value })
  }

  onChangeLng = (event) => {
    this.setState({ lng: event.target.value })
  }

  onChangeStartLat = (event) => {
    this.setState({ startLat: event.target.value })
  }

  onChangeStartLng = (event) => {
    this.setState({ startLng: event.target.value })
  }

  onChangeEndLat = (event) => {
    this.setState({ endLat: event.target.value })
  }

  onChangeEndLng = (event) => {
    this.setState({ endLng: event.target.value })
  }

  onSave = async (event) => {
    event.preventDefault();
    const { 
      locationName, 
      streetId, 
      description, 
      totalSlots, 
      lat, 
      lng, 
      startLat, 
      startLng, 
      endLat, 
      endLng, 
      isNonParkingZone 
    } = this.state;

    if (isNonParkingZone === '0') {
      this.setState({
        status: '1'
      })
    }

    if (
      locationName.length > 0 &&
      streetId.length > 0 &&
      // description.length > 0 &&
      totalSlots.length > 0
      // lat.length > 0 &&
      // lng.length > 0
    ) {
      //fetch login from the API
      const server_response = await ajax.createLocation(
        locationName,
        streetId,
        description,
        lat,
        lng,
        startLat,
        startLng,
        endLat,
        endLng,
        totalSlots,
        isNonParkingZone
      );

      // console.log(server_response.details.content)

      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })
        this.setState({
          locationName: '',
          description: '',
          lat: '',
          lng: '',
          startLat: '',
          startLng: '',
          endLat: '',
          endLng: '',
          totalSlots: ''
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._completeFields} />,
        loading: ''
      })
    }


  }

  render() {

    const streetList = this.state.streetList;
    const optionsStreet = [];
    if (streetList && streetList !== "404") {
      streetList.map((item, key) =>
        optionsStreet.push({ label: item.street_name, value: item.street_id })
      )
    }


    return (
      <div className="page">
        <LeftSideBar />
        <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="New Parking Location"
              previousPageTitle="Locations"
              back="/config/locations"
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {this.state.info}
                        <form method="post" onSubmit={this.onSave}>


                          <TextInput
                            label="Location Name"
                            type="text"
                            onChange={() => this.onChangeStreetName}
                            value={this.state.locationName}
                          />

                          <SelectSearch
                            label="Location Street"
                            onChange={() => this.onChangeStreet}
                            options={optionsStreet}
                            placeholder={"Select Street..."}
                          />

                          <TextInput
                            label="Location Description"
                            type="textarea"
                            onChange={() => this.onChangeDescription}
                            value={this.state.description}
                          />

                          <TextInput
                            label="Total Slots"
                            type="number"
                            onChange={() => this.onChangeSlots}
                            value={this.state.totalSlots}
                          />

                          <div className="switch-toggle d-flex justify-content-start m-0 p-0">
                            <span className="mr-5">Is nonparking zone</span>
                            <div className="onoffswitch2" onClick={this.onClickToggle}>
                              <input
                                type="radio"
                                className="onoffswitch2-checkbox ml-5"
                                checked={this.state.isNonParkingZone == 0 ? false : true}
                                onChange={() => this.onClickToggle}
                                value={this.state.isNonParkingZone}
                              />
                              <label htmlFor="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                          </div>

                          <hr />
                          {/* <p>---- Geo coordinates ----</p> */}
                          <hr />


                          <TextInput
                            label="Middle Geo Latitude"
                            type="number"
                            onChange={() => this.onChangeLat}
                            value={this.state.lat}
                          />

                          <TextInput
                            label="Middle Geo Longitude"
                            type="number"
                            onChange={() => this.onChangeLng}
                            value={this.state.lng}
                          />
                          <TextInput
                            label="Start Geo Latitude"
                            type="number"
                            onChange={() => this.onChangeStartLat}
                            value={this.state.startLat}
                          />

                          <TextInput
                            label="Start Geo Longitude"
                            type="number"
                            onChange={() => this.onChangeStartLng}
                            value={this.state.startLng}
                          />

                          <TextInput
                            label="End Geo Latitude"
                            type="number"
                            onChange={() => this.onChangeEndLat}
                            value={this.state.endLat}
                          />

                          <TextInput
                            label="End Geo Longitude"
                            type="number"
                            onChange={() => this.onChangeEndLng}
                            value={this.state.endLng}
                          />







                          <div className="modal-footer">
                            <input className="btn ripple btn-success" type="button" value="Save Location" onClick={this.onSave} />
                          </div>
                        </form>




                      </div>
                    </div>


                  </div>





                </div>

              </div>

              <ConfigMenu />



            </div>


          </div>
        </div>
        <RightSideBar />
         
      </div>


    );

  }
}

export default CreateLocation
