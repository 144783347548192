import React, { useContext, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfigurationContext from '../../../../Context/ConfigurationContext';
import ajax from '../../../../utils/ajax';
import dictionary from '../../../../utils/dictionary';
import functions from '../../../../utils/functions';
import ajaxPayment from '../../../../utils/remote/ajaxPayment';
import ActivityLoader from '../../../Common/ActivityLoader';
import AlertError from '../../../Common/AlertError';
import AlertSuccess from '../../../Common/AlertSuccess';
import InitialAvatar from '../../../Common/InitialAvatar';
import SelectSearch from '../../../Common/SelectSearch';
import TextInput from '../../../Common/TextInput';

const PopRTGSPayment=(props)=>{

    const initialValue={
        amount:'',
        depositorContact: '',
        depositorName: '',
        souceDescription:'',
        transactionDate:''
    }

    const [formValues, setFormValues]=useReducer((curVal, newVal)=>({...curVal, ...newVal}), initialValue)

    const {amount, depositorContact, depositorName, souceDescription, transactionDate} = formValues;
   
    const { bankList } = useContext(ConfigurationContext);

    const [bankId, setBankId] = useState('');
    const [info, setInfo] = useState(false);

   
    const handleFormChange=(event)=>{
        const {name, value} = event.target;
        setFormValues({[name]:value})
    }
  
   
  


   const  onConfirm = async(event) => {
        event.preventDefault();

        if (depositorContact.length > 0 && 
            depositorName.length > 0 && 
            souceDescription.length>0 && 
            transactionDate.length>0 && 
            bankId>0 &&
            amount>0) {

                if(amount > props.invoice.balance.amount)
                {
                    
                    setInfo(<AlertError message={dictionary._amountBound} />)
                    return false;
                }    
                const server_response = await ajaxPayment.depositReservationRTGS(
                    depositorName,
                    depositorContact,
                    amount,
                    souceDescription,
                    transactionDate,
                    bankId,
                    props.invoice.invoice.id
                )
        
                if (server_response.status === "OK") {
                    //load receipt
                    functions.closePopup("modal_rtgs_payment");
                    props.onConfirm(<AlertSuccess message={server_response.details.message} />)
                   
                } else {
                    
                    setInfo(<AlertError message={server_response.details.message} />)
                
                }
            

        } else {
            setInfo(<AlertError message={dictionary._completeFields} />)
            
        }
    }



    
    const renderBank=()=>{
        const optionsBank = [];
        if (Array.isArray(bankList)) {
            bankList.map((item, key) =>
                optionsBank.push({ label: item.bank_name, value: item.bank_id })
            )
        }

        return(
            <TextInput
                    label="Select Bank"
                    type="select" 
                    placeholder="Select bank of origin...." 
                    options={optionsBank}
                    onChange={ (item)=>{setBankId(item.value)} }
                    />
        )
    }



       

        return (
            <>
                <div className="modal" id="modal_rtgs_payment">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">


                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">New RTGS Payment</h6>
                                <span>Amount Due: {props.invoice.balance.amount_c}</span>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                { info }
                                <div className="row">

                                <div className="col-4">
                                     <div className="justify-content-center text-center mb-3 d-flex">
                                        <InitialAvatar size={2} value={<i className="fas fa-briefcase"></i>}/>
                                    </div>

                                    <div className="activity-block mr-3 scroll-widget border-top overflow-auto">
										<ul className="task-list">
										    <li><b>{ props.invoice.invoice.organisation.title }</b></li>
                                            <li style={{fontSize:'12px'}}>{ props.invoice.invoice.organisation.location_address }</li>
                                            <li style={{fontSize:'12px'}}>{ props.invoice.invoice.organisation.contact }</li>

										</ul>


									</div>




                                </div>



                                <div className="col-8">

                                <div className="justify-content-center text-center mb-3 d-flex">
                                        <InitialAvatar size={2} value={<i className="fas fa-edit"></i>}/>
                                </div>
                                <form method="post" onSubmit={onConfirm}>

                                    {renderBank()}

                                    <TextInput
                                          label="Funds Source"
                                          value={souceDescription}
                                          type="textarea"
                                          placeholder="Account Number - Account Name"
                                          onChange={handleFormChange}
                                          name="souceDescription"
                                    />

                                    <TextInput
                                        label="Amount"
                                        type="number"
                                        onChange={handleFormChange}
                                        value={amount}
                                        name="amount"
                                    />

                                    <TextInput
                                          label="RTGS Date"
                                          value={transactionDate}
                                          type="date"
                                          onChange={handleFormChange}
                                          name="transactionDate"
                                    />
                                     

                                    <TextInput
                                        label="Depositor's Name"
                                        type="text"
                                        onChange={handleFormChange}
                                        value={depositorName}
                                        name="depositorName"
                                    />

                                    <TextInput
                                        label="Depositor's Contact"
                                        type="text"
                                        onChange={handleFormChange}
                                        value={depositorContact}
                                        name="depositorContact"
                                    />


                                </form>
                                </div>
                               
                                </div>



                            </div>
                            <div className="modal-footer">
                                <button className="btn ripple btn-success" type="button" onClick={onConfirm}>Request RTGS Verification</button>
                                <button className="btn ripple btn-dark" type="button" data-dismiss="modal">Close</button>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )


    }


export default PopRTGSPayment;
