import React, { useContext } from 'react';
import FieldContext from '../../../Context/FieldContext';
import ActivityLoader from '../../Common/ActivityLoader';
import AlertError from '../../Common/AlertError';
import NoContent from '../../Common/NoContent';
import TableHeader from '../../Common/TableHeader';

const OpSessions = (props) => {

    const { sessionList, sessionMessage } = useContext(FieldContext)



    return (

        <div className="col-sm-12 col-xl-4 col-lg-4">
            <div className="card custom-card">
                <div className="card-body map-card">

                    {/* <div>
                            <h6 className="card-title mb-1">Sessions</h6>
                            <p className="text-muted mb-0 card-sub-title">List of today's sessions.</p>
                        </div> */}

                    <TableHeader
                        title="Sessions"
                        subtitle="List of today's sessions."
                    />

                    <div className="activity-block user-manager mt-3 pt-3 scroll-widget border-top overflow-auto">
                        {/* <div className="card-body">
                    <div className="activity-block scroll-widget overflow-auto"> */}
                        {sessionList == "404" && 
                            <>
                                {/* <AlertError message={sessionMessage} type="info" /> */}
                                <NoContent />
                            </>
                        }

                        {!sessionList && <ActivityLoader />}

                        {Array.isArray(sessionList) && <ul className="task-list">
                            {sessionList.map((item, key) => <li key={key}>
                                <i className={`task-icon bg-${item.active * 1 ? "success" : "danger"}`}></i>
                                <h6>{item.vehicle.plate_number}<small className="float-right text-muted tx-11">
                                    {item.start_time.time}
                                    {item.active * 1 ? " " : " - " + item.end_time.time}</small></h6>
                                <span className="text-muted tx-12">{item.location.location_name + ' - ' + item.location.street.street_name} (slots:{item.number_of_slots})</span>
                                <br /><span className="text-normal tx-12">Vehicle type: {item.vehicle.vehicle_type.type_name} (Expt. Slots: {item.vehicle.vehicle_type.slots})</span>
                            </li>)}

                        </ul>}
                        {/* </div>
                </div> */}
                    </div>
                </div>
            </div>
        </div>


    );

}

export default OpSessions;