import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import $ from 'jquery';
import ActivityLoader from '../../Components/Common/ActivityLoader'
import PopSessionLocation from '../../Components/PopSessionLocation'
import Toast from '../../Components/Common/Toast'
// import InitialAvatar from '../../Components/Common/InitialAvatar'
import WarnDelete from '../../Components/WarnDelete'
import ClientView from '../../Components/ClientView'

class NewSession extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        vehicleInfo: false,
        sessionInfo: false,
        menuOptions: '',
        conductorRecord: false,
        plateNumber: '',
        loading: false,
        toast: false
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/operations');
        } else {
            this.setState({
                conductorRecord: this.props.location.state.conductor,
                plateNumber: this.props.location.state.vehiclePlate
            }, () => this.stageSession())
        }

    }


    componentDidUpdate(prevProps) {
        if (this.props.location.state) {
            if (this.state.plateNumber !== this.props.location.state.vehiclePlate) {
                this.setState(
                    { plateNumber: this.props.location.state.vehiclePlate },
                    () => this.setState({
                        vehicleInfo: false,
                        sessionInfo: false
                    }, () => this.stageSession()
                    ));
            }
        }

        //this.props.match.params.id
    }



    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    stageSession = async () => {
        const server_response = await ajax.stageSession(this.state.plateNumber);
        if (server_response.status === "OK") {
            this.setState({
                vehicleInfo: server_response.details.content.vehicle_info,
                sessionInfo: server_response.details.content.session_info,
                toast: <Toast message={server_response.details.message} />
            })
        } else {
            this.setState({
                vehicleInfo: "404",
                toast: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    refresh = async () => {
        const server_response = await ajax.stageSession(this.state.plateNumber);
        if (server_response.status === "OK") {
            this.setState({
                vehicleInfo: server_response.details.content.vehicle_info,
                sessionInfo: server_response.details.content.session_info,
            })
        } else {
            this.setState({
                vehicleInfo: "404",
                toast: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onSelectRecord = (selectedUserId, userRecord) => {
        this.setState({
            selectedUserId,
            userRecord
        }, () => {
            //change menu according to selected role
            if (this.state.userRecord.user_role.role_id === "2") {
                this.setState({
                    menuOptions: {
                        "startSession": () => this.onClickStartSession(),
                        "add": () => this.onClickAdd(),
                        "edit": () => this.onClickEdit(),
                        "delete": () => this.onClickDelete()
                    }
                })
            } else {

                this.setState({
                    menuOptions: {
                        "add": () => this.onClickAdd(),
                        "edit": () => this.onClickEdit(),
                        "delete": () => this.onClickDelete()
                    }
                })

            }
        })
    }

    onClickStartSession = () => {
        this.setState({
            loading: true
        })
        this.resetToast();
        $("#modal_session_location").modal('show');
    }

    onClickEndSession = () => {
        this.setState({
            loading: true
        })
        this.resetToast();
        $("#modal_delete").modal('show');
    }

    onStartSession = async (locationId, slots) => {
        $("#modal_session_location").modal('hide');
        this.resetToast();
        this.setState({
            loading: true
        })
        const server_response = await ajax.startSession(
            this.state.plateNumber,
            this.state.conductorRecord.user_id,
            locationId,
            this.state.userId,
            slots
        );
        if (server_response.status === "OK") {
            this.setState({
                sessionInfo: server_response.details.content,
                toast: <Toast message={server_response.details.message} />,
                loading: false
            })
        } else {
            this.setState({
                toast: <Toast message={server_response.details.message} type={2} />
            })
        }

    }


    onConfirmEndSession = async () => {

        if (this.state.sessionInfo) {
            $("#modal_delete").modal('hide');
            this.setState({
                loading: true
            })
            const server_response = await ajax.endSession(
                this.state.sessionInfo.session_id,
                this.state.userId
            );
            if (server_response.status === "OK") {
                this.setState({
                    toast: <Toast message={server_response.details.message} />,
                    loading: false
                }, () => {

                    this.refresh()
                })
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} />
                })
            }

        }

    }

    refreshClient = () => {
        this.setState({
            vehicleInfo: false
        }, () => {
            this.refresh();
        })
    }

    render() {
        const conductorDetails = this.state.conductorRecord;
        const vehicleInfo = this.state.vehicleInfo;
        const sessionInfo = this.state.sessionInfo;
        return (
            <div className="page">
                {conductorDetails && <PopSessionLocation
                    userId={conductorDetails.user_id}
                    onConfirm={this.onStartSession}
                    title="Select location to confirm" />}

                {sessionInfo &&
                    <WarnDelete
                        title="End Session?"
                        description="You are about to end session as the currently logged in user!"
                        buttonLabel="End Session"
                        onConfirm={() => this.onConfirmEndSession()} />
                }

                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="New Session"
                            previousPageTitle="Operations"
                            back="/operations"
                        //options={this.state.menuOptions}
                        />
                        {this.state.toast}


                        <div className="row">

                            <div className="col-lg-4 col-md-12">

                                {vehicleInfo && vehicleInfo !== '404' &&
                                    <div className="card custom-card">

                                        <div className="card-header custom-card-header">
                                            <h5 className="card-title tx-dark tx-medium mb-0">Vehicle Info</h5>
                                        </div>
                                        <div className="card-body main-contact-info-body">

                                            <div className="media-list pb-lg-0">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <div>
                                                            <label>Plate Number</label> <span className="tx-medium">{vehicleInfo.plate_number}</span>
                                                        </div>
                                                        <div>
                                                            <label>Vehicle ID</label> <span className="tx-medium">{vehicleInfo.vehicle_id}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media">
                                                    <div className="media-body">
                                                        <div>
                                                            <label>Is VIP</label>
                                                            <span className="tx-medium">{vehicleInfo.is_vip}</span>
                                                        </div>
                                                        <div>
                                                            <label>Session Status</label>
                                                            <span className="tx-medium">{this.state.sessionInfo ? "ACTIVE" : "INACTIVE"}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media">
                                                    <div className="media-body">
                                                        <div>
                                                            <label>Added Since</label>
                                                            <span className="tx-medium">{vehicleInfo.created_at}</span>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>

                                        <div className="card-footer p-1">
                                            {!this.state.sessionInfo &&
                                                <button className="btn ripple btn-success btn-block h-50" onClick={this.onClickStartSession}>
                                                    {!this.state.loading ? "Start Session" : "Starting..."}
                                                </button>
                                            }

                                            {this.state.sessionInfo &&
                                                <button className="btn ripple btn-danger btn-block" onClick={this.onClickEndSession}>
                                                    {!this.state.loading ? "End Session" : "Ending..."}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }



                                {conductorDetails &&
                                    <div className="card custom-card">
                                        <div className="card-header custom-card-header">
                                            <h5 className="card-title tx-dark tx-medium mb-0">
                                                {conductorDetails.user_role.role_name}
                                            </h5>
                                        </div>
                                        <div className="card-body text-center">

                                            <div className="item-user pro-user">
                                                <p className="pro-user-desc text-muted mb-1">
                                                    {conductorDetails.first_name + ' ' + conductorDetails.last_name}
                                                </p>
                                            </div>
                                        </div>

                                    </div>}



                            </div>
                            {vehicleInfo && vehicleInfo !== "404" && <ClientView vehicleInfo={vehicleInfo} refresh={this.refreshClient} />}


                            {!vehicleInfo && <ActivityLoader />}


                        </div>





                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default NewSession
