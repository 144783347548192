import React, { useContext } from 'react';
import StatisticCard from '../../../StatisticCard';
import EfrisHistoryContext from '../../../../Context/Efris/EfrisHistoryContext';
import FiscalReceiptContext from '../../../../Context/Efris/FiscalReceiptContext';


const EDailyTarget =()=>{

    const {taxConfig} = useContext(EfrisHistoryContext);
    const {dailyTax} = useContext(FiscalReceiptContext);

 
    return <StatisticCard
                title="Daily Collection" 
                value={dailyTax}
                caption={taxConfig ? "Target: UGX. " + taxConfig.daily_target.amount_p : "..."}
                icon={"fa fa-clock"}
                color={"text-success"}
                progressbar={"progress-bar progress-bar-xs wd-100p bg-success"}
                />
 
}

export default EDailyTarget;