import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('parking@user');
const apiHost = dictionary.apiHost

export default {


    async locationAverageParkingTime(data) {
        // const data = { "page": page }

        try {
            let response = await fetch(apiHost + 'report/average/parking-time',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async filterSpaceInventory(
        street_id,
        createdFrom,
        createdTo,
        location_id,
        // vipValue,
        // keyword,
        page
        // status
    ) {

        let data = {
            "street_id": street_id,
            "location_id": location_id,


            // "vipValue": vipValue,
            // "keyword": keyword,
            "date_from": createdFrom,
            "date_to": createdTo,
            "page": page,
            // "status": status
        }



        try {
            let response = await fetch(apiHost + 'report/average/parking-time',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json()


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async listVehicleOccupancy(data) {


        try {
            let response = await fetch(apiHost + 'reports/occupancy',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            console.log(error)
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    }
}