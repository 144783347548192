import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../utils/functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import ajax from '../../utils/ajax'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import $ from 'jquery';
import PopInvoice from '../../Components/PopInvoice'
import { Link } from 'react-router-dom'
import TableHeader from '../../Components/Common/TableHeader'
import PageNav from '../../Components/Common/PageNav'

class ListBalances extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        currentPage: 1,
        transactionList: false,
        metaData: false,

    }

   
    componentDidMount() {
        
        this.listAllRecords();
    }


    componentWillUnmount() {
        //clearTimeout(this.timerCountSession)
    }


   


    listAllRecords = async () => {
       
        const server_response = await ajax.listVehiclesWithBalances(this.state.currentPage);
        if (server_response.status === "OK") {
            this.setState({
                transactionList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, ()=>this.paginate())

        } else {
            this.setState({
                transactionList: "404",
                metaData: false
            })
        }
    }


  

    onClickPayInvoice = (plateNumber) => {
        $("#modal_invoice").modal('hide');
        this.props.history.push({
            pathname: '/finance/new-payment',
            search: '?q=1',
            state: { vehiclePlate: plateNumber }
        })

    }





    customHeaderOptions = () => {
        return (
          <>
            <Link to="/finance/new-payment" className="dropdown-item pointer">
              <i className="fe fe-plus-circle mr-2"></i>New Payment
            </Link>

          </>
    
        )
      }


//==============NAVIGATION CONTROLS START===========================
  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
      this.setState({
        page: this.state.page + 1,
        transactionList: false

      }, () => {
        this.listAllRecords();
      })
    }

  }

  onClickPage = (page) => {
    this.setState({
      page: page,
      transactionList: false
    }, () => this.listAllRecords())
  }

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState({
        page: this.state.page - 1,
        transactionList: false

      }, () => {
        this.listAllRecords();
      })
    }

  }

  paginate = () => {
    if (this.state.metaData) {
      this.setState({
        pagination: false
      }, () => {

        this.setState({
          pagination: <PageNav
            totalPages={this.state.metaData.total_pages}
            pageList={this.state.metaData.list_of_pages}
            currentPage={this.state.currentPage}
            onClickNext={this.onClickNext}
            onClickPage={this.onClickPage}
            onClickPrevious={this.onClickPrevious}
          />
        })

      })

    }
  }

  //==============NAVIGATION CONTROLS END===========================




    render() {
        const transactionList = this.state.transactionList;
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Outstanding Balances"
                            previousPageTitle="Invoices"
                            back="/finance/invoices"
                            options={{
                                "customOptions": <this.customHeaderOptions />
                              }}
                        />



                        <div className="row">
                            <div className="col-sm-12 col-xl-12 col-lg-12">
                                <div className="card custom-card">
                                    <div className="card-body">

                                        <TableHeader
                                            title="Vehicle Balances"
                                            subtitle="All Outstanding vehicle balances"
                                        />


                                     


                                        <div className="table-responsive">
                                            <table className="table table-hover text-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Vehicle</th>
                                                        <th>Client Name</th>
                                                        <th>Client Contact</th>
                                                        <th>Balance(UGX)</th>
                                                        <th>Clamped</th>
                                                        <th>White Listed</th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {transactionList && transactionList !== "404" &&
                                                        transactionList.map((item, key) =>
                                                            <tr key={key}>
                                                                <td>
                                                                   {this.state.metaData.offset_count+key+1}
                                                                </td>
                                                                <td className="tx-bold">
                                                                    <Link to={
                                                                        {
                                                                            pathname: `/vehicles/profile/${item.vehicle_info.plate_number}`,
                                                                            state: { plateNumber: item.vehicle_info.plate_number }
                                                                        }}>{item.vehicle_info.plate_number}
                                                                    </Link>
                                                                </td>
                                                                <td>{item.vehicle_info.client.full_name}</td>
                                                                <td>{item.vehicle_info.client.phone_number}</td>
                                                                <td>{item.balance.amount_c}</td>
                                                                <td>
                                                                  {item.vehicle_info.clamped?"YES":"NO"}
                                                                </td>
                                                                <td>
                                                                  {item.vehicle_info.white_list?"YES":"NO"}
                                                                </td>
                                                                
                                                            </tr>
                                                        )}


                                                </tbody>
                                            </table>


                                            {transactionList && this.state.pagination}

                                            {!transactionList && <ActivityLoader />}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
                <RightSideBar />
                 
            </div>


        );

    }
}

export default ListBalances
