import React from 'react';
import ajax from '../../utils/ajax';
import dictionary from '../../utils/dictionary';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearch from '../Common/SelectSearch';
import TextInput from '../Common/TextInput';
import functions from '../../utils/functions'
import { ManageUserConsumer } from '../../Context/ManageUserContext';
import ajaxAccess from '../../utils/remote/ajaxAccess';

class PopAssignPermission extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        info: '',
        loading: false,

        roleId: '',
        userId: '',
        roleName: "Select a role...",
        selectedRoleId: '',
        permissions:this.props.permissions,
        target:this.props.target

    }

    onChangeRole = (selectedOption) => {
        this.setState({
            roleId: selectedOption.value,
            roleName: selectedOption.label
        })
    }

    onChangeUser = (selectedOption) => {
        this.setState({
            userId: selectedOption.value,
        })
    }

   

   

    onConfirm = () => {
        const target = this.state.target;
        this.setState({
            info: ''
        })
        const { roleId, userId } = this.state;
        
        if(target === "USER")
        {
            //assign user permissions
            if(userId<=0)
            {
                this.setState({
                    info: <AlertError message={"Select target user and try again"} />
                })

                return false;
             }
            return this.confirmUserAccess();
            
        }else{

            //assign role permissions
            if(roleId<=0)
            {
                this.setState({
                    info: <AlertError message={"Select target role and try again"} />
                })

                return false;
            }

            return this.confirmRoleAccess();

        }

      
        
    }


    confirmUserAccess=async()=>{
      const server_response = await ajaxAccess.grantUserBulkyAccess(this.state.userId, this.state.permissions);
      if(server_response.status==="OK")
      {
        this.setState({
          info:<AlertSuccess message={server_response.details.message}/>
        })
      }else{
        this.setState({
            info:<AlertError message={server_response.details.message}/>
        })
      }
    }

    confirmRoleAccess=async()=>{
        const server_response = await ajaxAccess.grantRoleBulkyAccess(this.state.roleId, this.state.permissions);
        if(server_response.status==="OK")
        {
          this.setState({
            info:<AlertSuccess message={server_response.details.message}/>
          })
        }else{
          this.setState({
              info:<AlertError message={server_response.details.message}/>
          })
        }
      }

   

    render() {

       const title=this.props.target==="USER"?"New User permissions":"New Role Permissions"

        return (
           <ManageUserConsumer>
               {props=>{

                   const roleList = props.roleList;

                    const optionsRole = [];

                    if (roleList && roleList !== "404") {
                        roleList.map((item, key) =>
                            optionsRole.push({
                                label: item.role_name,
                                value: item.role_id
                            })
                        )
                    }

                    const userList = props.userList;

                    const optionsUser = [];
                    if (userList && userList !== "404") {
                        userList.map((item, key) =>
                            optionsUser.push({
                                label: item.last_name + ' ' + item.first_name + " @" + item.username,
                                value: item.user_id
                            })
                        )
                    }
            
                   return (
                    <div className="modal" id="modal_assign_permission">
                    <div className="modal-dialog modal-dialog-centered" role="document">
    
    
                        <div className="modal-content modal-content-demo shadow">
                            <div className="modal-header">
                                <h6 className="modal-title">{title}</h6>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.info}
                                <form method="post" onSubmit={this.onConfirm}>
    
                                    
                                    {this.props.target==="ROLE" && <SelectSearch
                                        label="Target Role"
                                        onChange={() => this.onChangeRole}
                                        options={optionsRole}
                                        placeholder={"Select Role..."}
                                    />}
    
                                    {this.props.target==="USER" && <SelectSearch
                                        label="Target User"
                                        onChange={() => this.onChangeUser}
                                        options={optionsUser}
                                        placeholder={"Select User..."}
                                    />}
                                    
    
                                </form>
                                
                               
                            </div>
                            <div className="modal-footer">
                                {!this.state.loading && <>
                                    <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Grant Access</button>
                                    <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                                </>}
                                {this.state.loading && <ActivityLoader />}
                            </div>
                        </div>
    
    
                    </div>
    
                </div>
                   )
               }}
           </ManageUserConsumer>
          
        )


    }
}

export default PopAssignPermission;
