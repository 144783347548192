import React, { useEffect, useState } from "react"
import Select from 'react-select';

const customStyles = {
	option: (provided, state) => ({
	  ...provided,
	  //borderBottom: '2px dotted green',
	  color: state.isSelected ? '#FFFFFF' : 'black',
	  //backgroundColor: state.isSelected ? '#1c1c38' : 'white',
	  // color: '#000000',
	  textAlign: 'left',
	}),
	control: (provided) => ({
	  ...provided,
	  backgroundColor: '#FFFFFF',
	  color: '#000000',
	  // marginTop: "5%",
	}),
	singleValue: (provided) => ({
	  ...provided,
	  color: '#000000'
	})
  }
  
const FilterSelectInput=React.memo((props)=>{

	const [value, setValue] = useState(()=>{
		if(!props.currentValues){
			return "";
		}
		for(var i=0; i<props.currentValues.length; i++){
			if(props.currentValues[i][props.name]){
				return props.currentValues[i][props.name]
			}
		}

		return ""
	});

	const optionMap = [];

    useEffect(()=>{
		props.onChange(props.name, value)
	}, [value])


	const options = [{label:"--No selection--", value:""}];
	if(Array.isArray(props.options)){
		props.options.forEach(element => {
				options.push({label:element.title, value:element.value})
				optionMap[element.value]={label:element.title, value:element.value}
		});
	}

	const handleSelect=(item)=>{
		setValue(item.value);
	}

	if(props.hide){
		return <></>
	}

    return(
        <div className={"col-md-3"}>

            <div className="form-group mb-lg-0">
					<label className="">{props.label}</label>

					<Select
						options={options}
						onChange={handleSelect}
						placeholder={optionMap[value]?optionMap[value].label : props.placeholder}
						value={value}
						styles={customStyles}
						name={props.name}
					/>
					 
					{/* <select
						className="form-control select2-flag-search"
						data-placeholder={props.placeholder}
						onChange={(e)=>setValue(e.target.value)}
						name={props.name}
						defaultValue={value}
					>
						<option value="">-Select-</option>
						{props.options.map((item, key) =>
							<option key={key} value={item.value}>{item.title}</option>
						)}
					</select> */}
				</div>


	</div>
    )

})

export default FilterSelectInput