import React from 'react';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom'


class Code extends React.Component {

    state = {

    }

    componentDidMount() {

    }


    render() {

        return (
            <div className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav">
                <div className="main-content-left-components">

                    <div class="twlo-code code-rail docs-coderail docs-layout-column" data-column-mode="true" id="coderail" style={{ height: "925px" }}>
                        <div class="rail-placeholder"></div>
                        <header class="twlo-code-header rail-title">
                            <div class="twlo-code-header__title">
                                <a href="#linkcode">
                                    <div class="twlo-code-header__title-inner">Send a simple SMS using the Programmable SMS API</div>
                                    <div class="twlo-code-header__title-loading"></div>
                                </a>
                                <svg class="twlo-icon-interface-dropdown" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M15 7l-6 6-6-6V6h1l5 5 5-5h1v1z" fill="currentColor"></path></svg>
                            </div>
                            <ul class="twlo-code-header__dropdown"><li class="twlo-code-header__dropdown-item active">Send a simple SMS using the Programmable SMS API</li></ul>
                            {/* <div class="twlo-code-header__actions">
                                <ul class="twlo-code-header__languages"><li class="twlo-code-header__language">Node.js</li><li class="twlo-code-header__language">C#</li><li class="twlo-code-header__language">PHP</li><li class="twlo-code-header__language">Ruby</li><li class="twlo-code-header__language active">Python</li><li class="twlo-code-header__language">Java</li><li class="twlo-code-header__language">curl</li><li class="twlo-code-header__language">twilio-cli</li></ul>
                                <ul class="twlo-code-header__versions"><li class="twlo-code-header__version active">7.x</li></ul>
                            </div> */}
                        </header>
                        <div class="twlo-code__sample">
                            <div class="twlo-code__actions">
                                <span class="twlo-code__action twlo-code__outdated hidden">
                                    You are viewing an outdated version of this SDK.
                                </span>
                                <span class="twlo-code__action clippy"><span class="twlo-code__action-label hidden"></span>
                                    <svg class="twlo-icon-interface-clipboard" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M16 7v2h-5v2L8 8l3-3v2h5zM8 5H4v1h4V5zm-4 7h5v-1H4v1zm9-1h1v3H2V0h12v5h-1V3H3v10h10v-2zM3 2h10V1H3v1zm1 7v1h3V9H4zm0-1h3V7H4v1z" fill="currentColor"></path></svg>
                                </span>
                                <a class="twlo-code__action github hidden" href="#" target="_blank">
                                    <svg class="twlo-icon-interface-repo" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M5 3H4V2h1v1zm0 1H4v1h1V4zm0 4H4v1h1V8zm0-2H4v1h1V6zm9-6v14H7v2l-1.5-1L4 16v-2H2V0h12zm-1 11H3v2h1v-1h3v1h6v-2zm0-10H3v9h10V1z" fill="currentColor"></path></svg>
                                </a>
                            </div>
                            <pre class="twlo-code__snippet line-numbers language-py"><code class="notranslate language-py"><span class="prism-token prism-comment"># Download the helper library from https://www.twilio.com/docs/python/install</span>
                                <span class="prism-token prism-keyword prism-keyword-import">import</span> os
                                <span class="prism-token prism-keyword prism-keyword-from">from</span> twilio<span class="prism-token prism-punctuation">.</span>rest <span class="prism-token prism-keyword prism-keyword-import">import</span> Client


                                <span class="prism-token prism-comment"># Find your Account SID and Auth Token at twilio.com/console</span>
                                <span class="prism-token prism-comment"># and set the environment variables. See http://twil.io/secure</span>
                                account_sid <span class="prism-token prism-operator">=</span> os<span class="prism-token prism-punctuation">.</span>environ<span class="prism-token prism-punctuation">[</span><span class="prism-token prism-string">'TWILIO_ACCOUNT_SID'</span><span class="prism-token prism-punctuation">]</span>
                                auth_token <span class="prism-token prism-operator">=</span> os<span class="prism-token prism-punctuation">.</span>environ<span class="prism-token prism-punctuation">[</span><span class="prism-token prism-string">'TWILIO_AUTH_TOKEN'</span><span class="prism-token prism-punctuation">]</span>
                                client <span class="prism-token prism-operator">=</span> <span class="prism-token prism-function">Client</span><span class="prism-token prism-punctuation">(</span>account_sid<span class="prism-token prism-punctuation">,</span> auth_token<span class="prism-token prism-punctuation">)</span>

                                message <span class="prism-token prism-operator">=</span> client<span class="prism-token prism-punctuation">.</span>messages \
                                <span class="prism-token prism-punctuation">.</span><span class="prism-token prism-function">create</span><span class="prism-token prism-punctuation">(</span>
                                <span class="prism-token prism-parameter prism-variable">body</span><span class="prism-token prism-operator">=</span><span class="prism-token prism-string">'This is the ship that made the Kessel Run in fourteen parsecs?'</span><span class="prism-token prism-punctuation">,</span>
                                <span class="prism-token prism-parameter prism-variable">from_</span><span class="prism-token prism-operator">=</span><span class="prism-token prism-string">'+15017122661'</span><span class="prism-token prism-punctuation">,</span>
                                <span class="prism-token prism-parameter prism-variable">to</span><span class="prism-token prism-operator">=</span><span class="prism-token prism-string">'+15558675310'</span>
                                <span class="prism-token prism-punctuation">)</span>

                                <span class="prism-token prism-keyword prism-keyword-print">print</span><span class="prism-token prism-punctuation">(</span>message<span class="prism-token prism-punctuation">.</span>sid<span class="prism-token prism-punctuation">)</span>
                                <span aria-hidden="true" class="line-numbers-rows"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></code></pre>
                            <div class="docs-coderail__next">
                                <a class="docs-coderail__next-link" href="#">Next Sample</a>
                            </div>
                        </div>
                        {/* <div class="twlo-code__output">
                    <div class="docs-coderail__label">Example <span class="lang">JSON</span> response</div>
                    <pre class="twlo-code__snippet line-numbers language-json"><code class="notranslate language-json"><span class="prism-token prism-punctuation">{</span>
                        <span class="prism-token prism-property">"account_sid"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"api_version"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"2010-04-01"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"body"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"This is the ship that made the Kessel Run in fourteen parsecs?"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"date_created"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"Thu, 30 Jul 2015 20:12:31 +0000"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"date_sent"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"Thu, 30 Jul 2015 20:12:33 +0000"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"date_updated"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"Thu, 30 Jul 2015 20:12:33 +0000"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"direction"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"outbound-api"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"error_code"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-null prism-keyword">null</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"error_message"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-null prism-keyword">null</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"from"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"+15017122661"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"messaging_service_sid"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"MGXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"num_media"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"0"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"num_segments"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"1"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"price"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-null prism-keyword">null</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"price_unit"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-null prism-keyword">null</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"sid"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"status"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"sent"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"subresource_uris"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-punctuation">{</span>
                        <span class="prism-token prism-property">"media"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Messages/SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Media.json"</span>
                        <span class="prism-token prism-punctuation">}</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"to"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"+15558675310"</span><span class="prism-token prism-punctuation">,</span>
                        <span class="prism-token prism-property">"uri"</span><span class="prism-token prism-operator">:</span> <span class="prism-token prism-string">"/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Messages/SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX.json"</span>
                        <span class="prism-token prism-punctuation">}</span><span aria-hidden="true" class="line-numbers-rows"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></code></pre>
                </div> */}
                    </div>

                </div>
            </div >

        )

    }

}

export default Code;

