import React from 'react';


class Filter extends React.Component {
  state={
    types: ['btn-success', 'btn-warning', 'btn-secondary', 'btn-primary', 'btn-danger', 'btn-light'],
    type: this.props.type || 0
  }

  componentDidMount()
  {
      if(this.state.type>5)
      {
        this.setState({
            type:this.state.type-1
        })
      }
  }

  render(){
    
      return (
        <button 
            onClick={this.props.onClick}
            type="button" className={`btn ripple ${this.state.types[this.state.type]}  btn-sm`}>
         {this.props.title} <span className="badge badge-dark ml-2">{this.props.value}</span>
        </button>
      );
    
 
  }
}

export default Filter;