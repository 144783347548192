import React from 'react';
import functions from '../../../utils/functions';
import PopVehicleListing from '../../Vehicle/VehicleListing/PopVehicleListing';

class PopVehicleEntryOption extends React.Component {

    state = {
        userId: functions.sessionGuard(),
       
    }

    onClickManual=()=>{
        functions.closePopup("modal_vehicle_entry_option");
        this.props.onSelect();
    }


   


    render() {

        return (
            <div className="modal" id="modal_vehicle_entry_option">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">{this.props.title}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                        <div className="list-group">
                            <a className="list-group-item list-group-item-action">
                                <b>How do you want to add the vehicles ?</b>
                            </a>
                            <a onClick={this.props.onSelectImport} className="list-group-item list-group-item-action" href="#">Import from list...</a>
                            <a onClick={this.props.onSelectManual} className="list-group-item list-group-item-action" href="#">Type vehicles manually</a>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopVehicleEntryOption;
